var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { ApiQuery, ApiService, RecordService } from '../../core/services';
import { RangeService } from '../../core/services/range.service';
import * as moment from 'moment/moment';
import { HxToolsDate } from '../../shared/hx-tools/hx-tools-date';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
import { Router } from '@angular/router';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';
var CalendarsComponent = /** @class */ (function (_super) {
    __extends(CalendarsComponent, _super);
    function CalendarsComponent(recordService, rangeService, apiService, globalVariables, router, helpService, eventAggregator) {
        var _this = _super.call(this, helpService) || this;
        _this.recordService = recordService;
        _this.rangeService = rangeService;
        _this.apiService = apiService;
        _this.globalVariables = globalVariables;
        _this.router = router;
        _this.helpService = helpService;
        _this.eventAggregator = eventAggregator;
        _this.ranges = [];
        _this.nranges = [];
        _this.view = 'month';
        _this.metrics = false;
        _this.viewDate = new Date();
        _this.loading = true;
        _this.displayRecords = true;
        _this.displayRanges = true;
        _this.offset = 0;
        _this.onMessageReceived = function (payload) {
            if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
                _this._getAll();
            }
        };
        _this.events = new Array();
        return _this;
    }
    CalendarsComponent.prototype.ngOnInit = function () {
        this._getAll();
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.FSS__UPDATE
        }));
    };
    CalendarsComponent.prototype.ngOnDestroy = function () {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    };
    /**
     * Retrieve all the records the logged user is allowed to see
     */
    CalendarsComponent.prototype._getAll = function () {
        var _this = this;
        this.loading = true;
        this.user = this.globalVariables.get('currentSubject');
        // If the current subject is not defined, the subject is the logged user
        if (this.user === undefined) {
            this.user = this.globalVariables.get('currentUser');
        }
        this.userId = this.user.id;
        this.events = new Array(); // ??
        var end2 = 0;
        var start2 = 999999999999999999;
        this.apiService.getQuery(ApiQuery.all_activitiytype).flatMap(function (data) {
            _this.activities = new Array();
            for (var _i = 0, _a = data['objects']; _i < _a.length; _i++) {
                var activity = _a[_i];
                _this.activities[activity.resource_uri] = activity.name;
            }
            return _this.recordService.fetchNLast(100, _this.offset, _this.userId);
        }).flatMap(function (data) {
            if (data.objects) {
                _this.records = new Array();
                _this.nrecords = new Array();
                _this.dates = new Array();
                _this.ndates = new Array();
                // Loop records
                for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                    var record = _a[_i];
                    var timestamp = Math.round(record.start / 256);
                    var _start_day = HxToolsDate.getStartOfDay(timestamp);
                    var start = moment.unix(timestamp); // Unix time start
                    var endTimestamp = Math.round(record.end / 256);
                    var _end_day = HxToolsDate.getEndOfDay(timestamp);
                    var end = moment.unix(endTimestamp); // Unix time start
                    var _start_record_in_hour = start.hour() + Number(start.minutes()) / 60;
                    var _end_record_in_hour = end.hour() + Number(end.minutes()) / 60;
                    // overnight record
                    if (HxToolsTime.isOverNight(start.unix(), end.unix())) {
                        if (_end_day > end2)
                            end2 = _end_day;
                        if (_start_day < start2)
                            start2 = _start_day;
                        var date = start.format("dddd, MMMM Do YYYY");
                        if (!_this.nrecords[date]) {
                            _this.nrecords[date] = new Array();
                            _this.nranges[date] = new Array();
                            _this.ndates.push({
                                "date": date,
                                "startOfDay": _start_day,
                                "endOfDay": _end_day
                            });
                        }
                        var _record_duration = _end_record_in_hour + 24 - _start_record_in_hour;
                        _this.nrecords[date].push({
                            "date": date,
                            "startOfDay": _start_day,
                            "endOfDay": _end_day,
                            "start": _start_record_in_hour,
                            "end": _start_record_in_hour + _record_duration,
                            "duration": _record_duration,
                            "recordId": record.id
                        });
                        // day record
                    }
                    else {
                        if (_end_day > end2)
                            end2 = _end_day;
                        if (_start_day < start2)
                            start2 = _start_day;
                        var date = start.format("dddd, MMMM Do YYYY");
                        if (!_this.records[date]) {
                            _this.records[date] = new Array();
                            _this.dates.push({
                                "date": date,
                                "startOfDay": _start_day,
                                "endOfDay": _end_day
                            });
                        }
                        _this.records[date].push({
                            "date": date,
                            "startOfDay": _start_day,
                            "endOfDay": _end_day,
                            "start": _start_record_in_hour,
                            "end": _end_record_in_hour,
                            "duration": _end_record_in_hour - _start_record_in_hour,
                            "recordId": record.id
                        });
                    }
                    var durationRecord = moment.duration(end.unix() - start.unix(), 'seconds');
                    var event_1 = {
                        start: start.toDate(),
                        start_unix: start.unix(),
                        start_time: start.hour() + ":" + (start.minutes() < 10 ? "0" + start.minutes() : start.minutes()),
                        meta: { type: 'record' },
                        duration_hh_mm: durationRecord.hours() + ":" + (durationRecord.minutes() < 10 ? "0" + durationRecord.minutes() : durationRecord.minutes()),
                        id: record.id,
                    };
                    _this.events.push(event_1);
                } // End - Loop records
            }
            return _this.rangeService.fetchPeriod(256 * end2, 256 * start2, null, null, _this.userId);
        }).subscribe(function (data) {
            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                var range = _a[_i];
                var timestamp = Math.round(range.start / 256);
                var _start_day = moment(timestamp, "X").startOf('day').unix();
                var start = moment.unix(timestamp); // Unix time start
                var date = start.format("dddd, MMMM Do YYYY");
                timestamp = Math.round(range.end / 256);
                var _end_day = moment(timestamp, "X").endOf('day').unix();
                var end = moment.unix(timestamp); // Unix time start
                if (HxToolsTime.isOverNight(start.unix(), end.unix())) {
                    if (!_this.nranges[date]) {
                        _this.nranges[date] = new Array();
                    }
                    var durationRange = (end.hour() + Number(end.minutes()) / 60) + 24 - (start.hour() + Number(start.minutes()) / 60);
                    _this.nranges[date].push({
                        "date": date,
                        "startOfDay": _start_day,
                        "endOfDay": _end_day,
                        "start": start.hour() + Number(start.minutes()) / 60,
                        "end": start.hour() + Number(end.minutes()) / 60 + durationRange,
                        "rangeId": range.id
                    });
                }
                else {
                    if (!_this.ranges[date]) {
                        _this.ranges[date] = new Array();
                    }
                    _this.ranges[date].push({
                        "date": date,
                        "startOfDay": _start_day,
                        "endOfDay": _end_day,
                        "start": start.hour() + Number(start.minutes()) / 60,
                        "end": end.hour() + Number(end.minutes()) / 60,
                        "rangeId": range.id
                    });
                }
                var duration = moment.duration(end.unix() - start.unix(), 'seconds');
                var event_2 = {
                    start: start.toDate(),
                    start_unix: start.unix(),
                    _start: "" + Math.round(100 * (start.hour() + Number(start.minutes()) / 60) / 24),
                    meta: { type: 'range' },
                    start_time: start.hour() + ":" + (start.minutes() < 10 ? "0" + start.minutes() : start.minutes()),
                    icon: _this.activities[range.context.activitytype],
                    id: range.id,
                    duration_hh_mm: duration.hours() + ":" + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()),
                };
                _this.events.push(event_2);
            }
            _this.afterViewInit();
            _this.loading = false;
        });
    };
    CalendarsComponent.prototype.onClickItem = function (id, type) {
        var link;
        if (type === "record") {
            link = ['/record-view/' + id];
            this.router.navigate(link);
        }
        else if (type === "range") {
            link = ['/range-view/' + id];
            this.router.navigate(link);
        }
    };
    CalendarsComponent.prototype.beforeMonthViewRender = function (_a) {
        var _this = this;
        var body = _a.body;
        body.forEach(function (cell) {
            var groups = {};
            cell['energy'] = '-';
            cell['steps'] = '-';
            cell['avg_heartrate'] = '-';
            cell['totalTime'] = '-';
            cell.events.sort(function (a, b) { return (a.start_unix > b.start_unix) ? 1 : ((b.start_unix > a.start_unix) ? -1 : 0); });
            if (cell.events.length > 0) {
                var start = 256 * moment(cell.date).unix();
                var end = start + 24 * 60 * 60 * 256;
                _this.apiService.getQuery(ApiQuery.metrics_for_user_id__start__end, {
                    metric_ids: "149,71,44,22",
                    start: start,
                    end: end,
                    user_id: _this.userId
                })
                    .subscribe(function (data) {
                    cell['energy'] = Math.round(data.metrics[0].value);
                    cell['steps'] = Math.round(data.metrics[1].value);
                    cell['avg_heartrate'] = Math.round(data.metrics[2].value);
                    cell['totalTime'] = HxToolsTime.second2HMInt(data.metrics[3].value);
                });
            }
            cell.events.forEach(function (event) {
                groups['all'] = groups['all'] || [];
                groups['all'].push(event);
                groups[event.meta.type] = groups[event.meta.type] || [];
                groups[event.meta.type].push(event);
            });
            cell['eventGroups'] = new Array();
            cell['eventGroups'] = groups;
        });
    };
    return CalendarsComponent;
}(HelperComponent));
export { CalendarsComponent };
