import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../core/services';
import { TopMenuService } from "../shared/components/top-menu/top-menu.service";
import { GlobalVariables } from '../core/services/global.variables';

@Component({
    selector: 'app-entry',
    template: ``,
    styleUrls: []
})
export class EntryComponent implements OnInit {

    access_token: string;
    token_type: string;
    state: string;
    expires_in: number;
    scope: string;

    constructor(
        private authenticationService: AuthenticationService,
        private globalVariables: GlobalVariables,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private topMenuService: TopMenuService) {
    }

    ngOnInit() {
        const routeFragment: Observable<string> = this.activatedRoute.fragment;
        routeFragment.subscribe(fragment => {
            if (fragment) {
                let parameters = fragment.split('&');
                for (let param of parameters) {
                    const [name, val] = param.split('=');
                    this[name] = val;
                }
                if (this.access_token && this.token_type && this.state && this.expires_in && this.scope) {
                    this.globalVariables.set("access_token", this.access_token);
                    this.globalVariables.set("token_type", this.token_type);
                    this.authenticationService.retrieveAccount().subscribe(data => {
                        let user = data['objects'][0];
                        this.globalVariables.set("currentUserId", user.id);
                        this.globalVariables.set("currentUser", JSON.stringify(user));
                        this.globalVariables.set("profileUri", user.profile.resource_uri);
                        this.globalVariables.set("currentSubject", JSON.stringify(user));
                        this.globalVariables.set("currentSubjectId", user.id);
                        this.topMenuService.showNavBar(true);
                        this.router.navigate(['/']);
                    });
                } else {
                    this.router.navigate(['/login']);
                }
            } else {
                this.router.navigate(['/timeline']);
            }
        });
    }
}



