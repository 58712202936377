import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { isPresent, isDate } from './utils';

/**
 * Check if the control value is BEFORE tha date in parameter
 */
export const dateBefore = (dateBefore: any): ValidatorFn => {
  if (!isDate(dateBefore) && !(dateBefore instanceof Function)) {
    throw Error('dateBefore value must be or return a formatted date');
  }

  return (control: AbstractControl): {[key: string]: any} => {
    if (isPresent(Validators.required(control))) return null;

    let d: Date = new Date(control.value);

    if (!isDate(d)) return {dateBefore: true};
    if (dateBefore instanceof Function) dateBefore = dateBefore();

    return d <= new Date(dateBefore) ? null : {dateBefore: true};
  };
};

/**
 * Check if the control value is AFTER tha date in parameter
 */
export const dateAfter = (dateAfter: any): ValidatorFn => {

  if (!isDate(dateAfter) && !(dateAfter instanceof Function)) {
    throw Error('dateAfter value must be or return a formatted date');
  }

  return (control: AbstractControl): {[key: string]: any} => {
    if (isPresent(Validators.required(control))) return null;

    let d: Date = new Date(control.value);

    if (!isDate(d)) return {dateAfter: true};
    if (dateAfter instanceof Function) dateAfter = dateAfter();

    return d >= new Date(dateAfter) ? null : {dateAfter: true};
  };
};