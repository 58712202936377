import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { Record } from '../../models';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { NotifierService } from 'angular-notifier';
/**
 * Records represent uploaded data.
 * When a user syncs her device a record is created to link together all the associated data that is recorded for that session.
 */
@Injectable()
export class RecordService extends HttpService {

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }

    /**
     * Retrieve all the info for a given record
     */
    get(id: number, datatypes?: Array<number>): Observable<Record> {
        let _datatypes = '';
        if (datatypes) {
            _datatypes = '?include_metrics=';
            let i = 0;
            for (const datatype of datatypes) {
                _datatypes += datatype;
                if (i < datatypes.length - 1) { _datatypes += ','; }
                i++;
            }
        }
        const query = this.API_URL + 'api/' + 'record/' + id + '/' + _datatypes;
        return this._get(query);
    }

    /**
     * Delete a record
     */
    delete(id, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + "record/" + id + "/";
        return this._delete(query, keyToClear);
    }

    /**
     * Download the TCX, CSV or FIT format file of a record
     */
    getFile(id, format?: string): Observable<any> {
        if (!format) format = 'text/csv';
        const options = this.generateHeader(format);
        return this.http.get(this.API_URL + 'api/' + 'data/?record=' + id + '&datatype__in=19,33', { 'headers': options });
        // .map(response => { return response; });
    }


    getFileWithDatatype(id, datatype__in: string, format?: string): Observable<any> {
        if (!format) format = 'text/csv';
        const options = this.generateHeader(format);

        return this.http.get(this.API_URL + 'api/' + 'data/?record=' + id + '&datatype__in=' + datatype__in, { 'headers': options });
        // .map(response => { return response; });
    }


    update(id, data): Observable<any> {
        const options = this.generateHeader();
        return this.http.patch(this.API_URL + 'api/' + 'record/' + id + '/', data, { 'headers': options })
            .map((response: Response) => {

            })
            .catch(error => { console.error(error); 
                return Observable.throw(error.json());
            });
    }

    /**
     * Retrieve all the records the logged user can access to
     * If username is provided, the records are filtered by username
     *
     * All the params are optional
     *
     * @param {number} limit
     *  maximum number of records in the response.
     * @param {number} offset
     * @param {string} username
     *  (optional) username to filter the records
     * @param {string} order_by
     *  (optional) field by wich the records are sorted
     * @param {string} direction
     *  (optional) ascending or descending order
     * @param {number} start
     *  (optional) filter by range, start of the range
     * @param {number} end
     *  (optional) filter by range, end of the range
     * @param {number} duration
     *  (optional) filter by minimal duration
     *
     * @return
     *  List of records
     */
    fetch(limit?: number, offset?: number, username?: string, order_by?: string,
        direction?: string, start?: number, end?: number, label?: string, duration?: number): Observable<any> {

        if (!limit) { limit = 0; }
        if (!offset) { offset = 0; }

        // Filter by username
        let filter_by_username = '';
        if (username) { filter_by_username = '&user=' + username; }

        // Descending or ascending order
        let direction_q = '';
        if (direction) {
            direction_q = direction;
        }

        // Sorting
        let order_by_q = '';
        if (order_by) {
            let field = '';
            if (order_by === 'user') {
                field = '__username';
            }
            order_by_q = '&order_by=' + direction_q + order_by + field;
        }

        // time range only if start and end are provided
        let timerange = '';
        if (start && end) { timerange = '&start__lte=' + end + '&end__gte=' + start; }

        // Labels. Exist in HX ? TODO
        let _label = '';
        if (label) { _label = '&labels=' + label; }

        // Filter by duration. All records longer than the given duration
        let _duration = '';
        if (duration) { _duration = '&duration__gte=' + duration; }

        const query = this.API_URL + 'api/' + 'record/?minimal=1&limit=' + limit + '&include_metrics=44,12,149,71,1032&offset=' +
            offset + filter_by_username + order_by_q + timerange + _label + _duration;

        return this._get(query);
    }

    /**
     * Retrieve the next record for the usedId or any by default.
     *
     * @param {number} hxtime
     * @param {number} userId
     *
     * @return
     *  A record
     */
    getNext(hxtime, userId?: number) {
        let user = '';
        if (userId) { user = '&user=' + userId; }
        const query = this.API_URL + 'api/' + 'record/?limit=1' + user + '&order_by=start&start__gte=' + hxtime;
        return this._get(query);
    }


    /**
     * Retrieve the previous record for the usedId or any by default.
     *
     * @param {number} hxtime
     * @param {number} userId
     *
     * @return
     *  A record
     */
    getPrevious(hxtime: number, userId?: number) {
        let user = '';
        if (userId) { user = '&user=' + userId; }
        const query = this.API_URL + 'api/' + 'record/?limit=1' + user + '&order_by=-start&end__lte=' + hxtime;
        return this._get(query);
    }


    /**
     * Retrieve all the records in from a time period (start, end)
     *
     * @param {number} start
     * @param {number} end
     * @param {number} limit
     * @param {number} offset
     * @param {number} userId
     *
     * @return
     *  List of records
     */
    fetchPeriod(start: number, end: number, limit?: number, offset?: number, userId?: number): Observable<any> {
        if (!limit) { limit = 0; }
        if (!offset) { offset = 0; }
        let filterByUserId = '';
        if (userId) { filterByUserId = '&user=' + userId; }
        const query = this.API_URL + 'api/' +
            'record/?minimal=1&limit=' + limit + '&offset=' + offset +
            '&duration__gte=60' + '&start__lte=' + Math.ceil(end) + '&end__gte=' +
            Math.floor(start) + filterByUserId;
        return this._get(query);
    }

    /**
     * Retrieve the N last records
     *
     * @param {number} records
     * @param {number} offset
     * @param {number} userId
     *
     * @return
     *  List of N records
     */
    fetchNLast(limit: number, offset: number, userId: number): Observable<any> {
        const query = this.API_URL + 'api/' + 'record/?minimal=1&limit=' +
            limit + '&offset=' + offset + '&order_by=-start&duration__gte=60&user=' + userId;
        return this._get(query);
    }

    public downloadResource(id, datatype__in: string, format?: string): Observable<any> {
        const options = this.generateHeader(format);
        let url = this.API_URL + "api/" + "data/?record=" + id;
        if (format === 'text/csv') {
            url += "&datatype__in=" + datatype__in;
        }
        return this.http.get<Blob>(url, { headers: options, responseType: 'blob' as 'json' });
    }

}
