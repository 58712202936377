/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./entry.component";
import * as i2 from "../core/services/authentication.service";
import * as i3 from "../core/services/global.variables";
import * as i4 from "@angular/router";
import * as i5 from "../shared/components/top-menu/top-menu.service";
var styles_EntryComponent = [];
var RenderType_EntryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntryComponent, data: {} });
export { RenderType_EntryComponent as RenderType_EntryComponent };
export function View_EntryComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_EntryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-entry", [], null, null, null, View_EntryComponent_0, RenderType_EntryComponent)), i0.ɵdid(1, 114688, null, 0, i1.EntryComponent, [i2.AuthenticationService, i3.GlobalVariables, i4.ActivatedRoute, i4.Router, i5.TopMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntryComponentNgFactory = i0.ɵccf("app-entry", i1.EntryComponent, View_EntryComponent_Host_0, {}, {}, []);
export { EntryComponentNgFactory as EntryComponentNgFactory };
