<div class="hx-ui-dialog" [style.display]="visible ? 'block' : 'none'" [style.width.px]="width" [style.height.px]="height"
    (mousedown)="moveOnTop()">

    <div class="hx-ui-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top">
        <span class="hx-ui-dialog-title" *ngIf="header">{{header}}</span>
        <a *ngIf="closable" [ngClass]="{'hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all':true}" href="#" role="button"
            (click)="hide($event)">
            <span class="hx-icon hx-icon-times"></span>
            </a>
    </div>


    <div class="hx-ui-dialog-content hx-ui-widget-content">
        <div class="hx-form hx-form-aligned">


            <div class="blockquote">
                    To give someone access to your data, you can add them as an Admin by entering this person’s email (valid Hexoskin account). As an  Admin, this person will be able to view and edit your data. You can revoke this privilege at any time.
            </div>

            <div class="formErrors" *ngIf="emailField.errors">
                <span *ngIf="emailField.errors && emailField.errors.pattern">
                Email is invalid
                </span>
            </div>


            <div class="input_with_appended_unit">
                <label>Admin Email *</label>
                <input type="text" [(ngModel)]="email" #emailField="ngModel" size="30"
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" >
            </div>

            <div class="formNotes">
                * mandatory fields
            </div>

        </div>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <ng-content select="p-footer"></ng-content>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <button type="button" class="button" (click)="reject()" *ngIf="rejectVisible">
            <i class="hx-icon hx-icon-times"></i> Cancel
        </button>
        <button type="button" class="button" (click)="accept()" *ngIf="acceptVisible" [disabled]="!email || emailField.errors && emailField.errors.pattern">
            <i class="hx-icon hx-icon-check"></i> Add
        </button>
    </div>

    </div>