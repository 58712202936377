<article>
    <header>
        <h3>Hexoskin Terms of Use</h3>
    </header>

    <p>
        Carré Technologies Inc. and its affiliates and partners ("Hexoskin," "we" or "us") provide online and mobile services, including,
        but not limited to the Hexoskin website ("Hexoskin.com" or the "Site"), electronic devices, computer and mobile applications
        hosted by or on behalf of Carré Technologies (collectively, the "Hexoskin Services") intended to enhance a consumer's
        use of the personal fitness and body monitoring electronic products offered by Hexoskin (the "Hexoskin Products").
    </p>

    <p>
        THIS IS A LEGAL AGREEMENT BETWEEN YOU AND CARRÉ TECHNOLOGIES INC. AND GOVERNS YOUR USE OF THE HEXOSKIN SERVICES. Subject
        to your acceptance of and adherence to these <a href="/terms">Terms of Use</a> (the "Terms of Use"), Hexoskin
        hereby grants you a limited, non-exclusive license to utilize the Hexoskin Services as set forth herein. The terms
        "you" and "user" shall refer to all individuals and entities that access the Hexoskin Services.
    </p>

    <p>
        By your affirmative actions of registering for and/or using the Hexoskin Services, you signify your agreement to these <a
            href="/terms">Terms of Use</a> and our <a href="/privacy">Privacy Policy</a> and consent to allow Hexoskin
        to communicate with you electronically regarding the Hexoskin Services and Products.
    </p>

    <h4><strong>Modifications to the Terms of Use</strong></h4>

    <p>
        We may modify the <a href="/terms">Terms of Use</a> from time to time. A link to the most recent <a href="/terms">Terms of Use</a>        is available on the Hexoskin web site, and we will indicate the date of the latest modification at the bottom of
        the <a href="/terms">Terms of Use</a>. We may also communicate with you by email or through the Hexoskin Services
        when significant changes are made to this document. Your continued use of the Hexoskin Services following such changes
        constitutes your acceptance of the Amended <a href="/terms">Terms of Use</a>.
    </p>

    <h4><strong>Requirements for Use of the Hexoskin Services</strong></h4>

    <p>
        Full use of the Hexoskin Services requires compatible Hexoskin Garment and Hexoskin Device, a mobile device with Internet
        access, and certain software. The software may require periodic updates to maintain the availability of Hexoskin
        Services. The use of the Hexoskin Services may be affected by the performance of the Internet connection, the mobile
        device, or other third party accessories, software and devices. The Hexoskin Services is not a guaranteed part of
        any third party product or service offering, and no purchase or obtaining of any third party product or service shall
        be construed to represent or guarantee you access to the Hexoskin Services.
    </p>

    <p>
        Please see the section entitled "Precautions" below for recommended precautions related to your use of the Hexoskin Products
        and the Hexoskin Services.
    </p>

    <h4><strong>Age Requirements</strong></h4>

    <p>
        The Hexoskin Services are available for individuals aged 13 years or older. If you are 13 or older but under the age of 18,
        you should review this Agreement with your parent or guardian to make sure that you and your parent or guardian understand
        it.
    </p>

    <h4><strong>Agency and Intended Use</strong></h4>

    <p>
        You represent that you are using the Hexoskin Services for its intended purpose. If you are registering or using the Hexoskin
        Services on behalf of a company or other organization, you represent that you have authority to bind your employer
        by your actions.
    </p>

    <h4><strong>Not a Medical Device</strong></h4>

    <p>Hexoskin Products and Services are not intended, and should not be used to investigate, diagnose, monitor, treat or prevent
        disease or other medical conditions nor to provide information for medical or diagnostic purposes.</p>

    <p>
        Before starting any exercise program, you should ask your physician for a complete physical examination. You should warm-up
        and stretch correctly before beginning any exercise. Stop exercising immediately if you feel pain, dizziness, dehydration,
        or exhaustion. By exercising, you assume the risks inherent in physical exercise, including any injury that may result
        from such activity.
    </p>

    <p>
        Keep away from children. Do not attempt to open the product, disassemble it. Do not remove or attempt to remove the battery;
        no user serviceable parts are inside; attempt to do so voids the products' warranty (reference). Do not use the product
        if it is damaged. Do not use near pacemakers, or near other critical healthcare equipement. Do not use during medical
        exam. Do not use near a magnetic resonance imaging (MRI) devices or X-Ray devices. Carré Technologies is not responsible
        for uses that are not approved and for which the device is not certified. <strong>Consult the Important <a href="http://www.hexoskin.com/pages/safety">Safety and Product Information guide</a>    on our website for additional product warnings and supplemental information concerning the use and operation of this product.</strong>
    </p>

    <h4><strong>Precautions</strong></h4>

    <p>Content found on Hexoskin.com or through the Hexoskin Services is for informational purposes only and is not intended
        to replace the relationship between you and your physician or other medical provider. We are not a licensed medical
        care provider and have no expertise in diagnosing or treating medical conditions of any kind, or in determining the
        effect of any specific exercise on a medical condition.</p>

    <h4><strong>Warning about Physical Activity</strong></h4>

    <p>
        You should always consult a physician before starting a fitness program, changing your diet or if you have any questions
        regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something
        you have read on or through the Hexoskin Services. You should consult with your physician before following any training
        instructions you receive through the Hexoskin Services or participating in any event announced on or through the
        Hexoskin Services. Not all exercises or activities described on the Hexoskin Services are suitable for everyone.
        You should understand that when participating in any exercise or exercise program, there is the possibility of physical
        injury and/or death. If you think you may have a medical emergency, call your physician or 911 immediately. If you
        feel discomfort or pain, immediately stop the activity causing such discomfort or pain. People who have Type 1 diabetes
        or other adverse medical or nutritional conditions should not use the Hexoskin Services unless such use is directed
        and closely monitored by a physician. By using the Hexoskin Services, you represent that you have received consent
        from your physician to participate in the programs, workouts, and exercises described on the Hexoskin Services.
    </p>

    <p>
        We are not responsible for any health problems that may result from training programs, products, or events you learn about
        through the Hexoskin Services. If you engage in any exercise program you receive or learn about through the Hexoskin
        Services you agree that you do so at your own risk and are voluntarily participating in these activities.
    </p>

    <h4><strong>Information on the Hexoskin Services</strong></h4>

    <p>
        We try to provide helpful and accurate information on the Hexoskin Services, but we make no endorsement, representation or
        warranty of any kind about any information, services or recommendations made available through the Hexoskin Services.
        We are not responsible for the accuracy, reliability, effectiveness, or correct use of information you receive through
        the Hexoskin Services. Reliance on any information provided by Hexoskin, Hexoskin employees, or others appearing
        on or contributing content to the Hexoskin Services, you do so solely at your own risk.
    </p>

    <h4><strong>Your Privacy Settings</strong></h4>

    <p>
        Through the Hexoskin Services, we enable you, if you wish, to share information with others, as this is an important motivator
        to achieve fitness goals. You can use the privacy settings within the Hexoskin Services to control which information
        about you is shared with others.
    </p>

    <h4>User-Generated Content</h4>

    <p>
        Subject to Hexoskin's adherence with the privacy settings you select within the Hexoskin Services, you hereby grant to Hexoskin
        a perpetual, irrevocable, non-exclusive, worldwide, royalty-free license, with the right to sublicense, to reproduce,
        distribute, transmit, publicly perform, publicly display, digitally perform, modify, create derivative works of,
        and otherwise use and commercially exploit any text, photographs or other data and information you submit to the
        Hexoskin Services (collectively, "User Generated Content") in any media now existing or hereafter developed, including
        without limitation on websites, in audio format, and in any print media format. "User Generated Content" includes
        but is not limited to content posted on message board posts, blogs, journals, body metrics, and user comments. Subject
        to Hexoskin's adherence to the privacy settings you select within the Hexoskin Services, you hereby waive any rights
        of publicity and privacy with respect to the User Generated Content and any other legal or moral rights that might
        preclude Hexoskin's use of the User Generated Content or require your permission for Hexoskin to use the User Generated
        Content. You agree not to assert any claim, whether based on tort, contract, or other legal theory, against Hexoskin
        or its sublicensees relating to Hexoskin's or its sublicensees' use of the User Generated Content in accordance with
        your privacy settings, and you hereby release Hexoskin and its sublicensees from any such claims. You represent that
        your User Generated Content: (1) will not infringe any third party's copyright, patent, trademark, trade secret or
        other proprietary rights; (2) will not violate any law, statute, ordinance or regulation; (3) will not be obscene
        or contain child pornography; (4) will not contain any viruses, worms, time bombs or other computer programming code
        that is intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data
        or information; (5) will not violate any third party's rights of publicity or privacy; and (6) will not be defamatory,
        unlawfully threatening or harassing, harmful to minors in any way, or otherwise offensive or inappropriate. You are
        responsible for complying with all laws applicable to your User Generated Content.
    </p>

    <h4><strong>Registration and Password; Privacy Policy</strong></h4>

    <p>You are responsible for maintaining the confidentiality of your login information and password. You shall be responsible
        for all uses of your login information, whether or not authorized by you. You agree to immediately notify us of any
        unauthorized use of your login information or password. Hexoskin cannot and does not assume any responsibility or
        liability for any information submitted by someone who has fraudulently accessed your Hexoskin account or for any
        third party's fraudulent use or misuse of information submitted by you.</p>

    <p>You represent that the information that you provide about yourself as requested in the Hexoskin account registration
        form (the "Registration Data") is accurate and complete, and you agree to update your account information, as necessary,
        including providing Hexoskin with your current email address, so that you may receive notifications and other account-related
        communications.</p>

    <p>The Hexoskin Services are subject to the Hexoskin <a href="/privacy">Privacy Policy</a>.</p>

    <h4><strong>Ownership of the Hexoskin Services</strong></h4>

    <p>Except for the User Generated Content, the Hexoskin Services and all aspects thereof, including all copyrights, trademarks,
        and other intellectual property rights therein, are owned by Hexoskin or its licensors. You acknowledge that the
        Hexoskin Services and any underlying technology used in connection with the Hexoskin Services contain Hexoskin's
        intellectual property and proprietary information. You may not reproduce, distribute, modify or create derivative
        works of, publicly display (including by framing any content), or commercially exploit any part of the Hexoskin Services
        except as necessary to view the content therein and to create a reasonable number of copies of portions of such content
        (without modification) for your personal, non-commercial use. All rights in the Hexoskin Services not expressly granted
        to you by Hexoskin are retained by Hexoskin and its licensors.</p>

    <p>If you violate any of these <a href="/terms">Terms of Use</a>, your permission to use the Hexoskin Services automatically
        terminates and you must immediately destroy any copies you have made of any portion of the Hexoskin Services.</p>

    <h4><strong>Email Rules</strong></h4>

    <p>Some of our users will make their email addresses available if they wish to converse with other users with similar interests
        or goals. If you use this information, you agree not to transmit "spam" or other unwanted solicitations, or any material
        that is harassing, threatening, offensive, obscene, abusive, harmful, defamatory, invasive of another's privacy,
        infringing of another's intellectual property rights, or illegal. Users found using email information in this manner
        may have their user accounts terminated. We are not responsible for the content of email messages sent to you by
        our users or from third parties.</p>

    <h4><strong>Copyrighted Materials</strong></h4>

    <p>You agree that any materials uploaded by you does not infringe or violate the rights of any other party or violate any
        laws, contribute to or encourage infringing or otherwise unlawful conduct, or otherwise be obscene, objectionable,
        or in poor taste. You also agree that you have obtained all necessary rights and licenses required to allow the posting
        of any content posted by You. Hexoskin has the right to remove any material if it does not meet these standards or
        for any other reason Hexoskin deems appropriate.</p>

    <p>If you feel that any content uploaded on the Hexoskin Services infringes your copyrights or other intellectual property
        rights, please notify us by sending an email to our support.</p>

    <h4><strong>User Indemnification Of Hexoskin</strong></h4>

    <p>Upon request by us, you agree to defend, indemnify and hold Hexoskin and its partners, affiliates, service providers,
        licensors, officers, directors, employees and agents harmless from and against any and all losses, liabilities, damages
        and costs, including but not limited to reasonable legal and accounting fees, arising from any claims, actions or
        demands related to or alleged to relate to: (a) your use of the Hexoskin Services, including any User Generated Content
        you post, store, reproduce, display, or distribute via the Hexoskin Services; (b) your violation of the <a href="/terms">Terms of Use</a>;
        or (c) your violation of any intellectual property or other third party rights or any applicable law in connection
        with your use of the Hexoskin Services. We reserve the right to assume control of the defense of any third party
        claim that is subject to indemnification by you, in which event you will cooperate with us in asserting and available
        defenses.</p>

    <h4><strong>User Conduct</strong></h4>

    <p>If we determine that any user is not using the Hexoskin Services responsibly, we have the right (but not the obligation)
        to remove, edit, block, or delete such user's transmissions, User Generated Content, or use of the Hexoskin Services.
        We also have the right (but not the obligation) to suspend or terminate your access to other parts of the Hexoskin
        Services, or delete information you have uploaded to the Hexoskin Services, without prior notice if you engage in
        any conduct that we believe, in our sole discretion: (a) violates any provision of the Terms of Use, (b) is offensive
        to other users, (c) violates the rights of Hexoskin or third parties, or (d) could cause Hexoskin liability. Without
        limiting the foregoing, upon any termination of your account or access to the Hexoskin Services we may delete all
        User Generated Content and other information. If Hexoskin suspects that your Registration Data is inaccurate or incomplete,
        we may suspend or terminate your account.</p>

    <h4><strong>Changes to Hexoskin Services</strong></h4>

    <p>Hexoskin may change or discontinue, temporarily or permanently, any feature or component of the Hexoskin Services at
        any time without further notice. In addition, we may, without notice to the applicable user, terminate any account
        that have been inactive for more than 45 days, but have no obligation to do so. You agree that Hexoskin shall not
        be liable to you or to any third party for any modification, suspension or discontinuance of any feature or component
        of the Hexoskin Services.</p>

    <h4><strong>Third Party Services</strong></h4>

    <p>The Hexoskin Services may provide links or references to websites and services operated by third parties. We do not monitor
        or investigate such websites and services and we are not responsible for their content, functionality, or practices.
        Inclusion of links to third party websites on our Hexoskin Services does not imply approval or endorsement of the
        linked website by us. If you decide to access these third party websites and services, you do so at your own risk.
        Hexoskin suggests that you read the <a href="/terms">Terms of Use</a> and privacy policies (if any) on those third
        party websites. You agree that Hexoskin has no liability for any damage or loss of any type that is a result of your
        use of a third party website.</p>

    <h4><strong>Content And Warranty Disclaimer</strong></h4>

    <p>THE WEBSITE AND SERVICES ARE PROVIDED BY HEXOSKIN AND ITS AFFILIATES "AS IS." NEITHER HEXOSKIN NOR ITS PARTNERS, SUPPLIERS,
        OR AFFILIATES MAKE ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE
        WEBSITE, ITS CONTENTS, OR ANY INFORMATION MADE AVAILABLE BY OR THROUGH THE WEBSITE. IN ADDITION, HEXOSKIN AND ITS
        PARTNERS DISCLAIM ALL WARRANTIES WITH RESPECT TO THE WEBSITE, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. FURTHERMORE,
        HEXOSKIN DOES NOT WARRANT THAT USE OF THE WEBSITE WILL BE UNINTERRUPTED, AVAILABLE AT ANY TIME OR FROM ANY PARTICULAR
        LOCATION, SECURE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE IS FREE OF VIRUSES OR OTHER POTENTIALLY
        HARMFUL COMPONENTS.</p>

    <h4><strong>Limitation of Liability</strong></h4>

    <p>IN NO EVENT WILL HEXOSKIN BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT
        LIMITATION THOSE RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE, INABILITY
        TO USE, OR THE RESULTS OF USE OF THE WEBSITE, WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER
        LEGAL THEORY AND WHETHER OR NOT HEXOSKIN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE AGGREGATE LIABILITY
        OF HEXOSKIN, ARISING FROM OR RELATING TO THE WEBSITE (REGARDLESS OF THE FORM OF ACTION OR CLAIM, E.G. CONTRACT, WARRANTY,
        TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL THEORY) IS LIMITED TO $100. HEXOSKIN'S AFFILIATES, PROVIDERS
        AND PARTNERS HAVE NO LIABILITY WHATSOEVER ARISING FROM THE WEBSITE. In some locations applicable law may not allow
        certain of the limitations described above, in which case such limitations may not apply to you.</p>

    <h4><strong>General</strong></h4>

    <p>The <a href="/terms">Terms of Use</a> and the resolution of any dispute related to the Terms of Use or the Hexoskin
        Services shall be governed by and construed in accordance with the laws of the Province of Quebec without respect
        to its conflict of laws principles. You shall bring any legal action or proceeding against Hexoskin related to the
        Hexoskin Services exclusively in a federal or state court of competent jurisdiction sitting in Montreal, in the province
        of Quebec, and you agree to submit to the personal and exclusive jurisdiction of such courts. If any provision(s)
        of the Terms of Use is found to be contrary to law, then such provision(s) will be construed, as nearly as possible,
        to reflect the intentions of the parties with the other provisions remaining in full force and effect. Hexoskin's
        failure to exercise or enforce any right or provision of the <a href="/terms">Terms of Use</a> will not constitute
        a waiver of such right or provision. These terms and conditions constitute the entire agreement between you and Hexoskin
        with respect to the subject matter herein and supersede any and all prior or contemporaneous oral or written agreements.
        You may not assign this agreement to any other party and any attempt to do so is void.</p>

    <h4><strong>Terms Specifically Related to Our Paid Service</strong></h4>

    <p>
        For users who have elected to purchase a paid subscription to the Services the following additional terms and conditions
        shall apply:
    </p>

    <p><strong>1. Description of the Services.</strong></p>

    <p>
        If you are signing up for Hexoskin Services, for your subscription fee, Hexoskin hereby grants to you a license to use the
        Services you subscribed for.
    </p>

    <p><strong>2. Payments.</strong></p>

    <p>
        You agree to pay Hexoskin the fees for the Services for the duration of time and the amount you selected on the sign up page.
        You permit Hexoskin to charge this fee to your credit card and automatically renew the charge for the duration of
        your membership until you cancel. We use a third party credit card payment processor to process all payments.
    </p>

    <p><strong>3. Automatic Renewal and Cancellation.</strong></p>

    <p>
        Membership fees will be charged to your credit card and will automatically renew at the price then in effect for the duration
        of your membership until you cancel. Yearly memberships will automatically renew once a year. Cancellation of your
        membership can be done using the Hexoskin Services, or by sending us an email with the following information: username,
        email address, phone number, billing address, reason for cancellation. If you choose to cancel, your cancellation
        will take effect at the end of the term in which you cancelled. If your credit card is invalid for any reason, charged
        back, or if Hexoskin does not receive payment, we will cancel your member benefits immediately.
    </p>

    <p><strong>4. Term.</strong></p>

    <p>
        Unless terminated earlier, this Agreement will remain in force as long as you are a paid subscriber to the Services. If you
        terminate the Service, but continue to be a member of Hexoskin, the <a href="/terms">Terms of Use</a>        and <a href="/privacy">Privacy Policy</a> will still apply.
    </p>

    <p><strong>5. Refund Policy and Termination.</strong></p>

    <p>
        If Hexoskin cancels the entire Service, you may request a subscription refund in writing within 30 days after the cancellation
        and receive a refund for the prorated amount of the fees for the Service. If Hexoskin makes a material adverse change
        to the Service or to the terms of this Agreement, you may terminate this Service and request a refund in writing
        within 15 days of such change and Hexoskin will refund the prorated amount of the fees for the Service. If you cancel
        within 30 days of joining or renewal, you are eligible, upon request, for a full refund. Termination of your paid
        Service account and the refund rights above are your sole remedies under this Agreement and against Hexoskin in connection
        with Hexoskin's provision of the paid Service. Because Hexoskin reserves the right to alter or discontinue all or
        any portion of the paid Service or the Site, you should be sure to retain a copy of any materials you post on the
        Site and your User data, which can be exported into an Excel or CSV file.
    </p>

    <p><strong>6. Representations, Warranties and Covenants.</strong></p>

    <p>
        You represent, warrant and covenant that (a) the membership and credit card information that you supply is true, correct
        and complete, (b) you will pay any charges that you incur in connection with the Service, including any applicable
        taxes, (c) you will update your membership and credit card information as required, (d) you will not allow anyone
        else to use your membership, (e) you will not transfer your membership or password to anyone else and (f) you will
        report to us any unauthorized or prohibited use of your membership or this Site.
    </p>

    <p><strong>7. Amendment.</strong></p>

    <p>
        Subject to the termination and refund rights above, you agree that Hexoskin reserves the right to amend the paid Service
        and the terms associated therewith at any time and for any reason. Any changes may be posted to the Site or sent
        to you by email or postal mail.
    </p>

    <p>
        The PARTIES declare that they have requested that this Agreement and all accessory documents be drafted in English.
    </p>

    <p>
        Les parties aux présentes déclarent qu’elles ont exigé que le présent contrat et tout accessoire y afférant soient rédigés
        en anglais.
    </p>

    <p>Date of last update: October 24th, 2013</p>

    <p><strong>Headquarters Address</strong></p>

    <address>
        Hexoskin (Carré Technologies Inc.)<br /> 5800, rue Saint-Denis, suite 402A <br /> Montreal (Quebec) <br /> CANADA
        <br /> H2S 3L5 <br />
        <i class="icon-phone"></i> 1-888-887-2044 </address>
</article>