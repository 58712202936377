<div class="big_title">
    <div *ngIf="user && user.first_name != '';then firstname else nofirstname"></div>
    <ng-template #firstname>{{ user.first_name }}'s Health Status</ng-template>
    <ng-template #nofirstname>Health Status</ng-template>
</div>

<app-alert></app-alert>

<section #timerangeselector id="timerangeselector">
    <div class="hx-g header">
        <div class="hx-u-23-24">
            <div class="title">
                Time Range Selector
            </div>
        </div>
        <div class="hx-u-1-24">
            <img (click)="openModal('timerangeselector')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
        </div>
    </div>
    <div class="hx-range-selector">
        <duration-picker *ngIf="!loading" [inputDisabled]=false returnedValueUnit="hour" (onNumberChanged)="onNumberChanged($event)"
            class="hx-g">
            <div class="hx-u-5-24">
                <div class="hx-duration-picker margin-right">
                    <app-duration-picker-unit [name]="'day'" [value]="defaultRange.day" step=1></app-duration-picker-unit>
                </div>
            </div>
            <div class="hx-u-5-24">
                <div class="hx-duration-picker margin-right">
                    <app-duration-picker-unit [name]="'week'" [value]="defaultRange.week" step=1></app-duration-picker-unit>
                </div>
            </div>
            <div class="hx-u-5-24">
                <div class="hx-duration-picker margin-right">
                    <app-duration-picker-unit [name]="'month'" [value]="defaultRange.month" step=1></app-duration-picker-unit>
                </div>
            </div>
            <div class="hx-u-5-24">
                <div class="hx-duration-picker">
                    <app-duration-picker-unit [name]="'year'" [value]="defaultRange.year" step=1></app-duration-picker-unit>
                </div>
            </div>
            <div class="hx-u-4-24 hx-background-form">
                <div class="hx-pull-right">
                    <button class="button button-green" style="height:38px" (click)="updatePeriod()">Update</button>
                </div>
            </div>
            </duration-picker>
    </div>
</section>


<ng-container *ngIf="loading">
    <section>
        <div id="spinner">
            <div></div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!loading">

    <div dnd-sortable-container [sortableData]="kpis">

        <ng-container *ngFor="let _kpis of kpiSections; let i = index">
            <section [attr.id]="_kpis.name" [id]="_kpis.name" *ngIf="kpiSections[i].displayed" dnd-sortable [sortableIndex]="i" (onDropSuccess)="updateOrder()">
                <div class="hx-g header" style="cursor: move;">
                    <div class="hx-u-23-24">
                        <div class="title">
                            {{ _kpis.label }}
                        </div>
                    </div>
                    <div class="hx-u-1-24">
                        <img (click)="openModal(_kpis.name)" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
                        <!--i (click)="showCharts(i)" style="cursor:pointer;" class="hx-icon hx-icon-chevron-down chevron-caret"></i-->
                    </div>
                </div>
                <div class="hx-profile hx-panel">
                    <p *ngFor="let m of messages" [style.color]="m.color">
                        {{m.timestamp + ": "+ m.body }}
                    </p>

                    <div class="hx-g">
                        <div *ngFor="let kpi of _kpis.pkpis; let j= index;" class="hx-u-1-{{ _kpis.pkpis.length }}">
                            <metric-one-status *ngIf="kpi" (click)="displayChart(i, j)" [(title)]="kpi.title" [(name)]="kpi.name" origin="{{ kpi.origin }}"
                                [(statusColor)]="kpi.statusColor" [(colors)]="kpi.colors" [(displayStatus)]="kpi.displayStatus"
                                [(atRightPosition)]="j+1 === _kpis.kpis.length" [(writable)]="kpi.writable" [(linkActivity)]="kpi.linkActivity" [(value)]="kpi.value"
                                [(status)]="kpi.status" [(isSelected)]="kpi.selected" [(unit)]="kpi.unit" [(pattern)]="kpi.pattern"></metric-one-status>
                                <div *ngIf="!kpi" style="background-color: white; height: 100%; width: 100%"></div>
                                <!-- (click)="add(kpi.title, kpi.unit, kpi.name)" -->
                        </div>
                    </div>
                    <div class="hx-g">
                        <div *ngFor="let kpi of _kpis.pkpis; let j = index" class="hx-u-1-1 hx-under-chart">
                            <div *ngIf="!loading && kpi.displayed" class="hx-g">
                                <div [ngClass]="{ 'hx-u-3-4' : kpi.showDistribution || kpi.showExtra , 'hx-u-1' : !kpi.showDistribution && !kpi.showExtra }" style="margin-bottom:1px;">
                                    <div style="margin-right:1px;">
                                        <app-bchart *ngIf="values[kpi.name].length >= minimumNbrPointsForHistoric" [salt]="salt" [enableTitle]='false' [title]="kpi.title" [label]="kpi.name" [ids]="ids[kpi.name]"
                                            [values]="values[kpi.name]" [minX]="minTime" [maxX]="maxTime" type="line" [unit]="kpi.unit"
                                            [optionsAxis]="kpi.optionsAxis" [xAxis]="xAxis"></app-bchart>
                                            <div style="width:100%;height:200px;background-color: rgba(255,255,255,0.8);" *ngIf="values[kpi.name].length < minimumNbrPointsForHistoric">
                                                <div class="na">
                                                    No historical or N/A
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div *ngIf="kpi.showDistribution" class="hx-u-1-4">
                                    <app-bchart [markerEnabled]='false' [enabledToolip]='false' [salt]="salt" title="Population distribution" [ids]="ids[kpi.name]"
                                        [values]="distributions[kpi.name]" type="spline" [unit]="kpi.unit" [optionsAxis]="optionsAxisGaussian"
                                        [xAxis]="xAxisGaussian"></app-bchart>
                                </div>
                                <div *ngIf="kpi.showExtra" class="hx-u-1-4">
                                        <div class="hx-garment-size">
                                        <div class="title">Suggested Garment Size</div>
                                        <div *ngIf="suggested_size" class="size">
                                            {{ suggested_size }}
                                        </div>
                                        <div *ngIf="!suggested_size" class="tiny_size">
                                            Please contact the support
                                        </div>
                                </div>
                                </div>                                
                            </div>
                            <div *ngIf="explanations[kpi.name] && !loading && kpi && !showedExplanations[kpi.name] && kpi.displayed" style="background-color: white;">
                                <div class="show-explanations">
                                    <button class="button button-green" [disabled]="!explanations[kpi.name]" (click)="showExplanations(kpi.name)">Show explanations</button>
                                </div>
                            </div>
                            <div *ngIf="explanations[kpi.name] && !loading && kpi && showedExplanations[kpi.name] && kpi.displayed && explanations[kpi.name] !== false"
                                style="background-color: white;">
                                <div class="show-explanations">
                                    <button class="button button-green" *ngIf="showedExplanations[kpi.name]" (click)="showExplanations(kpi.name)">Hide explanations</button>
                                </div>
                        </div>
                        <div *ngIf="!loading && kpi && showedExplanations[kpi.name] && kpi.displayed && explanations[kpi.name] !== false">
                            <div class="panel-box panel-box-kpi-doc">
                                <div class="panel-box-body hx-kpi-explanation">
                                    <div class="pane pane-left scrollable-pane">
                                        <div [innerHTML]="explanations[kpi.name]">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>

    </section>
    </ng-container>
    </div>

    <popup icon="fa fa-question-circle" width="425"></popup>

    <confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>

</ng-container>

<app-growl [value]="msgs"></app-growl>


<help-modal id="timerangeselector-help" style="display: none;">
    <p>Use the "Time Range Selector" to narrow down your search for the summary of your health status information for a specific
        time range.</p>
</help-modal>


<help-modal id="cardiorespiratory_status-help" style="display: none;">
    <p>Cardiorespiratory data reported in this section can be used as a personal health indicator.<br>
    Move your cursor on the selected metric, then click on <img height="24" src="/assets/icons/pencil.svg"> to manually add a new value, if you wish.<br>
        The bell-shaped curve represents the population normal distribution for the selected metric. The point on the curve corresponds to your position in
        regards to the population’s value for that specific metric.<br>
        Click on “Show explanations” for more details on how to understand your data.
    </p>
</help-modal>

<help-modal id="measurements-help" style="display: none;">
    <p>Your anthropological data entered when creating the account. These data are important for the calculations of certain
        metrics on the Hexoskin system. Your Height and Weight can be modified over time if need be. Move your cursor on the selected metric, then click on <img height="24" src="/assets/icons/pencil.svg"> to manually add a new value. The BMI will be calculated
        accordingly.<br>
        The bell-shaped curve represents the population normal distribution for the selected measurement. The
        point on the curve corresponds to your position in regards to the population’s value for that specific measurement.<br>
        Click on "Show explanations" for more details on how to properly measure or weigh yourself.
    </p>
</help-modal>

<help-modal id="sleep-help" style="display: none;">
    <p>This section displays the most relevant sleep metrics calculated by Hexoskin to assess your health condition.<br>
        The bell-shaped curve represents the population normal distribution for the selected sleep metric. The point on the curve corresponds
        to your position in regards to the population’s value for that specific sleep metric.<br>
        Click on "Show explanations" for more details on how to understand your sleep data.
    </p>
</help-modal>