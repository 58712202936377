import { HxToolsDate } from './hx-tools-date';
import { HxToolsTime } from './hx-tools-time';

const DIURNAL_FIRST_HOUR = 0;
const DIURNAL_LAST_HOUR = 24;
const NOCTURAL_FIRST_HOUR = 18;
const NOCTURAL_LAST_HOUR = 24 + 12;

export class HxToolsTimeline {

    public static getParameters(range: any, record?: any) {


        if (record) {
            const startTimestamp = Math.round(record.start / 256);
            const endTimestamp = Math.round(record.end / 256);
            if (HxToolsTime.isOverNight(1000 * startTimestamp, 1000 * endTimestamp)) {
                return {
                    "time": "noctural",
                    "startHour": NOCTURAL_FIRST_HOUR,
                    "endHour": NOCTURAL_LAST_HOUR,
                    "startOfTheDay": HxToolsDate.getStartOfDay(startTimestamp) + NOCTURAL_FIRST_HOUR * 60 * 60,
                    "endOfTheDay": HxToolsDate.getStartOfDay(endTimestamp) + (NOCTURAL_LAST_HOUR - 24) * 60 * 60
                };
            } else {
                return {
                    "time": "diurnal",
                    "startHour": DIURNAL_FIRST_HOUR,
                    "endHour": DIURNAL_LAST_HOUR,
                    "startOfTheDay": HxToolsDate.getStartOfDay(startTimestamp),
                    "endOfTheDay": HxToolsDate.getEndOfDay(endTimestamp)
                };
            }
        } else {
            const startTimestamp = Math.round(range.start / 256);
            const endTimestamp = Math.round(range.end / 256);
            if (HxToolsTime.isOverNight(1000 * startTimestamp, 1000 * endTimestamp)) {
                return {
                    "time": "noctural",
                    "startHour": NOCTURAL_FIRST_HOUR,
                    "endHour": NOCTURAL_LAST_HOUR,
                    "startOfTheDay": HxToolsDate.getStartOfDay(startTimestamp) + NOCTURAL_FIRST_HOUR * 60 * 60,
                    "endOfTheDay": HxToolsDate.getStartOfDay(endTimestamp) + (NOCTURAL_LAST_HOUR - 24) * 60 * 60
                };
            } else {
                return {
                    "time": "diurnal",
                    "startHour": DIURNAL_FIRST_HOUR,
                    "endHour": DIURNAL_LAST_HOUR,
                    "startOfTheDay": HxToolsDate.getStartOfDay(startTimestamp),
                    "endOfTheDay": HxToolsDate.getEndOfDay(endTimestamp)
                };
            }
        }
    }
}