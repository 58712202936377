<div class="hx-menu hx-menu-horizontal hx-g">

    <div class="hx-u-5-24">
        <div style="padding: 12px 0 14px 16px;"><a [routerLink]="['/']"><img style="width:160px" src="assets/images/logo-hxdash-beta.svg" /></a>
        </div>
    </div>

    <div class="hx-u-19-24 menu">

        <nav role="menudd" top-menu>

            <select onchange="if (this.value) window.location.href = this.value;">
                <option value="/"></option>
                <option value="/timeline">Timeline</option>
                <option value="/calendars">Calendar</option>
                <option value="/fitness">Health Status</option>
                <option value="/relationships">Data Sharing</option>
                <option value="/logout">Logout</option>
            </select>

            <ul class="hx-menu-list">
                <li *ngIf="showNavBar" id="view1"><a [ngClass]="{'active': page === 'timeline'}" [routerLink]="['/timeline']"
                        (click)="page = 'timeline'" i18n>Timeline</a></li>
                <li *ngIf="showNavBar" id="view2"><a [ngClass]="{'active': page === 'calendars'}" [routerLink]="['/calendars']"
                        (click)="page = 'calendars'">Calendar</a></li>
                <li *ngIf="showNavBar" id="view5"><a [ngClass]="{'active': page === 'fitness'}" [routerLink]="['/fitness']"
                        (click)="page = 'fitness'">Health Status</a></li>
                <li *ngIf="showNavBar" id="view5"><a [ngClass]="{'active': page === 'ranges-table'}" [routerLink]="['/ranges-table']"
                        (click)="page = 'ranges-table'">Activities</a></li>
                <li *ngIf="showNavBar" id="view5"><a [ngClass]="{'active': page === 'records-table'}" [routerLink]="['/records-table']"
                        (click)="page = 'records-table'">Records</a></li>
                <li *ngIf="showNavBar && false" id="view6"><a [ngClass]="{'active': page === 'prescriptions-table'}"
                        [routerLink]="['/prescriptions-table']" (click)="page = 'prescriptions-table'">Programs</a></li>
                <li *ngIf="showNavBar" id="view6"><a [ngClass]="{'active': page === 'relationships'}" [routerLink]="['/relationships']"
                        (click)="page = 'relationships'">Data Sharing</a></li>
                <li *ngIf="showNavBar && athletes.length > 0" id="subject-list">
                    <a style="cursor:pointer;" (click)="athlete_menu = !athlete_menu; profile_menu = false; search_menu = false; box_menu = false">
                        {{ currentSubject?.first_name }} <i class="hx-icon hx-icon-chevron-down chevron-caret"></i>
                    </a>
                    <ul class="submenu" *ngIf="athlete_menu === true">
                        <li *ngFor="let athlete of athletes">
                            <a style="cursor:pointer;" *ngIf="currentSubject && currentSubject.id !== athlete.id" class="subject"
                                (click)="switchSubject(athlete.id); athlete_menu = false" [ngClass]="{ 'active' : athlete.id === activeSubjectId, 'inactive' : athlete.id !== activeSubjectId }">{{
                                athlete.first_name }} {{ athlete.last_name }}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="false && showNavBar && boxes?.length > 1" id="view-list">
                    <a style="cursor:pointer;" (click)="box_menu = !box_menu; profile_menu = false; search_menu = false; athlete_menu= false">
                        Views <i class="hx-icon hx-icon-chevron-down chevron-caret"></i>
                    </a>
                    <ul class="submenu" *ngIf="box_menu === true">
                        <li *ngFor="let box of boxes">
                            <a class="view-item tiny">{{ box.title }} <input type="checkbox" name="isActive"
                                    [(ngModel)]="box.displayed" (click)="box_menu = false"></a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="hx-menu-list" style="float: right; padding-top:4px;padding-right:8px;">

                <li *ngIf="showNavBar"><a [ngClass]="{'active': page === 'settings'}" [routerLink]="['/profile']"
                    (click)="profile_menu = false; page = 'settings'">Profile</a></li>
                <li>
                    <a style="cursor:pointer;" (click)="logout()" (click)="profile_menu = false">Logout</a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<div class="contextual-menus">

    <fast-subject-switching></fast-subject-switching>

    <app-selector></app-selector>

</div>