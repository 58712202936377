export const environment = {
  production: true,
  redirectURL: 'https://my.hexoskin.com/',
  apiURL: 'https://api.hexoskin.com/',
  dashDomain: 'my.hexoskin.com',
  clientId: 'T0IMLTqekAin4vbY2', 
  secret: '',
  flow: 'implicit',
  year: '2019',
  version: '2.1'
};