<div class="metric-box-grid">
  <div class="hx-g">
    <div class="hx-u-1-1 hx-metric-box-heading">
      <h5 class="metric-box-title">
        <i class="hx-icon hx-icon-{{ icon }}" ng-class="headerIcon"></i>{{ title }}
        <small class="hidden-xs">({{ unity }})</small>
      </h5>
    </div>
  </div>
  <div class="hx-g metric-box-body">
    <div class="hx-u-12-24">
      <div class="hx-pane left" style="text-align:center;">
        <div style="margin: 0 auto;">
          <div class="hx-icon hx-icon-average"></div>
          <div>{{ avg }}</div>
        </div>
      </div>
    </div>
    <div class="hx-u-12-24">
        <div class="hx-g hx-pane top">
          <div class="hx-u-8-24" style="text-align:center;">
            <div class="hx-icon hx-icon-caret-up">
            </div>
          </div>
          <div class="hx-u-16-24">{{ max }}</div>
        </div>
        <div class="hx-g hx-pane bottom">
          <div class="hx-u-8-24" style="text-align:center;">
            <div class="hx-icon hx-icon-caret-down">
            </div>
          </div>
          <div class="hx-u-16-24">{{ min }}</div>
        </div>
  </div>
</div>