
<ng-template #defaultTemplate>
    <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
        <div class="cal-tooltip-arrow"></div>
        <div class="cal-tooltip-inner" [innerHtml]="contents"></div>
    </div>
</ng-template>
<ng-template
    [ngTemplateOutlet]="customTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{ contents: contents, placement: placement,
    event: event }">
</ng-template>