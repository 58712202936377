<app-alert></app-alert>
<section id="searchr">
  <div class="hx-g header">
    <div class="hx-u-23-24">
      <div class="title">
        Search records
      </div>
    </div>
    <div class="hx-u-1-24">
      <img (click)="openModal('searchr')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
    </div>
  </div>

  <div class="hx-g form">
    <div class="hx-u-8-24">
      <div style="background-color: white; border-right: 1px solid black;height: 30px; ">
        <hx-select *ngIf="subjects && subjects.length > 0" name="filterUsername" [(ngModel)]="filterByUser" [options]="subjects"
          placeholder="User" [allowClear]="true" (opened)="onSingleOpened()" (closed)="onSingleClosed()" (selected)="onSingleSelected($event)"
          (deselected)="onSingleDeselected($event)">
        </hx-select>
      </div>
    </div>
    <div class="hx-u-8-24">
      <div style="background-color: white; border-right: 1px solid black; height: 30px; padding-bottom: 0px; margin-bottom: 0px">
        <form #myForm="ngForm" novalidate>
          <input type="text" name="daterange" (ngModelChange)="onClickGo($event)" [showCustomRangeLabel]="true"
            [showRangeLabelOnInput]="true" [ranges]="dateRanges" [locale]="{applyLabel: 'OK', format: 'DD/MM/YYYY'}"
            hxDaterangepicker [(ngModel)]="selectedPeriod" class="daterangepicker" size="30" style="height:27px;"
            readonly>
        </form>
      </div>
    </div>
    <div class="hx-u-8-24">
      <div style="background-color:white;color: #555;font-size: 14px;height:100%;">
        <div style="padding-top:8px;padding-left:4px;" class="hx-g">
          <div class="hx-u-1-1">
            Duration
            <ng-container>
              &ge; {{ filterByDuration | millisecond2HMSInt }}
            </ng-container>
            <div *ngIf="filterByDuration > 0" class="tip">
              <input type="button" class="duration-btn" (click)="decreaseDuration()" value="-">
              <span>Decrease of 10 secondes</span>
            </div>
            <div class="tip">
              <input type="button" class="duration-btn" (click)="increaseDuration()" value="+">
              <span>Increase of 10 minutes</span>
            </div>
          </div>
          <!--div class="hx-u-1-2">
            <div style="padding-right:4px">
              <input class="slider" type="range" name="testerslider" min="0" max="21600000" [(ngModel)]="filterByDuration" (ngModelChange)="updateDuration($event)"
                value="0" step="300000" list="testlist">
            </div>
          </div-->
        </div>
      </div>
    </div>

    <!--div class="hx-u-3-24">
      <div class="hx-g">
        <div class="hx-u-1-2 but" (click)="onClickGo()" style="background-color:#009649;color: white">Go</div>
        <div class="hx-u-1-2 but" (click)="onClickCancel()" style="background-color:gray;color:black">Cancel</div>
      </div>
    </div-->
  </div>
</section>


<ng-container *ngIf="loading">
  <section>
    <div id="spinner">
      <div></div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="!loading">

  <div *ngIf="downloadInProgress" class="download-in-progress">Download in progress...</div>


  <section id="records">
    <div class="hx-g header">
      <div class="hx-u-15-24">
        <div class="title">
          Records
        </div>
      </div>
      <div class="hx-u-7-24">
        <div class="hx-select">
          Number by page :
          <select [(ngModel)]="maxItemsPerPage" (ngModelChange)="resetByPage()">
            <option *ngFor="let number of [10, 25, 50, 100, 200] " value="{{ number }}">{{ number }}</option>
          </select>
        </div>
      </div>
      <div class="hx-u-1-24">
        <div class="occurence tip">
          {{ totalRecordsCount }}
          <span>Nbr&nbsp;of records</span>
        </div>
      </div>
      <div class="hx-u-1-24">
        <img (click)="openModal('records')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
      </div>
    </div>
    <div class="hx-profile hx-panel hx-records">
      <div class="hx-horizontal-padding-5px">
        <table class="hx-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Start
                <i style="cursor: pointer;" class="hx-icon hx-icon-chevron-down hx-icon-array" [ngClass]="{ 'hx-icon-active': order === 'desc' && column === 'start',  'hx-icon-inactive': column !== 'start', 'hx-icon-inactive': order === 'asc'}"
                  (click)="sort('start')"></i>

                <i style="cursor: pointer;" class="hx-icon hx-icon-chevron-up hx-icon-array" [ngClass]="{ 'hx-icon-active': order === 'asc' && column === 'start',  'hx-icon-inactive': column !== 'start', 'hx-icon-inactive': order === 'desc' }"
                  (click)="sort('start')"></i>
              </th>
              <th>Duration</th>
              <th>HR avg.</th>
              <th>BR avg.</th>
              <th>Energy</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let record of displayedData | filter : 'username' : filter_username; let i = index">
              <td>{{ record._user?.first_name }} {{ record._user?.last_name }}</td>
              <!-- {{ record.user.first_name | capitalizeFirst }} {{ record.user.last_name | capitalizeFirst}} -->
              <td style="cursor:pointer;" (click)="view(record)"><a onclick="return false;" href="/record-view/{{ record.id }}">{{
                  record.start | dbTimeToDateTime }}</a></td>
              <td>{{ record.duration_millisecondes | millisecond2HMSInt }}
              </td>
              <td>{{ record | dbRecordToMetric:44:'bpm' }}</td>
              <td>{{ record | dbRecordToMetric:12:'rpm' }}</td>
              <td>{{ record | dbRecordToMetric:149:'Cal' }}</td>
              <td>
                <div class="hx-status-text" [ngClass]="record.status">{{ record.status }}</div>
              </td>
              <td width="140">
                <div class="tip">
                    <a target="_blank" href="/record-view/{{ record.id }}">
                  <button class="button button-green" type="button"><i class="hx-icon hx-icon-eye"></i></button>
                  <span>Activity details</span>
                  </a>
                </div>
                <div class="tip">
                  <div class="multichoice-button">
                    <button class="button button-green multichoice-button-hit" type="button" (click)="dropdownHitMouseOver[i] = !dropdownHitMouseOver[i]"><i
                        class="hx-icon hx-icon-download"></i></button>

                    <div class="multichoice-button-content" (mouseleave)="dropdownHitMouseOver[i] = false" [ngClass]="{ 'multichoice-button-content-hover' : dropdownHitMouseOver[i] === true }">
                      <ul>
                        <li><a (click)="exportCSV(record.id); dropdownHitMouseOver[i] = false">Download CSV file</a></li>
                        <li><a (click)="exportBinary(record.id); dropdownHitMouseOver[i] = false">Download Binary file</a></li>
                        <li><a (click)="exportEDF(record.id); dropdownHitMouseOver[i] = false">Download EDF file</a></li>
                      </ul>
                    </div>
                  </div>
                  <span *ngIf="!dropdownHitMouseOver[i]">Click on the Data Format to start the Download</span>
                </div>
                <div class="tip">
                  <button class="button button-green" type="button" (click)="delete(record)"><i class="hx-icon hx-icon-delete"></i></button>
                  <span>Delete record</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="paginator">

      <div class="button-group margin-top-5 margin-bottom-5" style="text-align:center;">

        <!-- Previous page -->
        <div *ngIf="currentPage > 10" class="button button-blue tip">
          <i class="hx-icon hx-icon-chevron-left tip" (click)="changeToPage(currentPage-10)"></i>
          <span>
            Jump back 10 pages
          </span>
        </div>

        <!-- Previous page -->
        <div *ngIf="currentPage > 1" class="button button-blue tip">
          <i class="hx-icon hx-icon-caret-left tip" (click)="changeToPage(currentPage-1)"></i>
          <span>
            Previous page
          </span>
        </div>

        <div *ngIf="currentPage > 5" class="button button-blue">
          <i (click)="changeToPage(1)" [ngClass]="{ 'current': currentPage === page }">1</i>
        </div>
        <div *ngIf="currentPage > 5" class="button button-blue">
          ...
        </div>

        <!-- Pages around the current page -->
        <div *ngFor="let page of pages" class="button" [ngClass]="{ 'button-light-blue': currentPage === page, 'button-blue': currentPage !== page }">
          <i (click)="changeToPage(page)">{{ page }}</i>
        </div>

        <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
          ...
        </div>
        <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
          <i (click)="changeToPage(totalPages - 1)" [ngClass]="{ 'current': currentPage === page }">{{ totalPages - 1
            }}</i>
        </div>

        <!-- Next page -->
        <div *ngIf="currentPage < totalPages" class="button button-blue tip">
          <i class="hx-icon hx-icon-caret-right" (click)="changeToPage(currentPage+1)"></i>
          <span>
            Next page
          </span>
        </div>
        <!-- Jump to 5 -->
        <div *ngIf="currentPage + 9 < totalPages" class="button button-blue tip">
          <i class="hx-icon hx-icon-chevron-right" (click)="changeToPage(currentPage+10)"></i>
          <span>
            Jump 10 pages
          </span>
        </div>
      </div>


    </div>


  </section>

</ng-container>


<!--div class="hx-panel">
<chart *ngIf="options" type="StockChart" [options]="options" #chart></chart>
</div-->

<confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>

<app-growl [value]="msgs"></app-growl>


<help-modal id="searchr-help" style="display: none;">
  <p>Your search for records can be narrowed down to a specific User, Time range or even the Record duration and the
    record list will be displayed in the "Records" section below.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>

<help-modal id="records-help" style="display: none;">
  <p>This section displays a list of all the records that you can have access to. The number of records per page can be
    changed if need be.<br>
    The <img height="24" src="/assets/icons/view.svg"> is to view an Record, the <img height="24" src="/assets/icons/download.svg">
    is to download the data from the Record and the <img height="24" src="/assets/icons/trash.svg"> is to permanently
    delete the Record from the account.<br>
    Navigate from page to page by clicking on the page button (1, 2, 3, etc.) or with <img height="24" src="/assets/icons/pv_next.svg">
    button at the bottom of the Records list.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>
