/* tslint:disable: member-ordering forin */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { UserService } from '../../core/services';
import { Account } from '../../models/';
import { UserForm } from './user-form';
import 'rxjs/add/operator/mergeMap';
import { shorterThan } from '../../shared/validators/string.validator';
import { Message } from '../../shared/components/growl/message';

@Component({
    selector: 'user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['../../../assets/stylesheets/button.scss']
})
export class UserAccountComponent extends UserForm implements OnInit {

    account = new Account();
    profile: any;
    active = true;
    success = false;

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    @Input() user_id = null;

    @Output() valueChange = new EventEmitter();

    /**
     * Error messages
     */
    formErrors = {
        'first_name': '',
        'last_name': '',
        'username': ''
    };

    validationMessages = {
        'first_name': {
            'required': 'Firstname is required.',
            'shorterThan': 'Should be shorter than 30 characters'
        },
        'last_name': {
            'required': 'Lastname is required.',
            'shorterThan': 'Should be shorter than 30 characters'
        },
        'username': {
            'required': 'Username is required.',
            'shorterThan': 'Should be shorter than 30 characters'
        }
    };

    /**
     * CONSTRUCTOR
     */
    constructor(fb: FormBuilder,
                private userService: UserService,
                private alertService: AlertService) {
        super(fb);
    }

    /**
     * Building the form on init
     */
    ngOnInit(): void {
        this.userService.get(this.user_id).subscribe(data => {
            this.account.first_name = data.first_name;
            this.account.last_name = data.last_name;
            this.account.username = data.username;
            this.buildForm();
        });
    }

    /**    
     * Save on server the user information
     */
    onSubmit() {
        this.account = this.form.value;
        this.userService.save(this.account, this.user_id).subscribe(() => {
            this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The account info have been saved.' }];
            this.valueChange.emit(this.msgs);
        }, error => { console.error(error); 
            this.alertService.error(error);
        });
    }

    /**
     * Building form function 
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    buildForm(): void {
        this.form = this.fb.group({
            'first_name': [this.account.first_name, [
                Validators.required,
                shorterThan(30)
            ]
            ],
            'last_name': [this.account.last_name, [
                Validators.required,
                shorterThan(30)
            ]
            ],
            'username': [this.account.username, [
                Validators.required,
                shorterThan(30)
            ]
            ],
        });
        this.form.valueChanges
            .subscribe(() => {
                this.onValueChanged();
            });
        this.onValueChanged();
    }

}

