// Import from other modules
import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// Import from current module
import { ConfirmationService } from '../../../shared/components/confirmation/confirmation.service';
import { ApiQuery, ApiService, RangeService } from '../../../core/services';
import { GlobalVariables } from '../../../core/services/global.variables';
import { Range } from "../../../models";
import { Message } from '../../../shared/components/growl/message';
import { ArrayOfJSONObjects } from "../../../shared/hx-tools/array-of-json-objects";
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { DataService } from '../../../core/services';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Subject } from 'rxjs/Subject';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import * as _ from 'lodash';
import * as moment from 'moment/moment';

const TableData: Array<any> = [];


@Component({
    selector: 'app-ranges-table',
    templateUrl: './ranges-table.component.html',
    styleUrls: ['./ranges-table.component.scss',
        '../../../../assets/stylesheets/core/slider.scss',
        '../../../../assets/stylesheets/core/section.scss',
        '../../../../assets/stylesheets/button.scss'],
})
export class RangesTableComponent extends HelperComponent implements OnInit, OnDestroy {

    /**
     * Array of ranges
     */
    public data: Array<Range> = TableData;
    public displayedData: Array<any> = TableData;

    public myDateRangePickerOptions = {};

    //@ViewChild("chart") private chart;

    /**
     * Number of ranges before update
     * If previousDataLength !== number of ranges then new range(s) has(ve) been added or deleted
     */
    private previousDataLength: number = 0;

    public dropdownHitMouseOver: Array<boolean>;

    /**
     * Equals to true if the data is being retrieved from the server
     */
    public loading = false;

    /**
     * Minimum duration of ranges
     */
    public filterByDuration = 0;

    durationQuery: number; // bind this to input with ngModel
    durationQueryChanged: Subject<number> = new Subject<number>();

    /**
     * If we display the last record button
     */
    displayLastRecordBtn = false;

    /**
 * If we display the last record button
 */
    displayResetBtn = true;

    /**
     * The number of times the server has been called the page has been loaded
     */
    private time: number = 0;

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    /**
     * Total number of ranges
     */
    public totalRangesCount;

    /**
     * Sorting
     */
    public column: string = "start";
    public order: string = "desc";

    /**
     * Filters
     */
    public filter_user = null;

    /**
     * Table parameters
     */
    public maxItemsPerPage = 10;
    public currentPage = 1;
    public offset = 0;
    public pages;
    public totalPages;
    public selectedActivity: any;
    public selectedPeriod: any;
    public selectedUser: any;
    public start: number;
    public end: number;
    public activities = Array<any>();
    public users = Array<any>();
    public cachedUsers: Array<any>;

    downloadInProgress = false;
    downloadClicked = false;
    
    dateRanges: any = {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 100 days': [moment().subtract(100, 'days'), moment()],
        'This year': [moment().startOf('year'), moment()],
        'From the beginning': [moment().subtract(10, 'year'), moment()],
    };

    //private options: Object;
    /**
     * CONSTRUCTOR
     */
    public constructor(private rangeService: RangeService,
        private router: Router,
        public helpService: HelpService,
        private globalVariables: GlobalVariables,
        private dataService: DataService,
        private apiService: ApiService,
        private confirmationService: ConfirmationService,
        public viewContainerRef: ViewContainerRef) {
        super(helpService);
        this.pages = new Array<number>();

        this.dropdownHitMouseOver = new Array<boolean>();
        this.durationQueryChanged
            .debounceTime(1000) // wait 1 sec after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(model => {
                this.durationQuery = model;
                const resourceUri = this.globalVariables.get("profileUri");
                this.apiService.getFromUri(resourceUri).flatMap(data => {
                    this.currentPage = 1;
                    let preferences = data.preferences;
                    preferences.filterByDuration = this.filterByDuration;
                    return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
                }).subscribe(() => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'Your preference has been saved.'
                    });
                    this.onClickGo(null);
                },
                    error => {
                        console.error(error);
                    });
            });
    }

    /**
     * On init
     */
    public ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.selectedPeriod = {                               // update the selected period to be displayed
            startDate: moment().subtract(10, 'year'),
            endDate: moment()
        };
        this.getAll();
    }

    /** 
     * On destroy 
     */
    public ngOnDestroy() {
    }

    /**
     * Display the previous page
     */
    nextPage() {
        this.currentPage++;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    }

    /**
     * Display the next page
     */
    previousPage() {
        this.currentPage--;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    }

    /**
     * Display the page 
     */
    changeToPage(page) {
        this.currentPage = page;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    }


    /**
     * Retrieve all the ranges the logged user is allowed to see
     */
    getAll(user?: number, activityType?: number, start?: number, end?: number) {
        this.loading = true;
        let offset = (this.currentPage - 1) * this.maxItemsPerPage;
        // let offset = (this.currentPage - 1) * this.maxItemsPerPage;

        let _start = start;
        let _end = end;

        if (start === undefined || end === undefined) {
            _start = 256 * moment().subtract(10, 'year').unix();
            _end = 256 * moment().unix();
        }

        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            this.maxItemsPerPage = 10;
            if (data.preferences && data.preferences.maxItemsPerPage) {
                // todo : check if tree is valid
                this.maxItemsPerPage = data.preferences.maxItemsPerPage;
            }
            if (data.preferences && data.preferences.filterByDuration) {
                // todo : check if tree is valid
                this.filterByDuration = data.preferences.filterByDuration;
            }
            return this.apiService.getQuery(ApiQuery.all_users_for_user_id);
        }).flatMap(data => {
            this.users = new Array<any>();
            for (let i = 0; i < data.objects.length; i++) {
                const subject = {
                    "value": "" + data.objects[i].id,
                    "label": data.objects[i].first_name + " " + data.objects[i].last_name
                };
                this.users.push(subject);
            }

            // cache (todo)
            this.cachedUsers = new Array<any>();
            for (const _user of data.objects) {
                this.cachedUsers[_user.resource_uri] = _user.first_name + " " + _user.last_name;
            }
            const rangeRank = 0; // we only display ranges of rank = 1 here.
            return this.rangeService.fetchAll(this.maxItemsPerPage, offset,
                this.column, this.order === "desc" ? "-" : null, activityType, _start, _end, rangeRank, user, this.filterByDuration / 1000, [12, 44, 149]);
        }).flatMap(
            data => {
                this.totalRangesCount = data.meta.total_count;
                this.totalPages = Math.ceil(this.totalRangesCount / this.maxItemsPerPage);
                for (let i = 0; i < this.totalRangesCount; i++) {
                    this.dropdownHitMouseOver[i] = false;
                }


                // Beginning - pagination
                this.pages = [];
                if (this.currentPage === 1) {
                    this.pages.push(1);
                    if (this.maxItemsPerPage < this.totalRangesCount) {
                        this.pages.push(2);
                    }
                    if (this.maxItemsPerPage * 2 < this.totalRangesCount) {
                        this.pages.push(3);
                    }
                }
                if (this.currentPage >= 2) {
                    if (this.currentPage - 2 > 0) this.pages.push(this.currentPage - 2);
                    this.pages.push(this.currentPage - 1);
                    this.pages.push(this.currentPage);
                    if (this.maxItemsPerPage * this.currentPage < this.totalRangesCount) {
                        this.pages.push(this.currentPage + 1);
                    }
                }
                // End - pagination
                this.data = data;

                this.displayedData = data.objects;

                if ((this.time > 0) && (this.previousDataLength !== this.totalRangesCount)) {
                    //this.hasNewRecord = true;
                }
                this.previousDataLength = this.totalRangesCount;
                this.time++;
                this.loading = false;

                return this.apiService.getQuery(ApiQuery.all_activitiytype);
            }).subscribe(
            data => {

                let activityTypes = data.objects.filter(activitiy => activitiy.role === 'system' || activitiy.role === 'support');

                let activities = [];
                for (let i = 0; i < activityTypes.length; i++) {
                    const icon = HxToolsActivity.activityTypeToIconClass(activityTypes[i].name);
                    const activity = {
                        "label": activityTypes[i].name,
                        "icon": icon ? icon : 'other',
                        "value": "" + activityTypes[i].id
                    };
                    activities.push(activity);
                    this.activities = _.sortBy(activities, 'label');
                }
                for (let i = 0; i < this.displayedData.length; i++) {
                    // We retrieve the training routine category
                    if (this.displayedData[i].context && this.displayedData[i].context.activitytype) {
                        const parts = this.displayedData[i].context.activitytype.split('/');
                        this.displayedData[i].activityType = ArrayOfJSONObjects.findById(activityTypes, parts[3]);
                    }
                }
                this.loading = false;
            });
    }


    onChangeActivity(ev) {
        this.selectedActivity = ev.value;
        this.onClickGo(ev);
    }

    onChangeUser(ev) {
        this.selectedUser = ev.value;
        this.onClickGo(ev);
    }

    onClearActivity(ev) {
        this.selectedActivity = null;
        this.onClickGo(ev);
    }

    onClearUser(ev) {
        this.selectedUser = null;
        this.onClickGo(ev);
    }

    onClickGo($event) {
        if ($event !== undefined) {
            let range = $event;
            // Only if the time range has changed
            if (range.startDate !== undefined && range.endDate !== undefined) {
                this.currentPage = 1;
                this.start = 256 * range.startDate.startOf('day').unix();
                this.end = 256 * range.endDate.endOf('day').unix();
            }
        }
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    }

    reset() {
        this.start = this.end = null;
        this.getAll(this.selectedUser, this.selectedActivity);
    }

    updateDuration(ev) {

        this.durationQueryChanged.next(ev);
    }

    decreaseDuration() {
        if (this.filterByDuration >= 10 * 60 * 1000) {
            this.filterByDuration = this.filterByDuration - 10 * 60 * 1000;
        }
        this.durationQueryChanged.next(this.filterByDuration);
    }

    increaseDuration() {
        this.filterByDuration = this.filterByDuration + 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    }

    onClickCancel() {
        this.currentPage = 1;
        this.selectedUser = null;
        this.selectedPeriod = null;
        this.getAll();
    }

    populate(ranges) {
        return ranges;
    }

    /**
     * Delete a range
     * @param {number} id
     */
    delete(range: Range) {

        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this activity?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.msgs = [];
                this.loading = true;
                this.rangeService.delete(range.id, "range")
                    .subscribe(() => {
                        this.time = 0;
                        this.getAll();
                        this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range deleted' });
                        this.loading = false;
                    },
                    error => {
                        console.error(error);

                        this.loading = false;
                    });
            }
        });
    }

    /**
     * Redirect to range view page
     */
    view(range: any) {
        const link = ['/range-view/' + range.id];
        this.router.navigate(link);
    }

    resetByPage() {
        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            let preferences = data.preferences;
            preferences.maxItemsPerPage = this.maxItemsPerPage;
            return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(() => {
            this.msgs = [];
            this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
        },
            error => {
                console.error(error);
            });
    }
    /**
     * Sort data table
     */
    sort(property) {
        // let sortedData: any[];
        this.column = property;
        if (this.order === 'asc') this.order = 'desc';
        else this.order = 'asc';
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
        //this.displayedData = this.populate(sortedData);
    }

    /**
     * Export of CSV file
     */
    public exportCSV(range) {
        this.downloadInProgress = true;
        const datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, 'text/csv').subscribe(data => {
            const filename = "range-" + range.id + ".csv";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;
            }
        });
    }

    /**
     * Export of binary file
     */
    public exportBinary(range) {
        this.downloadInProgress = true;
        const datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, 'application/octet-stream').subscribe(data => {
            const filename = "range-" + range.id + ".zip";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;
            }
        });
    }

    /**
     * Export of EDF file
     */
    public exportEDF(range) {
        this.downloadInProgress = true;
        const datatype_query = "33,49,19,53";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, "application/x-edf").subscribe(data => {
            const filename = "range-" + range.id + ".edf";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;
            }
        });
    }

}
