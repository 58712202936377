var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
/**
 * Ranges
 */
var RangeService = /** @class */ (function (_super) {
    __extends(RangeService, _super);
    function RangeService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    /**
     * Delete a range
     */
    RangeService.prototype.delete = function (id, keyToClear) {
        var query = this.API_URL + "api/" + "range/" + id + "/";
        return this._delete(query, keyToClear);
    };
    /**
     * Retrieve all the Ranges for a user
     */
    RangeService.prototype.fetch = function (id) {
        var query = this.API_URL + "api/range/?user=" + id;
        return this._get(query);
    };
    RangeService.prototype.fetchPeriod = function (start, end, limit, offset, userId, order_by, cache) {
        if (cache === void 0) { cache = true; }
        if (!limit) {
            limit = 0;
        }
        if (!offset) {
            offset = 0;
        }
        // let filter_by_username = "";
        var user = "";
        if (userId) {
            user += "&user=" + userId;
        }
        var _order_by = "";
        if (order_by) {
            _order_by += "&order_by=" + order_by;
        }
        var metrics__include = ""; //&metrics__include=12,44,149";
        var query = this.API_URL + "api/" + "range/?limit=" + limit +
            "&offset=" + offset + metrics__include + "&start__lte=" + Math.ceil(start - Math.floor((Math.random() * 100) + 1))
            + "&end__gte=" + Math.floor(end + Math.floor((Math.random() * 100) + 1)) + _order_by
            + user;
        // + "&limit=" + Math.floor((Math.random() * 100) + 1) + 100;
        // TO DO : delete limit... workaround not good...
        return this._get(query, cache);
    };
    RangeService.prototype.getNext = function (time, userId) {
        var user = "";
        if (userId) {
            user = "&user=" + userId;
        }
        var query = this.API_URL + "api/" + "range/?limit=1" + user + "&order_by=start&start__gte=" + time;
        return this._get(query);
    };
    RangeService.prototype.getPrevious = function (time, userId) {
        var user = "";
        if (userId) {
            user = "&user=" + userId;
        }
        var query = this.API_URL + "api/" + "range/?limit=1" + user + "&order_by=-start&end__lte=" + time;
        return this._get(query);
    };
    RangeService.prototype.getAssociatedRecord = function (start, end, userId) {
        var user = "";
        if (userId) {
            user = "&user=" + userId;
        }
        var query = this.API_URL + "api/" + "record/?start__lte=" + start + "&end__gte=" + end + user;
        return this._get(query);
    };
    /**
     * Get the range from its id
     */
    RangeService.prototype.get = function (id, include_histograms, include_metrics) {
        var _include_histograms = "";
        var _include_metrics = "";
        var i = 0;
        if (include_histograms) {
            _include_histograms = "?include_histograms=";
            for (var _i = 0, include_histograms_1 = include_histograms; _i < include_histograms_1.length; _i++) {
                var histogram = include_histograms_1[_i];
                _include_histograms += histogram;
                if (i < include_histograms.length - 1) {
                    _include_histograms += ",";
                }
                i++;
            }
        }
        if (include_metrics) {
            _include_metrics = "?include_metrics=";
            if (include_histograms) {
                _include_metrics = "&include_metrics=";
            }
            for (var _a = 0, include_metrics_1 = include_metrics; _a < include_metrics_1.length; _a++) {
                var metric = include_metrics_1[_a];
                _include_metrics += metric;
                if (i < _include_metrics.length - 1) {
                    _include_metrics += ",";
                }
                i++;
            }
        }
        var query = this.API_URL + "api/" + "range/" + id + "/" + _include_histograms + _include_metrics;
        return this._get(query);
    };
    /**
     * Save range
     */
    RangeService.prototype.save = function (range, keyToClear) {
        var query = this.API_URL + "api/" + "range/";
        return this._post(query, range, keyToClear);
    };
    RangeService.prototype.update = function (range, keyToClear) {
        var query = this.API_URL + "api/" + "range/" + range.id + "/";
        return this._put(query, range, keyToClear);
    };
    RangeService.prototype.fetchAll = function (limit, offset, order_by, direction, activityType, start, end, rank, userId, duration, metrics) {
        if (!limit) {
            limit = 0;
        }
        if (!offset) {
            offset = 0;
        }
        var order_by_q = "";
        var direction_q = "";
        if (direction) {
            direction_q = direction;
        }
        if (order_by) {
            var field = "";
            if (order_by === "user") {
                field = "__username";
            }
            order_by_q = "&order_by=" + direction_q + order_by + field;
        }
        var filter = "";
        if (activityType) {
            filter += "&activitytype=" + activityType;
        }
        if (metrics) {
            filter += "&include_metrics=" + metrics.join();
        }
        var rank_q = "";
        if (_.isNumber(rank)) {
            rank_q += "&rank=" + rank;
        }
        var period = "";
        if (start && end) {
            period = "&start__gte=" + Math.ceil(start) + "&end__lte=" + Math.floor(end);
        }
        var user = "";
        if (userId) {
            user += "&user=" + userId;
        }
        var _duration = "";
        if (duration) {
            _duration = "&duration__gte=" + duration;
        }
        var query = this.API_URL + "api/" + "range/?minimal=1&limit=" + limit + "&offset=" +
            offset + order_by_q + filter + period + rank_q + user + _duration;
        return this._get(query);
    };
    RangeService.prototype.getFileWithDatatype = function (id, datatype__in, format) {
        if (!format)
            format = "text/csv";
        var options = this.generateHeader(format);
        return this.http.get(this.API_URL + "api/" + "data/?range=" + id + "&datatype__in=" + datatype__in, { headers: options, responseType: 'blob', reportProgress: true });
        // .map(response => { return response; });
    };
    RangeService.prototype.downloadResource = function (id, datatype__in, format) {
        var options = this.generateHeader(format);
        var url = this.API_URL + "api/" + "data/?range=" + id;
        if (format === 'text/csv') {
            url += "&datatype__in=" + datatype__in;
        }
        return this.http.get(url, { headers: options, responseType: 'blob' });
    };
    return RangeService;
}(HttpService));
export { RangeService };
