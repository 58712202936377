import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

/**
 * Responsible to retrieve information stored in the json format files
 */
@Injectable()
export class ConfigService {

    constructor(private http: Http) {
    }

    /**
     * Read JSON format file
     */
    read(filename: string): Observable<any> {
        return this.http.get(filename)
            .map((res: Response) => {
                return res.json();
            });
    }

}
