/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-week-view-header.component";
var styles_CalendarWeekViewHeaderComponent = [];
var RenderType_CalendarWeekViewHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarWeekViewHeaderComponent, data: {} });
export { RenderType_CalendarWeekViewHeaderComponent as RenderType_CalendarWeekViewHeaderComponent };
export function View_CalendarWeekViewHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["calendar-week-view-header.component.html"]))], null, null); }
export function View_CalendarWeekViewHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-week-view-header", [], null, null, null, View_CalendarWeekViewHeaderComponent_0, RenderType_CalendarWeekViewHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.CalendarWeekViewHeaderComponent, [], null, null)], null, null); }
var CalendarWeekViewHeaderComponentNgFactory = i0.ɵccf("app-calendar-week-view-header", i1.CalendarWeekViewHeaderComponent, View_CalendarWeekViewHeaderComponent_Host_0, { days: "days", locale: "locale", customTemplate: "customTemplate" }, { dayHeaderClicked: "dayHeaderClicked", eventDropped: "eventDropped" }, []);
export { CalendarWeekViewHeaderComponentNgFactory as CalendarWeekViewHeaderComponentNgFactory };
