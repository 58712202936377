var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalVariables } from '../../core/services/global.variables';
import { ApiService } from '../../core/services';
import { User } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import { lessThan, greaterThan, isNumber } from '../../shared/validators/number.validator';
import { dateBefore } from '../../shared/validators/date.validator';
var UserPreferencesComponent = /** @class */ (function (_super) {
    __extends(UserPreferencesComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function UserPreferencesComponent(fb, apiService, globalVariables) {
        var _this = _super.call(this, fb) || this;
        _this.apiService = apiService;
        _this.globalVariables = globalVariables;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.active = true;
        _this.success = false;
        _this.profile_id = null;
        _this.user = new User();
        return _this;
    }
    /**
     * Building the form on init
     */
    UserPreferencesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).subscribe(function (data) {
            _this.preferences = data.preferences;
        });
    };
    /**
     * Actions performed when submit is clicked
     */
    UserPreferencesComponent.prototype.onSubmit = function () {
    };
    /**
     * Building form function
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    UserPreferencesComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.fb.group({
            'weight': [this.user.weight, [
                    Validators.required,
                    isNumber,
                    lessThan(150),
                    greaterThan(5)
                ]
            ],
            'height': [this.user.height, [
                    Validators.required,
                    isNumber,
                    lessThan(2.30),
                    greaterThan(0.5)
                ]
            ],
            'gender': [this.user.gender, []],
            'date_of_birth': [this.user.date_of_birth, [
                    dateBefore(new Date(Date.now() + -(5 * 365 + 3) * 24 * 3600 * 1000)) // 5 years ago + 3 days
                ]],
        });
        this.form.valueChanges
            .subscribe(function (data) {
            _this.onValueChanged();
        });
        this.onValueChanged();
    };
    return UserPreferencesComponent;
}(UserForm));
export { UserPreferencesComponent };
