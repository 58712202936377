/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select-dropdown.component.ngfactory";
import * as i4 from "./select-dropdown.component";
import * as i5 from "@angular/forms";
import * as i6 from "./select.component";
var styles_SelectComponent = [i0.styles];
var RenderType_SelectComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectComponent, data: {} });
export { RenderType_SelectComponent as RenderType_SelectComponent };
function View_SelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "hx-icon ", _co.optionList.selection[0].icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.optionList.selection[0].label; _ck(_v, 2, 0, currVal_1); }); }
function View_SelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { option: 0, onDeselectOptionClick: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.optionList.selection[0].wrappedOption, _co.onDeselectOptionClick); var currVal_1 = _co.optionTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.optionTemplate; _ck(_v, 5, 0, currVal_2); }, null); }
function View_SelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholderView; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClearSelectionClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "hx-icon hx-icon-tiny hx-icon-times"]], null, null, null, null, null))], null, null); }
function View_SelectComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "hx-icon hx-icon-caret-down"]], null, null, null, null, null))], null, null); }
function View_SelectComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "hx-icon hx-icon-caret-down"]], null, null, null, null, null))], null, null); }
function View_SelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "single"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_9)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionList.hasSelected; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.optionList.hasSelected; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.allowClear && _co.optionList.hasSelected); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isOpen; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.isOpen; _ck(_v, 10, 0, currVal_4); }, null); }
function View_SelectComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "option"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "deselect-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeselectOptionClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u2715 "])), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_SelectComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholderView; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[3, 0], ["filterInput", 1]], null, 2, "input", [["autocomplete", "off"], ["tabindex", "-1"]], [[8, "placeholder", 0]], [[null, "input"], [null, "keydown"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onFilterInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.onMultipleFilterKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.onMultipleFilterFocus() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "width.px": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, _co.filterInputWidth); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholderView; _ck(_v, 0, 0, currVal_0); }); }
function View_SelectComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "multiple"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_11)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_12)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_13)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionList.selection; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.filterEnabled && !_co.optionList.hasSelected); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.filterEnabled; _ck(_v, 6, 0, currVal_2); }, null); }
function View_SelectComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "select-dropdown", [], null, [[null, "optionClicked"], [null, "optionsListClick"], [null, "singleFilterClick"], [null, "singleFilterFocus"], [null, "singleFilterInput"], [null, "singleFilterKeydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionClicked" === en)) {
        var pd_0 = (_co.onDropdownOptionClicked($event) !== false);
        ad = (pd_0 && ad);
    } if (("optionsListClick" === en)) {
        var pd_1 = (_co.onOptionsListClick() !== false);
        ad = (pd_1 && ad);
    } if (("singleFilterClick" === en)) {
        var pd_2 = (_co.onSingleFilterClick() !== false);
        ad = (pd_2 && ad);
    } if (("singleFilterFocus" === en)) {
        var pd_3 = (_co.onSingleFilterFocus() !== false);
        ad = (pd_3 && ad);
    } if (("singleFilterInput" === en)) {
        var pd_4 = (_co.onFilterInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("singleFilterKeydown" === en)) {
        var pd_5 = (_co.onSingleFilterKeydown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i3.View_SelectDropdownComponent_0, i3.RenderType_SelectDropdownComponent)), i1.ɵdid(1, 4833280, [[2, 4], ["dropdown", 4]], 0, i4.SelectDropdownComponent, [], { filterEnabled: [0, "filterEnabled"], highlightColor: [1, "highlightColor"], highlightTextColor: [2, "highlightTextColor"], left: [3, "left"], multiple: [4, "multiple"], notFoundMsg: [5, "notFoundMsg"], optionList: [6, "optionList"], top: [7, "top"], width: [8, "width"], placeholder: [9, "placeholder"], optionTemplate: [10, "optionTemplate"] }, { optionClicked: "optionClicked", optionsListClick: "optionsListClick", singleFilterClick: "singleFilterClick", singleFilterFocus: "singleFilterFocus", singleFilterInput: "singleFilterInput", singleFilterKeydown: "singleFilterKeydown" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterEnabled; var currVal_1 = _co.highlightColor; var currVal_2 = _co.highlightTextColor; var currVal_3 = _co.left; var currVal_4 = _co.multiple; var currVal_5 = _co.notFoundMsg; var currVal_6 = _co.optionList; var currVal_7 = _co.top; var currVal_8 = _co.width; var currVal_9 = _co.filterPlaceholder; var currVal_10 = _co.optionTemplate; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_SelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { selectionSpan: 0 }), i1.ɵqud(671088640, 2, { dropdown: 0 }), i1.ɵqud(671088640, 3, { filterInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["selection", 1]], null, 6, "div", [], [[1, "tabindex", 0]], [[null, "click"], [null, "focus"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectContainerClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.onSelectContainerFocus() !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.onSelectContainerKeydown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "open": 0, "focus": 1, "below": 2, "disabled": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_10)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_14)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.label !== ""); _ck(_v, 4, 0, currVal_0); var currVal_2 = _ck(_v, 7, 0, _co.isOpen, _co.hasFocus, _co.isBelow, _co.disabled); _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.multiple; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.multiple; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.isOpen; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.disabled ? null : 0); _ck(_v, 5, 0, currVal_1); }); }
export function View_SelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "hx-select", [], null, [["window", "blur"], ["window", "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowBlur() !== false);
        ad = (pd_0 && ad);
    } if (("window:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onWindowClick() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onWindowResize() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_SelectComponent_0, RenderType_SelectComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SelectComponent]), i1.ɵdid(2, 4833280, null, 1, i6.SelectComponent, [i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { optionTemplate: 0 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SelectComponentNgFactory = i1.ɵccf("hx-select", i6.SelectComponent, View_SelectComponent_Host_0, { options: "options", allowClear: "allowClear", disabled: "disabled", multiple: "multiple", noFilter: "noFilter", highlightColor: "highlightColor", highlightTextColor: "highlightTextColor", notFoundMsg: "notFoundMsg", placeholder: "placeholder", filterPlaceholder: "filterPlaceholder", label: "label" }, { opened: "opened", closed: "closed", selected: "selected", deselected: "deselected", focus: "focus", blur: "blur", noOptionsFound: "noOptionsFound" }, []);
export { SelectComponentNgFactory as SelectComponentNgFactory };
