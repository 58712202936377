import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { map, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalVariables } from '../../core/services/global.variables';
import { environment } from '../../../environments/environment';
import { NotifierService } from 'angular-notifier';
var HttpService = /** @class */ (function () {
    function HttpService(http, eventAggregator, globalVariables, notifierService) {
        this.http = http;
        this.eventAggregator = eventAggregator;
        this.globalVariables = globalVariables;
        this.notifierService = notifierService;
        this.DEFAULT_MAX_AGE = 300000;
        this.API_URL = environment.apiURL;
        this.cache = new Map();
        this.inFlightObservables = new Map();
        this.notifier = notifierService;
        if (this.API_URL.slice(-1) !== "/") {
            this.API_URL += "/";
        }
    }
    /**
     * Checks if the key exists and has not expired.
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    HttpService.prototype.hasValidCachedValue = function (key) {
        if (this.cache.has(key)) {
            if (this.cache.get(key).expiry < Date.now()) {
                this.cache.delete(key);
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    };
    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     *
     * @param {string} key
     *  Key
     * @param {string} value
     *  Value associated to the key
     * @param {string} maxAge
     *  Max duration of key in cache in seconds
     */
    HttpService.prototype.set = function (key, value, maxAge) {
        if (maxAge === void 0) { maxAge = this.DEFAULT_MAX_AGE; }
        this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
        this.notifyInFlightObservers(key, value);
    };
    /**
     * Checks if the a key exists in cache
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    HttpService.prototype.has = function (key) {
        return this.cache.has(key);
    };
    /**
     * Clear all the cache
     *
     */
    HttpService.prototype.clearAll = function () {
        var _this = this;
        this.cache.forEach(function (value, key) {
            _this.cache.delete(key);
        });
    };
    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     *
     * @param {string} key
     * @param {any} value
     *
     */
    HttpService.prototype.notifyInFlightObservers = function (key, value) {
        if (this.inFlightObservables.has(key)) {
            var inFlight = this.inFlightObservables.get(key);
            var observersCount = inFlight.observers.length;
            if (observersCount) {
                inFlight.next(value);
            }
            inFlight.complete();
            this.inFlightObservables.delete(key);
        }
    };
    HttpService.prototype.isAuthentificated = function () {
        var _this = this;
        var options = this.generateHeader();
        return this.http.get(this.API_URL + 'api/account/', { 'headers': options }).pipe(map(function (res) {
            return res;
        }), catchError(function (err) {
            window.location.href = _this.API_URL + '/api/connect/logout/';
            throw new Error(err.error.message);
        }));
    };
    HttpService.prototype._sanetize_url = function (url) {
        return url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
    };
    /**
     * Perform a GET
     *
     * @param {number} query
     *  API call url
     * @param {boolean} cache
     *  If cache must be used
     * @param {boolean} noErrorPopup
     *  If popup is display to inform about the error
     *
     * @return
     *  An observable
     */
    HttpService.prototype._get = function (query, cache, noErrorPopup) {
        var _this = this;
        if (cache === void 0) { cache = true; }
        query = this._sanetize_url(query);
        var options = this.generateHeader();
        // Response already in cache
        if (this.hasValidCachedValue(query) && cache) {
            return Observable.of(this.cache.get(query).value);
        }
        // Already waiting for the reponse
        if (this.inFlightObservables.has(query) && cache) {
            return this.inFlightObservables.get(query);
        }
        else {
            // Display request headers only if in dev mode
            if (!environment.production) {
                console.log(options);
            }
            this.inFlightObservables.set(query, new Subject());
            return this.http.get(query, { 'headers': options })
                .pipe(map(function (res) {
                _this.set(query, res, Date.now() + _this.DEFAULT_MAX_AGE);
                return res;
            }), catchError(function (err) {
                if (err.error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                    console.error('An error occurred:', err.error.message);
                }
                else {
                    console.error("Backend returned code " + err.status + ", body was: " + err.error);
                    if (err.status === 401 /*|| err.status === 400*/) {
                        localStorage.clear();
                        _this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
                            msg: HxEvent.LOGOUT
                        }));
                    }
                    else if (err.status === 403) {
                        _this.notifier.notify('error', 'You are not allowed to perform this action.');
                    }
                    else {
                        console.error("Backend returned code " + err.status + ", body was: " + err.error);
                    }
                }
                throw new Error((err.error && err.error.message) ? err.error.message : "An error occured");
            }));
        }
    };
    /**
     * Perform a PATCH
     *
     * @param {string} query
     *  API call url
     * @param {object} object
     *  the object to modify (only properties to be changed)
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  An observable
     */
    HttpService.prototype._patch = function (query, object, keyToClear) {
        var _this = this;
        query = this._sanetize_url(query);
        var options = this.generateHeader();
        var _object = object;
        if (this._is_not_json_string(object))
            _object = JSON.stringify(object);
        return this.http.patch(query, _object, { 'headers': options })
            .pipe(map(function (res) {
            if (keyToClear) {
                var keys = keyToClear.split(',');
                var _loop_1 = function (_key) {
                    _this.cache.forEach(function (value, key) {
                        if (key.indexOf(_key) !== -1) {
                            _this.cache.delete(key);
                        }
                    });
                };
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var _key = keys_1[_i];
                    _loop_1(_key);
                }
            }
            _this.set(query, res, Date.now() + _this.DEFAULT_MAX_AGE);
            return res;
        }), catchError(function (err) { return _this.handleError(err); }));
        /*
        .catch(err => this.handleError(err));
        */
    };
    /**
     * Perform a DELETE
     *
     * @param {string} query
     *  API call url
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    HttpService.prototype._delete = function (query, keyToClear) {
        var _this = this;
        var options = this.generateHeader();
        return this.http.delete(query, { 'headers': options })
            .pipe(map(function (response) {
            if (keyToClear) {
                var keys = keyToClear.split(',');
                var _loop_2 = function (_key) {
                    _this.cache.forEach(function (value, key) {
                        if (key.indexOf(_key) !== -1) {
                            _this.cache.delete(key);
                        }
                    });
                };
                for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                    var _key = keys_2[_i];
                    _loop_2(_key);
                }
            }
            return response;
        }), catchError(function (err) { return _this.handleError(err); }));
    };
    HttpService.prototype._is_not_json_string = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return true;
        }
        return false;
    };
    /**
     * Perform a POST
     *
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    HttpService.prototype._post = function (query, object, keyToClear) {
        var _this = this;
        var options = this.generateHeader();
        query = this._sanetize_url(query);
        var _object = object;
        if (this._is_not_json_string(object))
            _object = JSON.stringify(object);
        return this.http.post(query, _object, { 'headers': options })
            .pipe(map(function (response) {
            if (keyToClear) {
                var keys = keyToClear.split(',');
                var _loop_3 = function (_key) {
                    _this.cache.forEach(function (value, key) {
                        if (key.indexOf(_key) !== -1) {
                            _this.cache.delete(key);
                        }
                    });
                };
                for (var _i = 0, keys_3 = keys; _i < keys_3.length; _i++) {
                    var _key = keys_3[_i];
                    _loop_3(_key);
                }
            }
            var body = response;
            return body;
        }), catchError(function (err) { return _this.handleError(err); }));
    };
    /**
     * Perform a PUT
     *
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    HttpService.prototype._put = function (query, object, keyToClear) {
        var _this = this;
        query = this._sanetize_url(query);
        var options = this.generateHeader();
        return this.http.put(query, JSON.stringify(object), { 'headers': options })
            .pipe(map(function (response) {
            if (keyToClear) {
                _this.cache.forEach(function (value, key) {
                    if (key.indexOf(keyToClear) !== -1) {
                        _this.cache.delete(key);
                    }
                });
            }
            var body = response;
            return body;
        }), catchError(function (err) { return _this.handleError(err); }));
    };
    HttpService.prototype.getAuthToken = function () {
        return this.globalVariables.get("access_token");
    };
    /**
     * Generates headers for requesting API
     * TODO
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    HttpService.prototype.generateHeader = function (contentType) {
        var accessToken = this.globalVariables.get("access_token");
        var _headers = { 'Authorization': 'Bearer ' + accessToken };
        if (accessToken !== undefined) {
            _headers = { 'Authorization': 'Bearer ' + accessToken };
            if (contentType) {
                _headers = Object.assign({}, _headers, { 'Accept': contentType });
                // options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
            }
            else {
                // By default, application/json content type
                _headers = Object.assign({}, _headers, { 'Content-Type': 'application/json' });
                // _headers = Object.assign({}, _headers, { 'Content-Type': 'application/json; charset=utf-8' });
                // options = new RequestOptions({  headers: headers });
            }
            var headers = new HttpHeaders(_headers);
            return headers;
        }
        else {
            return null;
        }
    };
    /**
     * Handles error during server communication
     */
    HttpService.prototype.handleError = function (error, nopopup) {
        // We could use a remote logging infrastructure
        var errMsg;
        if (error instanceof HttpErrorResponse) {
            var msg = '';
            if (error.status === 403 || error.status === 404) {
                msg = 'You are not allowed to perform this action.';
            }
            if (error.error && error.error.errors && error.error.errors['__all__']) {
                msg = error.error.errors['__all__'];
            }
            if (msg !== '') {
                this.notifier.notify('error', msg);
            }
        }
        if (error instanceof Response) {
            if (error.status === 401) {
                this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
                    msg: error.status,
                    text: errMsg
                }));
            }
            var body = error.json() || '';
            errMsg = body.errors || JSON.stringify(body);
        }
        else {
            // errMsg.push(error.message ? error.message : error.toString());
        }
        if (nopopup !== true) {
            this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
                msg: HxEvent.ERROR,
                text: errMsg
            }));
        }
        return Observable.throw(error);
    };
    HttpService.prototype.getFromUrl = function (url) {
        var _this = this;
        if ((url === null) || (url === "")) {
            return new Observable(function (observer) {
                observer.next("");
                observer.complete();
            });
        }
        else {
            var options = this.generateHeader();
            return this.http.get(this.API_URL + url, { 'headers': options })
                .pipe(map(function (response) {
                return response;
            }), catchError(function (err) { return _this.handleError(err); }));
        }
    };
    return HttpService;
}());
export { HttpService };
