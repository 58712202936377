<div class="hx-form-panel">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="hx-form hx-form-aligned">

    <ul>

      <li class="hx-control-group">
        <label for="first_name">Firstname</label>
        <input type="text" id="first_name" formControlName="first_name" required maxlength="40" size="40">
        <div *ngIf="formErrors.first_name" class="alert alert-danger">
          {{ formErrors.first_name }}
        </div>
      </li>

      <li class="hx-control-group">
        <label for="last_name">Lastname</label>
        <input type="text" id="last_name" formControlName="last_name" required maxlength="40" size="40">
        <div *ngIf="formErrors.last_name" class="alert alert-danger">
          {{ formErrors.last_name }}
        </div>
      </li>

      <li class="hx-control-group">
        <label for="username">Username</label>
        <input type="text" id="username" formControlName="username" required readonly maxlength="40" size="40">
        <div *ngIf="formErrors.username" class="alert alert-danger">
          {{ formErrors.username }}
        </div>
      </li>
      <li class="hx-control-group">
        <label></label>
        <button type="submit" class="button button-green" [disabled]="!form.valid">Submit</button>
      </li>
    </ul>

  </form>

</div>
