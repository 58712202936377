import { OnInit } from '@angular/core';
var MetricsPanelComponent = /** @class */ (function () {
    function MetricsPanelComponent() {
        this.resultsRetrieved = false;
        this.positions = new Array();
    }
    MetricsPanelComponent.prototype.ngOnInit = function () {
        this.init();
    };
    MetricsPanelComponent.prototype.ngOnChanges = function () {
        this.init();
    };
    MetricsPanelComponent.prototype.intersect = function (a, b) {
        var result = a.filter(function (itemA) {
            return b.some(function (itemB) { return itemB === itemA; });
        });
        return result;
    };
    MetricsPanelComponent.prototype.init = function () {
        var ids = new Array();
        if (this.metricValues) {
            for (var _i = 0, _a = this.metricValues; _i < _a.length; _i++) {
                var metric = _a[_i];
                ids.push(metric.id);
            }
        }
        this._metricIds = new Array();
        this._metricValues = new Array();
        var current_position = 0;
        var _loop_1 = function (metricGroup) {
            if (this_1.intersect(ids, metricGroup.ids).length > 0
                && this_1.intersect(ids, metricGroup.ids).length === metricGroup.ids.length
                && !this_1._metricIds.find(function (item) { return item.title === metricGroup.title; })) {
                this_1._metricIds.push(metricGroup);
                var _loop_2 = function (id) {
                    var metric = this_1.metricValues.filter(function (entry) {
                        return entry.id === id;
                    });
                    this_1._metricValues.push(metric[0]);
                };
                for (var _i = 0, _a = metricGroup.ids; _i < _a.length; _i++) {
                    var id = _a[_i];
                    _loop_2(id);
                }
                this_1.positions.push(current_position);
                current_position = current_position + metricGroup.ids.length;
                /*metric.ids
 
                const activitytype = this.values.filter(function (entry) {
                    return entry.resource_uri === range.context.activitytype;
                });*/
            }
        };
        var this_1 = this;
        for (var _b = 0, _c = this.metricIds; _b < _c.length; _b++) {
            var metricGroup = _c[_b];
            _loop_1(metricGroup);
        }
        this.results = this._metricValues;
        this.resultsRetrieved = true;
        //    });
        /*        this.reportService.get(this.selectedUser, metricList, this.start, this.end).subscribe(data => {
                    this.results = data.metrics;
                    this.resultsRetrieved = true;
                })
        */
    };
    return MetricsPanelComponent;
}());
export { MetricsPanelComponent };
