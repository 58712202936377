import { User } from "./user";
import * as moment from 'moment/moment';
import { HxToolsTime } from '../shared/hx-tools/hx-tools-time';
import { ApiService } from '../core/services/api.service';

export class Record {

    id: number;

    duration: string;
    duration_millisecondes: number;

    start: number;

    end: number;

    start_date: string;
    humanized_start_date: string;

    user: string;
    _user: User;

    username: string;
    activity: Array<string>;
    training_routine: Array<string>;

    metrics: Array<any>;


    constructor(jsonStr: string, private apiService: ApiService) {
        let jsonObj: any = JSON.parse(jsonStr);
        for (let prop in jsonObj) {
            this[prop] = jsonObj[prop];
        }
    }


    public getDuration() {
        return this.end - this.start;
    }

    public calculate() {

        // todo : modify the directive table to call individual function instead
        this.duration_millisecondes = 1000 * (this.end - this.start) / 256;
        this.duration = HxToolsTime.millisecond2HMSInt(this.duration_millisecondes);
        //this.duration = moment.duration(this.duration_millisecondes, 'milliseconds').humanize();

        // retrieve those info from the labels (not implemented in API yet)
        this.activity = [];
        this.training_routine = [];

        const m = moment(this.start_date);
        if (m.isValid()) {
            this.humanized_start_date = m.format('DD/MM/YYYY, H:mm a');
        } else {
            this.humanized_start_date = "?";
        }

        // Check if the field user returned by the API is an object or an URI
        if (this.user !== null && typeof this.user === 'object') {
            this._user = this.user;
        } else {
            this.apiService.getFromUri(this.user).subscribe(data => {
                
                this._user = data;
            });
        }

    }

}
