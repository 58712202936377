import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['../../../../../../assets/stylesheets/core/button.scss',
    '../../../../../../assets/stylesheets/core/grid.scss',
    '../../../../../../assets/stylesheets/core/grid-base.scss',
    '../../../../../../assets/stylesheets/core/icon.scss',
    '../../../../../../assets/stylesheets/button.scss']
})
export class CalendarHeaderComponent {
  @Input() view: string;

  @Input() metrics: boolean;

  @Input() displayRecords: boolean;

  @Input() displayRanges: boolean;

  @Input() viewDate: Date;

  @Input() locale: string = 'en';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() metricsChange: EventEmitter<boolean> = new EventEmitter();

  @Output() displayRecordsChange: EventEmitter<boolean> = new EventEmitter();

  @Output() displayRangesChange: EventEmitter<boolean> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}