/* tslint:disable: member-ordering forin */
import { environment } from '../../../environments/environment';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiQuery, ApiService, DataService } from '../../core/services';
import 'rxjs/add/operator/mergeMap';
import { Message } from '../../shared/components/growl/message';
import { PopupService } from '../../shared/components/popup/popup.service';
import { ConfirmationService } from '../../shared/components/confirmation';
import { MetricOneStatusService } from '../../shared/components/metric/metric-one-status/metric-one-status.service';
import * as moment from 'moment/moment';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { Subscription } from 'rxjs/Subscription';
import { AlertService } from '../../shared/components/alert/alert.service';
import { Kpi } from '../../models';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { HelpService } from '../../shared/components/help/help.service';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
import { HxToolsGarmentSize } from '../../shared/hx-tools/hx-tools-garment-size';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as _ from 'lodash';
import { COLORS, WRITABLE_KPIS, LINK_ACTIVITY, PATTERNS, DISPLAY_STATUS, SHOW_DISTRIBUTION, SHOW_EXTRA, OPTIONS_AXIS, FITNESS_SECTIONS_KPIS, DISTRIBUTION_CHART } from '../../config';
import { decrementalSort } from '../../shared/hx-tools';
import { EXPLANATIONS } from './explanations';

@Component({
  selector: 'app-fitness',
  templateUrl: './fitness.component.html',
  styleUrls: ['./fitness.component.scss',
    '../../../assets/stylesheets/core/section.scss',
    '../../../assets/stylesheets/core/table.scss',
    '../../../assets/stylesheets/button.scss']
})
export class FitnessComponent extends HelperComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Messages for growl
   */
  public msgs: Message[] = [];

  /**
   * Minimal number of points to display historical
   */
  minimumNbrPointsForHistoric = 2;

  public salt = 0;

  public kpis: Array<any>;

  /**
   * Telling whether the data are being retrieved from the server or proceeded.
   */
  public loading = true;

  /**
   * Current subject object
   */
  public user: any;

  /**
   * All the KPI values to display on charts
   */
  values: Array<any>;

  distributions: Array<any>;

  private subscriptionTasks: Subscription;

  /**
  * All the KPI ids
  */
  ids: Array<any>;

  /**
   * Boolean array telling which charts are displayed
   */
  //displayedChart: Array<any>;

  /**
   *  Time range
   */
  defaultRange = {
    year: 1,
    month: 0,
    week: 0,
    day: 0
  };

  /* Garment suggested size */
  suggested_size = null;

  maxTime = 0;
  minTime = Number.MAX_SAFE_INTEGER;

  /**
   * X Axis format to pass to app-bchart
   */
  xAxis = {
    min: null,
    type: 'datetime',
    ordinal: false,
    dateTimeLabelFormats: { // don't display the dummy year
      second: '%Y-%m-%d<br/>%H:%M:%S',
      minute: '%Y-%m-%d<br/>%H:%M',
      hour: '%Y-%m-%d<br/>%H:%M',
      day: '%d/%m/%y',
      week: '%d/%m/%y',
      month: '%d/%m/%y',
      year: '%Y'
    },
    title: {
      text: null
    }
  };

  xAxisGaussian = {
    visible: false
  };

  showedExplanations = {
    "hr_max": false,
    "hr_recov": false,
    "hr_rest": false,
    "vo2_max": false,
    "weight": false,
    "height": false,
    "bmi": false,
    "total_sleep_time": false,
    "sleep_latency": false,
    "sleep_efficiency": false
  };

  profileId: number;
  kpiSections = [];

  // Does not display help icons straight away. Wait for the section rendered.
  displayHelp = false;

  start: number;

  timeOptionsAxis = {
    yAxis: [
      {
        title: {
          text: 'HH:MM'
        },
        labels: {
          formatter: function () {
            if (Number.isInteger(this.value)) {
              const time = this.value;
              const hours = Math.floor(time / 3600);
              const _mins = Math.round((time % 3600) / 60);
              let duration = "";
              if (_mins < 10) {
                duration = hours + ':0' + _mins;
              } else {
                duration = hours + ':' + _mins;
              }
              return duration;
            } else {
              return this.value;
            }
          }
        }
      }
    ]
  };

  /*
   constains the HTML for explanations for each KPI provided on the view
   */
  explanations;

  /**
   * CONSTRUCTOR
   */
  constructor(
    protected store: Store<AppState>,
    private apiService: ApiService,
    private eventAggregator: EventAggregator,
    private globalVariables: GlobalVariables,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    public helpService: HelpService,
    private popupService: PopupService,
    private alertService: AlertService,
    private metricOneStatusService: MetricOneStatusService) {

    super(helpService);
    this.explanations = EXPLANATIONS;

    // Display dialog box to add new kpi value
    this.metricOneStatusService.tasksConfirmed$.subscribe(
      kpi => {
        this.add(kpi.title, kpi.unit, kpi.name, kpi.pattern);
      }
    );

    this.subscriptionTasks = this.popupService.tasksConfirmed$.subscribe(
      task => {
        if (task.action === "delete") {
          this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this KPI?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
              this.apiService.deleteKPI(task.resource_uri, 'fitness,profile').subscribe(() => {
                if (!environment.production) { }
                this.msgs = [];
                this.msgs.push({
                  severity: 'success',
                  summary: 'Confirmed',
                  detail: 'The KPI has been successfully deleted.'
                });
                this._init(true);
                this.salt = Math.random(); // todo
              });
            }
          });
        }
        if (task.action === "update") {
          this.confirmationService.confirm({
            message: 'Are you sure that you want to update this KPI?',
            header: 'Update Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
              const o: any = new Object();
              o.value = parseInt(task.value, null);
              this.apiService.updateKPI(task.resource_uri, o).subscribe(function () {
                this.msgs = [];
                this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'The KPI has been successfully updated.' });
                this._init(true);
                this.salt = Math.random(); // todo
              });
            }
          });
        }
        if (task.action === "save") {
          const unixDate = moment(task.date).format("X");
          this.apiService.saveKPI(task.kpi,
            Number(task.value), this.user.profile.id, Math.round(256 * parseInt(unixDate, null))).subscribe(kpi => {
              this.msgs = [];
              this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'The KPI ' + kpi['name'] + ' has been successfully added.'
              });
              // We add the value without retrieving it from the server.
              // Ulgy : The server shoud returned the new list of kpis
              this.values[kpi['kpi']].push([1000 * kpi['start'] / 256, kpi['value'], kpi['resource_uri']]);
              this.values[kpi['kpi']].sort(decrementalSort);
              this.ids[kpi['kpi']][1000 * kpi['start'] / 256] = kpi['resource_uri'];
              this.salt = Math.random(); // todo
              this._init(true);
            });
        }
      });

  }

  /**
   * Building the form on init
   */
  ngOnInit(): void {


    // Lists to events from outside the component
    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
    this._init();
  }

  /**
   * Actions performed after view init
   */
  ngAfterViewInit() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.FSS__UPDATE
      }));
  }

  /**
   * Treatment of messages received from event aggregator
   */
  private onMessageReceived = (payload: MessageSentEventPayload) => {
    if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
      // user switch thus the view is updated
      this._init();
    } else if (payload.object.msg === HxEvent.CHART__EDIT_KPI) {
      this.edit(payload.object); // todo
    }
  }

  /**
   * Init data
   */
  _init(reload?: boolean): void {

    if (!reload) {
      this.loading = true;
    }

    // Get the current selected subject
    // If the current subject is not defined, the subject is the logged user
    this.user = this.globalVariables.get('currentSubject');
    if (this.user === undefined) {
      this.user = this.globalVariables.get('currentUser');
    }

    // Check whether profile is an uri or an object
    // Server does not provide always the same...
    let resourceUri;
    if (this.user.profile !== null && typeof this.user.profile === 'object') {
      resourceUri = this.user.profile.resource_uri;
      this.profileId = this.user.profile.id;
    } else {
      resourceUri = this.user.profile;
      this.profileId = resourceUri.split('/')[3];
      // We should handle an error with injectable..
      /*if (!Number.isInteger(this.profileId)) {
        this.router.navigate(['/']);
      }*/
    }

    let kpis = new Array<Kpi>();

    this.apiService.getFromUri(resourceUri).flatMap(data => {

      // We retrieve the time range from preferences stored on the server 
      if (data.preferences && data.preferences.healthStatusRangeSelector) {
        this.defaultRange = data.preferences.healthStatusRangeSelector;
      }
      this.kpiSections = FITNESS_SECTIONS_KPIS;

      // Start range calculation
      this.start = moment().subtract(this.defaultRange.day, "day")
        .subtract(this.defaultRange.week, "week").subtract(this.defaultRange.month, "month").
        subtract(this.defaultRange.year, "year").unix();

      kpis = this._parseFitness(data['fitness']); // todo : try or if
      this._populate(kpis);

      return this.apiService.getQuery(ApiQuery.get_metric_distribution_for_profile_id, { id: this.profileId });
    }).flatMap(data => {

      this.distributions = new Array<any>();
      for (const dist of data.objects) {
        this.distributions[dist.name] = this._buildDistribution(dist, kpis[dist.name].value);
      }

      return this.apiService.getHistoricalFitness(this.profileId, this.start);
    }).subscribe(data => {

      let that = this;
      that.values = new Array<any>();
      that.ids = new Array<any>();
      const historical = new Array<any>();

      for (let kpiSection of that.kpiSections) {
        for (let kpi of kpiSection.kpis) {

          historical[kpi] = JSON.parse(JSON.stringify(data.objects)).filter(function (entry) {
            return entry.kpi === kpi;
          });
          // Sort the kpi values as they may not be in the chronological order when
          // received by backend
          historical[kpi].sort(function (a, b) {
            return a.start - b.start;
          });

          that.values[kpi] = new Array<any>();
          that.ids[kpi] = new Array<any>();
          for (const kpiHistorical of historical[kpi]) {

            if (kpiHistorical.start != null) {
              const resource_uri = kpiHistorical.resource_uri ? kpiHistorical.resource_uri : null;
              const range = kpiHistorical.range ? kpiHistorical.range : null;
              const historical_start = 1000 * kpiHistorical.start / 256;

              if (kpiHistorical.unit.si_short === 's') {
                let time = kpiHistorical.value;
                time = Number((time).toFixed(3));
                that.values[kpi].push([historical_start,
                  time, resource_uri, range]);
              } else {
                that.values[kpi].push([historical_start, Math.round(100 * kpiHistorical.value) / 100, resource_uri, range]);
              }

              that.ids[kpi][historical_start] = [resource_uri, range];

              // Limits of the X axis.
              if (historical_start > this.maxTime) {
                this.maxTime = historical_start;
              }
              if (historical_start < this.minTime) {
                this.minTime = historical_start;
              }

            }
          }
        }
      }

      if (!reload) {
        this.loading = false;
      }

      setTimeout(function () {
        that.afterViewInit();
        this.displayHelp = true;
      }, 3000);


    });
  }

  _buildDistribution(dist, kpiValue) {
    let distribution = new Array<any>();
    for (let i = 0; i < dist.value[1].length; i++) {
      if ((kpiValue > dist.value[0][i][0]) && (kpiValue <= dist.value[0][i][1])) {
        distribution.push({
          marker: DISTRIBUTION_CHART, y: dist.value[1][i]
        });
      } else {
        distribution.push({
          marker: {}, y: dist.value[1][i]
        });
      }
    }
    return distribution;
  }

  _parseFitness(fitness) {

    let size = [0, 0];
    let kpis = Array<Kpi>();
    for (let i = 0; i < fitness.length; i++) {
      let status = fitness[i].zone_description;
      let unit = fitness[i].unit.si_short;
      if (status && status.toLowerCase() === "na") { status = null; }
      if (unit && unit.toLowerCase() === "na") { unit = null; }
      if (unit && unit.toLowerCase() === "na") { unit = null; }
      let statusColor = "success";
      if ((fitness[i].zone_description === "too long") ||
        (fitness[i].zone_description === "not recommended")) {
        statusColor = "warning";
      }
      kpis[fitness[i].kpi] = {
        "title": fitness[i].name,
        "name": fitness[i].kpi,
        "value": Math.round(100 * fitness[i].value) / 100,  // decimal precision
        "status": status,
        "displayStatus": DISPLAY_STATUS[fitness[i].kpi],
        "showDistribution": SHOW_DISTRIBUTION[fitness[i].kpi],
        "showExtra": SHOW_EXTRA[fitness[i].kpi],
        "unit": unit,
        "statusColor": statusColor,
        "colors": COLORS[DISPLAY_STATUS[fitness[i].kpi]],
        "writable": WRITABLE_KPIS[fitness[i].kpi],
        "linkActivity": LINK_ACTIVITY[fitness[i].kpi],
        "pattern": PATTERNS[fitness[i].kpi],
      };
      if (fitness[i].kpi === "abdominal_circumference") {
        size[1] = fitness[i].value;
      }
      if (fitness[i].kpi === "thoracic_circumference") {
        size[0] = fitness[i].value;
      }
    }
    this.suggested_size = HxToolsGarmentSize.getSuggestedSize(size[0], size[1]);
    return kpis;
  }

  _populate(kpis) {
    for (let j = 0; j < this.kpiSections.length; j++) {
      this.kpiSections[j].pkpis = new Array<any>();
      if (this.kpiSections[j].kpis) {
        for (let i = 0; i < this.kpiSections[j].kpis.length; i++) {
          this.kpiSections[j].pkpis[i] = kpis[this.kpiSections[j].kpis[i]];
          if (this.kpiSections[j].kpis[i] !== undefined) {
            this.kpiSections[j].pkpis[i].optionsAxis = OPTIONS_AXIS;
            // if unit is s (seconds) then we customize the yAxis to display in HH:MM format
            if (this.kpiSections[j].pkpis[i].unit === 's') {
              this.kpiSections[j].pkpis[i].optionsAxis = this.timeOptionsAxis;
            }
            this.kpiSections[j].pkpis[i].selected = false;
            if (i === 0) this.kpiSections[j].pkpis[i].selected = true; // by default display first kpi
            this.kpiSections[j].pkpis[i].displayed = false;
          }
        }
        this.kpiSections[j].pkpis[0].displayed = true;
      }
    }
  }

  onNumberChanged(ev) {
    this.start = ev.date.unix();
    if (ev.range && ev.range.length === 4) {
      this.defaultRange = {
        day: ev.range[0],
        week: ev.range[1],
        month: ev.range[2],
        year: ev.range[3]
      };
    }
  }

  updatePeriod() {
    const resourceUri = this.globalVariables.get("profileUri");
    this.apiService.getFromUri(resourceUri).flatMap(data => {
      let preferences = data.preferences;
      preferences.healthStatusRangeSelector = this.defaultRange;
      return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
    }).subscribe(() => {
      if (!environment.production) { }
      this.msgs = [];
      this.msgs.push({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'The range has been saved in your preferences. The charts are being updated.'
      });
      this._init();
    },
      error => {
        console.error(error);
        this.alertService.error(error[0]);
      });
  }

  /**
   * Display selected chart
   */
  displayChart(i, j) {

    for (let k = 0; k < this.kpiSections[i].pkpis.length; k++) {
      this.kpiSections[i].pkpis[k].displayed = false;
    }
    this.kpiSections[i].pkpis[j].displayed = true;

    for (let k = 0; k < this.kpiSections[i].pkpis.length; k++) {
      this.kpiSections[i].pkpis[k].selected = false;
    }
    this.kpiSections[i].pkpis[j].selected = true;
  }

  /**
   * Display dialog box for editing KPI
   */
  add(kpi: string, unit: string, name: string, pattern: string) {
    this.popupService.confirm({
      message: '',
      header: 'Add ' + kpi,
      icon: '',
      kpi: name,
      range: null,
      resource_uri: null,
      unit: unit,
      acceptVisible: true,
      rejectVisible: true,
      updateVisible: false,
      deleteVisible: false,
      pattern: pattern ? pattern : "[0-9]+([\.,][0-9]+)?",
      accept: () => { }
    });
  }

  /**
   * Display dialog box for editing KPI
   */
  edit(object: any) {

    if (LINK_ACTIVITY[object.label] === true) {

      let value = object.value;
      let unit = object.unit;

      if (object.unit === 's') {
        const time = object.value;

        // If time is longer than 1 second, otherwise keep it unchanged
        if (time > 1) {
          value = HxToolsTime.second2HHMM(time);
          unit = "hh:mm";
        } else {
          // todo : refactoring, loop instead of multiples if
          if (value * 10 >= 1) {
            value = Number((value).toFixed(2));
          }
          if (value * 100 >= 1) {
            value = Number((value).toFixed(3));
          }
        }
      }

      this.popupService.confirm({
        message: '',
        header: 'Edit ' + object.title,
        icon: '',
        range: object.range,
        resource_uri: object.resource_uri,
        kpi: object.label,
        value: value,
        date: object.date,
        deleteVisible: true,
        updateVisible: true,
        acceptVisible: false,
        rejectVisible: true,
        unit: unit,
        pattern: PATTERNS[object.label] ? PATTERNS[object.label] : ".*",
        update: () => { },
        delete: () => { }
      });
    }
  }

  /**
   * Display selected explanation
   */
  showExplanations(kpiName) {
    if (this.showedExplanations[kpiName]) {
      this.showedExplanations[kpiName] = false;
    } else {
      this.showedExplanations[kpiName] = true;
    }
  }

  ngOnDestroy() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
    this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    this.subscriptionTasks.unsubscribe();
  }

}

