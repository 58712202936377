<div class="form">
  <form name="activityForm" role="form" class="hx-form" autocomplete="off" novalidate="">
    <div class="form-title" *ngIf="mode == 'add'">Add activity</div>
    <div class="form-title" *ngIf="mode == 'edit'">Edit activity</div>

    <input name="startDate" placeholder="start" readonly type="text" value="{{ from | dateHumanize }}" datetime="yyyy-MM-dd HH:mm:ss" required="">
    <input name="endDate" placeholder="end" readonly type="text" value="{{ to | dateHumanize }}" datetime="yyyy-MM-dd HH:mm:ss" required="">

    <input type="text" [(ngModel)]="name" class="form-control ng-pristine ng-invalid ng-invalid-required ng-untouched" name="name"
      placeholder="Enter note here" required="">

      <hx-select name="activityType" [options]="activityTypes" placeholder="Select one" [allowClear]="true" [(ngModel)]="selectedActivityType"
        (opened)="onSingleOpened()" (closed)="onSingleClosed()" (selected)="onSingleSelected()" (deselected)="onSingleDeselected()">
        </hx-select>

        <button *ngIf="mode == 'add'" [disabled]="!from || !to || !name || !selectedActivityType" type="button" (click)="save()" class="button">Add</button>
        <button *ngIf="mode == 'edit'" type="button" (click)="update()" class="button">Save</button>
        <button *ngIf="mode == 'edit'" (click)="delete()" class="button">
                <i class="hx-icon hx-icon-delete"></i> Delete
            </button>
        <button type="button" (click)="reset()" class="button">
                <i class="hx-icon hx-icon-times"></i> Cancel
                </button>

  </form>
</div>

<confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>

