import { Pipe, PipeTransform } from '@angular/core';

/**
 */
@Pipe({ name: 'activityTypeToIconClass' })
export class ActivityTypeToIconClassPipe implements PipeTransform {
  transform(str: string): string {
    if (!str) {
      return 'hx-icon-other';
    }
    let icon = str.toLowerCase().replace(/\s/g, '-');

    if (icon.match(/cross-country-ski/i)) { icon = "cross-country-skiing"; }
    else if (icon.match(/downhill-ski/i)) { icon = "downhill-skiing"; }
    else if (icon.match(/hike/i)) { icon = "hiking"; }
    else if (icon.match(/jog|run|sprint|cooper/i)) { icon = "running"; }
    else if (icon.match(/meditate/i)) { icon = "meditating"; }
    else if (icon.match(/mountain-bike/i)) { icon = "mountain-biking"; }
    else if (icon.match(/rest-test/i)) { icon = "5-minute-rest-test"; }
    else if (icon.match(/roller-skate/i)) { icon = "roller-skating"; }
    else if (icon.match(/roller-skate/i)) { icon = "rowing"; }
    else if (icon.match(/skate/i)) { icon = "skating"; }
    else if (icon.match(/swim/i)) { icon = "swimming"; }
    else if (icon.match(/row/i)) { icon = "rowing"; }
    else if (icon.match(/weight-training/i)) { icon = "weight-lifting"; }

    return 'hx-icon-' + icon;
  }
}

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {

    transform(value: any) {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }

}


@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
