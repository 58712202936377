import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

/** */
@Injectable()
export class RecordViewService {

    // Observable string sources
    public tasks = new Subject<any>();

    // Observable string streams
    tasksConfirmed$ = this.tasks.asObservable();

    postTask(task: any) {
        this.tasks.next(task);
    }

}
