<div class="big_title">
    <div *ngIf="user && user.first_name != '';then firstname else nofirstname"></div>
    <ng-template #firstname>{{ user.first_name }}'s Timeline</ng-template>
    <ng-template #nofirstname>Timeline</ng-template>
</div>

<section>
    <div *ngFor="let announcement of announcements" style="margin:4px 0 4px 0; opacity: 0.7; padding: 10px; height: 50px; background-color: rgba(254, 197, 46, 1); border: 1px solid rgba(0, 0, 0, 0.7);  color: black">
        <div class="hx-g">
            <div class="hx-u-23-24">
                <b style="font-size:120%">{{ announcement.title }}</b><br/>
                {{ announcement.body }}
            </div>
            <!--div class="hx-u-1-24 ">
                <i class="hx-icon hx-icon-times hx-pull-right"></i>
            </div-->
        </div>
    </div>
</section>

<section #filter id="filter">
    <div class="hx-g header">
        <div class="hx-u-24-24">
            <div class="hx-u-23-24">
                <div class="title">
                    Filter
                </div>
            </div>
            <div class="hx-u-1-24">
                <img *ngIf="dates" (click)="openModal('filter')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
            </div>
        </div>
    </div>

    <div class="hx-g form">
        <div class="hx-u-12-24">
            <div style="background-color: white; border-right: 1px solid black;height: 30px; ">
                <form #myForm="ngForm" novalidate>
                    <div *ngIf="isInitiliazed" style="padding:4px;color: #555;">
                        <span style="font-size: 12px;">Time range</span> :
                        <input type="text" name="daterange" (ngModelChange)="rangeClicked($event)" [showCustomRangeLabel]="true" [showRangeLabelOnInput]="true"
                            [ranges]="dateRanges" [locale]="{applyLabel: 'OK', format: 'DD/MM/YYYY'}" hxDaterangepicker [(ngModel)]="selected"
                            class="daterangepicker" size="40" readonly>
                    </div>
                </form>
            </div>
        </div>
        <div class="hx-u-12-24">
            <div style="background-color:white;color: #555;font-size: 14px;height:100%;border-right: 1px solid black;">
                <div style="padding-top:8px;padding-left:4px;">
                    <label>
                <input [checked]="!showRecords" (change)="showRecords = !showRecords" type="checkbox" data-toggle="toggle">Show only activities
            </label>
                </div>
            </div>
        </div>


    </div>
</section>

<ng-container *ngIf="loading">
    <section>
        <div id="spinner">
            <div></div>
        </div>
    </section>
</ng-container>

<ng-container>

    <div class="info" *ngIf="lastRecord &&  moreTh5daysInactive">There are no records since {{ lastRecord.start | dbTimeToDate }} ({{ lastRecord.start | timeInterval }} ago)</div>

    <ng-container *ngIf="boxes[0] && boxes[0].displayed">

        <section #quickview id="quickview" class="metrics">
            <div class="title-wrapper hx-g">
                <div class="hx-u-23-24">
                    <div class="title">
                        Quick View
                    </div>
                </div>
                <div class="hx-u-1-24">
                    <img *ngIf="dates" (click)="openModal('quickview')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
                </div>
            </div>
            <monthly-report *ngIf="!loading && selected" #report [selectedPeriod]="selected" [start]="start" [end]="end" [offsetMonth]="offsetMonth">
            </monthly-report>
        </section>
    </ng-container>
    
    <section #timeline id="timeline" *ngIf="boxes[1] && boxes[1].displayed">
        <div class="hx-g header">
            <div class="hx-u-22-24">
                <div class="title">
                    Timelines
                </div>
            </div>
            <div class="hx-u-1-24">
                <div class="occurence tip">
                    <ng-container *ngIf="showRecords">
                        {{ dates.length }}
                    </ng-container>
                    <ng-container *ngIf="!showRecords">
                        {{ totatDaysWithRanges }}
                    </ng-container>
                    <span>Nbr&nbsp;of&nbsp;activities</span>
                </div>
            </div>
            <div class="hx-u-1-24">
                <img *ngIf="dates" (click)="openModal('timeline')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
            </div>
            <!--div class="hx-u-1-24">
                <div *ngIf="dates" class="occurence tip">
                    {{ dates.length }}
                    <span>Nbr&nbsp;of&nbsp;days</span>

                </div>
            </div-->
        </div>
        <div>
            <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" (scrolled)="displayMore()">
                <ng-container *ngIf="!showRecords">
                    <ng-container *ngFor="let date of rdates; let i = index">
                        <rrbox *ngIf="((ranges.get(date.date) && ranges.get(date.date).length > 0)) && (i < maxDays)" time="diurnal" [userId]="user.id"
                            start="0" end="24" [date]="date" metricList="149,71,44,22" [records]="records.get(date.date)" [ranges]="ranges.get(date.date)"></rrbox>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="showRecords">
                    <ng-container *ngFor="let date of dates; let i = index">
                        <rrbox *ngIf="((ranges.get(date.date) && ranges.get(date.date).length > 0) || showRecords) && (i < maxDays)" time="diurnal"
                            [userId]="user.id" start="0" end="24" [date]="date" metricList="149,71,44,22" [records]="records.get(date.date)"
                            [ranges]="ranges.get(date.date)"></rrbox>
                    </ng-container>
                </ng-container>

            </div>

        </div>

    </section>

</ng-container>

<app-growl [value]="msgs"></app-growl>

<help-modal id="timeline-help" style="display: none;">
    <p>Summary of your different metrics for the all your records of the day and a timeline that displays when the records /
        activities took place.<br>A Record is represented by <img width="26" src="/assets/icons/blue_gray_box.svg">, while
        an Activity is represented by
        <img width="26" src="/assets/icons/blue_box.svg">, superimposing the Record box (<img width="26" src="/assets/icons/blue_gray_box.svg">).
        To directly access the record of your choice, click on <img width="26" src="/assets/icons/blue_gray_box.svg">. To
        directly access an Activity, click on <img width="26" src="/assets/icons/blue_box.svg">.</p>
    <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>

<help-modal id="filter-help" style="display: none;">
    <p>Click on <img height="24" src="/assets/icons/calendar.svg"> to narrow down your search for records or activities to a
        specific or custom time range. Tick the box <img height="24" src="/assets/icons/case.svg"> « Show Activities recorded
        with Hexoskin App » to display only the Activities that were launched via the Hexoskin App.</p>
    <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>

<help-modal id="quickview-help" style="display: none;">
    <p>Chart showing the heart rate average (metric by default) for the time range set in the "Filter" section ("Last record"
        by default). Click on the other metrics to have them displayed in the chart. Change the time range in the "Filter"
        section.
    </p>
    <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>