import { Component, OnChanges, Input, AfterViewInit } from "@angular/core";
import { MapTask } from "./map.task";
import { MapService } from "./map.service";
import { MapAction } from "./action";


import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';

import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';

declare let google: any;

const FLAG_SIZE = 18;

/**
* Google map
*/
@Component({
    selector: "app-map",
    template: `
        <div  id="googleMap33" #routeMap style="width:100%;height:380px;"></div>
    `,
    styleUrls: []
})
export class MapComponent implements AfterViewInit, OnChanges {

    @Input() gpsPositions: Array<any>;

    // @ViewChild("routeMap") private container; // to do

    public map: any;

    private mapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#1d2c4d"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#8ec3b9"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#1a3646"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#4b6878"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#64779e"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#4b6878"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#334e87"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#023e58"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#283d6a"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#6f9ba5"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#1d2c4d"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#023e58"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#3C7680"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#304a7d"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#98a5be"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#1d2c4d"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#2c6675"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#255763"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#b0d5ce"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#023e58"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#98a5be"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#1d2c4d"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#283d6a"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#3a4762"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#0e1626"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#4e6d70"
                }
            ]
        }
    ];

    private flag = {
        path: "M51.421,22.593L10,3.362V1c0-0.553-0.448-1-1-1S8,0.447,8,1v3v33v3v19c0,0.553,0.448,1,1,1s1-0.447,1-1V40.681 l41.366-16.251c0.373-0.146,0.622-0.501,0.634-0.9C52.012,23.13,51.784,22.762,51.421,22.593z M10,38.533V37V5.566l38.46,17.857 L10,38.533z",
        fillColor: 'white',
        fillOpacity: 1,
        scale: 0.2,
        strokeColor: 'white',
        strokeWeight: 1
    };

    private start = {
        url: '/assets/map/start.svg',
        size: new google.maps.Size(FLAG_SIZE, FLAG_SIZE),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(FLAG_SIZE / 2, FLAG_SIZE)
    };

    private stop = {
        url: '/assets/map/stop.svg',
        size: new google.maps.Size(FLAG_SIZE, FLAG_SIZE),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(FLAG_SIZE / 2, FLAG_SIZE)
    };


    constructor(private mapService: MapService,
        private eventAggregator: EventAggregator) {
    }

    ngAfterViewInit() {

        this.init();
    }

    ngOnChanges() {
        this.init();
    }


    init() {
        let that = this;

        let positions = new Array<any>();
        let bounds = new google.maps.LatLngBounds();

        let i = 0;
        let center: any;
        let trackId;
        let startingPosition, endingPosition;
        for (let gps of that.gpsPositions) {
            if (i === 0 || gps.track === trackId) { // We only keep the first track (there could be more than one ?)
                if (i === 0) startingPosition = gps;
                else endingPosition = gps;
                // const utime = new Date(gps.time);
                // let mtime = utime.getTime();
                bounds.extend({
                    lng: gps.position[0],
                    lat: gps.position[1]
                });
                positions.push({
                    lng: gps.position[0],
                    lat: gps.position[1]
                });
            }
            if (i === 0) {
                trackId = gps.track;
                center = new google.maps.LatLng(gps.position[1], gps.position[0]);
            }
            i++;
        }
        let mapProp = {
            center: center,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: that.mapStyle
        };
        that.map = new google.maps.Map(document.getElementById("googleMap33"), mapProp);

        let path = new google.maps.Polyline({
            path: positions,
            geodesic: true,
            strokeColor: '#F9B233',
            strokeOpacity: 1.0,
            strokeWeight: 2
        });

        //path.addListener('mouseover', this.show());

        google.maps.event.addListener(that.map, 'mousemove', function (h) {
            let latlng = h.latLng;
            //alert(path);
            let needle = {
                minDistance: 100, //silly high
                index: -1,
                latlng: null
            };
            path.getPath().forEach(function (routePoint, index) {
                let dist = google.maps.geometry.spherical.computeDistanceBetween(latlng, routePoint);
                if (dist < needle.minDistance) {
                    needle.minDistance = dist;
                    needle.index = index;
                    needle.latlng = routePoint;
                }
            });
            // The closest point in the polyline


            if (needle.minDistance < 100) that.mapService.postTask(new MapTask(MapAction.MOUSEMOVE_ON_ROUTE_PATH,
                {
                    "index": needle.index,
                    "gps": that.gpsPositions[needle.index]
                }
            ));

            if (needle.minDistance < 100) that.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                {
                    msg: HxEvent.MAP__MOUSEHOVER_ROUTEPATH,
                    index: needle.index,
                    gps: that.gpsPositions[needle.index]
                }));


            if (needle.minDistance >= 100) that.mapService.postTask(new MapTask(MapAction.MOUSEOUT_ROUTE_PATH));

            if (needle.minDistance >= 100) that.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                {
                    msg: HxEvent.MAP__MOUSEOUT_ROUTEPATH
                }));


            // The clicked point on the polyline
            //alert(latlng);
        });


        google.maps.event.addListener(path, 'click', function (h) {
            let latlng = h.latLng;
            //alert(path);
            let needle = {
                minDistance: 9999999999, //silly high
                index: -1,
                latlng: null
            };
            path.getPath().forEach(function (routePoint, index) {
                let dist = google.maps.geometry.spherical.computeDistanceBetween(latlng, routePoint);
                if (dist < needle.minDistance) {
                    needle.minDistance = dist;
                    needle.index = index;
                    needle.latlng = routePoint;
                }
            });
            // The closest point in the polyline



            that.addMarker(latlng);
            that.mapService.postTask(new MapTask(MapAction.CLICK_ON_ROUTE_PATH, needle.index));
            // The clicked point on the polyline
            //alert(latlng);
        });



        const startMarker = new google.maps.Marker({
            position: new google.maps.LatLng(startingPosition.position[1], startingPosition.position[0]),
            map: this.map,
            icon: this.start
        });


        const endMarker = new google.maps.Marker({
            position: new google.maps.LatLng(endingPosition.position[1], endingPosition.position[0]),
            map: this.map,
            icon: this.stop,
        });

        path.setMap(this.map);
        that.map.fitBounds(bounds);
    }

    show() {

    }

    addMarker(latlng) {
        //let marker = 
        return new google.maps.Marker({  // todo
            position: latlng,
            map: this.map,
            icon: this.flag,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(100, 100),
            scaledSize: new google.maps.Size(25, 25)
        });
    }

}
