<div *ngIf="selector && selector.shown && false" class="hx-selector">
    <div class="title">
        Views
    </div>
    <table class="hx-table">
        <tbody>
            <tr *ngFor="let _b of selector.boxes | keyvalue">
                <td class="box">
                    <div (click)="toggleBox(_b.value.name)">
                        <input type="checkbox" name="isActive" [checked]="_b.value.displayed">
                        <span [ngClass]="{ 'active' : _b.value.displayed === true,  'inactive' : _b.value.displayed === false  }">{{
                                _b.value.label }}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>