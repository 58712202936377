/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/calendar-tooltip.directive";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../common/calendar-event-actions.component.ngfactory";
import * as i5 from "../common/calendar-event-actions.component";
import * as i6 from "../common/calendar-event-title.component.ngfactory";
import * as i7 from "../common/calendar-event-title.component";
import * as i8 from "../../directives/click.directive";
import * as i9 from "./calendar-week-view-event.component";
var styles_CalendarWeekViewEventComponent = [];
var RenderType_CalendarWeekViewEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarWeekViewEventComponent, data: {} });
export { RenderType_CalendarWeekViewEventComponent as RenderType_CalendarWeekViewEventComponent };
function View_CalendarWeekViewEventComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 7, "div", [["calCalendarTooltip", "TO DO"], ["class", "cal-event"]], [[2, "cal-starts-within-week", null], [2, "cal-ends-within-week", null], [4, "backgroundColor", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onMouseOut() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(2, 147456, null, 0, i2.CalendarTooltipDirective, [i0.ElementRef, i0.Injector, i0.Renderer2, i0.ComponentFactoryResolver, i0.ViewContainerRef, i3.DOCUMENT], { contents: [0, "contents"], placement: [1, "placement"], customTemplate: [2, "customTemplate"], event: [3, "event"], appendToBody: [4, "appendToBody"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-calendar-event-actions", [], null, null, null, i4.View_CalendarEventActionsComponent_0, i4.RenderType_CalendarEventActionsComponent)), i0.ɵdid(4, 49152, null, 0, i5.CalendarEventActionsComponent, [], { event: [0, "event"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "app-calendar-event-title", [["view", "week"]], null, [[null, "calClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("calClick" === en)) {
        var pd_0 = (_co.eventClicked.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CalendarEventTitleComponent_0, i6.RenderType_CalendarEventTitleComponent)), i0.ɵdid(6, 49152, null, 0, i7.CalendarEventTitleComponent, [], { event: [0, "event"], view: [1, "view"] }, null), i0.ɵdid(7, 212992, null, 0, i8.ClickDirective, [i0.Renderer2, i0.ElementRef], null, { click: "calClick" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "cal-event"; var currVal_4 = ((_co.weekEvent.event == null) ? null : _co.weekEvent.event.cssClass); _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = "TO DO"; var currVal_6 = _co.tooltipPlacement; var currVal_7 = _co.tooltipTemplate; var currVal_8 = _co.weekEvent.event; var currVal_9 = _co.tooltipAppendToBody; _ck(_v, 2, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.weekEvent.event; _ck(_v, 4, 0, currVal_10); var currVal_11 = _co.weekEvent.event; var currVal_12 = "week"; _ck(_v, 6, 0, currVal_11, currVal_12); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.weekEvent.startsBeforeWeek; var currVal_1 = !_co.weekEvent.endsAfterWeek; var currVal_2 = _co.weekEvent.event.color.secondary; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CalendarWeekViewEventComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_CalendarWeekViewEventComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, [["defaultTemplate", 2]], null, 0, null, View_CalendarWeekViewEventComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarWeekViewEventComponent_2)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { weekEvent: 0, tooltipPlacement: 1, eventClicked: 2, tooltipTemplate: 3, tooltipAppendToBody: 4 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.weekEvent, _co.tooltipPlacement, _co.eventClicked, _co.tooltipTemplate, _co.tooltipAppendToBody); var currVal_1 = (_co.customTemplate || i0.ɵnov(_v, 0)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarWeekViewEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-week-view-event", [], null, null, null, View_CalendarWeekViewEventComponent_0, RenderType_CalendarWeekViewEventComponent)), i0.ɵdid(1, 49152, null, 0, i9.CalendarWeekViewEventComponent, [], null, null)], null, null); }
var CalendarWeekViewEventComponentNgFactory = i0.ɵccf("app-calendar-week-view-event", i9.CalendarWeekViewEventComponent, View_CalendarWeekViewEventComponent_Host_0, { weekEvent: "weekEvent", tooltipPlacement: "tooltipPlacement", tooltipAppendToBody: "tooltipAppendToBody", customTemplate: "customTemplate", tooltipTemplate: "tooltipTemplate" }, { eventClicked: "eventClicked" }, []);
export { CalendarWeekViewEventComponentNgFactory as CalendarWeekViewEventComponentNgFactory };
