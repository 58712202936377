/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selector.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./selector.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../core/services/api.service";
import * as i6 from "../../../core/services/global.variables";
import * as i7 from "../../../core/event-aggregator/event.aggregator";
var styles_SelectorComponent = [i0.styles];
var RenderType_SelectorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectorComponent, data: {} });
export { RenderType_SelectorComponent as RenderType_SelectorComponent };
function View_SelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "td", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleBox(_v.context.$implicit.value.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["name", "isActive"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "active": 0, "inactive": 1 }), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, (_v.context.$implicit.value.displayed === true), (_v.context.$implicit.value.displayed === false)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value.displayed; _ck(_v, 3, 0, currVal_0); var currVal_2 = _v.context.$implicit.value.label; _ck(_v, 7, 0, currVal_2); }); }
function View_SelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "hx-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Views "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "table", [["class", "hx-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectorComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.selector.boxes)); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_SelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selector && _co.selector.shown) && false); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selector", [], null, null, null, View_SelectorComponent_0, RenderType_SelectorComponent)), i1.ɵdid(1, 245760, null, 0, i3.SelectorComponent, [i1.NgZone, i4.Store, i5.ApiService, i6.GlobalVariables, i7.EventAggregator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectorComponentNgFactory = i1.ɵccf("app-selector", i3.SelectorComponent, View_SelectorComponent_Host_0, {}, {}, []);
export { SelectorComponentNgFactory as SelectorComponentNgFactory };
