import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import { SKpi } from '../../models/s-kpi';
import { HxToolsDate } from '../../shared/hx-tools/hx-tools-date';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import * as _ from 'lodash';
import { NotifierService } from 'angular-notifier';

export class ApiQuery {
    public static all_annotations_for_record_id = _.template('annotation/?record=<%= record_id %>');
    public static all_annotations_for_user_id__start__end = _.template('annotation/?user=<%= user %>&start__gte=<%= start %>&start__lte=<%= end %>');
    public static all_activitiytype = _.template('activitytype/?limit=0&show_all=1');
    public static create_related_user = _.template('createrelateduser/');
    public static all_coaches_for_user_id = _.template('bundle_permission/?bundle=1&user_target=<%= user_id %>');
    public static all_friends_for_user_id = _.template('bundle_permission/?bundle=2');
    public static all_subjects_for_user_id = _.template('bundle_permission/?bundle=1&user_allowed=<%= user_id %>');
    public static all_subjects_for_user_id_limit_offset = _.template('bundle_permission/?bundle=1&user_allowed=<%= user_id %>&limit=<%= limit %>&offset=<%= offset %>');
    public static all_users_for_user_id = _.template('user/?limit=0');
    public static all_pending_relationships_requests = _.template('bundle_permission_request/');
    public static metrics_for_user_id__start__end = _.template('report/?include_metrics=<%= metric_ids %>&start=<%= start %>&end=<%= end %>&user=<%= user_id %>');
    public static metrics_for_range = _.template('report/?include_metrics=<%= metric_ids %>&range=<%= range_id %>');
    public static metrics_for_record = _.template('report/?include_metrics=<%= metric_ids %>&record=<%= record_id %>');
    public static all_datatypes = _.template('datatype/?limit=0');
    public static all_metrics = _.template('metric/?limit=0');
    public static add_friend = _.template('bundle_permission/');
    public static retrieve_gps_positions = _.template('trackpoint/?limit=0&user=<%= user_id %>&start=<%= start %>&end=<%= end %>');
    public static all_prescriptions = _.template('trainingroutine/');
    public static add_prescription = _.template('trainingroutine/');
    public static update_prescription = _.template('trainingroutine/<%= id %>/');
    public static get_metric_distribution_for_profile_id = _.template('profile/<%= id %>/populationdistribution/');
    public static save_profile = _.template('profile/<%= id %>/');
    public static get_report_for_user_id__metrics__start__end__o_groupby = _.template('report/?user=<%= user_id %>&include_metrics=<%= metric_ids %>&start=<%= start %>&end=<%= end %><%= option %>');
}


@Injectable()
export class ApiService extends HttpService {

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }

    /**
     * Delete a distant object
     */
    delete(objectName: string, id, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + objectName + "/" + id + "/";
        return this._delete(query, keyToClear);
    }


    /**
     * Delete from an URI
     */
    deleteFromURI(resourceUri: string, keyToClear?: string): Observable<any> {
        const query = this.API_URL + resourceUri;

        return this._delete(query, keyToClear);
    }


    /**
     * Save a distant object
     */
    save(objectName: string, object: Object, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + objectName + "/";
        return this._post(query, object, keyToClear);
    }

    /**
     * Update a distant object
     */
    update(objectName: string, object: Object, id: number, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + objectName + "/" + id + "/";
        return this._patch(query, object, keyToClear);
    }

    /**
     * Retrieve all the annotations for a given record
     */
    getAll(id: number): Observable<Array<any>> {

        //const query =   let res =  all_annotations_for_record_id({ 'record_id': id });

        const query = this.API_URL + "api/" + "annotation/?record=" + id;
        return this._get(query);
    }

    getFromUri(uri) {
        if (uri[0] === "/") {
            uri = uri.slice(1);
        }
        const query = this.API_URL + uri;
        return this._get(query, false);
    }

    getQuery(queryName, params?) {
        let query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._get(query);
    }

    postQuery(queryName, object, params?, keyToClear?) {
        let query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._post(query, object, keyToClear);
    }

    putQuery(queryName, object, params?, keyToClear?) {
        let query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._put(query, object, keyToClear);
    }

    patchQuery(queryName, object, params?, keyToClear?) {
        let query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._patch(query, object, keyToClear);
    }
    
    /**
     * Retrieve all the annotations for a time range and user
     */
    getAllFromTimeRange(user: number, start: number, end: number): Observable<Array<any>> {

        const query = this.API_URL + "api/" + "annotation/?user=" + user +
            "&start__gte=" + (start - Math.floor((Math.random() * 100) + 1))
            + "&start__lte=" + (end + Math.floor((Math.random() * 100) + 1));
        // TO DO : delete limit... workaround not good...

        return this._get(query);
    }

    getHistoricalFitness(profile_id?: number, start?: number): Observable<any> {
        let query: string = this.API_URL + "api/profile/"; // was profile...
        if (profile_id) query = query + profile_id + "/";
        else query = query + this.globalVariables.get('currentUserId') + "/";

        let startOfTheDay = 256 * HxToolsDate.getStartOfMonth(start);
        let endOfTheDay = 256 * HxToolsDate.getEndOfMonth();

        if (start) {
            query += "fitness/?limit=0&start__gte=" + startOfTheDay + "&start__lte=" + endOfTheDay;
        } else {
            query += "fitness/?limit=0";
        }
        return this._get(query, false);
    }

    getPreferences(profileId?: number): Observable<any> {
        let query: string = this.API_URL + "api/profile/"; // was profile...
        if (profileId) query = query + profileId + "/";
        else query = query + this.globalVariables.get('currentUserId') + "/";
        return this._get(query);
    }


    saveKPI(kpi: string, value: any, profile_id?: number, start?: number) {
        let _kpi = new SKpi();
        _kpi.value = value;
        if (start) _kpi.start = start ? start : null;
        let query = "profile/";
        if (profile_id) {
            query = query + profile_id;
        } else {
            query = query + this.globalVariables.get('currentUserId');
        }
        return this._post(this.API_URL + "api/" + query + "/fitness/" + kpi + "/", _kpi, 'fitness,profile');
    }


    deleteKPI(resource_uri: string, keyToClear?: string) {
        return this._delete(this.API_URL + resource_uri, keyToClear);
    }

    updateKPI(resource_uri: string, data: Object) { //TODO
        const options = this.generateHeader();
        return this.http.patch(this.API_URL + resource_uri, data, { 'headers': options })
            .map((response: Response) => {
                return response.json();
            });
    }

    getAnnouncements() {
        const query = this.API_URL + '/api/studyannouncement/?limit=' + Math.round(Math.random() * 100);
        return this._get(query);
    }

    /**
     * Retrieve a specific KPI for a given user
     */
    getKPI(kpi: string, profile_id?: number): Observable<any> {
        let query = this.API_URL + "api/profile/";
        if (profile_id) {
            query = query + profile_id;
        } else {
            query = query + this.globalVariables.get('currentUserId');
        }
        query += "/fitness/" + kpi + "/?order_by=-start&limit=1";
        return this._get(query);
    }

    addSubject(userAllowedId: number, userTargetEmail: string): Observable<any> {
        const bundle = { 'user_allowed': userAllowedId, 'user_target': userTargetEmail, 'bundle': 1 };
        return this._post(this.API_URL + "api/bundle_permission_request/", bundle);
    }

    addUser(email: string, firstname: string, lastname: string): Observable<any> {
        const options = this.generateHeader();
        const bundle = { 
            "email": email,
            "first_name": firstname,
            "last_name": lastname, 
            "bundles": [
                "/api/bundle/1/"
            ]
        };
        return this._post(this.API_URL + "api/createrelateduser/", bundle);
    }
    
}

