import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'metrics-panel',
    templateUrl: './metrics-panel.component.html',
    styleUrls: ['./metrics-panel.component.scss',
        '../../../../assets/stylesheets/app.scss',
        '../../../../assets/stylesheets/core/section.scss',
        '../../../../assets/stylesheets/core/grid.scss',
        '../../../../assets/stylesheets/core/icon.scss']
})
export class MetricsPanelComponent implements OnInit {

    @Input()
    metricIds: Array<any>;

    _metricIds: Array<any>;

    values: Array<any>;

    @Input()
    metricValues: Array<any>;

    _metricValues: Array<any>;

    @Input() id: number;
    @Input() start;
    @Input() end;
    @Input() type;

    positions: Array<any>;
    public results: Array<any>;
    public resultsRetrieved = false;

    constructor() {
        this.positions = new Array<any>();
    }

    ngOnInit() {

        this.init();
    }

    ngOnChanges() {
        this.init();
    }

    intersect(a, b) {
        const result = a.filter(itemA => {
            return b.some(itemB => itemB === itemA);
        });
        return result;
    }

    init() {


        let ids = new Array<number>();
        if (this.metricValues) {
            for (const metric of this.metricValues) {
                ids.push(metric.id);
            }
        }
        this._metricIds = new Array<any>();
        this._metricValues = new Array<any>();
        let current_position = 0;

        for (const metricGroup of this.metricIds) {


            if (this.intersect(ids, metricGroup.ids).length > 0
                && this.intersect(ids, metricGroup.ids).length === metricGroup.ids.length
                && !this._metricIds.find(item => item.title === metricGroup.title)) {

                this._metricIds.push(metricGroup);

                for (const id of metricGroup.ids) {

                    const metric = this.metricValues.filter(function (entry) {
                        return entry.id === id;
                    });
                    this._metricValues.push(metric[0]);

                }
                this.positions.push(current_position);
                current_position = current_position + metricGroup.ids.length;
                /*metric.ids
 
                const activitytype = this.values.filter(function (entry) {
                    return entry.resource_uri === range.context.activitytype;
                });*/


            }
        }

        this.results = this._metricValues;

        this.resultsRetrieved = true;
        //    });


        /*        this.reportService.get(this.selectedUser, metricList, this.start, this.end).subscribe(data => {
                    this.results = data.metrics;
                    this.resultsRetrieved = true;
                })
        */

    }

}
