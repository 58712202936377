var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable: member-ordering forin */
import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { UserService } from '../../core/services';
import { Account } from '../../models/';
import { UserForm } from './user-form';
import 'rxjs/add/operator/mergeMap';
import { shorterThan } from '../../shared/validators/string.validator';
var UserAccountComponent = /** @class */ (function (_super) {
    __extends(UserAccountComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function UserAccountComponent(fb, userService, alertService) {
        var _this = _super.call(this, fb) || this;
        _this.userService = userService;
        _this.alertService = alertService;
        _this.account = new Account();
        _this.active = true;
        _this.success = false;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.user_id = null;
        _this.valueChange = new EventEmitter();
        /**
         * Error messages
         */
        _this.formErrors = {
            'first_name': '',
            'last_name': '',
            'username': ''
        };
        _this.validationMessages = {
            'first_name': {
                'required': 'Firstname is required.',
                'shorterThan': 'Should be shorter than 30 characters'
            },
            'last_name': {
                'required': 'Lastname is required.',
                'shorterThan': 'Should be shorter than 30 characters'
            },
            'username': {
                'required': 'Username is required.',
                'shorterThan': 'Should be shorter than 30 characters'
            }
        };
        return _this;
    }
    /**
     * Building the form on init
     */
    UserAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.get(this.user_id).subscribe(function (data) {
            _this.account.first_name = data.first_name;
            _this.account.last_name = data.last_name;
            _this.account.username = data.username;
            _this.buildForm();
        });
    };
    /**
     * Save on server the user information
     */
    UserAccountComponent.prototype.onSubmit = function () {
        var _this = this;
        this.account = this.form.value;
        this.userService.save(this.account, this.user_id).subscribe(function () {
            _this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The account info have been saved.' }];
            _this.valueChange.emit(_this.msgs);
        }, function (error) {
            console.error(error);
            _this.alertService.error(error);
        });
    };
    /**
     * Building form function
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    UserAccountComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.fb.group({
            'first_name': [this.account.first_name, [
                    Validators.required,
                    shorterThan(30)
                ]
            ],
            'last_name': [this.account.last_name, [
                    Validators.required,
                    shorterThan(30)
                ]
            ],
            'username': [this.account.username, [
                    Validators.required,
                    shorterThan(30)
                ]
            ],
        });
        this.form.valueChanges
            .subscribe(function () {
            _this.onValueChanged();
        });
        this.onValueChanged();
    };
    return UserAccountComponent;
}(UserForm));
export { UserAccountComponent };
