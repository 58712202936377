/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./duration-picker.component";
var styles_DurationPickerComponent = [];
var RenderType_DurationPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DurationPickerComponent, data: {} });
export { RenderType_DurationPickerComponent as RenderType_DurationPickerComponent };
export function View_DurationPickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_DurationPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "duration-picker", [], null, null, null, View_DurationPickerComponent_0, RenderType_DurationPickerComponent)), i0.ɵdid(1, 1097728, null, 1, i1.DurationPickerComponent, [], null, null), i0.ɵqud(603979776, 1, { units: 1 })], null, null); }
var DurationPickerComponentNgFactory = i0.ɵccf("duration-picker", i1.DurationPickerComponent, View_DurationPickerComponent_Host_0, { returnedValueUnit: "returnedValueUnit", inputDisabled: "inputDisabled" }, { onNumberChanged: "onNumberChanged" }, ["*"]);
export { DurationPickerComponentNgFactory as DurationPickerComponentNgFactory };
