
    <article>
  <header>
    <h3>Hexoskin Privacy Policy</h3>
  </header>

  <p>
    Carré Technologies is aware that Hexoskin records personal information about your activities and your body metrics.
    It is a priority for us to keep your information confidential and that you understand how your information may be
    stored and used to provide you services and to improve our wearable body metrics technology.
  </p>

  <h4><strong>Information about web usage and personal contact</strong></h4>

  <p>
    Carré Technologies Inc. collects personal information on its website only when you fill in the request for
    information form, when you log into your account or when you contact them by email. If you wish to receive
    publications from Carré Technologies, please consult the contact section and fill in the subscription form for the
    mailing list. A person who subscribes to the mailing list can, at any time upon receipt of a publication from
    Carré Technologies, unsubscribe from this list.
  </p>

  <h4><strong>Collection and use of information acquired using Hexoskin</strong></h4>

  <p>
    Carré Technologies Inc. collects information from your Hexoskin only when it is connected to our servers via a
    synchronization application. Synchronization applications can be hosted on a mobile device, a personal computer or
    another computing device.
  </p>

  <h4><strong>What kind of personal information is collected</strong></h4>

  <p>When you use Hexoskin services with your personal account, we may record the following information:</p>

  <ul>
    <li>email address and password (Registration Information),</li>
    <li>name, billing address, shipping address (Billing Information),</li>
    <li>birth date, gender, height, weight, affiliation (Profile Information),</li>
    <li>
      relations with friends, family, personal coach, a health professional and information sharing permissions with
      these people (Social Information),
    </li>
    <li>
      body metrics such as cardiac activity, breathing activity, movement activity, and information and statistics
      based on these measurements (Activity Information).
    </li>
  </ul>

  <h4><strong>How we use your personal information</strong></h4>

  <p>Carré Technologies may use your personal information to:</p>

  <ul>
    <li>Provide you services,</li>
    <li>Improve our services and algorithms,</li>
    <li>Send you updates and information concerning your usage of the Services,</li>
    <li>Research and data analysis.</li>
  </ul>

  <h4><strong>Research and Data Analysis</strong></h4>

  <p>
    Carré Technologies may share <u>anonymously</u>: Profile Information, Social Information, and Activity Information
    with researchers and partners to conduct further research on health, wellness and fitness. Before we share raw or
    aggregate data, it is de-identified to make sure you cannot be identified personally.
  </p>

  <h4><strong>Surveys</strong></h4>

  <p>
    Carré Technologies may use your personal information to contact you for surveys in order to follow the evolution of
    your health, wellness, and fitness, and better serve you.
  </p>

  <h4><strong>Billing Information</strong></h4>

  <p>
    If you order services from hexoskin.com, carretechnologies.com or another affiliated sites you will have to provide
    us with billing information pertaining to your order.
  </p>

  <h4><strong>Other Uses of Personal Information</strong></h4>

  <p>
    Carré Technologies will not use your personal information for purposes other than those described in the present
    policy without having obtained your prior consent.
  </p>

  <p>
    Carré Technologies, however, reserves the right to disclose the information regarding its users in the following situations:
    To conform to a law or regulation, to ensure its defense in the event of legal action or to protect its
    rights or those of the users of its site.
  </p>

  <h4><strong>Your Right to Delete Your Data</strong></h4>

  <p>
    If you would like to cancel your account, you may do so by emailing our technical support. When you request
    cancellation of your account, your personally identifiable information will be deleted, including but not limited
    your email address, name, contact lists and links to social and partner sites such as Facebook and Twitter. Archival
    copies of your account information that exist within our back-up system will be deleted in accordance with our
    normal back-up expiration schedule.
  </p>

  <p>
    Following cancellation or termination of your account, we may continue to utilize de-identified and anonymized
    historical data associated with your use of Hexoskin Products.
  </p>

  <h4><strong>Children</strong></h4>

  <p>
    Hexoskin Products and Services are intended for adults. Users under the age of 18 are not permitted to use
    our Products and Services or register for and/or use Hexoskin Services without the consent of a parent or legal
    guardian.
  </p>

  <p>
    We do not knowingly collect any personal information from children under 13. If we discover that a child under the
    age of 13 has provided us with personally identifying information, we will take steps to delete the information as
    soon as possible.
  </p>

  <h4><strong>Data Security</strong></h4>

  <p>
    We use a combination of firewall barriers, encryption techniques and authentication procedures, among others, to
    maintain the security of your data and to protect your accounts and our systems from unauthorized access.
  </p>

  <p>
    However, no Internet transmission, telephone call, or regular mail, can be guaranteed to be fully secure or error
    free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in
    deciding what information you send to us using these means of communication.
  </p>

  <p>
    When you register for the Service, we require a password from you for your privacy and security. This password is
    encrypted when transmitted and stored on our systems. You are responsible for choosing a secure password and keeping
    it confidential.
  </p>

  <p>
    It is important to understand that these precautions apply only to our services and systems. We exercise no control
    over how your information is transmitted, stored, maintained or used by third parties or on third-party services.
  </p>

  <h4><strong>Statistical Data</strong></h4>

  <p>
    Each time you visit our websites or server, Carré Technologies may collect some anonymous information, including
    the type of browser and system you are using; the address of the site you have come from and move to after your
    visit; the date and time of your visit; and your server’s IP address.
  </p>

  <p>
    We collect this information for statistical purposes to find out how our websites are used and navigated, including
    the number of hits, the frequency and duration of visits, most popular session times. We may use this information to
    evaluate and improve our website and online services performance.
  </p>

  <h4><strong>NOTIFICATION OF CHANGES</strong></h4>

  <p>
    The Effective Date of this Privacy Policy is set forth below. Please make special note of the Effective Date of
    this Privacy Policy. We may revise and update this Privacy Policy if our practices change, as technology changes, or
    as we add new services or change existing ones. If we make any material changes to our Privacy Policy or how we
    handle your personal information or how we keep your purchases anonymous, you will know because the Effective Date
    of the Privacy Policy will change. If we are going to use any personal information in a manner that is materially
    different from that stated in our Privacy Policy at the time we collected such information, we will give you a
    reasonable opportunity to consent to the change. If you do not consent, your personal information will be used as
    agreed to under the terms of the privacy policy in effect at the time we obtained that information but you may not
    be able to use our Site or Services in the future. By using our Site or Services after the Effective Date, you are
    deemed to consent to our then-current privacy policy. We will use information previously obtained in accordance with
    the privacy policy in effect when the information was obtained from you.
  </p>

  <h4><strong>IMPORTANT DISCLOSURES AND PRACTICES</strong></h4>

  <p><strong>Note about International Users.</strong> Our services are located in Canada. If you are using the site from
    outside the Canada, you should be aware that your information collected on this site may be stored and processed in
    Canada or any other country in which Carré Technologies, or its affiliates, subsidiaries or agents maintain
    facilities, including hosting services or servers, and by providing your information to this site, you are
    consenting to the transfer of your information outside of your country in accordance with this Privacy Policy and
    our <a href="/terms">Terms of Use</a>. You are also consenting to the application of Canadian law in all matters concerning this site.
  </p>

  <h4><strong>Other Terms and Conditions</strong></h4>

  <p>Your usage of Hexoskin Products and Services is subject to the <a href="/terms">Terms of Use</a>.</p>

  <h4><strong>DISPUTES</strong></h4>

  <p>If you choose to use our services, any dispute over privacy is subject to this Privacy Policy and our <a href="/terms">Terms of Use</a>,
  including limitations on damages, arbitration of disputes, and application of the laws in the province of Quebec,
  Canada.</p>

  <h4><strong>Contact us if you have questions or suggestions</strong></h4>

  <p>Please send your questions, comments or suggestions to the following address:
  <a href="mailto:info@hexoskin.com">info@hexoskin.com</a>.</p>

  <p>Effective Date: June 1<sup>st</sup>, 2011<br>This version: May 19<sup>th</sup>, 2012</p>

  <p><strong>Headquarters Address</strong></p>

  <address>
    Hexoskin (Carré Technologies Inc.)<br />
    5800, rue Saint-Denis, suite 402A <br />
    Montreal (Quebec) <br />
    CANADA <br />
    H2S 3L5 <br />
    <i class="icon-phone"></i> 1-888-887-2044  </address>

  <h4><strong>Collection and use of personal information</strong></h4>

  <p>
    Carré Technologies collects information only when you fill in the request for information form or when you contact
    them by email. If you wish to receive publications from Carré Technologies, please consult the Contact section
    and fill in the subscription form for the mailing list.
  </p>

  <p>
    A person who subscribes to the mailing list can, at any time upon receipt of a publication from Carré Technologies,
    unsubscribe from this list.
  </p>

  <p>
    Carré Technologies may use your personal information to contact you for surveys in order to follow the evolution of
    your Hexoskin product and better serve you.
  </p>

  <p>
    If you order services from carretechnologies.com or hexoskin.com you will have to provide us with billing
    information pertaining to your order.
  </p>

  <p>
    Carré Technologies will not use your personal information for purposes other than those described in the present
    confidentiality statement without having obtained your prior consent.
  </p>

  <p>
    Carré Technologies however, reserves the right to disclose the information regarding the users of its Hexoskin Web
    site in the following situations: To conform to a law or regulation, to ensure its defense in the event of legal
    action or to protect its rights or those of the users of its site.
  </p>

  <h4><strong>Statistical data</strong></h4>

  <p>
    Each time you visit the Hexoskin website or servers, Carré Technologies may collect some anonymous information,
    including the type of browser and system you are using; the address of the site you have come from and move to after
    your visit; the date and time of your visit; and your server’s IP address.
  </p>

  <p>
    We collect this information for statistical purposes to find out how the Hexoskin website is used and navigated,
    including the number of hits, the frequency and duration of visits, most popular session times. We may use this
    information to evaluate and improve our website performance.
  </p>

  <h4><strong>External links</strong></h4>

  <p>
    The Web site of Hexoskin contains links to external Web sites for which it does not manage the content.
    The external links present on this Web site shall not be considered as recommendations and are placed on the site for
    informational purposes only.&nbsp; Carré Technologies is not responsible for the information contained outside its
    own Web site and cannot offer a guarantee as to the accuracy of this information. The present confidentiality
    statement does not apply to these sites.
  </p>

  <h4><strong>“Cookies”</strong></h4>

  <p>Carré Technologies reserves the right to use cookies on its site. If you do not want to allow Carré Technologies to
  use cookies when you consult this site, it is possible to deactivate the cookies function on most browsers.</p>

  <h4><strong>NOTIFICATION OF CHANGES</strong></h4>

  <p>The Effective Date of this Privacy Policy is set forth below. Please make special note of the Effective Date of
  this Privacy Policy. We may revise and update this Privacy Policy if our practices change, as technology changes, or
  as we add new services or change existing ones. If we make any material changes to our Privacy Policy or how we
  handle your personal information or how we keep your purchases anonymous, you will know because the Effective Date
  of the Privacy Policy will change. If we are going to use any personal information in a manner that is materially
  different from that stated in our Privacy Policy at the time we collected such information, we will give you a
  reasonable opportunity to consent to the change. If you do not consent, your personal information will be used as
  agreed to under the terms of the privacy policy in effect at the time we obtained that information but you may not
  be able to use our Site or Services in the future. By using our Site or Services after the Effective Date, you are
  deemed to consent to our then-current privacy policy. We will use information previously obtained in accordance with
  the privacy policy in effect when the information was obtained from you.</p>

  <h4><strong>IMPORTANT DISCLOSURES AND PRACTICES</strong></h4>

  <p>A Note about International Users: The site is located in Canada. If you are using the site from outside the Canada,
  you should be aware that your information collected on this site may be stored and processed in Canada or any other
  country in which Carré Technologies, or its affiliates, subsidiaries or agents maintain facilities, including
  hosting services or servers, and by providing your information to this site, you are consenting to the transfer of
  your information outside of your country in accordance with this Privacy Policy and our <a href="/terms">Terms of Use</a>. You are also
  consenting to the application of United States law in all matters concerning this site.</p>

  <h4><strong>DISPUTES</strong></h4>

  <p>If you choose to visit the Hexoskin web site, your visit and any dispute over privacy is subject to this Privacy
  Policy and our <a href="/terms">Terms of Use</a> including limitations on damages, arbitration of disputes, and application of the laws
  in the province of Quebec, Canada.</p>

  <h4><strong>Additional information</strong></h4>

  <p>
    Please send your questions, comments or suggestions to the following address:
    <a href="mailto:info@hexoskin.com">info@hexoskin.com</a>  </p>

  <p>Effective Date: May 29, 2012</p>
</article>