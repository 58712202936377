// Import from other modules
import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { RecordViewService } from '../record-view/record-view.service';
import { ApiService } from '../../../core/services';
import { Annotation } from '../../../models/annotation';
import { GrowlMessageEvent } from '../../../shared/components/growl';
import { ChartService, ChartTask, Action } from '../../../charts/chart/';
import { ConfirmationService } from '../../../shared/components/confirmation/confirmation.service';
import { Message } from '../../../shared/components/growl/message';

@Component({
    selector: 'record-annotation',
    templateUrl: './record-annotation.component.html',
    styleUrls: ['./record-annotation.component.scss', '../../../../assets/stylesheets/core/form.scss', '../../../../assets/stylesheets/button.scss']
})
export class RecordAnnotationComponent implements OnInit {

    @Input() data: any; // to delete

    // Timestamp of the annotation
    @Input() start: string;

    // Note of the annotation
    @Input() annotation: string;

    // User id
    @Input() user: number;

    // Annotation id
    @Input() id: number;

    // Mode (add or edit)
    public mode: string;

    /**
     * Record unique identifier
     */
    @Input() record_id: number;

    deletableAnnotation: boolean = false;

    /**
     * Event Emitter when plot line added on a chart
     */
    @Output()
    annotationLabel: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event Emitter for growl messages
     */
    @Output()
    growlMessageLabel: EventEmitter<GrowlMessageEvent> = new EventEmitter<GrowlMessageEvent>();

    /**
     * Event Emitter when on action must be performed by parent component
     */
    @Output()
    action: EventEmitter<Action> = new EventEmitter<Action>();

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    constructor(public route: ActivatedRoute,
        public recordViewService: RecordViewService,
        public apiService: ApiService,
        public confirmationService: ConfirmationService,
        public chartService: ChartService) {

        /**
        * Listen to the orders received from others components        
        */
        this.chartService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === Action.DISPLAY_ANNOTATION_DELETE_BUTTON) {
                    this.deletableAnnotation = true;
                } else if (task.action === Action.DISPLAY_ANNOTATION_CANCEL_BUTTON) {
                    this.deletableAnnotation = false;
                } else if (task.action === Action.PUT_ANNOTATION_FORM_IN_EDIT_MODE) {
                    this.mode = "edit";
                } else if (task.action === Action.PUT_ANNOTATION_FORM_IN_ADD_MODE) {
                    this.mode = "add";
                }
            });

    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    annotationLabelChanged() {
        this.annotationLabel.emit(this.annotation);
    }

    /**
     * Delete the current plot line if it has not been saved
     * Hide the annotation form (this component)
     */
    reset() {
        // Tells brother component chartComponent to delete plotline not saved
        this.chartService.postTask(new ChartTask(Action.DELETE_NOT_SAVED_PLOTLINE));
        // Tells parent component recordViewComponent to hide itself
        this.action.emit(Action.HIDE_ANNOTATION_FORM);
    }

    /**
     * Delete an annotation
     */
    delete() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this annotation?',
            header: 'Delete Confirmation',
            rejectVisible: true,
            acceptVisible: true,
            icon: 'fa fa-trash',
            accept: () => {
                this.apiService.delete('annotation', this.id, 'annotation')
                    .subscribe(
                        () => {
                            this.growlMessageLabel.emit(new GrowlMessageEvent('success', 'Confirmed', 'Annotation deleted'));
                            this.action.emit(Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR);
                            this.action.emit(Action.HIDE_ANNOTATION_FORM);
                            // Send order to delete plotline to chart component
                            this.chartService.postTask(new ChartTask(Action.DELETE_NOT_SAVED_PLOTLINE, "plotline", this.id));
                            // Empty the annotation form fields
                            this.chartService.postTask(new ChartTask(Action.EMPTY_FORM_FIELDS));
                        },
                        error => {
                            console.error(error);

                            // this.loading = false;
                        });
            }
        });
    }

    /**
     * Save current annotation on the server
     * Update the chart
     */
    save() {

        this.annotationLabel.emit(this.annotation);

        let annotation: Annotation = new Annotation();
        annotation.annotation = this.annotation;
        annotation.start = Math.round(+this.start);
        annotation.datatype = 4098; // default datatype for manual annotation
        annotation.user = this.user;
        //annotation.record = this.record_id;


        this.apiService.save('annotation', annotation, "annotation").subscribe(
            () => {
                this.msgs = [];
                this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range saved' });
                this.action.emit(Action.DISPLAY_GROWL_ANNOTATION_SAVED);
                //this.growlMessageLabel.emit(new GrowlMessageEvent('success', 'Confirmed', 'Annotation saved'));
                this.action.emit(Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR);
                this.action.emit(Action.HIDE_ANNOTATION_FORM);
                this.action.emit(Action.REFRESH_CHART);
                // make annotation persistant
            },
            error => {
                console.error(error);

            });
    }


    update() {


        this.annotationLabel.emit(this.annotation);

        let annotation: Annotation = new Annotation();
        annotation.annotation = this.annotation;
        //annotation.user = this.user;
        //annotation.record = this.record_id;


        this.apiService.update('annotation', annotation, this.id, "annotation").subscribe(
            () => {
                this.msgs = [];
                this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range saved' });
                this.action.emit(Action.DISPLAY_GROWL_ANNOTATION_SAVED);
                //this.growlMessageLabel.emit(new GrowlMessageEvent('success', 'Confirmed', 'Annotation saved'));
                this.action.emit(Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR);
                this.action.emit(Action.HIDE_ANNOTATION_FORM);
                this.action.emit(Action.REFRESH_CHART);
                // make annotation persistant
            },
            error => {
                console.error(error);

            });
    }


}
