var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { HttpService } from './http.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
// const sha1 = require('sha1');
var AuthenticationService = /** @class */ (function (_super) {
    __extends(AuthenticationService, _super);
    function AuthenticationService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    AuthenticationService.prototype.retrieveAccount = function () {
        return this._get(this.API_URL + "/api/account/", false);
    };
    AuthenticationService.prototype.clearLocalStorage = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.retrieveDataResolver = resolve;
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentUserId');
            localStorage.removeItem('currentSubject');
            localStorage.removeItem('currentSubjectId');
            localStorage.removeItem('stringAuth');
            localStorage.removeItem('auth');
            _this.retrieveDataResolver();
        });
    };
    AuthenticationService.prototype.logout = function () {
        var _this = this;
        var _headers = { 'Authorization': this.globalVariables.get("access_token") + " " + this.globalVariables.get("token_type") };
        _headers = Object.assign({}, _headers, { 'Content-Type': "application/json" });
        var headers = new HttpHeaders(_headers);
        return this.http.get(this.API_URL + "/api/connect/logout/", { headers: headers })
            .map(function (response) {
            localStorage.clear();
            _this.clearAll();
            _this.clearLocalStorage().then(function () { _this.notifyFSS(); });
            window.location.href = 'this.API_URL + "/api/connect/logout/';
        }).catch(function (error) {
            console.error(error);
            return Observable.throw({});
        });
    };
    AuthenticationService.prototype.notifyFSS = function () {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.LOGOUT
        }));
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        // User is logged
        if ((this.globalVariables.get('access_token')) &&
            (this.globalVariables.get('currentUser')) &&
            (this.globalVariables.get('currentUserId')) &&
            (this.globalVariables.get('profileUri'))) {
            // current subject is not defined, subject = logged user
            if (this.globalVariables.get('currentSubject') === null) {
                this.globalVariables.set('currentSubject', this.globalVariables.get('currentUser'));
                this.globalVariables.set('currentSubjectId', this.globalVariables.get('currentUserId'));
            }
            return true;
        }
        localStorage.clear();
        return false;
    };
    return AuthenticationService;
}(HttpService));
export { AuthenticationService };
