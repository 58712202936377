/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./basic-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./basic-chart.component";
import * as i3 from "../../core/event-aggregator/event.aggregator";
var styles_BasicChartComponent = [i0.styles];
var RenderType_BasicChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BasicChartComponent, data: {} });
export { RenderType_BasicChartComponent as RenderType_BasicChartComponent };
export function View_BasicChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["bcontainer", 1]], null, 0, "div", [["class", "chart"], ["style", "width:100%;heigth:100px"]], null, null, null, null, null))], null, null); }
export function View_BasicChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bchart", [], null, null, null, View_BasicChartComponent_0, RenderType_BasicChartComponent)), i1.ɵdid(1, 4964352, null, 0, i2.BasicChartComponent, [i3.EventAggregator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BasicChartComponentNgFactory = i1.ɵccf("app-bchart", i2.BasicChartComponent, View_BasicChartComponent_Host_0, { type: "type", salt: "salt", unit: "unit", color: "color", xAxisSuffix: "xAxisSuffix", xAxisTitle: "xAxisTitle", hasTimeAsXAxis: "hasTimeAsXAxis", minX: "minX", maxX: "maxX", enabledScrollbar: "enabledScrollbar", enabledToolip: "enabledToolip", enableTitle: "enableTitle", optionsColumnColors: "optionsColumnColors", optionsAxis: "optionsAxis", xAxis: "xAxis", interactive: "interactive", enableRangeSelector: "enableRangeSelector", markerEnabled: "markerEnabled", enableNavigator: "enableNavigator", title: "title", label: "label", values: "values", ids: "ids", names: "names" }, { action: "action" }, []);
export { BasicChartComponentNgFactory as BasicChartComponentNgFactory };
