<div>
  <div class="holder">
    <div id="header">
      <div>
        <app-top-menu></app-top-menu>
      </div>
    </div>
    <div id="body">
      <div id="inner_body">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div id="footer">
      <div class="footer hx-g">
        <div class="hx-u-1-2">
          <a href="https://www.hexoskin.com/collections/all" target="_blank">Online Store</a> |
          <a href="https://www.hexoskin.com/pages/privacy-policy" target="_blank">Privacy Policy</a> |
          <a href="https://www.hexoskin.com/pages/legal-notice" target="_blank">Legal Notice</a> |
          <a href="https://www.hexoskin.com/pages/terms-of-use" target="_blank">Terms of Use</a>
        </div>
        <div class="hx-u-1-2">
          <div class="hx-pull-right">
            {{ env.dashDomain }} © {{ env.year }} Carré Technologies, Inc.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-alert></app-error-alert>

<notifier-container></notifier-container>