<ng-container *ngIf="resultsRetrieved">
  <div class="title-wrapper">
    <div class="title">
      Metrics
    </div>
  </div>
  <div *ngIf="!metricIds" class="empty_box" style="background-color: white; padding:10px; height:225">
    <img class="spinner" src="assets/images/spinner.gif">
  </div>
  <div class="hx-g metric-box-container g-no-gutter">

    <ng-container *ngIf="_metricIds.length === 4 || _metricIds.length === 8">

      <div [ngClass]="{ 'th_line' : i / 4 >= 1, 'first_line' : i / 4 < 1, 'right' : i % 4 === 3  }" *ngFor="let metricGroup of _metricIds; let i = index"
        class="hx-u-1-4">
        <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length"></metric-box-mma>
          <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
            <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
              [length]="metricGroup.ids.length"></metric-box-one>
  </div>
  </ng-container>


  <ng-container *ngIf="_metricIds.length === 6 || _metricIds.length === 9 || _metricIds.length === 12">

    <div [ngClass]="{ 'th_line' : i / 3 >= 1, 'first_line' : i / 3 < 1, 'right' : i % 3 === 2  }" *ngFor="let metricGroup of _metricIds; let i = index"
      class="hx-u-1-3">
      <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
            </div>
  </ng-container>


  <ng-container *ngIf="_metricIds.length === 5">

    <div [ngClass]="{ 'first_line' : true, 'right' : i === 4  }" *ngFor="let metricGroup of _metricIds; let i = index" class="hx-u-1-5">
      <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
    </div>
  </ng-container>


  <ng-container *ngIf="_metricIds.length === 7">

    <div [ngClass]="{ 'th_line' : i / 4 >= 1, 'first_line hx-u-1-4' : i / 4 < 1, 'hx-u-1-3' : i / 4 >= 1,'right' : (i % 4 === 3 && i / 4 < 1) || (i % 4 === 2 && i / 4 >= 1)  }" *ngFor="let metricGroup of _metricIds; let i = index">
     <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
    </div>
  </ng-container>

  <ng-container *ngIf="_metricIds.length === 10">

    <div [ngClass]="{ 'th_line' : i / 4 >= 1, 'first_line hx-u-1-4' : i / 4 < 1, 'hx-u-1-3' : i / 4 >= 1,'right' : (i % 4 === 3 && i / 4 < 1) || (i % 4 === 2 && i / 4 >= 1)  }" *ngFor="let metricGroup of _metricIds; let i = index">
     <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
    </div>
  </ng-container>

  <ng-container *ngIf="_metricIds.length === 3">

    <div [ngClass]="{ 'first_line' : true, 'right' : i === 2  }" *ngFor="let metricGroup of _metricIds; let i = index" class="hx-u-1-3">
      <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
    </div>
  </ng-container>


  <ng-container *ngIf="_metricIds.length === 2">

    <div [ngClass]="{ 'first_line' : true, 'right' : i === 2  }" *ngFor="let metricGroup of _metricIds; let i = index" class="hx-u-1-2">
      <metric-box-mma *ngIf="metricGroup.ids.length === 3" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
        [length]="metricGroup.ids.length"></metric-box-mma>
        <metric-box-two *ngIf="metricGroup.ids.length === 2" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
          [length]="metricGroup.ids.length" [type]="metricGroup.type"></metric-box-two>
          <metric-box-one *ngIf="metricGroup.ids.length === 1" [title]="metricGroup.title" [results]="results" [offset]="positions[i]"
            [length]="metricGroup.ids.length"></metric-box-one>
    </div>
  </ng-container>


  </div>