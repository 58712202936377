import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

/**
 * SelectorService allows the selector to communicate with others component
 * It allows to post task to be performed
 */
@Injectable()
export class SelectorService {

    // Observable string sources
    private boxes = new Subject<any>();
    
    // Observable string streams
    boxesConfirmed$ = this.boxes.asObservable();

    /**
     * Post boxes
     */
    postBoxes(boxes: Object) {
        this.boxes.next(boxes);
    }

}
