<section>
    <div class="hx-g header">
        <div class="hx-u-24-24">
            <div class="title">
                My Profile
            </div>
        </div>
    </div>
    <div class="hx-profile hx-panel padding-bottom">

        <ul class="hx-tab-menu">
            <li class="hx-tab-item">
                <button [class.inactive]="form !== 'info'" [class.active]="form === 'info'"
                    type="button" (click)="displayForm('info')">Info</button>
            </li>
            <li class="hx-tab-item">
                <button [class.inactive]="form !== 'account'" [class.active]="form === 'account'"
                    type="tab" (click)="displayForm('account')">Account</button>
            </li>

            <li class="hx-tab-item">
                <button [class.inactive]="form !== 'password'" [class.active]="form === 'password'"
                    type="button" (click)="displayForm('password')">Password</button>
            </li>
        </ul>

        <user-info *ngIf="form === 'info'" [profile_id]="user.profile.id" (valueChange)='displayMsg($event)'></user-info>

        <user-account *ngIf="form === 'account'" [user_id]="user.id" (valueChange)='displayMsg($event)'></user-account>

        <user-fitness *ngIf="form === 'profile'" [profile_id]="user.profile.id" (valueChange)='displayMsg($event)'></user-fitness>

        <user-password *ngIf="form === 'password'" [user_id]="user.id" (valueChange)='displayMsg($event)'></user-password>
    </div>


</section>

<app-growl [value]="msgs"></app-growl>