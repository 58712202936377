import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CoachService } from '../../core/services';
import { Message } from '../../shared/components/growl/message';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'app-account',
    template: ``,
    styleUrls: []
})
export class AccountComponent {

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];
    public sub;
    protected readonly notifier: NotifierService;

    /**
     * CONSTRUCTOR
     */
    public constructor(public coachService: CoachService,
        public notifierService: NotifierService,
        public route: ActivatedRoute,
        public router: Router) {
        this.notifier = notifierService;
        if (this.route.snapshot.queryParams['token']) {
            this.coachService.accept(this.route.snapshot.queryParams['token']).subscribe(
                data => {
                    this.notifier.notify('success', 'The user has been successfully added.');
                    const link = ['/relationships'];
                    this.router.navigate(link);
                },
                error => {
                    this.notifier.notify('error', 'An error occured. Impossible to add the user.');
                    const link = ['/relationships'];
                    this.router.navigate(link);
                }
            );
        }
    }

}
