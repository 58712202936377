import * as moment from 'moment/moment';
import { HxToolsTime } from './hx-tools-time';


export class HxToolsRange {


    static getRangesOfTheDayForTimeline(ranges: Array<any>, selectedRange: any, startOfTheDay: number, endOfTheDay: number) {
        // empty the ranges array
        let rangesOfDay = new Array<any>();

        for (const range of ranges) {

            let highlighted = false;
            if (selectedRange && (range.id === selectedRange.id)) {
                highlighted = true;
            }
            const start = moment.unix(Math.round(range.start / 256));
            //const date = start.format("dddd, MMMM Do YYYY");
            const interval = HxToolsTime.calculateIntervalRangeForTimeline(range, startOfTheDay);

            rangesOfDay.push(
                {
                    "id": range.id,
                    "start": range.start,
                    "end": range.end,
                    "date": start.format("dddd, MMMM Do YYYY"),
                    "duration": range.duration,
                    "status": range.status,
                    "startOfDay": startOfTheDay,
                    "endOfDay": endOfTheDay,
                    "dstart": interval[0],
                    "dend": interval[1],
                    "rangeId": range.id,
                    "highlighted": highlighted,
                    "activity": range.context.activitytype,
                    "name": range.name,
                    "metrics": range.metrics,
                    "rank": range.rank
                }
            );

        }
        return rangesOfDay;
    }


    static getRangesOfTheDayForTimelineForRecord(ranges: Array<any>, startOfTheDay: number, endOfTheDay: number) {
        // empty the ranges array
        let rangesOfDay = new Array<any>();
        for (const range of ranges) {

            let highlighted = false;
            const start = moment.unix(Math.round(range.start / 256));
            ///const date = start.format("dddd, MMMM Do YYYY");
            const interval = HxToolsTime.calculateIntervalRangeForTimeline(range, startOfTheDay);

            rangesOfDay.push(
                {
                    "id": range.id,
                    "start": range.start,
                    "end": range.end,
                    "date": start.format("dddd, MMMM Do YYYY"),
                    "duration": range.duration,
                    "status": range.status,
                    "startOfDay": startOfTheDay,
                    "endOfDay": endOfTheDay,
                    "dstart": interval[0],
                    "dend": interval[1],
                    "rangeId": range.id,
                    "highlighted": highlighted,
                    "activity": range.context.activitytype,
                    "name": range.name,
                    "metrics": range.metrics
                }
            );

        }
        return rangesOfDay;
    }

}
