import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { map, catchError } from 'rxjs/operators';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/observable/empty';

@Injectable()
export class DataService extends HttpService {

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }
    updatePreferences(uri, data) {
        
        return this._patch(this.API_URL + uri, data);
    }

    /**
     * Retrieve all the data for a given record
     */
    getForRecordId(metric_ids, record_id, type): Observable<any> {
        const options = this.generateHeader();
        if ((metric_ids === undefined) || (metric_ids.length === 0)) {
            return Observable.of([]);
        }
        if (!type) {
            type = "record";
        }
        return this.http.get(this.API_URL + "api/" + "data/?datatype=" + metric_ids + "&" + type + "=" + record_id, { 'headers': options })
            .pipe(
            map(response => {
                
                if (response['length'] > 0) {
                    return response;
                } else {
                    return Observable.of([]);
                }
            }),
            catchError(err => this.handleError(err))
            );
    }



    /**
     * Retrieve data for data_type, start, end and user
     */
    getData(user: number, ids: Array<any>, start: number, end: number) {

        const _ids = ids.reduce((a, b) => a.concat(b), []);

        let type = "";
        if (!_ids || _ids.length === 0) { return Observable.empty(); } // Observable.empty<Response>()
        for (let i = 0; i < _ids.length; i++) {
            type += _ids[i];
            if (i < _ids.length - 1) {
                type += ",";
            }
        }
        const query = this.API_URL + "api/" + "data/?user=" + user +
            "&datatype=" + type +
            "&start=" + Math.floor(start) +
            "&end=" + Math.floor(end);
        return this._get(query);
    }




}
