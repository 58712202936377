<div class="hx-form-panel">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="hx-form hx-form-aligned">

    <ul>
      <li class="hx-control-group">
        <label for="new_password">New password</label>
        <input id="new_password" name="new_password" formControlName="new_password" type="password" class="form-control">
        <div *ngIf="formErrors.new_password" class="alert alert-danger">
          {{ formErrors.new_password }}
        </div>
        <div *ngIf="new_password.errors && new_password.errors.minlength" class="alert alert-danger">
          Password must be at least 8 characters long.
        </div>
      </li>

      <li class="hx-control-group">
        <label for="confirm_password">Confirm password</label>
        <input id="confirm_password" name="confirm_password" formControlName="confirm_password" type="password" class="form-control">
        <div *ngIf="formErrors.confirm_password" class="alert alert-danger">
          {{ formErrors.confirm_password }}
        </div>
      </li>
      <li class="hx-control-group">
        <label></label>
        <button type="submit" class="button button-green" [disabled]="!form.valid">Submit</button>
      </li>
    </ul>

  </form>