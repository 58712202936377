import { Component, OnInit } from '@angular/core';
import { OAuth2Flow } from '../../core/services/oauth2-flow';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  template: ``
})
export class LoginComponent implements OnInit {

  constructor() { }

  /**
   * Reset login status at the initialisation
   * Retrieve the return url
   */
  ngOnInit() {
    new OAuth2Flow({
      client_id: environment.clientId,
      secret: null,  // no secret key for implicit mode
      redirect_uri: environment.redirectURL,
      flow: environment.flow
    }).authenticate(() => {});
  }

}
