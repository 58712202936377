import { OnInit, OnDestroy, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, } from "@angular/core";
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
// import { chart } from 'highcharts/highstock';
import * as Highcharts from 'highcharts/highstock';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
/**
* Historical Chart component
*/
var BasicChartComponent = /** @class */ (function () {
    /**
     * constructor
     */
    function BasicChartComponent(eventAggregator) {
        var _this = this;
        this.eventAggregator = eventAggregator;
        /**
        * Color serie if specified
        */
        this.color = "#122F4C";
        /**
        * X Axis Suffix
        */
        this.xAxisSuffix = "";
        this.xAxisTitle = "";
        this.hasTimeAsXAxis = true;
        this.minX = null;
        this.maxX = null;
        /**
         * Enable horizontal scrollbar on Xaxis of the navigator
         */
        this.enabledScrollbar = false;
        this.enabledToolip = true;
        this.enableTitle = true;
        this.optionsColumnColors = null;
        /**
         * JSON for Axis options
         */
        this.optionsAxis = {
            "yAxis": [
                {
                    "labels": {
                        "format": "{value}"
                    },
                    "opposite": true
                }
            ]
        };
        this.interactive = true;
        /**
         * If range selector is diplayed
         */
        this.enableRangeSelector = false;
        this.markerEnabled = true;
        /**
         * If navigator is diplayed
         */
        this.enableNavigator = false;
        /**
         * Event Emitter when on action must be performed by parent component
         */
        this.action = new EventEmitter();
        /**
         * Deal with the message received and proceed to the appropriate treatment
         */
        this.onMessageReceived = function (payload) {
            //this.edit(payload.object);
            var that = _this;
            if (payload.object.msg === HxEvent.MAP__MOUSEHOVER_ROUTEPATH) {
                that._chart.xAxis[0].removePlotLine("highlighted");
                that._chart.xAxis[0].addPlotLine({
                    id: "highlighted",
                    color: "#F9B233",
                    value: that.values[payload.object.index][0],
                    width: 2
                });
            }
            if (payload.object.msg === HxEvent.MAP__MOUSEOUT_ROUTEPATH) {
                that._chart.xAxis[0].removePlotLine("highlighted");
            }
            if (payload.object.msg === HxEvent.CHART__DATA_LOADING) {
                that._chart.showLoading();
            }
            if (payload.object.msg === HxEvent.CHART__DATA_LOADED) {
                that._chart.hideLoading();
            }
            if (payload.object.msg === HxEvent.CHART__SYNC_ZOOM) {
                //this.updateOptions();
            }
        };
    }
    Object.defineProperty(BasicChartComponent.prototype, "salt", {
        set: function (value) {
            this.loadData();
        },
        enumerable: true,
        configurable: true
    });
    /**
    * Initialize the charts
    */
    BasicChartComponent.prototype.initChartOptions = function (names) {
        var that = this;
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        var title;
        if (this.enableTitle === true) {
            title = that.title;
        }
        else {
            title = "";
        }
        var options = {
            title: {
                text: title,
                align: 'center',
                x: 5,
                style: {
                    color: '#122F4C',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                enabled: that.enabledScrollbar
            },
            chart: {
                type: that.type,
                renderTo: "",
                zoomType: false,
                backgroundColor: 'rgba(238, 247, 248, 1)',
                style: {
                    "color": "white",
                    "fontFamily": "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
                    "fontSize": "12px"
                },
                events: {
                /**
                 * A click on the chart add a plot line (for annotation)
                 * if the flag icon has been clicked before and the annotation form is displayed
                 */
                },
            },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: that.markerEnabled
                    }
                },
                series: {
                    marker: {
                        radius: 2
                    }
                }
            },
            navigator: {
                adaptToUpdatedData: false,
                series: {
                    data: undefined
                },
                enabled: that.enableNavigator
            },
            rangeSelector: {
                enabled: that.enableRangeSelector,
                buttonTheme: {
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 8,
                    style: {
                        color: '#122F4C',
                        fontWeight: 'bold',
                    },
                    states: {
                        hover: {},
                        select: {
                            fill: '#122F4C',
                            style: {
                                color: 'white'
                            }
                        }
                        // disabled: { ... }
                    }
                },
                inputBoxBorderColor: 'gray',
                inputBoxWidth: 120,
                inputBoxHeight: 18,
                inputStyle: {
                    color: '#122F4C',
                    fontWeight: 'bold'
                },
                labelStyle: {
                    color: '#122F4C',
                    fontWeight: 'bold'
                },
                selected: 1
            },
            xAxis: {},
            loading: {
                labelStyle: {
                    color: '#122F4C'
                }
            },
            series: [],
            tooltip: {}
        };
        if (this.enabledToolip) {
            options.tooltip = {
                formatter: function (ev) {
                    var unit = "";
                    if (that.unit !== null) {
                        unit = that.unit;
                    }
                    var text;
                    if (that.hasTimeAsXAxis === true) {
                        // If unit is s (second), reformat the value to H:M:S
                        if (that.unit === 's') {
                            var time = that.values[ev.chart.hoverPoint.index][1];
                            var duration = "";
                            if (time > 1) {
                                duration = HxToolsTime.second2HHMM(time);
                            }
                            else {
                                // todo : refactoring
                                if (time * 10 >= 1) {
                                    duration = Number((time).toFixed(2)) + ' s';
                                }
                                if (time * 100 >= 1) {
                                    duration = Number((time).toFixed(3)) + ' s';
                                }
                            }
                            text = duration;
                        }
                        else {
                            text = (Math.round(100 * that.values[ev.chart.hoverPoint.index][1]) / 100) + " " + unit;
                        }
                    }
                    else {
                        text = (Math.round(100 * that.values[ev.chart.hoverPoint.index]) / 100) + " " + unit;
                    }
                    if (that.values[ev.chart.hoverPoint.index][3]) {
                        text += "<br>Calculated from a range";
                    }
                    return text;
                }
            };
        }
        else {
            options.tooltip = {
                enabled: false
            };
        }
        // Synchronize zoom
        /*const xAxis = {
            events: {
                setExtremes: function (e) {

                    if (typeof (e.rangeSelectorButton) !== 'undefined') {
                        that.reset++;

                        let numbers = Observable.timer(10000);
                        if (that.reset === 1) {
                            that.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                                {
                                    msg: HxEvent.CHART__SYNC_ZOOM,
                                    zoomCount: e.rangeSelectorButton.count,
                                    zoomType: e.rangeSelectorButton.type
                                }));
                        }
                        // Call after 10 second.. Please set your time
                        numbers.subscribe(x => {
                            that.reset = 0;
                        });


                    }
                }
            }
        };*/
        if (this.optionsColumnColors) {
            options = Object.assign({}, options, this.optionsColumnColors);
        }
        // to change that.... ugly...
        if (that.xAxis === "dayOfMonth") {
            options.xAxis = {
                "title": "Date",
                labels: {
                    formatter: function () {
                        return that.values[this.value][0];
                    }
                }
            };
        }
        else if (that.xAxis === "Unit") {
            options.xAxis = {
                labels: {
                    formatter: function () {
                        return that.values[this.value][0];
                    }
                },
                title: {
                    text: that.xAxisTitle,
                }
            };
        }
        else if (that.xAxis !== "") {
            options.xAxis = that.xAxis; //Object.assign({}, xAxis, that.xAxis);
            if (this.minX !== null && this.maxX !== null) {
                options.xAxis = {
                    min: this.minX,
                    max: this.maxX,
                    ordinal: false
                };
            }
        }
        // const colors = ["red", "#387EC7", "#AAD0F7", "#599ADD"];
        for (var i = 0; i < names.length; i++) {
            // let axis_i = 0;
            var serie = void 0;
            serie = {
                color: that.color,
                visible: true,
                name: "Projection",
                yAxis: 0,
                point: {
                    events: {
                        click: function (ev) {
                            //##
                            if (that.interactive) {
                                var unit = "";
                                if (that.unit !== null) {
                                    unit = that.unit;
                                }
                                that.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
                                    msg: HxEvent.CHART__EDIT_KPI,
                                    id: 5,
                                    title: that.title,
                                    label: that.label,
                                    date: this.x,
                                    resource_uri: that.ids && that.ids[this.x] && that.ids[this.x][0] ? that.ids[this.x][0] : null,
                                    range: that.ids && that.ids[this.x] && that.ids[this.x][1] ? that.ids[this.x][1] : null,
                                    value: this.y,
                                    unit: unit
                                }));
                            }
                        }
                    }
                }
            };
            options.series.push(serie);
        }
        options = Object.assign({}, options, this.optionsAxis);
        options.chart.renderTo = this.container.nativeElement;
        that.options = options;
        return options;
    };
    BasicChartComponent.prototype.ngOnInit = function () {
        this._chart.showLoading();
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
    };
    BasicChartComponent.prototype.ngAfterViewInit = function () {
        this.loadData();
    };
    BasicChartComponent.prototype.ngOnChanges = function (changes) {
        //##
        this.loadData();
    };
    /**
    * Load data
    */
    BasicChartComponent.prototype.loadData = function () {
        var that = this;
        that.names = ["projection"];
        var options = that.initChartOptions(that.names);
        options.navigator.series.data = this.values;
        that._chart = new Highcharts.StockChart(options);
        /*let serie = {
            "color": "red"
        }
        that._chart.options.series[0] = serie;*/
        that._chart.series[0].setData(this.values);
    };
    BasicChartComponent.prototype.ngOnDestroy = function () {
        if (this._chart) {
            this._chart.destroy();
        }
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    };
    return BasicChartComponent;
}());
export { BasicChartComponent };
