import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RangeService } from '../../../core/services/range.service';

@Component({
    selector: 'app-redirection',
    template: ``
})
export class RedirectionComponent {

    range: Object;
    rangeId: number;
    nextRangeId: number;
    previousRangeId: number;

    record: Object;
    recordId: number;
    nextRecordId: number;
    previousRecordId: number;

    userId: number;

    constructor(public router: Router,
        public rangeService: RangeService) { }

    /**
     * Redirect to a range page from its id
     * @params id range id
     */
    public redirectToRange(id) {
        this.range = null;
        this.rangeId = null;
        const link = ['/range-view/' + id];
        this.router.navigate(link);
    }

    /**
     * Redirect to next range
     */
    public nextRange() {
        this.redirectToRange(this.nextRangeId);
    }

    /**
     * Redirect to previous range
     */
    public previousRange() {
        this.redirectToRange(this.previousRangeId);
    }

    /**
     * Redirect to a record page from its id
     * @params id record id
     */
    public redirectToRecord(id) {
        this.record = null;
        this.recordId = null;
        const link = ['/record-view/' + id];
        this.router.navigate(link);
    }

    /**
     * Redirect to next record
     */
    public nextRecord() {
        this.redirectToRecord(this.nextRecordId);
    }

    /**
     * Redirect to previous record
     */
    public previousRecord() {
        this.redirectToRecord(this.previousRecordId);
    }

    /**
    * Retrieve the previous range
    * @param range
    */
    protected _retrievePreviousRangeId(range) {
        this.rangeService.getPrevious(range.start, this.userId).subscribe(_data => {
            this.previousRangeId = null;
            if (_data.objects.length > 0) {
                this.previousRangeId = _data.objects[0].id;
            }
        });
    }

    /**
    * Retrieve the next range
    * @param range
    */
    protected _retrieveNextRangeId(range) {
        this.rangeService.getNext(range.end, +this.userId).subscribe(_data => {
            this.nextRangeId = null;
            if (_data.objects.length > 0) {
                this.nextRangeId = _data.objects[0].id;
            }
        });
    }

}
