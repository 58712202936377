import { ElementRef, AfterViewInit, OnDestroy, EventEmitter, Renderer } from '@angular/core';
import { DomHandler } from '../../../core/dom/domhandler';
import { AddAthleteService } from './add-athlete.service';
var AddAthleteComponent = /** @class */ (function () {
    function AddAthleteComponent(el, domHandler, renderer, popupService) {
        var _this = this;
        this.el = el;
        this.domHandler = domHandler;
        this.renderer = renderer;
        this.popupService = popupService;
        this.acceptIcon = 'fa-check';
        this.acceptLabel = 'Yes';
        this.acceptVisible = true;
        this.deleteVisible = true;
        this.updateVisible = true;
        this.rejectIcon = 'fa-close';
        this.rejectLabel = 'No';
        this.rejectVisible = true;
        this.closeOnEscape = true;
        this.closable = true;
        this.responsive = true;
        this.subscription = popupService.requirePopup$.subscribe(function (popup) {
            if (popup.key === _this.key) {
                _this.popup = popup;
                _this.message = _this.popup.message || _this.message;
                _this.icon = _this.popup.icon || _this.icon;
                _this.header = _this.popup.header || _this.header;
                _this.unit = _this.popup.unit || _this.unit;
                _this.kpi = _this.popup.kpi || _this.kpi;
                _this.resource_uri = _this.popup.resource_uri || _this.resource_uri;
                _this.email = popup.email ? popup.email : null;
                _this.first_name = popup.first_name ? popup.first_name : null;
                _this.last_name = popup.last_name ? popup.last_name : null;
                _this.rejectVisible = _this.popup.rejectVisible === null ? _this.rejectVisible : _this.popup.rejectVisible;
                _this.acceptVisible = _this.popup.acceptVisible === null ? _this.acceptVisible : _this.popup.acceptVisible;
                _this.deleteVisible = _this.popup.deleteVisible === null ? _this.deleteVisible : _this.popup.deleteVisible;
                _this.updateVisible = _this.popup.updateVisible === null ? _this.updateVisible : _this.popup.updateVisible;
                if (_this.popup.accept) {
                    _this.popup.acceptEvent = new EventEmitter();
                    _this.popup.acceptEvent.subscribe(_this.popup.accept);
                }
                if (_this.popup.reject) {
                    _this.popup.rejectEvent = new EventEmitter();
                    _this.popup.rejectEvent.subscribe(_this.popup.reject);
                }
                _this.visible = true;
            }
        });
    }
    Object.defineProperty(AddAthleteComponent.prototype, "visible", {
        get: function () {
            return this._visible;
        },
        set: function (val) {
            this._visible = val;
            if (this._visible) {
                if (!this.positionInitialized) {
                    this.center();
                    this.positionInitialized = true;
                }
                this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
            }
            if (this._visible)
                this.enableModality();
            else
                this.disableModality();
        },
        enumerable: true,
        configurable: true
    });
    AddAthleteComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.contentContainer = this.domHandler.findSingle(this.el.nativeElement, '.hx-ui-popup-content');
        if (this.responsive) {
            this.documentResponsiveListener = this.renderer.listenGlobal('window', 'resize', function (event) {
                _this.center();
            });
        }
        if (this.closeOnEscape && this.closable) {
            this.documentEscapeListener = this.renderer.listenGlobal('body', 'keydown', function (event) {
                if (event.which === 27) {
                    if (_this.el.nativeElement.children[0].style.zIndex === DomHandler.zindex) {
                        _this.hide(event);
                    }
                }
            });
        }
        if (this.appendTo) {
            if (this.appendTo === 'body')
                document.body.appendChild(this.el.nativeElement);
            else
                this.domHandler.appendChild(this.el.nativeElement, this.appendTo);
        }
    };
    AddAthleteComponent.prototype.center = function () {
        var container = this.el.nativeElement.children[0];
        var elementWidth = this.domHandler.getOuterWidth(container);
        var elementHeight = this.domHandler.getOuterHeight(container);
        if (elementWidth === 0 && elementHeight === 0) {
            container.style.visibility = 'hidden';
            container.style.display = 'block';
            elementWidth = this.domHandler.getOuterWidth(container);
            elementHeight = this.domHandler.getOuterHeight(container);
            container.style.display = 'none';
            container.style.visibility = 'visible';
        }
        var viewport = this.domHandler.getViewport();
        var x = (viewport.width - elementWidth) / 2;
        var y = (viewport.height - elementHeight) / 2;
        container.style.left = x + 'px';
        container.style.top = y + 'px';
    };
    AddAthleteComponent.prototype.enableModality = function () {
        if (!this.mask) {
            this.mask = document.createElement('div');
            this.mask.style.zIndex = this.el.nativeElement.children[0].style.zIndex - 1;
            this.domHandler.addMultipleClasses(this.mask, 'hx-ui-widget-overlay hx-ui-popup-mask');
            document.body.appendChild(this.mask);
        }
    };
    AddAthleteComponent.prototype.disableModality = function () {
        if (this.mask) {
            document.body.removeChild(this.mask);
            this.mask = null;
        }
    };
    AddAthleteComponent.prototype.hide = function (event) {
        this.visible = false;
        if (event) {
            event.preventDefault();
        }
    };
    AddAthleteComponent.prototype.moveOnTop = function () {
        this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
    };
    AddAthleteComponent.prototype.ngOnDestroy = function () {
        this.disableModality();
        if (this.documentResponsiveListener) {
            this.documentResponsiveListener();
        }
        if (this.documentEscapeListener) {
            this.documentEscapeListener();
        }
        if (this.appendTo && this.appendTo === 'body') {
            document.body.removeChild(this.el.nativeElement);
        }
        this.subscription.unsubscribe();
    };
    Object.defineProperty(AddAthleteComponent.prototype, "diagnostic", {
        get: function () { return JSON.stringify(this.email); },
        enumerable: true,
        configurable: true
    });
    AddAthleteComponent.prototype.accept = function (form) {
        if (this.popup.acceptEvent) {
            this.popup.acceptEvent.emit();
            this.popupService.postTask({
                "action": "save",
                "email": this.email,
                "first_name": this.first_name,
                "last_name": this.last_name
            });
            this.email = null;
        }
        this.hide();
        this.popup = null;
    };
    AddAthleteComponent.prototype.reject = function () {
        if (this.popup.rejectEvent) {
            this.popup.rejectEvent.emit();
        }
        this.hide();
        this.popup = null;
    };
    return AddAthleteComponent;
}());
export { AddAthleteComponent };
