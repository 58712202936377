// Import from other modules
import { environment } from '../../../../environments/environment';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// Import from current module
import { ConfirmationService } from '../../../shared/components/confirmation/confirmation.service';
import { ApiQuery, ApiService, RecordService } from '../../../core/services';
import { Record } from "../../../models";
import { Message } from '../../../shared/components/growl/message';
import { DataService } from '../../../core/services';
import { Subject } from 'rxjs/Subject';
import { GlobalVariables } from '../../../core/services/global.variables';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import * as moment from 'moment/moment';

const TableData: Array<any> = [];

@Component({
    selector: 'app-records-table',
    templateUrl: './records-table.component.html',
    styleUrls: ['./records-table.component.scss',
        '../../../../assets/stylesheets/core/nav.scss',
        '../../../../assets/stylesheets/core/section.scss',
        '../../../../assets/stylesheets/button.scss'/*,
        '../../../../assets/stylesheets/core/time-range.scss'*/],
})
export class RecordsTableComponent extends HelperComponent implements OnInit {

    /**
     * Array of records
     */
    public data: Array<Record> = TableData;
    public displayedData: Array<Record> = TableData;

    public myDateRangePickerOptions = {};

    /**
     * Number of records before update
     * If previousDataLength !== number of records then new record(s) has(ve) been added or deleted
     */
    private previousDataLength: number = 0;

    /**
     * Equals to true if the data is being retrieved from the server
     */
    public loading = false;

    public filter_username = null;
    /**
     * 
     */
    public selectedPeriod: any;
    public start: number;
    public end: number;

    /**
     * The number of times the server has been called the page has been loaded
     */
    private time: number = 0;

    /**
     * Equals to true if a new record has been added
     */
    public hasNewRecord: boolean;

    public subjects: Array<any>;

    public dropdownHitMouseOver: Array<boolean>;

    /**
     * Minimum duration of records
     */
    public filterByDuration = 0;

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    /**
     * Total number of records
     */
    public totalRecordsCount;

    /**
     * Sorting
     */
    public column: string = "start";
    public order: string = "desc";

    /**
     * Filters
     */
    public filterByUser = null;

    /**
     * If we display the last record button
     */
    displayLastRecordBtn = false;

    /**
    * If we display the last record button
    */
    displayResetBtn = true;

    downloadInProgress = false;
    downloadClicked = false;

    /**
     * Table parameters
     */
    public maxItemsPerPage = 10;
    public currentPage = 1;
    public offset = 0;
    public pages;
    public totalPages;

    durationQuery: number; // bind this to input with ngModel
    durationQueryChanged: Subject<number> = new Subject<number>();

    dateRanges: any = {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 100 days': [moment().subtract(100, 'days'), moment()],
        'This year': [moment().startOf('year'), moment()],
        'From the beginning': [moment().subtract(10, 'year'), moment()],
    };


    //private options: Object;
    /**
     * CONSTRUCTOR
     */
    public constructor(private recordService: RecordService,
        private globalVariables: GlobalVariables,
        private apiService: ApiService,
        private router: Router,
        public helpService: HelpService,
        private dataService: DataService,
        private confirmationService: ConfirmationService,
        public viewContainerRef: ViewContainerRef) {
        super(helpService);
        this.pages = new Array<number>();

        this.dropdownHitMouseOver = new Array<boolean>();
        this.durationQueryChanged
            .debounceTime(1000) // wait 1 sec after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(model => {
                this.durationQuery = model;
                const resourceUri = this.globalVariables.get("profileUri");
                this.apiService.getFromUri(resourceUri).flatMap(data => {
                    this.currentPage = 1;
                    let preferences = data.preferences;
                    preferences.filterByDuration = this.filterByDuration;
                    return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
                }).subscribe(() => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'Your preference has been saved.'
                    });
                    this.getAll();
                },
                    error => {
                        console.error(error);
                    });
            });

    }

    /**
     * On init
     */
    public ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.hasNewRecord = false;
        this.selectedPeriod = {                               // update the selected period to be displayed
            startDate: moment().subtract(10, 'year'),
            endDate: moment()
        };
        this.getAll();
    }

    /**
     * Delete new record message
     */
    public deleteMsg() {
        this.hasNewRecord = false;
    }

    /**
     * Display the previous page
     */
    nextPage() {
        this.currentPage++;
        this.getAll();
    }

    /**
     * Display the next page
     */
    previousPage() {
        this.currentPage--;
        this.getAll();
    }

    /**
     * Display the page 
     */
    changeToPage(page) {
        this.currentPage = page;
        this.getAll();
    }

    onClickGo($event) {
        this.currentPage = 1;
        if ($event !== undefined) {
            let range = $event;
            this.currentPage = 1;
            this.start = 256 * range.startDate.startOf('day').unix();
            this.end = 256 * range.endDate.endOf('day').unix();
        }
        this.getAll();
    }

    resetByPage() {

        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            let preferences = data.preferences;
            preferences.maxItemsPerPage = this.maxItemsPerPage;
            return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(() => {
            this.msgs = [];
            this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            this.getAll();
        },
            error => {
                console.error(error);
            });
    }

    reset() {
        this.start = this.end = null;
        this.selectedPeriod = null;
        this.currentPage = 1;
        this.getAll();
    }

    updateDuration(ev) {

        this.durationQueryChanged.next(ev);
    }

    /**
     * Export of CSV file
     */
    public exportCSV(recordId) {
        this.downloadInProgress = true;
        const datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, 'text/csv').subscribe(data => {

            const filename = "record-" + recordId + ".csv";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {

                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;
            }

        });
    }

    /**
     * Export of binary file
     */
    public exportBinary(recordId) {
        this.downloadInProgress = true;
        const datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, 'application/octet-stream').subscribe(data => {

            const filename = "record-" + recordId + ".zip";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {

                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;

            }

        });
    }

    /**
     * Export of EDF file
     */
    public exportEDF(recordId) {
        this.downloadInProgress = true;
        const datatype_query = "33,49,19,53";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, "application/x-edf").subscribe(data => {

            const filename = "record-" + recordId + ".edf";
            const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                this.downloadInProgress = false;
            } else {

                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                this.downloadInProgress = false;
            }
        });
    }


    onSingleOpened() {

    }

    onSingleClosed() {


    }

    onSingleSelected($event) {
        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            this.currentPage = 1;
            this.filterByUser = $event.value;
            let preferences = data.preferences;
            preferences.filterByUser = $event.value;
            return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(() => {

            this.msgs = [];
            this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            this.getAll();
        },
            error => {
                console.error(error);
            });
    }

    onSingleDeselected() {
        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            this.currentPage = 1;
            this.filterByUser = null;
            let preferences = data.preferences;
            preferences.filterByUser = null;
            return this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(() => {

            this.msgs = [];
            this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            this.getAll();
        },
            error => {
                console.error(error);
            });
    }

    /**
     * Retrieve all the records the logged user is allowed to see
     */
    getAll() {
        this.loading = true;
        let offset = (this.currentPage - 1) * this.maxItemsPerPage;

        if (this.start === undefined || this.end === undefined) {
            this.start = 256 * moment().subtract(10, 'year').unix();
            this.end = 256 * moment().unix();
        }

        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(data => {
            this.maxItemsPerPage = 10;
            this.filterByUser = null;
            if (data.preferences) {
                if (data.preferences.maxItemsPerPage) {
                    // todo : check if tree is valid
                    this.maxItemsPerPage = data.preferences.maxItemsPerPage;
                }
                if (data.preferences.filterByUser) {
                    // todo : check if tree is valid
                    this.filterByUser = data.preferences.filterByUser;
                }
                if (data.preferences.filterByDuration) {
                    // todo : check if tree is valid
                    this.filterByDuration = data.preferences.filterByDuration;
                }
            }
            return this.apiService.getQuery(ApiQuery.all_users_for_user_id);
        }).flatMap(data => {

            this.subjects = new Array<any>();
            for (let i = 0; i < data.objects.length; i++) {
                const subject = {
                    "value": "" + data.objects[i].id,
                    "label": data.objects[i].first_name + " " + data.objects[i].last_name
                };
                this.subjects.push(subject);
            }
            return this.recordService.fetch(this.maxItemsPerPage, offset,
                this.filterByUser, this.column, this.order === "desc" ? "-" : null,
                this.start, this.end, null, this.filterByDuration / 1000);
        }).subscribe(data => {
            this.totalRecordsCount = data.meta.total_count;
            for (let i = 0; i < this.totalRecordsCount; i++) {
                this.dropdownHitMouseOver[i] = false;
            }
            this.totalPages = Math.ceil(this.totalRecordsCount / this.maxItemsPerPage);
            // Beginning - pagination
            this.pages = [];
            if (this.currentPage === 1) {
                this.pages.push(1);
                if (this.maxItemsPerPage < this.totalRecordsCount) {
                    this.pages.push(2);
                }
                if (this.maxItemsPerPage * 2 < this.totalRecordsCount) {
                    this.pages.push(3);
                }
            }
            if (this.currentPage >= 2) {
                if (this.currentPage - 2 > 0) this.pages.push(this.currentPage - 2);
                this.pages.push(this.currentPage - 1);
                this.pages.push(this.currentPage);
                if (this.maxItemsPerPage * this.currentPage < this.totalRecordsCount) {
                    this.pages.push(this.currentPage + 1);
                }
            }
            // End - pagination
            this.data = data;
            let temp_data = this.populate(data.objects);
            this.displayedData = temp_data;

            if ((this.time > 0) && (this.previousDataLength !== this.totalRecordsCount)) {
                //this.hasNewRecord = true;
            }
            this.previousDataLength = this.totalRecordsCount;
            this.time++;
            this.loading = false;
        },
            error => {
                console.error(error);
            });
    }


    decreaseDuration() {
        this.filterByDuration = this.filterByDuration - 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    }

    increaseDuration() {
        this.filterByDuration = this.filterByDuration + 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    }

    populate(records) {
        let temp_data = [];
        for (const record1 of records) {
            let str: string = JSON.stringify(record1);
            let record2: Record = new Record(str, this.apiService);

            record2.calculate();
            temp_data.push(record2);
        }
        return temp_data;
    }

    /**
     * 
     */
    paginate(data) {
        let temp_data = [];
        data = this.populate(data);
        if (data) {
            this.data = data;
            let start = this.maxItemsPerPage * (this.currentPage - 1);
            let i = 0;
            for (let record1 of data) {
                if ((i >= start) && (i < start + this.maxItemsPerPage)) {
                    temp_data.push(record1);
                }
                i++;
            }
        }
        return temp_data;
    }

    /**
     * Delete a record
     * @param {number} id
     */
    delete(record: Record) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.msgs = [];
                this.loading = true;
                this.recordService.delete(record.id, "record")
                    .subscribe(
                        () => {

                            this.time = 0;
                            this.getAll();
                            this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Record deleted' });
                            this.loading = false;
                        },
                        error => {
                            console.error(error);
                            this.loading = false;
                        });
            }
        });
    }

    /**
     * Redirect to record view page
     */
    view(record: Record) {
        const link = ['/record-view/' + record.id];

        this.router.navigate(link);
    }

    /**
     * Redirect to export view page
     */
    export(record: Record) {
        const link = ['/record-export/' + record.id];
        this.router.navigate(link);
    }

    /**
     * Sort data table
     */
    sort(property) {
        this.column = property;
        if (this.order === 'asc') this.order = 'desc';
        else this.order = 'asc';
        this.getAll();
    }

}
