import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router) { }

    /* Basically we only check if the 3 following info are stored in localstorage 
    to give access to the pages */
    canActivate() {
        if (localStorage.getItem('access_token')
        && localStorage.getItem('profileUri')
        && localStorage.getItem('currentUserId')) {
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']);
            return false;
        }
    }

}
