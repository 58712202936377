// Import from other modules
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { ConfigService } from '../../../shared/components/config/config.service';
import { ApiQuery, ApiService, RecordService, RangeService, DataService } from '../../../core/services';
import { Record, Annotation, Band } from "../../../models";
import { Action, ChartService } from "../../../charts/chart/";
import { Message } from '../../../shared/components/growl/message';
import { MapService } from '../../../shared/components/map/map.service';
import { MapAction } from '../../../shared/components/map/action';
import { Subscription } from 'rxjs/Subscription';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { HxToolsGeo, HxToolsTime, HxToolsRange, HxToolsTimeline } from '../../../shared/hx-tools';
import { Router } from '@angular/router';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../../core/services/global.variables';
import { DataViewComponent } from '../../data-view/data-view.component';
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { HelpService } from '../../../shared/components/help/help.service';
import { Section, HelperComponent } from '../../../shared/components/helper/helper.component';
import { BoxViewComponent } from '../../data-view/box-view.component';
import { ExportComponent } from '../../../shared/components/export/export.component';
import { RedirectionComponent} from '../../../shared/components/redirection/redirection.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Mixin } from '../../../core/mixin/mixin-decorator';
import * as _ from 'lodash';
import { METRICS } from '../../../config';

@Mixin([BoxViewComponent, RedirectionComponent, ExportComponent, HelperComponent])
@Component({
  selector: 'app-record-view',
  templateUrl: './record-view.component.html',
  styleUrls: ['./record-view.component.scss',
    '../../../../assets/stylesheets/app.scss',
    '../../../../assets/stylesheets/core/section.scss',
    '../../../../assets/stylesheets/core/nav.scss',
    '../../../../assets/stylesheets/core/grid.scss',
    '../../../../assets/stylesheets/core/icon.scss',
    '../../../../assets/stylesheets/chart.scss',
    '../../../../assets/stylesheets/button.scss']
})
export class RecordViewComponent extends DataViewComponent implements OnInit, OnDestroy, BoxViewComponent {

  /**
   * Form DOM manipulation of the views
   */
  @ViewChild("elem") elem: ElementRef;

  /**
   * View list telling if displayed or not
   */
  public displayView: Map<string, boolean>;

  /**
   * Promise for retrieve url parameters
   */
  public sub: any;

  /**
   * Record unique ID
   */
  public recordId: number;

  /**
   * User ID
   */
  public userId: number;

  /**
   * Record object
   */
  public record: Record;

  /**
   * List of records on the same day to be displayed on the timeline
   */
  public records: Array<any>;

  /**
   * List of ranges on the same day to be displayed on the timeline
   */
  public rangesOfDay: Array<any>;

  /**
   * Annotation currently being edited
   */
  public editableAnnotation: Annotation;

  /**
   * Range currently being edited
   */
  public editableRange: Band;

  /**
   * State tells whether the user is editing or add range/annotation to record
   */
  public state = null;

  /**
   * Equals to true if the data is being retrieved from the server
   */
  public loading = true;

  /**
   * If processed data charts are displayed
   */
  public displayProcessedData = true;

  /**
   * If raw data charts are displayed
   */
  public displayRawData = false;

  /**
   * All the displayable processed data charts
   */
  public charts: Array<any> = [];
  public processedCharts: Array<any> = [];

  /**
   * The curve queue for processed data charts
   */
  public curvesQueue: Array<number>;
  public curves: Array<any>;

  /**
   * The curve queue for raw data charts
   */
  public curvesRawQueue: Array<number>;
  public curvesRaw: Array<any>;
  /**
   * All the displayable raw data charts
   */
  public rawCharts: Array<any> = [];

  /**
   * Messages for growl
   */
  public msgs: Message[] = [];

  /**
   * If annotation/range forms are displayed
   */
  displayedForms: Array<boolean>;

  /**
   * the GPS positions of the path to be displayed on the map
   */
  public gpsPositions: Array<any>;

  /**
   * All the activity types retrieved from server
   */
  public activityTypes;

  /**
   * the altitudes and distance between altitude positions along the path
   */
  public altitudes: Array<any>;
  public altitudeDistances: Array<any>;

  public salt: number;

  public metricValues: Array<any> = [];

  public displayNavigatorRawData = true;
  public displayNavigatorProcessedData = false;

  styleDisappear = `@-webkit-keyframes disappear{ 0% { /* Sets the initial height to the measured height of the element */ height: ##height##;
  } 30% { -webkit-transform: translateX(-5%); transform: translateX(-5%); } 60% { -webkit-transform: translateX(200%); transform:
  translateX(200%); /* Makes sur the height and bottom margin stay constant till this point in the animation */ height: ##height##;
  margin-bottom: 1em; } 100% { /* Keeps the element out of the screen. If it's not there, * the element comes back to its
  original position */ -webkit-transform: translateX(200%); transform: translateX(200%); /* Makes the blank space collapse
  */ height: 0; margin-bottom: 0; margin-top: 0;} } @keyframes disappear{ 0% { /* Sets the initial height to the measured height of the
  element */ height: ##height##; } 30% { -webkit-transform: translateX(-5%); transform: translateX(-5%); } 60% { -webkit-transform:
  translateX(200%); transform: translateX(200%); /* Makes sur the height and bottom margin stay constant till this point
  in the animation */ height: ##height##; margin-bottom: 1em; } 100% { /* Keeps the element out of the screen. If it's not
  there, * the element comes back to its original position */ -webkit-transform: translateX(200%); transform: translateX(200%);
  /* Makes the blank space collapse */ height: 0; margin-bottom: 0; margin-top: 0;} }`;

  boxes = [];

  public metricsToDisplay: Array<number>;

  /* Variables for timeline */
  public time = "diurnal"; // or noctural
  public startHour = 0;
  public endHour = 24;
  // current user;
  public user;

  startOfTheDay: any;
  endOfTheDay: any;

  public annotations: Array<any> = [];
  public subRanges: Array<any>;

  public isOverNight = false;
  public previousRecordId: number = null;
  public nextRecordId: number = null;

      /**
   * Inherited from parent classes
   */
  exportData: () => void;

  /*
   List of the metrics to display in panel.
   Equals to METRICS in config file.
   Turn around : importing const in template does not work in 
   defined outside component file...
   */
  public metrics;

  helps = [];

  //@ViewChildren(Section) sections: QueryList<Section>;
  @ViewChildren(Section, { read: ElementRef }) sections: QueryList<ElementRef>;
  @ViewChild('chartsbtn') chartsbtn: ElementRef;

  /**
   * CONSTRUCTOR
   */
  constructor(public store: Store<AppState>,
    public route: ActivatedRoute,
    public recordService: RecordService,
    public configService: ConfigService,
    public helpService: HelpService,
    public apiService: ApiService,
    public globalVariables: GlobalVariables,
    public router: Router,
    public eventAggregator: EventAggregator,
    public selectorService: SelectorService,
    public dataService: DataService,
    public mapService: MapService,
    public rangeService: RangeService,
    public chartService: ChartService) {

    super(store, chartService, apiService, selectorService, globalVariables);
    this.metrics = METRICS;

    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);

    this.rangesOfDay = new Array<any>();
    this.metricsToDisplay = new Array<number>();

    this.altitudes = new Array<any>();
    this.altitudeDistances = new Array<any>();

    this.gpsPositions = new Array<any>();

    this.mapService.tasksConfirmed$.subscribe(
      task => {
        if (task.action === MapAction.CLICK_ON_ROUTE_PATH) {

        }
      });

    this.displayView = new Map<string, boolean>();
    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);

    //-------------------------------- Chart related actions  ---------------------------------//
    this.chartService.tasksConfirmed$.subscribe(
      task => {
        if (task.action === Action.REFRESH_CHART) {
          this.rangeService.fetchPeriod(this.record.end, this.record.start, null, null, this.userId, "start").subscribe(data => {
            this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, this.record, this.startOfTheDay, this.endOfTheDay);
          });
          this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
            user: this.userId,
            start: this.record.start, end: this.record.end
          }).subscribe(_annotations => {
            this.annotations = _annotations.objects;
          });
        }
        if (task.action === Action.DISPLAY_RANGE_EDIT_FORM) {
          this.displayForm('EDIT_RANGE');
        }
        if (task.action === Action.HIDE_RANGE_FORM) {
          this.editableRange = null;
        }
      });

  }

  /**
   * Inherited from BoxViewComponent
   */
  isBoxPreferenceValid: () => boolean;

  /**
   * Inherited from BoxViewComponent
   */
  initBoxes: () => void;

  ngOnInit() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.SELECTOR__DISPLAY
      }));
    // We retrieve the record Id from the URL
    this.sub = this.route.params.subscribe(params => {
      this.recordId = +params['id'];
      this.init();
    });

  }

  afterViewInit(): void {
    this.sections.forEach(_section => {
      this.helps[_section.nativeElement.id] = false;
    });
  }

  openModalChartsBtn() {
    let _top = 0 + this.chartsbtn.nativeElement.getBoundingClientRect().top + window.scrollY;
    this.helpService.open("chartsbtn", _top + 'px');
  }

  /**
   * Treatment of messages received from event aggregator
   */
  private onMessageReceived = (payload: MessageSentEventPayload) => {
    if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
      this.router.navigate(['/home']);
    }
  }

  init() {

    let user = this.globalVariables.get('currentSubject');
    this.userId = user.id;

    this.curves = new Array<any>();
    this.curvesRaw = new Array<any>();

    this.curvesQueue = new Array<number>();
    this.curvesRawQueue = new Array<number>();

    // By default we only display the first curve of each graph
    this.curvesQueue.push(0);
    this.curvesRawQueue.push(0);

    this.loading = true;

    this.initBoxes();

    // We retrieve the default config
    this.configService.read('assets/config/config-raw.json').flatMap(data => {
      this.rawCharts = data;

      return this.configService.read('assets/config/config.json');
    }).flatMap(data => {
      this.processedCharts = data;
      return this.recordService.get(this.recordId);
    }).flatMap(data => {

      const str: string = JSON.stringify(data);
      const record: Record = new Record(str, this.apiService);
      record.calculate();
      this.user = record._user.first_name + " " + record._user.last_name;
      this.userId = record._user.id;
      // change current user
      this.globalVariables.set('currentSubject', record.user);
      this.globalVariables.set('currentSubjectId', "" + record._user.id);
      /*this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
        {
          msg: HxEvent.FSS__MOUSECLICK_SUBJECT
        }));*/

      /*************** Charts **************/

      let chartSet;

      // Raw Data Charts
      chartSet = new Object();
      for (const id of this.curvesRawQueue) {
        this._addCurve(chartSet, this.rawCharts[id]);
      }
      this.curvesRaw[0] = chartSet;

      // Processed Data Charts (check if datatype is relevant for activity)
      this.charts = new Array<any>();
      for (const chart of this.processedCharts) {
        let found = true;
        if (chart.activitytypes && chart.activitytypes.length > 0) {
          found = false;
        }
        if (found) {
          this.charts.push(chart);
        }
      }

      chartSet = new Object();
      for (let id of this.curvesQueue) {
        this._addCurve(chartSet, this.charts[id]);
        //this.displayedHistograms[id] = true;
      }
      this.curves[0] = chartSet;

      /*************** End charts **************/

      this.record = record;
      this.metricValues = record.metrics;

      const parameters = HxToolsTimeline.getParameters(record);
      this.time = parameters.time;
      this.startHour = parameters.startHour;
      this.endHour = parameters.endHour;
      this.startOfTheDay = parameters.startOfTheDay;
      this.endOfTheDay = parameters.endOfTheDay;

      record.calculate();
      this.userId = this.record.user ? this.record._user.id : null;
      // Retrieve all the records of the day
      return this.recordService.fetchPeriod(this.startOfTheDay * 256, this.endOfTheDay * 256, 0, 0, this.userId);
    }).flatMap(data => {
      // empty the records array
      this.isOverNight = HxToolsTime.isOverNight(1000 * this.record.start / 256, 1000 * this.record.end / 256);
      this.records = new Array<any>();
      for (let record of data.objects) {
        let intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(record.start),
          HxToolsTime.HX2U(record.end),
          this.startOfTheDay * 1000, this.isOverNight);
        this.records.push(
          {
            "start": record.start,
            "end": record.end,
            "dstart": intervals[0],
            "dend": intervals[1],
            "recordId": record.id,
            "highlighted": this.record.id === record.id ? true : false
          }
        );
      }

      return this.rangeService.fetchPeriod(this.endOfTheDay * 256, this.startOfTheDay * 256, 0, 0, this.userId);
    }).flatMap(_ranges => {
      this.rangesOfDay = new Array<any>();
      for (let range of _ranges.objects) {
        let intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(range.start),
          HxToolsTime.HX2U(range.end),
          this.startOfTheDay * 1000, this.isOverNight);
        this.rangesOfDay.push(
          {
            "start": range.start,
            "end": range.end,
            "dstart": intervals[0],
            "dend": intervals[1],
            "rangeId": range.id,
            "highlighted": false
          }
        );
      }
      /*  return this.rangeService.fetchPeriod(this.record.end, this.record.start, 0, 0, this.userId);
      }).flatMap(data => {
  
        /*this.metricsToDisplay = new Array<number>();
        if (data.objects) {
          for (const range of data.objects) {
            for (const metric of range.metrics) {
              this.metricsToDisplay.push(metric.id);
            }
          }
        }
        // If there is no activity inside the record, then we display default metrics
        if (this.metricsToDisplay.length === 0) {*/
      this.metricsToDisplay = [12, 14, 15, 257, 71, 44, 46, 47, 149, 58, 255];
      /*}*/

      return this.recordService.get(this.recordId, this.metricsToDisplay);
    }).flatMap(data => {
      this.metricValues = data.metrics;

      // return this.gpsService.get(this.record._user.id, this.record.start, this.record.end);
      return this.apiService.getQuery(ApiQuery.retrieve_gps_positions,
        {
          user_id: this.record._user.id,
          start: this.record.start,
          end: this.record.end
        });
    }).flatMap(data => {

      this.gpsPositions = data['objects'];
      this.altitudes = HxToolsGeo.retrieveAltitudeFromGPS(this.gpsPositions);

      return this.apiService.getQuery(ApiQuery.all_activitiytype);
    }).flatMap(data => {
      let activityTypes = data.objects.filter(activitiy => activitiy.role === 'system');
      let _activityTypes = activityTypes;
      for (let i = 0; i < _activityTypes.length; i++) {
        _activityTypes[i].label = _activityTypes[i].name;
        _activityTypes[i].icon = HxToolsActivity.activityTypeToIconClass(_activityTypes[i].name);
        _activityTypes[i].value = "" + _activityTypes[i].id;
      }
      this.activityTypes = _.sortBy(_activityTypes, 'label');
      // add training routine : 5 mins rest test (hardcoded...)
      this.activityTypes.push({'label': '5 Minutes Rest Test', 'id': 1001016, 'value': 1001016, 'icon': "hx-icon-5-minute-rest-test"});
      return this.recordService.getNext(this.record.end, this.userId);
    }).flatMap(data => {
      this.nextRecordId = null;
      if (data.objects.length > 0) {
        this.nextRecordId = data.objects[0].id;
      }
      return this.recordService.getPrevious(this.record.start, this.userId);
    }).flatMap(data => {
      this.previousRecordId = null;
      if (data.objects.length > 0) {
        this.previousRecordId = data.objects[0].id;
      }
      this.loading = false;
      return this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
        user: this.userId,
        start: this.record.start, end: this.record.end
      });
    }).flatMap(_annotations => {
      this.annotations = _annotations.objects;
      return this.rangeService.fetchPeriod(this.record.end, this.record.start, null, null, this.userId, "start");
    }).subscribe(data => {
      this.subRanges = HxToolsRange.getRangesOfTheDayForTimelineForRecord(data.objects, this.startOfTheDay, this.endOfTheDay);
      this.loading = false;
    });
  }

  /** 
   * Perform the actions corresponding to the events emitted
   * by others components
   */
  performAction(action: Action) {
    super.performAction(action);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.SELECTOR__HIDE
      }));
  }

}
