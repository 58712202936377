import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';
import { HxToolsTime } from '../hx-tools/hx-tools-time';

/**
 * Convert hx timestamp to human readable date time
 * Ex : { datetime | dateHumanize }
 */
@Pipe({ name: 'dateHumanize' })
export class DateHumanizePipe implements PipeTransform {
  transform(time: number): string {
    if (!time) return undefined;
    let truncated_time = Math.trunc(1000 * time / 256); // In case the timestamp is not an integer
    if ((new Date(truncated_time)).getTime()) {
      return moment(truncated_time).format('YYYY-MM-DD HH:mm:ss');
    }
    // if time is not a valid timestamp, it returns 
    return "";
  }
}


@Pipe({ name: 'durationHumanize' })
export class DurationHumanizePipe implements PipeTransform {
  // duration is in milliseconds
  transform(duration: number): string {
    if (!duration) return undefined;
    return moment.duration(duration, 'milliseconds').humanize();
  }
}

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {

  transform(value: Date): string {
    let today = moment();
    let birthdate = moment(value);
    let years = today.diff(birthdate, 'years');
    let html: string = years + "y ";

    html += today.subtract(years, 'years').diff(birthdate, 'months') + "m";

    return html;
  }

}

@Pipe({ name: 'durationMinimized' })
export class DurationMinimizedPipe implements PipeTransform {
  transform(duration: string): string {
    if (duration) {
      let _duration = duration;
      _duration = _duration.replace(/a few seconds/gi, "< 1 m");
      _duration = _duration.replace(/minutes/gi, "m");
      _duration = _duration.replace(/a minute/gi, "1 m");
      _duration = _duration.replace(/hours/gi, "h");
      _duration = _duration.replace(/an hour/gi, "1 h");
      _duration = _duration.replace(/secondes/gi, "s");
      return _duration;
    } else {
      return "?";
    }
  }
}

@Pipe({ name: 'durationMin2HrMinForm' })
export class DurationMin2HrMinFormPipe implements PipeTransform {
  transform(duration: number): string {
    if (duration) {
      const _duration = moment.duration(duration, 'minutes');
      const hours = ("" + _duration.hours()).length === 1 ? "0" + _duration.hours() : _duration.hours();
      const minutes = ("" + _duration.minutes()).length === 1 ? "0" + _duration.minutes() : _duration.minutes();
      return hours + ":" + minutes;
    } else {
      return "00:00";
    }
  }
}


@Pipe({ name: 'second2HMS' })
export class Second2HMSPipe implements PipeTransform {
  transform(duration: number): string {
    return HxToolsTime.second2HMS(duration);
  }
}

@Pipe({ name: 'HMSFromRelativeTime' })
export class HMSFromRelativeTimePipe implements PipeTransform {
  transform(duration: number): string {
    if (duration >= 0) {
      return HxToolsTime.second2HMS(duration);
    } else {
      return HxToolsTime.second2HMS(24 * 60 * 60 + duration);
    }
  }
}

@Pipe({ name: 'millisecond2HMS' })
export class Millisecond2HMSPipe implements PipeTransform {
  transform(duration: number): string {
    return HxToolsTime.millisecond2HMS(duration);
  }
}

@Pipe({ name: 'millisecond2HMSInt' })
export class Millisecond2HMSIntPipe implements PipeTransform {
  transform(duration: number): string {
    return HxToolsTime.millisecond2HMSInt(duration);
  }
}

@Pipe({ name: 'timeInterval' })
export class TimeIntervalPipe implements PipeTransform {
  transform(time: number): string {
    const today = new Date();
    const timestamp = 1000 * Math.round(time / 256);
    const duration = moment.duration(- timestamp + today.getTime(), 'milliseconds');
    let txt = "";
    let d = duration.days();
    let m = duration.months();
    let y = duration.years();
    if (y > 0) {
      txt += y + " year";
      if (y > 1) {
        txt += "s";
      }
      txt += " ";
    }
    if (m > 0) {
      if (y > 0) { txt += " and "; }
      txt += m + " month";
      if (m > 1) {
        txt += "s";
      }
      txt += " ";
    }
    if (d > 0) {
      if (y > 0 || m > 0) { txt += " and "; }
      txt += d + " day";
      if (d > 1) {
        txt += "s";
      }
      txt += " ";
    }
    return txt;
    //   return duration.days() + " days";
    // return moment.duration(timestamp - today.getTime(), 'milliseconds').humanize();
  }
}

@Pipe({ name: 'dbTimeToDate' })
export class DbTimeToDatePipe implements PipeTransform {
  transform(time: number): string {
    if (!time) return undefined;
    let timestamp = Math.round(time / 256);
    let start = moment.unix(timestamp);
    return start.format("dddd, MMMM Do YYYY");
  }
}

@Pipe({ name: 'dbTimeToShortDate' })
export class DbTimeToShortDatePipe implements PipeTransform {
  transform(time: number): string {
    if (!time) return undefined;
    let timestamp = Math.round(time / 256);
    let start = moment.unix(timestamp);
    return start.format("ll");
  }
}


@Pipe({ name: 'dbTimeToDateTime' })
export class DbTimeToDateTimePipe implements PipeTransform {
  transform(time: number): string {
    if (!time) return undefined;
    let timestamp = Math.round(time / 256);
    let start = moment.unix(timestamp);
    return start.format("YYYY/MM/DD HH:mm:ss");
  }
}

@Pipe({ name: 'activityName' })
export class ActivityNamePipe implements PipeTransform {
  transform(activity: string, activities: Array<any>): string {
    let parts;
    if (activity !== undefined && activity !== null) {
      if (activity.indexOf('/') > -1) {
        parts = activity.split('/');
      }
      if (parts.length > 2) {
        let id = parts[3];
        let _activity = activities.filter(activitiy => activitiy.id === id);
        if (_activity.length > 0) {
          return _activity[0].name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "other";
    }
  }
}

@Pipe({ name: 'dbRecordToMetric' })
export class DbRecordToMetricPipe implements PipeTransform {
  transform(record: any, metric_id: number, unitStr?: string): string {
    if (!record.metrics) return "-";
    for (let i = 0; i < record.metrics.length; i++) {
      if (record.metrics[i].id === metric_id) {
        if (!(record.metrics[i])) return "-";
        if (!(record.metrics[i].value)) return "-";
        let unit = record.metrics[i].unit;
        //let unit_si = unit.si_short;
        let value: string = Math.round(record.metrics[i].value) + "";
        if ((unit.si_short === "m") && (record.metrics[i].value >= 1000)) {
          value = (Math.round(record.metrics[i].value / 10) / 100) + "";
          //unit_si = "km";
        } else if (unit.si_short === "s") {
          let duration = moment.duration(record.metrics[i].value, 'seconds');
          moment.relativeTimeThreshold('ss', 3);
          moment.relativeTimeThreshold('s', 59);
          moment.relativeTimeThreshold('m', 59);
          moment.relativeTimeThreshold('h', 24);
          value = duration.humanize();
          value = value.replace(/minutes/gi, "m");
          value = value.replace(/hours/gi, "h");
          value = value.replace(/an hour/gi, "1 h");
          value = value.replace(/secondes/gi, "s");
        }
        if (!unitStr) {
          unitStr = "";
        }
        return value + " " + unitStr;
      }
    }
    return "-";
  }
}
