import { AbstractControl, ValidatorFn } from '@angular/forms';

export const equalTo = (equalControl: AbstractControl): ValidatorFn => {
  let subscribe = false;

  return (control: AbstractControl): {[key: string]: boolean} => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    const v = control.value;

    return equalControl.value === v ? null : {equalTo: true};
  };
};

/*
export const matchingPasswords = (passwordKey: string, confirmPasswordKey: string) : ValidatorFn => {
  return (group: FormGroup): { [key: string]: any } => {
    let password = group.controls[passwordKey];
    let confirmPassword = group.controls[confirmPasswordKey];

    if (password.value !== confirmPassword.value) {
      return {
        mismatchedPasswords: true
      };
    }
  }
}
*/