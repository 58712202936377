/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-week-view-event.component.ngfactory";
import * as i2 from "./calendar-week-view-event.component";
import * as i3 from "@angular/common";
import * as i4 from "./calendar-week-view-header.component.ngfactory";
import * as i5 from "./calendar-week-view-header.component";
import * as i6 from "./calendar-week-view.component";
import * as i7 from "../../providers/calendar-utils.provider";
var styles_CalendarWeekViewComponent = [];
var RenderType_CalendarWeekViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarWeekViewComponent, data: {} });
export { RenderType_CalendarWeekViewComponent as RenderType_CalendarWeekViewComponent };
function View_CalendarWeekViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["event", 1]], null, 2, "div", [["class", "cal-event-container"]], [[2, "cal-draggable", null], [4, "width", null], [4, "marginLeft", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-calendar-week-view-event", [], null, [[null, "eventClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClicked" === en)) {
        var pd_0 = (_co.eventClicked.emit({ event: _v.context.$implicit.event }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CalendarWeekViewEventComponent_0, i1.RenderType_CalendarWeekViewEventComponent)), i0.ɵdid(2, 49152, null, 0, i2.CalendarWeekViewEventComponent, [], { weekEvent: [0, "weekEvent"], tooltipPlacement: [1, "tooltipPlacement"], tooltipAppendToBody: [2, "tooltipAppendToBody"], customTemplate: [3, "customTemplate"], tooltipTemplate: [4, "tooltipTemplate"] }, { eventClicked: "eventClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.tooltipPlacement; var currVal_5 = _co.tooltipAppendToBody; var currVal_6 = _co.eventTemplate; var currVal_7 = _co.tooltipTemplate; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.event.draggable; var currVal_1 = (((100 / _co.days.length) * _v.context.$implicit.span) + "%"); var currVal_2 = (((100 / _co.days.length) * _v.context.$implicit.offset) + "%"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CalendarWeekViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["eventRowContainer", 1]], null, 2, "div", [["class", "cal-events-row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarWeekViewComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.row; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CalendarWeekViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["weekViewContainer", 1]], null, 4, "div", [["class", "cal-week-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-calendar-week-view-header", [], null, [[null, "dayHeaderClicked"], [null, "eventDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dayHeaderClicked" === en)) {
        var pd_0 = (_co.dayHeaderClicked.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventDropped" === en)) {
        var pd_1 = (_co.eventTimesChanged.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_CalendarWeekViewHeaderComponent_0, i4.RenderType_CalendarWeekViewHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i5.CalendarWeekViewHeaderComponent, [], { days: [0, "days"], locale: [1, "locale"], customTemplate: [2, "customTemplate"] }, { dayHeaderClicked: "dayHeaderClicked", eventDropped: "eventDropped" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarWeekViewComponent_1)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; var currVal_1 = _co.locale; var currVal_2 = _co.headerTemplate; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.eventRows; _ck(_v, 4, 0, currVal_3); }, null); }
export function View_CalendarWeekViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-week-view", [], null, null, null, View_CalendarWeekViewComponent_0, RenderType_CalendarWeekViewComponent)), i0.ɵdid(1, 770048, null, 0, i6.CalendarWeekViewComponent, [i0.ChangeDetectorRef, i7.CalendarUtils, i0.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarWeekViewComponentNgFactory = i0.ɵccf("app-calendar-week-view", i6.CalendarWeekViewComponent, View_CalendarWeekViewComponent_Host_0, { viewDate: "viewDate", events: "events", excludeDays: "excludeDays", refresh: "refresh", locale: "locale", tooltipPlacement: "tooltipPlacement", tooltipTemplate: "tooltipTemplate", tooltipAppendToBody: "tooltipAppendToBody", weekStartsOn: "weekStartsOn", headerTemplate: "headerTemplate", eventTemplate: "eventTemplate", precision: "precision", weekendDays: "weekendDays" }, { dayHeaderClicked: "dayHeaderClicked", eventClicked: "eventClicked", eventTimesChanged: "eventTimesChanged", beforeViewRender: "beforeViewRender" }, []);
export { CalendarWeekViewComponentNgFactory as CalendarWeekViewComponentNgFactory };
