import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalendarEvent, CalendarMonthViewDay } from '../../shared/components/calendar';
import { ViewEncapsulation } from '@angular/core';
import { ApiQuery, ApiService, RecordService } from '../../core/services';
import { RangeService } from '../../core/services/range.service';
import * as moment from 'moment/moment';
import { HxToolsDate } from '../../shared/hx-tools/hx-tools-date';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
import { Router } from '@angular/router';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.scss', '../../../assets/stylesheets/core/color.scss', '../../../assets/stylesheets/core/form.scss', '../../../assets/stylesheets/app.scss', '../../../assets/stylesheets/core/icon.scss', '../../../assets/stylesheets/core/panel.scss', '../../../assets/stylesheets/core/metric.scss', '../../../assets/stylesheets/core/section.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarsComponent extends HelperComponent implements OnInit, OnDestroy {

  private events: Array<CalendarEvent>;
  private records: Array<Array<any>>;
  private activities: Array<any>;
  private nrecords: Array<Array<any>>;
  private ranges: Array<Array<any>> = [];
  private nranges: Array<Array<any>> = [];
  public dates: Array<any>;
  public ndates: Array<any>;
  public userId: number;
  public user: any;
  view: string = 'month';
  metrics: boolean = false;

  viewDate: Date = new Date();
  public loading = true;

  displayRecords = true;
  displayRanges = true;

  constructor(private recordService: RecordService,
    private rangeService: RangeService,
    private apiService: ApiService,
    private globalVariables: GlobalVariables,
    private router: Router,
    public helpService: HelpService,
    private eventAggregator: EventAggregator) {
    super(helpService);
    this.events = new Array<CalendarEvent>();
  }

  private offset = 0;

  ngOnInit(): void {
    this._getAll();
    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);

    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.FSS__UPDATE
      }));
  }

  ngOnDestroy() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
    this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
  }

  private onMessageReceived = (payload: MessageSentEventPayload) => {
    if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
      this._getAll();
    }
  }

  /**
   * Retrieve all the records the logged user is allowed to see
   */
  _getAll() {
    this.loading = true;
    this.user = this.globalVariables.get('currentSubject');
    // If the current subject is not defined, the subject is the logged user
    if (this.user === undefined) {
      this.user = this.globalVariables.get('currentUser');
    }

    this.userId = this.user.id;
    this.events = new Array<CalendarEvent>(); // ??

    let end2 = 0;
    let start2 = 999999999999999999;

    this.apiService.getQuery(ApiQuery.all_activitiytype).flatMap(
      data => {
        this.activities = new Array<any>();
        for (let activity of data['objects']) {
          this.activities[activity.resource_uri] = activity.name;
        }
        return this.recordService.fetchNLast(100, this.offset, this.userId);
      }
    ).flatMap(
      data => {
        if (data.objects) {
          this.records = new Array<any>();
          this.nrecords = new Array<any>();
          this.dates = new Array<any>();
          this.ndates = new Array<any>();

          // Loop records
          for (let record of data.objects) {

            let timestamp = Math.round(record.start / 256);
            let _start_day = HxToolsDate.getStartOfDay(timestamp);
            let start = moment.unix(timestamp); // Unix time start

            let endTimestamp = Math.round(record.end / 256);
            let _end_day = HxToolsDate.getEndOfDay(timestamp);
            let end = moment.unix(endTimestamp); // Unix time start

            let _start_record_in_hour = start.hour() + Number(start.minutes()) / 60;
            let _end_record_in_hour = end.hour() + Number(end.minutes()) / 60;


            // overnight record
            if (HxToolsTime.isOverNight(start.unix(), end.unix())) {

              if (_end_day > end2) end2 = _end_day;
              if (_start_day < start2) start2 = _start_day;
              let date = start.format("dddd, MMMM Do YYYY");

              if (!this.nrecords[date]) {
                this.nrecords[date] = new Array<any>();
                this.nranges[date] = new Array<any>();
                this.ndates.push({
                  "date": date,
                  "startOfDay": _start_day,
                  "endOfDay": _end_day
                });
              }
              let _record_duration = _end_record_in_hour + 24 - _start_record_in_hour;
              
              this.nrecords[date].push({
                  "date": date,
                  "startOfDay": _start_day,
                  "endOfDay": _end_day,
                  "start": _start_record_in_hour,
                  "end": _start_record_in_hour + _record_duration,
                  "duration": _record_duration,
                  "recordId": record.id
                });

            // day record
            } else {

              if (_end_day > end2) end2 = _end_day;
              if (_start_day < start2) start2 = _start_day;
              let date = start.format("dddd, MMMM Do YYYY");

              if (!this.records[date]) {
                this.records[date] = new Array<any>();
                this.dates.push({
                  "date": date,
                  "startOfDay": _start_day,
                  "endOfDay": _end_day
                });
              }

              this.records[date].push({
                  "date": date,
                  "startOfDay": _start_day,
                  "endOfDay": _end_day,
                  "start": _start_record_in_hour,
                  "end": _end_record_in_hour,
                  "duration": _end_record_in_hour - _start_record_in_hour,
                  "recordId": record.id
                });
            }

            const durationRecord = moment.duration(end.unix() - start.unix(), 'seconds');
            let event = {
              start: start.toDate(),
              start_unix: start.unix(),
              start_time: start.hour() + ":" + (start.minutes() < 10 ? "0" + start.minutes() : start.minutes()),
              meta: { type: 'record' },
              duration_hh_mm: durationRecord.hours() + ":" + (durationRecord.minutes() < 10 ? "0" + durationRecord.minutes() : durationRecord.minutes()),
              id: record.id,
            };
            this.events.push(event);


          } // End - Loop records
        }

        return this.rangeService.fetchPeriod(256 * end2, 256 * start2, null, null, this.userId);
      }).subscribe(data => {

        for (let range of data.objects) {
          let timestamp = Math.round(range.start / 256);
          let _start_day = moment(timestamp, "X").startOf('day').unix();
          let start = moment.unix(timestamp); // Unix time start
          let date = start.format("dddd, MMMM Do YYYY");

          timestamp = Math.round(range.end / 256);
          let _end_day = moment(timestamp, "X").endOf('day').unix();
          let end = moment.unix(timestamp); // Unix time start

          if (HxToolsTime.isOverNight(start.unix(), end.unix())) {

            if (!this.nranges[date]) {
              this.nranges[date] = new Array<any>();
            }
            const durationRange = (end.hour() + Number(end.minutes()) / 60) + 24 - (start.hour() + Number(start.minutes()) / 60);
            this.nranges[date].push(
              {
                "date": date,
                "startOfDay": _start_day,
                "endOfDay": _end_day,
                "start": start.hour() + Number(start.minutes()) / 60,
                "end": start.hour() + Number(end.minutes()) / 60 + durationRange,
                "rangeId": range.id
              }
            );

          } else {

            if (!this.ranges[date]) {
              this.ranges[date] = new Array<any>();
            }
            this.ranges[date].push(
              {
                "date": date,
                "startOfDay": _start_day,
                "endOfDay": _end_day,
                "start": start.hour() + Number(start.minutes()) / 60,
                "end": end.hour() + Number(end.minutes()) / 60,
                "rangeId": range.id
              }
            );
          }

          const duration = moment.duration(end.unix() - start.unix(), 'seconds');
          let event = {
            start: start.toDate(),
            start_unix: start.unix(),
            _start: "" + Math.round(100 * (start.hour() + Number(start.minutes()) / 60) / 24),
            meta: { type: 'range' },
            start_time: start.hour() + ":" + (start.minutes() < 10 ? "0" + start.minutes() : start.minutes()),
            icon: this.activities[range.context.activitytype],
            id: range.id,
            duration_hh_mm: duration.hours() + ":" + (duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes()),
          };
          this.events.push(event);

        }

        this.afterViewInit();
        this.loading = false;
      });
  }

  onClickItem(id, type) {
    let link;
    if (type === "record") {
      link = ['/record-view/' + id];
      this.router.navigate(link);
    } else if (type === "range") {
      link = ['/range-view/' + id];
      this.router.navigate(link);
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(cell => {
      const groups: any = {};
      cell['energy'] = '-';
      cell['steps'] = '-';
      cell['avg_heartrate'] = '-';
      cell['totalTime'] = '-';
      cell.events.sort(function (a, b) { return (a.start_unix > b.start_unix) ? 1 : ((b.start_unix > a.start_unix) ? -1 : 0); });
      if (cell.events.length > 0) {
        const start = 256 * moment(cell.date).unix();
        const end = start + 24 * 60 * 60 * 256;
        this.apiService.getQuery(ApiQuery.metrics_for_user_id__start__end,
          {
            metric_ids: "149,71,44,22",
            start: start,
            end: end,
            user_id: this.userId
          })
          .subscribe(
          data => {
            cell['energy'] = Math.round(data.metrics[0].value);
            cell['steps'] = Math.round(data.metrics[1].value);
            cell['avg_heartrate'] = Math.round(data.metrics[2].value);
            cell['totalTime'] = HxToolsTime.second2HMInt(data.metrics[3].value);
          });
      }
      cell.events.forEach((event: CalendarEvent<{ type: string }>) => {
        groups['all'] = groups['all'] || [];
        groups['all'].push(event);
        groups[event.meta.type] = groups[event.meta.type] || [];
        groups[event.meta.type].push(event);
      });
      cell['eventGroups'] = new Array<any>();
      cell['eventGroups'] = groups;
    });

  }

}
