<div #elem>

  <section name="timeline" *ngIf="displayView && displayView['timeline']" [ngClass]="{ 'active' : boxes[0] && boxes[0].displayed, 'inactive' : boxes[0] && !boxes[0].displayed }">
    <div class="title-wrapper">
      <div class="title hx-g">
        <div class="hx-u-10-24">
          <div class="" style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;"><i class="hx-icon hx-icon-calendar"></i> {{ record?.start | dbTimeToDate }}</div>
          <div class="" style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;"><i class="hx-icon hx-icon-clock"></i> {{ record?.duration }}</div>
        </div>
        <div class="hx-u-4-24">
        </div>
        <div class="hx-u-6-24">
          <div class="hx-activity">
            <div style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;">
              {{ user }}
            </div>
          </div>
        </div>
        <div class="hx-u-4-24 ">
          <div class="pull-right" style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;">
            <div *ngIf="previousRecordId" class="tip button"><i class="tip hx-icon hx-icon-chevron-left clickable" (click)="previousRecord()"></i><span class="tip">Previous record</span></div>
            <div *ngIf="nextRecordId" class="tip button"><i class="hx-icon hx-icon-chevron-right clickable" (click)="nextRecord()"></i>
              <span>Next record</span></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="empty_box" style="background-color: white; padding:10px; height:119px">
      <img class="spinner" src="assets/images/spinner.gif">
    </div>
    <div *ngIf="!loading" style="background-color: white;padding:10px;">
      <app-timeline [records]="records" [salt]="salt" [time]="time" [startHour]="startHour" [endHour]="endHour" [ranges]="rangesOfDay"></app-timeline>
    </div>
  </section>

  <section name="metrics" *ngIf="displayView && displayView['metrics'] && metrics" [ngClass]="{ 'active' : boxes[1] && boxes[1].displayed, 'inactive' : boxes[1] && !boxes[1].displayed }"
    class="metrics">
    <metrics-panel [metricIds]="metrics" [id]="recordId" [metricValues]="metricValues" type="record" [start]="" [end]=""></metrics-panel>
    </section>

    <div *ngIf="downloadInProgress" class="download-in-progress">Download in progress...</div>

    <section id="charts" name="charts" *ngIf="displayView && displayView['charts']" [ngClass]="{ 'active' : boxes[2] && boxes[2].displayed, 'inactive' : boxes[2] && !boxes[2].displayed }">
      <div class="title-wrapper hx-g">
        <div class="hx-u-23-24">
          <div class="title">
            Charts
          </div>
        </div>
        <div class="hx-u-1-24">
          <img (click)="openModal('charts')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
        </div>
      </div>
      <div>
        <div *ngIf="loading" class="panel-box-body" style="height: 414px;">
        </div>
        <div *ngIf="!loading" class="panel-box-body" [class.margin-top]="j > 0" style="min-height: 300px;">
          <div class="command">
            <div class="hx-g">
              <div class="hx-u-lg-1-2 hx-u-md-1-1 hx-u-sm-1-1">
                <div id="chartsbtn" #chartsbtn class="select-chart">
                  <ul>
                    <li><a [ngClass]="{ 'active ' : displayProcessedData }" (click)="displayProcessedData = true; displayRawData = false">Processed Data</a></li>
                    <li><a [ngClass]="{ 'active ' : displayRawData }" (click)="displayProcessedData = false; displayRawData = true">Raw Data</a></li>
                    <li><img (click)="openModalChartsBtn()" width="20px" style="cursor: pointer; margin-top: 20px;" src="/assets/icons/info.svg"></li>
                  </ul>
                </div>
              </div>
              <div class="hx-u-lg-1-2 hx-u-md-1-1 hx-u-sm-1-1">
                <div id="chartmenu">
                  <nav>
                    <ul>
                      <!--li><a><i class="hx-icon hx-icon hx-icon-info-circle hx-icon-button"></i></a></li-->
                      <li>
                        <div class="tip">
                          <i (click)="resetChart()" class="hx-icon hx-icon hx-icon-refresh hx-icon-button"></i><span>Reset</span></div>
                      </li>
                      <li *ngIf="!displayNavigatorProcessedData">
                        <div class="tip">
                          <a (click)="displayNavigator()"><i class="hx-icon hx-icon hx-icon-search hx-icon-button"></i></a><span>Display navigator</span></div>
                      </li>
                      <li *ngIf="displayNavigatorProcessedData">
                        <div class="tip">
                          <a (click)="hideNavigator()" class="active"><i class="hx-icon hx-icon hx-icon-search hx-icon-button"></i></a><span>Hide navigator</span></div>
                      </li>
                      <li *ngIf="displayedForms['ADD_RANGE'] === true || displayedForms['EDIT_RANGE'] === true">
                        <div class="tip"><a (click)="hideForm('ADD_RANGE')" class="active"><i class="hx-icon hx-icon-plus hx-icon-button"></i></a><span>Display activity form</span></div>
                      </li>
                      <li *ngIf="displayedForms['ADD_RANGE'] === false && displayedForms['EDIT_RANGE'] === false">
                        <div class="tip"><a (click)="displayForm('ADD_RANGE')"><i class="hx-icon hx-icon-plus hx-icon-button"></i></a><span>Hide activity form</span></div>
                      </li>
                      <li *ngIf="displayedForms['ANNOTATION'] === true">
                        <div class="tip"><a (click)="hideForm('ANNOTATION')" class="active"><i class="hx-icon hx-icon hx-icon-flag hx-icon-button"></i></a><span>Display annotation form</span></div>
                      </li>
                      <li *ngIf="displayedForms['ANNOTATION'] === false">
                        <div class="tip"><a (click)="displayForm('ANNOTATION')"><i class="hx-icon hx-icon hx-icon-flag hx-icon-button"></i></a><span>Hide annotation form</span></div>
                      </li>
                      <li>
                        <div class="tip"><i class="hx-icon hx-icon hx-icon-download hx-icon-button" (click)="downloadClicked = !downloadClicked"></i><span>Click on the Data Format to start the Download</span>
                        </div>
                        <ul [ngClass]="{ 'clicked' : downloadClicked === true } ">
                          <li><a (click)="exportData('csv', 'record')">Download CSV file</a></li>
                          <li><a (click)="exportData('zip', 'record')">Download Binary file</a></li>
                          <li><a (click)="exportData('edf', 'record')">Download EDF file</a></li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="displayedForms['ANNOTATION']">
            <record-annotation (action)="performAction($event)" [user]="userId" [record_id]="recordId" [id]="editableAnnotation.id" [start]="editableAnnotation.start"
              [annotation]="editableAnnotation.annotation"></record-annotation>
          </div>

          <div *ngIf="displayedForms['EDIT_RANGE']">
            <record-range [user]="userId" [activityTypes]="activityTypes" [selectedActivityType]="editableRange.activitytype" (growlMessageLabel)="growlMessage($event)"
              [name]="editableRange.name" (action)="performAction($event)" [id]="editableRange.id" [from]="editableRange.start"
              [to]="editableRange.end" mode="edit"></record-range>
          </div>

          <div *ngIf="displayedForms['ADD_RANGE']">
            <record-range [user]="userId" [activityTypes]="activityTypes" [selectedActivityType]="editableRange.activitytype" (growlMessageLabel)="growlMessage($event)"
              [name]="editableRange.name" (action)="performAction($event)" [id]="editableRange.id" [from]="editableRange.start"
              [to]="editableRange.end" mode="add"></record-range>
          </div>

          <app-highchart *ngIf="displayProcessedData" [activityTypes]="activityTypes" type="record" [id]="1" (action)="performAction($event)"
            (annotationEvent)="onChartAnnotation($event)" (rangeEvent)="onChartRange($event)" [state]="state" [record_id]="recordId"
            [user]="record.user.id" [curves]="curves" [salt]="salt" [ids]="curvesQueue" [displayNavigator]="displayNavigatorProcessedData">
            </app-highchart>

            <app-highchart *ngIf="displayRawData" [activityTypes]="activityTypes" type="record" [id]="1" (action)="performAction($event)" (annotationEvent)="onChartAnnotation($event)"
              (rangeEvent)="onChartRange($event)" (evRangeSelected)="onChartRange($event)" [state]="state" [record_id]="recordId"
              [user]="record.user.id" [curves]="curvesRaw" [salt]="salt" [ids]="curvesQueue" [displayNavigator]="displayNavigatorRawData">
              </app-highchart>

              <div class="command">
                <div class="hx-g">
                  <div class="hx-u-lg-1-1 hx-u-md-1-1 hx-u-sm-1-1">
                    <div *ngIf="displayProcessedData" class="select-chart">
                      <ul>
                        <li *ngFor="let chart of charts; let i = index">
                          <a [ngClass]="{ 'active ' : ( curvesQueue.indexOf(i) > -1 ), 'grayed' : waiting }" (click)="!waiting && toggleCurve(i, curvesQueue.indexOf(i) > -1)">{{ chart.label }}</a>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="displayRawData" class="select-chart">
                      <ul>
                        <li *ngFor="let chart of rawCharts; let i = index">
                          <a [ngClass]="{ 'active ' : ( curvesRawQueue.indexOf(i) > -1 ), 'grayed' : waiting }" (click)="!waiting && toggleCurveRaw(i, curvesRawQueue.indexOf(i) > -1 )">{{ chart.label }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </section>


    <section name="activities" *ngIf="!loading && subRanges && displayView && displayView['activities'] && record" class="metrics"
      [ngClass]="{ 'active' : boxes[3] && boxes[3].displayed, 'inactive' : boxes[3] && !boxes[3].displayed }">
      <div class="title-wrapper">
        <div class="title hx-g">
          <div class="hx-u-23-24">
            Activities
          </div>
          <div class="hx-u-1-24">
            <div class="occurence tip">
              {{ subRanges.length }}
              <span>Nbr&nbsp;of&nbsp;subactivities</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table class="hx-table hx-table-ranges">
          <thead *ngIf="subRanges.length > 1">
            <tr>
              <th>Name</th>
              <th>Duration</th>
              <th>Activity type</th>
              <th>HR avg.</th>
              <th>BR avg.</th>
              <th>Energy</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let subrange of subRanges">
              <tr *ngIf="subrange.id != rangeId" (mouseenter)="highlightRange(subrange) " (mouseleave)="dishighlightRange()">
                <td>
                  {{ subrange.name }}
                </td>
                <td>{{ subrange.duration }}</td>
                <td><i class="hx-icon hx-icon {{ subrange.activity | activityName:activityTypes | activityTypeToIconClass }}"></i>                  {{ subrange.activity | activityName:activityTypes }}</td>
                <td> {{ subrange | dbRecordToMetric:44:'bpm' }}</td>
                <td>{{ subrange | dbRecordToMetric:12:'rpm' }}</td>
                <td> {{ subrange | dbRecordToMetric:149:'Cal' }}</td>
                <td>
                  <div class="tip"><button class="button button-green" type="button" (click)="redirectToRange(subrange.id)"><i class="hx-icon hx-icon-eye"></i></button><span>Activity details</span></div>
                  <!--div class="tip"><button class="button button-green" type="button" (click)="editRange(subrange)"><i class="hx-icon hx-icon-edit"></i></button><span>Edit activity</span></div-->
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      </section>


      <section name="annotations" *ngIf="!loading && displayView && displayView['annotations'] && record" class="metrics" [ngClass]="{ 'active' : boxes[4] && boxes[4].displayed, 'inactive' : boxes[4] && !boxes[4].displayed }">
        <div class="title-wrapper">
          <div class="title hx-g">
            <div class="hx-u-23-24">
              Annotations
            </div>
            <div class="hx-u-1-24">
              <div class="occurence tip">
                {{ annotations.length }}
                <span>Nbr&nbsp;of&nbsp;annotations</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table class="hx-table hx-table-ranges">
            <thead *ngIf="annotations.length > 0">
              <tr>
                <th>Name</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let annotation of annotations">
                <tr (mouseenter)="highlightAnnotation(annotation)" (mouseleave)="dishighlightAnnotation()">
                  <td>{{ annotation.annotation }}</td>
                  <td>{{ annotation.start | dateHumanize }}</td>
                  <td>
                    <!--div class="tip"><button class="button button-green" type="button" (click)="editAnnotation(subrange)"><i class="hx-icon hx-icon-edit"></i></button><span>Edit annotation</span></div-->
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </section>

      <!--section name="map" *ngIf="displayView && displayView['map']" [ngClass]="{ 'active' : boxes[3] && boxes[3].displayed, 'inactive' : boxes[3] && !boxes[3].displayed }"
      class="metrics">
      <div class="title-wrapper">
        <div class="title">
          Map
        </div>
      </div>
      <app-map *ngIf="gpsPositions.length > 1" [gpsPositions]="gpsPositions"></app-map>
      <div *ngIf="gpsPositions.length === 0" class="panel-box-body">
        <div class="missing-info">
          No GPS info available
        </div>
      </div>
      </section-->

      <!--section name="altitude" *ngIf="!loading && displayView['altitude']" [ngClass]="{ 'active' : boxes[4] && boxes[4].displayed, 'inactive' : boxes[4] && !boxes[4].displayed }"
        class="metrics">
        <div class="title-wrapper">
          <div class="title">
            Altitude
          </div>
        </div>
        <div *ngIf="!loading">
          <app-bchart *ngIf="gpsPositions.length > 1" [values]="altitudes" type="spline" unit="meters"></app-bchart>
          <div *ngIf="gpsPositions.length === 0" class="panel-box-body">
            <div class="missing-info">
              No altitude info available
            </div>
          </div>
        </div>
        </section-->

</div>

<app-growl [value]="msgs"></app-growl>


<help-modal id="charts-help" style="display: none;">
  <p>
    <img height="24" src="/assets/icons/zoom.svg">: When selected, it allows you to click and drag on the graph to select
    the time period that you wish to zoom in. Click again on the <img height="24" src="/assets/icons/zoom.svg"> to reinitialize
    the view.<br>
    <img height="24" src="/assets/icons/add.svg">: This allows you to tag an activity within a record. Define the time interval
    for your activity by clicking and dragging on the graph to select the time period that you wish to include in your new
    activity. You can add a note to this activity and select an activity type with the drop-down list. Click on "Add” to
    save your new activity.<br>
    <img height="24" src="/assets/icons/flag.svg">: This allows you to add annotations to your record. Click on the specific
    time point on the graph where you want to add an annotation <img height="24" src="/assets/icons/flag.svg">, then add
    a note. Click on "Add” to save your annotation.<br>
    <img height="24" src="/assets/icons/download_a.svg">: This allows you to import your data in csv, binary or edf format.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>


<help-modal id="chartsbtn-help" style="display: none;">
  <p>The view will be adjusted according to the type of data selected (processed data or raw data). The chart legend can be
    used to activate or deactivate the view of your choice of metrics to be displayed. For example, you can click on "Raw
    Resp.” and deactivate "Raw Resp. (abdominal)” to have a better view of the thoracic respiration curve or vice versa.
    You can do this also for the "Acceleration”, by deactivating "Acceleration -X, -Y or -Z”.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>