import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'metric-box-two',
    templateUrl: './metric-box-two.component.html',
    styleUrls: ['./metric-box-two.component.scss']
})
export class MetricBoxTwoComponent implements OnInit {

    @Input()
    metricIds: Array<number>;

    @Input()
    results: Array<any>;

    @Input()
    offset: number;

    @Input()
    type: string;

    @Input()
    length: number;

    //////////
    @Input()
    title: string;

    @Input()
    precision = 1;

    @Input()
    unity: string;

    @Input()
    icon: string;

    loading = false;
    avg = "-";
    max = "-";
    min = "-";

    constructor() {

    }

    ngOnChanges() {
        this.init();
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.loading = true;
        this.precision = 1;

        //    this.title = "test";
        //   this.icon = "heart-rate";
        //   this.unity = "(bpm)";
        /* for (let result of this.results) {
             if (result.)
         }*/

        let results = this.results.slice(this.offset, this.offset + this.length);

        this.unity = results[0] ? results[0].unit.si_short
            : (results[1] ? results[1].unit.si_short
                : (results[2] ? results[2].unit.si_short : "?"));

        if (results[0] && results[0].value < 10) {
            this.precision = 100;
        } else if (results[1] && results[1].value < 10) {
            this.precision = 100;
        } else if (results[2] && results[2].value < 10) {
            this.precision = 100;
        }

        this.avg = results[0] ? results[0].value ? (Math.round(this.precision * results[0].value) / this.precision).toString() : "-" : "-";
        this.max = results[1] ? results[1].value ? (Math.round(this.precision * results[1].value) / this.precision).toString() : "-" : "-";
        this.min = results[2] ? results[2].value ? (Math.round(this.precision * results[2].value) / this.precision).toString() : "-" : "-";

        //this.title = results[0].title.replace("Avg.", "").replace("Avg", "");
        this.icon = "";


        if (this.unity === "bpm") {
            this.icon = "heart-rate";
        } else if (this.unity === "L/min") {
            this.icon = "minute-ventilation";
        } else if (this.unity === "rpm") {
            this.icon = "breathing-rate";
        } else if (this.unity === "spm") {
            this.icon = "cadence";
        } else if (this.unity === "L") {
            this.icon = "breathing-volume";
        } else if (this.unity === "g") {
            this.icon = "activity-level";
        } else if (this.unity === "m") {
            this.icon = "distance";
        } else if (this.unity === "Cal") {
            this.icon = "energy";
        }

    }

}