import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService, ApiQuery, DataService } from '../../core/services';
import { User, UserInfo } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import * as moment from 'moment/moment';
import { AlertService } from '../../shared/components/alert/alert.service';
import { lessThan, greaterThan, isNumber } from '../../shared/validators/number.validator';
import { dateBefore, dateAfter } from '../../shared/validators/date.validator';
import { Message } from '../../shared/components/growl/message';

function compare_start_date(a, b) {
    if (a.start < b.start)
        return 1;
    if (a.start > b.start)
        return -1;
    return 0;
}

const minimum_age = 5
const maximum_age = 120

@Component({
    selector: 'user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['../../../assets/stylesheets/button.scss']
})
export class UserInfoComponent extends UserForm implements OnInit {

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    user: User;
    profile: any;
    active = true;
    success = false;
    system = 'metric';
    units = {
        'weight': {
            'metric': 'kg',
            'imperial': 'lbs'
        },
        'height': {
            'metric': 'cm',
            'imperial': ''
        }
    };
    heights = {};
    weights = {};
    preferences = {};



    @Input() profile_id = null;

    @Output() valueChange = new EventEmitter();

    /**
     * Error messages
     */
    formErrors = {
        'weight': '',
        'height': '',
        'gender': '',
        'system': '',
        'date_of_birth': '',
    };

    validationMessages = {
        'weight': {
            'required': 'Weight is required.'
        },
        'height': {
            'required': 'Height is required.'
        },
        'date_of_birth': {
            'required': 'Date of birth is required.',
            'dateBefore': 'Date of birthday should be before ' +
                moment().subtract(minimum_age, 'years').format('YYYY-MM-DD'),
            'dateAfter': 'Date of birthday should be after ' +
                moment().subtract(maximum_age, 'years').format('YYYY-MM-DD')
        }

    };

    /**
     * CONSTRUCTOR
     */
    constructor(fb: FormBuilder,
        private apiService: ApiService,
        private dataService: DataService,
        private alertService: AlertService) {
        super(fb);
        this.user = new User();
        this.system = 'metric';

    }


    convert = {
        height: {
            imperial: (v) => {
                let feet = v * 0.3937 / 12;
                let inch = Math.round(feet % 1 * 120) / 10;
                return Math.floor(feet) + "' " + Math.floor(inch) + "\"";
            },
            metric: (v) => {
                let [f, i] = v.split(' ').map(parseFloat);
                return Math.round((f * 12 + i) / 0.3937) / 100;
            }
        },
        weight: {
            imperial: v => Math.round(v * 2.2046),
            metric: v => Math.round(v / 0.022046) / 100
        }
    };


    /**
     * Building the form on init
     */
    ngOnInit(): void {

        
        this.apiService.getFromUri('api/profile/' + this.profile_id + '/').flatMap(data => {
            this.user.date_of_birth = data.date_of_birth;
            this.user.gender = data.gender;
            return this.apiService.getKPI("height", this.profile_id);
        }).flatMap(data => {
            data.objects.sort(compare_start_date); // we retrieve the last record
            this.user.height = "" + 100 * data.objects[0].value;
            return this.apiService.getPreferences(this.profile_id);
        }).flatMap(data => {
            this.preferences = data;
            if (data.unit_system !== undefined) {
                this.system = data.unit_system;
            } else {
                this.system = 'metric';
            }
            this.setMeasurementFields(this.system);
            return this.apiService.getKPI("weight", this.profile_id);
        }).subscribe(data => {
            data.objects.sort(compare_start_date);  // we retrieve the last record
            this.user.weight = Math.round(data.objects[0].value);
            this.buildForm();
        });
    }

    /**    
     * Actions performed when submit is clicked
     */
    onSubmit() {

        this.user = this.form.value;
        let userInfo = new UserInfo();

        userInfo.date_of_birth = this.user.date_of_birth;
        userInfo.gender = this.user.gender;

        this.apiService.putQuery(ApiQuery.save_profile, userInfo, { id: this.profile_id }).flatMap(() => {
            return this.apiService.saveKPI("height", Number(this.user.height) / 100, this.profile_id);
        }).flatMap(() => {
            return this.apiService.saveKPI("weight", Number(this.user.weight), this.profile_id);
        }).subscribe(() => {
            this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The info has been saved.' }];
            this.valueChange.emit(this.msgs);
        }, error => {
            this.alertService.error(error);
        });
    }

    /**
     * Building form function 
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    buildForm(): void {
        this.form = this.fb.group({
            'weight': [this.user.weight, [
                Validators.required
            ]
            ],
            'height': [this.user.height, [
                Validators.required
            ]
            ],
            'gender': [this.user.gender, []],
            'system': ['metric', []],
            'date_of_birth': [this.user.date_of_birth, [
                dateBefore(new Date(Date.now() + -(minimum_age * 365) * 24 * 3600 * 1000)),
                dateAfter(new Date(Date.now() + -(maximum_age * 365) * 24 * 3600 * 1000)) // 5 years ago + 3 days
            ]],
        });

        this.form.valueChanges
            .subscribe(() => {
                this.onValueChanged(/*data*/);
            });

        this.onValueChanged();
    }

    setMeasurementFields(system = 'metric') {

        this.preferences['unit_system'] = system;
        this.dataService.updatePreferences('/api/profile/' + this.profile_id + '/', this.preferences).subscribe(() => {
            if (system === 'metric') {
                this.heights = {};
                for (let height = 50; height <= 230; height++) {
                    this.heights[height] = height + ' cm';
                }
                this.weights = [];
                for (let weight = 25; weight <= 180; weight++) {
                    this.weights[weight] = weight + ' kg';
                }
            } else {
                this.heights = [];
                for (let height = 50; height <= 230; height++) {
                    this.heights[height] = this.convert.height.imperial(height) + " (" + height + " cm)";
                }
                this.weights = [];
                for (let weight = 25; weight <= 180; weight++) {
                    this.weights[weight] = this.convert.weight.imperial(weight) + ' lbs';
                }
            }
        });
    }

}

