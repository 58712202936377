// Import from other modules
import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Import from current module
import { ApiQuery, ApiService, CoachService } from '../../core/services';
import { AddCoachService } from './coach/add-coach.service';
import { AddFriendService } from './friend/add-friend.service';
import { AddUserService } from './user/add-user.service';
import { ConfirmationService } from '../../shared/components/confirmation';
import { Message } from '../../shared/components/growl/message';
import { AlertService } from '../../shared/components/alert/alert.service';
import { AddAthleteService } from './athlete/add-athlete.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'app-relationship-table',
    templateUrl: './relationship-table.component.html',
    styleUrls: ['./relationship-table.component.scss',
        '../../../assets/stylesheets/app.scss',
        '../../../assets/stylesheets/core/section.scss',
        '../../../assets/stylesheets/core/section.scss',
        '../../../assets/stylesheets/core/metric.scss',
        '../../../assets/stylesheets/core/icon.scss',
        '../../../assets/stylesheets/button.scss']
})
export class RelationshipTableComponent extends HelperComponent implements OnInit {


    coaches: Array<any>;
    athletes: Array<any>;
    friends: Array<any>;
    pendingRequests: Array<any>;

    loadingAthletes = true;
    loadingCoaches = true;
    loadingFriends = true;
    loadingPendingRequests = true;

    /**
 * Table parameters
 */
    public maxItemsPerPage = 20;
    public currentPage = 1;
    public offset = 0;
    public pages;
    public totalPages;

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    protected readonly notifier: NotifierService;

    /**
     * CONSTRUCTOR
     */
    public constructor(public coachService: CoachService,
        public addCoachService: AddCoachService,
        public notifierService: NotifierService,
        public globalVariables: GlobalVariables,
        public helpService: HelpService,
        public apiService: ApiService,
        public addFriendService: AddFriendService,
        public alertService: AlertService,
        public router: Router,
        public addAthleteService: AddAthleteService,
        public addUserService: AddUserService,
        public confirmationService: ConfirmationService) {
        super(helpService);
        this.notifier = notifierService;
        this.addCoachService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === "save") {
                    const userId = +this.globalVariables.get("currentUserId");
                    this.confirmationService.confirm({
                        message: 'Are you sure that you want to add this coach?',
                        header: 'Confirmation',
                        icon: 'fa fa-add',
                        rejectVisible: true,
                        acceptVisible: true,
                        accept: () => {
                            this.coachService.add(task.email, userId, "bundle_permission").flatMap(res => {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Confirmed',
                                    detail: task.email + ' has been added as your coach'
                                });
                                return this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId });
                                //return this.coachService.fetch(userId);
                            }).subscribe(data => {

                                this.coaches = data.objects;
                                this.loadingCoaches = false;
                            }, err => {
                                this.showError(err);
                            });
                        }
                    });
                }
            });

        this.addAthleteService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === "save") {
                    const userId = +this.globalVariables.get("currentUserId");
                    this.confirmationService.confirm({
                        message: 'Are you sure that you want to add this user?',
                        header: 'Confirmation',
                        icon: 'fa fa-add',
                        rejectVisible: true,
                        acceptVisible: true,
                        accept: () => {
                            this.apiService.addUser(task.email, task.first_name, task.last_name).subscribe(res => {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Confirmed',
                                    detail: 'An email has been sent to ' + task.email + '.'
                                });
                                this.retrieveUsers(userId);
                                this.pendingRequests = new Array<any>();
                                this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(data => {
                                    //this.coachService.fetchPending(userId).subscribe(data => {
                                    this.pendingRequests = data.objects;
                                });
                            },
                                err => {
                                    this.showError(err);
                                });
                        }
                    });
                }
            });

        this.addUserService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === "save") {
                    const userId = +this.globalVariables.get("currentUserId");
                    this.confirmationService.confirm({
                        message: 'Are you sure that you want to add this user?',
                        header: 'Confirmation',
                        icon: 'fa fa-add',
                        rejectVisible: true,
                        acceptVisible: true,
                        accept: () => {
                            this.apiService.addSubject(userId, task.email).subscribe(res => {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Confirmed',
                                    detail: 'An email has been sent to ' + task.email + '.'
                                });
                                this.retrieveUsers(userId);
                                this.pendingRequests = new Array<any>();
                                this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(data => {
                                    //this.coachService.fetchPending(userId).subscribe(data => {
                                    this.pendingRequests = data.objects;
                                });
                            },
                                err => {
                                    this.addAthlete(task.email);
                                    // this.showError(err); TODO : server must provide another error code than 404...
                                });
                        }
                    });
                }
            });

        this.addFriendService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === "save") {
                    const userId = +this.globalVariables.get("currentUserId");
                    this.confirmationService.confirm({
                        message: 'Are you sure that you want to add this friend?',
                        header: 'Confirmation',
                        icon: 'fa fa-add',
                        rejectVisible: true,
                        acceptVisible: true,
                        accept: () => {
                            const bundle = { 'user_allowed': task.email, 'user_target': userId, 'bundle': 2 };
                            this.apiService.postQuery(ApiQuery.add_friend, bundle, null, 'bundle_permission').flatMap(res => {
                                // this.friendService.add(task.email, userId, "bundle_permission").flatMap(res => {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Confirmed',
                                    detail: task.email + ' has been added as your friend'
                                });
                                return this.apiService.getQuery(ApiQuery.all_friends_for_user_id);
                                //return this.friendService.fetch(userId);
                            }).subscribe(data => {
                                this.friends = new Array<any>();
                                const userId = +this.globalVariables.get("currentUserId");
                                for (let friend of data.objects) {
                                    if (friend.user_allowed.id === userId) {
                                        this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                                    } else {
                                        this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                                    }
                                }
                                this.loadingCoaches = false;
                            }, err => {
                                this.showError(err);
                            });
                        }
                    });
                }
            });

    }

    public selection = "friends";

    private showError(err) {
        let message = "";
        if (err.error && err.error.errors) {
            for (let property in err.error.errors) {
                if ((err.error.errors.hasOwnProperty(property)) && (err.error.errors[property][0])) {
                    message += err.error.errors[property][0];
                }
            }
        }
        if (message !== "") {
            this.notifier.notify('error', message);
        }
    }

    selectMyFriends() {
        this.selection = "friends";
    }

    selectMyCoaches() {
        this.selection = "coaches";
    }

    selectMyAthletes() {
        this.selection = "athletes";
    }

    selectPendingRequests() {
        this.selection = "pendingRequests";
    }

    /**
 * Display the previous page
 */
    nextPage() {
        this.currentPage++;
        this.getAll();
    }

    /**
     * Display the next page
     */
    previousPage() {
        this.currentPage--;
        this.getAll();
    }

    /**
     * Display the page 
     */
    changeToPage(page) {
        this.currentPage = page;
        this.getAll();
    }

    /**
     * On init
     */
    public ngOnInit(): void {
        this.getAll();
    }

    public retrieveUsers(userId) {
        let offset = (this.currentPage - 1) * this.maxItemsPerPage;
        this.apiService.getQuery(ApiQuery.all_subjects_for_user_id_limit_offset, { user_id: userId, limit: this.maxItemsPerPage, offset: offset }).subscribe(data => {
            //this.subjectService.fetch(userId).subscribe(data => {

            let totalRecordsCount = data.meta.total_count;
            this.totalPages = Math.ceil(totalRecordsCount / this.maxItemsPerPage);
            // Beginning - pagination
            this.pages = [];
            if (this.currentPage === 1) {
                this.pages.push(1);
                if (this.maxItemsPerPage < totalRecordsCount) {
                    this.pages.push(2);
                }
                if (this.maxItemsPerPage * 2 < totalRecordsCount) {
                    this.pages.push(3);
                }
            }
            if (this.currentPage >= 2) {
                if (this.currentPage - 2 > 0) this.pages.push(this.currentPage - 2);
                this.pages.push(this.currentPage - 1);
                this.pages.push(this.currentPage);
                if (this.maxItemsPerPage * this.currentPage < totalRecordsCount) {
                    this.pages.push(this.currentPage + 1);
                }
            }
            // End - pagination

            this.athletes = data.objects;
            this.loadingAthletes = false;
        });
    }

    public getAll() {

        this.coaches = new Array<any>();

        const userId = +this.globalVariables.get('currentUserId');
        this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId }).subscribe(data => {
            //this.coachService.fetch(userId).subscribe(data => {
            this.coaches = data.objects;
            this.loadingCoaches = false;
        });

        this.athletes = new Array<any>();
        this.retrieveUsers(userId);

        this.friends = new Array<any>();
        this.apiService.getQuery(ApiQuery.all_friends_for_user_id).subscribe(data => {
            //this.friendService.fetch(userId).subscribe(data => {
            for (let friend of data.objects) {
                if (friend.user_allowed.id === userId) {
                    this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                } else {
                    this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                }
            }
            this.loadingFriends = false;
        });

        this.pendingRequests = new Array<any>();
        this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(data => {
            //this.coachService.fetchPending(userId).subscribe(data => {
            this.pendingRequests = data.objects;

            this.loadingPendingRequests = false;
        });


    }


    revokeCoach(resourceUri: string) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.apiService.deleteFromURI(resourceUri, "bundle_permission").flatMap(res => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    const userId = +this.globalVariables.get('currentUserId');
                    return this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId });
                    //return this.coachService.fetch(userId);
                }).subscribe(data => {

                    this.coaches = data.objects;
                    this.loadingCoaches = false;
                });
            }
        });
    }


    revokeAthlete(resourceUri: string) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.apiService.deleteFromURI(resourceUri, 'bundle_permission').subscribe(res => {
                    //this.subjectService.delete(resourceUri).subscribe(res => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    const userId = +this.globalVariables.get('currentUserId');
                    this.retrieveUsers(userId);
                });
            }
        });
    }

    revokeFriend(resourceUri: string) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.apiService.deleteFromURI(resourceUri, 'bundle_permission').subscribe(res => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    const userId = +this.globalVariables.get('currentUserId');
                    this.apiService.getQuery(ApiQuery.all_friends_for_user_id, { user_id: userId }).subscribe(data => {
                        //this.friendService.fetch(userId).subscribe(data => {
                        this.friends = new Array<any>();
                        const userId = +this.globalVariables.get("currentUserId");
                        for (let friend of data.objects) {
                            if (friend.user_allowed.id === userId) {
                                this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                            } else {
                                this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                            }
                        }
                        this.loadingFriends = false;
                    });
                });
            }
        });
    }

    addCoach() {
        this.addCoachService.confirm({
            message: ' ',
            header: 'Share your data with an admin',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: () => { }
        });
        /*
            const link = ['/add-coach'];
     
            this.router.navigate(link);
            */
    }

    addAthlete(email) {
        this.addAthleteService.confirm({
            message: ' ',
            header: 'Add a new user',
            icon: '',
            kpi: 'test',
            unit: '',
            email: email,
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: () => { }
        });
    }


    addUser() {
        this.addUserService.confirm({
            message: ' ',
            header: 'Add a new user',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: () => { }
        });
    }

    addFriend() {
        this.addFriendService.confirm({
            message: ' ',
            header: 'Add a new friend/relative',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: () => { }
        });
    }

    activeUser(id: number) {

        this.globalVariables.set('currentUserId', id + "");
    }

}