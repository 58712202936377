import * as _ from 'lodash';

export class HxToolsGarmentSize {

    static super_min: Array<number> = [0.69, 0.74, 0.80, 0.85, 0.91, 0.97, 1.02, 1.10, 1.19];
    static optimal_min: Array<number> = [0.70, 0.75, 0.81, 0.87, 0.92, 0.98, 1.03, 1.12, 1.20];
    static optimal_max: Array<number> = [0.73, 0.79, 0.85, 0.91, 0.96, 1.02, 1.08, 1.17, 1.26];
    static super_max: Array<number> = [0.76, 0.82, 0.88, 0.94, 1.01, 1.07, 1.13, 1.22, 1.31];

    static size: Array<string> = ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];

    static intersect(arr1, arr2) {
        //console.log(arr1);
        //console.log(arr2);

        let arr = new Array(arr2.length);
        for (let i = 0; i < arr.length; i++) {
            arr[i] = 0;
            if (arr1[i] === 1 && arr2[i] === 2) {
                arr[i] = 1;
            }
        }
        //console.log(arr);
        return arr;
    }


    static getSuggestedSize(thoracic: number, abdominal: number): string {


        let optimals = Array(9).fill(0);
        let supers = Array(9).fill(0);

        let thoracic_i = 0;
        for (let i = 0; i < this.optimal_min.length; i++) {
            if (this.optimal_min[i] <= thoracic && thoracic <= this.optimal_max[i]) {
                optimals[i]++;
            }
            if (this.super_min[i] <= thoracic && thoracic <= this.super_max[i]) {
                supers[i]++;
            }
        }

        let thoracic_optimal = optimals;
        let thoracic_super = supers;
        let perfect_thoracic_fit = _.findIndex(optimals, function (el) { return el === 1; });
        let thoracic_fit = _.findIndex(supers, function (el) { return el === 1; });

        let abdominal_supers = Array(9).fill(0);
        let abdominal_optimals = Array(9).fill(0);
        let abdominal_i = 0;
        for (let i = 0; i < this.optimal_min.length; i++) {
            if (this.optimal_min[i] <= abdominal && abdominal <= this.optimal_max[i]) {
                optimals[i]++;
                abdominal_optimals[i]++;
            }
            if (this.super_min[i] <= abdominal && abdominal <= this.super_max[i]) {
                supers[i]++;
                abdominal_supers[i]++;
            }
        }

        let perfect_abdominal_fit = _.findIndex(abdominal_optimals, function (el) { return el === 1; });
        let abdominal_fit = _.findIndex(abdominal_supers, function (el) { return el === 1; });
        let perfect_fit = _.findIndex(optimals, function (el) { return el === 2; });
        let good_fit = _.findIndex(supers, function (el) { return el === 2; });
        let incomplete_fit = _.findIndex(optimals, function (el) { return el === 1; });

        let r1 = this.intersect(thoracic_optimal, supers);
        let intersect_with_thoracic_optimal = _.findIndex(r1, function (el) { return el === 1; });

        let r2 = this.intersect(thoracic_super, supers);
        let intersect_with_thoracic_super = _.findIndex(r2, function (el) { return el === 1; });

        if (perfect_fit > -1) {
            // Perfect fit on both circumferences, then returns the size
            return this.size[perfect_fit];
        } else if (good_fit > -1) {
            let sizes = [];
            sizes.push(this.size[good_fit]);
            if (good_fit < supers.length - 1 && supers[good_fit + 1] === 2) {
                sizes.push(this.size[good_fit + 1]);
            }
            return sizes.join(",");
        } else if (perfect_thoracic_fit && intersect_with_thoracic_optimal > -1) {
            // Perfect fit on thoracic circumference only, search for fit on abdominal circumference
            return this.size[intersect_with_thoracic_optimal];
        } else if (thoracic_fit && intersect_with_thoracic_super > -1) {
            // Fit on thoracic circumference only, search for fit on abdominal circumference
            return this.size[intersect_with_thoracic_super];
        } else if (thoracic > abdominal && Math.abs(perfect_thoracic_fit - perfect_abdominal_fit) <= 1) {
            // If the thoracic bigger than abdominal, use the min abdominal size, max 1 size diff

            if (thoracic < this.super_max[perfect_abdominal_fit]) {
                return this.size[perfect_abdominal_fit];
            } else {
                return null;
            }            
        } else if (thoracic < abdominal && Math.abs(perfect_thoracic_fit - perfect_abdominal_fit) <= 1) {
            // If the abdominal bigger than thoracic, use the min thoracic size, max 1 size diff
            if (abdominal < this.super_max[perfect_thoracic_fit]) {
                return this.size[perfect_thoracic_fit];
            } else {
                return null;
            } 
        } else {
            // Otherwise not able to return any size.
            return null;
        }
    }
}