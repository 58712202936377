<ng-template #defaultTemplate>
    <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal> 0">{{ day.badgeTotal
            }}</span>
        <span class="cal-day-number">{{ day.date |
            calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="cal-events" *ngIf="day.events.length> 0">
        <div
            class="cal-event"
            *ngFor="let event of day.events"
            [style.backgroundColor]="event.color.primary"
            [ngClass]="event?.cssClass"
            (mouseenter)="highlightDay.emit({event: event})"
            (mouseleave)="unhighlightDay.emit({event: event})"
            [calCalendarTooltip]="event.title |
            calendarEventTitle:'monthTooltip':event"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipEvent]="event"
            [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody"
            (calClick)="onEventClick($event, event)">
        </div>
    </div>
</ng-template>
<ng-template
    [ngTemplateOutlet]="customTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{
    day: day,
    openDay: openDay,
    locale: locale,
    tooltipPlacement: tooltipPlacement,
    highlightDay: highlightDay,
    unhighlightDay: unhighlightDay,
    eventClicked: eventClicked,
    tooltipTemplate: tooltipTemplate,
    tooltipAppendToBody: tooltipAppendToBody
    }">
</ng-template>