<app-alert></app-alert>

<section id="relationships">
  <div class="hx-g header">
    <div class="hx-u-23-24">
      <div class="title">
        Data sharing
      </div>
    </div>
    <div class="hx-u-1-24">
      <img (click)="openModal('relationships')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
    </div>
  </div>

  <div class="relationships-types hx-g">
    <div class="hx-u-8-24">
      <div class="relationships-type" (click)="selectMyFriends()" [ngClass]="{ 'active ' : selection === 'friends'}">Friends & Relatives
      </div>
    </div>
    <div class="hx-u-8-24">
      <div class="relationships-type" (click)="selectMyCoaches()" [ngClass]="{ 'active ' : selection === 'coaches'}">Admins
      </div>
    </div>
    <div class="hx-u-8-24">
      <div class="relationships-type" (click)="selectMyAthletes()" [ngClass]="{ 'active ' : selection === 'athletes'}">Users
      </div>
    </div>
    <!--div class="hx-u-6-24">
      <div class="trainingroutine-type-right" (click)="selectImport()" [ngClass]="{ 'active ' : selection === 'import'}">IMPORT
      </div>
    </div-->
  </div>



  <div *ngIf="!loadingCoaches && selection ==='coaches'">
    <div class="hx-g hx-padding-bottom-15" style="background-color: white;padding:10px;">
      <div class="button button-blue" (click)="addCoach()"><i class="hx-icon hx-icon-plus"></i> Share your data with an admin</div>
      <table *ngIf="coaches.length > 0" class="hx-table">
        <thead>
          <tr>
            <th colspan="1">Name</th>
            <th colspan="1">Email</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let coach of coaches">
            <td>{{ coach.user_allowed.first_name }} {{ coach.user_allowed.last_name }}
              <ng-container *ngIf="!coach.user_target.first_name && !coach.user_target.first_name && !coach.user_target.email">
                {{ coach.user_target.username }}
              </ng-container>
            </td>
            <td>{{ coach.user_allowed.email }} </td>
            <td>
              <div class="button button-blue" (click)="revokeCoach(coach.resource_uri)"><i class="hx-icon hx-icon-delete"></i> Remove</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>








  <div *ngIf="!loadingAthletes && selection === 'athletes'">
    <div class="hx-g hx-padding-bottom-15" style="background-color: white;padding:10px;">
      <div class="button button-blue" (click)="addUser()"><i class="hx-icon hx-icon-plus"></i> Add a new user</div>
      <table *ngIf="athletes.length > 0" class="hx-table">
        <thead>
          <tr>
            <th colspan="1">Name</th>
            <th colspan="1">Email</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let athlete of athletes">
            <td>{{ athlete.user_target.first_name }} {{ athlete.user_target.last_name }}
              <ng-container *ngIf="!athlete.user_target.first_name && !athlete.user_target.first_name && !athlete.user_target.email">
                {{ athlete.user_target.username }}
              </ng-container>
            </td>
            <td>{{ athlete.user_target.email }} </td>
            <td>
              <div class="button button-blue" (click)="revokeAthlete(athlete.resource_uri)"><i class="hx-icon hx-icon-delete"></i> Remove</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginator">
          <div class="button-group margin-top-5 margin-bottom-5" style="text-align:center;">

            <!-- Previous page -->
            <div *ngIf="currentPage > 10" class="button button-blue tip">
              <i class="hx-icon hx-icon-chevron-left tip" (click)="changeToPage(currentPage-10)"></i>
              <span>
                Jump back 10 pages
              </span>
            </div>
      
            <!-- Previous page -->
            <div *ngIf="currentPage > 1" class="button button-blue tip">
              <i class="hx-icon hx-icon-caret-left tip" (click)="changeToPage(currentPage-1)"></i>
              <span>
                Previous page
              </span>
            </div>
      
            <div *ngIf="currentPage > 5" class="button button-blue">
              <i (click)="changeToPage(1)" [ngClass]="{ 'current': currentPage === page }">1</i>
            </div>
            <div *ngIf="currentPage > 5" class="button button-blue">
              ...
            </div>
      
            <!-- Pages around the current page -->
            <div *ngFor="let page of pages" class="button" [ngClass]="{ 'button-light-blue': currentPage === page, 'button-blue': currentPage !== page }">
              <i (click)="changeToPage(page)">{{ page }}</i>
            </div>
      
            <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
              ...
            </div>
            <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
              <i (click)="changeToPage(totalPages - 1)" [ngClass]="{ 'current': currentPage === page }">{{ totalPages - 1
                }}</i>
            </div>
      
            <!-- Next page -->
            <div *ngIf="currentPage < totalPages" class="button button-blue tip">
              <i class="hx-icon hx-icon-caret-right" (click)="changeToPage(currentPage+1)"></i>
              <span>
                Next page
              </span>
            </div>
            <!-- Jump to 5 -->
            <div *ngIf="currentPage + 9 < totalPages" class="button button-blue tip">
              <i class="hx-icon hx-icon-chevron-right" (click)="changeToPage(currentPage+10)"></i>
              <span>
                Jump 10 pages
              </span>
            </div>
          </div>
      
      
        </div>

  </div>


  <div *ngIf="!loadingFriends && selection === 'friends'">
    <div class="hx-g hx-padding-bottom-15" style="background-color: white;padding:10px;">
      <div class="button button-blue" (click)="addFriend()"><i class="hx-icon hx-icon-plus"></i> Add a new friend/relative</div>
      <table *ngIf="friends.length > 0" class="hx-table">
        <thead>
          <tr>
            <th colspan="1">Name</th>
            <th colspan="1">Email</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let friend of friends">
            <td>{{ friend.friend.first_name }} {{ friend.friend.last_name }}
              <ng-container *ngIf="!friend.friend.first_name && !friend.friend.first_name && !friend.friend.email">
                {{ friend.friend.username }}
              </ng-container>
            </td>
            <td>{{ friend.friend.email }} </td>
            <td>
              <div class="button button-blue" (click)="revokeFriend(friend.resource_uri)"><i class="hx-icon hx-icon-delete"></i> Remove</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</section>

<section *ngIf="!loadingPendingRequests && pendingRequests.length > 0">
  <div class="hx-g header">
    <div class="hx-u-24-24">
      <div class="title">
        Pending requests
      </div>
    </div>
  </div>

    <div>
      <div class="hx-g hx-padding-bottom-15" style="background-color: white;padding:10px;">
        <table class="hx-table">
          <thead>
            <tr>
              <th colspan="1">Name</th>
              <th colspan="1">Email</th>
              <th colspan="1">Relationships Type</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pendingRequest of pendingRequests">
              <td>{{ pendingRequest.user_target.first_name }} {{ pendingRequest.user_target.last_name }}
                <ng-container *ngIf="!pendingRequest.user_target.first_name && !pendingRequest.user_target.first_name && !pendingRequest.user_target.email">
                  {{ pendingRequest.user_target.username }}
                </ng-container>
              </td>
              <td>{{ pendingRequest.user_target.username }} </td>
              <td>
                Admin
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</section>



<add-coach header="Confirmation" [closable]="true" icon="fa fa-question-circle" width="425"></add-coach>

<add-user header="Confirmation" [closable]="true" icon="fa fa-question-circle" width="425"></add-user>

<add-athlete header="Confirmation" [closable]="true" icon="fa fa-question-circle" width="425"></add-athlete>

<add-friend header="Confirmation" [closable]="true" icon="fa fa-question-circle" width="425"></add-friend>

<confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>

<app-growl [value]="msgs"></app-growl>

<help-modal id="relationships-help" style="display: none;">
  <p>Sharing your data with friends/relatives, admin or other users is possible.<br>
When sharing your data with a friend or relative, you will be able to mutually view each other’s data. <br>
When sharing your data with an admin account, this person will be able to view and edit your data, but you will not be able to view or edit theirs.<br> 
When sharing data with a user, you then become their admin account, therefore you will be able to view and edit their data, as they will not be able to view or edit yours.<br>
Access can be revoked or users can be removed from list by clicking on the <img height="24" src="/assets/icons/trash.svg">.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>