import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { isPresent } from './utils';

/**
 * Check if the control value is SHORTER THAN value in parameter
 */
export const shorterThan = (shorterThan: number): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: boolean} => {
    if (!isPresent(shorterThan)) return null;
    if (isPresent(Validators.required(control))) return null;

    let v: string = control.value;
    return v.length < +shorterThan ? null : {shorterThan: true};
  };
};
