import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { isPresent } from './utils';

/**
 * Check if the control value is LESS THAN value in parameter
 */
export const lessThan = (_lessThan: number): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: boolean} => {
    if (!isPresent(_lessThan)) return null;
    if (isPresent(Validators.required(control))) return null;

    let v: number = +control.value;
    return v < +_lessThan ? null : {_lessThan: true};
  };
};

/**
 * Check if the control value is GREATER THAN value in parameter
 */
export const greaterThan = (_greaterThan: number): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: boolean} => {
    if (!isPresent(_greaterThan)) return null;
    if (isPresent(Validators.required(control))) return null;

    let v: number = +control.value;
    return v > +_greaterThan ? null : {_greaterThan: true};
  };
};

/**
 * Check if the control value is actually a number
 */
export const isNumber: ValidatorFn = (control: AbstractControl): {[key: string]: boolean} => {
  if (isPresent(Validators.required(control))) return null;

  let v: string = control.value;
  return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(v) ? null : {'isNumber': true};
};
