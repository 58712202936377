import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';

import { PreferenceService } from "../../services/preferences.service";
import { ToggleBox, TOGGLE_BOX, UpdatePreferencesSuccess, UpdatePreferencesFailure, CHANGE_TIME_RANGE, ChangeTimeRange } from "../actions/boxes-selector.actions";

import { NotifierService } from 'angular-notifier';


@Injectable()
export class SelectorBoxEffects {

    private readonly notifier: NotifierService;

    constructor(
        private actions: Actions,
        private preferenceService: PreferenceService,
        private notifierService: NotifierService,
        private router: Router,
    ) {
        this.notifier = this.notifierService;
    }

    @Effect()
    ToggleBox: Observable<any> = this.actions
        .ofType(TOGGLE_BOX)
        .map((action: ToggleBox) => action.payload)
        .switchMap(payload => {

            

            let preferences = {};
            preferences[payload.page] = {};
            preferences[payload.page][payload.name] = {
                'name': payload.name,
                'label': payload.label,
                'displayed': payload.displayed
            };

            this.notifier.hideOldest();

            console.log("========> 6 (show preferences to send to backend");
            console.log(preferences);

            return this.preferenceService.saveSelector(payload.page, payload.name, payload.displayed)
                .map(() => {
                    //this.notifier.notify('success', 'Your preference has been successfully saved.');
                    return new UpdatePreferencesSuccess();
                    // update on the view.... TODO
                })
                .catch((error) => {
                    this.notifier.notify('error', 'An error occured. Your preference has not been saved');
                    return Observable.of(new UpdatePreferencesFailure());
                });

            //return Observable.of(new UpdatePreferences());
        });

    @Effect()
    ChangeTimeRange: Observable<any> = this.actions
        .ofType(CHANGE_TIME_RANGE)
        .map((action: ChangeTimeRange) => action.range)
        .switchMap(payload => {
            let preferences = {};
            preferences['time_range'] = payload;

            this.notifier.hideOldest();
            return this.preferenceService.save(preferences)
                .map(() => {
                    this.notifier.notify('success', 'Time range has been successfully saved in your preferences.');
                    return new UpdatePreferencesSuccess();
                })
                .catch((error) => {
                    this.notifier.notify('error', 'An error occured. Your preference has not been saved');
                    return Observable.of(new UpdatePreferencesFailure());
                });

            //return Observable.of(new UpdatePreferences());
        });
}
