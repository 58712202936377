
export const COLORS = {
    "2c": { "high": "#F7931E", "normal": "#39B54A" },
    "5c": { "poor": "#EA1820", "average": "#faab61", "good": "#52BC60", "excellent": "#1E974C", "superior": "#3276AD" },
    "5c_weight": { "severe thinness": "#f7931e", "underweight": "#fbb03b", "normal": "#39b54a", "overweight": "#fbb03b", "obese": "#f7931e" },
};

export const WRITABLE_KPIS = {
    hr_max: true,
    hr_recov: true,
    hr_rest: true,
    vo2_max: true,
    rmssd: false,
    respiration_coef: true,
    weight: true,
    height: true,
    bmi: false,
    total_sleep_time: false,
    sleep_latency: false,
    sleep_efficiency: false,
    abdominal_circumference: true,
    thoracic_circumference: true
  };

  export const SLEEP_STATUS = [
    'No underlying data. Sleep not processed', // 0
    'Sleep processed', // 1
    '', // 2
    'The end of the sleep range finished when the device was not connected. The sleep measures have been calculated with the available data.',  // 3
    'The device was disconnected for less than 5% of the night. Sleep processed.',  // 4
    '',  // 5
    'The device was disconnected for more than 5% of the night. There is not enough data to perform the sleep analysis. Sleep not processed.',  // 6
    'The beginning of the sleep was started when the device was not connected. Sleep not processed.',  // 7
    '',  // 8
    'Range too large for sleep processing - longer than a day. Sleep not processed.',  // 9
    'Range too small for sleep processing. Sleep not processed.',  // 10
    'Range does not have REM and NREM period. Sleep not processed.' // 11
  ];

  export const LINK_ACTIVITY = {
    hr_max: true,
    hr_recov: true,
    hr_rest: true,
    vo2_max: true,
    rmssd: true,
    respiration_coef: true,
    weight: true,
    height: true,
    bmi: false,
    total_sleep_time: true,
    sleep_latency: true,
    sleep_efficiency: true
  };

  export const PATTERNS = {
    hr_max: "[0-9]+([\.,][0-9]{1,2})?",
    hr_recov: "[0-9]+([\.,][0-9]{1,2})?",
    hr_rest: "[0-9]+([\.,][0-9]{1,2})?",
    vo2_max: "[0-9]+([\.,][0-9]v)?",
    rmssd: "[0-9]+([\.,][0-9]{1,2})?",
    respiration_coef: "[0-9]+([\.,][0-9]{1,2})?",
    weight: "[0-9]+([\.,][0-9]{1,2})?",
    height: "[0-9]+([\.,][0-9]{1,2})?",
    bmi: "[0-9]+([\.,][0-9]{1,2})?",
    total_sleep_time: "[0-9]{1,2}([:,][0-9]{1,2})?",
    sleep_latency: "[0-9]{1,2}([:,][0-9]{1,2})?",
    sleep_efficiency: "[0-9]+([\.,][0-9]{1,2})?"
  };

 
 export const OPTIONS_AXIS_GAUSSIAN = {
    "yAxis": [
      {
        "gridLineWidth": 0,
        "minorGridLineWidth": 0,
        "labels": {
          "enable": false
        }
      }
    ]
  };

  export const OPTIONS_AXIS  = {
    "yAxis": [
      {
        "labels": {
          "format": "{value}"
        },
        "opposite": true
      }
    ]
  };

  export const DISPLAY_STATUS = {
    'hr_max': '2c',
    'hr_rest': '5c',
    'rmssd': false,
    'total_sleep_time': false,
    'sleep_latency': false,
    'hr_recov': '5c',
    'vo2_max': '5c',
    'respiration_coef': '5c',
    'height': false,
    'weight': false,
    'bmi': '5c_weight',
    'sleep_efficiency': '5c'
  };

export const SHOW_DISTRIBUTION = {
    'hr_max': true,
    'hr_rest': true,
    'rmssd': false,
    'total_sleep_time': true,
    'sleep_latency': true,
    'hr_recov': true,
    'vo2_max': true,
    'respiration_coef': true,
    'height': true,
    'weight': true,
    'bmi': true,
    'sleep_efficiency': true,
    'thoracic_circumference': false,
    'abdominal_circumference': false
  };

  export const SHOW_EXTRA = {
    'hr_max': false,
    'hr_rest': false,
    'rmssd': false,
    'total_sleep_time': false,
    'sleep_latency': false,
    'hr_recov': false,
    'vo2_max': false,
    'respiration_coef': false,
    'height': false,
    'weight': false,
    'bmi': false,
    'sleep_efficiency': false,
    'thoracic_circumference': true,
    'abdominal_circumference': true
  };


export const DEFAULT_COLORS = [
  { "color": "rgba(42, 127, 193, 0.9)" },
  { "color": "rgba(30,162,77, 0.9)" },
  { "color": "rgba(249,170,65, 0.9)" },
  { "color": "rgba(236,82,48, 0.9)" },
  { "color": "rgba(180,31,50, 0.9)" }
];

export const SLEEP_POSITION_LABELS = [
  { "name": "Unknown", "color": "rgba(0,0,0,0.6)" },
  { "name": "Belly", "color": "rgba(255,76,44,0.6)" },
  { "name": "Back", "color": "rgba(50, 255, 170, 0.6)" },
  { "name": "Right", "color": "rgba(232, 214, 50, 0.6)" },
  { "name": "Left", "color": "rgba(233, 63, 255, 0.6)" },
  { "name": "Standing", "color": "rgba(0, 0, 0, 1)" /*, "bgcolor": "gray"*/ }
];

export const  SLEEP_PHASE_LABELS = [
  { "name": "No data", "color": "rgba(0,0,0,1)" },
  { "name": "NREM", "color": "rgba(85,57,130,0.6)" },
  { "name": "REM", "color": "rgba(0,140,111,0.6)" },
  { "name": "Wake", "color": "rgba(255, 119, 92,0.6)" }
];

export const ZONE_LABELS = [
  "Very-light",
  "Light",
  "Moderate",
  "Hard",
  "Maximum"
];

export const METRICS = [
  {
      "ids": [44, 46, 47],
      "title": "Heart Rate"
  },
  {
      "ids": [44, 47],
      "title": "Heart Rate",
      "type": "min"
  },
  {
      "ids": [55, 57, 58],
      "title": "Minute Ventilation"
  },
  {
      "ids": [12, 14, 15],
      "title": "Breathing Rate"
  },
  {
      "ids": [12, 15],
      "title": "Breathing Rate",
      "type": "min"
  },
  {
      "ids": [17, 19, 20],
      "title": "Cadence"
  },
  {
      "ids": [77, 79, 80],
      "title": "Tidal Volume"
  },
  {
      "ids": [1, 3, 4],
      "title": "Activity"
  },
  {
      "ids": [502, 501],
      "title": "Speed",
      "type": "max"
  },
  {
      "ids": [170, 172, 171],
      "title": "HF"
  },
  {
      "ids": [255, 257, 258],
      "title": "MV Adjusted"
  },
  {
      "ids": [149],
      "title": "Energy"
  },
  {
      "ids": [71],
      "title": "Steps"
  },
  {
      "ids": [2038],
      "title": "Distance"
  },
  {
      "ids": [1039],
      "title": "Sleep Efficiency"
  },
  {
      "ids": [1032],
      "title": "Total Sleep Time"
  },
  {
      "ids": [1042],
      "title": "Sleep Percent"
  },
  {
      "ids": [1061],
      "title": "Time To Fall Asleep"
  },
  {
      "ids": [1038],
      "title": "Sleep position changes"
  },
  {
      "ids": [184],
      "title": "HRV (RMSSD in ms)"
  }
];

export const FITNESS_SECTIONS_KPIS = [
  {
    'label': 'Cardiorespiratory Status',
    'name': 'cardiorespiratory_status',
    'displayed': true,
    'kpis': [
      "hr_max",
      "hr_recov",
      "hr_rest",
      "vo2_max",
      "rmssd"
    ],
  },
  {
    'label': 'Measurements',
    'name': 'measurements',
    'displayed': true,
    'kpis': [
      "weight",
      "height",
      "bmi",
      "thoracic_circumference",
      "abdominal_circumference"
    ]
  },
  {
    'label': 'Sleep',
    'name': 'sleep',
    'displayed': true,
    'kpis': [
      "total_sleep_time",
      "sleep_latency",
      "sleep_efficiency"
    ]
  }
];



export const DISTRIBUTION_CHART = {
  lineColor : "#122F4C",
  fillColor: 'white',
  lineWidth: 2,
  radius: 6,
  enabled: true
};

