import * as moment from 'moment/moment';

export class HxToolsDate {

    public static getCurrentMY() {

        let firstDay = moment().startOf('month');
        let lastDay = moment().endOf('month');

        let currentMonth = lastDay.month() + 1;
        let currentYear = firstDay.year();
        return [currentMonth, currentYear];
    }

    public static isBefore(dateTobeCompared: Array<number>, refDate: Array<number>) {
        if (dateTobeCompared[1] < refDate[1]) {
            return true;
        } else {
            if (dateTobeCompared[0] < refDate[0]) {
                return true;
            } else {
                return false;
            }
        }
    }

    public static isBeforeThisMonth(date: Array<number>) {
        return this.isBefore(date, this.getCurrentMY());
    }

    public static getMY(unixTime: number) {
        const date = moment(unixTime, "X");
        return [date.month() + 1, date.year()];
    }

    public static getDMY(unixTime: number) {
        const date = moment(unixTime, "X");
        return [date.day() + 1, date.month() + 1, date.year()];
    }

    /**
     * get unix timestamp of the start of date
     */
    public static getStartOfDay(timestamp?: number, offset?: number): number {
        if (!offset) offset = 0;
        if (timestamp) return moment(timestamp, "X").startOf('day').unix() + offset;
        else return moment().startOf('day').unix() + offset;
    }


    public static getStartOfDayHX(timestampHx: number, offset?: number): number {
        if (!offset) offset = 0;
        return 256 * (moment(1000 * timestampHx / 256, "X").startOf('day').unix() + offset) / 1000;
    }

    /**
     * get unix timestamp of the end of date
     */
    public static getEndOfDay(timestamp?: number): number {
        if (timestamp) return moment(timestamp, "X").endOf('day').unix();
        else return moment().endOf('day').unix();
    }

    public static getEndOfDayHX(timestampHx: number, offset?: number): number {
        if (!offset) offset = 0;
        return 256 * (moment(1000 * timestampHx / 256, "X").endOf('day').unix() + offset) / 1000;
    }

    /**
     * get unix timestamp of the start of date month
     */
    public static getStartOfMonth(timestamp?: number, offset?: number): number {
        if (!offset) offset = 0;
        if (timestamp) return moment(timestamp, "X").startOf('month').unix() + offset;
        else return moment().startOf('month').unix() + offset;
    }

    /**
     * get unix timestamp of the end of date month
     */
    public static getEndOfMonth(timestamp?: number): number {
        if (timestamp) return moment(timestamp, "X").endOf('month').unix();
        else return moment().endOf('month').unix();
    }

    /**
     * get unix timestamp of the start of day of the day X days ago
     */
    public static getDateDaysAgo(nbOfDays: number): number {
        let day = moment(Date.now());
        let duration = moment.duration({ 'days': nbOfDays });
        return day.subtract(duration).valueOf();
    }

    /**
     * convert unix timestamp to hexo timestamp
     */
    public static toHxTimestamp(unixTimestamp: number) {
        if (unixTimestamp.toString().length >= 13) return (unixTimestamp / 1000) * 256;
        return 256 * unixTimestamp;
    }

    /**
     * convert hexo timestamp to unix timestamp
     */
    public static toUnixTimestamp(hxTimestamp: number, inMilliseconds?: boolean) {
        if (inMilliseconds) return (hxTimestamp / 256) * 1000;
        return (hxTimestamp / 256);
    }



}

