import { URLSearchParams } from '@angular/http';
import { environment } from '../../../environments/environment';

export class OAuth2Process {

    client_id: string;
    secret: string;
    redirect_uri: string;
    flow: string;
    scope: any;

    _request: any;

    oauth2_baseurl = environment.apiURL + '/api/connect/oauth2';
    oauth2_authurl: string;
    oauth2_tokenurl: string;

    constructor(client_id, secret, redirect_uri, flow) {
        this.client_id = client_id;
        this.secret = secret;
        this.redirect_uri = redirect_uri;
        this.flow = flow || 'implicit';

        this.oauth2_authurl = environment.apiURL + '/api/connect/login'; //this.oauth2_baseurl + '/oauth2_login/';
        this.oauth2_tokenurl = this.oauth2_baseurl + '/token/';

        /*this.oauth2_authurl = this.oauth2_baseurl + '/auth/';
        this.oauth2_tokenurl = this.oauth2_baseurl + '/token/';*/

    }

    get_auth_url() {
        let token_type = {
            'implicit': 'token',
            'authorization_code': 'code'
        }[this.flow];
        if (!token_type) throw new Error(`No authentication portion for flow: ${this.flow}`);
        return this.build_oauth2_token_request_uri(token_type);
    }

    build_oauth2_token_request_uri(response_type, kwargs?) {
        let state = this.generate_state_token();

        let get_args = {
            'response_type': response_type,
            'state': state,
            'client_id': this.client_id,
            'redirect_uri': this.redirect_uri,
        };

        if (this.scope) get_args['scope'] = this.scope;
        if (kwargs) get_args = Object.assign(get_args, kwargs);
        if (!get_args.redirect_uri) delete get_args.redirect_uri;

        let searchParams = new URLSearchParams();
        searchParams.append('response_type', response_type);
        searchParams.append('state', state);
        searchParams.append('client_id', this.client_id);
        searchParams.append('redirect_uri', this.redirect_uri);

        let oauthurl = new URL(this.oauth2_authurl);
        oauthurl.search = searchParams.toString();
        // let paramsString = "q=URLUtils.searchParams&topic=api";
        // let searchParams = new URLSearchParams(_get_arts);
        // return oauthurl.href; // + _get_arts;
        return oauthurl;
    }

    /**
    * A unique token generator that is extremely predictable but will do the
    * job for providing state tokens.
    */
    generate_state_token() {
        return Date.now() + Math.random().toString().slice(-4);
    }

}
