<div class="hx-ui-dialog" [style.display]="visible ? 'block' : 'none'" [style.width.px]="width" [style.height.px]="height"
    (mousedown)="moveOnTop()">

    <div class="hx-ui-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top">
        <span class="hx-ui-dialog-title" *ngIf="header">{{header}}</span>
        <a *ngIf="closable" [ngClass]="{'hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all':true}" href="#" role="button"
            (click)="hide($event)">
            <span class="hx-icon hx-icon-times"></span>
            </a>
    </div>

    <div class="hx-ui-dialog-content hx-ui-widget-content">
        <div class="hx-form hx-form-aligned">

            <div class="blockquote">
                An invitation will be sent to this person’s email address. Once the invitation is accepted, you will be able to view and edit your friend's data. You can remove any user at any time.
            </div>

            <div class="formErrors" *ngIf="emailField.errors && emailField.errors.pattern">
                <span *ngIf="emailField.errors && emailField.errors.pattern">
                Email is invalid
                </span>
            </div>

            <div class="hx-control-group input_with_appended_unit">
                <label>Email*</label>
                <input type="email" [(ngModel)]="email" #emailField="ngModel" size="30" pattern="^\w+([\.-]?\w+)*(\+[0-9a-zA-Z]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            </div>

            <div class="formNotes">
                * mandatory fields
            </div>

        </div>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <ng-content select="p-footer"></ng-content>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <button type="button" class="button" (click)="reject()" *ngIf="rejectVisible">
            <i class="hx-icon hx-icon-times"></i> Cancel
        </button>
        <button type="button" class="button" (click)="accept()" *ngIf="acceptVisible" [disabled]="!email || emailField.errors && emailField.errors.pattern">
            <i class="hx-icon hx-icon-check"></i> Add
        </button>
    </div>

    </div>