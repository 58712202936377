var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService, ApiQuery, DataService } from '../../core/services';
import { User, UserInfo } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import * as moment from 'moment/moment';
import { AlertService } from '../../shared/components/alert/alert.service';
import { dateBefore, dateAfter } from '../../shared/validators/date.validator';
function compare_start_date(a, b) {
    if (a.start < b.start)
        return 1;
    if (a.start > b.start)
        return -1;
    return 0;
}
var minimum_age = 5;
var maximum_age = 120;
var UserInfoComponent = /** @class */ (function (_super) {
    __extends(UserInfoComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function UserInfoComponent(fb, apiService, dataService, alertService) {
        var _this = _super.call(this, fb) || this;
        _this.apiService = apiService;
        _this.dataService = dataService;
        _this.alertService = alertService;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.active = true;
        _this.success = false;
        _this.system = 'metric';
        _this.units = {
            'weight': {
                'metric': 'kg',
                'imperial': 'lbs'
            },
            'height': {
                'metric': 'cm',
                'imperial': ''
            }
        };
        _this.heights = {};
        _this.weights = {};
        _this.preferences = {};
        _this.profile_id = null;
        _this.valueChange = new EventEmitter();
        /**
         * Error messages
         */
        _this.formErrors = {
            'weight': '',
            'height': '',
            'gender': '',
            'system': '',
            'date_of_birth': '',
        };
        _this.validationMessages = {
            'weight': {
                'required': 'Weight is required.'
            },
            'height': {
                'required': 'Height is required.'
            },
            'date_of_birth': {
                'required': 'Date of birth is required.',
                'dateBefore': 'Date of birthday should be before ' +
                    moment().subtract(minimum_age, 'years').format('YYYY-MM-DD'),
                'dateAfter': 'Date of birthday should be after ' +
                    moment().subtract(maximum_age, 'years').format('YYYY-MM-DD')
            }
        };
        _this.convert = {
            height: {
                imperial: function (v) {
                    var feet = v * 0.3937 / 12;
                    var inch = Math.round(feet % 1 * 120) / 10;
                    return Math.floor(feet) + "' " + Math.floor(inch) + "\"";
                },
                metric: function (v) {
                    var _a = v.split(' ').map(parseFloat), f = _a[0], i = _a[1];
                    return Math.round((f * 12 + i) / 0.3937) / 100;
                }
            },
            weight: {
                imperial: function (v) { return Math.round(v * 2.2046); },
                metric: function (v) { return Math.round(v / 0.022046) / 100; }
            }
        };
        _this.user = new User();
        _this.system = 'metric';
        return _this;
    }
    /**
     * Building the form on init
     */
    UserInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.getFromUri('api/profile/' + this.profile_id + '/').flatMap(function (data) {
            _this.user.date_of_birth = data.date_of_birth;
            _this.user.gender = data.gender;
            return _this.apiService.getKPI("height", _this.profile_id);
        }).flatMap(function (data) {
            data.objects.sort(compare_start_date); // we retrieve the last record
            _this.user.height = "" + 100 * data.objects[0].value;
            return _this.apiService.getPreferences(_this.profile_id);
        }).flatMap(function (data) {
            _this.preferences = data;
            if (data.unit_system !== undefined) {
                _this.system = data.unit_system;
            }
            else {
                _this.system = 'metric';
            }
            _this.setMeasurementFields(_this.system);
            return _this.apiService.getKPI("weight", _this.profile_id);
        }).subscribe(function (data) {
            data.objects.sort(compare_start_date); // we retrieve the last record
            _this.user.weight = Math.round(data.objects[0].value);
            _this.buildForm();
        });
    };
    /**
     * Actions performed when submit is clicked
     */
    UserInfoComponent.prototype.onSubmit = function () {
        var _this = this;
        this.user = this.form.value;
        var userInfo = new UserInfo();
        userInfo.date_of_birth = this.user.date_of_birth;
        userInfo.gender = this.user.gender;
        this.apiService.putQuery(ApiQuery.save_profile, userInfo, { id: this.profile_id }).flatMap(function () {
            return _this.apiService.saveKPI("height", Number(_this.user.height) / 100, _this.profile_id);
        }).flatMap(function () {
            return _this.apiService.saveKPI("weight", Number(_this.user.weight), _this.profile_id);
        }).subscribe(function () {
            _this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The info has been saved.' }];
            _this.valueChange.emit(_this.msgs);
        }, function (error) {
            _this.alertService.error(error);
        });
    };
    /**
     * Building form function
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    UserInfoComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.fb.group({
            'weight': [this.user.weight, [
                    Validators.required
                ]
            ],
            'height': [this.user.height, [
                    Validators.required
                ]
            ],
            'gender': [this.user.gender, []],
            'system': ['metric', []],
            'date_of_birth': [this.user.date_of_birth, [
                    dateBefore(new Date(Date.now() + -(minimum_age * 365) * 24 * 3600 * 1000)),
                    dateAfter(new Date(Date.now() + -(maximum_age * 365) * 24 * 3600 * 1000)) // 5 years ago + 3 days
                ]],
        });
        this.form.valueChanges
            .subscribe(function () {
            _this.onValueChanged( /*data*/);
        });
        this.onValueChanged();
    };
    UserInfoComponent.prototype.setMeasurementFields = function (system) {
        var _this = this;
        if (system === void 0) { system = 'metric'; }
        this.preferences['unit_system'] = system;
        this.dataService.updatePreferences('/api/profile/' + this.profile_id + '/', this.preferences).subscribe(function () {
            if (system === 'metric') {
                _this.heights = {};
                for (var height = 50; height <= 230; height++) {
                    _this.heights[height] = height + ' cm';
                }
                _this.weights = [];
                for (var weight = 25; weight <= 180; weight++) {
                    _this.weights[weight] = weight + ' kg';
                }
            }
            else {
                _this.heights = [];
                for (var height = 50; height <= 230; height++) {
                    _this.heights[height] = _this.convert.height.imperial(height) + " (" + height + " cm)";
                }
                _this.weights = [];
                for (var weight = 25; weight <= 180; weight++) {
                    _this.weights[weight] = _this.convert.weight.imperial(weight) + ' lbs';
                }
            }
        });
    };
    return UserInfoComponent;
}(UserForm));
export { UserInfoComponent };
