import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { AuthenticationService, UserService } from '../../core/services';
import { UserForm } from './user-form';
import 'rxjs/add/operator/mergeMap';
import { equalTo } from '../../shared/validators/password.validator';
import { Message } from '../../shared/components/growl/message';
import { GlobalVariables } from '../../core/services/global.variables';

@Component({
    selector: 'user-password',
    templateUrl: './user-password.component.html',
    styleUrls: ['../../../assets/stylesheets/button.scss']
})
export class UserPasswordComponent extends UserForm implements OnInit {

    profile: any;
    active = true;
    success = false;

    @Input() user_id = null;

    @Output() valueChange = new EventEmitter();

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    /**
     * Error messages
     */
    formErrors = {
        'new_password': '',
        'confirm_password': ''
    };

    validationMessages = {
        'new_password': {
            'required': 'The field is required.',
            'minLength': 'Passwords must be at least 8 characters in length'
        },
        'confirm_password': {
            'required': 'The field is required.',
            'equalTo': 'The passwords are not identical',
            'minLength': 'Passwords must be at least 8 characters in length'
        }
    };

    id: number;

    /**
     * CONSTRUCTOR
     */
    constructor(fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private globalVariables: GlobalVariables,
        private alertService: AlertService) {
        super(fb);
    }

    /**
     * Building the form on init
     */
    ngOnInit(): void {
        this.buildForm();
    }

    /**    
     * Actions performed when submit is clicked
     */
    onSubmit() {
        this.userService.save({ 'password' : this.form.value.new_password }).subscribe(data => {
            this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The password has been successfully changed.' }];
            this.valueChange.emit(this.msgs);
            this.form.reset();
            this.success = true;
        }, error => {
            this.alertService.error(error);
        });
    }

    get new_password() { return this.form.get('new_password'); }

    /**
     * Building form function 
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    buildForm(): void {

        let new_password = new FormControl('', [Validators.required, Validators.minLength(8)]);
        let confirm_password = new FormControl('', equalTo(new_password));

        this.form = new FormGroup({
            new_password: new_password,
            confirm_password: confirm_password
        });

        this.form.valueChanges
            .subscribe(data => {
                this.onValueChanged();
            });

        this.onValueChanged();
    }

}

