/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile.component.scss.shim.ngstyle";
import * as i1 from "../../../assets/stylesheets/core/section.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./user-info.component.ngfactory";
import * as i4 from "./user-info.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../core/services/api.service";
import * as i7 from "../../core/services/data.service";
import * as i8 from "../../shared/components/alert/alert.service";
import * as i9 from "./user-account.component.ngfactory";
import * as i10 from "./user-account.component";
import * as i11 from "../../core/services/user.service";
import * as i12 from "./user-fitness.component.ngfactory";
import * as i13 from "./user-fitness.component";
import * as i14 from "./user-password.component.ngfactory";
import * as i15 from "./user-password.component";
import * as i16 from "../../core/services/authentication.service";
import * as i17 from "../../core/services/global.variables";
import * as i18 from "../../shared/components/helper/helper.component";
import * as i19 from "@angular/common";
import * as i20 from "../../shared/components/growl/growl.component.ngfactory";
import * as i21 from "../../shared/components/growl/growl.component";
import * as i22 from "../../core/dom/domhandler";
import * as i23 from "./profile.component";
var styles_ProfileComponent = [i0.styles, i1.styles];
var RenderType_ProfileComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
function View_ProfileComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "user-info", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.displayMsg($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_UserInfoComponent_0, i3.RenderType_UserInfoComponent)), i2.ɵdid(1, 114688, null, 0, i4.UserInfoComponent, [i5.FormBuilder, i6.ApiService, i7.DataService, i8.AlertService], { profile_id: [0, "profile_id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.profile.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "user-account", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.displayMsg($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_UserAccountComponent_0, i9.RenderType_UserAccountComponent)), i2.ɵdid(1, 114688, null, 0, i10.UserAccountComponent, [i5.FormBuilder, i11.UserService, i8.AlertService], { user_id: [0, "user_id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "user-fitness", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.displayMsg($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_UserFitnessComponent_0, i12.RenderType_UserFitnessComponent)), i2.ɵdid(1, 114688, null, 0, i13.UserFitnessComponent, [i5.FormBuilder, i6.ApiService, i8.AlertService], { profile_id: [0, "profile_id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.profile.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "user-password", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.displayMsg($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_UserPasswordComponent_0, i14.RenderType_UserPasswordComponent)), i2.ɵdid(1, 114688, null, 0, i15.UserPasswordComponent, [i5.FormBuilder, i16.AuthenticationService, i11.UserService, i17.GlobalVariables, i8.AlertService], { user_id: [0, "user_id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.id; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 24, "section", [], null, null, null, null, null)), i2.ɵdid(1, 16384, null, 0, i18.Section, [], null, null), (_l()(), i2.ɵeld(2, 0, null, null, 3, "div", [["class", "hx-g header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "hx-u-24-24"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" My Profile "])), (_l()(), i2.ɵeld(6, 0, null, null, 18, "div", [["class", "hx-profile hx-panel padding-bottom"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 9, "ul", [["class", "hx-tab-menu"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 2, "li", [["class", "hx-tab-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 1, "button", [["type", "button"]], [[2, "inactive", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayForm("info") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Info"])), (_l()(), i2.ɵeld(11, 0, null, null, 2, "li", [["class", "hx-tab-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 1, "button", [["type", "tab"]], [[2, "inactive", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayForm("account") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Account"])), (_l()(), i2.ɵeld(14, 0, null, null, 2, "li", [["class", "hx-tab-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 1, "button", [["type", "button"]], [[2, "inactive", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayForm("password") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Password"])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProfileComponent_1)), i2.ɵdid(18, 16384, null, 0, i19.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProfileComponent_2)), i2.ɵdid(20, 16384, null, 0, i19.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProfileComponent_3)), i2.ɵdid(22, 16384, null, 0, i19.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProfileComponent_4)), i2.ɵdid(24, 16384, null, 0, i19.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(25, 0, null, null, 1, "app-growl", [], null, null, null, i20.View_GrowlComponent_0, i20.RenderType_GrowlComponent)), i2.ɵdid(26, 4636672, null, 0, i21.GrowlComponent, [i2.ElementRef, i22.DomHandler, i2.IterableDiffers], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.form === "info"); _ck(_v, 18, 0, currVal_6); var currVal_7 = (_co.form === "account"); _ck(_v, 20, 0, currVal_7); var currVal_8 = (_co.form === "profile"); _ck(_v, 22, 0, currVal_8); var currVal_9 = (_co.form === "password"); _ck(_v, 24, 0, currVal_9); var currVal_10 = _co.msgs; _ck(_v, 26, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.form !== "info"); var currVal_1 = (_co.form === "info"); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = (_co.form !== "account"); var currVal_3 = (_co.form === "account"); _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_4 = (_co.form !== "password"); var currVal_5 = (_co.form === "password"); _ck(_v, 15, 0, currVal_4, currVal_5); }); }
export function View_ProfileComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i2.ɵdid(1, 114688, null, 0, i23.ProfileComponent, [i17.GlobalVariables], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i2.ɵccf("app-profile", i23.ProfileComponent, View_ProfileComponent_Host_0, {}, {}, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
