/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../../../../assets/stylesheets/core/button.scss.shim.ngstyle";
import * as i1 from "../../../../../../assets/stylesheets/core/grid.scss.shim.ngstyle";
import * as i2 from "../../../../../../assets/stylesheets/core/grid-base.scss.shim.ngstyle";
import * as i3 from "../../../../../../assets/stylesheets/core/icon.scss.shim.ngstyle";
import * as i4 from "../../../../../../assets/stylesheets/button.scss.shim.ngstyle";
import * as i5 from "@angular/core";
import * as i6 from "../../pipes/calendar-date.pipe";
import * as i7 from "../../providers/calendar-date-formatter.provider";
import * as i8 from "../../directives/calendar-today.directive";
import * as i9 from "@angular/common";
import * as i10 from "../../directives/calendar-previous-view.directive";
import * as i11 from "../../directives/calendar-next-view.directive";
import * as i12 from "./calendar-header.component";
var styles_CalendarHeaderComponent = [i0.styles, i1.styles, i2.styles, i3.styles, i4.styles];
var RenderType_CalendarHeaderComponent = i5.ɵcrt({ encapsulation: 0, styles: styles_CalendarHeaderComponent, data: {} });
export { RenderType_CalendarHeaderComponent as RenderType_CalendarHeaderComponent };
function View_CalendarHeaderComponent_1(_l) { return i5.ɵvid(0, [(_l()(), i5.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i5.ɵted(1, null, [" ", " "])), i5.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i5.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i5.ɵnov(_v.parent, 0), _co.viewDate, (_co.view + "ViewTitle"), _co.locale)); _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarHeaderComponent_2(_l) { return i5.ɵvid(0, [(_l()(), i5.ɵeld(0, 0, null, null, 6, "div", [["class", "button-group"]], null, null, null, null, null)), (_l()(), i5.ɵeld(1, 0, null, null, 1, "div", [["class", "button button-blue"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i5.ɵted(-1, null, [" Month "])), (_l()(), i5.ɵeld(3, 0, null, null, 1, "div", [["class", "button button-blue"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("week") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i5.ɵted(-1, null, [" Week "])), (_l()(), i5.ɵeld(5, 0, null, null, 1, "div", [["class", "button button-blue"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewChange.emit("day") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i5.ɵted(-1, null, [" Day "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view === "month"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.view === "week"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.view === "day"); _ck(_v, 5, 0, currVal_2); }); }
export function View_CalendarHeaderComponent_0(_l) { return i5.ɵvid(0, [i5.ɵpid(0, i6.CalendarDatePipe, [i7.CalendarDateFormatter, i5.LOCALE_ID]), (_l()(), i5.ɵeld(1, 0, null, null, 40, "div", [["class", "hx-g hx-padding-bottom-15"]], null, null, null, null, null)), (_l()(), i5.ɵeld(2, 0, null, null, 5, "div", [["class", "hx-u-2-24"]], null, null, null, null, null)), (_l()(), i5.ɵeld(3, 0, null, null, 4, "div", [["calCalendarToday", ""], ["class", "button button-blue tip"]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i5.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i5.ɵdid(4, 16384, null, 0, i8.CalendarTodayDirective, [], { viewDate: [0, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i5.ɵted(-1, null, [" Today "])), (_l()(), i5.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i5.ɵted(-1, null, ["Current month"])), (_l()(), i5.ɵeld(8, 0, null, null, 2, "div", [["class", "hx-u-2-24"], ["style", "padding: 0.5em 0;color: #183F66;text-align: center;"]], null, null, null, null, null)), (_l()(), i5.ɵand(16777216, null, null, 1, null, View_CalendarHeaderComponent_1)), i5.ɵdid(10, 16384, null, 0, i9.NgIf, [i5.ViewContainerRef, i5.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i5.ɵeld(11, 0, null, null, 2, "div", [["class", "hx-u-3-24"]], null, null, null, null, null)), (_l()(), i5.ɵand(16777216, null, null, 1, null, View_CalendarHeaderComponent_2)), i5.ɵdid(13, 16384, null, 0, i9.NgIf, [i5.ViewContainerRef, i5.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i5.ɵeld(14, 0, null, null, 13, "div", [["class", "hx-u-7-24"]], null, null, null, null, null)), (_l()(), i5.ɵeld(15, 0, null, null, 12, "div", [["class", "button-group"]], null, null, null, null, null)), (_l()(), i5.ɵeld(16, 0, null, null, 5, "div", [["calCalendarPreviousView", ""], ["class", "button button-blue"]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i5.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i5.ɵdid(17, 16384, null, 0, i10.CalendarPreviousViewDirective, [], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i5.ɵeld(18, 0, null, null, 3, "div", [["class", "tip"]], null, null, null, null, null)), (_l()(), i5.ɵeld(19, 0, null, null, 0, "i", [["class", "hx-icon hx-icon-caret-left"]], null, null, null, null, null)), (_l()(), i5.ɵeld(20, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i5.ɵted(-1, null, ["Previous month"])), (_l()(), i5.ɵeld(22, 0, null, null, 5, "div", [["calCalendarNextView", ""], ["class", "button button-blue"]], null, [[null, "viewDateChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i5.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("viewDateChange" === en)) {
        var pd_1 = ((_co.viewDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("viewDateChange" === en)) {
        var pd_2 = (_co.viewDateChange.next(_co.viewDate) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i5.ɵdid(23, 16384, null, 0, i11.CalendarNextViewDirective, [], { view: [0, "view"], viewDate: [1, "viewDate"] }, { viewDateChange: "viewDateChange" }), (_l()(), i5.ɵeld(24, 0, null, null, 3, "div", [["class", "tip"]], null, null, null, null, null)), (_l()(), i5.ɵeld(25, 0, null, null, 0, "i", [["class", "hx-icon hx-icon-caret-right"]], null, null, null, null, null)), (_l()(), i5.ɵeld(26, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i5.ɵted(-1, null, ["Next month"])), (_l()(), i5.ɵeld(28, 0, null, null, 13, "div", [["class", "hx-u-10-24 hx-pull-right"]], null, null, null, null, null)), (_l()(), i5.ɵeld(29, 0, null, null, 12, "div", [["class", "button-group"]], null, null, null, null, null)), (_l()(), i5.ɵeld(30, 0, null, null, 3, "div", [["class", "button-selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.metricsChange.emit(!_co.metrics) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i5.ɵdid(31, 278528, null, 0, i9.NgClass, [i5.IterableDiffers, i5.KeyValueDiffers, i5.ElementRef, i5.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i5.ɵpod(32, { "button-selector-active": 0 }), (_l()(), i5.ɵted(-1, null, [" Daily metrics "])), (_l()(), i5.ɵeld(34, 0, null, null, 3, "div", [["class", "button-selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayRecordsChange.emit(!_co.displayRecords) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i5.ɵdid(35, 278528, null, 0, i9.NgClass, [i5.IterableDiffers, i5.KeyValueDiffers, i5.ElementRef, i5.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i5.ɵpod(36, { "button-selector-active": 0 }), (_l()(), i5.ɵted(-1, null, [" Records "])), (_l()(), i5.ɵeld(38, 0, null, null, 3, "div", [["class", "button-selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayRangesChange.emit(!_co.displayRanges) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i5.ɵdid(39, 278528, null, 0, i9.NgClass, [i5.IterableDiffers, i5.KeyValueDiffers, i5.ElementRef, i5.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i5.ɵpod(40, { "button-selector-active": 0 }), (_l()(), i5.ɵted(-1, null, [" Activities "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewDate; _ck(_v, 4, 0, currVal_0); var currVal_1 = false; _ck(_v, 10, 0, currVal_1); var currVal_2 = false; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.view; var currVal_4 = _co.viewDate; _ck(_v, 17, 0, currVal_3, currVal_4); var currVal_5 = _co.view; var currVal_6 = _co.viewDate; _ck(_v, 23, 0, currVal_5, currVal_6); var currVal_7 = "button-selector"; var currVal_8 = _ck(_v, 32, 0, (_co.metrics == true)); _ck(_v, 31, 0, currVal_7, currVal_8); var currVal_9 = "button-selector"; var currVal_10 = _ck(_v, 36, 0, (_co.displayRecords == true)); _ck(_v, 35, 0, currVal_9, currVal_10); var currVal_11 = "button-selector"; var currVal_12 = _ck(_v, 40, 0, (_co.displayRanges == true)); _ck(_v, 39, 0, currVal_11, currVal_12); }, null); }
export function View_CalendarHeaderComponent_Host_0(_l) { return i5.ɵvid(0, [(_l()(), i5.ɵeld(0, 0, null, null, 1, "calendar-header", [], null, null, null, View_CalendarHeaderComponent_0, RenderType_CalendarHeaderComponent)), i5.ɵdid(1, 49152, null, 0, i12.CalendarHeaderComponent, [], null, null)], null, null); }
var CalendarHeaderComponentNgFactory = i5.ɵccf("calendar-header", i12.CalendarHeaderComponent, View_CalendarHeaderComponent_Host_0, { view: "view", metrics: "metrics", displayRecords: "displayRecords", displayRanges: "displayRanges", viewDate: "viewDate", locale: "locale" }, { viewChange: "viewChange", metricsChange: "metricsChange", displayRecordsChange: "displayRecordsChange", displayRangesChange: "displayRangesChange", viewDateChange: "viewDateChange" }, []);
export { CalendarHeaderComponentNgFactory as CalendarHeaderComponentNgFactory };
