var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { map, catchError } from 'rxjs/operators';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/observable/empty';
var DataService = /** @class */ (function (_super) {
    __extends(DataService, _super);
    function DataService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    DataService.prototype.updatePreferences = function (uri, data) {
        return this._patch(this.API_URL + uri, data);
    };
    /**
     * Retrieve all the data for a given record
     */
    DataService.prototype.getForRecordId = function (metric_ids, record_id, type) {
        var _this = this;
        var options = this.generateHeader();
        if ((metric_ids === undefined) || (metric_ids.length === 0)) {
            return Observable.of([]);
        }
        if (!type) {
            type = "record";
        }
        return this.http.get(this.API_URL + "api/" + "data/?datatype=" + metric_ids + "&" + type + "=" + record_id, { 'headers': options })
            .pipe(map(function (response) {
            if (response['length'] > 0) {
                return response;
            }
            else {
                return Observable.of([]);
            }
        }), catchError(function (err) { return _this.handleError(err); }));
    };
    /**
     * Retrieve data for data_type, start, end and user
     */
    DataService.prototype.getData = function (user, ids, start, end) {
        var _ids = ids.reduce(function (a, b) { return a.concat(b); }, []);
        var type = "";
        if (!_ids || _ids.length === 0) {
            return Observable.empty();
        } // Observable.empty<Response>()
        for (var i = 0; i < _ids.length; i++) {
            type += _ids[i];
            if (i < _ids.length - 1) {
                type += ",";
            }
        }
        var query = this.API_URL + "api/" + "data/?user=" + user +
            "&datatype=" + type +
            "&start=" + Math.floor(start) +
            "&end=" + Math.floor(end);
        return this._get(query);
    };
    return DataService;
}(HttpService));
export { DataService };
