var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { NotifierService } from 'angular-notifier';
/**
 * Records represent uploaded data.
 * When a user syncs her device a record is created to link together all the associated data that is recorded for that session.
 */
var RecordService = /** @class */ (function (_super) {
    __extends(RecordService, _super);
    function RecordService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    /**
     * Retrieve all the info for a given record
     */
    RecordService.prototype.get = function (id, datatypes) {
        var _datatypes = '';
        if (datatypes) {
            _datatypes = '?include_metrics=';
            var i = 0;
            for (var _i = 0, datatypes_1 = datatypes; _i < datatypes_1.length; _i++) {
                var datatype = datatypes_1[_i];
                _datatypes += datatype;
                if (i < datatypes.length - 1) {
                    _datatypes += ',';
                }
                i++;
            }
        }
        var query = this.API_URL + 'api/' + 'record/' + id + '/' + _datatypes;
        return this._get(query);
    };
    /**
     * Delete a record
     */
    RecordService.prototype.delete = function (id, keyToClear) {
        var query = this.API_URL + "api/" + "record/" + id + "/";
        return this._delete(query, keyToClear);
    };
    /**
     * Download the TCX, CSV or FIT format file of a record
     */
    RecordService.prototype.getFile = function (id, format) {
        if (!format)
            format = 'text/csv';
        var options = this.generateHeader(format);
        return this.http.get(this.API_URL + 'api/' + 'data/?record=' + id + '&datatype__in=19,33', { 'headers': options });
        // .map(response => { return response; });
    };
    RecordService.prototype.getFileWithDatatype = function (id, datatype__in, format) {
        if (!format)
            format = 'text/csv';
        var options = this.generateHeader(format);
        return this.http.get(this.API_URL + 'api/' + 'data/?record=' + id + '&datatype__in=' + datatype__in, { 'headers': options });
        // .map(response => { return response; });
    };
    RecordService.prototype.update = function (id, data) {
        var options = this.generateHeader();
        return this.http.patch(this.API_URL + 'api/' + 'record/' + id + '/', data, { 'headers': options })
            .map(function (response) {
        })
            .catch(function (error) {
            console.error(error);
            return Observable.throw(error.json());
        });
    };
    /**
     * Retrieve all the records the logged user can access to
     * If username is provided, the records are filtered by username
     *
     * All the params are optional
     *
     * @param {number} limit
     *  maximum number of records in the response.
     * @param {number} offset
     * @param {string} username
     *  (optional) username to filter the records
     * @param {string} order_by
     *  (optional) field by wich the records are sorted
     * @param {string} direction
     *  (optional) ascending or descending order
     * @param {number} start
     *  (optional) filter by range, start of the range
     * @param {number} end
     *  (optional) filter by range, end of the range
     * @param {number} duration
     *  (optional) filter by minimal duration
     *
     * @return
     *  List of records
     */
    RecordService.prototype.fetch = function (limit, offset, username, order_by, direction, start, end, label, duration) {
        if (!limit) {
            limit = 0;
        }
        if (!offset) {
            offset = 0;
        }
        // Filter by username
        var filter_by_username = '';
        if (username) {
            filter_by_username = '&user=' + username;
        }
        // Descending or ascending order
        var direction_q = '';
        if (direction) {
            direction_q = direction;
        }
        // Sorting
        var order_by_q = '';
        if (order_by) {
            var field = '';
            if (order_by === 'user') {
                field = '__username';
            }
            order_by_q = '&order_by=' + direction_q + order_by + field;
        }
        // time range only if start and end are provided
        var timerange = '';
        if (start && end) {
            timerange = '&start__lte=' + end + '&end__gte=' + start;
        }
        // Labels. Exist in HX ? TODO
        var _label = '';
        if (label) {
            _label = '&labels=' + label;
        }
        // Filter by duration. All records longer than the given duration
        var _duration = '';
        if (duration) {
            _duration = '&duration__gte=' + duration;
        }
        var query = this.API_URL + 'api/' + 'record/?minimal=1&limit=' + limit + '&include_metrics=44,12,149,71,1032&offset=' +
            offset + filter_by_username + order_by_q + timerange + _label + _duration;
        return this._get(query);
    };
    /**
     * Retrieve the next record for the usedId or any by default.
     *
     * @param {number} hxtime
     * @param {number} userId
     *
     * @return
     *  A record
     */
    RecordService.prototype.getNext = function (hxtime, userId) {
        var user = '';
        if (userId) {
            user = '&user=' + userId;
        }
        var query = this.API_URL + 'api/' + 'record/?limit=1' + user + '&order_by=start&start__gte=' + hxtime;
        return this._get(query);
    };
    /**
     * Retrieve the previous record for the usedId or any by default.
     *
     * @param {number} hxtime
     * @param {number} userId
     *
     * @return
     *  A record
     */
    RecordService.prototype.getPrevious = function (hxtime, userId) {
        var user = '';
        if (userId) {
            user = '&user=' + userId;
        }
        var query = this.API_URL + 'api/' + 'record/?limit=1' + user + '&order_by=-start&end__lte=' + hxtime;
        return this._get(query);
    };
    /**
     * Retrieve all the records in from a time period (start, end)
     *
     * @param {number} start
     * @param {number} end
     * @param {number} limit
     * @param {number} offset
     * @param {number} userId
     *
     * @return
     *  List of records
     */
    RecordService.prototype.fetchPeriod = function (start, end, limit, offset, userId) {
        if (!limit) {
            limit = 0;
        }
        if (!offset) {
            offset = 0;
        }
        var filterByUserId = '';
        if (userId) {
            filterByUserId = '&user=' + userId;
        }
        var query = this.API_URL + 'api/' +
            'record/?minimal=1&limit=' + limit + '&offset=' + offset +
            '&duration__gte=60' + '&start__lte=' + Math.ceil(end) + '&end__gte=' +
            Math.floor(start) + filterByUserId;
        return this._get(query);
    };
    /**
     * Retrieve the N last records
     *
     * @param {number} records
     * @param {number} offset
     * @param {number} userId
     *
     * @return
     *  List of N records
     */
    RecordService.prototype.fetchNLast = function (limit, offset, userId) {
        var query = this.API_URL + 'api/' + 'record/?minimal=1&limit=' +
            limit + '&offset=' + offset + '&order_by=-start&duration__gte=60&user=' + userId;
        return this._get(query);
    };
    RecordService.prototype.downloadResource = function (id, datatype__in, format) {
        var options = this.generateHeader(format);
        var url = this.API_URL + "api/" + "data/?record=" + id;
        if (format === 'text/csv') {
            url += "&datatype__in=" + datatype__in;
        }
        return this.http.get(url, { headers: options, responseType: 'blob' });
    };
    return RecordService;
}(HttpService));
export { RecordService };
