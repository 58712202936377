import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/operator/catch';

@Injectable()
export class CoachService extends HttpService {


    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }


    add(userAllowedEmail: string, userTargetId: number, keyToClear?: string): Observable<any> { // replace any to do
        const bundle = { 'user_allowed': userAllowedEmail, 'user_target': userTargetId, 'bundle': 1 };
        const query = this.API_URL + "api/bundle_permission/";
        return this._post(query, bundle, keyToClear);
    }

    accept(token: string) {
        const options = this.generateHeader();
        return this.http.put(this.API_URL + "api/" + "bundle_permission_request/" + token + "/", null, { headers : options})
            .map((response: Response) => {
            })
            .catch(error => { console.error(error); 
                throw new Error(error.message ? error.message : "An error occured when adding user");
            });
    }

}
