var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { ApiService, RangeService, RecordService } from '../../core/services';
import * as moment from 'moment/moment';
import { HxToolsDate, HxToolsTime } from '../../shared/hx-tools';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { SelectorService } from '../../shared/components/selector/selector.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { Store } from '@ngrx/store';
var HomeComponent = /** @class */ (function (_super) {
    __extends(HomeComponent, _super);
    function HomeComponent(store, recordService, apiService, eventAggregator, globalVariables, selectorService, rangeService, helpService) {
        var _this = _super.call(this, helpService) || this;
        _this.store = store;
        _this.recordService = recordService;
        _this.apiService = apiService;
        _this.eventAggregator = eventAggregator;
        _this.globalVariables = globalVariables;
        _this.selectorService = selectorService;
        _this.rangeService = rangeService;
        _this.helpService = helpService;
        _this.msgs = []; // Messages for growl
        _this.loading = true; // If data is being loaded
        _this.showRecords = true; // If records without activities associated are shown
        _this.boxes = []; // Selected views of the page
        _this.isInitiliazed = false;
        _this.maxDays = 5;
        _this.days_before_msg = 7; // max nbr of days without record before displaying msg.
        _this.totalDays = 0;
        _this.totatDaysWithRanges = 0;
        _this.moreTh5daysInactive = false;
        _this.announcements = [];
        _this.default_boxes = [{ "title": "Quick View", "displayed": true },
            { "title": "Timelines", "displayed": true }]; // Default selected views of the page (used if not in DB)
        _this.dateRanges = {
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 100 days': [moment().subtract(100, 'days'), moment()],
            'This year': [moment().startOf('year'), moment()],
        };
        /**
         * Actions performed when current subject changes
         *
         * @param {object} record Record object
         */
        _this.onMessageReceived = function (payload) {
            if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
                _this.__reset();
                _this.loadFirstTimelines();
            }
        };
        _this.dates = new Array();
        _this.rdates = new Array();
        _this.ranges = new Map();
        _this.records = new Map();
        store.select('selector').subscribe(function (_selector) {
            if (_selector.time_range === undefined || _selector.time_range === null) {
                _this._retrieveRecordsAndRanges(true);
            }
            else {
                var range = _this.dateRanges[_selector.time_range.label];
                _this.start = 256 * range[0].unix();
                _this.end = 256 * range[1].unix();
                _this._retrieveRecordsAndRanges(false);
                _this.selected = {
                    startDate: range[0],
                    endDate: range[1]
                };
            }
        });
        return _this;
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.getAnnouncements().subscribe(function (data) {
            _this.announcements = data.objects;
        });
        this.user = this.globalVariables.has('currentSubject') ?
            this.globalVariables.get('currentSubject') : this.globalVariables.get('currentUser');
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.MAIN__PARTIAL_SCREEN }));
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
        this._initTimeRange(); // TODO : Add condition data range already in memory
    };
    /**
   * Actions performed after view init
   * We generate the help boxes
   *
   * @param {object} record Record object
   */
    HomeComponent.prototype.ngAfterViewInit = function () {
        this.afterViewInit();
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.FSS__UPDATE
        }));
    };
    HomeComponent.prototype.displayMore = function () {
        this.maxDays += 5;
    };
    HomeComponent.prototype._initTimeRange = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).subscribe(function (data) {
            if (data.preferences) {
                if (data.preferences.time_range === undefined || data.preferences.time_range === null) {
                    _this._retrieveRecordsAndRanges(true);
                }
                else {
                    var range = _this.dateRanges[data.preferences.time_range.label];
                    _this.start = 256 * range[0].unix();
                    _this.end = 256 * range[1].unix();
                    _this._retrieveRecordsAndRanges(false);
                }
            }
        });
    };
    /**
    * Retrieve the records and the ranges for time range selected
    *
    * @param {boolean} lastRecordMonth  if the month of the last record is displayed
    */
    HomeComponent.prototype._retrieveRecordsAndRanges = function (lastRecordMonth) {
        var _this = this;
        this.maxDays = 5;
        this.loading = true;
        this.__reset(); // reset the range and record arrays
        var user = this.globalVariables.get('currentSubject'); // retrieve user currently selected (not necessarly the same as the user logged in)  
        if (user === undefined) { // If the current subject is not defined, the subject is the logged user
            user = this.globalVariables.get('currentUser');
        }
        var _start = Number.MAX_SAFE_INTEGER; // Start of the date range, unix time
        var _end = 0; // End of the date range, unix time   
        var dates = new Array();
        var rdates = new Array();
        var resourceUri = this.globalVariables.get("profileUri"); // URI for api call to retrieve user profile
        this.apiService.getFromUri(resourceUri).subscribe(function (data) {
            _this.__setDisplayedBoxes(data);
            var box_selectors = {
                'page': 'timeline',
                'boxes': (JSON.parse(JSON.stringify(_this.default_boxes))) //this.boxes
            };
            _this.selectorService.postBoxes(box_selectors);
        });
        this.recordService.fetchNLast(1, 0, user.id).flatMap(function (data) {
            if (lastRecordMonth) { // If lastRecordMonth, display month of the last record
                _this.__lastRecordMonth(data);
            }
            return _this.recordService.fetchPeriod(_this.start, // Retrieve all the records of the period selected
            _this.end, 0, 0, user.id);
        }).flatMap(function (data) {
            if (data.objects) {
                _this.records = new Map();
                for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                    var record = _a[_i];
                    _this.__addRecord(dates, record, _start, _end);
                }
            }
            _this.dates = dates.sort(function (a, b) {
                var x = a.startOfDay > b.startOfDay ? -1 : 1;
                return x;
            });
            _this.totalDays = _this.dates.length;
            return _this.rangeService.fetchPeriod(_this.end, _this.start, null, null, user.id);
            // Retrieving all the ranges included in the period defined by _start and _end
        }).subscribe(function (data) {
            if (data.objects) {
                _this.ranges = new Map();
                for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                    var range = _a[_i];
                    _this.__addRange(rdates, range);
                }
            }
            _this.rdates = rdates.sort(function (a, b) {
                var x = a.startOfDay > b.startOfDay ? -1 : 1;
                return x;
            });
            _this.totatDaysWithRanges = _this.ranges.size;
            _this.afterViewInit();
            _this.loading = false;
            _this.isInitiliazed = true; // Changes values just the first time 
        });
    };
    /**
    * Actions performed when data range has changed
    *
    * @param {object} $event Date range
    */
    HomeComponent.prototype.rangeClicked = function ($event) {
        /*    if ($event === undefined) {
              this._retrieveRecordsAndRanges(true);
            } else {
              let range = $event;
              this.start = 256 * range.startDate.startOf('day').unix();
              this.end = 256 * range.endDate.endOf('day').unix();
              this._retrieveRecordsAndRanges(false);
            }
            */
    };
    HomeComponent.prototype.__reset = function () {
        this.dates = new Array();
        this.ranges = new Map();
        this.records = new Map();
    };
    /**
     * Add a record to the list of the records to be displayed
     *
     * @param {object} record Record object
     */
    HomeComponent.prototype.__addRecord = function (dates, record, _start, _end) {
        var timestamp = HxToolsDate.toUnixTimestamp(record.start);
        var startOfTheDay = HxToolsDate.getStartOfDay(timestamp);
        var start = moment.unix(timestamp); // Record start in unix format
        var endTimestamp = HxToolsDate.toUnixTimestamp(record.end);
        var endOfTheDay = HxToolsDate.getEndOfDay(timestamp);
        var end = moment.unix(endTimestamp); // Record end in unix format
        if (endOfTheDay > _end)
            _end = endOfTheDay;
        if (startOfTheDay < _start)
            _start = startOfTheDay;
        var date = start.format("dddd, MMMM Do YYYY");
        if (!this.records.has(date)) {
            this.records.set(date, new Array());
            dates.push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay
            });
        }
        // If the record is overnight, then we split the record
        if (HxToolsTime.isOverNight(1000 * timestamp, 1000 * endTimestamp)) {
            this.records.get(date).push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
                "dstart": start.hour() + Number(start.minutes()) / 60, "dend": 24,
                "recordId": record.id, "highlighted": false,
                "_start": record.start, "_end": record.end
            });
            var dateDayAfter = start.add(1, 'days').format("dddd, MMMM Do YYYY");
            if (!this.records.has(dateDayAfter)) {
                this.records.set(dateDayAfter, new Array());
                dates.push({
                    "date": dateDayAfter,
                    "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60
                });
            }
            this.records.get(dateDayAfter).push({
                "date": dateDayAfter,
                "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60,
                "dstart": 0, "dend": end.hour() + Number(end.minutes()) / 60,
                "recordId": record.id, "highlighted": false,
                "_start": record.start, "_end": record.end
            });
        }
        else {
            this.records.get(date).push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
                "dstart": start.hour() + Number(start.minutes()) / 60, "dend": end.hour() + Number(end.minutes()) / 60,
                "recordId": record.id, "highlighted": false,
                "_start": record.start, "_end": record.end
            });
        }
    };
    /**
     * Add a range to the list of the ranges to be displayed
     *
     * @param {object} range Range object
     */
    HomeComponent.prototype.__addRange = function (rdates, range) {
        var startTimestamp = HxToolsDate.toUnixTimestamp(range.start);
        var startOfTheDay = HxToolsDate.getStartOfDay(startTimestamp); // unix timestamp start of the day of the range
        var start = moment.unix(startTimestamp); // unix timestamp start of the range
        var endTimestamp = HxToolsDate.toUnixTimestamp(range.end);
        var endOfTheDay = HxToolsDate.getEndOfDay(startTimestamp);
        var end = moment.unix(endTimestamp);
        var date = start.format("dddd, MMMM Do YYYY");
        if (!this.ranges.has(date)) { //  If no range yet for that date
            this.ranges.set(date, new Array());
            rdates.push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay
            });
        }
        // If the range is overnight, then we split it
        if (HxToolsTime.isOverNight(1000 * startTimestamp, 1000 * endTimestamp)) {
            this.ranges.get(date).push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
                "dstart": start.hour() + Number(start.minutes()) / 60, "dend": 24,
                "rangeId": range.id, "highlighted": false,
                "activity": range.context.activitytype,
                "name": range.name
            });
            var dateDayAfter = start.add(1, 'days').format("dddd, MMMM Do YYYY"); // Next day
            if (!this.ranges.has(dateDayAfter)) { //  If no range yet for that date (next day)
                this.ranges.set(dateDayAfter, new Array());
                rdates.push({
                    "date": dateDayAfter,
                    "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60
                });
            }
            this.ranges.get(dateDayAfter).push({
                "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60,
                "dstart": 0, "dend": end.hour() + Number(end.minutes()) / 60,
                "rangeId": range.id, "highlighted": false,
                "activity": range.context.activitytype,
                "name": range.name
            });
        }
        else { // If the range is during the day
            this.ranges.get(date).push({
                "date": date,
                "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
                "dstart": start.hour() + Number(start.minutes()) / 60, "dend": end.hour() + Number(end.minutes()) / 60,
                "rangeId": range.id, "highlighted": false,
                "activity": range.context.activitytype,
                "name": range.name
            });
        }
    };
    /**
     * Update selected period in the case the month of the last record
     * is to be displayed
     *
     * @param {array} records List of records sorted in anti-chronological order
     */
    HomeComponent.prototype.__lastRecordMonth = function (records) {
        var mostRecentRecordStart;
        this.lastRecord = records.objects[0]; // To display the information relative to the last report
        this.moreTh5daysInactive = false;
        if (this.lastRecord && (Math.floor(Date.now() / 1000) - this.lastRecord.start / 256 > this.days_before_msg * 24 * 60 * 60)) {
            this.moreTh5daysInactive = true;
        }
        if (records.objects && records.objects[0]) { // Check if at least one record 
            mostRecentRecordStart = records.objects[0].start; // Retrieves start time of the last record
            // Converts start time to unix timestamp
            var timestamp = HxToolsDate.toUnixTimestamp(mostRecentRecordStart);
            // Updates start and end
            this.start = 256 * HxToolsDate.getStartOfMonth(timestamp);
            this.end = 256 * HxToolsDate.getEndOfMonth(timestamp);
            // gets timestamps of the first and last day of the month
            var firstDay = moment(HxToolsDate.getStartOfMonth(timestamp), "X");
            var lastDay = moment(HxToolsDate.getEndOfMonth(timestamp), "X");
            this.selected = {
                startDate: moment(new Date(firstDay.year(), firstDay.month(), firstDay.date(), 5, 0, 0)),
                endDate: moment(new Date(lastDay.year(), lastDay.month(), lastDay.date(), 5, 0, 0))
            };
            // Add 'Last record' to the calendar
            this.dateRanges['Last Record'] = [moment(1000 * HxToolsDate.getStartOfMonth(timestamp)), moment(1000 * HxToolsDate.getEndOfMonth(timestamp))];
        }
        else { // In the case of new user
            var firstDay = moment().subtract(1, 'month');
            var lastDay = moment();
            this.start = firstDay.unix() * 256;
            this.end = lastDay.unix() * 256;
            this.selected = {
                startDate: moment(new Date(firstDay.year(), firstDay.month(), firstDay.date(), 5, 0, 0)),
                endDate: moment(new Date(lastDay.year(), lastDay.month(), lastDay.date(), 5, 0, 0))
            };
        }
    };
    HomeComponent.prototype.__setDisplayedBoxes = function (data) {
        if (data.preferences && data.preferences.timeline && data.preferences.timeline.length > 0) {
            // todo : check if tree is valid
            var i = 0;
            var valid = true;
            for (var _i = 0, _a = data.preferences.timeline; _i < _a.length; _i++) {
                var box = _a[_i];
                if (!this.default_boxes[i]) {
                    valid = false;
                }
                else if (box.title !== this.default_boxes[i].title) {
                    valid = false;
                }
                i++;
            }
            if (valid) {
                this.boxes = data.preferences.timeline;
            }
            else {
                this.boxes = this.default_boxes;
            }
        }
        else {
            this.boxes = this.default_boxes;
        }
    };
    /**
     * Retrieve the last 10 records and ranges on the same period of time.
     */
    HomeComponent.prototype.loadFirstTimelines = function () {
        this.loading = true;
        this.user = this.globalVariables.get('currentSubject');
        this._retrieveRecordsAndRanges(true); // Display the first timelines
    };
    /**
     * Actions to perform when the component is distroyed
     */
    HomeComponent.prototype.ngOnDestroy = function () {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.SELECTOR__HIDE }));
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    };
    return HomeComponent;
}(HelperComponent));
export { HomeComponent };
