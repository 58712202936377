/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/components/metric/metric-one-status/metric-one-status.component.ngfactory";
import * as i2 from "../../shared/components/metric/metric-one-status/metric-one-status.component";
import * as i3 from "../../shared/components/metric/metric-one-status/metric-one-status.service";
import * as i4 from "@angular/common";
import * as i5 from "./user-fitness.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../core/services/api.service";
import * as i8 from "../../shared/components/alert/alert.service";
var styles_UserFitnessComponent = [];
var RenderType_UserFitnessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserFitnessComponent, data: {} });
export { RenderType_UserFitnessComponent as RenderType_UserFitnessComponent };
function View_UserFitnessComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "metric-one-status", [], null, [[null, "titleChange"], [null, "valueChange"], [null, "statusChange"], [null, "unitChange"]], function (_v, en, $event) { var ad = true; if (("titleChange" === en)) {
        var pd_0 = ((_v.context.$implicit.title = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } if (("statusChange" === en)) {
        var pd_2 = ((_v.context.$implicit.status = $event) !== false);
        ad = (pd_2 && ad);
    } if (("unitChange" === en)) {
        var pd_3 = ((_v.context.$implicit.unit = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_MetricOneStatusComponent_0, i1.RenderType_MetricOneStatusComponent)), i0.ɵdid(2, 114688, null, 0, i2.MetricOneStatusComponent, [i3.MetricOneStatusService], { title: [0, "title"], value: [1, "value"], status: [2, "status"], unit: [3, "unit"], origin: [4, "origin"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.title; var currVal_2 = _v.context.$implicit.value; var currVal_3 = _v.context.$implicit.status; var currVal_4 = _v.context.$implicit.unit; var currVal_5 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.origin, ""); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "hx-u-1-", _v.parent.context.$implicit.length, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_UserFitnessComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFitnessComponent_2)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserFitnessComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFitnessComponent_1)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kpis; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserFitnessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-fitness", [], null, null, null, View_UserFitnessComponent_0, RenderType_UserFitnessComponent)), i0.ɵdid(1, 114688, null, 0, i5.UserFitnessComponent, [i6.FormBuilder, i7.ApiService, i8.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserFitnessComponentNgFactory = i0.ɵccf("user-fitness", i5.UserFitnessComponent, View_UserFitnessComponent_Host_0, { profile_id: "profile_id" }, {}, []);
export { UserFitnessComponentNgFactory as UserFitnessComponentNgFactory };
