var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Import from current module
import { ApiQuery, ApiService, CoachService } from '../../core/services';
import { AddCoachService } from './coach/add-coach.service';
import { AddFriendService } from './friend/add-friend.service';
import { AddUserService } from './user/add-user.service';
import { ConfirmationService } from '../../shared/components/confirmation';
import { AlertService } from '../../shared/components/alert/alert.service';
import { AddAthleteService } from './athlete/add-athlete.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { NotifierService } from 'angular-notifier';
var RelationshipTableComponent = /** @class */ (function (_super) {
    __extends(RelationshipTableComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function RelationshipTableComponent(coachService, addCoachService, notifierService, globalVariables, helpService, apiService, addFriendService, alertService, router, addAthleteService, addUserService, confirmationService) {
        var _this = _super.call(this, helpService) || this;
        _this.coachService = coachService;
        _this.addCoachService = addCoachService;
        _this.notifierService = notifierService;
        _this.globalVariables = globalVariables;
        _this.helpService = helpService;
        _this.apiService = apiService;
        _this.addFriendService = addFriendService;
        _this.alertService = alertService;
        _this.router = router;
        _this.addAthleteService = addAthleteService;
        _this.addUserService = addUserService;
        _this.confirmationService = confirmationService;
        _this.loadingAthletes = true;
        _this.loadingCoaches = true;
        _this.loadingFriends = true;
        _this.loadingPendingRequests = true;
        /**
     * Table parameters
     */
        _this.maxItemsPerPage = 20;
        _this.currentPage = 1;
        _this.offset = 0;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.selection = "friends";
        _this.notifier = notifierService;
        _this.addCoachService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === "save") {
                var userId_1 = +_this.globalVariables.get("currentUserId");
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to add this coach?',
                    header: 'Confirmation',
                    icon: 'fa fa-add',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        _this.coachService.add(task.email, userId_1, "bundle_permission").flatMap(function (res) {
                            _this.msgs = [];
                            _this.msgs.push({
                                severity: 'success',
                                summary: 'Confirmed',
                                detail: task.email + ' has been added as your coach'
                            });
                            return _this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId_1 });
                            //return this.coachService.fetch(userId);
                        }).subscribe(function (data) {
                            _this.coaches = data.objects;
                            _this.loadingCoaches = false;
                        }, function (err) {
                            _this.showError(err);
                        });
                    }
                });
            }
        });
        _this.addAthleteService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === "save") {
                var userId_2 = +_this.globalVariables.get("currentUserId");
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to add this user?',
                    header: 'Confirmation',
                    icon: 'fa fa-add',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        _this.apiService.addUser(task.email, task.first_name, task.last_name).subscribe(function (res) {
                            _this.msgs = [];
                            _this.msgs.push({
                                severity: 'success',
                                summary: 'Confirmed',
                                detail: 'An email has been sent to ' + task.email + '.'
                            });
                            _this.retrieveUsers(userId_2);
                            _this.pendingRequests = new Array();
                            _this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(function (data) {
                                //this.coachService.fetchPending(userId).subscribe(data => {
                                _this.pendingRequests = data.objects;
                            });
                        }, function (err) {
                            _this.showError(err);
                        });
                    }
                });
            }
        });
        _this.addUserService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === "save") {
                var userId_3 = +_this.globalVariables.get("currentUserId");
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to add this user?',
                    header: 'Confirmation',
                    icon: 'fa fa-add',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        _this.apiService.addSubject(userId_3, task.email).subscribe(function (res) {
                            _this.msgs = [];
                            _this.msgs.push({
                                severity: 'success',
                                summary: 'Confirmed',
                                detail: 'An email has been sent to ' + task.email + '.'
                            });
                            _this.retrieveUsers(userId_3);
                            _this.pendingRequests = new Array();
                            _this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(function (data) {
                                //this.coachService.fetchPending(userId).subscribe(data => {
                                _this.pendingRequests = data.objects;
                            });
                        }, function (err) {
                            _this.addAthlete(task.email);
                            // this.showError(err); TODO : server must provide another error code than 404...
                        });
                    }
                });
            }
        });
        _this.addFriendService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === "save") {
                var userId_4 = +_this.globalVariables.get("currentUserId");
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to add this friend?',
                    header: 'Confirmation',
                    icon: 'fa fa-add',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        var bundle = { 'user_allowed': task.email, 'user_target': userId_4, 'bundle': 2 };
                        _this.apiService.postQuery(ApiQuery.add_friend, bundle, null, 'bundle_permission').flatMap(function (res) {
                            // this.friendService.add(task.email, userId, "bundle_permission").flatMap(res => {
                            _this.msgs = [];
                            _this.msgs.push({
                                severity: 'success',
                                summary: 'Confirmed',
                                detail: task.email + ' has been added as your friend'
                            });
                            return _this.apiService.getQuery(ApiQuery.all_friends_for_user_id);
                            //return this.friendService.fetch(userId);
                        }).subscribe(function (data) {
                            _this.friends = new Array();
                            var userId = +_this.globalVariables.get("currentUserId");
                            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                                var friend = _a[_i];
                                if (friend.user_allowed.id === userId) {
                                    _this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                                }
                                else {
                                    _this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                                }
                            }
                            _this.loadingCoaches = false;
                        }, function (err) {
                            _this.showError(err);
                        });
                    }
                });
            }
        });
        return _this;
    }
    RelationshipTableComponent.prototype.showError = function (err) {
        var message = "";
        if (err.error && err.error.errors) {
            for (var property in err.error.errors) {
                if ((err.error.errors.hasOwnProperty(property)) && (err.error.errors[property][0])) {
                    message += err.error.errors[property][0];
                }
            }
        }
        if (message !== "") {
            this.notifier.notify('error', message);
        }
    };
    RelationshipTableComponent.prototype.selectMyFriends = function () {
        this.selection = "friends";
    };
    RelationshipTableComponent.prototype.selectMyCoaches = function () {
        this.selection = "coaches";
    };
    RelationshipTableComponent.prototype.selectMyAthletes = function () {
        this.selection = "athletes";
    };
    RelationshipTableComponent.prototype.selectPendingRequests = function () {
        this.selection = "pendingRequests";
    };
    /**
 * Display the previous page
 */
    RelationshipTableComponent.prototype.nextPage = function () {
        this.currentPage++;
        this.getAll();
    };
    /**
     * Display the next page
     */
    RelationshipTableComponent.prototype.previousPage = function () {
        this.currentPage--;
        this.getAll();
    };
    /**
     * Display the page
     */
    RelationshipTableComponent.prototype.changeToPage = function (page) {
        this.currentPage = page;
        this.getAll();
    };
    /**
     * On init
     */
    RelationshipTableComponent.prototype.ngOnInit = function () {
        this.getAll();
    };
    RelationshipTableComponent.prototype.retrieveUsers = function (userId) {
        var _this = this;
        var offset = (this.currentPage - 1) * this.maxItemsPerPage;
        this.apiService.getQuery(ApiQuery.all_subjects_for_user_id_limit_offset, { user_id: userId, limit: this.maxItemsPerPage, offset: offset }).subscribe(function (data) {
            //this.subjectService.fetch(userId).subscribe(data => {
            var totalRecordsCount = data.meta.total_count;
            _this.totalPages = Math.ceil(totalRecordsCount / _this.maxItemsPerPage);
            // Beginning - pagination
            _this.pages = [];
            if (_this.currentPage === 1) {
                _this.pages.push(1);
                if (_this.maxItemsPerPage < totalRecordsCount) {
                    _this.pages.push(2);
                }
                if (_this.maxItemsPerPage * 2 < totalRecordsCount) {
                    _this.pages.push(3);
                }
            }
            if (_this.currentPage >= 2) {
                if (_this.currentPage - 2 > 0)
                    _this.pages.push(_this.currentPage - 2);
                _this.pages.push(_this.currentPage - 1);
                _this.pages.push(_this.currentPage);
                if (_this.maxItemsPerPage * _this.currentPage < totalRecordsCount) {
                    _this.pages.push(_this.currentPage + 1);
                }
            }
            // End - pagination
            _this.athletes = data.objects;
            _this.loadingAthletes = false;
        });
    };
    RelationshipTableComponent.prototype.getAll = function () {
        var _this = this;
        this.coaches = new Array();
        var userId = +this.globalVariables.get('currentUserId');
        this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId }).subscribe(function (data) {
            //this.coachService.fetch(userId).subscribe(data => {
            _this.coaches = data.objects;
            _this.loadingCoaches = false;
        });
        this.athletes = new Array();
        this.retrieveUsers(userId);
        this.friends = new Array();
        this.apiService.getQuery(ApiQuery.all_friends_for_user_id).subscribe(function (data) {
            //this.friendService.fetch(userId).subscribe(data => {
            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                var friend = _a[_i];
                if (friend.user_allowed.id === userId) {
                    _this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                }
                else {
                    _this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                }
            }
            _this.loadingFriends = false;
        });
        this.pendingRequests = new Array();
        this.apiService.getQuery(ApiQuery.all_pending_relationships_requests).subscribe(function (data) {
            //this.coachService.fetchPending(userId).subscribe(data => {
            _this.pendingRequests = data.objects;
            _this.loadingPendingRequests = false;
        });
    };
    RelationshipTableComponent.prototype.revokeCoach = function (resourceUri) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: function () {
                _this.apiService.deleteFromURI(resourceUri, "bundle_permission").flatMap(function (res) {
                    _this.msgs = [];
                    _this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    var userId = +_this.globalVariables.get('currentUserId');
                    return _this.apiService.getQuery(ApiQuery.all_coaches_for_user_id, { user_id: userId });
                    //return this.coachService.fetch(userId);
                }).subscribe(function (data) {
                    _this.coaches = data.objects;
                    _this.loadingCoaches = false;
                });
            }
        });
    };
    RelationshipTableComponent.prototype.revokeAthlete = function (resourceUri) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: function () {
                _this.apiService.deleteFromURI(resourceUri, 'bundle_permission').subscribe(function (res) {
                    //this.subjectService.delete(resourceUri).subscribe(res => {
                    _this.msgs = [];
                    _this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    var userId = +_this.globalVariables.get('currentUserId');
                    _this.retrieveUsers(userId);
                });
            }
        });
    };
    RelationshipTableComponent.prototype.revokeFriend = function (resourceUri) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to revoke it?',
            header: 'Confirmation',
            icon: 'fa fa-delete',
            rejectVisible: true,
            acceptVisible: true,
            accept: function () {
                _this.apiService.deleteFromURI(resourceUri, 'bundle_permission').subscribe(function (res) {
                    _this.msgs = [];
                    _this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The revocation has been successful'
                    });
                    var userId = +_this.globalVariables.get('currentUserId');
                    _this.apiService.getQuery(ApiQuery.all_friends_for_user_id, { user_id: userId }).subscribe(function (data) {
                        //this.friendService.fetch(userId).subscribe(data => {
                        _this.friends = new Array();
                        var userId = +_this.globalVariables.get("currentUserId");
                        for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                            var friend = _a[_i];
                            if (friend.user_allowed.id === userId) {
                                _this.friends.push({ 'friend': friend.user_target, 'resource_uri': friend.resource_uri });
                            }
                            else {
                                _this.friends.push({ 'friend': friend.user_allowed, 'resource_uri': friend.resource_uri });
                            }
                        }
                        _this.loadingFriends = false;
                    });
                });
            }
        });
    };
    RelationshipTableComponent.prototype.addCoach = function () {
        this.addCoachService.confirm({
            message: ' ',
            header: 'Share your data with an admin',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: function () { }
        });
        /*
            const link = ['/add-coach'];
     
            this.router.navigate(link);
            */
    };
    RelationshipTableComponent.prototype.addAthlete = function (email) {
        this.addAthleteService.confirm({
            message: ' ',
            header: 'Add a new user',
            icon: '',
            kpi: 'test',
            unit: '',
            email: email,
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: function () { }
        });
    };
    RelationshipTableComponent.prototype.addUser = function () {
        this.addUserService.confirm({
            message: ' ',
            header: 'Add a new user',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: function () { }
        });
    };
    RelationshipTableComponent.prototype.addFriend = function () {
        this.addFriendService.confirm({
            message: ' ',
            header: 'Add a new friend/relative',
            icon: '',
            kpi: 'test',
            unit: '',
            resource_uri: null,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            accept: function () { }
        });
    };
    RelationshipTableComponent.prototype.activeUser = function (id) {
        this.globalVariables.set('currentUserId', id + "");
    };
    return RelationshipTableComponent;
}(HelperComponent));
export { RelationshipTableComponent };
