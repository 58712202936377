import { Component, Input } from '@angular/core';
import { CalendarEvent } from '../../calendar-utils';

@Component({
  selector: 'app-calendar-event-actions',
  templateUrl: 'calendar-event-actions.component.html'
})
export class CalendarEventActionsComponent {
  @Input() event: CalendarEvent;
}
