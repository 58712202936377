var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/operator/catch';
var CoachService = /** @class */ (function (_super) {
    __extends(CoachService, _super);
    function CoachService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    CoachService.prototype.add = function (userAllowedEmail, userTargetId, keyToClear) {
        var bundle = { 'user_allowed': userAllowedEmail, 'user_target': userTargetId, 'bundle': 1 };
        var query = this.API_URL + "api/bundle_permission/";
        return this._post(query, bundle, keyToClear);
    };
    CoachService.prototype.accept = function (token) {
        var options = this.generateHeader();
        return this.http.put(this.API_URL + "api/" + "bundle_permission_request/" + token + "/", null, { headers: options })
            .map(function (response) {
        })
            .catch(function (error) {
            console.error(error);
            throw new Error(error.message ? error.message : "An error occured when adding user");
        });
    };
    return CoachService;
}(HttpService));
export { CoachService };
