/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./growl.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./growl.component";
import * as i4 from "../../../core/dom/domhandler";
var styles_GrowlComponent = [i0.styles];
var RenderType_GrowlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GrowlComponent, data: {} });
export { RenderType_GrowlComponent as RenderType_GrowlComponent };
function View_GrowlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["msgel", 1]], null, 12, "div", [["aria-live", "polite"], ["class", "hx-ui-growl-item-container ui-state-highlight ui-corner-all hx-ui-shadow"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "hx-ui-growl-message-info": 0, "hx-ui-growl-message-warn": 1, "hx-ui-growl-message-error": 2, "hx-ui-growl-message-success": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "hx-ui-growl-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "hx-ui-growl-icon-close fa fa-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.$implicit, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "hx-ui-growl-image fa fa-2x"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "fa-info-circle": 0, "fa-exclamation-circle": 1, "fa-close": 2, "fa-check": 3 }), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "hx-ui-growl-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "hx-ui-growl-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["style", "clear: both;"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "hx-ui-growl-item-container ui-state-highlight ui-corner-all hx-ui-shadow"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.severity === "info"), (_v.context.$implicit.severity === "warn"), (_v.context.$implicit.severity === "error"), (_v.context.$implicit.severity === "success")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "hx-ui-growl-image fa fa-2x"; var currVal_3 = _ck(_v, 7, 0, (_v.context.$implicit.severity === "info"), (_v.context.$implicit.severity === "warn"), (_v.context.$implicit.severity === "error"), (_v.context.$implicit.severity === "success")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.summary; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.detail; _ck(_v, 11, 0, currVal_5); }); }
export function View_GrowlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerViewChild: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 4, "div", [], [[4, "zIndex", null]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GrowlComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.styleClass; var currVal_2 = "hx-ui-growl hx-ui-widget"; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.style; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.value; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zIndex; _ck(_v, 1, 0, currVal_0); }); }
export function View_GrowlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-growl", [], null, null, null, View_GrowlComponent_0, RenderType_GrowlComponent)), i1.ɵdid(1, 4636672, null, 0, i3.GrowlComponent, [i1.ElementRef, i4.DomHandler, i1.IterableDiffers], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GrowlComponentNgFactory = i1.ɵccf("app-growl", i3.GrowlComponent, View_GrowlComponent_Host_0, { sticky: "sticky", life: "life", value: "value", style: "style", styleClass: "styleClass" }, {}, []);
export { GrowlComponentNgFactory as GrowlComponentNgFactory };
