<div class="hx-ui-dialog" [style.display]="visible ? 'block' : 'none'" [style.width.px]="width" [style.height.px]="height"
    (mousedown)="moveOnTop()">

    <div class="hx-ui-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top">
        <span class="hx-ui-dialog-title" *ngIf="header">{{header}}</span>
        <a *ngIf="closable" [ngClass]="{'hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all':true}" href="#" role="button"
            (click)="hide($event)">
            <span class="hx-icon hx-icon-times"></span>
            </a>
    </div>


    <div class="hx-ui-dialog-content hx-ui-widget-content">
        <div class="hx-form hx-form-aligned">

            <div class="blockquote">
                The user does not exist. Do you want to create the user ? If so, an email will be sent to the email address. The person will have to fill in a form to actually create the account. Once the account is created, you will be able to view and edit the new user's data. You can remove any user at any time.
            </div>

            <div class="formErrors" *ngIf="emailField.errors && emailField.errors.pattern || firstNameField.errors && firstNameField.errors.pattern || lastNameField.errors && lastNameField.errors.pattern">
                <span *ngIf="emailField.errors && emailField.errors.pattern">
                Email is invalid
                </span>
                <span *ngIf="firstNameField.errors && firstNameField.errors.pattern">
                Firstname must only contains letters
                </span>
                <span *ngIf="lastNameField.errors && lastNameField.errors.pattern">
                Lastname must only contains letters
                </span>
            </div>

            <div class="hx-control-group input_with_appended_unit">
                <label>Firstname*</label>
                <input type="text" size="30" #firstNameField="ngModel" pattern="^[0-9a-zA-Zàáâãäçèéêëìíîïñòóôõöšùúûüýÿž \-]+$" [(ngModel)]="first_name">
            </div>
            
            <div class="hx-control-group input_with_appended_unit">
                <label>Lastname*</label>
                <input type="text" size="30" #lastNameField="ngModel" pattern="^[0-9a-zA-Záéíóú \-]+$" [(ngModel)]="last_name">
            </div>

            <div class="hx-control-group input_with_appended_unit">
                <label>Email*</label>
                <input type="email" [(ngModel)]="email" #emailField="ngModel" size="30" pattern="^\w+([\.-]?\w+)*(\+[0-9a-zA-Z]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            </div>

            <div class="formNotes">
                * mandatory fields
            </div>

        </div>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <ng-content select="p-footer"></ng-content>
    </div>

    <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
        <button type="button" class="button" (click)="reject()" *ngIf="rejectVisible">
            <i class="hx-icon hx-icon-times"></i> I do not want to create the user
        </button>
        <button type="button" class="button" (click)="accept()" *ngIf="acceptVisible" [disabled]="!email || !first_name || !last_name || emailField.errors && emailField.errors.pattern">
            <i class="hx-icon hx-icon-check"></i> Send a request to create the user
        </button>
    </div>

    </div>