import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ApiService, ApiQuery } from '../../../core/services';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { GlobalVariables } from '../../../core/services/global.variables';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { BoxesSelector } from '../../../core/boxes-selector/models/boxes-selector';
import { ToggleBox } from '../../../core/boxes-selector/actions/boxes-selector.actions';


@Component({
    selector: 'app-selector',
    templateUrl: './selector.component.html',
    styleUrls: ['./selector.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectorComponent implements OnInit, OnDestroy {

    public subjects: Array<any>;
    public currentSubjectId = null;
    page: string;
    boxes = new Array<any>();
    displayed = false;

    selector: BoxesSelector;

    constructor(private zone: NgZone,
        private store: Store<AppState>,
        private apiService: ApiService,
        private globalVariables: GlobalVariables,
        private eventAggregator: EventAggregator) {

        store.select('selector').subscribe(_selector => {
            this.zone.run(() => {
                console.log("=======> 5* (we receive new state)");
                
                this.selector = _selector.box_views;
                console.log(this.selector);
            });
        });
    }

    /**
     * Toggle box view
     * @param boxId
     */
    toggleBox(label_box: string) {
        console.log("======> 1");
        console.log(this.selector.boxes);
        let box = this.selector.boxes.get(label_box);
        console.log("======> 2");
        console.log(box);
        if (box !== undefined) this.store.dispatch(new ToggleBox(box));
    }


    ngOnDestroy() {
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    }


    ngOnInit(): void {
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
    }

    private onMessageReceived = (payload: MessageSentEventPayload) => {
        if (payload.object.msg === HxEvent.FSS__UPDATE) {
            this.apiService.getQuery(ApiQuery.all_users_for_user_id).subscribe(data => {
                this.subjects = data.objects;
                this.currentSubjectId = this.globalVariables.get('currentSubjectId');
            });
        } else if (payload.object.msg === HxEvent.LOGOUT) {
            this.subjects = null;
            this.currentSubjectId = null;
            // Current subject has been changed from another component
        } else if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
            this.currentSubjectId = this.globalVariables.get('currentSubjectId');
        } else if (payload.object.msg === HxEvent.SELECTOR__DISPLAY) {
            this.displayed = true;
        } else if (payload.object.msg === HxEvent.SELECTOR__HIDE) {
            this.displayed = false;
        }
    }

}
