import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({ name: 'round2dec' })
export class Round2decPipe implements PipeTransform {
  transform(str: string): number {
    if (!str) return undefined;
    if (!Number(str)) return undefined;
    return Math.round(parseFloat(str) * 100) / 100;
  }
}

@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform {
  transform(str: string): number {
    if (!str) return undefined;
    if (!Number(str)) return undefined;
    return Math.round(parseFloat(str));
  }
}

@Pipe({ name: 'roundWithUnit' })
export class RoundPipeWithUnit implements PipeTransform {
  transform(val: number, unit: any): string {
    if (!val) return "-";
    if (!Number(val)) return "-";
    //let unit_si = unit.si_short;
    let value: string = Math.round(val) + "";
    if ((unit.si_short === "m") && (val >= 1000)) {
      value = (Math.round(val / 10) / 100) + "";
      //unit_si = "km";
    } else if (unit.si_short === "s") {
      let duration = moment.duration(val, 'seconds');
      moment.relativeTimeThreshold('ss', 3);
      moment.relativeTimeThreshold('s', 59);
      moment.relativeTimeThreshold('m', 59);
      moment.relativeTimeThreshold('h', 24);
      //value = duration.humanize();


      value = "";
      //if (duration.hours() !== 0) {
        if (duration.hours() < 10) { value += "0"; }
        value += duration.hours() + ":";
      //}
      //if (duration.hours() !== 0 || duration.minutes() !== 0) {
        if (duration.minutes() < 10) { value += "0"; }
        value += duration.minutes() + ":";
      //}
      if (duration.seconds() < 10) { value += "0"; }
      value += duration.seconds();



      /* value = value.replace(/minutes/gi, "m");
       value = value.replace(/hours/gi, "h");
       value = value.replace(/an hour/gi, "1 h");
       value = value.replace(/secondes/gi, "s");*/
    }
    return value;
  }
}

@Pipe({ name: 'transformUnit' })
export class TransformUnitPipe implements PipeTransform {
  transform(val: number, unit: any): string {
    if (!val) return "-";
    if (!Number(val)) return "-";
    let unit_si = unit.si_short;
    if ((unit.si_short === "m") && (val >= 1000)) {
      return "km";
    }
    if (unit_si === "Step") {
      return null;
    }
    if (unit_si === "s") {
      return null;
    }
    return unit.si_short;
  }
}

/*
@Pipe({ name: 'transformUnit' })
export class TranformUnitPipe implements PipeTransform {
  transform(str: string): string {
    if (str === "Step") {
      return null;
    } else if (str === "s") {
      return null;
    }
    return str;
  }
}
*/

@Pipe({ name: 'toIcon' })
export class ToIconPipe implements PipeTransform {
  transform(str: string): string {
    if (str === "Step") {
      return "steps";
    } else if (str === "s") {
      return "clock";
    } else if (str === "m") {
      return "distance";
    } else if (str === "Cal") {
      return "energy";
    } else if (str === "bpm") {
      return "heart-rate";
    }
    return str;
  }
}
