import { Component, Directive, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { HelpService } from '../../../shared/components/help/help.service';


@Directive({ selector: 'section' })
export class Section {
  @Input() id: string;
}

@Component({
  selector: 'app-helper',
  template: ``
})
export class HelperComponent {

  helps = [];

  //@ViewChildren(Section) sections: QueryList<Section>;
  @ViewChildren(Section, { read: ElementRef }) sections: QueryList<ElementRef>;

  afterViewInit() {

    this.sections.forEach(_section => {

      this.helps[_section.nativeElement.id] = false; 
    });

  }

  constructor(public helpService: HelpService) { }

  openModal(id: string) {

    if (!this.helps[id]) {

      this.helps[id] = true;
      let top = '0px';

      let section = this.sections.find(_section => _section.nativeElement.id === id);
      let _top = 0 + section.nativeElement.getBoundingClientRect().top + window.scrollY;
      top = _top + 'px';
      this.helpService.open(id, top);

    } else {
      this.helps[id] = false;
      this.helpService.close(id);
    }
  }

  closeModal(id: string) {
    this.helpService.close(id);
  }


}
