/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./metric-small.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./metric-small.component";
var styles_MetricSmallComponent = [i0.styles];
var RenderType_MetricSmallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MetricSmallComponent, data: {} });
export { RenderType_MetricSmallComponent as RenderType_MetricSmallComponent };
export function View_MetricSmallComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "hx-small-metric"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "metric-box metric-box-range "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "hx-g metric-box-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "metric-box-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["style", "font-size:14px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["ng-class", "headerIcon"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["style", "padding: 5px;"]], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(12, { "hx-small-font-metric": 0 }), (_l()(), i1.ɵted(13, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 12, 0, (_co.title === "Duration")); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "hx-icon hx-icon-", _co.icon, ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.unit ? (("(" + _co.unit) + ")") : ""); _ck(_v, 9, 0, currVal_2); var currVal_4 = _co.value; _ck(_v, 13, 0, currVal_4); }); }
export function View_MetricSmallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "metric-small", [], null, null, null, View_MetricSmallComponent_0, RenderType_MetricSmallComponent)), i1.ɵdid(1, 114688, null, 0, i3.MetricSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MetricSmallComponentNgFactory = i1.ɵccf("metric-small", i3.MetricSmallComponent, View_MetricSmallComponent_Host_0, { value: "value", icon: "icon", title: "title", unit: "unit", prefix: "prefix" }, {}, []);
export { MetricSmallComponentNgFactory as MetricSmallComponentNgFactory };
