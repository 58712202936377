import { Component, OnInit, Input } from '@angular/core';
import { MetricOneStatusService, MetricOneStatusTask } from './metric-one-status.service';
import * as moment from 'moment/moment';

@Component({
    selector: 'metric-one-status',
    templateUrl: './metric-one-status.component.html',
    styleUrls: ['./metric-one-status.component.scss', '../../../../../assets/stylesheets/core/icon.scss']
})
export class MetricOneStatusComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    value: string;

    @Input()
    status: string;

    @Input()
    writable: boolean;

    @Input()
    linkActivity: boolean;
    
    @Input()
    pattern: string;

    @Input()
    unit: string;

    @Input()
    name: string;

    @Input()
    displayStatus: boolean;

    @Input()
    colors: Object;

    @Input()
    origin: string;

    @Input()
    statusColor: string;

    displayedEdit = false;
    isMouseHover = false;
    @Input() isSelected: boolean;
    @Input() atRightPosition: boolean;

    constructor(private metricOneStatusService: MetricOneStatusService) {
    }

    mouseHover(hover: boolean) {
        this.isMouseHover = hover;
    }

    ngOnInit() {

        if ((this.unit === "s") && (parseInt(this.value) > 60)) {
            this.value = moment.duration(1000 * parseInt(this.value), 'milliseconds').humanize(); // to do.
            this.unit = "";
        }
    }

    displayEdit() {
        this.displayedEdit = true;
    }

    add() {
        this.metricOneStatusService.postTask(new MetricOneStatusTask(this.name, this.unit, this.title, this.pattern));
    }

}