/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chart.component";
import * as i4 from "../../core/services/data.service";
import * as i5 from "../../core/services/range.service";
import * as i6 from "../../core/services/api.service";
import * as i7 from "../../shared/components/map/map.service";
import * as i8 from "./chart.service";
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
export { RenderType_ChartComponent as RenderType_ChartComponent };
function View_ChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "chart_no_data"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No data "]))], null, null); }
function View_ChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["container", 1]], null, 0, "div", [["class", "chart"]], null, null, null, null, null))], null, null); }
export function View_ChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { container: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, [[null, "pan"], [null, "panleft"], [null, "panright"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pan" === en)) {
        var pd_0 = (_co.onPan($event) !== false);
        ad = (pd_0 && ad);
    } if (("panleft" === en)) {
        var pd_1 = (_co.onPanLeft($event) !== false);
        ad = (pd_1 && ad);
    } if (("panright" === en)) {
        var pd_2 = (_co.onPanRight($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasData; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasData; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-highchart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵdid(1, 770048, null, 0, i3.ChartComponent, [i4.DataService, i5.RangeService, i6.ApiService, i7.MapService, i8.ChartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartComponentNgFactory = i1.ɵccf("app-highchart", i3.ChartComponent, View_ChartComponent_Host_0, { id: "id", user: "user", type: "type", displayNavigator: "displayNavigator", record_id: "record_id", range_size: "range_size", state: "state", salt: "salt", curves: "curves", ids: "ids", activityTypes: "activityTypes", quality: "quality" }, { annotationEvent: "annotationEvent", rangeEvent: "rangeEvent", evRangeSelected: "evRangeSelected", action: "action" }, []);
export { ChartComponentNgFactory as ChartComponentNgFactory };
