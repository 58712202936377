/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monthly-report.component.scss.shim.ngstyle";
import * as i1 from "../../../../assets/stylesheets/app.scss.shim.ngstyle";
import * as i2 from "../../../../assets/stylesheets/core/section.scss.shim.ngstyle";
import * as i3 from "../../../../assets/stylesheets/core/slider.scss.shim.ngstyle";
import * as i4 from "../../../../assets/stylesheets/core/metric.scss.shim.ngstyle";
import * as i5 from "../../../../assets/stylesheets/core/icon.scss.shim.ngstyle";
import * as i6 from "@angular/core";
import * as i7 from "../../../charts/basic-chart/basic-chart.component.ngfactory";
import * as i8 from "../../../charts/basic-chart/basic-chart.component";
import * as i9 from "../../../core/event-aggregator/event.aggregator";
import * as i10 from "@angular/common";
import * as i11 from "../../pipes/round.pipe";
import * as i12 from "./monthly-report.component";
import * as i13 from "../../../core/services/api.service";
import * as i14 from "../../../core/services/global.variables";
var styles_MonthlyReportComponent = [i0.styles, i1.styles, i2.styles, i3.styles, i4.styles, i5.styles];
var RenderType_MonthlyReportComponent = i6.ɵcrt({ encapsulation: 0, styles: styles_MonthlyReportComponent, data: {} });
export { RenderType_MonthlyReportComponent as RenderType_MonthlyReportComponent };
function View_MonthlyReportComponent_3(_l) { return i6.ɵvid(0, [(_l()(), i6.ɵeld(0, 0, null, null, 1, "app-bchart", [["xAxis", "dayOfMonth"]], null, null, null, i7.View_BasicChartComponent_0, i7.RenderType_BasicChartComponent)), i6.ɵdid(1, 4964352, null, 0, i8.BasicChartComponent, [i9.EventAggregator], { type: [0, "type"], unit: [1, "unit"], color: [2, "color"], xAxisSuffix: [3, "xAxisSuffix"], enabledToolip: [4, "enabledToolip"], optionsAxis: [5, "optionsAxis"], xAxis: [6, "xAxis"], interactive: [7, "interactive"], title: [8, "title"], values: [9, "values"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartType; var currVal_1 = ((_co.monthyMetrics[_v.parent.context.index].unit == null) ? null : _co.monthyMetrics[_v.parent.context.index].unit.si_short); var currVal_2 = _co.colors[_v.parent.context.index]; var currVal_3 = _co.xAxisSuffix; var currVal_4 = true; var currVal_5 = _co.optionsAxis; var currVal_6 = "dayOfMonth"; var currVal_7 = false; var currVal_8 = _co.monthyMetrics[_v.parent.context.index].title; var currVal_9 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_MonthlyReportComponent_2(_l) { return i6.ɵvid(0, [(_l()(), i6.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i6.ɵand(16777216, null, null, 1, null, View_MonthlyReportComponent_3)), i6.ɵdid(2, 16384, null, 0, i10.NgIf, [i6.ViewContainerRef, i6.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === _co.indexOfActiveChart); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MonthlyReportComponent_4(_l) { return i6.ɵvid(0, [(_l()(), i6.ɵeld(0, 0, null, null, 18, "div", [["class", "hx-u-1-5"]], null, null, null, null, null)), (_l()(), i6.ɵeld(1, 0, null, null, 17, "div", [["class", "metric-box metric-box-count"]], null, null, null, null, null)), i6.ɵdid(2, 278528, null, 0, i10.NgClass, [i6.IterableDiffers, i6.KeyValueDiffers, i6.ElementRef, i6.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i6.ɵpod(3, { "right": 0 }), (_l()(), i6.ɵeld(4, 0, null, null, 7, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i6.ɵeld(5, 0, null, null, 6, "div", [["class", "hx-u-1-1 hx-metric-box-heading"]], null, null, null, null, null)), (_l()(), i6.ɵeld(6, 0, null, null, 5, "h5", [["class", "metric-box-title"]], null, null, null, null, null)), (_l()(), i6.ɵeld(7, 0, null, null, 1, "i", [["ng-class", "headerIcon"]], [[8, "className", 0]], null, null, null, null)), i6.ɵppd(8, 1), (_l()(), i6.ɵted(9, null, ["", " "])), (_l()(), i6.ɵeld(10, 0, null, null, 1, "small", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i6.ɵted(11, null, ["(", ")"])), (_l()(), i6.ɵeld(12, 0, null, null, 0, "div", [["style", "height:5px"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i6.ɵeld(13, 0, null, null, 5, "div", [["class", "hx-g metric-box-body"], ["style", "cursor:pointer;"]], null, null, null, null, null)), (_l()(), i6.ɵeld(14, 0, null, null, 4, "div", [["class", "metric-box-value"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayChart(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i6.ɵdid(15, 278528, null, 0, i10.NgClass, [i6.IterableDiffers, i6.KeyValueDiffers, i6.ElementRef, i6.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i6.ɵpod(16, { "metric-box-body-selected": 0 }), (_l()(), i6.ɵted(17, null, ["", ""])), i6.ɵppd(18, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "metric-box metric-box-count"; var currVal_1 = _ck(_v, 3, 0, (_v.context.index === (_co.monthyMetrics.length - 1))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_6 = "metric-box-value"; var currVal_7 = _ck(_v, 16, 0, "i === indexOfActiveChart"); _ck(_v, 15, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i6.ɵinlineInterpolate(1, "hx-icon hx-icon-", i6.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i6.ɵnov(_v.parent.parent, 0), ((_v.context.$implicit.unit == null) ? null : _v.context.$implicit.unit.si_short))), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.unit.si_short; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.colors[_v.context.index]; _ck(_v, 12, 0, currVal_5); var currVal_8 = i6.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i6.ɵnov(_v.parent.parent, 1), _v.context.$implicit.value, _v.context.$implicit.unit)); _ck(_v, 17, 0, currVal_8); }); }
function View_MonthlyReportComponent_1(_l) { return i6.ɵvid(0, [(_l()(), i6.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i6.ɵand(16777216, null, null, 1, null, View_MonthlyReportComponent_2)), i6.ɵdid(2, 278528, null, 0, i10.NgForOf, [i6.ViewContainerRef, i6.TemplateRef, i6.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i6.ɵeld(3, 0, null, null, 2, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i6.ɵand(16777216, null, null, 1, null, View_MonthlyReportComponent_4)), i6.ɵdid(5, 278528, null, 0, i10.NgForOf, [i6.ViewContainerRef, i6.TemplateRef, i6.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.values; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.monthyMetrics; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_MonthlyReportComponent_0(_l) { return i6.ɵvid(0, [i6.ɵpid(0, i11.ToIconPipe, []), i6.ɵpid(0, i11.RoundPipeWithUnit, []), (_l()(), i6.ɵand(16777216, null, null, 1, null, View_MonthlyReportComponent_1)), i6.ɵdid(3, 16384, null, 0, i10.NgIf, [i6.ViewContainerRef, i6.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MonthlyReportComponent_Host_0(_l) { return i6.ɵvid(0, [(_l()(), i6.ɵeld(0, 0, null, null, 1, "monthly-report", [], null, null, null, View_MonthlyReportComponent_0, RenderType_MonthlyReportComponent)), i6.ɵdid(1, 638976, null, 0, i12.MonthlyReportComponent, [i13.ApiService, i14.GlobalVariables, i9.EventAggregator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MonthlyReportComponentNgFactory = i6.ɵccf("monthly-report", i12.MonthlyReportComponent, View_MonthlyReportComponent_Host_0, { metricIds: "metricIds", offsetMonth: "offsetMonth", selectedPeriod: "selectedPeriod", start: "start", end: "end" }, {}, []);
export { MonthlyReportComponentNgFactory as MonthlyReportComponentNgFactory };
