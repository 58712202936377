/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-dropdown.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select-dropdown.component";
var styles_SelectDropdownComponent = [i0.styles];
var RenderType_SelectDropdownComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectDropdownComponent, data: {} });
export { RenderType_SelectDropdownComponent as RenderType_SelectDropdownComponent };
function View_SelectDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["filterInput", 1]], null, 0, "input", [["autocomplete", "off"]], [[8, "placeholder", 0]], [[null, "click"], [null, "input"], [null, "keydown"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSingleFilterClick() !== false);
        ad = (pd_0 && ad);
    } if (("input" === en)) {
        var pd_1 = (_co.onSingleFilterInput($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.onSingleFilterKeydown($event) !== false);
        ad = (pd_2 && ad);
    } if (("focus" === en)) {
        var pd_3 = (_co.onSingleFilterFocus() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SelectDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "hx-icon ", _v.parent.context.$implicit.icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_1); }); }
function View_SelectDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, [[null, "click"], [null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.onOptionMouseover(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "highlighted": 0, "selected": 1, "disabled": 2 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectDropdownComponent_3)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(6, { option: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _v.context.$implicit.highlighted, _v.context.$implicit.selected, _v.context.$implicit.disabled); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getOptionStyle(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.wrappedOption); var currVal_3 = _co.optionTemplate; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = !_co.optionTemplate; _ck(_v, 8, 0, currVal_4); }, null); }
function View_SelectDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notFoundMsg; _ck(_v, 1, 0, currVal_0); }); }
export function View_SelectDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { filterInput: 0 }), i1.ɵqud(402653184, 2, { optionsList: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "top.px": 0, "left.px": 1, "width.px": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, [[2, 0], ["optionsList", 1]], null, 5, "div", [["class", "options"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionsListClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "ul", [], null, [[null, "wheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("wheel" === en)) {
        var pd_0 = (_co.onOptionsWheel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_2)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDropdownComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.top, _co.left, _co.width); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.multiple && _co.filterEnabled); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.optionList.filtered; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.optionList.hasShown; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_SelectDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "select-dropdown", [], null, null, null, View_SelectDropdownComponent_0, RenderType_SelectDropdownComponent)), i1.ɵdid(1, 4833280, null, 0, i3.SelectDropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectDropdownComponentNgFactory = i1.ɵccf("select-dropdown", i3.SelectDropdownComponent, View_SelectDropdownComponent_Host_0, { filterEnabled: "filterEnabled", highlightColor: "highlightColor", highlightTextColor: "highlightTextColor", left: "left", multiple: "multiple", notFoundMsg: "notFoundMsg", optionList: "optionList", top: "top", width: "width", placeholder: "placeholder", optionTemplate: "optionTemplate" }, { optionClicked: "optionClicked", optionsListClick: "optionsListClick", singleFilterClick: "singleFilterClick", singleFilterFocus: "singleFilterFocus", singleFilterInput: "singleFilterInput", singleFilterKeydown: "singleFilterKeydown" }, []);
export { SelectDropdownComponentNgFactory as SelectDropdownComponentNgFactory };
