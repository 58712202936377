import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { WeekViewEvent } from '../../calendar-utils';

@Component({
  selector: 'app-calendar-week-view-event',
  templateUrl: './calendar-week-view-event.component.html',
})
export class CalendarWeekViewEventComponent {
  @Input() weekEvent: WeekViewEvent;

  @Input() tooltipPlacement: string;

  @Input() tooltipAppendToBody: boolean;

  @Input() customTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Output() eventClicked: EventEmitter<any> = new EventEmitter();
}
