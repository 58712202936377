import { Pipe, PipeTransform, Injectable } from '@angular/core';


/**
 */
@Pipe({ name: 'search' })
@Injectable()
export class SearchPipe implements PipeTransform {
    transform(value, [term]) {
        return value.filter((item) => item.username.startWith(term));
    }
}


@Pipe({
    name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
    transform(items: any, field: string, value: string): any[] {
        if ((!items) || (!value)) return items;
        if ((items.length > 1) && (items[0][field] === undefined)) return undefined;
        let filtered_items = new Array<any>();
        for (let i = 0; i < items.length; i++) {
            let _value;
            if (items[i][field] instanceof Array) {
                _value = "";
                for (let k = 0; k < items[i][field].length; k++) {
                    _value += items[i][field][k];
                    if (k < items[i][field].length - 1) _value += ",";
                }
            } else {
                _value = items[i][field].toLowerCase();
            }
            if (_value.includes(value.toLowerCase())) filtered_items.push(items[i]);
        }
        return filtered_items;
    }
}

@Pipe({
    name: 'sort'
})
@Injectable()
export class SortPipe implements PipeTransform {
    transform(items: any[], field: string): any[] {
        let sortedArray: string[] = items.sort((n1, n2) => {
            if (n1[field] > n2[field]) {
                return 1;
            }
            if (n1[field] < n2[field]) {
                return -1;
            }
            return 0;
        });
        return sortedArray;
    }
}
