var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import 'rxjs/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import { SKpi } from '../../models/s-kpi';
import { HxToolsDate } from '../../shared/hx-tools/hx-tools-date';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from '../../core/services/global.variables';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import * as _ from 'lodash';
import { NotifierService } from 'angular-notifier';
var ApiQuery = /** @class */ (function () {
    function ApiQuery() {
    }
    ApiQuery.all_annotations_for_record_id = _.template('annotation/?record=<%= record_id %>');
    ApiQuery.all_annotations_for_user_id__start__end = _.template('annotation/?user=<%= user %>&start__gte=<%= start %>&start__lte=<%= end %>');
    ApiQuery.all_activitiytype = _.template('activitytype/?limit=0&show_all=1');
    ApiQuery.create_related_user = _.template('createrelateduser/');
    ApiQuery.all_coaches_for_user_id = _.template('bundle_permission/?bundle=1&user_target=<%= user_id %>');
    ApiQuery.all_friends_for_user_id = _.template('bundle_permission/?bundle=2');
    ApiQuery.all_subjects_for_user_id = _.template('bundle_permission/?bundle=1&user_allowed=<%= user_id %>');
    ApiQuery.all_subjects_for_user_id_limit_offset = _.template('bundle_permission/?bundle=1&user_allowed=<%= user_id %>&limit=<%= limit %>&offset=<%= offset %>');
    ApiQuery.all_users_for_user_id = _.template('user/?limit=0');
    ApiQuery.all_pending_relationships_requests = _.template('bundle_permission_request/');
    ApiQuery.metrics_for_user_id__start__end = _.template('report/?include_metrics=<%= metric_ids %>&start=<%= start %>&end=<%= end %>&user=<%= user_id %>');
    ApiQuery.metrics_for_range = _.template('report/?include_metrics=<%= metric_ids %>&range=<%= range_id %>');
    ApiQuery.metrics_for_record = _.template('report/?include_metrics=<%= metric_ids %>&record=<%= record_id %>');
    ApiQuery.all_datatypes = _.template('datatype/?limit=0');
    ApiQuery.all_metrics = _.template('metric/?limit=0');
    ApiQuery.add_friend = _.template('bundle_permission/');
    ApiQuery.retrieve_gps_positions = _.template('trackpoint/?limit=0&user=<%= user_id %>&start=<%= start %>&end=<%= end %>');
    ApiQuery.all_prescriptions = _.template('trainingroutine/');
    ApiQuery.add_prescription = _.template('trainingroutine/');
    ApiQuery.update_prescription = _.template('trainingroutine/<%= id %>/');
    ApiQuery.get_metric_distribution_for_profile_id = _.template('profile/<%= id %>/populationdistribution/');
    ApiQuery.save_profile = _.template('profile/<%= id %>/');
    ApiQuery.get_report_for_user_id__metrics__start__end__o_groupby = _.template('report/?user=<%= user_id %>&include_metrics=<%= metric_ids %>&start=<%= start %>&end=<%= end %><%= option %>');
    return ApiQuery;
}());
export { ApiQuery };
var ApiService = /** @class */ (function (_super) {
    __extends(ApiService, _super);
    function ApiService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    /**
     * Delete a distant object
     */
    ApiService.prototype.delete = function (objectName, id, keyToClear) {
        var query = this.API_URL + "api/" + objectName + "/" + id + "/";
        return this._delete(query, keyToClear);
    };
    /**
     * Delete from an URI
     */
    ApiService.prototype.deleteFromURI = function (resourceUri, keyToClear) {
        var query = this.API_URL + resourceUri;
        return this._delete(query, keyToClear);
    };
    /**
     * Save a distant object
     */
    ApiService.prototype.save = function (objectName, object, keyToClear) {
        var query = this.API_URL + "api/" + objectName + "/";
        return this._post(query, object, keyToClear);
    };
    /**
     * Update a distant object
     */
    ApiService.prototype.update = function (objectName, object, id, keyToClear) {
        var query = this.API_URL + "api/" + objectName + "/" + id + "/";
        return this._patch(query, object, keyToClear);
    };
    /**
     * Retrieve all the annotations for a given record
     */
    ApiService.prototype.getAll = function (id) {
        //const query =   let res =  all_annotations_for_record_id({ 'record_id': id });
        var query = this.API_URL + "api/" + "annotation/?record=" + id;
        return this._get(query);
    };
    ApiService.prototype.getFromUri = function (uri) {
        if (uri[0] === "/") {
            uri = uri.slice(1);
        }
        var query = this.API_URL + uri;
        return this._get(query, false);
    };
    ApiService.prototype.getQuery = function (queryName, params) {
        var query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._get(query);
    };
    ApiService.prototype.postQuery = function (queryName, object, params, keyToClear) {
        var query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._post(query, object, keyToClear);
    };
    ApiService.prototype.putQuery = function (queryName, object, params, keyToClear) {
        var query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._put(query, object, keyToClear);
    };
    ApiService.prototype.patchQuery = function (queryName, object, params, keyToClear) {
        var query = this.API_URL + "api/" + queryName(params ? params : {});
        return this._patch(query, object, keyToClear);
    };
    /**
     * Retrieve all the annotations for a time range and user
     */
    ApiService.prototype.getAllFromTimeRange = function (user, start, end) {
        var query = this.API_URL + "api/" + "annotation/?user=" + user +
            "&start__gte=" + (start - Math.floor((Math.random() * 100) + 1))
            + "&start__lte=" + (end + Math.floor((Math.random() * 100) + 1));
        // TO DO : delete limit... workaround not good...
        return this._get(query);
    };
    ApiService.prototype.getHistoricalFitness = function (profile_id, start) {
        var query = this.API_URL + "api/profile/"; // was profile...
        if (profile_id)
            query = query + profile_id + "/";
        else
            query = query + this.globalVariables.get('currentUserId') + "/";
        var startOfTheDay = 256 * HxToolsDate.getStartOfMonth(start);
        var endOfTheDay = 256 * HxToolsDate.getEndOfMonth();
        if (start) {
            query += "fitness/?limit=0&start__gte=" + startOfTheDay + "&start__lte=" + endOfTheDay;
        }
        else {
            query += "fitness/?limit=0";
        }
        return this._get(query, false);
    };
    ApiService.prototype.getPreferences = function (profileId) {
        var query = this.API_URL + "api/profile/"; // was profile...
        if (profileId)
            query = query + profileId + "/";
        else
            query = query + this.globalVariables.get('currentUserId') + "/";
        return this._get(query);
    };
    ApiService.prototype.saveKPI = function (kpi, value, profile_id, start) {
        var _kpi = new SKpi();
        _kpi.value = value;
        if (start)
            _kpi.start = start ? start : null;
        var query = "profile/";
        if (profile_id) {
            query = query + profile_id;
        }
        else {
            query = query + this.globalVariables.get('currentUserId');
        }
        return this._post(this.API_URL + "api/" + query + "/fitness/" + kpi + "/", _kpi, 'fitness,profile');
    };
    ApiService.prototype.deleteKPI = function (resource_uri, keyToClear) {
        return this._delete(this.API_URL + resource_uri, keyToClear);
    };
    ApiService.prototype.updateKPI = function (resource_uri, data) {
        var options = this.generateHeader();
        return this.http.patch(this.API_URL + resource_uri, data, { 'headers': options })
            .map(function (response) {
            return response.json();
        });
    };
    ApiService.prototype.getAnnouncements = function () {
        var query = this.API_URL + '/api/studyannouncement/?limit=' + Math.round(Math.random() * 100);
        return this._get(query);
    };
    /**
     * Retrieve a specific KPI for a given user
     */
    ApiService.prototype.getKPI = function (kpi, profile_id) {
        var query = this.API_URL + "api/profile/";
        if (profile_id) {
            query = query + profile_id;
        }
        else {
            query = query + this.globalVariables.get('currentUserId');
        }
        query += "/fitness/" + kpi + "/?order_by=-start&limit=1";
        return this._get(query);
    };
    ApiService.prototype.addSubject = function (userAllowedId, userTargetEmail) {
        var bundle = { 'user_allowed': userAllowedId, 'user_target': userTargetEmail, 'bundle': 1 };
        return this._post(this.API_URL + "api/bundle_permission_request/", bundle);
    };
    ApiService.prototype.addUser = function (email, firstname, lastname) {
        var options = this.generateHeader();
        var bundle = {
            "email": email,
            "first_name": firstname,
            "last_name": lastname,
            "bundles": [
                "/api/bundle/1/"
            ]
        };
        return this._post(this.API_URL + "api/createrelateduser/", bundle);
    };
    return ApiService;
}(HttpService));
export { ApiService };
