/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./metric-box-one.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./metric-box-one.component";
var styles_MetricBoxOneComponent = [i0.styles];
var RenderType_MetricBoxOneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MetricBoxOneComponent, data: {} });
export { RenderType_MetricBoxOneComponent as RenderType_MetricBoxOneComponent };
function View_MetricBoxOneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unity; _ck(_v, 1, 0, currVal_0); }); }
export function View_MetricBoxOneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "metric-box metric-box-count"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "hx-u-1-1 hx-metric-box-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "h5", [["class", "metric-box-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["ng-class", "headerIcon"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "small", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MetricBoxOneComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "hx-g metric-box-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "metric-box-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.unity; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "hx-icon hx-icon-", _co.icon, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.val; _ck(_v, 11, 0, currVal_3); }); }
export function View_MetricBoxOneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "metric-box-one", [], null, null, null, View_MetricBoxOneComponent_0, RenderType_MetricBoxOneComponent)), i1.ɵdid(1, 638976, null, 0, i3.MetricBoxOneComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MetricBoxOneComponentNgFactory = i1.ɵccf("metric-box-one", i3.MetricBoxOneComponent, View_MetricBoxOneComponent_Host_0, { metricIds: "metricIds", results: "results", offset: "offset", length: "length", title: "title", precision: "precision", unity: "unity", icon: "icon", type: "type" }, {}, []);
export { MetricBoxOneComponentNgFactory as MetricBoxOneComponentNgFactory };
