import { Component, OnInit, Input } from '@angular/core';
import { ApiQuery, ApiService } from '../../../core/services';

@Component({
  selector: 'rrbox',
  templateUrl: './rrbox.component.html',
  styleUrls: ['./rrbox.component.scss']
})
export class RrboxComponent implements OnInit {

  @Input() period: string;

  /**
   * Array of records
   */
  _records: Array<any>;
  @Input() set records(array: Array<any>) {
    this._records = array;
  }
  get records(): Array<any> {
    return this._records;
  }

  /**
  * Array of ranges
  */
  _ranges: Array<any>;
  @Input() set ranges(array: Array<any>) {
    this._ranges = array;
  }
  get ranges(): Array<any> {
    return this._ranges;
  }


  @Input() metricList: string;
  @Input() date: any;
  @Input() time: string;
  @Input() userId: number = 1;

  @Input() start = 18;
  @Input() end = 42;
  loading = true;
  // public timelapseChanged: number = 0;

  /**
   * Hours to display in the timeline
   */
  hours: Array<number>;

  metrics: Array<string>;

  /**
   * Cache to store the metrics
   * Avoid API calls if metrics already in cache
   */
  _cache: Array<any>;

  constructor(
    private apiService: ApiService) {
    this.metrics = new Array<string>();
    this.metrics.push("0");
    this.metrics.push("0");
    this.metrics.push("0");
    this.metrics.push("0");
    this.hours = new Array<number>();
    this._cache = new Array<any>();

  }

  onChangeStart() {}

  evMouseOverRangeMetrics(recordId) {
    if (this._cache[recordId]) {
      this.metrics = this._cache[recordId];
    } else {
      let query = ApiQuery.metrics_for_record;
      this.apiService.getQuery(query, { metric_ids: this.metricList, record_id: recordId })
        .subscribe(
        data => {
          this.metrics = data.metrics;
          this._cache[recordId] = this.metrics;
        });
    }
  }

  evMouseOverRange2Metrics(rangeId) {
    if (this._cache[rangeId]) {
      this.metrics = this._cache[rangeId];
    } else {
      let query = ApiQuery.metrics_for_range;
      this.apiService.getQuery(query, { metric_ids: this.metricList, range_id: rangeId })
        .subscribe(
        data => {
          this.metrics = data.metrics;
          this._cache[rangeId] = this.metrics;

        });
    }
  }


  evRemoveRangeMetrics() {
    this.retrieveAllDayMetrics();
  }

  evClickRange() {
  }

  retrieveAllDayMetrics() {
    let key = 256 * this.date.startOfDay + "-" + 256 * this.date.endOfDay;
    if (this._cache[key]) {
      this.metrics = this._cache[key];
    } else {
      let start = 256 * this.date.startOfDay;
      let end = 256 * this.date.endOfDay;
      if (this.time === "noctural") {
        start = 256 * (this.date.startOfDay + 18 * 60 * 60);
        end = 256 * (this.date.startOfDay + 35 * 60 * 60);
      }
      this.apiService.getQuery(ApiQuery.metrics_for_user_id__start__end,
        {
          metric_ids: this.metricList,
          start: start,
          end: end,
          user_id: this.userId
        }).subscribe(
        data => {
          this.metrics = data.metrics;
          this._cache[key] = this.metrics;
          this.loading = false;
        });
    }
  }


  ngOnInit() {
    for (let h = 0; h <= 24; h++) this.hours.push(h);
    this.retrieveAllDayMetrics();
  }


}
