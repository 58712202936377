<div class="form">
    <form name="annotationForm" role="form" class="hx-form" autocomplete="off" novalidate="">
        <div class="form-title" *ngIf="!deletableAnnotation">Add annotation</div>
        <div class="form-title" *ngIf="deletableAnnotation">Edit annotation</div>

        <input type="text" [class.edit_mode]="mode === 'edit'" class="form-control ng-pristine ng-valid-min ng-valid-max ng-valid-datetime ng-valid ng-valid-required ng-touched"
            datetime="yyyy-MM-dd HH:mm:ss" value="{{ start | dateHumanize }}" name="startDate" required="">

            <input type="text" [(ngModel)]="annotation" [class.edit_mode]="mode === 'edit'" class="form-control ng-pristine ng-invalid ng-invalid-required ng-untouched"
                name="annotation" placeholder="Enter note here" required="">

                <button *ngIf="!deletableAnnotation" type="button" (click)="save()" class="button">
                    <i class="hx-icon hx-icon-add"></i> Add</button>
                <button *ngIf="!deletableAnnotation" type="button" (click)="reset()" class="button">
                <i class="hx-icon hx-icon-times"></i> Cancel
                </button>

                <button *ngIf="deletableAnnotation" type="button" (click)="update()" class="button">Save</button>
                <button *ngIf="deletableAnnotation" (click)="delete()" class="button">
                <i class="hx-icon hx-icon-delete"></i>
            </button>
                <input type="hidden" name="id" ng-model="annotation.id" class="ng-pristine ng-untouched ng-valid">
                <div class="tip"><button class="button hx-button-tip">?</button>
                    <span>Click&nbsp;the&nbsp;vertical dash&nbsp;line&nbsp;to&nbsp;edit an&nbsp;annotation</span>
                </div>

    </form>
</div>

<confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>