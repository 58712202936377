<div class="metric-box metric-box-count">
  <div class="hx-g">
    <div class="hx-u-1-1 hx-metric-box-heading">
      <h5 class="metric-box-title">
        <i class="hx-icon hx-icon-{{ icon }}" ng-class="headerIcon"></i>{{ title }}
        <small class="hidden-xs"><ng-container *ngIf="unity">({{ unity }})</ng-container></small>
      </h5>
    </div>
  </div>
  <div class="hx-g metric-box-body">
        <div class="metric-box-value">{{ val }}</div>
  </div>
</div>