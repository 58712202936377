<div *ngIf="subjects && subjects.length > 1" class="subjects">
    <div class="title">
        Fast User Switching
    </div>
    <div *ngIf="subjects.length > 10" class="search">
        <input class="form-control" style="background-color: rgba(255,255,255,0.5);border: 0px solid white;padding:4px;" type="text"
            name="filter" [(ngModel)]="filter" placeholder="Search">
    </div>
    <div style="max-height:330px;" [ngClass]="{ 'scrollable' : subjects.length > 10 }" class="table">
        <table class="hx-table">
            <tbody>
                <tr>
                    <td class="subject" class="active">
                        <div>
                            <span style="">{{ currentSubject?.first_name }} {{ currentSubject?.last_name }}</span>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let subject of subjects | filter : 'name' : filter | sort : 'name'">
                    <ng-container *ngIf="subject.id != currentSubject.id">
                        <td class="subject">
                            <div style="cursor:pointer;" (click)="switchSubject(subject.id)">
                                <span style="">{{ subject.name }}</span>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>