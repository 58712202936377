import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { HttpService } from './http.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
// const sha1 = require('sha1');

@Injectable()
export class AuthenticationService extends HttpService {


    private retrieveDataResolver;


    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }




    retrieveAccount() {
        return this._get(this.API_URL + "/api/account/", false);
    }

    clearLocalStorage(): Promise<any> {
        return new Promise((resolve) => {
            this.retrieveDataResolver = resolve;
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentUserId');
            localStorage.removeItem('currentSubject');
            localStorage.removeItem('currentSubjectId');
            localStorage.removeItem('stringAuth');
            localStorage.removeItem('auth');
            this.retrieveDataResolver();
        });
    }

    logout() { // todo : refactoring, this is useless

        let _headers = { 'Authorization': this.globalVariables.get("access_token") + " " + this.globalVariables.get("token_type") };
        _headers = Object.assign({}, _headers, { 'Content-Type': "application/json" });
        const headers = new HttpHeaders(_headers);

        return this.http.get(this.API_URL + "/api/connect/logout/", { headers: headers })
            .map((response: Response) => {
                localStorage.clear();
                this.clearAll();
                this.clearLocalStorage().then(() => { this.notifyFSS(); });
                window.location.href = 'this.API_URL + "/api/connect/logout/';
            }).catch(error => {
                console.error(error);
                return Observable.throw({});
            });

    }

    notifyFSS() {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
            {
                msg: HxEvent.LOGOUT
            }));

    }

    isLoggedIn() {
        // User is logged
        if ((this.globalVariables.get('access_token')) &&
            (this.globalVariables.get('currentUser')) &&
            (this.globalVariables.get('currentUserId')) &&
            (this.globalVariables.get('profileUri'))) {

            // current subject is not defined, subject = logged user
            if (this.globalVariables.get('currentSubject') === null) {
                this.globalVariables.set('currentSubject', this.globalVariables.get('currentUser'));
                this.globalVariables.set('currentSubjectId', this.globalVariables.get('currentUserId'));
            }
            return true;
        }
        localStorage.clear();
        return false;
    }
}
