import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TopMenuService } from "./shared/components/top-menu/top-menu.service";
import { AuthenticationService } from './core/services';
import { Renderer2, ElementRef, OnInit } from '@angular/core';
import { EventAggregator } from './core/event-aggregator/event.aggregator';
import { MessageSentEvent } from './core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from './core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from './core/event-aggregator/events/event';
import { Router } from '@angular/router';
import { GlobalVariables } from './core/services/global.variables';
import { Boxes } from './shared/components/select-boxes/boxes';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['../assets/stylesheets/core/reset.scss', '../assets/stylesheets/core/grid.scss'
        , '../assets/stylesheets/core/grid-base.scss', './app.component.scss',
        '../../node_modules/angular-notifier/styles/core.scss', '../../node_modules/angular-notifier/styles/themes/theme-material.scss',
        '../../node_modules/angular-notifier/styles/types/type-success.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

    box: ElementRef;
    boxes: Boxes;
    env: any;

    constructor(
        private authenticationService: AuthenticationService,
        private eventAggregator: EventAggregator,
        private globalVariables: GlobalVariables,
        private router: Router,
        private renderer: Renderer2, private el: ElementRef,
        private topMenuService: TopMenuService) {
    }


    childEventClicked(boxes: Boxes) {

        this.boxes = boxes;
    }

    ngOnInit() {

        this.env = environment;
        console.log(this.env);
        // set a user context for Sentry
        if (this.authenticationService.isLoggedIn()) {
            this.topMenuService.showNavBar(true);
        } else {
            this.topMenuService.showNavBar(false);
            // todo login
        }
        this.renderer.addClass(this.el.nativeElement, 'partial-main');
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
    }

    private onMessageReceived = (payload: MessageSentEventPayload) => {

        const that = this;
        if (payload.object.msg === HxEvent.MAIN__FULL_SCREEN) {
            that.renderer.removeClass(this.el.nativeElement, 'partial-main');
            that.renderer.addClass(this.el.nativeElement, 'full-main');
        } else if (payload.object.msg === HxEvent.MAIN__PARTIAL_SCREEN) {
            that.renderer.removeClass(this.el.nativeElement, 'full-main');
            that.renderer.addClass(this.el.nativeElement, 'partial-main');
        /*} else if (payload.object.msg === HxEvent.ERROR) {
            this.displayError(payload.object.text);*/
        } else if (payload.object.msg === 401) {
            this.authenticationService.logout().subscribe(() => {
                this.router.navigate(['/']);
            });
        } else if (payload.object.msg === HxEvent.LOGOUT) {
            localStorage.clear(); // clear all data in localstorage
            window.location.href = environment.apiURL + '/api/connect/logout/'; // explicit logout from the serveur
        }
    }

    /*displayError(errors: Array<string>) {
        let text = "";

        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                text += key + " : ";
                if (errors.hasOwnProperty(key)) {
                    for (let error of errors[key]) {
                        text += error;
                    }
                }
            }
        }
    }*/

}


