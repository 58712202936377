<div class="hx-form-panel">

  <div *ngIf="success" class="alert alert-success">
    Your info has been successfully updated.
  </div>


  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="hx-form hx-form-aligned">

    <ul>
      <li class="hx-control-group">
        <label for="gender">Gender</label>
        <label class="hx-radio">
          <input type="radio" value="F" name="gender" id="gender" formControlName="gender" [checked]="user.gender === 'F'">
          Female
          <input type="radio" value="M" name="gender" id="gender" formControlName="gender" [checked]="user.gender === 'M'">     
          Male
        </label>
        <div *ngIf="formErrors.gender" class="alert alert-danger">
          {{ formErrors.gender }}
        </div>
      </li>

      <li class="hx-control-group">
        <label for="system">System</label>
        <label class="hx-radio">
          <input type="radio" value="metric" name="system" id="system" formControlName="system" [checked]="system === 'metric'"
          (change)="setMeasurementFields('metric')">
          Metric
          <input type="radio" value="imperial" name="system" id="system" formControlName="system" [checked]="system === 'imperial'"
          (change)="setMeasurementFields('imperial')">     
          Imperial
        </label>
        <!--div *ngIf="formErrors.system" class="alert alert-danger">
          {{ formErrors.system }}
        </div-->
      </li>

      <li class="hx-control-group">
        <label for="weight">Weight <small class="hidden-xs ng-binding"></small></label>

        <select formControlName="weight" class="form-control" id="weight" required>
          <option></option>
          <option *ngFor="let weight of weights | keyvalue" [value]="weight.key" [selected]="user.weight == weight.key">
            {{ weight.value }}
          </option>
        </select>
        <div *ngIf="formErrors.weight" class="alert alert-danger">
          {{ formErrors.weight }}
        </div>
      </li>

      <li class="hx-control-group">
        <label for="height">Height <small class="hidden-xs ng-binding">(m)</small> </label>
        <select formControlName="height" class="form-control" id="height" required>
          <option></option>
          <option *ngFor="let height of heights | keyvalue" [value]="height.key" [selected]="user.height == height.key">
             {{ height.value }}
          </option>
        </select>
        <div *ngIf="formErrors.height" class="alert alert-danger">
          {{ formErrors.height }}
        </div>
      </li>

      <li class="hx-control-group">
        <label for="date_of_birth">Date of birth</label>
        <input type="date" id="date_of_birth" class="form-control" formControlName="date_of_birth" placeholder="YYYY-MM-DD" required>
        <div *ngIf="formErrors.date_of_birth" class="alert alert-danger">
          {{ formErrors.date_of_birth }}
        </div>
      </li>

      <li class="hx-control-group">
        <label></label>
        <button type="submit" class="button button-green" [disabled]="!form.valid">Submit</button>
      </li>

    </ul>

  </form>
</div>
