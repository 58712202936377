      <article>
  <header>
    <h3>Legal Notice</h3>
  </header>

  <p>
    On this page the term "Web site" includes the Web site of <strong>Hexoskin</strong>, and all the applications it
    contains (Extranet, mail system and all other related applications).
  </p>

  <p>
    The present Web site is offered to users subject to certain general conditions. By using this Web site as well as
    any of its applications, you accept the general conditions.
  </p>

  <p>
    This page states the general conditions under which you may use the present Web site. Please read this page carefully.
  </p>

  <h4><strong>Miscellaneous rights</strong></h4>

  <p>
    <strong>Carré Technologies</strong> is the owner of the present <strong>Hexoskin</strong> Web site. The content of
    the present Web site as well as all of the applications are provided for informational purposes only. Any
    information provided by a member of the personnel of <strong>Carré Technologies</strong> or by a third party
    authorized by <strong>Carré Technologies</strong> to provide such information shall take precedence over the
    information contained on this site. If you do not agree with one or more of the general conditions for use of this
    Web site mentioned on this page, please do not use this Web site. <strong>Carré Technologies</strong>, cannot be
    held liable for any damage caused as a result of the use of this Web site.
  </p>

  <h4><strong>Responsibility</strong></h4>

  <p>
    <strong>Carré Technologies</strong> is not responsible for any damages resulting from any actions taken based on
    the information contained on the present Web site or for any damages sustained following the disclosure of personal
    or confidential information submitted on the Internet.
  </p>

  <p>
    <strong>Carré Technologies</strong> is not responsible for the content of the Web sites accessed through "external links"
    on its site. When consulting these sites, you do so at your own risk. The external links present on this Web
    site shall not be considered as recommendations and are placed on the site for information purposes only.
  </p>

  <p>
    <strong>Carré Technologies</strong> will pay no compensation or indemnity related to any aspect of or attributed to the use of this Web site.
  </p>

  <h4><strong>Information</strong></h4>

  <p>
    The information contained on this Web site is protected by property rights and its distribution outside this Web
    site must be authorized in writing by <strong>Carré Technologies</strong>. The reproduction, in part or in whole, of
    this content (information, texts, images, graphics or other) is strictly forbidden without the authorization of
    <strong>Carré Technologies</strong>. <strong>Carré Technologies</strong> cannot be held liable for any damage
    resulting from the non-authorized reproduction or use of the information on this Web site.
  </p>

  <p>
    <strong>Carré Technologies</strong> reserves the right to cease or interrupt the online activities relating to this
    Web site without prior notice. By using the present Web site, you waive all rights to any type of legal action
    against <strong>Carré Technologies</strong>.
  </p>

  <p>
    <strong>Carré Technologies</strong> reserves the right to modify its conditions for use at any time without prior notice.
  </p>
</article>