var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { ApiService } from '../../core/services';
import { Fitness } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import { lessThan } from '../../shared/validators/number.validator';
var UserFitnessComponent = /** @class */ (function (_super) {
    __extends(UserFitnessComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function UserFitnessComponent(fb, apiService, alertService) {
        var _this = _super.call(this, fb) || this;
        _this.apiService = apiService;
        _this.alertService = alertService;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.fitness = new Fitness();
        _this.active = true;
        _this.success = false;
        _this.profile_id = null;
        /**
         * Error messages
         */
        _this.formErrors = {
            'hr_max': '',
            'hr_rest': '',
            'bp_coef_numerator': '',
            'bp_coef_denumerator': '',
            'respiration_coef': ''
        };
        _this.validationMessages = {
            'hr_max': {
                'required': 'Weight is required.',
                'lessThan': 'HR Max should be lower than 250'
            },
            'hr_rest': {
                'required': 'Height is required.',
            },
            'bp_coef_numerator': {
                'required': 'Numerator is required.',
            },
            'bp_coef_denumerator': {
                'required': 'Denumerator is required.',
            },
            'respiration_coef': {
                'required': 'respiration coefficient is required.',
            }
        };
        return _this;
    }
    /**
     * Building the form on init
     */
    UserFitnessComponent.prototype.ngOnInit = function () {
        var _this = this;
        var kpis = new Array();
        this.apiService.getFromUri('api/profile/' + this.profile_id + '/').subscribe(function (data) {
            for (var i = 0; i < data.fitness.length; i++) {
                var status_1 = data.fitness[i].zone_description;
                var unit = data.fitness[i].unit.si_short;
                var origin = "manual";
                if (data.fitness[i].range) {
                    origin = "measured";
                }
                if (status_1 && status_1.toLowerCase() === "na")
                    status_1 = null;
                if (unit && unit.toLowerCase() === "na")
                    unit = null;
                if (unit && unit.toLowerCase() === "na")
                    unit = null;
                kpis[data.fitness[i].kpi] = {
                    "title": data.fitness[i].name,
                    "value": Math.round(100 * data.fitness[i].value) / 100,
                    "status": status_1,
                    "unit": unit,
                    "origin": origin
                };
            }
            var kpi_tree = [
                [
                    "hr_max",
                    "hr_recov",
                    "hr_rest",
                    "vo2_max",
                    "respiration_coef"
                ],
                [
                    "weight",
                    "height",
                    "bmi"
                ],
                [
                    "total_sleep_time",
                    "sleep_latency",
                    "sleep_efficiency",
                ]
            ];
            _this.kpis = new Array();
            for (var j = 0; j < kpi_tree.length; j++) {
                _this.kpis[j] = new Array();
                for (var i = 0; i < kpi_tree[j].length; i++) {
                    _this.kpis[j][i] = kpis[kpi_tree[j][i]];
                }
            }
        });
    };
    /**
     * Actions performed when submit is clicked
     */
    UserFitnessComponent.prototype.onSubmit = function () {
        var _this = this;
        this.fitness.hr_max = this.form.value.hr_max;
        this.fitness.hr_rest = this.form.value.hr_rest;
        this.fitness.bp_coef_numerator = this.form.value.bp_coef_numerator;
        this.fitness.bp_coef_denumerator = this.form.value.bp_coef_denumerator;
        this.fitness.respiration_coef = this.form.value.respiration_coef;
        this.apiService.saveKPI("hr_max", Number(this.fitness.hr_max), this.profile_id).flatMap(function () {
            return _this.apiService.saveKPI("hr_rest", Number(_this.fitness.hr_rest), _this.profile_id);
        }).flatMap(function () {
            var tuple = [+_this.fitness.bp_coef_numerator, +_this.fitness.bp_coef_denumerator];
            return _this.apiService.saveKPI("bp_coef", tuple, _this.profile_id);
        }).flatMap(function () {
            return _this.apiService.saveKPI("respiration_coef", +_this.fitness.respiration_coef, _this.profile_id);
        }).subscribe(function () {
            _this.msgs = [];
            _this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'The fitness parameters have been saved.' });
        }, function (error) {
            console.error(error);
            _this.alertService.error(error);
        });
    };
    /**
     * Building form function
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    UserFitnessComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.fb.group({
            'hr_max': [this.fitness.hr_max, [
                    Validators.required,
                    lessThan(260)
                ]
            ],
            'hr_rest': [this.fitness.hr_rest, [
                    Validators.required,
                ]
            ],
            'bp_coef_numerator': [this.fitness.bp_coef_numerator, [
                    Validators.required,
                ]
            ],
            'bp_coef_denumerator': [this.fitness.bp_coef_denumerator, [
                    Validators.required,
                ]
            ],
            'respiration_coef': [this.fitness.respiration_coef, [
                    Validators.required,
                ]
            ]
        });
        this.form.valueChanges
            .subscribe(function () {
            _this.onValueChanged( /*data*/);
        });
        this.onValueChanged();
    };
    return UserFitnessComponent;
}(UserForm));
export { UserFitnessComponent };
