
    <div *ngIf="!loading">
        <div *ngFor="let _values of values; let i = index">
            <app-bchart *ngIf="i === indexOfActiveChart" [interactive]=false [unit]="monthyMetrics[i].unit?.si_short" [values]="_values" [color]="colors[i]" [title]="monthyMetrics[i].title"
                [type]="chartType" [xAxisSuffix]="xAxisSuffix" [enabledToolip]=true [optionsAxis]="optionsAxis" xAxis="dayOfMonth"></app-bchart>
        </div>
        <div class="hx-g">
            <div *ngFor="let metric of monthyMetrics; let i = index" class="hx-u-1-5">
                <div class="metric-box metric-box-count" [ngClass]="{ 'right' : i === monthyMetrics.length - 1 }">
                    <div class="hx-g">
                        <div class="hx-u-1-1 hx-metric-box-heading">
                            <h5 class="metric-box-title">
                                <i class="hx-icon hx-icon-{{ metric.unit?.si_short | toIcon  }}" ng-class="headerIcon"></i>{{
                                metric.title }}
                                <small class="hidden-xs">({{ metric.unit.si_short }})</small>
                            </h5>
                        </div>
                    </div>
                    <div style="height:5px" [style.background-color]="colors[i]">
                    </div>
                    <div class="hx-g metric-box-body" style="cursor:pointer;">
                        <div (click)="displayChart(i)" [ngClass]="{ 'metric-box-body-selected' : 'i === indexOfActiveChart' }" class="metric-box-value">{{ metric.value | roundWithUnit:metric.unit }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>