/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/click.directive";
import * as i3 from "./calendar-event-actions.component";
var styles_CalendarEventActionsComponent = [];
var RenderType_CalendarEventActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarEventActionsComponent, data: {} });
export { RenderType_CalendarEventActionsComponent as RenderType_CalendarEventActionsComponent };
function View_CalendarEventActionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "cal-event-action"], ["href", "javascript:;"]], [[8, "innerHTML", 1]], [[null, "calClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("calClick" === en)) {
        var pd_0 = (_v.context.$implicit.onClick({ event: _co.event }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(2, 212992, null, 0, i2.ClickDirective, [i0.Renderer2, i0.ElementRef], null, { click: "calClick" })], function (_ck, _v) { var currVal_1 = "cal-event-action"; var currVal_2 = _v.context.$implicit.cssClass; _ck(_v, 1, 0, currVal_1, currVal_2); _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 0, 0, currVal_0); }); }
function View_CalendarEventActionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "cal-event-actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarEventActionsComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.actions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CalendarEventActionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarEventActionsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.actions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CalendarEventActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-event-actions", [], null, null, null, View_CalendarEventActionsComponent_0, RenderType_CalendarEventActionsComponent)), i0.ɵdid(1, 49152, null, 0, i3.CalendarEventActionsComponent, [], null, null)], null, null); }
var CalendarEventActionsComponentNgFactory = i0.ɵccf("app-calendar-event-actions", i3.CalendarEventActionsComponent, View_CalendarEventActionsComponent_Host_0, { event: "event" }, {}, []);
export { CalendarEventActionsComponentNgFactory as CalendarEventActionsComponentNgFactory };
