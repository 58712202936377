import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';


@Injectable()
export class GlobalVariables {

    constructor() { }

    /**
     * Checks if the key exists
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    protected hasValue(key: string): boolean {
        if (localStorage.getItem(key)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     *
     * @param {string} key
     *  Key
     * @param {string} value
     *  Value associated to the key
     * @param {string} maxAge
     *  Max duration of key in cache in seconds
     */
    public set(key: string, value: any): void {
        if (typeof value === 'object') {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, value);
        }
    }

    /**
     * Get value associated to the key
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    public has(key: string): boolean {
        return this.hasValue(key);
    }

    /**
     * Checks if the a key exists in localStorage
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    public get(key: string): any {
        const value = localStorage.getItem(key);
        if (this.isJsonString(value)) {
            return JSON.parse(value);
        }
        return value;
    }

    /**
     * Checks if the a string is a valid Json
     *
     * @param {string} str
     *
     * @return
     *  True if the a string is a valid Json, else false
     */
    public isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Clear all the global variables
     *
     */
    public clearAll() {
        localStorage.clear();
    }

}
