<div class="help contextual-menus" [ngStyle]="{'top': top}">
            <div class="help-title hx-g">
            <div class="hx-u-4-5">
                Info
                </div>
            <div class="hx-u-1-5" style="text-align:right;cursor:pointer" (click)="close()">
                X
                </div>
            </div>   
            <div class="help-body">
                <ng-content></ng-content>
            </div>
        </div>