import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TimelineService } from "./timeline.service";
import { ApiQuery, ApiService } from '../../../core/services';

const WAITINGEVENT = -1;
const MOUSEDOWN = 0;
const TIMELINE_SIZE = 600;
const CHANGE_TIMELAPSE = 1;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {

  /**
   * Array of records
   */
  _records: Array<any>;
  @Input() set records(array: Array<any>) {
    if (array === undefined) {
      this._records = new Array<any>();
    } else {
      this._records = array;
    }
  }
  get records(): Array<any> {
    return this._records;
  }

  /**
  * Array of ranges
  */
  _ranges: Array<any>;
  @Input() set ranges(array: Array<any>) {
    this._ranges = array;
  }
  get ranges(): Array<any> {
    return this._ranges;
  }

  @Input() salt: number;

  /**
   * First hour of the timeline (by default midnight 0)
   */
  @Input() startHour: number;
  private startHourV: number;
  /**
   * Last hour of the timeline (by default midnight 24)
   */
  @Input() endHour: number;
  private endHourV: number;
  /**
   * Time = diurnal or noctural
   */
  @Input() time: string;
  /**
  * Mouse over range event
  */
  @Output() evMouseOverRangeMetrics = new EventEmitter<boolean>();
  @Output() evMouseOverRange2Metrics = new EventEmitter<boolean>();
  /**
  * Mouse out range event
  */
  @Output() evRemoveRangeMetrics = new EventEmitter<boolean>();
  /**
   * Mouse click event
   */
  @Output() evClickRange = new EventEmitter<boolean>();
  /**
   * Hour range 
   */
  public hours: Array<number>;
  /**
   *  Hours to display 
   */
  public hoursAt2h: Array<any>;
  /**
   * Record coordinates to draw bar in timeline
   */
  public recordCoordinates: Array<any>;
  /**
  * Ranges coordinates to draw bar in timeline
  */
  public rangeCoordinates: Array<any>;
  /**
   * Size of one hour on the timeline (by default 600 / 24)
   */
  private hourSize = 25;
  /**
   * Line at each pixel to track position of the mouse
   */
  public lines: Array<number>;
  /**
   * Mouse state (WAITING, DOWN, UP)
   */
  private mouseState: number = WAITINGEVENT;
  /** 
   * Position of the mouse when it is pressed down
   */
  private original_xx;
  /**
   * Start point of the zoom in
   */
  public xx;
  /**
   * Width of the zoom in
   */
  public ww;

  public loading = true;

  public rid;

  public activityTypes: Array<any>;

  constructor(private router: Router,
    private apiService: ApiService,
    private timelineService: TimelineService) {
    /* this.records = new Array<any>();
     this.ranges = new Array<any>();*/
    this.rid = Math.random();
    this.timelineService.tasksConfirmed$.subscribe(
      task => {

        if (task.action === CHANGE_TIMELAPSE && task.object.time === this.time) {
          this.startHourV = task.object.dstart;
          this.endHourV = task.object.dend;
          this.render();
        }

      });

  }

  /**
   * At initialization, we draw the ranges and records on timeline
   * and the vertical pixel to track position of the mouse when the mouse
   * is presssed down.
   */
  ngOnInit() {
    this.apiService.getQuery(ApiQuery.all_activitiytype).subscribe(data => {
      this.activityTypes = data.objects.filter(activitiy => activitiy.role === 'system' || activitiy.role === 'support' );
      this.startHourV = this.startHour;
      this.endHourV = this.endHour;
      this.render();
      this.lines = new Array<any>();
      for (let i = 0; i < TIMELINE_SIZE; i++) {
        this.lines.push(i);
      }
    });
  }

  ngOnChanges() {
    this.startHourV = this.startHour;
    this.endHourV = this.endHour;
    this.render();
    this.lines = new Array<any>();
    for (let i = 0; i < TIMELINE_SIZE; i++) {
      this.lines.push(i);
    }
  }

  /**
   * When start and end hours change
   */
  /* ngOnChanges() {
     this.startHourV = this.startHour;
     this.endHourV = this.endHour;
     this.render();
     this.lines = new Array<any>();
     for (let i = 0; i < TIMELINE_SIZE; i++) {
       this.lines.push(i);
     }
   }*/

  /**
   * highlight the zoom in (mouse pressed down and horizontal move over the timeline)
   */
  highlightZoomIn(line) {
    if ((this.mouseState === MOUSEDOWN) && (this.xx)) {
      // move to the right
      if (line > this.xx) {
        this.ww = line - this.xx;
        // move to the left
      } else {
        let w = this.original_xx - line;
        this.xx = line;
        this.ww = w;
      }
    }
  }


  flashing_record = "#518CAB;#8eb8ce;#518CAB";
  flashing_range = "#518CAB;#8eb8ce;#518CAB";


  /* ngOnChanges(...args: any[]) {
 
 
 
     this.render();
   }
 */

  render() {

    // hour size on the timeline
    this.hourSize = TIMELINE_SIZE / (this.endHourV - this.startHourV);

    /* Positionning the records along the timeline */
    this.recordCoordinates = new Array<any>();

    for (let record of this.records) {
      let x = this.hourSize * (record.dstart - this.startHourV);

      let width = TIMELINE_SIZE - x;
      if (record.dend >= record.dstart) {
        width = this.hourSize * (record.dend - record.dstart);
      }
      this.recordCoordinates.push(
        {
          "x": x,
          "width": width,
          "recordId": record.recordId,
          "color": record.highlighted ? "#518CAB" : "#c9d2db",
          "highlighted": record.highlighted
        }
      );
    }

    /* Positionning the ranges along the timeline */
    this.rangeCoordinates = new Array<any>();
    if (this.ranges) {
      for (let range of this.ranges) {
        let x = this.hourSize * (range.dstart - this.startHourV);
        let width = TIMELINE_SIZE - x;
        if (range.dend >= range.dstart) {
          width = this.hourSize * (range.dend - range.dstart);
        }
        this.rangeCoordinates.push(
          {
            "x": x,
            "width": width,
            "rangeId": range.rangeId, // todo
            "color": range.highlighted ? "green" : "#183F66", //range.highlighted ? "#518CAB" : "#183F66"
            "highlighted": range.highlighted
          }
        );
      }
    }

    this.hours = new Array<number>();
    this.hoursAt2h = new Array<any>();

    let j = this.startHourV;
    for (let i = 0; i <= this.endHourV - this.startHourV; i++) {
      let h = j;
      if (j > 24) {
        h = j - 24;
      }
      let _h = "" + h;
      if (h < 10) {
        _h = "0" + h;
      }
      this.hours.push(this.hourSize * i);
      let v = {
        "x": this.hourSize * i,
        "x2": this.hourSize * i + 1,
        "value": _h
      };
      /* display hours only at every 2 hours */
      if (i % 2 === 0) this.hoursAt2h.push(v);
      j++;
    }

    // We hide the timelapse highlight
    this.xx = 0;
    this.ww = 0;

    this.loading = false;
  }

  /**
   * Emit event mouse over range if the mouse
   * is not pressed
   */
  displayRangeMetrics(recordId) {
    if (this.mouseState !== MOUSEDOWN) {
      this.evMouseOverRangeMetrics.emit(recordId);
    }
  }


  transform(str: string): string {
    if (!str) {
      return 'hx-icon-other';
    }
    let icon = str.toLowerCase().replace(/\s/g, '-');

    if (icon.match(/cross-country-ski/i)) { icon = "cross-country-skiing"; }
    else if (icon.match(/downhill-ski/i)) { icon = "downhill-skiing"; }
    else if (icon.match(/hike/i)) { icon = "hiking"; }
    else if (icon.match(/jog|run|sprint|cooper/i)) { icon = "running"; }
    else if (icon.match(/meditate/i)) { icon = "meditating"; }
    else if (icon.match(/mountain-bike/i)) { icon = "mountain-biking"; }
    else if (icon.match(/rest-test/i)) { icon = "5-minute-rest-test"; }
    else if (icon.match(/roller-skate/i)) { icon = "roller-skating"; }
    else if (icon.match(/roller-skate/i)) { icon = "rowing"; }
    else if (icon.match(/skate/i)) { icon = "skating"; }
    else if (icon.match(/swim/i)) { icon = "swimming"; }
    else if (icon.match(/row/i)) { icon = "rowing"; }
    else if (icon.match(/weight-training/i)) { icon = "weight-lifting"; }

    return 'hx-icon-' + icon;
  }

  displayRangeMetricsRange(rangeId, $event) {
    let activityType = null;
    let _element = document.getElementById("ontop_" + this.rid);
    _element.innerHTML = null;
    const activity = this.ranges.filter(function (entry) {
      return entry.rangeId === rangeId;
    });
    activityType = this.activityTypes.filter(function (entry) {
      return entry.resource_uri === activity[0].activity;
    });
    _element.style['top'] = '0';
    _element.style['left'] = $event.layerX + 10 + 'px';
    _element.style['display'] = 'block';
    if (activityType[0]) {
      _element.innerHTML = "<i class=\"hx-icon hx-icon " +
        this.transform(activityType[0].name) + "\"></i><br>" + activity[0].name;
    } else {
      _element.innerHTML = "No info.";
    }
    if (this.mouseState !== MOUSEDOWN) {
      this.evMouseOverRange2Metrics.emit(rangeId);
    }
  }

  /**
   * Emit event mouse out range
   */
  removeRangeMetrics() {
    let _element = document.getElementById("ontop_" + this.rid);
    _element.style['display'] = 'none';
    if (this.mouseState !== MOUSEDOWN) {
      this.evRemoveRangeMetrics.emit();
    }
  }

  /**
   * Redirect to record view if click on record
   */
  clickRecord(recordId) {
    let link = ['/record-view/' + recordId];
    this.router.navigate(link);
    this.evClickRange.emit(recordId);
  }

  /**
   * Redirect to range view if click on record
   */
  clickRange(rangeId) {

    let link = ['/range-view/' + rangeId];
    this.router.navigate(link);
    this.evClickRange.emit(rangeId);
  }

}
