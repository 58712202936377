import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import 'rxjs/add/operator/mergeMap';

@Component({
    selector: 'user-form',
    template: ''
})
export class UserForm implements OnInit {

    form: FormGroup;
    formErrors = {};
    validationMessages = {};

    constructor(protected fb: FormBuilder) { }

    ngOnInit(): void { }

    /**
    * Check if the fields pass the validators
    */
    onValueChanged() {
        if (!this.form) { return; }
        const form = this.form;

        for (const field in this.formErrors) {
            if (this.formErrors.hasOwnProperty(field)) {
                // clear previous error message
                this.formErrors[field] = '';
                const control = form.get(field);

                if (control && control.dirty && !control.valid) {
                    const messages = this.validationMessages[field];
                    let i = 0;
                    for (const key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            if (i === 0) { 
                                // we display only the first message, 
                                // thus the order of the validators are important!
                                this.formErrors[field] = messages[key];
                            }
                            i++;
                        }
                    }
                }
            }
        }
    }

}

