<div class="hx-metric-one-status" (mouseenter)="mouseHover(true)" (mouseleave)="mouseHover(false)">
  <div class="hx-g title" [ngClass]="{ 'not-right' : atRightPosition === false, 'hover' : isMouseHover === true, 'selected' : isSelected === true, 'unselected' : isSelected === false }">
    <div class="hx-u-24-24">
      <div class="heading">
        {{ title }}
      </div>
    </div>
    <div *ngIf="false" class="hx-u-6-24">
      <div *ngIf="false && status && displayStatus" [ngStyle]="{'background-color':colors[status]}" class="hx-icon hx-fitness-indicator">&nbsp;&nbsp;</div>
      <div class="hx-fitness-indicator-status">{{ status }}</div>
    </div>
  </div>
  <div class="hx-g hx-metric-one-status hx-right-border" [ngClass]="{ 'not-right' : atRightPosition === false }">
    <div class="hx-u-20-24">
      <div class="hx-panel-value pane-value" [ngClass]="{ 'hover' : isMouseHover === true, 'selected' : isSelected === true, 'unselected' : isSelected === false }">
        <div class="value">
          <div>{{ value }} {{ unit }} <i *ngIf="isMouseHover && writable" (click)="add()" class="hx-icon hx-icon-edit hx-icon-clickable"></i></div>
          <div class="hx-fitness-indicator-status">{{ status }}</div>
        </div>
      </div>
    </div>
    <div class="hx-u-4-24">
      <div class="hx-panel-value pane-value" [ngClass]="{ 'hover' : isMouseHover === true, 'selected' : isSelected === true, 'unselected' : isSelected === false }">
        <div *ngIf="status && displayStatus" [ngStyle]="{'background-color':colors[status]}" class="hx-icon hx-fitness-indicator">&nbsp;&nbsp;</div>
      </div>
    </div>
  </div>
</div>