var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import { PreferenceService } from "../../services/preferences.service";
import { TOGGLE_BOX, UpdatePreferencesSuccess, UpdatePreferencesFailure, CHANGE_TIME_RANGE } from "../actions/boxes-selector.actions";
import { NotifierService } from 'angular-notifier';
var SelectorBoxEffects = /** @class */ (function () {
    function SelectorBoxEffects(actions, preferenceService, notifierService, router) {
        var _this = this;
        this.actions = actions;
        this.preferenceService = preferenceService;
        this.notifierService = notifierService;
        this.router = router;
        this.ToggleBox = this.actions
            .ofType(TOGGLE_BOX)
            .map(function (action) { return action.payload; })
            .switchMap(function (payload) {
            var preferences = {};
            preferences[payload.page] = {};
            preferences[payload.page][payload.name] = {
                'name': payload.name,
                'label': payload.label,
                'displayed': payload.displayed
            };
            _this.notifier.hideOldest();
            console.log("========> 6 (show preferences to send to backend");
            console.log(preferences);
            return _this.preferenceService.saveSelector(payload.page, payload.name, payload.displayed)
                .map(function () {
                //this.notifier.notify('success', 'Your preference has been successfully saved.');
                return new UpdatePreferencesSuccess();
                // update on the view.... TODO
            })
                .catch(function (error) {
                _this.notifier.notify('error', 'An error occured. Your preference has not been saved');
                return Observable.of(new UpdatePreferencesFailure());
            });
            //return Observable.of(new UpdatePreferences());
        });
        this.ChangeTimeRange = this.actions
            .ofType(CHANGE_TIME_RANGE)
            .map(function (action) { return action.range; })
            .switchMap(function (payload) {
            var preferences = {};
            preferences['time_range'] = payload;
            _this.notifier.hideOldest();
            return _this.preferenceService.save(preferences)
                .map(function () {
                _this.notifier.notify('success', 'Time range has been successfully saved in your preferences.');
                return new UpdatePreferencesSuccess();
            })
                .catch(function (error) {
                _this.notifier.notify('error', 'An error occured. Your preference has not been saved');
                return Observable.of(new UpdatePreferencesFailure());
            });
            //return Observable.of(new UpdatePreferences());
        });
        this.notifier = this.notifierService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SelectorBoxEffects.prototype, "ToggleBox", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SelectorBoxEffects.prototype, "ChangeTimeRange", void 0);
    return SelectorBoxEffects;
}());
export { SelectorBoxEffects };
