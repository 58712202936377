
export class HxToolsActivity {

    static activityTypeToIconClass(str: string): string {
        if (!str) {
            return 'hx-icon-other';
        }
        let icon = str.toLowerCase().replace(/\s/g, '-');

        if (icon.match(/cross-country-ski/i)) { icon = "cross-country-skiing"; }
        else if (icon.match(/downhill-ski/i)) { icon = "downhill-skiing"; }
        else if (icon.match(/hike/i)) { icon = "hiking"; }
        else if (icon.match(/jog|run|sprint|cooper/i)) { icon = "running"; }
        else if (icon.match(/meditate/i)) { icon = "meditating"; }
        else if (icon.match(/mountain-bike/i)) { icon = "mountain-biking"; }
        else if (icon.match(/rest-test/i)) { icon = "5-minute-rest-test"; }
        else if (icon.match(/roller-skate/i)) { icon = "roller-skating"; }
        else if (icon.match(/roller-skate/i)) { icon = "rowing"; }
        else if (icon.match(/skate/i)) { icon = "skating"; }
        else if (icon.match(/swim/i)) { icon = "swimming"; }
        else if (icon.match(/row/i)) { icon = "rowing"; }
        else if (icon.match(/weight-training/i)) { icon = "weight-lifting"; }
        else if (icon.match(/heart-rate-recovery-2-min-test/i)) { icon = "other"; }
        else if (icon.match(/volume-test/i)) { icon = "other"; }
        else if (icon.match(/beeptest/i)) { icon = "other"; }
        else if (icon.match(/standard/i)) { icon = "other"; }
        return 'hx-icon-' + icon;
    }

}
