// Import from other modules
import { Component, OnInit, Output, ViewChild, OnDestroy, ElementRef, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { ConfigService } from '../../../shared/components/config/config.service';
import { ApiQuery, HttpService, ApiService, RangeService, RecordService, DataService } from '../../../core/services';
import { Range } from "../../../models";
import { Action, ChartService, ChartTask } from "../../../charts/chart/";
import { HxToolsGeo, HxToolsRange, HxToolsHistogram, HxToolsTimeline } from '../../../shared/hx-tools';
import { Subscription } from 'rxjs/Subscription';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { Router } from '@angular/router';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { DataViewComponent } from '../../data-view/data-view.component';
import { BoxViewComponent } from '../../data-view/box-view.component';
import { GlobalVariables } from '../../../core/services/global.variables';
import { HelpService } from '../../../shared/components/help/help.service';
import { Section, HelperComponent } from '../../../shared/components/helper/helper.component';
import { HxToolsTime } from '../../../shared/hx-tools/hx-tools-time';
import { Boxes } from '../../../shared/components/select-boxes/boxes';
import { RedirectionComponent } from '../../../shared/components/redirection/redirection.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { AddBox, DisplaySelector, EmptyHideSelector } from '../../../core/boxes-selector/actions/boxes-selector.actions';
import { BoxesSelector } from '../../../core/boxes-selector/models/boxes-selector';
import { ExportComponent } from '../../../shared/components/export/export.component';
import { Mixin } from '../../../core/mixin/mixin-decorator';
import { ZONE_LABELS, SLEEP_POSITION_LABELS, SLEEP_PHASE_LABELS } from '../../../config';
import { METRICS, SLEEP_STATUS } from '../../../config';

@Mixin([BoxViewComponent, RedirectionComponent, ExportComponent, HelperComponent])
@Component({
  selector: 'app-range-view',
  templateUrl: './range-view.component.html',
  styleUrls: ['./range-view.component.scss',
    '../../../../assets/stylesheets/app.scss',
    '../../../../assets/stylesheets/core/section.scss',
    '../../../../assets/stylesheets/core/grid.scss',
    '../../../../assets/stylesheets/core/nav.scss',
    '../../../../assets/stylesheets/core/icon.scss',
    '../../../../assets/stylesheets/chart.scss',
    '../../../../assets/stylesheets/button.scss']
})
export class RangeViewComponent extends DataViewComponent implements OnInit, OnDestroy {

  /**
   * Form DOM manipulation of the views
   */
  @ViewChild("elem") elem: ElementRef;

  @Output() boxesClicked = new EventEmitter<Boxes>();

  ZONE_LABELS = ZONE_LABELS;
  SLEEP_POSITION_LABELS = SLEEP_POSITION_LABELS;
  SLEEP_PHASE_LABELS = SLEEP_PHASE_LABELS;

  /**
   * Range unique ID
   */
  public rangeId: number;

  /**
   * Record object
   */
  public range: Range;

  /**
   * Associated record
  */
  public record: any;

  /**
   * Promise for retrieve url parameters
   */
  public sub: any;

  public records: Array<any>;

  /**
   * Equals to true if the data is being retrieved from the server
   */
  public loading = true;

  public displayNavigatorRawData = true;
  public displayNavigatorProcessedData = false;

  /**
   * Acitivity Type Name
   */
  public activityTypeName: string;



  /**
   * Charts displaued (Processed or Raw)
   */
  public displayProcessedData = true;
  public displayRawData = false;

  /**
   * All the displayable charts
   * 
   */
  public startOfTheDay;
  public endOfTheDay;

  public processedCharts: Array<any> = [];


  public metricValues: Array<any> = [];

  /**
   * All the activity types retrieved from server
   */
  public activityTypes;

  /**
   * Matrix telling which charts are actually displayed
   */
  public display: Array<Array<any>> = [];


  public annotations: Array<any> = [];

  public ECGStatus;
  public RespStatus;
  public gpsPositions: Array<any>;

  public user;

  public ranges: Array<any>;
  public rangesOfDay: Array<any>;
  public subRanges: Array<any>;

  altitudes: Array<any>;
  public previousRangeId: number = null;
  public nextRangeId: number = null;

  public nextRank: number = null;
  /* Variables for timeline */
  public time = "diurnal";
  public startHour = 0;
  public endHour = 24;
  public isOverNight = false;

  /**
   * User ID
   */
  public userId: number;

  mode = "add";

  /* Type of activity */
  activity = "";
  sleep_status = null;

  helps = [];

  /**
   * If we take the preferences saved on the server
   */
  preference_db = true;

  //@ViewChildren(Section) sections: QueryList<Section>;
  @ViewChildren(Section, { read: ElementRef }) sections: QueryList<ElementRef>;
  @ViewChild('chartsbtn') chartsbtn: ElementRef;

  selector: BoxesSelector;

  /*
 List of the metrics to display in panel.
 Equals to METRICS in config file.
 Turn around : importing const in template does not work in 
 defined outside component file...
 */
  public metrics;


  /**
 * Inherited from parent classes
 */
  isBoxPreferenceValid: () => boolean;
  initBoxes: () => void;
  exportData: () => void;
  _retrievePreviousRangeId: (rangeId) => void;
  _retrieveNextRangeId: (rangeId) => void;

  /**
   * CONSTRUCTOR
   */
  constructor(public store: Store<AppState>,
    public route: ActivatedRoute,
    public rangeService: RangeService,
    public recordService: RecordService,
    public httpService: HttpService,
    public helpService: HelpService,
    public globalVariables: GlobalVariables,
    public apiService: ApiService,
    public configService: ConfigService,
    public dataService: DataService,
    public eventAggregator: EventAggregator,
    public router: Router,
    public selectorService: SelectorService,
    public chartService: ChartService) {

    super(store, chartService, apiService, selectorService, globalVariables);
    this.metrics = METRICS;

    store.select('selector').subscribe(_selector => {
      this.selector = _selector.box_views;
    });

    this.altitudes = new Array<any>();
    this.gpsPositions = new Array<any>();
    this.displayedHistograms = new Array<any>();
    this.displayView = new Map<string, boolean>();

    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);

    //-------------------------------- Chart related actions  ---------------------------------//
    this.chartService.tasksConfirmed$.subscribe(
      task => {
        if (task.action === Action.REFRESH_CHART) {
          this.rangeService.fetchPeriod(this.range.end, this.range.start, null, null, this.userId, "start").subscribe(data => {
            this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, this.range, this.startOfTheDay, this.endOfTheDay);
          });
          this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
            user: this.userId,
            start: this.range.start, end: this.range.end
          }).subscribe(_annotations => {
            this.annotations = _annotations.objects;
          });
        }
        if (task.action === Action.DISPLAY_RANGE_EDIT_FORM) {
          this.displayForm('EDIT_RANGE');
        }
      });
  }

  ngOnInit() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.SELECTOR__DISPLAY }));
    // We retrieve the record Id from the URL
    this.sub = this.route.params.subscribe(params => {
      this.rangeId = +params['id'];
      this._init();
    });
  }

  afterViewInit() {
    this.sections.forEach(_section => {
      this.helps[_section.nativeElement.id] = false;
    });
  }

  private _init() {

    let histogramIds = new Array<number>();       // array of metric ids for histogram
    this.metricValues = new Array<any>(); // To reinitalize!!! IMPORTANT...

    this.curves = new Array<any>();
    this.curvesRaw = new Array<any>();

    this.curvesQueue = new Array<number>();
    this.curvesQueue.push(0, 1);

    this.curvesRawQueue = new Array<number>();
    this.curvesRawQueue.push(0);

    this.loading = true;

    let user = this.globalVariables.get('currentSubject');
    this.userId = user.id;

    this.initBoxes();

    /**
     * Retrieve the activity types from server
     */
    this._retrieveActivityTypes();

    this.configService.read('assets/config/config-raw.json') // We retrieve the default config for raw data
      .flatMap(_rawCharts => {
        this.rawCharts = _rawCharts;
        return this.configService.read('assets/config/config.json'); // We retrieve the default config for processed data
      }).flatMap(_processedCharts => {
        this.processedCharts = _processedCharts;
        const chartsets = this.processedCharts;
        for (let i = 0; i < chartsets.length; i++) {          // retrieve metric ids for Histograms
          if (chartsets[i].histogram) { histogramIds.push(chartsets[i].histogram); }
        }
        return this.rangeService.get(this.rangeId, histogramIds); // retrieve range with metrics for hitograms
      }).flatMap(
        _range => {
          const range = new Range(JSON.stringify(_range));
          this.range = range;
          const parameters = HxToolsTimeline.getParameters(this.range/*, this.record*/);
          this.time = parameters.time;
          this.startHour = parameters.startHour;
          this.endHour = parameters.endHour;
          this.startOfTheDay = parameters.startOfTheDay;
          this.endOfTheDay = parameters.endOfTheDay;
          this.nextRank = range.rank + 1;

          return this.apiService.getFromUri(range.user);
        }).flatMap(_user => {
          this.user = _user.first_name + " " + _user.last_name;
          this.userId = _user.id;
          this.globalVariables.set('currentSubject', _user);
          this.globalVariables.set('currentSubjectId', _user.id);

          this._retrieveGpsPositions(this.range);
          this._retrievePreviousRangeId(this.range);
          this._retrieveNextRangeId(this.range);

          /*************** Raw Charts **************/
          let rawChartSet = new Object();
          this._addCurve(rawChartSet, this.rawCharts[0]); // By default, first metric displayed on chart
          this.curvesRaw[0] = rawChartSet;

          /*************** Processed Charts **************/
          this.charts = new Array<any>();
          for (const chart of this.processedCharts) { // Create list of charts relevant to the activity range
            if (this._isRelevantDatatypeForRangeActivity(this.range, chart)) {
              this.charts.push(chart);
            }
          }
          let processedChartSet = new Object();
          for (let id of this.curvesQueue) {
            this._addCurve(processedChartSet, this.charts[id]);
            this.displayedHistograms[id] = true;
          }
          this.curves[0] = processedChartSet;

          /*************** Histograms **************/
          const histogramSet = HxToolsHistogram.createHistograms(this.range, histogramIds, this.columnColors);
          this.range.histogramsReordered = histogramSet.histogramsReordered;

          /*************** Metrics **************/
          this._retrieveRelevantMetricForRangeActivity(this.range, histogramIds);

          this.range.calculate();
          return this.httpService.getFromUrl(this.range.context.activitytype);
        }).flatMap(_activitytype => {
          this.activityTypeName = _activitytype.name;
          this.loading = false; // we can display the components at this point.
          return this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
            user: this.userId,
            start: this.range.start, end: this.range.end
          });
        }).flatMap(_annotations => {
          this.annotations = _annotations.objects;
          return this.recordService.fetchPeriod(this.startOfTheDay * 256, this.endOfTheDay * 256, null, null, this.userId);
        }).flatMap(_records => {
          this.isOverNight = HxToolsTime.isOverNight(1000 * this.range.start / 256, 1000 * this.range.end / 256);
          this.records = new Array<any>();
          for (let record of _records.objects) {
            let intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(record.start),
              HxToolsTime.HX2U(record.end),
              this.startOfTheDay * 1000, this.isOverNight);
            this.records.push(
              {
                "start": record.start,
                "end": record.end,
                "dstart": intervals[0],
                "dend": intervals[1],
                "recordId": record.id,
                "highlighted": false
              }
            );
          }
          return this.rangeService.fetchPeriod(this.endOfTheDay * 256, this.startOfTheDay * 256, null, null, this.userId);
        }).flatMap(_ranges => {
          this.rangesOfDay = new Array<any>();
          for (let range of _ranges.objects) {
            let intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(range.start),
              HxToolsTime.HX2U(range.end),
              this.startOfTheDay * 1000, this.isOverNight);
            this.rangesOfDay.push(
              {
                "start": range.start,
                "end": range.end,
                "dstart": intervals[0],
                "dend": intervals[1],
                "rangeId": range.id,
                "highlighted": this.range.id === range.id ? true : false
              }
            );
          }
          return this.rangeService.fetchPeriod(this.range.end, this.range.start, null, null, this.userId, "start");
        }).subscribe(data => {
          this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, this.range, this.startOfTheDay, this.endOfTheDay);
        });
  }

  /**
   * Display the edit form for range
   */
  public editRange(range) {
    this.hideForms('EDIT_RANGE');
    this.displayNavigatorProcessedData = false;                           // disable navigator
    this.displayedForms['EDIT_RANGE'] = true;
    this.onChartRange(range);
    this.chartService.postTask(new ChartTask(Action.HIGHLIGHT_PLOTRANGE, null, null, range));
    this.mode = "edit";
  }

  /**
  * Retrieve the all the activity types
  * @param range
  */
  private _retrieveActivityTypes() {
    this.apiService.getQuery(ApiQuery.all_activitiytype).subscribe(activityTypes => {
      this.activityTypes = activityTypes.objects.filter(activitiy => activitiy.role === 'system');
      for (let i = 0; i < this.activityTypes.length; i++) {
        this.activityTypes[i].label = this.activityTypes[i].name;
        this.activityTypes[i].icon = HxToolsActivity.activityTypeToIconClass(this.activityTypes[i].name);
        this.activityTypes[i].value = "" + this.activityTypes[i].id;
      }
    });
  }

  /**
  * Retrieve the gps positions
  * @param range
  */
  private _retrieveGpsPositions(range) {
    this.apiService.getQuery(ApiQuery.retrieve_gps_positions,
      { user_id: +this.userId, start: range.start, end: range.end }).subscribe(gpsData => {
        this.gpsPositions = gpsData['objects'];
        this.altitudes = HxToolsGeo.retrieveAltitudeFromGPS(this.gpsPositions);
      });
  }

  /**
  * Check if the datatype is relevant for range activity
  * @param range
  * @param chart
  * @returns returns true if the datatype is relevant for range activity, false otherwise
  */
  private _isRelevantDatatypeForRangeActivity(range, chart) {
    let relevant = true; // defaut, it is
    if (chart.activitytypes && chart.activitytypes.length > 0) {
      relevant = false;
      for (const activitytype of chart.activitytypes) {
        if (range.context.activitytype === activitytype) {
          relevant = true;
        }
      }
    }
    return relevant;
  }

  private _retrieveRelevantMetricForRangeActivity(range, histogramIds) {
    if (range.context.activitytype == null) { // If not activitytype associated to range (todo : check default metrics)
      this.rangeService.get(this.rangeId, histogramIds, [44, 46, 47, 71, 149, 255, 257]).subscribe(_data => {
        this.metricValues = _data['metrics'];
      });
    } else {
      this.httpService.getFromUrl(range.context.activitytype).flatMap(_data => {
        let metricIds = [];
        for (const metric of _data.default_metrics) {
          const metric_id = metric.replace('/api/metric/', '').replace('/', '');
          metricIds.push(metric_id);
        }
        // If sleep activity
        this.activity = null;
        if (range.context.activitytype === '/api/activitytype/12/') {
          this.activity = "sleep";
          metricIds.push(1071);
        }
        return this.rangeService.get(this.rangeId, histogramIds, metricIds);
      }).subscribe(_data => {
        this.sleep_status = null;
        this.metricValues = _data['metrics'];
        if (this.activity === "sleep") {
          let _sleep_status = this.metricValues.filter(function (item) { return item.id === 1071; });
          if (_sleep_status.length > 0 && _sleep_status[0].value !== 1) {
            this.sleep_status = SLEEP_STATUS[_sleep_status[0].value];
          }
        }
      });
    }
  }

  /**
   * Perform the actions corresponding to the events emitted
   * by others components
   */
  performAction(action: Action) {
    super.performAction(action);
  }

  /**
 * Treatment of messages received from event aggregator
 */
  private onMessageReceived = (payload: MessageSentEventPayload) => {
    if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {   // if subject changes, go back to home page
      this.router.navigate(['/home']);
    }
  }

  /**
   * Destroyes what should be destroyed....
   */
  ngOnDestroy() {
    this.store.dispatch(new EmptyHideSelector());
    this.metricValues = new Array<any>();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.SELECTOR__HIDE
      }));
  }

  openModalChartsBtn() {
    let _top = 0 + this.chartsbtn.nativeElement.getBoundingClientRect().top + window.scrollY;
    this.helpService.open("chartsbtn", _top + 'px');
  }

}
