import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AddAthlete } from './add-athlete';

@Injectable()
export class AddAthleteService {

    private requirePopupSource = new Subject<AddAthlete>();
    private acceptPopupSource = new Subject<AddAthlete>();
    private deletePopupSource = new Subject<AddAthlete>();
    private updatePopupSource = new Subject<AddAthlete>();
    // Observable string sources
    private tasks = new Subject<any>();
    // Observable string streams
    tasksConfirmed$ = this.tasks.asObservable();

    requirePopup$ = this.requirePopupSource.asObservable();
    accept = this.acceptPopupSource.asObservable();
    delete = this.deletePopupSource.asObservable();
    update = this.updatePopupSource.asObservable();

    confirm(popup: AddAthlete) {
        this.requirePopupSource.next(popup);
        return this;
    }

    onAccept() {
        this.acceptPopupSource.next();
    }

    onDelete() {
        this.deletePopupSource.next();
    }

    onUpdate() {
        this.updatePopupSource.next();
    }

    postTask(task: any) {
        this.tasks.next(task);
    }

}
