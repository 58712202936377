<section>
    <div class="hx-g header">
        <div class="hx-u-24-24">
            <div class="title">
                Preferences
            </div>
        </div>
    </div>
    <div class="hx-preferences padding-bottom">

        <table class="hx-table">
            <thead>
                <tr>
                    <th>
                        Timeline Views
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let timeline of preferences?.timeline">
                    <td>{{ timeline.title }}</td>
                    <td style="text-align:right"><input type="checkbox" name="isActive" [(ngModel)]="timeline.displayed"></td>
                </tr>
            </tbody>

            <thead>
                <tr>
                    <th>
                        Record Views
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record_view of preferences?.record_view">
                    <td>{{ record_view.title }}</td>
                    <td style="text-align:right"><input type="checkbox" name="isActive" [(ngModel)]="record_view.displayed"></td>
                </tr>
            </tbody>

            <thead>
                <tr>
                    <th>
                        Health Status Views
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let kpis of preferences?.health_status">
                    <td>{{ kpis.title }}</td>
                    <td style="text-align:right"><input type="checkbox" name="isActive" [(ngModel)]="kpis.displayed"></td>
                </tr>
                <tr>
                    <td></td>
                    <td style="text-align:right"><button class="hx-button hx-button-green" type="submit">Update My Preferences</button></td>
                </tr>
            </tbody>
        </table>


    </div>
</section>
