/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-alert.component.scss.shim.ngstyle";
import * as i1 from "../../../../assets/stylesheets/button.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./error-alert.component";
import * as i5 from "../../../core/dom/domhandler";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/event-aggregator/event.aggregator";
import * as i8 from "./error-alert.service";
var styles_ErrorAlertComponent = [i0.styles, i1.styles];
var RenderType_ErrorAlertComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ErrorAlertComponent, data: {} });
export { RenderType_ErrorAlertComponent as RenderType_ErrorAlertComponent };
function View_ErrorAlertComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [["class", "hx-ui-dialog-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorAlertComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "a", [["href", "#"], ["role", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(1, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { ngClass: [0, "ngClass"] }, null), i2.ɵpod(2, { "hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all": 0 }), (_l()(), i2.ɵeld(3, 0, null, null, 0, "span", [["class", "fa fa-fw fa-close"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, true); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorAlertComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "button", [["class", "button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "i", [["class", "hx-icon hx-icon-times"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Close "]))], null, null); }
export function View_ErrorAlertComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [["class", "hx-ui-dialog"]], [[4, "display", null], [4, "width", "px"], [4, "height", "px"]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.moveOnTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "div", [["class", "hx-ui-error-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ErrorAlertComponent_1)), i2.ɵdid(3, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ErrorAlertComponent_2)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 1, "div", [["class", "hx-ui-dialog-content hx-ui-widget-content"]], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, [" ", " "])), (_l()(), i2.ɵeld(8, 0, null, null, 2, "div", [["class", "hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ErrorAlertComponent_3)), i2.ɵdid(10, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.header; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.closable; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.rejectVisible; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "block" : "none"); var currVal_1 = _co.width; var currVal_2 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.message; _ck(_v, 7, 0, currVal_5); }); }
export function View_ErrorAlertComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-error-alert", [], null, null, null, View_ErrorAlertComponent_0, RenderType_ErrorAlertComponent)), i2.ɵdid(1, 4374528, null, 0, i4.ErrorAlertComponent, [i2.ElementRef, i5.DomHandler, i6.Router, i7.EventAggregator, i2.Renderer, i8.ErrorAlertService], null, null)], null, null); }
var ErrorAlertComponentNgFactory = i2.ɵccf("app-error-alert", i4.ErrorAlertComponent, View_ErrorAlertComponent_Host_0, { header: "header", unit: "unit", kpi: "kpi", range: "range", resource_uri: "resource_uri", icon: "icon", message: "message", acceptIcon: "acceptIcon", acceptLabel: "acceptLabel", acceptVisible: "acceptVisible", deleteVisible: "deleteVisible", updateVisible: "updateVisible", rejectIcon: "rejectIcon", rejectLabel: "rejectLabel", rejectVisible: "rejectVisible", width: "width", height: "height", closeOnEscape: "closeOnEscape", rtl: "rtl", closable: "closable", responsive: "responsive", appendTo: "appendTo", key: "key", visible: "visible" }, {}, []);
export { ErrorAlertComponentNgFactory as ErrorAlertComponentNgFactory };
