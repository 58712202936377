import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { ApiService } from '../../core/services';
import { Fitness } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import { lessThan } from '../../shared/validators/number.validator';
import { Message } from '../../shared/components/growl/message';

@Component({
    selector: 'user-fitness',
    templateUrl: './user-fitness.component.html'
})
export class UserFitnessComponent extends UserForm implements OnInit {

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];
    public kpis: Array<any>;
    fitness = new Fitness();
    profile: any;
    active = true;
    success = false;

    @Input() profile_id = null;

    /**
     * Error messages
     */
    formErrors = {
        'hr_max': '',
        'hr_rest': '',
        'bp_coef_numerator': '',
        'bp_coef_denumerator': '',
        'respiration_coef': ''
    };

    validationMessages = {
        'hr_max': {
            'required': 'Weight is required.',
            'lessThan': 'HR Max should be lower than 250'
        },
        'hr_rest': {
            'required': 'Height is required.',
        },
        'bp_coef_numerator': {
            'required': 'Numerator is required.',
        },
        'bp_coef_denumerator': {
            'required': 'Denumerator is required.',
        },
        'respiration_coef': {
            'required': 'respiration coefficient is required.',
        }
    };



    /**
     * CONSTRUCTOR
     */
    constructor(fb: FormBuilder,
        private apiService: ApiService,
        private alertService: AlertService) {
        super(fb);
    }

    /**
     * Building the form on init
     */
    ngOnInit(): void {

        let kpis = new Array<any>();
        this.apiService.getFromUri('api/profile/' + this.profile_id + '/').subscribe(data => {

            for (let i = 0; i < data.fitness.length; i++) {
                let status = data.fitness[i].zone_description;
                let unit = data.fitness[i].unit.si_short;
                let origin = "manual";
                if (data.fitness[i].range) {
                    origin = "measured";
                }
                if (status && status.toLowerCase() === "na") status = null;
                if (unit && unit.toLowerCase() === "na") unit = null;
                if (unit && unit.toLowerCase() === "na") unit = null;
                kpis[data.fitness[i].kpi] = {
                    "title": data.fitness[i].name,
                    "value": Math.round(100 * data.fitness[i].value) / 100,
                    "status": status,
                    "unit": unit,
                    "origin": origin
                };
            }



            let kpi_tree = [
                [
                    "hr_max",
                    "hr_recov",
                    "hr_rest",
                    "vo2_max",
                    "respiration_coef"
                ],
                [
                    "weight",
                    "height",
                    "bmi"
                ],
                [
                    "total_sleep_time",
                    "sleep_latency",
                    "sleep_efficiency",
                ]
            ];

            this.kpis = new Array<any>();
            for (let j = 0; j < kpi_tree.length; j++) {
                this.kpis[j] = new Array<any>();
                for (let i = 0; i < kpi_tree[j].length; i++) {
                    this.kpis[j][i] = kpis[kpi_tree[j][i]];
                }
            }
        });

    }


    /**    
     * Actions performed when submit is clicked
     */
    onSubmit() {
        this.fitness.hr_max = this.form.value.hr_max;
        this.fitness.hr_rest = this.form.value.hr_rest;
        this.fitness.bp_coef_numerator = this.form.value.bp_coef_numerator;
        this.fitness.bp_coef_denumerator = this.form.value.bp_coef_denumerator;
        this.fitness.respiration_coef = this.form.value.respiration_coef;

        this.apiService.saveKPI("hr_max", Number(this.fitness.hr_max), this.profile_id).flatMap(() => {
            return this.apiService.saveKPI("hr_rest", Number(this.fitness.hr_rest), this.profile_id);
        }).flatMap(() => {
            let tuple: number[] = [+this.fitness.bp_coef_numerator, +this.fitness.bp_coef_denumerator];
            return this.apiService.saveKPI("bp_coef", tuple, this.profile_id);
        }).flatMap(() => {
            return this.apiService.saveKPI("respiration_coef", +this.fitness.respiration_coef, this.profile_id);
        }).subscribe(() => {
            this.msgs = [];
            this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'The fitness parameters have been saved.' });
        }, error => {
            console.error(error);
            this.alertService.error(error);
        });
    }


    /**
     * Building form function 
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    buildForm(): void {
        this.form = this.fb.group({
            'hr_max': [this.fitness.hr_max, [
                Validators.required,
                lessThan(260)
            ]
            ],
            'hr_rest': [this.fitness.hr_rest, [
                Validators.required,
            ]
            ],
            'bp_coef_numerator': [this.fitness.bp_coef_numerator, [
                Validators.required,
            ]
            ],
            'bp_coef_denumerator': [this.fitness.bp_coef_denumerator, [
                Validators.required,
            ]
            ],
            'respiration_coef': [this.fitness.respiration_coef, [
                Validators.required,
            ]
            ]
        });

        this.form.valueChanges
            .subscribe(() => {
                this.onValueChanged(/*data*/);
            });

        this.onValueChanged();
    }



}

