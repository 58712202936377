import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from "@angular/core";
import { ApiQuery, ApiService, DataService, RangeService } from "../../core/services";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import { ArrayOfJSONObjects } from "../../shared/hx-tools/array-of-json-objects";
import { ChartService } from "./chart.service";
import { ChartTask } from "./chart-task";
import { Band } from "../../models";
import { Action } from "./action";
import { State } from "./state";
import { BaseChartComponent } from './base-chart.component';
import { MapService } from '../../shared/components/map/map.service';
import { MapAction } from '../../shared/components/map/action';
import { HxToolsActivity } from '../../shared/hx-tools/hx-tools-activity';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
import * as CONST from './constants';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts/highstock';

/**
* Historical Chart component
*/
@Component({
    selector: "app-highchart",
    styleUrls: ['./chart.component.scss'],
    template: `
        <div *ngIf="!hasData" class="chart_no_data">
          No data
        </div>
        <div *ngIf="hasData" class="chart" #container></div>
        <div (pan)="onPan($event)"
        (panleft)="onPanLeft($event)"
        (panright)="onPanRight($event)">
        </div>
    `
})
export class ChartComponent extends BaseChartComponent implements OnDestroy, OnInit {


    @Input() id: number;

    /**
    * The date to fetch data
    */
    //private _date: string;

    /**
    * The user unique identifier
    */
    @Input() user: number;

    @Input() type: string;
    /**
    * If the data is raw or processed
    * If raw, the navigator is enabled
    */
    @Input() displayNavigator: boolean;
    /**
    * The record unique identifier
    */
    @Input() record_id: number;
    /**
    * Whether it is raw data
    */
    isRawData: boolean;
    /**
    * Whether the chart is loading data from server
    */
    isLoadingData: boolean;
    /**
    * Whether data has been loading from server
    */
    hasData = true;
    /**
    * The range size
    */
    @Input() range_size: number;
    /**
    * The state defines the possible interactions on the chart
    * When equals to 'annotation', a click fills the field datetime of the annotation form 
    * When equals to 'range', a selection fills the fields of the range form
    */
    @Input() state: State;

    @Input() salt: number;

    @Input() curves: Array<any>;

    @Input() ids: Array<number>;

    @Input() activityTypes: any;
    /**
    * Signal quality
    */
    @Input() quality: any;

    private values: Array<any>;

    /**
    * Set the date to which to fetch the data
    */
    /*@Input() set date(val: string) {
        this._date = val;
    }*/

    /**
    * Size of the chart in milliseconds
    */
    size: number;

    seriesInds: Array<any>;
    /**
     * Event Emitter when on action must be performed by parent component
     */
    @Output()
    action: EventEmitter<Action> = new EventEmitter<Action>();

    /**
     * constructor
     */
    constructor(private dataService: DataService,
        private rangeService: RangeService,
        private apiService: ApiService,
        private mapService: MapService,
        private chartService: ChartService) {



        super();

        /**
         * Listen to the orders received from others components
         */
        this.chartService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === Action.REFRESH_CHART) {
                    this.loadData(/*this.start, this.end*/);
                } else if (task.action === Action.REINIT_CHART) {
                    this.loadData(true);
                } else if (task.action === Action.DELETE_NOT_SAVED_PLOTLINE) {
                    this.removePlotLine("currentPlotLine");
                } else if (task.action === Action.DELETE_PLOTRANGE) {
                    this.removePlotBand(task.id);
                    let index = ArrayOfJSONObjects.getIndexFromById(this.ranges, task.id);
                    if (index) {
                        this.ranges.splice(index, 1);
                    }
                } else if (task.action === Action.CANCEL_PLOTRANGE) {
                    this.removePlotBand("editablePlotBand");
                } else if (task.action === Action.HIGHLIGHT_PLOTRANGE) {
                    this.removeEditablePlotBand();
                    this.addRange(1000 * task.object.start / 256, 1000 * task.object.end / 256, 'editablePlotBand', '', CONST.SELECTED_RANGE_COLOR, null, 1);
                } else if (task.action === Action.HIGHLIGHT_PLOTANNOTATION) {
                    this.removeHighlightPlotLine();
                    this.addAnnotation(1000 * task.object.start / 256, 'highlighted', "", "red", 3);
                } else if (task.action === Action.CANCEL_PLOTANNOTATION) {
                    this.removeHighlightPlotLine();
                }
            });

        this.mapService.tasksConfirmed$.subscribe(
            task => {
                let that = this;
                if (task.action === MapAction.MOUSEMOVE_ON_ROUTE_PATH) {
                    that.removePlotLine("highlighted");
                    let utime = new Date(task.object.gps.time);
                    let mtime = utime.getTime();
                    that.addPlotLine({
                        id: "highlighted",
                        color: "#F9B233",
                        value: mtime,
                        width: 2
                    });
                }
                if (task.action === MapAction.MOUSEOUT_ROUTE_PATH) {
                    that.removePlotLine("highlighted");
                }
            });

    }

    startHX = null;
    endHX = null;
    originalStartHX = null;
    originalEndHX = null;

    public ngOnInit() {
        this.loadData(true);
    }

    onPanLeft($event) {
        console.log($event);
    }

    onPanRight($event) {
        console.log($event);
    }

    onPan($event) {
        console.log($event);
    }

    /**
    * Load data
    */
    public loadData(reset = false) {

        let that = this;

        // If currently loading
        this.isLoadingData = true;
        let start;
        let end;

        this.dataService.getForRecordId(this.curves[0].navigator, that.record_id, this.type) //1 instead of this.record
            .mergeMap(
                data => {
                    if (that._chart) {
                        that._chart.destroy(); // todo, generate error in some case, not easy to reproduce
                    }
                    // initiate the chart options
                    let options = that.initChartOptions();

                    // load data for the navigator
                    if ((data[0] !== undefined) && (data[0].data[this.curves[0].navigator].length > 0)) {
                        this.hasData = true;

                        // reset the range if it is larger
                        if (reset) {
                            let nbr_values = data[0].data[this.curves[0].navigator].length;
                            this.startHX = data[0].data[this.curves[0].navigator][0][0];
                            this.endHX = data[0].data[this.curves[0].navigator][nbr_values - 1][0];
                        }

                        // load data into navigator and instantiate chart
                        this.values = options.navigator.series.data = HxToolsTime.fromHXtoU(data[0].data[this.curves[0].navigator]);
                        that._chart = new (<any>Highcharts).StockChart(options);
                        that._chart.showLoading();
                        that._chart.options.rangeSelector.enabled = false;    // remove the range selector

                        start = Math.floor(this.startHX);  // make sure start and end are integer 
                        end = Math.floor(this.endHX);

                        // if the default range is defined, then we only define the extremes accordingly
                        // this is the case for example with ECG, we only display the number of seconds specified by the property 'range'
                        let _start;
                        let _end;
                        if (that.curves[0].range > 0 || (that.curves[0].range instanceof Array && that.curves[0].range[0] > 0)) {
                            let lapsetime;
                            if (that.curves[0].range instanceof Array) lapsetime = that.curves[0].range[0];
                            else lapsetime = that.curves[0].range;
                            _start = start;
                            _end = start + 256 * lapsetime;
                            this.size = _end - _start;
                            return this.dataService.getData(that.user, this.curves[0].ids, _start, _end);
                        } else {
                            if (end - start > 24 * 60 * 60 * 1000) {
                                // if the duration is greater than 24 hours, then we only show the first 24 hours.
                                _start = start;
                                _end = start + 24 * 60 * 60 * 1000;
                                this.size = _end - _start;
                                return this.dataService.getData(that.user, this.curves[0].ids, start, _end);
                            } else {
                                // if the duration is less than 24 hours, then we show all the data
                                _start = start;
                                _end = start + 24 * 60 * 60 * 1000;
                                this.size = _end - _start;
                                return this.dataService.getData(that.user, this.curves[0].ids, start, end);
                            }
                        }
                    } else {

                        this.hasData = false;
                        return Observable.of([]);
                    }
                }).flatMap(data => {

                    let _ids = this.curves[0].ids.reduce((a, b) => a.concat(b), []);
                    let serie_num = 0;
                    if (data[0] !== undefined) {
                        for (let k = 0; k < this.curves.length; k++) {
                            for (let j = 0; j < this.curves[k].ids.length; j++) {
                                for (let i = 0; i < this.curves[k].ids[j].length; i++) {
                                    let _id = this.curves[k].ids[j][i];
                                    // Convert HX time to Unix time
                                    let values = HxToolsTime.fromHXtoU(data[0].data[_id]);

                                    // ECG (factor of 0.0064) : Should be done on server side
                                    if (_id === 4113) { 
                                        _.forEach(values, function (value, key) {
                                            values[key] = [value[0], value[1] * 0.0064];
                                        });
                                    }
                                    if (this.curves[k].series && this.curves[k].series[j] && this.curves[k].series[j].length > 1) {
                                        // If datatype contains several series (polygons)
                                        // It is the case for sleep positions and sleep phases
                                        let phases = HxToolsTime.points2Polygons(values, this.curves[k].series[j],
                                            this.curves[k].yBottom[j], this.curves[k].yTop[j]);
                                        let phase = 0;
                                        for (const serie of this.curves[0].series[serie_num]) {
                                            that._chart.series[this.seriesInds[serie_num][phase]].setData(phases[phase]);
                                            that._chart.series[this.seriesInds[serie_num][phase]].name = serie.name;
                                            phase++;
                                        }
                                    } else {
                                        // Else a simple curve, for the others cases
                                        that._chart.series[this.seriesInds[serie_num][0]].setData(values);
                                        let datatypes = _.flattenDeep(this.curves[k].datatype);
                                        //that._chart.series[this.seriesInds[i][0]].name = datatypes[i];
                                    }
                                    serie_num++;
                                }
                            }
                        }
                    }
                    this.hasData = true;
                    that.isLoadingData = false;
                    this._chart.hideLoading();
                    // We retrieve all the ranges between start and end
                    return this.rangeService.fetchPeriod(end, start, null, null, that.user, null, false);
                }).flatMap(data => {
                    this.ranges = new Array<any>();
                    for (const range of data.objects) {
                        if (range.id !== this.record_id) { // Exclude the current range
                            this.ranges.push(range);
                            let icon = null;
                            if (this.activityTypes && range.context.activitytype !== null) {
                                // range.context.activitytype could be null something...
                                const activitytype = this.activityTypes.filter(function (entry) {
                                    return entry.resource_uri === range.context.activitytype;
                                });
                                // Check if defined as range may not have activity type associated
                                if (activitytype[0] !== undefined) {
                                    icon = HxToolsActivity.activityTypeToIconClass(activitytype[0].name);
                                }
                            }
                            this.addRange(Math.floor(1000 * range.start / 256),
                                Math.floor(1000 * range.end / 256),
                                range.id, range.name, null, icon);
                        }
                    }
                    // We retrieve all the annotations between start and end
                    return this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end,
                        {
                            user: that.user,
                            start: start,
                            end: end
                        });
                }).subscribe(data => {
                    if (data['objects']) {
                        for (const annotation of data['objects']) {
                            this.annotations.push(annotation);
                            this.addAnnotation(1000 * annotation.start / 256, annotation.id, annotation.annotation, '#0F84C7');
                        }
                    }
                },
                error => {
                    console.log(error);
                    this.hasData = false;
                });
    }


    /*  private __getExtremes(data) {
          let start;
          let end;
          // get the extremes of the navigator, cannot zoom outside the navigator
          if (this.startHX && this.endHX) {
              start = Math.floor(256 * this.startHX / 1000); //Math.floor(this.startHX); //
              end = Math.floor(256 * this.endHX / 1000); //Math.floor(this.endHX);  //
          } else {
              let number_dots = data[0].data[this.curves[0].navigator].length;
              start = Math.ceil(data[0].data[this.curves[0].navigator][0][0]); //Math.ceil(data[0].data[this.curves[0].navigator][0][0]);
              end = Math.floor(data[0].data[this.curves[0].navigator][number_dots - 1][0]);
          }
          return { start: start, end: end };
  
      }
  */

    /**
    * Initialize the charts
    */
    private initChartOptions() {

        let that = this;
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        let options = {
            rangeSelector: {
                enabled: false
            },
            chart: {
                zoomType: "x",
                backgroundColor: 'rgba(238, 247, 248, 1)',
                renderTo: "",
                events: {
                    /**
                     * A click on the chart add a plot line (for annotation)
                     * if the flag icon has been clicked before and the annotation form is displayed
                     */
                    click: function (e) {
                        /**
                         * If the annotation form, a click fills the datetime field
                         */
                        if (that.state === State.ANNOTATION_ACTIVE) {
                            // remove the plot line not saved from the chart
                            that.removeEditablePlotLine();
                            that.removeHighlightPlotLine();
                            let current_position = e.xAxis[0].value;
                            let prec_in_sec = 60 * that.size / 86400000;
                            that.chartService.postTask(new ChartTask(Action.DISPLAY_ANNOTATION_CANCEL_BUTTON));
                            const closest_annotation = that.retrieveClosestAnnotation(e.xAxis[0].value, 256 * prec_in_sec);
                            if (closest_annotation !== null) {
                                that.annotationEvent.emit(closest_annotation);
                                that.chartService.postTask(new ChartTask(Action.DISPLAY_ANNOTATION_DELETE_BUTTON));
                                that.chartService.postTask(new ChartTask(Action.PUT_ANNOTATION_FORM_IN_EDIT_MODE));
                                current_position = closest_annotation.start;
                                // that.addPlotLine(current_position, 'green', 'selectedPlotLine');
                            } else {
                                // add the new plot line on the chart
                                that.chartService.postTask(new ChartTask(Action.PUT_ANNOTATION_FORM_IN_ADD_MODE));
                                that.addAnnotation(current_position, 'currentPlotLine', "", "red");
                            }
                            /**
                             * If the annotation form is not displayed, one click on an actual annotation
                             * will display the form and fills the datetime field in order to edit it
                             */
                        } else if (that.state === State.RANGE_ACTIVE) {
                            const current_position = e.xAxis[0].value;

                            that.removeEditablePlotBand();
                            const selected_range = that.retrieveClosestRange(current_position);
                            if (selected_range !== null) {
                                that.rangeEvent.emit(selected_range);
                                that.chartService.postTask(new ChartTask(Action.DISPLAY_RANGE_EDIT_FORM));
                                that.addRange(1000 * selected_range.start / 256, 1000 * selected_range.end / 256, 'editablePlotBand', '', CONST.SELECTED_RANGE_COLOR, null, 1);
                                // that._chart.xAxis[0].removePlotBand(selected_range.id);
                            } else {
                                const range = new Band();
                                range.start = null;
                                range.end = null;
                                that.rangeEvent.emit(range);
                            }
                        }
                    },
                    /**
                     * A selection on the chart add a plot band (for range)
                     * if the plus sign icon has been clicked before and the range form is displayed
                     */
                    selection: function (e) {
                        e.preventDefault(); // prevent default behavior zoom
                        that.removeEditablePlotBand();

                        if (that.state === State.RANGE_ACTIVE) {

                            that.chartService.postTask(new ChartTask(Action.DISPLAY_RANGE_ADD_FORM));
                            const start = e.xAxis[0].min.toFixed(2);
                            const end = e.xAxis[0].max.toFixed(2);
                            const selected_range = new Band();
                            selected_range.start = 256 * start / 1000;
                            selected_range.end = 256 * end / 1000;
                            that.rangeEvent.emit(selected_range);
                            //that.chartService.postTask(new ChartTask(Action.DISPLAY_ADD_RANGE));
                            that.addRange(start, end, 'editablePlotBand', '', CONST.SELECTED_RANGE_COLOR, null, 1);
                        }
                        if (that.state === State.ZOOM) {
                            const startUX = e.xAxis[0].min.toFixed(2);
                            const endUX = e.xAxis[0].max.toFixed(2);
                            that.zoomData(startUX, endUX);
                        }
                    },
                },
            },
            navigator: {
                adaptToUpdatedData: false, // Whether the navigator and scrollbar should adapt to updated data
                // in the base X axis. When loading data async this should be false. Otherwise new data will
                // trigger navigator redraw, which will cause unwanted looping.
                series: {
                    data: undefined
                },
                enabled: false // By default, the navigator is enabled
            },
            scrollbar: {
                enabled: false // Scrollbar is disabled, all the data is displayed in the chart.
            },
            series: [], // To be define
            yAxis: [], // To be define
            xAxis: {
                type: 'datetime',
                title: "Time",
                events: {
                    afterSetExtremes: function (e) {
                        e.preventDefault();
                        // only when user uses navigator
                        if ((e.trigger !== undefined && e.trigger === "navigator" && that.isLoadingData === false) ||
                            (e.type !== undefined && e.type === "setExtremes")) {
                            if (e.min === undefined || e.max === undefined ||
                                e.min < 0 || e.max < 0) {
                                return;
                            }
                            // round to the seconds
                            if (e.max / 1000 % 1 !== 0 || e.min / 1000 % 1 !== 0) {
                                this.setExtremes(Math.round(e.min / 1000) * 1000, Math.round(e.max / 1000) * 1000);
                                return;
                            }
                            // to do : if raw data, limit interval to range value
                            that.zoomData(e.min, e.max);
                        }

                    }
                },
                minRange: undefined
            }
        };

        // Display navigator
        options.navigator.enabled = false;
        if (this.displayNavigator === true) {
            options.navigator.enabled = true;
            options.scrollbar.enabled = true;
        }

        // Series
        let colors = ["#122F4C", "#599ADD", "#F9B233", "#D14841", "#9365B8", "#61BD6D", "#BE1621", "#553982", "#553982", "#475577"];

        let indice = 0;
        let color_indice = 0;
        this.seriesInds = new Array<any>();
        // Loop through the datatypes
        for (let i = 0; i < this.curves[0].datatype.length; i++) {

            for (let j = 0; j < this.curves[0].datatype[i].length; j++) {
                let seriesInds = [];
                // If the datatype contains several series (typically for polygons for phase, position sleep)
                if (this.curves[0].series && this.curves[0].series[i].length > 1) {
                    for (let k = 0; k < this.curves[0].series[i].length; k++) {
                        options.series.push({
                            "data": [],
                            "color": this.curves[0].series[i][k].color,
                            "visible": true,
                            "lineWidth": 1.5,
                            "name": this.curves[0].series[i][k].name,
                            "yAxis": indice,
                            "type": this.curves[0].types[i]
                        });
                        seriesInds.push(options.series.length - 1);
                    }
                    // If the datatype contains only one serie (most cases)
                } else {
                    options.series.push({
                        "data": [],
                        "color": colors[color_indice],
                        "visible": true,
                        "lineWidth": 1.5,
                        "name": that.curves[0].datatype[i][j],
                        "yAxis": indice,
                        "step": "left",
                        "type": this.curves[0].types[i]
                    });
                    seriesInds.push(options.series.length - 1);
                }
                this.seriesInds.push(seriesInds);
                color_indice++;
            }
            indice++;
        }


        /***********/
        /* Tooltip *
        /***********/
        const options_tooltip = {
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                shape: "square",
                style: {
                    "font-family": "Open Sans"
                },
                valueDecimals: 2
            }
        };
        if (this.curves[0].tooltip_disabled && this.curves[0].tooltip_disabled === true) {
            options_tooltip.tooltip.enabled = false;
        }
        options = Object.assign({}, options, options_tooltip);

        /**
         * We display the legend only there are more than 1 serie on the chart
         */
        let options_legend = {};
        //if (this.curves[0].ids.length > 1) { we always display legend
        options_legend = {
            legend: {
                enabled: true,
                align: 'right',
                borderColor: '#122F4C',
                borderWidth: 1,
                layout: 'horizontal',
                padding: 8,
                margin: 30,
                verticalAlign: 'top',
                y: 0,
                shadow: false,
                itemHoverStyle: { "color": "#122F4C" },
                itemStyle: { "color": "#122F4C", "cursor": "pointer", "fontSize": "12px", "fontWeight": "normal" },
            }
        };
        //}

        options = Object.assign({}, options, options_legend);
        /*let options_axis = {
            yAxis: this.curves[0].yAxis
        };*/
        options.yAxis = this.curves[0].yAxis;
        //options = Object.assign({}, options, options_axis);
        options.chart.renderTo = this.container.nativeElement;
        return options;
        //    }


        /*this.configService.read('assets/config/chart.json').subscribe(chart_options => {
            return Object.assign({}, options, chart_options);
        })

        return options;*/
    }

    /**
    * load data based on a range date
    * called when chart buttons is clicked and navigator selection has been changed.
    */
    private zoomData(startUX, endUX) {
        this.startHX = Math.floor(256 * startUX / 1000);
        this.endHX = Math.floor(256 * endUX / 1000);
        let that = this;
        if (that.isLoadingData === false) {
            this.isLoadingData = true;
            this._chart.showLoading();
            this.dataService
                .getData(this.user, this.curves[0].ids, this.startHX, this.endHX)
                .subscribe(data => {
                    if (data[0]) {
                        let _ids = this.curves[0].ids.reduce((a, b) => a.concat(b), []);
                        for (let i = 0; i < _ids.length; i++) {
                            let values = HxToolsTime.fromHXtoU(data[0].data[_ids[i]]);

                            if (this.curves[0].multiplying_factor) {
                                let d = _.forEach(values, function (value, key) {
                                    values[key] = [value[0], value[1] * that.curves[0].multiplying_factor[0]];
                                });
                            }

                            that._chart.series[i].setData(values);
                            that._chart.series[i].name = this.curves[0].datatype[i];
                        }
                    }
                    this.isLoadingData = false;
                    this._chart.hideLoading();
                },
                    error => { //console.error(error); 

                    }
                );
        }
    }

}

