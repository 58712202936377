export enum Action {
    DISPLAY_ANNOTATION_FORM = 1,
    DISPLAY_RANGE_FORM = 2,
    HIDE_ANNOTATION_FORM = 3,
    HIDE_RANGE_FORM = 4,
    CHANGE_ANNOTATION_TO_DEFAULT_COLOR = 5,
    DISPLAY_ANNOTATION_DELETE_BUTTON = 6,
    DISPLAY_ANNOTATION_CANCEL_BUTTON = 7,
    REFRESH_CHART = 8,
    DELETE_NOT_SAVED_PLOTLINE = 9,
    EMPTY_FORM_FIELDS = 10,
    DISPLAY_RANGE_EDIT_FORM = 11,
    DISPLAY_RANGE_ADD_FORM = 112,
    DISPLAY_RANGE_CANCEL_BUTTON = 12,
    DELETE_PLOTRANGE = 13,
    CANCEL_PLOTRANGE = 131,
    PUT_ANNOTATION_FORM_IN_EDIT_MODE = 14,
    PUT_ANNOTATION_FORM_IN_ADD_MODE = 15,
    DISPLAY_GROWL_RANGE_SAVED = 16,
    DISPLAY_GROWL_RANGE_DELETED = 17,
    DISPLAY_GROWL_ANNOTATION_SAVED = 18,
    DISPLAY_GROWL_ANNOTATION_DELETED = 19,
    DISPLAY_ALARMS = 20,
    HIDE_ALARMS = 21,
    HIGHLIGHT_PLOTRANGE = 23,
    RANGE_FORM_RENDERED = 24,
    DISPLAY_ADD_RANGE = 25,
    DISPLAY_EDIT_RANGE = 26,
    HIGHLIGHT_PLOTANNOTATION = 27,
    CANCEL_PLOTANNOTATION = 28,
    REINIT_CHART = 29,
    ZOOM_MODE = 30
}
