import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ApiService, RangeService, RecordService } from '../../core/services';
import * as moment from 'moment/moment';
import { HxToolsDate, HxToolsTime } from '../../shared/hx-tools';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { SelectorService } from '../../shared/components/selector/selector.service';
import { Message } from '../../shared/components/growl/message';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelpService } from '../../shared/components/help/help.service';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss',
    '../../shared/components/help/help.scss',
    '../../../assets/stylesheets/app.scss',
    '../../../assets/stylesheets/core/color.scss',
    '../../../assets/stylesheets/core/form.scss',
    '../../../assets/stylesheets/app.scss',
    '../../../assets/stylesheets/core/icon.scss',
    '../../../assets/stylesheets/core/slider.scss',
    '../../../assets/stylesheets/core/panel.scss',
    '../../../assets/stylesheets/core/metric.scss',
    '../../../assets/stylesheets/core/section.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends HelperComponent implements OnInit, OnDestroy, AfterViewInit {

  public msgs: Message[] = [];  // Messages for growl
  private records: Map<string, Array<any>>; // Daily records list
  private ranges: Map<string, Array<any>>; // Daily ranges list
  public dates: Array<any>; // List of dates for which there are records/ranges during the day
  public rdates: Array<any>; // List of dates for which there are ranges during the day (no record alone)
  public lastRecord: any; // Last Record to display the information relative to it
  public loading = true; // If data is being loaded
  public showRecords = true; // If records without activities associated are shown
  boxes = []; // Selected views of the page
  start: number; // Start of the range date (unix timestamp)
  end: number; // End of the range date (unix timestamp)
  user: any; // Subject
  isInitiliazed = false;
  selectedPeriod: any;
  selected: any;
  maxDays = 5;
  days_before_msg = 7; // max nbr of days without record before displaying msg.
  totalDays = 0;
  totatDaysWithRanges = 0;
  moreTh5daysInactive = false;

  announcements = [];

  default_boxes = [ { "title": "Quick View", "displayed": true },
                    { "title": "Timelines", "displayed": true }]; // Default selected views of the page (used if not in DB)

  @ViewChild('timeline', { read: ElementRef }) timeline: ElementRef;
  @ViewChild('quickview', { read: ElementRef }) quickview: ElementRef;
  @ViewChild('filter', { read: ElementRef }) filter: ElementRef;

  dateRanges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 100 days': [moment().subtract(100, 'days'), moment()],
    'This year': [moment().startOf('year'), moment()],
  };

  constructor(private store: Store<AppState>,
    private recordService: RecordService,
    private apiService: ApiService,
    private eventAggregator: EventAggregator,
    public globalVariables: GlobalVariables,
    private selectorService: SelectorService,
    private rangeService: RangeService,
    public helpService: HelpService) {
    
    super(helpService);

    this.dates = new Array<any>();
    this.rdates = new Array<any>();
    this.ranges = new Map<string, Array<any>>();
    this.records = new Map<string, Array<any>>();

    store.select('selector').subscribe(_selector => {
      if (_selector.time_range === undefined || _selector.time_range === null) {
        this._retrieveRecordsAndRanges(true);
      } else {
        let range = this.dateRanges[_selector.time_range.label];
        this.start = 256 * range[0].unix();
        this.end = 256 * range[1].unix();
        this._retrieveRecordsAndRanges(false);
        this.selected = {                               // update the selected period to be displayed
          startDate: range[0],
          endDate: range[1]
        };
      }
    });
  }

  ngOnInit(): void {

    this.apiService.getAnnouncements().subscribe(data => {
      this.announcements = data.objects;
    })

    this.user = this.globalVariables.has('currentSubject') ? 
      this.globalVariables.get('currentSubject') : this.globalVariables.get('currentUser');

    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      { msg: HxEvent.MAIN__PARTIAL_SCREEN }));
    this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);

    this._initTimeRange(); // TODO : Add condition data range already in memory
  }

    /**
   * Actions performed after view init 
   * We generate the help boxes
   * 
   * @param {object} record Record object
   */
  ngAfterViewInit() {
    this.afterViewInit();
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
      {
        msg: HxEvent.FSS__UPDATE
      }));
  }

  /**
   * Actions performed when current subject changes
   * 
   * @param {object} record Record object
   */
  private onMessageReceived = (payload: MessageSentEventPayload) => {
    if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
      this.__reset();
      this.loadFirstTimelines();
    }
  }
  
  displayMore() {
    this.maxDays += 5;
  }

  _initTimeRange() {
    const resourceUri = this.globalVariables.get("profileUri");
    this.apiService.getFromUri(resourceUri).subscribe(data => {
      if (data.preferences) {
        if (data.preferences.time_range === undefined || data.preferences.time_range === null) {
          this._retrieveRecordsAndRanges(true);
        } else {
          let range = this.dateRanges[data.preferences.time_range.label];
          this.start = 256 * range[0].unix();
          this.end = 256 * range[1].unix();
          this._retrieveRecordsAndRanges(false);
        }
      }
    });
  }

  /** 
  * Retrieve the records and the ranges for time range selected
  *
  * @param {boolean} lastRecordMonth  if the month of the last record is displayed
  */
  _retrieveRecordsAndRanges(lastRecordMonth: boolean) {

    this.maxDays = 5;
    this.loading = true;
    this.__reset();                                               // reset the range and record arrays
    let user = this.globalVariables.get('currentSubject');        // retrieve user currently selected (not necessarly the same as the user logged in)  
    if (user === undefined) {                                     // If the current subject is not defined, the subject is the logged user
      user = this.globalVariables.get('currentUser');
    }
    let _start = Number.MAX_SAFE_INTEGER;                         // Start of the date range, unix time
    let _end = 0;                                                 // End of the date range, unix time   
    let dates = new Array<any>();
    let rdates = new Array<any>();

    const resourceUri = this.globalVariables.get("profileUri");   // URI for api call to retrieve user profile

    this.apiService.getFromUri(resourceUri).subscribe(data => {
      this.__setDisplayedBoxes(data);
      let box_selectors = {
        'page': 'timeline',
        'boxes': (JSON.parse(JSON.stringify(this.default_boxes)))//this.boxes
      };

      this.selectorService.postBoxes(box_selectors);
    });

    this.recordService.fetchNLast(1, 0, user.id).flatMap(data => {
      if (lastRecordMonth) {                                     // If lastRecordMonth, display month of the last record
        this.__lastRecordMonth(data);
      }
      return this.recordService.fetchPeriod(this.start,           // Retrieve all the records of the period selected
        this.end,
        0, 0, user.id);
    }).flatMap(data => {
      if (data.objects) {
        this.records = new Map<string, Array<any>>();
        for (const record of data.objects) this.__addRecord(dates, record, _start, _end);
      }
      this.dates = dates.sort(function (a, b) {                   // Reverse chronological sorting
        const x = a.startOfDay > b.startOfDay ? -1 : 1;
        return x;
      });
      this.totalDays = this.dates.length;
      return this.rangeService.fetchPeriod(this.end, this.start, null, null, user.id);
      // Retrieving all the ranges included in the period defined by _start and _end
    }).subscribe(data => {
      if (data.objects) {
        this.ranges = new Map<string, Array<any>>();
        for (const range of data.objects) this.__addRange(rdates, range);
      }
      this.rdates = rdates.sort(function (a, b) {                   // Reverse chronological sorting
        const x = a.startOfDay > b.startOfDay ? -1 : 1;
        return x;
      });
      this.totatDaysWithRanges = this.ranges.size;
      this.afterViewInit();
      this.loading = false;
      this.isInitiliazed = true;                                  // Changes values just the first time 
    });

  }

  /** 
  * Actions performed when data range has changed
  *
  * @param {object} $event Date range
  */
  rangeClicked($event) {
    /*    if ($event === undefined) {
          this._retrieveRecordsAndRanges(true);
        } else {
          let range = $event;
          this.start = 256 * range.startDate.startOf('day').unix();
          this.end = 256 * range.endDate.endOf('day').unix();
          this._retrieveRecordsAndRanges(false);
        }
        */
  }

  __reset() {
    this.dates = new Array<any>();
    this.ranges = new Map<string, Array<any>>();
    this.records = new Map<string, Array<any>>();
  }

  /**
   * Add a record to the list of the records to be displayed
   * 
   * @param {object} record Record object
   */
  __addRecord(dates, record, _start, _end) {

    const timestamp = HxToolsDate.toUnixTimestamp(record.start);
    const startOfTheDay = HxToolsDate.getStartOfDay(timestamp);
    const start = moment.unix(timestamp);                 // Record start in unix format
    const endTimestamp = HxToolsDate.toUnixTimestamp(record.end);
    const endOfTheDay = HxToolsDate.getEndOfDay(timestamp);
    const end = moment.unix(endTimestamp);                // Record end in unix format

    if (endOfTheDay > _end) _end = endOfTheDay;
    if (startOfTheDay < _start) _start = startOfTheDay;

    const date = start.format("dddd, MMMM Do YYYY");

    if (!this.records.has(date)) {
      this.records.set(date, new Array<any>());
      dates.push({
        "date": date,
        "startOfDay": startOfTheDay, "endOfDay": endOfTheDay
      });
    }
    // If the record is overnight, then we split the record
    if (HxToolsTime.isOverNight(1000 * timestamp, 1000 * endTimestamp)) {
      this.records.get(date).push(
        {
          "date": date,
          "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
          "dstart": start.hour() + Number(start.minutes()) / 60, "dend": 24,
          "recordId": record.id, "highlighted": false,
          "_start": record.start, "_end": record.end
        }
      );
      const dateDayAfter = start.add(1, 'days').format("dddd, MMMM Do YYYY");
      if (!this.records.has(dateDayAfter)) {
        this.records.set(dateDayAfter, new Array<any>());
        dates.push({
          "date": dateDayAfter,
          "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60
        });
      }
      this.records.get(dateDayAfter).push(
        {
          "date": dateDayAfter,
          "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60,
          "dstart": 0, "dend": end.hour() + Number(end.minutes()) / 60,
          "recordId": record.id, "highlighted": false,
          "_start": record.start, "_end": record.end
        }
      );
    } else {
      this.records.get(date).push(
        {
          "date": date,
          "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
          "dstart": start.hour() + Number(start.minutes()) / 60, "dend": end.hour() + Number(end.minutes()) / 60,
          "recordId": record.id, "highlighted": false,
          "_start": record.start, "_end": record.end
        }
      );
    }
  }

  /**
   * Add a range to the list of the ranges to be displayed
   * 
   * @param {object} range Range object
   */
  __addRange(rdates, range) {

    const startTimestamp = HxToolsDate.toUnixTimestamp(range.start);
    const startOfTheDay = HxToolsDate.getStartOfDay(startTimestamp);   // unix timestamp start of the day of the range
    const start = moment.unix(startTimestamp);                         // unix timestamp start of the range
    const endTimestamp = HxToolsDate.toUnixTimestamp(range.end);
    const endOfTheDay = HxToolsDate.getEndOfDay(startTimestamp);
    const end = moment.unix(endTimestamp);
    const date = start.format("dddd, MMMM Do YYYY");

    if (!this.ranges.has(date)) {                                     //  If no range yet for that date
      this.ranges.set(date, new Array<any>());
      rdates.push({
        "date": date,
        "startOfDay": startOfTheDay, "endOfDay": endOfTheDay
      });
    }
    // If the range is overnight, then we split it

    if (HxToolsTime.isOverNight(1000 * startTimestamp, 1000 * endTimestamp)) {
      this.ranges.get(date).push(
        {
          "date": date,
          "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
          "dstart": start.hour() + Number(start.minutes()) / 60, "dend": 24,
          "rangeId": range.id, "highlighted": false,
          "activity": range.context.activitytype,
          "name": range.name
        }
      );
      const dateDayAfter = start.add(1, 'days').format("dddd, MMMM Do YYYY"); // Next day
      if (!this.ranges.has(dateDayAfter)) {                         //  If no range yet for that date (next day)
        this.ranges.set(dateDayAfter, new Array<any>());
        rdates.push({
          "date": dateDayAfter,
          "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60
        });
      }
      this.ranges.get(dateDayAfter).push(
        {
          "startOfDay": startOfTheDay + 24 * 60 * 60, "endOfDay": endOfTheDay + 24 * 60 * 60,
          "dstart": 0, "dend": end.hour() + Number(end.minutes()) / 60,
          "rangeId": range.id, "highlighted": false,
          "activity": range.context.activitytype,
          "name": range.name
        }
      );
    } else {                                                       // If the range is during the day
      this.ranges.get(date).push(
        {
          "date": date,
          "startOfDay": startOfTheDay, "endOfDay": endOfTheDay,
          "dstart": start.hour() + Number(start.minutes()) / 60, "dend": end.hour() + Number(end.minutes()) / 60,
          "rangeId": range.id, "highlighted": false,
          "activity": range.context.activitytype,
          "name": range.name
        }
      );
    }
  }

  /**
   * Update selected period in the case the month of the last record
   * is to be displayed
   * 
   * @param {array} records List of records sorted in anti-chronological order
   */
  __lastRecordMonth(records) {

    let mostRecentRecordStart;
    this.lastRecord = records.objects[0];             // To display the information relative to the last report

    this.moreTh5daysInactive = false;
    if (this.lastRecord && (Math.floor(Date.now() / 1000) - this.lastRecord.start / 256 > this.days_before_msg * 24 * 60 * 60)) {
      this.moreTh5daysInactive = true;
    }
    
    if (records.objects && records.objects[0]) {      // Check if at least one record 

      mostRecentRecordStart = records.objects[0].start; // Retrieves start time of the last record
      // Converts start time to unix timestamp
      const timestamp = HxToolsDate.toUnixTimestamp(mostRecentRecordStart);
      // Updates start and end
      this.start = 256 * HxToolsDate.getStartOfMonth(timestamp);
      this.end = 256 * HxToolsDate.getEndOfMonth(timestamp);
      // gets timestamps of the first and last day of the month
      let firstDay = moment(HxToolsDate.getStartOfMonth(timestamp), "X");
      let lastDay = moment(HxToolsDate.getEndOfMonth(timestamp), "X");

      this.selected = {                               // update the selected period to be displayed
        startDate: moment(new Date(firstDay.year(), firstDay.month(), firstDay.date(), 5, 0, 0)),
        endDate: moment(new Date(lastDay.year(), lastDay.month(),
          lastDay.date(), 5, 0, 0))
      };
      // Add 'Last record' to the calendar
      this.dateRanges['Last Record'] = [moment(1000 * HxToolsDate.getStartOfMonth(timestamp)), moment(1000 * HxToolsDate.getEndOfMonth(timestamp))];
    } else { // In the case of new user
      let firstDay = moment().subtract(1, 'month');
      let lastDay = moment();
      this.start = firstDay.unix() * 256;
      this.end = lastDay.unix() * 256;
      this.selected = {                               // update the selected period to be displayed
        startDate: moment(new Date(firstDay.year(), firstDay.month(), firstDay.date(), 5, 0, 0)),
        endDate: moment(new Date(lastDay.year(), lastDay.month(),
          lastDay.date(), 5, 0, 0))
      };
    }
  }

  __setDisplayedBoxes(data) {
    if (data.preferences && data.preferences.timeline && data.preferences.timeline.length > 0) {
      // todo : check if tree is valid
      let i = 0;
      let valid = true;
      for (const box of data.preferences.timeline) {
        if (!this.default_boxes[i]) {
          valid = false;
        } else if (box.title !== this.default_boxes[i].title) {
          valid = false;
        }
        i++;
      }
      if (valid) {
        this.boxes = data.preferences.timeline;
      } else {
        this.boxes = this.default_boxes;
      }
    } else {
      this.boxes = this.default_boxes;
    }
  }

  /**
   * Retrieve the last 10 records and ranges on the same period of time.
   */
  loadFirstTimelines() {
    this.loading = true;
    this.user = this.globalVariables.get('currentSubject');
    this._retrieveRecordsAndRanges(true);                               // Display the first timelines
  }

  /**
   * Actions to perform when the component is distroyed
   */
  ngOnDestroy() {
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.SELECTOR__HIDE }));
    this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
    this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
  }


}
