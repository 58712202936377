import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { TimelineTask } from './timeline.task';

/**
 * ChartService allows the chart (highchart) to communicate with others component
 * It allows to post task to be performed
 */
@Injectable()
export class TimelineService {

    // Observable string sources
    private tasks = new Subject<any>();

    // Observable string streams
    tasksConfirmed$ = this.tasks.asObservable();

    /**
     * Post task
     * Allows to send actions to execute to others component
     * @params : task name
     */
    postTask(task: TimelineTask) {


        this.tasks.next(task);
    }

}