﻿import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { HelpService } from './help.service';

@Component({
    selector: 'help-modal',
    template:
    `<div class="help contextual-menus" [ngStyle]="{'top': top}">
            <div class="help-title hx-g">
            <div class="hx-u-4-5">
                Info
                </div>
            <div class="hx-u-1-5" style="text-align:right;cursor:pointer" (click)="close()">
                X
                </div>
            </div>   
            <div class="help-body">
                <ng-content></ng-content>
            </div>
        </div>`,
    styleUrls: ['./help.scss']
})

export class HelpComponent implements OnInit, OnDestroy {
    @Input() id: string;

    top: string = '142px';
    private element: any;

    constructor(private modalService: HelpService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', function (e: any) {
            if (e.target.className === 'help-modal') {
                modal.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(top: string): void {

        this.element.style.display = 'block';
        this.top = top;
        //this.element.style.top = this.top;
        document.body.classList.add('help-modal-open');
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('help-modal-open');
    }
}