var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// Import from current module
import { ConfirmationService } from '../../../shared/components/confirmation/confirmation.service';
import { ApiQuery, ApiService, RecordService } from '../../../core/services';
import { Record } from "../../../models";
import { DataService } from '../../../core/services';
import { Subject } from 'rxjs/Subject';
import { GlobalVariables } from '../../../core/services/global.variables';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import * as moment from 'moment/moment';
var TableData = [];
var RecordsTableComponent = /** @class */ (function (_super) {
    __extends(RecordsTableComponent, _super);
    //private options: Object;
    /**
     * CONSTRUCTOR
     */
    function RecordsTableComponent(recordService, globalVariables, apiService, router, helpService, dataService, confirmationService, viewContainerRef) {
        var _this = _super.call(this, helpService) || this;
        _this.recordService = recordService;
        _this.globalVariables = globalVariables;
        _this.apiService = apiService;
        _this.router = router;
        _this.helpService = helpService;
        _this.dataService = dataService;
        _this.confirmationService = confirmationService;
        _this.viewContainerRef = viewContainerRef;
        /**
         * Array of records
         */
        _this.data = TableData;
        _this.displayedData = TableData;
        _this.myDateRangePickerOptions = {};
        /**
         * Number of records before update
         * If previousDataLength !== number of records then new record(s) has(ve) been added or deleted
         */
        _this.previousDataLength = 0;
        /**
         * Equals to true if the data is being retrieved from the server
         */
        _this.loading = false;
        _this.filter_username = null;
        /**
         * The number of times the server has been called the page has been loaded
         */
        _this.time = 0;
        /**
         * Minimum duration of records
         */
        _this.filterByDuration = 0;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        /**
         * Sorting
         */
        _this.column = "start";
        _this.order = "desc";
        /**
         * Filters
         */
        _this.filterByUser = null;
        /**
         * If we display the last record button
         */
        _this.displayLastRecordBtn = false;
        /**
        * If we display the last record button
        */
        _this.displayResetBtn = true;
        _this.downloadInProgress = false;
        _this.downloadClicked = false;
        /**
         * Table parameters
         */
        _this.maxItemsPerPage = 10;
        _this.currentPage = 1;
        _this.offset = 0;
        _this.durationQueryChanged = new Subject();
        _this.dateRanges = {
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 100 days': [moment().subtract(100, 'days'), moment()],
            'This year': [moment().startOf('year'), moment()],
            'From the beginning': [moment().subtract(10, 'year'), moment()],
        };
        _this.pages = new Array();
        _this.dropdownHitMouseOver = new Array();
        _this.durationQueryChanged
            .debounceTime(1000) // wait 1 sec after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(function (model) {
            _this.durationQuery = model;
            var resourceUri = _this.globalVariables.get("profileUri");
            _this.apiService.getFromUri(resourceUri).flatMap(function (data) {
                _this.currentPage = 1;
                var preferences = data.preferences;
                preferences.filterByDuration = _this.filterByDuration;
                return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
            }).subscribe(function () {
                _this.msgs = [];
                _this.msgs.push({
                    severity: 'success',
                    summary: 'Confirmed',
                    detail: 'Your preference has been saved.'
                });
                _this.getAll();
            }, function (error) {
                console.error(error);
            });
        });
        return _this;
    }
    /**
     * On init
     */
    RecordsTableComponent.prototype.ngOnInit = function () {
        this.router.events.subscribe(function (evt) {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.hasNewRecord = false;
        this.selectedPeriod = {
            startDate: moment().subtract(10, 'year'),
            endDate: moment()
        };
        this.getAll();
    };
    /**
     * Delete new record message
     */
    RecordsTableComponent.prototype.deleteMsg = function () {
        this.hasNewRecord = false;
    };
    /**
     * Display the previous page
     */
    RecordsTableComponent.prototype.nextPage = function () {
        this.currentPage++;
        this.getAll();
    };
    /**
     * Display the next page
     */
    RecordsTableComponent.prototype.previousPage = function () {
        this.currentPage--;
        this.getAll();
    };
    /**
     * Display the page
     */
    RecordsTableComponent.prototype.changeToPage = function (page) {
        this.currentPage = page;
        this.getAll();
    };
    RecordsTableComponent.prototype.onClickGo = function ($event) {
        this.currentPage = 1;
        if ($event !== undefined) {
            var range = $event;
            this.currentPage = 1;
            this.start = 256 * range.startDate.startOf('day').unix();
            this.end = 256 * range.endDate.endOf('day').unix();
        }
        this.getAll();
    };
    RecordsTableComponent.prototype.resetByPage = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            var preferences = data.preferences;
            preferences.maxItemsPerPage = _this.maxItemsPerPage;
            return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(function () {
            _this.msgs = [];
            _this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            _this.getAll();
        }, function (error) {
            console.error(error);
        });
    };
    RecordsTableComponent.prototype.reset = function () {
        this.start = this.end = null;
        this.selectedPeriod = null;
        this.currentPage = 1;
        this.getAll();
    };
    RecordsTableComponent.prototype.updateDuration = function (ev) {
        this.durationQueryChanged.next(ev);
    };
    /**
     * Export of CSV file
     */
    RecordsTableComponent.prototype.exportCSV = function (recordId) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, 'text/csv').subscribe(function (data) {
            var filename = "record-" + recordId + ".csv";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    /**
     * Export of binary file
     */
    RecordsTableComponent.prototype.exportBinary = function (recordId) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, 'application/octet-stream').subscribe(function (data) {
            var filename = "record-" + recordId + ".zip";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    /**
     * Export of EDF file
     */
    RecordsTableComponent.prototype.exportEDF = function (recordId) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,49,19,53";
        this.downloadClicked = false;
        this.recordService.downloadResource(recordId, datatype_query, "application/x-edf").subscribe(function (data) {
            var filename = "record-" + recordId + ".edf";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    RecordsTableComponent.prototype.onSingleOpened = function () {
    };
    RecordsTableComponent.prototype.onSingleClosed = function () {
    };
    RecordsTableComponent.prototype.onSingleSelected = function ($event) {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            _this.currentPage = 1;
            _this.filterByUser = $event.value;
            var preferences = data.preferences;
            preferences.filterByUser = $event.value;
            return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(function () {
            _this.msgs = [];
            _this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            _this.getAll();
        }, function (error) {
            console.error(error);
        });
    };
    RecordsTableComponent.prototype.onSingleDeselected = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            _this.currentPage = 1;
            _this.filterByUser = null;
            var preferences = data.preferences;
            preferences.filterByUser = null;
            return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(function () {
            _this.msgs = [];
            _this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            _this.getAll();
        }, function (error) {
            console.error(error);
        });
    };
    /**
     * Retrieve all the records the logged user is allowed to see
     */
    RecordsTableComponent.prototype.getAll = function () {
        var _this = this;
        this.loading = true;
        var offset = (this.currentPage - 1) * this.maxItemsPerPage;
        if (this.start === undefined || this.end === undefined) {
            this.start = 256 * moment().subtract(10, 'year').unix();
            this.end = 256 * moment().unix();
        }
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            _this.maxItemsPerPage = 10;
            _this.filterByUser = null;
            if (data.preferences) {
                if (data.preferences.maxItemsPerPage) {
                    // todo : check if tree is valid
                    _this.maxItemsPerPage = data.preferences.maxItemsPerPage;
                }
                if (data.preferences.filterByUser) {
                    // todo : check if tree is valid
                    _this.filterByUser = data.preferences.filterByUser;
                }
                if (data.preferences.filterByDuration) {
                    // todo : check if tree is valid
                    _this.filterByDuration = data.preferences.filterByDuration;
                }
            }
            return _this.apiService.getQuery(ApiQuery.all_users_for_user_id);
        }).flatMap(function (data) {
            _this.subjects = new Array();
            for (var i = 0; i < data.objects.length; i++) {
                var subject = {
                    "value": "" + data.objects[i].id,
                    "label": data.objects[i].first_name + " " + data.objects[i].last_name
                };
                _this.subjects.push(subject);
            }
            return _this.recordService.fetch(_this.maxItemsPerPage, offset, _this.filterByUser, _this.column, _this.order === "desc" ? "-" : null, _this.start, _this.end, null, _this.filterByDuration / 1000);
        }).subscribe(function (data) {
            _this.totalRecordsCount = data.meta.total_count;
            for (var i = 0; i < _this.totalRecordsCount; i++) {
                _this.dropdownHitMouseOver[i] = false;
            }
            _this.totalPages = Math.ceil(_this.totalRecordsCount / _this.maxItemsPerPage);
            // Beginning - pagination
            _this.pages = [];
            if (_this.currentPage === 1) {
                _this.pages.push(1);
                if (_this.maxItemsPerPage < _this.totalRecordsCount) {
                    _this.pages.push(2);
                }
                if (_this.maxItemsPerPage * 2 < _this.totalRecordsCount) {
                    _this.pages.push(3);
                }
            }
            if (_this.currentPage >= 2) {
                if (_this.currentPage - 2 > 0)
                    _this.pages.push(_this.currentPage - 2);
                _this.pages.push(_this.currentPage - 1);
                _this.pages.push(_this.currentPage);
                if (_this.maxItemsPerPage * _this.currentPage < _this.totalRecordsCount) {
                    _this.pages.push(_this.currentPage + 1);
                }
            }
            // End - pagination
            _this.data = data;
            var temp_data = _this.populate(data.objects);
            _this.displayedData = temp_data;
            if ((_this.time > 0) && (_this.previousDataLength !== _this.totalRecordsCount)) {
                //this.hasNewRecord = true;
            }
            _this.previousDataLength = _this.totalRecordsCount;
            _this.time++;
            _this.loading = false;
        }, function (error) {
            console.error(error);
        });
    };
    RecordsTableComponent.prototype.decreaseDuration = function () {
        this.filterByDuration = this.filterByDuration - 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    };
    RecordsTableComponent.prototype.increaseDuration = function () {
        this.filterByDuration = this.filterByDuration + 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    };
    RecordsTableComponent.prototype.populate = function (records) {
        var temp_data = [];
        for (var _i = 0, records_1 = records; _i < records_1.length; _i++) {
            var record1 = records_1[_i];
            var str = JSON.stringify(record1);
            var record2 = new Record(str, this.apiService);
            record2.calculate();
            temp_data.push(record2);
        }
        return temp_data;
    };
    /**
     *
     */
    RecordsTableComponent.prototype.paginate = function (data) {
        var temp_data = [];
        data = this.populate(data);
        if (data) {
            this.data = data;
            var start = this.maxItemsPerPage * (this.currentPage - 1);
            var i = 0;
            for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                var record1 = data_1[_i];
                if ((i >= start) && (i < start + this.maxItemsPerPage)) {
                    temp_data.push(record1);
                }
                i++;
            }
        }
        return temp_data;
    };
    /**
     * Delete a record
     * @param {number} id
     */
    RecordsTableComponent.prototype.delete = function (record) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: function () {
                _this.msgs = [];
                _this.loading = true;
                _this.recordService.delete(record.id, "record")
                    .subscribe(function () {
                    _this.time = 0;
                    _this.getAll();
                    _this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Record deleted' });
                    _this.loading = false;
                }, function (error) {
                    console.error(error);
                    _this.loading = false;
                });
            }
        });
    };
    /**
     * Redirect to record view page
     */
    RecordsTableComponent.prototype.view = function (record) {
        var link = ['/record-view/' + record.id];
        this.router.navigate(link);
    };
    /**
     * Redirect to export view page
     */
    RecordsTableComponent.prototype.export = function (record) {
        var link = ['/record-export/' + record.id];
        this.router.navigate(link);
    };
    /**
     * Sort data table
     */
    RecordsTableComponent.prototype.sort = function (property) {
        this.column = property;
        if (this.order === 'asc')
            this.order = 'desc';
        else
            this.order = 'asc';
        this.getAll();
    };
    return RecordsTableComponent;
}(HelperComponent));
export { RecordsTableComponent };
