var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/components/alert/alert.service';
import { AuthenticationService, UserService } from '../../core/services';
import { UserForm } from './user-form';
import 'rxjs/add/operator/mergeMap';
import { equalTo } from '../../shared/validators/password.validator';
import { GlobalVariables } from '../../core/services/global.variables';
var UserPasswordComponent = /** @class */ (function (_super) {
    __extends(UserPasswordComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function UserPasswordComponent(fb, authenticationService, userService, globalVariables, alertService) {
        var _this = _super.call(this, fb) || this;
        _this.authenticationService = authenticationService;
        _this.userService = userService;
        _this.globalVariables = globalVariables;
        _this.alertService = alertService;
        _this.active = true;
        _this.success = false;
        _this.user_id = null;
        _this.valueChange = new EventEmitter();
        /**
         * Messages for growl
         */
        _this.msgs = [];
        /**
         * Error messages
         */
        _this.formErrors = {
            'new_password': '',
            'confirm_password': ''
        };
        _this.validationMessages = {
            'new_password': {
                'required': 'The field is required.',
                'minLength': 'Passwords must be at least 8 characters in length'
            },
            'confirm_password': {
                'required': 'The field is required.',
                'equalTo': 'The passwords are not identical',
                'minLength': 'Passwords must be at least 8 characters in length'
            }
        };
        return _this;
    }
    /**
     * Building the form on init
     */
    UserPasswordComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    /**
     * Actions performed when submit is clicked
     */
    UserPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.userService.save({ 'password': this.form.value.new_password }).subscribe(function (data) {
            _this.msgs = [{ severity: 'success', summary: 'Confirmed', detail: 'The password has been successfully changed.' }];
            _this.valueChange.emit(_this.msgs);
            _this.form.reset();
            _this.success = true;
        }, function (error) {
            _this.alertService.error(error);
        });
    };
    Object.defineProperty(UserPasswordComponent.prototype, "new_password", {
        get: function () { return this.form.get('new_password'); },
        enumerable: true,
        configurable: true
    });
    /**
     * Building form function
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    UserPasswordComponent.prototype.buildForm = function () {
        var _this = this;
        var new_password = new FormControl('', [Validators.required, Validators.minLength(8)]);
        var confirm_password = new FormControl('', equalTo(new_password));
        this.form = new FormGroup({
            new_password: new_password,
            confirm_password: confirm_password
        });
        this.form.valueChanges
            .subscribe(function (data) {
            _this.onValueChanged();
        });
        this.onValueChanged();
    };
    return UserPasswordComponent;
}(UserForm));
export { UserPasswordComponent };
