import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { BoxesSelector, Box } from '../models/boxes-selector';

export const ADD_BOX = 'Add_Box';
export const DELETE_BOX = 'Delete_Box';
export const DISPLAY_SELECTOR = 'Display_Selector';
export const HIDE_SELECTOR = 'Hide_Selector';
export const EMPTY_SELECTOR = 'Empty_Selector';
export const EMPTY_HIDE_SELECTOR = 'EmptyHideSelector';
export const TOGGLE_BOX = 'ToggleBox';
export const UPDATE_PREFERENCES = 'UpdatePreferences';
export const UPDATE_PREFERENCES_SUCCESS = 'UpdatePreferencesSuccess';
export const UPDATE_PREFERENCES_FAILURE = 'UpdatePreferencesFailure';
export const CHANGE_TIME_RANGE = 'Change_Time_Range';

export class AddBox implements Action {
    readonly type = ADD_BOX;

    constructor(public payload: Box) { }
}

export class DeleteBox implements Action {
    readonly type = DELETE_BOX;

    constructor(public name: string) { }
}

export class DisplaySelector implements Action {
    readonly type = DISPLAY_SELECTOR;

    constructor() { }
}

export class HideSelector implements Action {
    readonly type = HIDE_SELECTOR;

    constructor() { }
}

export class EmptySelector implements Action {
    readonly type = EMPTY_SELECTOR;

    constructor() { }
}

export class EmptyHideSelector implements Action {
    readonly type = EMPTY_HIDE_SELECTOR;

    constructor() { }
}

export class ToggleBox implements Action {
    readonly type = TOGGLE_BOX;

    constructor(public payload: Box) { }
}

export class UpdatePreferences implements Action {
    readonly type = UPDATE_PREFERENCES;

    constructor() {}
}

export class UpdatePreferencesSuccess implements Action {
    readonly type = UPDATE_PREFERENCES_SUCCESS;

    constructor() {}
}

export class UpdatePreferencesFailure implements Action {
    readonly type = UPDATE_PREFERENCES_FAILURE;

    constructor() {}
}

export class ChangeTimeRange implements Action {
    readonly type = CHANGE_TIME_RANGE;
    
    constructor(public range: Object) {}   
}

export type Actions = AddBox | DeleteBox | DisplaySelector | HideSelector | EmptySelector | EmptyHideSelector | ToggleBox | UpdatePreferences | UpdatePreferencesSuccess | UpdatePreferencesFailure | ChangeTimeRange;
