var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
// Import from other modules
import { OnInit, OnDestroy, ElementRef, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { ConfigService } from '../../../shared/components/config/config.service';
import { ApiQuery, ApiService, RecordService, RangeService, DataService } from '../../../core/services';
import { Record } from "../../../models";
import { Action, ChartService } from "../../../charts/chart/";
import { MapService } from '../../../shared/components/map/map.service';
import { MapAction } from '../../../shared/components/map/action';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { HxToolsGeo, HxToolsTime, HxToolsRange, HxToolsTimeline } from '../../../shared/hx-tools';
import { Router } from '@angular/router';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../../core/services/global.variables';
import { DataViewComponent } from '../../data-view/data-view.component';
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import { BoxViewComponent } from '../../data-view/box-view.component';
import { ExportComponent } from '../../../shared/components/export/export.component';
import { RedirectionComponent } from '../../../shared/components/redirection/redirection.component';
import { Store } from '@ngrx/store';
import { Mixin } from '../../../core/mixin/mixin-decorator';
import * as _ from 'lodash';
import { METRICS } from '../../../config';
var RecordViewComponent = /** @class */ (function (_super) {
    __extends(RecordViewComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function RecordViewComponent(store, route, recordService, configService, helpService, apiService, globalVariables, router, eventAggregator, selectorService, dataService, mapService, rangeService, chartService) {
        var _this = _super.call(this, store, chartService, apiService, selectorService, globalVariables) || this;
        _this.store = store;
        _this.route = route;
        _this.recordService = recordService;
        _this.configService = configService;
        _this.helpService = helpService;
        _this.apiService = apiService;
        _this.globalVariables = globalVariables;
        _this.router = router;
        _this.eventAggregator = eventAggregator;
        _this.selectorService = selectorService;
        _this.dataService = dataService;
        _this.mapService = mapService;
        _this.rangeService = rangeService;
        _this.chartService = chartService;
        /**
         * State tells whether the user is editing or add range/annotation to record
         */
        _this.state = null;
        /**
         * Equals to true if the data is being retrieved from the server
         */
        _this.loading = true;
        /**
         * If processed data charts are displayed
         */
        _this.displayProcessedData = true;
        /**
         * If raw data charts are displayed
         */
        _this.displayRawData = false;
        /**
         * All the displayable processed data charts
         */
        _this.charts = [];
        _this.processedCharts = [];
        /**
         * All the displayable raw data charts
         */
        _this.rawCharts = [];
        /**
         * Messages for growl
         */
        _this.msgs = [];
        _this.metricValues = [];
        _this.displayNavigatorRawData = true;
        _this.displayNavigatorProcessedData = false;
        _this.styleDisappear = "@-webkit-keyframes disappear{ 0% { /* Sets the initial height to the measured height of the element */ height: ##height##;\n  } 30% { -webkit-transform: translateX(-5%); transform: translateX(-5%); } 60% { -webkit-transform: translateX(200%); transform:\n  translateX(200%); /* Makes sur the height and bottom margin stay constant till this point in the animation */ height: ##height##;\n  margin-bottom: 1em; } 100% { /* Keeps the element out of the screen. If it's not there, * the element comes back to its\n  original position */ -webkit-transform: translateX(200%); transform: translateX(200%); /* Makes the blank space collapse\n  */ height: 0; margin-bottom: 0; margin-top: 0;} } @keyframes disappear{ 0% { /* Sets the initial height to the measured height of the\n  element */ height: ##height##; } 30% { -webkit-transform: translateX(-5%); transform: translateX(-5%); } 60% { -webkit-transform:\n  translateX(200%); transform: translateX(200%); /* Makes sur the height and bottom margin stay constant till this point\n  in the animation */ height: ##height##; margin-bottom: 1em; } 100% { /* Keeps the element out of the screen. If it's not\n  there, * the element comes back to its original position */ -webkit-transform: translateX(200%); transform: translateX(200%);\n  /* Makes the blank space collapse */ height: 0; margin-bottom: 0; margin-top: 0;} }";
        _this.boxes = [];
        /* Variables for timeline */
        _this.time = "diurnal"; // or noctural
        _this.startHour = 0;
        _this.endHour = 24;
        _this.annotations = [];
        _this.isOverNight = false;
        _this.previousRecordId = null;
        _this.nextRecordId = null;
        _this.helps = [];
        /**
         * Treatment of messages received from event aggregator
         */
        _this.onMessageReceived = function (payload) {
            if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
                _this.router.navigate(['/home']);
            }
        };
        _this.metrics = METRICS;
        _this.eventAggregator.getEvent(MessageSentEvent).subscribe(_this.onMessageReceived);
        _this.rangesOfDay = new Array();
        _this.metricsToDisplay = new Array();
        _this.altitudes = new Array();
        _this.altitudeDistances = new Array();
        _this.gpsPositions = new Array();
        _this.mapService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === MapAction.CLICK_ON_ROUTE_PATH) {
            }
        });
        _this.displayView = new Map();
        _this.eventAggregator.getEvent(MessageSentEvent).subscribe(_this.onMessageReceived);
        //-------------------------------- Chart related actions  ---------------------------------//
        _this.chartService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === Action.REFRESH_CHART) {
                _this.rangeService.fetchPeriod(_this.record.end, _this.record.start, null, null, _this.userId, "start").subscribe(function (data) {
                    _this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, _this.record, _this.startOfTheDay, _this.endOfTheDay);
                });
                _this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
                    user: _this.userId,
                    start: _this.record.start, end: _this.record.end
                }).subscribe(function (_annotations) {
                    _this.annotations = _annotations.objects;
                });
            }
            if (task.action === Action.DISPLAY_RANGE_EDIT_FORM) {
                _this.displayForm('EDIT_RANGE');
            }
            if (task.action === Action.HIDE_RANGE_FORM) {
                _this.editableRange = null;
            }
        });
        return _this;
    }
    RecordViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.SELECTOR__DISPLAY
        }));
        // We retrieve the record Id from the URL
        this.sub = this.route.params.subscribe(function (params) {
            _this.recordId = +params['id'];
            _this.init();
        });
    };
    RecordViewComponent.prototype.afterViewInit = function () {
        var _this = this;
        this.sections.forEach(function (_section) {
            _this.helps[_section.nativeElement.id] = false;
        });
    };
    RecordViewComponent.prototype.openModalChartsBtn = function () {
        var _top = 0 + this.chartsbtn.nativeElement.getBoundingClientRect().top + window.scrollY;
        this.helpService.open("chartsbtn", _top + 'px');
    };
    RecordViewComponent.prototype.init = function () {
        var _this = this;
        var user = this.globalVariables.get('currentSubject');
        this.userId = user.id;
        this.curves = new Array();
        this.curvesRaw = new Array();
        this.curvesQueue = new Array();
        this.curvesRawQueue = new Array();
        // By default we only display the first curve of each graph
        this.curvesQueue.push(0);
        this.curvesRawQueue.push(0);
        this.loading = true;
        this.initBoxes();
        // We retrieve the default config
        this.configService.read('assets/config/config-raw.json').flatMap(function (data) {
            _this.rawCharts = data;
            return _this.configService.read('assets/config/config.json');
        }).flatMap(function (data) {
            _this.processedCharts = data;
            return _this.recordService.get(_this.recordId);
        }).flatMap(function (data) {
            var str = JSON.stringify(data);
            var record = new Record(str, _this.apiService);
            record.calculate();
            _this.user = record._user.first_name + " " + record._user.last_name;
            _this.userId = record._user.id;
            // change current user
            _this.globalVariables.set('currentSubject', record.user);
            _this.globalVariables.set('currentSubjectId', "" + record._user.id);
            /*this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
              {
                msg: HxEvent.FSS__MOUSECLICK_SUBJECT
              }));*/
            /*************** Charts **************/
            var chartSet;
            // Raw Data Charts
            chartSet = new Object();
            for (var _i = 0, _a = _this.curvesRawQueue; _i < _a.length; _i++) {
                var id = _a[_i];
                _this._addCurve(chartSet, _this.rawCharts[id]);
            }
            _this.curvesRaw[0] = chartSet;
            // Processed Data Charts (check if datatype is relevant for activity)
            _this.charts = new Array();
            for (var _b = 0, _c = _this.processedCharts; _b < _c.length; _b++) {
                var chart = _c[_b];
                var found = true;
                if (chart.activitytypes && chart.activitytypes.length > 0) {
                    found = false;
                }
                if (found) {
                    _this.charts.push(chart);
                }
            }
            chartSet = new Object();
            for (var _d = 0, _e = _this.curvesQueue; _d < _e.length; _d++) {
                var id = _e[_d];
                _this._addCurve(chartSet, _this.charts[id]);
            }
            _this.curves[0] = chartSet;
            /*************** End charts **************/
            _this.record = record;
            _this.metricValues = record.metrics;
            var parameters = HxToolsTimeline.getParameters(record);
            _this.time = parameters.time;
            _this.startHour = parameters.startHour;
            _this.endHour = parameters.endHour;
            _this.startOfTheDay = parameters.startOfTheDay;
            _this.endOfTheDay = parameters.endOfTheDay;
            record.calculate();
            _this.userId = _this.record.user ? _this.record._user.id : null;
            // Retrieve all the records of the day
            return _this.recordService.fetchPeriod(_this.startOfTheDay * 256, _this.endOfTheDay * 256, 0, 0, _this.userId);
        }).flatMap(function (data) {
            // empty the records array
            _this.isOverNight = HxToolsTime.isOverNight(1000 * _this.record.start / 256, 1000 * _this.record.end / 256);
            _this.records = new Array();
            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                var record = _a[_i];
                var intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(record.start), HxToolsTime.HX2U(record.end), _this.startOfTheDay * 1000, _this.isOverNight);
                _this.records.push({
                    "start": record.start,
                    "end": record.end,
                    "dstart": intervals[0],
                    "dend": intervals[1],
                    "recordId": record.id,
                    "highlighted": _this.record.id === record.id ? true : false
                });
            }
            return _this.rangeService.fetchPeriod(_this.endOfTheDay * 256, _this.startOfTheDay * 256, 0, 0, _this.userId);
        }).flatMap(function (_ranges) {
            _this.rangesOfDay = new Array();
            for (var _i = 0, _a = _ranges.objects; _i < _a.length; _i++) {
                var range = _a[_i];
                var intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(range.start), HxToolsTime.HX2U(range.end), _this.startOfTheDay * 1000, _this.isOverNight);
                _this.rangesOfDay.push({
                    "start": range.start,
                    "end": range.end,
                    "dstart": intervals[0],
                    "dend": intervals[1],
                    "rangeId": range.id,
                    "highlighted": false
                });
            }
            /*  return this.rangeService.fetchPeriod(this.record.end, this.record.start, 0, 0, this.userId);
            }).flatMap(data => {
        
              /*this.metricsToDisplay = new Array<number>();
              if (data.objects) {
                for (const range of data.objects) {
                  for (const metric of range.metrics) {
                    this.metricsToDisplay.push(metric.id);
                  }
                }
              }
              // If there is no activity inside the record, then we display default metrics
              if (this.metricsToDisplay.length === 0) {*/
            _this.metricsToDisplay = [12, 14, 15, 257, 71, 44, 46, 47, 149, 58, 255];
            /*}*/
            return _this.recordService.get(_this.recordId, _this.metricsToDisplay);
        }).flatMap(function (data) {
            _this.metricValues = data.metrics;
            // return this.gpsService.get(this.record._user.id, this.record.start, this.record.end);
            return _this.apiService.getQuery(ApiQuery.retrieve_gps_positions, {
                user_id: _this.record._user.id,
                start: _this.record.start,
                end: _this.record.end
            });
        }).flatMap(function (data) {
            _this.gpsPositions = data['objects'];
            _this.altitudes = HxToolsGeo.retrieveAltitudeFromGPS(_this.gpsPositions);
            return _this.apiService.getQuery(ApiQuery.all_activitiytype);
        }).flatMap(function (data) {
            var activityTypes = data.objects.filter(function (activitiy) { return activitiy.role === 'system'; });
            var _activityTypes = activityTypes;
            for (var i = 0; i < _activityTypes.length; i++) {
                _activityTypes[i].label = _activityTypes[i].name;
                _activityTypes[i].icon = HxToolsActivity.activityTypeToIconClass(_activityTypes[i].name);
                _activityTypes[i].value = "" + _activityTypes[i].id;
            }
            _this.activityTypes = _.sortBy(_activityTypes, 'label');
            // add training routine : 5 mins rest test (hardcoded...)
            _this.activityTypes.push({ 'label': '5 Minutes Rest Test', 'id': 1001016, 'value': 1001016, 'icon': "hx-icon-5-minute-rest-test" });
            return _this.recordService.getNext(_this.record.end, _this.userId);
        }).flatMap(function (data) {
            _this.nextRecordId = null;
            if (data.objects.length > 0) {
                _this.nextRecordId = data.objects[0].id;
            }
            return _this.recordService.getPrevious(_this.record.start, _this.userId);
        }).flatMap(function (data) {
            _this.previousRecordId = null;
            if (data.objects.length > 0) {
                _this.previousRecordId = data.objects[0].id;
            }
            _this.loading = false;
            return _this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
                user: _this.userId,
                start: _this.record.start, end: _this.record.end
            });
        }).flatMap(function (_annotations) {
            _this.annotations = _annotations.objects;
            return _this.rangeService.fetchPeriod(_this.record.end, _this.record.start, null, null, _this.userId, "start");
        }).subscribe(function (data) {
            _this.subRanges = HxToolsRange.getRangesOfTheDayForTimelineForRecord(data.objects, _this.startOfTheDay, _this.endOfTheDay);
            _this.loading = false;
        });
    };
    /**
     * Perform the actions corresponding to the events emitted
     * by others components
     */
    RecordViewComponent.prototype.performAction = function (action) {
        _super.prototype.performAction.call(this, action);
    };
    RecordViewComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.SELECTOR__HIDE
        }));
    };
    RecordViewComponent = __decorate([
        Mixin([BoxViewComponent, RedirectionComponent, ExportComponent, HelperComponent]),
        __metadata("design:paramtypes", [Store,
            ActivatedRoute,
            RecordService,
            ConfigService,
            HelpService,
            ApiService,
            GlobalVariables,
            Router,
            EventAggregator,
            SelectorService,
            DataService,
            MapService,
            RangeService,
            ChartService])
    ], RecordViewComponent);
    return RecordViewComponent;
}(DataViewComponent));
export { RecordViewComponent };
