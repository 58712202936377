<div class="hx-date rrbox">
  {{ date.date }}
</div>

<div *ngIf="loading" class="empty_box" style="background-color: white; padding:10px; height:92">
  <img class="spinner" src="assets/images/spinner.gif">
</div>
<div *ngIf="!loading" class="hx-g">
  <div class="hx-u-8-24">
    <div class="hx-g">
      <div *ngFor="let metric of metrics; trackBy : metricName" class="hx-u-6-24">
        <metric-small [ngClass]="{ 'hx-unavailable-metric' : metric.value === 0 }" [unit]="metric.value | transformUnit:metric.unit"
          [title]="metric.title" [value]="metric.value | roundWithUnit:metric.unit" [icon]="metric.unit?.si_short | toIcon "></metric-small>
      </div>
    </div>
  </div>
  <div class="hx-u-16-24" style="background-color: white;opacity:0.8; padding: 0px 0">
    <div style="vertical-align: bottom;padding:4px;">
      <app-timeline [records]="records" [time]="time" [startHour]="start" [endHour]="end" [ranges]="ranges" (evMouseOverRangeMetrics)="evMouseOverRangeMetrics($event)"
        (evMouseOverRange2Metrics)="evMouseOverRange2Metrics($event)" (evRemoveRangeMetrics)="evRemoveRangeMetrics()" (evClickRange)="evClickRange($event)"></app-timeline>
    </div>
  </div>
</div>