import { Component } from '@angular/core';
import { TopMenuService } from "./top-menu.service";
import { ApiQuery, ApiService, UserService } from '../../../core/services';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { GlobalVariables } from '../../../core/services/global.variables';
import 'rxjs/add/operator/filter';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';

export interface View {
    name: string;
    displayed: boolean;
}
export interface AppState {
    readonly view: View[];
}

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['../../../../assets/stylesheets/core/menu.scss']
})
export class TopMenuComponent {
    showNavBar = false; //todo

    public athletes: Array<any> = [];
    public loading = true;
    public activeSubjectId = null;
    public currentSubjectId: number;
    public currentSubject: any;
    public boxes: Array<any>;
    public search_menu = false;
    public profile_menu = false;
    public athlete_menu = false;
    public views_menu = false;

    public page = '';

    constructor(
        private store: Store<AppState>,
        private topMenuService: TopMenuService,
        private globalVariables: GlobalVariables,
        private eventAggregator: EventAggregator,
        private location: Location,
        private selectorService: SelectorService,
        private userService: UserService,
        private apiService: ApiService) {

        let urlParts = this.location.path().replace('/', '').split('/');
        this.page = urlParts[0] ? urlParts[0] : '';

        this.topMenuService.showNavBarEmitter.subscribe((mode) => {
            // mode will be null the first time it is created, so you need to ignore it when null
            if (mode !== null) {
                this.showNavBar = mode;
            }
            if (mode === true) {
                this.apiService.getQuery(ApiQuery.all_users_for_user_id).subscribe(data => {
                    this.athletes = data.objects;

                    this.activeSubjectId = this.globalVariables.has('currentSubjectId') ? this.globalVariables.get('currentSubjectId') : this.globalVariables.get('currentUserId');
                    this.currentSubject = this.globalVariables.has('currentSubject') ? this.globalVariables.get('currentSubject') : this.globalVariables.get('currentUser');

                    this.loading = false;
                });
            }
        });
        /*this.router.events
            .filter(event => event instanceof NavigationStart)
            .subscribe((event: Event) => {
                let params = this.activatedRoute.snapshot.queryParams;
                // You only receive NavigationStart events
            });*/
    }

    switchSubject(subjectId: number) {

        this.userService.get(subjectId).subscribe(data => {
            this.globalVariables.set('currentSubjectId', data.id);
            this.globalVariables.set('currentSubject', data);
            this.currentSubject = data;
            this.currentSubjectId = data.id;
            this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                {
                    msg: HxEvent.FSS__MOUSECLICK_SUBJECT
                }));
        });
    }

    switchScreen() {
        let message;
        if (this.globalVariables.get("screen") && this.globalVariables.get("screen") === "full") {
            message = HxEvent.MAIN__PARTIAL_SCREEN;

            this.globalVariables.set("screen", "partial");
        } else {
            this.globalVariables.set("screen", "full");
            message = HxEvent.MAIN__FULL_SCREEN;
        }

        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
            {
                msg: message
            }));
    }

    logout() {
        //this.userService.clearAll();
        localStorage.clear();

        let searchParams = new URLSearchParams();
        searchParams.append('client_id', environment.clientId);
        searchParams.append('redirect_uri', environment.redirectURL);

        let oauthurl = new URL(environment.apiURL);
        oauthurl.search = searchParams.toString();

        console.log(oauthurl.search);
        //window.location.href = oauthurl.search;

        window.location.href = environment.apiURL + "/api/connect/logout/?" + oauthurl.search;
    }

}
