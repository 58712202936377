import { Component, OnInit } from "@angular/core";
import { Profile } from "../../models/profile";
import { GlobalVariables } from '../../core/services/global.variables';

/**
 * The profile component
 */
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss', '../../../assets/stylesheets/core/section.scss']
})
export class ProfileComponent implements OnInit {

    profile: Profile;
    public id: number;
    public user_id: number;
    public profile_id: number;
    public loading = true;
    public user;
    public profileOwner = false;
    public form: string;
    public msgs: string;

    constructor(private globalVariables: GlobalVariables) {
        this.form = "info";
    }

    ngOnInit() {
        this.user = this.globalVariables.get('currentUser');
    }

    displayForm(form: string) {

        this.form = form;
    }

    displayMsg(ev) {
        this.msgs = ev;
    }

}
