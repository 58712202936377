import { ContentChildren, Component, QueryList, EventEmitter, Input, AfterContentInit, Output, forwardRef } from '@angular/core';

import { DurationPickerUnitComponent } from './duration-picker-unit.component';
import * as moment from 'moment/moment';

@Component({
    selector: 'duration-picker',
    template: `
	<ng-content></ng-content>
  `,
    styles: []
})
export class DurationPickerComponent implements AfterContentInit {

    @Input() returnedValueUnit: any;
    @Input() inputDisabled: boolean;

    @Output() onNumberChanged: EventEmitter<any> = new EventEmitter();

    @ContentChildren(forwardRef(() => DurationPickerUnitComponent)) units: QueryList<DurationPickerUnitComponent>;

    days: number;
    weeks: number;
    months: number;
    years: number;

    constructor() { }

    supportedUnits: any = {
        millisecond: {
            'label': 'Millisecond',
            'max': 999,
            'min': 0,
            'value': 0,
            'step': 1
        },
        second: {
            'label': 'Second',
            'max': 59,
            'min': 0,
            'value': 0,
            'step': 1
        },
        minute: {
            'label': 'Minute',
            'max': 59,
            'min': 0,
            'value': 0,
            'step': 1
        },
        hour: {
            'label': 'Hour',
            'max': 23,
            'min': 0,
            'value': 0,
            'step': 1
        },
        day: {
            'label': 'Day',
            'max': 7,
            'min': 0,
            'value': 0,
            'step': 1
        },
        week: {
            'label': 'Week',
            'max': 51,
            'min': 0,
            'value': 0,
            'step': 1
        },
        month: {
            'label': 'Month',
            'max': 12,
            'min': 0,
            'value': 0,
            'step': 1
        },
        year: {
            'label': 'Year',
            'max': 25,
            'min': 0,
            'value': 0,
            'step': 1
        }
    };

    private convert: any = {
        'year': {
            'second': 604800 * 365
        },
        'month': {
            'second': 604800
        },
        'week': {
            'second': 604800
        },
        'day': {
            'second': 86400
        },
        'hour': {
            'second': 3600
        }
    };

    ngAfterContentInit() {
        if (!this.returnedValueUnit) {
            this.returnedValueUnit = 'second';
        }
        this.returnedValueUnit = this.returnedValueUnit.trim().toLowerCase();
        if (this.inputDisabled === null) {
            this.inputDisabled = true;
        }
    }

    public updateValue() {
        this.onNumberChanged.emit({
            "date": this.getValue(),
            "range": [this.days, this.weeks, this.months, this.years]
        });
    }

    public getValue() {
        let value = 0;
        let stringDuration = "";
        let now = moment();

       this.units.toArray().forEach(element => {
            if (element.name.toLocaleLowerCase().toString() === "day") {
                this.days = element.getValue();
                now = now.subtract(element.getValue(), "day");
            }
            if (element.name.toLocaleLowerCase().toString() === "week") {
                this.weeks = element.getValue();
                now = now.subtract(element.getValue(), "week");
            }
            if (element.name.toLocaleLowerCase().toString() === "month") {
                this.months = element.getValue();
                now = now.subtract(element.getValue(), "month");
            }
            if (element.name.toLocaleLowerCase().toString() === "year") {
                this.years = element.getValue();
                now = now.subtract(element.getValue(), "year");
            }
        });
        return now;


        /*        this.units.toArray().forEach(element => {
                    value += this.convert[element.name.toLocaleLowerCase()][this.returnedValueUnit] * element.getValue();
                });*/
        //      return value;
    }
}