/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-month-cell.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "./calendar-month-cell.component";
import * as i4 from "./calendar-month-view-header.component.ngfactory";
import * as i5 from "./calendar-month-view-header.component";
import * as i6 from "./calendar-month-view.component";
import * as i7 from "../../providers/calendar-utils.provider";
var styles_CalendarMonthViewComponent = [];
var RenderType_CalendarMonthViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarMonthViewComponent, data: {} });
export { RenderType_CalendarMonthViewComponent as RenderType_CalendarMonthViewComponent };
function View_CalendarMonthViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-calendar-month-cell", [["class", "cal-cell cal-day-cell"]], [[2, "cal-drag-over", null], [2, "cal-past", null], [2, "cal-today", null], [2, "cal-future", null], [2, "cal-weekend", null], [2, "cal-in-month", null], [2, "cal-out-month", null], [2, "cal-has-events", null], [2, "cal-open", null], [4, "backgroundColor", null]], [[null, "click"], [null, "highlightDay"], [null, "unhighlightDay"], [null, "eventClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dayClicked.emit({ day: _v.context.$implicit }) !== false);
        ad = (pd_0 && ad);
    } if (("highlightDay" === en)) {
        var pd_1 = (_co.toggleDayHighlight($event.event, true) !== false);
        ad = (pd_1 && ad);
    } if (("unhighlightDay" === en)) {
        var pd_2 = (_co.toggleDayHighlight($event.event, false) !== false);
        ad = (pd_2 && ad);
    } if (("eventClicked" === en)) {
        var pd_3 = (_co.eventClicked.emit({ event: $event.event }) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_CalendarMonthCellComponent_0, i1.RenderType_CalendarMonthCellComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 49152, null, 0, i3.CalendarMonthCellComponent, [], { day: [0, "day"], openDay: [1, "openDay"], locale: [2, "locale"], tooltipPlacement: [3, "tooltipPlacement"], tooltipAppendToBody: [4, "tooltipAppendToBody"], customTemplate: [5, "customTemplate"], tooltipTemplate: [6, "tooltipTemplate"] }, { highlightDay: "highlightDay", unhighlightDay: "unhighlightDay", eventClicked: "eventClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_10 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.cssClass); _ck(_v, 1, 0, currVal_10); var currVal_11 = _v.context.$implicit; var currVal_12 = _co.openDay; var currVal_13 = _co.locale; var currVal_14 = _co.tooltipPlacement; var currVal_15 = _co.tooltipAppendToBody; var currVal_16 = _co.cellTemplate; var currVal_17 = _co.tooltipTemplate; _ck(_v, 2, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.dragOver; var currVal_1 = i0.ɵnov(_v, 2).day.isPast; var currVal_2 = i0.ɵnov(_v, 2).day.isToday; var currVal_3 = i0.ɵnov(_v, 2).day.isFuture; var currVal_4 = i0.ɵnov(_v, 2).day.isWeekend; var currVal_5 = i0.ɵnov(_v, 2).day.inMonth; var currVal_6 = !i0.ɵnov(_v, 2).day.inMonth; var currVal_7 = (i0.ɵnov(_v, 2).day.events.length > 0); var currVal_8 = (i0.ɵnov(_v, 2).day === i0.ɵnov(_v, 2).openDay); var currVal_9 = i0.ɵnov(_v, 2).day.backgroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_CalendarMonthViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "cal-cell-row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarMonthViewComponent_2)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i2.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.view.days, _v.context.$implicit, (_v.context.$implicit + _co.view.totalDaysVisibleInWeek))); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CalendarMonthViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "cal-month-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-calendar-month-view-header", [], null, null, null, i4.View_CalendarMonthViewHeaderComponent_0, i4.RenderType_CalendarMonthViewHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i5.CalendarMonthViewHeaderComponent, [], { days: [0, "days"], locale: [1, "locale"], customTemplate: [2, "customTemplate"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "cal-days"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthViewComponent_1)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnHeaders; var currVal_1 = _co.locale; var currVal_2 = _co.headerTemplate; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.view.rowOffsets; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_CalendarMonthViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-month-view", [], null, null, null, View_CalendarMonthViewComponent_0, RenderType_CalendarMonthViewComponent)), i0.ɵdid(1, 770048, null, 0, i6.CalendarMonthViewComponent, [i0.ChangeDetectorRef, i7.CalendarUtils, i0.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarMonthViewComponentNgFactory = i0.ɵccf("app-calendar-month-view", i6.CalendarMonthViewComponent, View_CalendarMonthViewComponent_Host_0, { viewDate: "viewDate", events: "events", excludeDays: "excludeDays", activeDayIsOpen: "activeDayIsOpen", refresh: "refresh", locale: "locale", tooltipPlacement: "tooltipPlacement", tooltipTemplate: "tooltipTemplate", tooltipAppendToBody: "tooltipAppendToBody", weekStartsOn: "weekStartsOn", headerTemplate: "headerTemplate", cellTemplate: "cellTemplate", openDayEventsTemplate: "openDayEventsTemplate", weekendDays: "weekendDays" }, { beforeViewRender: "beforeViewRender", dayClicked: "dayClicked", eventClicked: "eventClicked", eventTimesChanged: "eventTimesChanged" }, []);
export { CalendarMonthViewComponentNgFactory as CalendarMonthViewComponentNgFactory };
