<div class="metric-box-grid">
  <div class="hx-g">
    <div class="hx-u-1-1 hx-metric-box-heading">
      <h5 class="metric-box-title">
        <i class="hx-icon hx-icon-{{ icon }}" ng-class="headerIcon"></i>{{ title }}
        <small class="hidden-xs">({{ unity }})</small>
      </h5>
    </div>
  </div>
  <div class="hx-g metric-box-body">
    <div class="hx-u-12-24">
      <div class="hx-pane left" style="text-align:center;">
        <div style="margin: 0 auto;">
          <div class="hx-icon hx-icon-average"></div>
          <div>{{ avg }}</div>
        </div>
      </div>
    </div>
    <div class="hx-u-12-24">
      <div *ngIf="type === 'min'" class="hx-pane minright" style="text-align:center;">
        <div style="margin: 0 auto;">
          <div class="hx-icon hx-icon-caret-down"></div>
          <div>{{ max }}</div>
        </div>
      </div>
      <div *ngIf="type === 'max'" class="hx-pane maxright" style="text-align:center;">
        <div style="margin: 0 auto;">
          <div class="hx-icon hx-icon-caret-up"></div>
          <div>{{ max }}</div>
        </div>
      </div>
    </div>
  </div>