export class Box {
    page: string;
    name: string;
    label: string;
    displayed: boolean;
    kpis: Array<any>;
    order: number;

    constructor(page?: string, name?: string, label?: string, displayed?: boolean, order?: number, kpis?: Array<any>) {
        this.page = page;
        this.name = name;
        this.label = label;
        this.displayed = displayed;
        this.kpis = kpis;
        this.order = order;
    }
}

export class BoxesSelector {
    boxes: Map<string, Box>;
    shown: boolean;
 
    constructor(boxes: Map<string, Box> = new Map(), shown: boolean= false) {
        this.boxes = boxes;
        this.shown = shown;
    }
}
