// Import from other modules
import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { RangeService } from '../../../core/services';
import { RecordViewService } from '../../records/record-view';
import { Action } from "../../../charts/chart/action";
import { GrowlMessageEvent } from '../../../shared/components/growl';
import { Range } from '../../../models';
import { ChartService, ChartTask } from '../../../charts/chart';
import { ConfirmationService } from '../../../shared/components/confirmation';
import { Message } from '../../../shared/components/growl/message';


@Component({
    selector: 'record-range',
    templateUrl: './record-range.component.html',
    styleUrls: ['./record-range.component.scss', '../../../../assets/stylesheets/core/form.scss', '../../../../assets/stylesheets/button.scss']
})
export class RecordRangeComponent implements OnInit {

    @Input() data: any; // to delete
    @Input()
    from: string;

    @Input()
    to: string;

    @Input()
    nextRank: number = null;

    /** 
     * Note of the annotation
     */
    @Input() name: string;

    /** 
     * Edit or add
     */
    @Input() mode: string;

    /**
     *  Range unique identifier 
     */
    @Input() id: number;

    /** 
     * User id
     */
    @Input() user: number;

    /**
     * Event Emitter when plot line added on a chart
     */
    @Output()
    rangeLabel: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event Emitter for growl messages
     */
    @Output()
    growlMessageLabel: EventEmitter<GrowlMessageEvent> = new EventEmitter<GrowlMessageEvent>();

    /**
     * Event Emitter when on action must be performed by parent component
     */
    @Output()
    action: EventEmitter<Action> = new EventEmitter<Action>();

    @Input() selectedActivityType: any;

    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    @Input() activityTypes;
    public editable_range: Range;
    public deletableRange: boolean;
    //

    multiple0: boolean = false;
    multiple1: boolean = true;
    options0: Array<any> = [];
    options1: Array<any> = [];
    selection: Array<string>;

    @ViewChild('preSingle') preSingle;
    @ViewChild('preMultiple') preMultiple;

    logSingleString: string = '';
    logMultipleString: string = '';

    constructor(public route: ActivatedRoute,
        public recordViewService: RecordViewService,
        public chartService: ChartService,
        public confirmationService: ConfirmationService,
        public rangeService: RangeService) {

        this.chartService.tasksConfirmed$.subscribe(
            task => {
                if (task.action === Action.DISPLAY_RANGE_EDIT_FORM) {
                    this.mode = "edit";
                    this.deletableRange = true;
                } else if (task.action === Action.DISPLAY_RANGE_ADD_FORM) {
                    this.mode = "add";
                } else if (task.action === Action.DISPLAY_RANGE_CANCEL_BUTTON) {
                    this.deletableRange = false;
                }
            });
    }


    ngOnInit() {
    }

    /**
     * Delete a range
     */
    delete() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this activity?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: () => {
                this.rangeService.delete(this.id, "range")
                    .subscribe(() => {
                        this.action.emit(Action.DISPLAY_GROWL_RANGE_DELETED);
                        this.action.emit(Action.HIDE_RANGE_FORM);
                        this.action.emit(Action.REFRESH_CHART);
                        // Send order to delete plotband to chart component
                        this.chartService.postTask(new ChartTask(Action.DELETE_PLOTRANGE, "plotband", this.id));
                        // Empty the annotation form fields
                        this.chartService.postTask(new ChartTask(Action.EMPTY_FORM_FIELDS));
                        this.chartService.postTask(new ChartTask(Action.REFRESH_CHART));
                    });
            }
        });
    }

    onSingleOpened() {
    }

    onSingleClosed() {
    }

    onSingleSelected() {
        console.log(this.selectedActivityType);
    }

    onSingleDeselected() {
        this.selectedActivityType = null;
    }

    save() {
        this.rangeLabel.emit(this.name);  // to do
        let range = new Range();
        range.name = this.name;
        range.start = Math.floor(+this.from);
        range.end = Math.floor(+this.to);
        range.datatype = 4098; // default datatype for manual annotation
        range.user = "" + this.user;
        if (this.nextRank) {
            range.rank = this.nextRank;
        }

        //range.user = user // to do
        range.context = new Object();
        if (this.selectedActivityType) {
            if (this.selectedActivityType > 1000000) {
                // if a training routine. ID bigger than 1000000 correspond to trainingroutine.
                range.context.trainingroutine = this.selectedActivityType - 1000000;
                if (range.context.trainingroutine === 1016) {
                    range.end = range.start + 5 * 60 * 256; // rest test must last 5 mins, not less, not more
                    this.to = range.end + ''; 
                }
            } else {
                // if an activity type
                range.context.activitytype = this.selectedActivityType;
            }
            
        }
        this.rangeService.save(range, "range").subscribe(
            () => {
                this.action.emit(Action.DISPLAY_GROWL_RANGE_SAVED);
                this.action.emit(Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR);
                this.action.emit(Action.HIDE_RANGE_FORM);
                this.action.emit(Action.REFRESH_CHART);
            });
            
    }

    update() {
        this.rangeLabel.emit(this.name);  // to do
        let range = new Range();
        range.name = this.name;
        range.start = Math.floor(+this.from);
        range.end = Math.floor(+this.to);
        range.datatype = 4098; // default datatype for manual annotation
        //range.user = "" + this.user;
        range.id = this.id;

        //range.user = user // to do
        range.context = new Object();
        if (this.selectedActivityType) {
            range.context.activitytype = this.selectedActivityType;
        }

        console.log(range);
        this.rangeService.update(range, "range").subscribe(
            () => {
                console.log("################");
                this.action.emit(Action.DISPLAY_GROWL_RANGE_SAVED);
                this.action.emit(Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR);
                this.action.emit(Action.HIDE_RANGE_FORM);
                this.action.emit(Action.REFRESH_CHART);
                this.action.emit(Action.EMPTY_FORM_FIELDS);
            });
    }

    reset() {
        // Tells brother component chartComponent to delete plotband not saved
        this.chartService.postTask(new ChartTask(Action.CANCEL_PLOTRANGE));
        // Tells parent component recordViewComponent to hide itself
        this.action.emit(Action.HIDE_RANGE_FORM);
    }


}