
class HistogramSet {
    histogramsReordered: Array<any>;
    options: Array<Object>;
}

export class HxToolsHistogram {


    static createHistograms(range: any, histogramIds: Array<number>, columnColors: Map<string, Array<any>>) {

        let histogramSet: HistogramSet = new HistogramSet();

        histogramSet.histogramsReordered = new Array<any>();
        histogramSet.options = new Array<Object>();
        for (const histogramId of histogramIds) {
            const h = range.histograms.filter(function (entry) {
                return entry.id === histogramId;
            });
            const histogram = h[0];
            const xValues = histogram.value[0];
            const yValues = histogram.value[1];
            const values = new Array<any>();
            let i = 0;
            for (const xValue of xValues) {
                if (histogram.id !== 308 && histogram.id !== 309) { // If it is not sleep position
                    values.push([Math.round(xValue[0]) + "-" + Math.round(xValue[1]), Math.round(yValues[i] / 60)]);
                } else if (histogram.id === 309) {
                    const sleepPhases = ["No Data", null, "NREM", null, null, "REM", "Wake"];
                    if (sleepPhases[xValue[0]]) {
                        values.push([sleepPhases[xValue[0]], Math.round(yValues[i] / 60)]);
                    }
                } else {
                    const sleepPositions = ["Unknown", "Belly", "Back", "Right", "Left", "Standing"];
                    values.push([sleepPositions[xValue[0]], Math.round(yValues[i] / 60)]);
                }
                i++;
            }
            histogram.valueForChart = values;
            if (histogram.unit && histogram.unit[0]) {
                histogram.xAxisTitle = histogram.unit[0].si_short;
            }
            histogram.options = new Object();
            if (columnColors.get("" + histogram.id) !== undefined) {
                histogram.options = {
                    plotOptions: {
                        column: {
                            colorByPoint: true
                        }
                    },
                    colors: columnColors.get("" + histogram.id)
                };
            } else {
                histogram.options = {
                    plotOptions: {
                        column: {
                            colorByPoint: true
                        }
                    },
                    colors: columnColors.get('else')
                };
            }
            histogramSet.histogramsReordered.push(histogram);


        }

        return histogramSet;
    }

}
