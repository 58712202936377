var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import 'rxjs/add/operator/catch';
import { NotifierService } from 'angular-notifier';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    /**
    * Retrieve all the users associated with the logged user
    */
    UserService.prototype.fetch = function () {
        var query = this.API_URL + "api/user/?limit=" + Math.floor((Math.random() * 100) + 100); //todo
        return this._get(query, false);
    };
    /**
     * Get the user from user_id
     */
    UserService.prototype.get = function (id) {
        var query = this.API_URL + "api/user/";
        if (id) {
            query = query + id + "/";
        }
        else {
            query = query + this.globalVariables.get('currentUserId') + "/";
        }
        return this._get(query);
    };
    /**
     * Add a new user
     */
    UserService.prototype.add = function (account) {
        var options = this.generateHeader();
        var query = "createuser/";
        return this.http.post(this.API_URL + "api/" + query, JSON.stringify(account), { headers: options })
            .map(function (response) {
        })
            .catch(function (error) {
            console.error(error);
            return Observable.throw(error.json());
        });
    };
    /**
     * Update user info
     */
    UserService.prototype.save = function (account, id) {
        var options = this.generateHeader();
        var query = "user/";
        if (id) {
            query = query + id + "/";
        }
        else {
            query = query + this.globalVariables.get('currentUserId') + "/";
        }
        return this._put(this.API_URL + "api/" + query, account, 'user');
    };
    return UserService;
}(HttpService));
export { UserService };
