/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./map.component";
import * as i2 from "./map.service";
import * as i3 from "../../../core/event-aggregator/event.aggregator";
var styles_MapComponent = [];
var RenderType_MapComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["routeMap", 1]], null, 0, "div", [["id", "googleMap33"], ["style", "width:100%;height:380px;"]], null, null, null, null, null))], null, null); }
export function View_MapComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i0.ɵdid(1, 4767744, null, 0, i1.MapComponent, [i2.MapService, i3.EventAggregator], null, null)], null, null); }
var MapComponentNgFactory = i0.ɵccf("app-map", i1.MapComponent, View_MapComponent_Host_0, { gpsPositions: "gpsPositions" }, {}, []);
export { MapComponentNgFactory as MapComponentNgFactory };
