var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/operator/mergeMap';
import * as _ from 'lodash';
var PreferenceService = /** @class */ (function (_super) {
    __extends(PreferenceService, _super);
    function PreferenceService(http, eventAggregator, globalVariables, notifierService) {
        return _super.call(this, http, eventAggregator, globalVariables, notifierService) || this;
    }
    PreferenceService.prototype.saveSelector = function (page, name, displayed) {
        var _this = this;
        var _preferences = {
            'preferences': page
        };
        var profile_uri = localStorage.getItem('profileUri');
        var query = this.API_URL + profile_uri;
        console.log(query);
        var current_preferences;
        var new_preferences;
        //, JSON.stringify(_preferences)
        return this._get(query)
            .map(function (res) {
            if (res['preferences']) {
                current_preferences = {
                    'preferences': res['preferences']
                };
            }
            else {
                current_preferences = {
                    'preferences': ''
                };
            }
            //const second_field = _preferences.preferences[page].filter()
            //let index = _.findIndex(current_preferences.preferences[page], { name: name });
            new_preferences = _.cloneDeep(current_preferences);
            //console.log(index);
            console.log(current_preferences.preferences);
            //console.log(second_field);
            //if (_.hasIn(new_preferences, ['preferences', first_field, second_field])) {
            // update
            /*    _.set(new_preferences,
                    ['preferences', page, second_field],
                    _.get(_preferences, ['preferences', page, second_field]));
*/
            console.log(new_preferences);
            /*} else {
                // add
                _.set(new_preferences,
                    ['preferences', first_field],
                    _.get(_preferences, ['preferences', first_field]));
            }*/
        })
            .mergeMap(function (update) { return _this._patch(query, JSON.stringify(new_preferences)); })
            .map(function (res) {
            console.log("XXXXXXX");
        })
            .map(function (res) { return _this.res = res; });
    };
    /**
     * Save preferences
     */
    PreferenceService.prototype.save = function (preferences) {
        var _this = this;
        var _preferences = {
            'preferences': preferences
        };
        var profile_uri = localStorage.getItem('profileUri');
        var query = this.API_URL + profile_uri;
        console.log(query);
        var current_preferences;
        var new_preferences;
        //, JSON.stringify(_preferences)
        return this._get(query)
            .map(function (res) {
            if (res['preferences']) {
                current_preferences = {
                    'preferences': res['preferences']
                };
            }
            else {
                current_preferences = {
                    'preferences': ''
                };
            }
            var first_field = Object.keys(_preferences.preferences)[0];
            var second_field = Object.keys(_preferences.preferences[first_field])[0];
            new_preferences = _.cloneDeep(current_preferences);
            console.log(new_preferences);
            console.log(_preferences);
            console.log(first_field);
            console.log(second_field);
            console.log(Object.keys(preferences[first_field]));
            //if (_.hasIn(new_preferences, ['preferences', first_field, second_field])) {
            // update
            _.set(new_preferences, ['preferences', first_field, second_field], _.get(_preferences, ['preferences', first_field, second_field]));
            console.log(new_preferences);
            /*} else {
                // add
                _.set(new_preferences,
                    ['preferences', first_field],
                    _.get(_preferences, ['preferences', first_field]));
            }*/
        })
            .mergeMap(function (update) { return _this._patch(query, JSON.stringify(new_preferences)); })
            .map(function (res) {
            console.log("XXXXXXX");
        })
            .map(function (res) { return _this.res = res; });
    };
    return PreferenceService;
}(HttpService));
export { PreferenceService };
