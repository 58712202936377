
/**
 * Functions to manipulate array of objects
 */
export class ArrayOfJSONObjects {
    /**
     * Retrieve the element with a specific id
     */
    static findById(data: Array<any>, id: number) {
        for (let item of data) {
            if (item.id == id) {
                return item;
            }
        }
        return null;
    }
    /**
     * Retrieve the position of the element with a specific id
     */
    static getIndexFromById(data: Array<any>, id: number) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                return i;
            }
        }
        return null;
    }
    /**
     * Retrieve the position of the element with a specific id
     */
    static getIndexFromByIndex(data: Array<any>, index: number) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].index === index) {
                return i;
            }
        }
        return null;
    }
    /**
     * Retrieve the position of the element with a specific rid
     */
    static getIndexFromByRid(data: Array<any>, index: number) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].rid === index) {
                return i;
            }
        }
        return null;
    }
    /**
     * Move an element from fromIndex position to toIndex position
     */
    static moveFromTo(data: Array<any>, fromIndex, toIndex) {
        /* if ((fromIndex >= 0) && (toIndex >= 0) &&
             (fromIndex !== toIndex) && (fromIndex < data.length) &&
             (toIndex < data.length)) {
 
             let element = data[fromIndex];
             data.splice(fromIndex, 1);
             data.splice(toIndex, 0, element);
 
         }*/
        return data.splice(toIndex, 0, data.splice(fromIndex, 1)[0]);
    }
}