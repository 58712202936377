<div class="hx-small-metric">
  <div class="metric-box metric-box-range ">
    <div class="hx-g metric-box-body">
      <div class="metric-box-value">
        <div style="font-size:14px;">
          <i class="hx-icon hx-icon-{{ icon }}" ng-class="headerIcon"></i><br/> {{ title }}
          <small class="hidden-xs">{{ unit ? '('+unit+')' : '' }}</small>
        </div>
        <div style="padding: 5px;" [ngClass]="{'hx-small-font-metric' : title === 'Duration'}">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</div>


