<div class="hx-ui-dialog" [style.display]="visible ? 'block' : 'none'" [style.width.px]="width" [style.height.px]="height"
  (mousedown)="moveOnTop()">

  <div class="hx-ui-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top">
    <span class="hx-ui-dialog-title" *ngIf="header">{{header}}</span>
    <a *ngIf="closable" [ngClass]="{'hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all':true}" href="#"
      role="button" (click)="hide($event)">
      <span class="fa fa-fw fa-close"></span>
    </a>
  </div>

  <div class="hx-ui-dialog-content hx-ui-widget-content">
    <div class="hx-form hx-form-aligned">

      <div class="formErrors" *ngIf="valueField.errors">
        <span *ngIf="valueField.errors && valueField.errors.pattern">
          The value is not valid
        </span>
      </div>

      <div class="input_with_appended_unit">
        <label>Value</label>
        <input type="number" placeholder="New value" [(ngModel)]="value" [readonly]="!acceptVisible" #valueField="ngModel"
          [pattern]="pattern">
        <span>{{ unit }}</span>
      </div>

      <label>Date</label>
      <input type="date" name="date" placeholder="YYYY-MM-DD" [readonly]="!acceptVisible" [(ngModel)]="date">
    </div>
  </div>

  <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
    <ng-content select="p-footer"></ng-content>
  </div>

  <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
    <button type="button" class="button" (click)="reject()" *ngIf="rejectVisible">
      <i class="hx-icon hx-icon-times"></i> Cancel
    </button>
    <button type="button" class="button" (click)="delete()" *ngIf="deleteVisible">
      <i class="hx-icon hx-icon-delete"></i> Delete
    </button>
    <button type="button" class="button" (click)="accept()" *ngIf="acceptVisible" [disabled]="!value || valueField?.errors && valueField?.errors.pattern">
      <i class="hx-icon hx-icon-check"></i> Save
    </button>
    <button *ngIf="range" type="button" class="button" (click)="goToRange()">
      <i class="hx-icon hx-icon-eye"></i> View activity
    </button>
    <button *ngIf="false" type="button" class="button" (click)="update()" [disabled]="!value || valueField?.errors && valueField?.errors.pattern">
      <i class="hx-icon hx-icon-check"></i> Update
    </button>
  </div>

</div>