<app-alert></app-alert>


<section id="search">
  <div class="hx-g header">
    <div class="hx-u-23-24">
      <div class="title">
        Search activities
      </div>
    </div>
    <div class="hx-u-1-24">
      <img (click)="openModal('search')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
    </div>
  </div>

  <div class="hx-g form">
    <div class="hx-u-6-24">
      <div style="background-color: white; border-right: 1px solid black;height: 30px; ">
        <hx-select *ngIf="users.length > 0" name="filterUsername" [(ngModel)]="selectedUser" [options]="users" placeholder="User"
          [allowClear]="true" (selected)="onChangeUser($event)" (deselected)="onClearUser()">
          </hx-select>
      </div>
    </div>
    <div class="hx-u-4-24">
      <div style="background-color: white; border-right: 1px solid black; height: 30px; padding-bottom: 0px; margin-bottom: 0px">


        <hx-select *ngIf="activities.length > 0" name="filterUsername" [options]="activities" placeholder="Activity type" [allowClear]="true"
          [(ngModel)]="selectedActivity" (selected)="onChangeActivity($event)" (deselected)="onClearActivity()">
          </hx-select>


      </div>
    </div>
    <div class="hx-u-8-24">
      <div style="background-color: white; border-right: 1px solid black;height: 30px; vertical-align: bottom">
        <form #myForm="ngForm" novalidate>
            <input type="text" name="daterange" (ngModelChange)="onClickGo($event)" [showCustomRangeLabel]="true" [showRangeLabelOnInput]="true"
              [ranges]="dateRanges" [locale]="{applyLabel: 'OK', format: 'DD/MM/YYYY'}" hxDaterangepicker [(ngModel)]="selectedPeriod"
              class="daterangepicker" size="30" style="height:27px;" readonly>
        </form>
      </div>
    </div>

    <div class="hx-u-6-24">
      <div style="background-color:white;color: #555;font-size: 14px;height:100%;">
        <div style="padding-top:8px;padding-left:4px;" class="hx-g">
          <div class="hx-u-1-1">
            Duration
            <ng-container>
              &ge; {{ filterByDuration | millisecond2HMSInt }}
            </ng-container>
            <div *ngIf="filterByDuration > 0" class="tip">
              <input type="button" class="duration-btn" (click)="decreaseDuration()" value="-">
              <span>Decrease of 10 minutes</span>
            </div>
            <div class="tip">
              <input type="button" class="duration-btn" (click)="increaseDuration()" value="+">
              <span>Increase of 10 minutes</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--div class="hx-u-3-24">
      <div class="hx-g">
        <div class="hx-u-1-2 but" (click)="onClickGo()" style="background-color:#009649;color: white">Go</div>
        <div class="hx-u-1-2 but" (click)="onClickCancel()" style="background-color:gray;color:black">Cancel</div>
      </div>
    </div-->
  </div>
</section>

<ng-container *ngIf="loading">
  <section>
    <div id="spinner">
      <div></div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="!loading">

  <div *ngIf="downloadInProgress" class="download-in-progress">Download in progress...</div>

  <section id="activities">
    <div class="hx-g header">
      <div class="hx-u-15-24">
        <div class="title">
          Activities
        </div>
      </div>
      <div class="hx-u-7-24">
        <div class="hx-select">
          Number by page :
          <select [(ngModel)]="maxItemsPerPage" (ngModelChange)="resetByPage()">
          <option *ngFor="let number of [10, 25, 50, 100, 200] " value="{{ number }}">{{ number }}</option>
      </select>
        </div>
      </div>
      <div class="hx-u-1-24">
        <div class="occurence tip">
          {{ totalRangesCount }}
          <span>Nbr&nbsp;of&nbsp;activities</span>
        </div>
      </div>
      <div class="hx-u-1-24">
        <img (click)="openModal('activities')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
      </div>
    </div>
    <div *ngIf="!loading" class="hx-g body">
      <div class="hx-u-1-1 hx-panel2" [ngClass]="{ 'loading' : loading }">
        <div class="hx-horizontal-padding-5px">
          <table class="hx-table">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th width="100">Name</th>
                <th>Start
                  <i style="cursor: pointer;" class="hx-icon hx-icon-chevron-down hx-icon-array" [ngClass]="{ 'hx-icon-active': order === 'desc' && column === 'start',  'hx-icon-inactive': column !== 'start', 'hx-icon-inactive': order === 'asc'}"
                    (click)="sort('start')"></i>
                    <i style="cursor: pointer;" class="hx-icon hx-icon-chevron-up hx-icon-array" [ngClass]="{ 'hx-icon-active': order === 'asc' && column === 'start',  'hx-icon-inactive': column !== 'start', 'hx-icon-inactive': order === 'desc' }"
                      (click)="sort('start')"></i>
                </th>
                <th>Duration</th>
                <th>HR avg.</th>
                <th>BR avg.</th>
                <th>Energy</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of displayedData | filter : 'username' : filter_username; let i = index">
                <td>{{ cachedUsers[record.user] }}</td>
                <td>
                  <i *ngIf="record.activityType" class="hx-icon hx-horizontal-padding-5px hx-icon-lightened" [ngClass]="record.activityType?.name | activityTypeToIconClass"></i>                  <!--{{ record.activityType?.name | capitalizeFirst }}--></td>
                <td>{{ record.name }}</td>

                <td style="cursor:pointer;" (click)="view(record)"><a onclick="return false;" href="/range-view/{{ record.id }}">{{ record.start | dbTimeToDateTime }}</a></td>

                <td>
                  <!--i class="hx-icon hx-icon-hour-glass hx-icon-lightened"></i-->{{ record.duration }}</td>
                <td> {{ record | dbRecordToMetric:44:'bpm' }}</td>
                <td>{{ record | dbRecordToMetric:12:'rpm' }}</td>
                <td> {{ record | dbRecordToMetric:149:'Cal' }}</td>
                <td width="140">
                  <div class="tip">
                      <a target="_blank" href="/range-view/{{ record.id }}">
                    <button class="button button-green" type="button" ><i class="hx-icon hx-icon-eye"></i></button>
                    <span>Activity details</span>
                    </a>
                  </div>
                  <div class="tip">
                    <div class="multichoice-button">
                      <button class="button button-green multichoice-button-hit" type="button" (click)="dropdownHitMouseOver[i] = !dropdownHitMouseOver[i]"><i class="hx-icon hx-icon-download"></i></button>

                      <div class="multichoice-button-content" (mouseleave)="dropdownHitMouseOver[i] = false" [ngClass]="{ 'multichoice-button-content-hover' : dropdownHitMouseOver[i] === true }">
                        <ul>
                          <li><a (click)="exportCSV(record); dropdownHitMouseOver[i] = false">Download CSV file</a></li>
                          <li><a (click)="exportBinary(record); dropdownHitMouseOver[i] = false">Download Binary file</a></li>
                          <li><a (click)="exportEDF(record); dropdownHitMouseOver[i] = false">Download EDF file</a></li>
                        </ul>
                      </div>
                    </div>
                    <span *ngIf="!dropdownHitMouseOver[i]">Click on the Data Format to start the Download</span>
                  </div>
                  <div class="tip">
                    <button class="button button-green" type="button" (click)="delete(record)"><i class="hx-icon hx-icon-delete"></i></button>
                    <span>Delete activity</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="paginator">

      <div class="button-group margin-top-5 margin-bottom-5" style="text-align:center;">

        <!-- Previous page -->
        <div *ngIf="currentPage > 10" class="button button-blue tip">
          <i class="hx-icon hx-icon-chevron-left tip" (click)="changeToPage(currentPage-10)"></i>
          <span>
          Jump back 10 pages
        </span>
        </div>

        <!-- Previous page -->
        <div *ngIf="currentPage > 1" class="button button-blue tip">
          <i class="hx-icon hx-icon-caret-left tip" (click)="changeToPage(currentPage-1)"></i>
          <span>
          Previous page
        </span>
        </div>

        <div *ngIf="currentPage > 5" class="button button-blue">
          <i (click)="changeToPage(1)" [ngClass]="{ 'current': currentPage === page }">1</i>
        </div>
        <div *ngIf="currentPage > 5" class="button button-blue">
          ...
        </div>

        <!-- Pages around the current page -->
        <div *ngFor="let page of pages" class="button" [ngClass]="{ 'button-light-blue': currentPage === page, 'button-blue': currentPage !== page }">
          <i (click)="changeToPage(page)">{{ page }}</i>
        </div>

        <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
          ...
        </div>
        <div *ngIf="currentPage < totalPages - 5" class="button button-blue">
          <i (click)="changeToPage(totalPages - 1)" [ngClass]="{ 'current': currentPage === page }">{{ totalPages - 1 }}</i>
        </div>

        <!-- Next page -->
        <div *ngIf="currentPage < totalPages" class="button button-blue tip">
          <i class="hx-icon hx-icon-caret-right" (click)="changeToPage(currentPage+1)"></i>
          <span>
          Next page
        </span>
        </div>
        <!-- Jump to 5 -->
        <div *ngIf="currentPage + 9 < totalPages" class="button button-blue tip">
          <i class="hx-icon hx-icon-chevron-right" (click)="changeToPage(currentPage+10)"></i>
          <span>
          Jump 10 pages
        </span>
        </div>
      </div>


    </div>

  </section>

</ng-container>


<!--div class="hx-panel">
<chart *ngIf="options" type="StockChart" [options]="options" #chart></chart>
</div-->

<confirmation header="Confirmation" icon="fa fa-question-circle" width="425"></confirmation>

<app-growl [value]="msgs"></app-growl>

<help-modal id="search-help" style="display: none;">
  <p>Your search for activities can be narrowed down to a specific User, Activity type, Time range or even the Activity duration
    and the activity list will be displayed in the "Activities" section below.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>

<help-modal id="activities-help" style="display: none;">
  <p>This section displays a list of all the accessible activities. The number of activities per page can be changed if need
    be.<br> The <img height="24" src="/assets/icons/view.svg"> is to view an Activity, the <img height="24" src="/assets/icons/download.svg">    is to download the data from the Activity and the <img height="24" src="/assets/icons/trash.svg"> is to permanently delete
    the Activity from the account.<br> Navigate from page to page by clicking on the page button (1, 2, 3, etc.) or with
    <img height="24" src="/assets/icons/pv_next.svg"> button at the bottom of the Activities list.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>