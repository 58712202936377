import * as moment from 'moment/moment';

export class Histogram {
    id: number;
    valueForChart: Array<any>;
    value: Array<any>;
    xAxisTitle: string;
    unit: Array<any>;
}

export class Range {
    id: number;
    start: number;
    end: number;
    user: string;
    userId: number;
    name: string;
    rank: number;

    duration: string;
    duration_millisecondes: number;
    labels: Array<any>;
    datatype: number;
    status: string;

    activity: Array<string>;
    training_routine: Array<string>;

    start_date: string;
    humanized_start_date: string;

    username: string;
    firstname: string;
    context: any;

    lastname: string;

    metrics: Array<any>;
    histograms: Array<Histogram>;
    histogramsReordered: Array<Histogram>;
    constructor(jsonStr?: string) {
        if (jsonStr) {
            let jsonObj: any = JSON.parse(jsonStr);
            for (let prop in jsonObj) {
                this[prop] = jsonObj[prop];
            }
        }
    }

    public getDuration() {
        return 1000 * (this.end - this.start) / 256;
    }

    public calculate() {
        // todo : modify the directive table to call individual function instead
        //this.duration = moment.duration(this.getDuration() / 1000, 'seconds').humanize();
       
        this.duration_millisecondes = 1000 * (this.end - this.start) / 256;
        // retrieve those info from the labels (not implemented in API yet)
        this.activity = [];
        this.training_routine = [];
        let s = this.user.replace("/api/user/", "");
        this.userId = +s.replace("/", "");

        let m = moment(this.start_date);
        if (m.isValid()) {
            this.humanized_start_date = m.format('MM/DD/YYYY, H:mm a');
        } else {
            this.humanized_start_date = "?";
        }

    }


}