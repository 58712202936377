import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Range } from '../../models';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
/**
 * Ranges
 */
@Injectable()
export class RangeService extends HttpService {

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }

    /**
     * Delete a range
     */
    delete(id, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + "range/" + id + "/";
        return this._delete(query, keyToClear);
    }


    /**
     * Retrieve all the Ranges for a user
     */
    fetch(id: number): Observable<Array<any>> {
        const query = this.API_URL + "api/range/?user=" + id;
        return this._get(query);
    }

    fetchPeriod(start: number, end: number, limit?: number, offset?: number, userId?: number, order_by?: string, cache = true): Observable<any> {
        if (!limit) { limit = 0; }
        if (!offset) { offset = 0; }
        // let filter_by_username = "";
        let user = "";
        if (userId) {
            user += "&user=" + userId;
        }
        let _order_by = "";
        if (order_by) {
            _order_by += "&order_by=" + order_by;
        }
        let metrics__include = ""; //&metrics__include=12,44,149";
        const query = this.API_URL + "api/" + "range/?limit=" + limit +
            "&offset=" + offset + metrics__include + "&start__lte=" + Math.ceil(start - Math.floor((Math.random() * 100) + 1))
            + "&end__gte=" + Math.floor(end + Math.floor((Math.random() * 100) + 1)) + _order_by
            + user;
        // + "&limit=" + Math.floor((Math.random() * 100) + 1) + 100;
        // TO DO : delete limit... workaround not good...
        return this._get(query, cache);
    }

    getNext(time, userId?: number) {
        let user = "";
        if (userId) { user = "&user=" + userId; }
        const query = this.API_URL + "api/" + "range/?limit=1" + user + "&order_by=start&start__gte=" + time;
        return this._get(query);
    }


    getPrevious(time, userId?: number) {
        let user = "";
        if (userId) { user = "&user=" + userId; }
        const query = this.API_URL + "api/" + "range/?limit=1" + user + "&order_by=-start&end__lte=" + time;
        return this._get(query);
    }


    getAssociatedRecord(start: number, end: number, userId?: number) {
        let user = "";
        if (userId) { user = "&user=" + userId; }
        const query = this.API_URL + "api/" + "record/?start__lte=" + start + "&end__gte=" + end + user;
        return this._get(query);
    }

    /**
     * Get the range from its id
     */
    get(id: number, include_histograms?: Array<number>, include_metrics?: Array<number>): Observable<Array<any>> {
        let _include_histograms = "";
        let _include_metrics = "";
        let i = 0;
        if (include_histograms) {
            _include_histograms = "?include_histograms=";
            for (const histogram of include_histograms) {
                _include_histograms += histogram;
                if (i < include_histograms.length - 1) { _include_histograms += ","; }
                i++;
            }
        }
        if (include_metrics) {
            _include_metrics = "?include_metrics=";
            if (include_histograms) {
                _include_metrics = "&include_metrics=";
            }
            for (const metric of include_metrics) {
                _include_metrics += metric;
                if (i < _include_metrics.length - 1) { _include_metrics += ","; }
                i++;
            }
        }
        const query = this.API_URL + "api/" + "range/" + id + "/" + _include_histograms + _include_metrics;

        return this._get(query);
    }

    /**
     * Save range
     */
    save(range: Range, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + "range/";
        return this._post(query, range, keyToClear);
    }

    update(range: Range, keyToClear?: string): Observable<any> {
        const query = this.API_URL + "api/" + "range/" + range.id + "/";
        return this._put(query, range, keyToClear);
    }

    fetchAll(limit?: number,
        offset?: number,
        order_by?: string,
        direction?: string,
        activityType?: number,
        start?: number,
        end?: number,
        rank?: number,
        userId?: number,
        duration?: number,
        metrics?: any): Observable<any> {

        if (!limit) { limit = 0; }
        if (!offset) { offset = 0; }
        let order_by_q = "";
        let direction_q = "";
        if (direction) {
            direction_q = direction;
        }
        if (order_by) {
            let field = "";
            if (order_by === "user") {
                field = "__username";
            }
            order_by_q = "&order_by=" + direction_q + order_by + field;
        }
        let filter = "";
        if (activityType) {
            filter += "&activitytype=" + activityType;
        }
        if (metrics) {
            filter += "&include_metrics=" + metrics.join();
        }
        let rank_q = "";
        if (_.isNumber(rank)) {
            rank_q += "&rank=" + rank;
        }
        let period = "";
        if (start && end) {
            period = "&start__gte=" + Math.ceil(start) + "&end__lte=" + Math.floor(end);
        }
        let user = "";
        if (userId) {
            user += "&user=" + userId;
        }
        let _duration = "";
        if (duration) { _duration = "&duration__gte=" + duration; }

        const query = this.API_URL + "api/" + "range/?minimal=1&limit=" + limit + "&offset=" +
            offset + order_by_q + filter + period + rank_q + user + _duration;
        return this._get(query);
    }

    getFileWithDatatype(id, datatype__in: string, format?: string): Observable<any> {
        if (!format) format = "text/csv";
        const options = this.generateHeader(format);
        return this.http.get<Blob>(this.API_URL + "api/" + "data/?range=" + id + "&datatype__in=" + datatype__in, { headers: options, responseType: 'blob' as 'json', reportProgress: true });
        // .map(response => { return response; });
    }

    public downloadResource(id, datatype__in: string, format?: string): Observable<any> {
        const options = this.generateHeader(format);
        let url = this.API_URL + "api/" + "data/?range=" + id;
        if (format === 'text/csv') {
            url += "&datatype__in=" + datatype__in;
        }
        return this.http.get<Blob>(url, { headers: options, responseType: 'blob' as 'json' });
    }

}
