import { OnInit } from '@angular/core';
import { ApiQuery, ApiService } from '../../../core/services';
var RrboxComponent = /** @class */ (function () {
    function RrboxComponent(apiService) {
        this.apiService = apiService;
        this.userId = 1;
        this.start = 18;
        this.end = 42;
        this.loading = true;
        this.metrics = new Array();
        this.metrics.push("0");
        this.metrics.push("0");
        this.metrics.push("0");
        this.metrics.push("0");
        this.hours = new Array();
        this._cache = new Array();
    }
    Object.defineProperty(RrboxComponent.prototype, "records", {
        get: function () {
            return this._records;
        },
        set: function (array) {
            this._records = array;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RrboxComponent.prototype, "ranges", {
        get: function () {
            return this._ranges;
        },
        set: function (array) {
            this._ranges = array;
        },
        enumerable: true,
        configurable: true
    });
    RrboxComponent.prototype.onChangeStart = function () { };
    RrboxComponent.prototype.evMouseOverRangeMetrics = function (recordId) {
        var _this = this;
        if (this._cache[recordId]) {
            this.metrics = this._cache[recordId];
        }
        else {
            var query = ApiQuery.metrics_for_record;
            this.apiService.getQuery(query, { metric_ids: this.metricList, record_id: recordId })
                .subscribe(function (data) {
                _this.metrics = data.metrics;
                _this._cache[recordId] = _this.metrics;
            });
        }
    };
    RrboxComponent.prototype.evMouseOverRange2Metrics = function (rangeId) {
        var _this = this;
        if (this._cache[rangeId]) {
            this.metrics = this._cache[rangeId];
        }
        else {
            var query = ApiQuery.metrics_for_range;
            this.apiService.getQuery(query, { metric_ids: this.metricList, range_id: rangeId })
                .subscribe(function (data) {
                _this.metrics = data.metrics;
                _this._cache[rangeId] = _this.metrics;
            });
        }
    };
    RrboxComponent.prototype.evRemoveRangeMetrics = function () {
        this.retrieveAllDayMetrics();
    };
    RrboxComponent.prototype.evClickRange = function () {
    };
    RrboxComponent.prototype.retrieveAllDayMetrics = function () {
        var _this = this;
        var key = 256 * this.date.startOfDay + "-" + 256 * this.date.endOfDay;
        if (this._cache[key]) {
            this.metrics = this._cache[key];
        }
        else {
            var start = 256 * this.date.startOfDay;
            var end = 256 * this.date.endOfDay;
            if (this.time === "noctural") {
                start = 256 * (this.date.startOfDay + 18 * 60 * 60);
                end = 256 * (this.date.startOfDay + 35 * 60 * 60);
            }
            this.apiService.getQuery(ApiQuery.metrics_for_user_id__start__end, {
                metric_ids: this.metricList,
                start: start,
                end: end,
                user_id: this.userId
            }).subscribe(function (data) {
                _this.metrics = data.metrics;
                _this._cache[key] = _this.metrics;
                _this.loading = false;
            });
        }
    };
    RrboxComponent.prototype.ngOnInit = function () {
        for (var h = 0; h <= 24; h++)
            this.hours.push(h);
        this.retrieveAllDayMetrics();
    };
    return RrboxComponent;
}());
export { RrboxComponent };
