import { Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { UserService, ApiService, ApiQuery } from '../../../core/services';
import { DomHandler } from '../../../core/dom/domhandler';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../../core/services/global.variables';

@Component({
    selector: 'fast-subject-switching',
    templateUrl: './fast-subject-switching.component.html',
    styleUrls: ['./fast-subject-switching.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FastSubjectSwitchingComponent implements OnDestroy, OnInit {

    public subjects: Array<any>;
    public currentSubjectId = null;
    public currentSubject: any;
    public filter: string;

    constructor(private userService: UserService,
        private apiService: ApiService,
        private globalVariables: GlobalVariables,
        private el: ElementRef,
        private domHandler: DomHandler,
        private eventAggregator: EventAggregator) {

    }

    ngOnInit(): void {
        // Subscribe to the event aggregator
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
    }

    switchSubject(subjectId: number) {

        this.userService.get(subjectId).subscribe(data => {

            this.globalVariables.set('currentSubjectId', data.id);
            this.globalVariables.set('currentSubject', data);
            this.currentSubject = data;
            this.currentSubjectId = subjectId;

            const contentContainer = this.domHandler.findSingle(this.el.nativeElement, '.table');
            contentContainer.scrollTop = 0;
            this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                {
                    msg: HxEvent.FSS__MOUSECLICK_SUBJECT
                }));
        });

    }

    private onMessageReceived = (payload: MessageSentEventPayload) => {

        if (payload.object.msg === HxEvent.FSS__UPDATE) {
            this.apiService.getQuery(ApiQuery.all_users_for_user_id).subscribe(data => {
                this.subjects = data.objects;
                for (let subject of this.subjects) {
                    subject.name = subject.first_name + " " + subject.last_name;
                    if (subject.name === " ") {
                        subject.name = subject.username;
                    }
                }
                this.currentSubjectId = this.globalVariables.has('currentSubjectId') ? this.globalVariables.get('currentSubjectId') : this.globalVariables.get('currentUserId');

                this.currentSubject = this.globalVariables.has('currentSubject') ? this.globalVariables.get('currentSubject') : this.globalVariables.get('currentUser');

            });
        } else if (payload.object.msg === HxEvent.FSS__HIDE) {
            this.subjects = null;

        } else if (payload.object.msg === HxEvent.LOGOUT) {
            this.subjects = null;
            this.currentSubjectId = null;
            // Current subject has been changed from another component
        } else if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
            this.currentSubjectId = this.globalVariables.has('currentSubjectId') ? this.globalVariables.has('currentSubjectId') : this.globalVariables.has('currentUserId');
            this.currentSubject = this.globalVariables.has('currentSubject') ? this.globalVariables.get('currentSubject') : this.globalVariables.get('currentUser');
        }
    }

    ngOnDestroy() {
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
    }

}
