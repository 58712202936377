var HistogramSet = /** @class */ (function () {
    function HistogramSet() {
    }
    return HistogramSet;
}());
var HxToolsHistogram = /** @class */ (function () {
    function HxToolsHistogram() {
    }
    HxToolsHistogram.createHistograms = function (range, histogramIds, columnColors) {
        var histogramSet = new HistogramSet();
        histogramSet.histogramsReordered = new Array();
        histogramSet.options = new Array();
        var _loop_1 = function (histogramId) {
            var h = range.histograms.filter(function (entry) {
                return entry.id === histogramId;
            });
            var histogram = h[0];
            var xValues = histogram.value[0];
            var yValues = histogram.value[1];
            var values = new Array();
            var i = 0;
            for (var _i = 0, xValues_1 = xValues; _i < xValues_1.length; _i++) {
                var xValue = xValues_1[_i];
                if (histogram.id !== 308 && histogram.id !== 309) { // If it is not sleep position
                    values.push([Math.round(xValue[0]) + "-" + Math.round(xValue[1]), Math.round(yValues[i] / 60)]);
                }
                else if (histogram.id === 309) {
                    var sleepPhases = ["No Data", null, "NREM", null, null, "REM", "Wake"];
                    if (sleepPhases[xValue[0]]) {
                        values.push([sleepPhases[xValue[0]], Math.round(yValues[i] / 60)]);
                    }
                }
                else {
                    var sleepPositions = ["Unknown", "Belly", "Back", "Right", "Left", "Standing"];
                    values.push([sleepPositions[xValue[0]], Math.round(yValues[i] / 60)]);
                }
                i++;
            }
            histogram.valueForChart = values;
            if (histogram.unit && histogram.unit[0]) {
                histogram.xAxisTitle = histogram.unit[0].si_short;
            }
            histogram.options = new Object();
            if (columnColors.get("" + histogram.id) !== undefined) {
                histogram.options = {
                    plotOptions: {
                        column: {
                            colorByPoint: true
                        }
                    },
                    colors: columnColors.get("" + histogram.id)
                };
            }
            else {
                histogram.options = {
                    plotOptions: {
                        column: {
                            colorByPoint: true
                        }
                    },
                    colors: columnColors.get('else')
                };
            }
            histogramSet.histogramsReordered.push(histogram);
        };
        for (var _i = 0, histogramIds_1 = histogramIds; _i < histogramIds_1.length; _i++) {
            var histogramId = histogramIds_1[_i];
            _loop_1(histogramId);
        }
        return histogramSet;
    };
    return HxToolsHistogram;
}());
export { HxToolsHistogram };
