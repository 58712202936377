<div #elem>


  <div class="alert" *ngIf="!loading && range && range.status == 'realtime'; else notRealtime">Incomplete data: This
    data has not been synced with HxServices. Some features might be unavailable.</div>

  <ng-template #notRealtime>
    <ng-container *ngIf="!loading && range && range.status != 'complete'">
      <div class="alert">Data is missing for this activity.</div>
    </ng-container>
  </ng-template>

  <section name="timeline" *ngIf="range"
    [ngClass]="{ 'active' : selector.boxes.get('timeline') && selector.boxes.get('timeline').displayed, 'inactive' : !selector.boxes.get('timeline'), 'inactive' : selector.boxes.get('timeline') && selector.boxes.get('timeline').displayed === false }">
    <div class="title-wrapper">
      <div class="title hx-g">
        <div class="hx-u-9-24">
          <div class="" style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;"><i class="hx-icon hx-icon-calendar"></i>
            {{ range.start | dbTimeToDate }} </div>
          <div class="" style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;"><i class="hx-icon hx-icon-clock"></i>
            {{ range.duration }}</div>
          <i *ngIf="isOverNight" class="hx-icon hx-icon-sleep"></i>
        </div>
        <div class="hx-u-12-24">
          <div class="hx-g">
            <div class="hx-u-1-2">
              <div class="hx-activity">
                <div style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;">
                  <i class="hx-icon hx-icon {{ activityTypeName | activityTypeToIconClass }}"></i> {{ activityTypeName
                  |
                  capitalize }}
                </div>
              </div>
            </div>
            <div class="hx-u-1-2">
              <div class="hx-activity">
                <div style="display: inline-block;padding:5px;border-radius:2px;margin-right:5px;">
                  {{ user }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hx-u-3-24 ">
          <div class="pull-right" style="display: inline-block;border-radius:2px;margin-right:5px;">
            <div *ngIf="previousRangeId" class="tip button"><i class="tip hx-icon hx-icon-chevron-left clickable"
                (click)="previousRange()"></i><span class="tip">Previous activity</span></div>
            <div *ngIf="nextRangeId" class="tip button"><i class="hx-icon hx-icon-chevron-right clickable" (click)="nextRange()"></i>
              <span>Next activity</span></div>
          </div>
        </div>
      </div>
      <div class="hx-g">
        <div class="hx-u-9-24">
        </div>
        <div class="hx-u-8-24">
          <div class="name">{{ range.name }}</div>
        </div>
        <div class="hx-u-7-24">
          <div *ngIf="range.rank > 0" class="name">Rank activity = {{ range.rank }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="empty_box" style="background-color: white; padding:10px; height:119px">
      <img class="spinner" src="assets/images/spinner.gif">
    </div>
    <div *ngIf="!loading" style="background-color: white;padding:10px;">
      <app-timeline [records]="records" [time]="time" [startHour]="startHour" [endHour]="endHour" [ranges]="rangesOfDay"></app-timeline>
    </div>
  </section>

  <section name="sleep_status" *ngIf="activity == 'sleep' && sleep_status && sleep_status != 1">
    <div class="sleep-status">
        {{ sleep_status }}
    </div>
  </section>

  <ng-container *ngIf="loading">
    <section>
      <div id="spinner">
        <div></div>
      </div>
    </section>
  </ng-container>

  <section name="metrics" *ngIf="range"
    [ngClass]="{ 'active' : selector.boxes.get('metrics') && selector.boxes.get('metrics').displayed, 'inactive' : !selector.boxes.get('metrics'), 'inactive' : selector.boxes.get('metrics') && selector.boxes.get('metrics').displayed === false }"
    class="metrics">
    <metrics-panel *ngIf="!loading && metrics" [metricIds]="metrics" [id]="rangeId" [metricValues]="metricValues" type="range"
      [start]="" [end]=""></metrics-panel>
  </section>

  <div *ngIf="downloadInProgress" class="download-in-progress">Download in progress...</div>

  <section id="charts" name="charts"  *ngIf="range" [ngClass]="{ 'active' : selector.boxes.get('charts') && selector.boxes.get('charts').displayed, 'inactive' : !selector.boxes.get('charts'), 'inactive' : selector.boxes.get('charts') && selector.boxes.get('charts').displayed === false }">
    <div class="title-wrapper hx-g">
      <div class="hx-u-23-24">
        <div class="title">
          Charts
        </div>
      </div>
      <div class="hx-u-1-24">
        <img (click)="openModal('charts')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
      </div>
    </div>
    <div>
      <div *ngIf="loading" class="panel-box-body" style="height: 414px;">
      </div>
      <div *ngIf="!loading" class="panel-box-body" [class.margin-top]="j > 0" style="min-height: 300px;">
        <div class="command">
          <div class="hx-g">
            <div class="hx-u-lg-1-2 hx-u-md-1-1 hx-u-sm-1-1">
              <div id="chartsbtn" #chartsbtn class="select-chart">
                <ul>
                  <li><a [ngClass]="{ 'active ' : displayProcessedData }" (click)="displayProcessedData = true; displayRawData = false">Processed
                      Data</a></li>
                  <li><a [ngClass]="{ 'active ' : displayRawData }" (click)="displayProcessedData = false; displayRawData = true">Raw
                      Data</a></li>
                  <li><img (click)="openModalChartsBtn()" width="20px" style="cursor: pointer; margin-top: 20px;" src="/assets/icons/info.svg"></li>
                </ul>
              </div>
            </div>
            <div class="hx-u-lg-1-2 hx-u-md-1-1 hx-u-sm-1-1">

              <div id="chartmenu">
                <nav>
                  <ul>
                    <!--li><a><i class="hx-icon hx-icon hx-icon-info-circle hx-icon-button"></i></a></li-->
                    <li>
                      <div class="tip">
                        <i (click)="resetChart()" class="hx-icon hx-icon hx-icon-refresh hx-icon-button"></i><span>Reset</span></div>
                    </li>
                    <li *ngIf="!displayNavigatorProcessedData">
                      <div class="tip">
                        <a (click)="displayNavigator()"><i class="hx-icon hx-icon hx-icon-search hx-icon-button"></i></a><span>Display
                          navigator</span></div>
                    </li>
                    <li *ngIf="displayNavigatorProcessedData">
                      <div class="tip">
                        <a (click)="hideNavigator()" class="active"><i class="hx-icon hx-icon hx-icon-search hx-icon-button"></i></a><span>Hide
                          navigator</span></div>
                    </li>
                    <li *ngIf="displayedForms['ADD_RANGE'] === true || displayedForms['EDIT_RANGE'] === true">
                      <div class="tip"><a (click)="hideForm('ADD_RANGE')" class="active"><i class="hx-icon hx-icon-plus hx-icon-button"></i></a><span>Display
                          activity form</span></div>
                    </li>
                    <li *ngIf="displayedForms['ADD_RANGE'] === false && displayedForms['EDIT_RANGE'] === false">
                      <div class="tip"><a (click)="displayForm('ADD_RANGE')"><i class="hx-icon hx-icon-plus hx-icon-button"></i></a><span>Hide
                          activity form</span></div>
                    </li>
                    <li *ngIf="displayedForms['ANNOTATION'] === true">
                      <div class="tip"><a (click)="hideForm('ANNOTATION')" class="active"><i class="hx-icon hx-icon hx-icon-flag hx-icon-button"></i></a><span>Display
                          annotation form</span></div>
                    </li>
                    <li *ngIf="displayedForms['ANNOTATION'] === false">
                      <div class="tip"><a (click)="displayForm('ANNOTATION')"><i class="hx-icon hx-icon hx-icon-flag hx-icon-button"></i></a><span>Hide
                          annotation form</span></div>
                    </li>
                    <li>
                      <div class="tip"><i class="hx-icon hx-icon hx-icon-download hx-icon-button" (click)="downloadClicked = !downloadClicked"></i><span>Click
                          on the Data Format to start the Download</span>
                      </div>
                      <ul [ngClass]="{ 'clicked' : downloadClicked === true } ">
                        <li><a (click)="exportData('csv', 'range')">Download CSV file</a></li>
                        <li><a (click)="exportData('zip', 'range')">Download Binary file</a></li>
                        <li><a (click)="exportData('edf', 'range')">Download EDF file</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="displayedForms['ANNOTATION']">
          <record-annotation (action)="performAction($event)" [user]="userId" [record_id]="rangeId" [id]="editableAnnotation.id"
            [start]="editableAnnotation.start" [annotation]="editableAnnotation.annotation"></record-annotation>
        </div>

        <div *ngIf="displayedForms['EDIT_RANGE']">
          <record-range [user]="userId" [activityTypes]="activityTypes" [selectedActivityType]="editableRange.activitytype"
            (growlMessageLabel)="growlMessage($event)" [name]="editableRange.name" (action)="performAction($event)"
            [id]="editableRange.id" [from]="editableRange.start" [to]="editableRange.end" mode="edit"></record-range>
        </div>

        <div *ngIf="displayedForms['ADD_RANGE']">
          <record-range [user]="userId" [nextRank]="nextRank" [activityTypes]="activityTypes" [selectedActivityType]="editableRange.activitytype"
            (growlMessageLabel)="growlMessage($event)" [name]="editableRange.name" (action)="performAction($event)"
            [id]="editableRange.id" [from]="editableRange.start" [to]="editableRange.end" mode="add"></record-range>
        </div>

        <app-highchart *ngIf="displayProcessedData" type="range" [id]="1" (action)="performAction($event)" (annotationEvent)="onChartAnnotation($event)"
          (rangeEvent)="onChartRange($event)" [state]="state" [record_id]="rangeId" [user]="range.userId" [curves]="curves"
          [salt]="salt" [ids]="curvesQueue" [displayNavigator]="displayNavigatorProcessedData">
        </app-highchart>

        <app-highchart *ngIf="displayRawData" type="range" [id]="1" (action)="performAction($event)" (annotationEvent)="onChartAnnotation($event)"
          (rangeEvent)="onChartRange($event)" [state]="state" [record_id]="rangeId" [user]="range.userId" [curves]="curvesRaw"
          [salt]="salt" [ids]="curvesQueue" [displayNavigator]="displayNavigatorRawData">
        </app-highchart>

        <div class="command">
          <div *ngIf="displayProcessedData" class="hx-g">
            <div class="hx-u-20-24">
              <div class="select-chart">
                <ul>
                  <li *ngFor="let chart of charts; let i = index">
                    <a [ngClass]="{ 'active ' : ( curvesQueue.indexOf(i) > -1 ), 'grayed' : waiting }" (click)="!waiting && toggleCurve(i, curvesQueue.indexOf(i) > -1)">{{
                      chart.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="hx-u-4-24">
              <div class="tip">
                <a *ngIf="!showHistogram" (click)="showHistogram = true">Show histograms <i class="hx-icon hx-icon hx-icon-caret-down"></i></a>
                <a *ngIf="showHistogram" (click)="showHistogram = false">Hide histograms <i class="hx-icon hx-icon hx-icon-caret-up"></i></a>
                <span *ngIf="!showHistogram">Click&nbsp;to&nbsp;show the&nbsp;histograms corresponding&nbsp;to&nbsp;the
                  metrics&nbsp;shown&nbsp;above</span>
                <span *ngIf="showHistogram">Click&nbsp;to&nbsp;hide the&nbsp;histograms</span>
              </div>
            </div>
          </div>
          <div *ngIf="displayRawData" class="hx-g">
            <div class="hx-u-1-1" class="select-chart">
              <ul>
                <li *ngFor="let chart of rawCharts; let i = index">
                  <a [ngClass]="{ 'active ' : ( curvesRawQueue.indexOf(i) > -1 ), 'grayed' : waiting }" (click)="!waiting && toggleCurveRaw(i, curvesRawQueue.indexOf(i) > -1 )">{{
                    chart.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngFor="let histogram of range.histogramsReordered; let i = index">
          <ng-container *ngIf="displayedHistograms[i] && showHistogram">
            <div class="hx-g">
              <div class="hx-u-1-1 metric-zone-title">
                {{ histogram.name }}
              </div>
            </div>
            <div class="hx-g">
              <div class="hx-u-18-24">
                <div class="metric-zone-chart">
                  <app-bchart [values]="histogram.valueForChart" [optionsColumnColors]="histogram.options" type="column"
                    unit="min" xAxis="Unit" [optionsAxis]="optionsAxis" [xAxisTitle]="histogram.xAxisTitle"></app-bchart>
                </div>
              </div>
              <div class="hx-u-6-24 metric-zone-area">
                <div class="metric-zone">
                  <ng-container *ngFor="let zone of histogram.valueForChart; let j = index">
                    <ul>
                      <li *ngIf="histogram.id !== 308 && histogram.id !== 309" class="metric-zone-{{ j }}">{{ zone[1] | durationMin2HrMinForm }} {{ ZONE_LABELS[j] }}</li>
                      <li *ngIf="histogram.id === 308" [style.color]="SLEEP_POSITION_LABELS[j].color"
                        [style.background-color]="SLEEP_POSITION_LABELS[j].bgcolor">{{ zone[1] | durationMin2HrMinForm }}
                        {{ SLEEP_POSITION_LABELS[j].name }}</li>
                      <li *ngIf="histogram.id === 309" [style.color]="SLEEP_PHASE_LABELS[j].color"
                        [style.background-color]="SLEEP_PHASE_LABELS[j].bgcolor">{{ zone[1] | durationMin2HrMinForm }} {{
                          SLEEP_PHASE_LABELS[j].name }}</li>
                    </ul>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section name="activities" *ngIf="range" [ngClass]="{ 'active' : selector.boxes.get('activities') && selector.boxes.get('activities').displayed, 'inactive' : !selector.boxes.get('activities'), 'inactive' : selector.boxes.get('activities') && selector.boxes.get('activities').displayed === false }" class="metrics">
    <div class="title-wrapper">
      <div class="title hx-g">
        <div class="hx-u-23-24">
          Subactivities
        </div>
        <div class="hx-u-1-24">
          <div *ngIf="subRanges" class="occurence tip">
            {{ subRanges.length - 1 }}
            <span>Nbr&nbsp;of&nbsp;subactivities</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="hx-table hx-table-ranges">
        <thead *ngIf="subRanges && subRanges.length > 1">
          <tr>
            <th>Name</th>
            <th>Duration</th>
            <th>Activity type</th>
            <th>HR avg.</th>
            <th>BR avg.</th>
            <th>Energy</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let subrange of subRanges">
            <tr *ngIf="subrange.id != rangeId" (mouseenter)="highlightRange(subrange) " (mouseleave)="dishighlightRange()">
              <td>
                {{ subrange.name }}
              </td>
              <td>{{ subrange.duration }}</td>
              <td><i class="hx-icon hx-icon {{ subrange.activity | activityName:activityTypes | activityTypeToIconClass }}"></i>
                {{ subrange.activity | activityName:activityTypes }}</td>
              <td> {{ subrange | dbRecordToMetric:44:'bpm' }}</td>
              <td>{{ subrange | dbRecordToMetric:12:'rpm' }}</td>
              <td> {{ subrange | dbRecordToMetric:149:'Cal' }}</td>
              <td>
                <div class="tip"><button class="button button-green" type="button" (click)="redirectToRange(subrange.id)"><i
                      class="hx-icon hx-icon-eye"></i></button><span>Activity details</span></div>
                <!--div class="tip"><button class="button button-green" type="button" (click)="editRange(subrange)"><i class="hx-icon hx-icon-edit"></i></button><span>Edit activity</span></div-->
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </section>


  <section name="annotations" *ngIf="range" class="metrics">
    <div class="title-wrapper">
      <div class="title hx-g">
        <div class="hx-u-23-24">
          Annotations
        </div>
        <div class="hx-u-1-24">
          <div class="occurence tip">
            {{ annotations.length }}
            <span>Nbr&nbsp;of&nbsp;annotations</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="hx-table hx-table-ranges">
        <thead *ngIf="annotations.length > 0">
          <tr>
            <th>Name</th>
            <th>Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let annotation of annotations">
            <tr (mouseenter)="highlightAnnotation(annotation)" (mouseleave)="dishighlightAnnotation()">
              <td>{{ annotation.annotation }}</td>
              <td>{{ annotation.start | dateHumanize }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </section>

  <ng-container *ngIf="gpsPositions.length > 1">
    <section id="map" name="map" *ngIf="range" [ngClass]="{ 'active' : selector.boxes.get('map') && selector.boxes.get('map').displayed, 'inactive' : !selector.boxes.get('map'), 'inactive' : selector.boxes.get('map') && selector.boxes.get('map').displayed === false }" class="metrics">
      <div class="title-wrapper hx-g">
        <div class="hx-u-23-24">
          <div class="title">
            Map
          </div>
        </div>
        <div class="hx-u-1-24">
          <img (click)="openModal('map')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
        </div>
      </div>
      <app-map *ngIf="gpsPositions.length > 1" [gpsPositions]="gpsPositions"></app-map>
      <!--div *ngIf="gpsPositions.length <= 1" class="panel-box-body">
            <div class="missing-info">
              No GPS info available
            </div>
          </div-->
    </section>
  </ng-container>

  <ng-container *ngIf="gpsPositions.length > 1">
    <section name="altitude" *ngIf="range" [ngClass]="{ 'active' : selector.boxes.get('altitude') && selector.boxes.get('altitude').displayed, 'inactive' : !selector.boxes.get('altitude'), 'inactive' : selector.boxes.get('altitude') && selector.boxes.get('altitude').displayed === false }" class="metrics">
      <div class="title-wrapper">
        <div class="title">
          Altitude
        </div>
      </div>
      <div *ngIf="!loading">
        <app-bchart *ngIf="gpsPositions.length > 1" [values]="altitudes" type="spline" unit="m"></app-bchart>
        <!--div *ngIf="gpsPositions.length <= 1" class="panel-box-body">
              <div class="missing-info">
                No altitude info available
              </div>
            </div-->
      </div>
    </section>
  </ng-container>

</div>

<help-modal id="charts-help" style="display: none;">
  <p>
    <img height="24" src="/assets/icons/zoom.svg">: When selected, it allows you to click and drag on the graph to
    select
    the time period that you wish to zoom in. Click again on the <img height="24" src="/assets/icons/zoom.svg"> to
    reinitialize
    the view.<br>
    <img height="24" src="/assets/icons/flag.svg">: This allows you to add annotations to your activity. Click on the
    specific
    time point on the graph where you want to add an annotation <img height="24" src="/assets/icons/flag.svg">, then
    add
    a note. Click on "Add" to save your annotation.<br>
    <img height="24" src="/assets/icons/download_a.svg">: This allows you to import your data in csv, binary or edf
    format.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>


<help-modal id="chartsbtn-help" style="display: none;">
  <p>The view will be adjusted according to the type of data selected (processed data or raw data). The chart legend
    can be
    used to activate or deactivate the view of your choice of metrics to be displayed. For example, you can click on
    "Raw
    Resp." and deactivate "Raw Resp. (abdominal)" to have a better view of the thoracic respiration curve or vice
    versa.
    You can do this also for the "Acceleration", by deactivating "Acceleration -X, -Y or -Z".
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>


<help-modal id="map-help" style="display: none;">
  <p>You will be able to track simultaneously your metrics (in the Charts section) and the altitude over your itinerary
    by moving
    your mouse cursor along your cycling route or your run on the map. GPS data from your phone will only be taken into
    account
    if you are carrying your phone with you (with the Hexoskin App turned on) during a cycling or run activity only.
  </p>
  <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>

<app-growl [value]="msgs"></app-growl>