import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, Input, EventEmitter, Renderer } from '@angular/core';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { DomHandler } from '../../../core/dom/domhandler';
import { ErrorAlertService } from './error-alert.service';
import { Subscription } from 'rxjs/Subscription';
import { ErrorAlert } from './error-alert';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.scss',
    '../../../../assets/stylesheets/button.scss']
})
export class ErrorAlertComponent implements AfterViewInit, OnDestroy {


    @Input() header: string;

    @Input() unit: string;

    @Input() kpi: string;

    @Input() range: any;

    @Input() resource_uri: string;

    @Input() icon: string;

    @Input() message: string;

    @Input() acceptIcon: string = 'fa-check';

    @Input() acceptLabel: string = 'Yes';

    @Input() acceptVisible: boolean = true;

    @Input() deleteVisible: boolean = true;

    @Input() updateVisible: boolean = true;

    @Input() rejectIcon: string = 'fa-close';

    @Input() rejectLabel: string = 'No';

    @Input() rejectVisible: boolean = true;

    @Input() width: any;

    @Input() height: any;

    @Input() closeOnEscape: boolean = true;

    @Input() rtl: boolean;

    @Input() closable: boolean = true;

    @Input() responsive: boolean = true;

    @Input() appendTo: any;

    @Input() key: string;

    date: string;

    value: number;

    popup: ErrorAlert;

    _visible: boolean;

    documentEscapeListener: any;

    documentResponsiveListener: any;

    mask: any;

    contentContainer: any;

    positionInitialized: boolean;

    subscription: Subscription;

    constructor(public el: ElementRef, public domHandler: DomHandler,
        private router: Router, private eventAggregator: EventAggregator,
        public renderer: Renderer, private popupService: ErrorAlertService) {

        /**
         * Default date (today as most common case)
         */
        let today = new Date();
        let local = new Date(today);
        local.setMinutes(today.getMinutes() - today.getTimezoneOffset());
        this.date = local.toJSON().slice(0, 10);


        this.subscription = popupService.requirePopup$.subscribe(popup => {


            if (popup.key === this.key) {

                this.popup = popup;
                this.message = this.popup.message || this.message;
                this.icon = this.popup.icon || this.icon;
                this.header = this.popup.header || this.header;
                this.unit = this.popup.unit || this.unit;
                this.kpi = this.popup.kpi || this.kpi;
                this.range = this.popup.range;
                this.resource_uri = this.popup.resource_uri || this.resource_uri;
                this.value = popup.value ? popup.value : null;

                if (popup.date) {
                    let _local = new Date(popup.date);
                    _local.setMinutes(today.getMinutes() - today.getTimezoneOffset());
                    let date = _local.toJSON().slice(0, 10);
                    this.date = popup.date ? date : this.date;
                }

                this.rejectVisible = this.popup.rejectVisible === null ? this.rejectVisible : this.popup.rejectVisible;
                this.acceptVisible = this.popup.acceptVisible === null ? this.acceptVisible : this.popup.acceptVisible;
                this.deleteVisible = this.popup.deleteVisible === null ? this.deleteVisible : this.popup.deleteVisible;
                this.updateVisible = this.popup.updateVisible === null ? this.updateVisible : this.popup.updateVisible;

                if (this.popup.accept) {
                    this.popup.acceptEvent = new EventEmitter();
                    this.popup.acceptEvent.subscribe(this.popup.accept);
                }

                if (this.popup.update) {
                    this.popup.updateEvent = new EventEmitter();
                    this.popup.updateEvent.subscribe(this.popup.update);
                }

                if (this.popup.delete) {

                    this.popup.deleteEvent = new EventEmitter();
                    this.popup.deleteEvent.subscribe(this.popup.delete);
                }

                if (this.popup.reject) {
                    this.popup.rejectEvent = new EventEmitter();
                    this.popup.rejectEvent.subscribe(this.popup.reject);
                }

                this.visible = true;
            }
        });
    }

    @Input() get visible(): boolean {
        return this._visible;
    }

    set visible(val: boolean) {
        this._visible = val;

        if (this._visible) {
            if (!this.positionInitialized) {
                this.center();
                this.positionInitialized = true;
            }

            this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
        }

        if (this._visible)
            this.enableModality();
        else
            this.disableModality();
    }

    ngAfterViewInit() {

        this.contentContainer = this.domHandler.findSingle(this.el.nativeElement, '.hx-ui-popup-content');

        if (this.responsive) {
            this.documentResponsiveListener = this.renderer.listenGlobal('window', 'resize', (event) => {

                this.center();
            });
        }

        if (this.closeOnEscape && this.closable) {
            this.documentEscapeListener = this.renderer.listenGlobal('body', 'keydown', (event) => {
                if (event.which === 27) {
                    if (this.el.nativeElement.children[0].style.zIndex === DomHandler.zindex) {
                        this.hide(event);
                    }
                }
            });
        }

        if (this.appendTo) {
            if (this.appendTo === 'body')
                document.body.appendChild(this.el.nativeElement);
            else
                this.domHandler.appendChild(this.el.nativeElement, this.appendTo);
        }
    }

    center() {
        let container = this.el.nativeElement.children[0];

        let elementWidth = this.domHandler.getOuterWidth(container);
        let elementHeight = this.domHandler.getOuterHeight(container);
        if (elementWidth === 0 && elementHeight === 0) {
            container.style.visibility = 'hidden';
            container.style.display = 'block';
            elementWidth = this.domHandler.getOuterWidth(container);
            elementHeight = this.domHandler.getOuterHeight(container);
            container.style.display = 'none';
            container.style.visibility = 'visible';
        }
        let viewport = this.domHandler.getViewport();
        let x = (viewport.width - elementWidth) / 2;
        let y = (viewport.height - elementHeight) / 2;

        container.style.left = x + 'px';
        container.style.top = y + 'px';
    }

    enableModality() {
        if (!this.mask) {
            this.mask = document.createElement('div');
            this.mask.style.zIndex = this.el.nativeElement.children[0].style.zIndex - 1;
            this.domHandler.addMultipleClasses(this.mask, 'hx-ui-widget-overlay hx-ui-popup-mask');
            document.body.appendChild(this.mask);
        }
    }

    disableModality() {
        if (this.mask) {
            document.body.removeChild(this.mask);
            this.mask = null;
        }
    }

    hide(event?: Event) {
        this.visible = false;

        if (event) {
            event.preventDefault();
        }
    }

    moveOnTop() {
        this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
    }

    ngOnDestroy() {
        this.disableModality();

        if (this.documentResponsiveListener) {
            this.documentResponsiveListener();
        }

        if (this.documentEscapeListener) {
            this.documentEscapeListener();
        }

        if (this.appendTo && this.appendTo === 'body') {
            document.body.removeChild(this.el.nativeElement);
        }

        this.subscription.unsubscribe();
    }

    accept() {
        if (this.popup.acceptEvent) {
            this.popup.acceptEvent.emit();
            this.popupService.postTask({
                "action": "save",
                "date": this.date,
                "value": this.value,
                "kpi": this.kpi,
                "resource_uri": this.resource_uri
            });
            this.value = null;
        }

        this.hide();
        this.popup = null;
    }


    goToRange() {
        const link = ['/range-view/' + this.range.id];
        this.router.navigate(link);
    }

    delete() {
        if (this.popup.deleteEvent) {
            this.popup.deleteEvent.emit();
            this.popupService.postTask({
                "action": "delete",
                "date": this.date,
                "value": this.value,
                "kpi": this.kpi,
                "resource_uri": this.resource_uri
            });
            this.value = null;
        }
        this.hide();
        this.popup = null;
    }

    update() {
        if (this.popup.updateEvent) {
            this.popup.updateEvent.emit();
            this.popupService.postTask({
                "action": "update",
                "date": this.date,
                "value": this.value,
                "kpi": this.kpi,
                "resource_uri": this.resource_uri
            });
            this.value = null;
        }
        this.hide();
        this.popup = null;
    }

    reject() {
        if (this.popup.rejectEvent) {
            this.popup.rejectEvent.emit();
        }

        this.hide();
        this.popup = null;
    }
}
