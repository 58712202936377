import { Component, Input } from '@angular/core';
import { CalendarEvent } from '../../calendar-utils';

@Component({
  selector: 'app-calendar-event-title',
  templateUrl: './calendar-event-title.component.html',
  styleUrls: ['../../../../../../assets/stylesheets/core/icon.scss']
})
export class CalendarEventTitleComponent {
  @Input() event: CalendarEvent;

  @Input() view: string;

  linkToRecordView() {

  }
}
