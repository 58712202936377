

export class HxToolsGeo {

    static retrieveAltitudeFromGPS(gps: Array<any>) {
        let altitudes = new Array<any>();
        let i = 0;
        let trackId;
        for (const _gps of gps) {
            if (i === 0 || _gps.track === trackId) { // We only keep the first track (there could be more than one ?)
                const utime = new Date(_gps.time);
                const mtime = utime.getTime();
                altitudes.push([mtime, _gps.altitude]);
            }
            if (i === 0) { // because several tracks (a bug?)
                trackId = _gps.track;
            }
            i++;
        }
        return altitudes;
    }

}
