var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable: member-ordering forin */
import { environment } from '../../../environments/environment';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiQuery, ApiService, DataService } from '../../core/services';
import 'rxjs/add/operator/mergeMap';
import { PopupService } from '../../shared/components/popup/popup.service';
import { ConfirmationService } from '../../shared/components/confirmation';
import { MetricOneStatusService } from '../../shared/components/metric/metric-one-status/metric-one-status.service';
import * as moment from 'moment/moment';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { AlertService } from '../../shared/components/alert/alert.service';
import { GlobalVariables } from '../../core/services/global.variables';
import { HelperComponent } from '../../shared/components/helper/helper.component';
import { HelpService } from '../../shared/components/help/help.service';
import { HxToolsTime } from '../../shared/hx-tools/hx-tools-time';
import { HxToolsGarmentSize } from '../../shared/hx-tools/hx-tools-garment-size';
import { Store } from '@ngrx/store';
import { COLORS, WRITABLE_KPIS, LINK_ACTIVITY, PATTERNS, DISPLAY_STATUS, SHOW_DISTRIBUTION, SHOW_EXTRA, OPTIONS_AXIS, FITNESS_SECTIONS_KPIS, DISTRIBUTION_CHART } from '../../config';
import { decrementalSort } from '../../shared/hx-tools';
import { EXPLANATIONS } from './explanations';
var FitnessComponent = /** @class */ (function (_super) {
    __extends(FitnessComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function FitnessComponent(store, apiService, eventAggregator, globalVariables, confirmationService, dataService, helpService, popupService, alertService, metricOneStatusService) {
        var _this = _super.call(this, helpService) || this;
        _this.store = store;
        _this.apiService = apiService;
        _this.eventAggregator = eventAggregator;
        _this.globalVariables = globalVariables;
        _this.confirmationService = confirmationService;
        _this.dataService = dataService;
        _this.helpService = helpService;
        _this.popupService = popupService;
        _this.alertService = alertService;
        _this.metricOneStatusService = metricOneStatusService;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        /**
         * Minimal number of points to display historical
         */
        _this.minimumNbrPointsForHistoric = 2;
        _this.salt = 0;
        /**
         * Telling whether the data are being retrieved from the server or proceeded.
         */
        _this.loading = true;
        /**
         * Boolean array telling which charts are displayed
         */
        //displayedChart: Array<any>;
        /**
         *  Time range
         */
        _this.defaultRange = {
            year: 1,
            month: 0,
            week: 0,
            day: 0
        };
        /* Garment suggested size */
        _this.suggested_size = null;
        _this.maxTime = 0;
        _this.minTime = Number.MAX_SAFE_INTEGER;
        /**
         * X Axis format to pass to app-bchart
         */
        _this.xAxis = {
            min: null,
            type: 'datetime',
            ordinal: false,
            dateTimeLabelFormats: {
                second: '%Y-%m-%d<br/>%H:%M:%S',
                minute: '%Y-%m-%d<br/>%H:%M',
                hour: '%Y-%m-%d<br/>%H:%M',
                day: '%d/%m/%y',
                week: '%d/%m/%y',
                month: '%d/%m/%y',
                year: '%Y'
            },
            title: {
                text: null
            }
        };
        _this.xAxisGaussian = {
            visible: false
        };
        _this.showedExplanations = {
            "hr_max": false,
            "hr_recov": false,
            "hr_rest": false,
            "vo2_max": false,
            "weight": false,
            "height": false,
            "bmi": false,
            "total_sleep_time": false,
            "sleep_latency": false,
            "sleep_efficiency": false
        };
        _this.kpiSections = [];
        // Does not display help icons straight away. Wait for the section rendered.
        _this.displayHelp = false;
        _this.timeOptionsAxis = {
            yAxis: [
                {
                    title: {
                        text: 'HH:MM'
                    },
                    labels: {
                        formatter: function () {
                            if (Number.isInteger(this.value)) {
                                var time = this.value;
                                var hours = Math.floor(time / 3600);
                                var _mins = Math.round((time % 3600) / 60);
                                var duration = "";
                                if (_mins < 10) {
                                    duration = hours + ':0' + _mins;
                                }
                                else {
                                    duration = hours + ':' + _mins;
                                }
                                return duration;
                            }
                            else {
                                return this.value;
                            }
                        }
                    }
                }
            ]
        };
        /**
         * Treatment of messages received from event aggregator
         */
        _this.onMessageReceived = function (payload) {
            if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) {
                // user switch thus the view is updated
                _this._init();
            }
            else if (payload.object.msg === HxEvent.CHART__EDIT_KPI) {
                _this.edit(payload.object); // todo
            }
        };
        _this.explanations = EXPLANATIONS;
        // Display dialog box to add new kpi value
        _this.metricOneStatusService.tasksConfirmed$.subscribe(function (kpi) {
            _this.add(kpi.title, kpi.unit, kpi.name, kpi.pattern);
        });
        _this.subscriptionTasks = _this.popupService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === "delete") {
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to delete this KPI?',
                    header: 'Delete Confirmation',
                    icon: 'fa fa-trash',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        _this.apiService.deleteKPI(task.resource_uri, 'fitness,profile').subscribe(function () {
                            if (!environment.production) { }
                            _this.msgs = [];
                            _this.msgs.push({
                                severity: 'success',
                                summary: 'Confirmed',
                                detail: 'The KPI has been successfully deleted.'
                            });
                            _this._init(true);
                            _this.salt = Math.random(); // todo
                        });
                    }
                });
            }
            if (task.action === "update") {
                _this.confirmationService.confirm({
                    message: 'Are you sure that you want to update this KPI?',
                    header: 'Update Confirmation',
                    icon: 'fa fa-trash',
                    rejectVisible: true,
                    acceptVisible: true,
                    accept: function () {
                        var o = new Object();
                        o.value = parseInt(task.value, null);
                        _this.apiService.updateKPI(task.resource_uri, o).subscribe(function () {
                            this.msgs = [];
                            this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'The KPI has been successfully updated.' });
                            this._init(true);
                            this.salt = Math.random(); // todo
                        });
                    }
                });
            }
            if (task.action === "save") {
                var unixDate = moment(task.date).format("X");
                _this.apiService.saveKPI(task.kpi, Number(task.value), _this.user.profile.id, Math.round(256 * parseInt(unixDate, null))).subscribe(function (kpi) {
                    _this.msgs = [];
                    _this.msgs.push({
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'The KPI ' + kpi['name'] + ' has been successfully added.'
                    });
                    // We add the value without retrieving it from the server.
                    // Ulgy : The server shoud returned the new list of kpis
                    _this.values[kpi['kpi']].push([1000 * kpi['start'] / 256, kpi['value'], kpi['resource_uri']]);
                    _this.values[kpi['kpi']].sort(decrementalSort);
                    _this.ids[kpi['kpi']][1000 * kpi['start'] / 256] = kpi['resource_uri'];
                    _this.salt = Math.random(); // todo
                    _this._init(true);
                });
            }
        });
        return _this;
    }
    /**
     * Building the form on init
     */
    FitnessComponent.prototype.ngOnInit = function () {
        // Lists to events from outside the component
        this.eventAggregator.getEvent(MessageSentEvent).subscribe(this.onMessageReceived);
        this._init();
    };
    /**
     * Actions performed after view init
     */
    FitnessComponent.prototype.ngAfterViewInit = function () {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.FSS__UPDATE
        }));
    };
    /**
     * Init data
     */
    FitnessComponent.prototype._init = function (reload) {
        var _this = this;
        if (!reload) {
            this.loading = true;
        }
        // Get the current selected subject
        // If the current subject is not defined, the subject is the logged user
        this.user = this.globalVariables.get('currentSubject');
        if (this.user === undefined) {
            this.user = this.globalVariables.get('currentUser');
        }
        // Check whether profile is an uri or an object
        // Server does not provide always the same...
        var resourceUri;
        if (this.user.profile !== null && typeof this.user.profile === 'object') {
            resourceUri = this.user.profile.resource_uri;
            this.profileId = this.user.profile.id;
        }
        else {
            resourceUri = this.user.profile;
            this.profileId = resourceUri.split('/')[3];
            // We should handle an error with injectable..
            /*if (!Number.isInteger(this.profileId)) {
              this.router.navigate(['/']);
            }*/
        }
        var kpis = new Array();
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            // We retrieve the time range from preferences stored on the server 
            if (data.preferences && data.preferences.healthStatusRangeSelector) {
                _this.defaultRange = data.preferences.healthStatusRangeSelector;
            }
            _this.kpiSections = FITNESS_SECTIONS_KPIS;
            // Start range calculation
            _this.start = moment().subtract(_this.defaultRange.day, "day")
                .subtract(_this.defaultRange.week, "week").subtract(_this.defaultRange.month, "month").
                subtract(_this.defaultRange.year, "year").unix();
            kpis = _this._parseFitness(data['fitness']); // todo : try or if
            _this._populate(kpis);
            return _this.apiService.getQuery(ApiQuery.get_metric_distribution_for_profile_id, { id: _this.profileId });
        }).flatMap(function (data) {
            _this.distributions = new Array();
            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                var dist = _a[_i];
                _this.distributions[dist.name] = _this._buildDistribution(dist, kpis[dist.name].value);
            }
            return _this.apiService.getHistoricalFitness(_this.profileId, _this.start);
        }).subscribe(function (data) {
            var that = _this;
            that.values = new Array();
            that.ids = new Array();
            var historical = new Array();
            for (var _i = 0, _a = that.kpiSections; _i < _a.length; _i++) {
                var kpiSection = _a[_i];
                var _loop_1 = function (kpi) {
                    historical[kpi] = JSON.parse(JSON.stringify(data.objects)).filter(function (entry) {
                        return entry.kpi === kpi;
                    });
                    // Sort the kpi values as they may not be in the chronological order when
                    // received by backend
                    historical[kpi].sort(function (a, b) {
                        return a.start - b.start;
                    });
                    that.values[kpi] = new Array();
                    that.ids[kpi] = new Array();
                    for (var _i = 0, _a = historical[kpi]; _i < _a.length; _i++) {
                        var kpiHistorical = _a[_i];
                        if (kpiHistorical.start != null) {
                            var resource_uri = kpiHistorical.resource_uri ? kpiHistorical.resource_uri : null;
                            var range = kpiHistorical.range ? kpiHistorical.range : null;
                            var historical_start = 1000 * kpiHistorical.start / 256;
                            if (kpiHistorical.unit.si_short === 's') {
                                var time = kpiHistorical.value;
                                time = Number((time).toFixed(3));
                                that.values[kpi].push([historical_start,
                                    time, resource_uri, range]);
                            }
                            else {
                                that.values[kpi].push([historical_start, Math.round(100 * kpiHistorical.value) / 100, resource_uri, range]);
                            }
                            that.ids[kpi][historical_start] = [resource_uri, range];
                            // Limits of the X axis.
                            if (historical_start > _this.maxTime) {
                                _this.maxTime = historical_start;
                            }
                            if (historical_start < _this.minTime) {
                                _this.minTime = historical_start;
                            }
                        }
                    }
                };
                for (var _b = 0, _c = kpiSection.kpis; _b < _c.length; _b++) {
                    var kpi = _c[_b];
                    _loop_1(kpi);
                }
            }
            if (!reload) {
                _this.loading = false;
            }
            setTimeout(function () {
                that.afterViewInit();
                this.displayHelp = true;
            }, 3000);
        });
    };
    FitnessComponent.prototype._buildDistribution = function (dist, kpiValue) {
        var distribution = new Array();
        for (var i = 0; i < dist.value[1].length; i++) {
            if ((kpiValue > dist.value[0][i][0]) && (kpiValue <= dist.value[0][i][1])) {
                distribution.push({
                    marker: DISTRIBUTION_CHART, y: dist.value[1][i]
                });
            }
            else {
                distribution.push({
                    marker: {}, y: dist.value[1][i]
                });
            }
        }
        return distribution;
    };
    FitnessComponent.prototype._parseFitness = function (fitness) {
        var size = [0, 0];
        var kpis = Array();
        for (var i = 0; i < fitness.length; i++) {
            var status_1 = fitness[i].zone_description;
            var unit = fitness[i].unit.si_short;
            if (status_1 && status_1.toLowerCase() === "na") {
                status_1 = null;
            }
            if (unit && unit.toLowerCase() === "na") {
                unit = null;
            }
            if (unit && unit.toLowerCase() === "na") {
                unit = null;
            }
            var statusColor = "success";
            if ((fitness[i].zone_description === "too long") ||
                (fitness[i].zone_description === "not recommended")) {
                statusColor = "warning";
            }
            kpis[fitness[i].kpi] = {
                "title": fitness[i].name,
                "name": fitness[i].kpi,
                "value": Math.round(100 * fitness[i].value) / 100,
                "status": status_1,
                "displayStatus": DISPLAY_STATUS[fitness[i].kpi],
                "showDistribution": SHOW_DISTRIBUTION[fitness[i].kpi],
                "showExtra": SHOW_EXTRA[fitness[i].kpi],
                "unit": unit,
                "statusColor": statusColor,
                "colors": COLORS[DISPLAY_STATUS[fitness[i].kpi]],
                "writable": WRITABLE_KPIS[fitness[i].kpi],
                "linkActivity": LINK_ACTIVITY[fitness[i].kpi],
                "pattern": PATTERNS[fitness[i].kpi],
            };
            if (fitness[i].kpi === "abdominal_circumference") {
                size[1] = fitness[i].value;
            }
            if (fitness[i].kpi === "thoracic_circumference") {
                size[0] = fitness[i].value;
            }
        }
        this.suggested_size = HxToolsGarmentSize.getSuggestedSize(size[0], size[1]);
        return kpis;
    };
    FitnessComponent.prototype._populate = function (kpis) {
        for (var j = 0; j < this.kpiSections.length; j++) {
            this.kpiSections[j].pkpis = new Array();
            if (this.kpiSections[j].kpis) {
                for (var i = 0; i < this.kpiSections[j].kpis.length; i++) {
                    this.kpiSections[j].pkpis[i] = kpis[this.kpiSections[j].kpis[i]];
                    if (this.kpiSections[j].kpis[i] !== undefined) {
                        this.kpiSections[j].pkpis[i].optionsAxis = OPTIONS_AXIS;
                        // if unit is s (seconds) then we customize the yAxis to display in HH:MM format
                        if (this.kpiSections[j].pkpis[i].unit === 's') {
                            this.kpiSections[j].pkpis[i].optionsAxis = this.timeOptionsAxis;
                        }
                        this.kpiSections[j].pkpis[i].selected = false;
                        if (i === 0)
                            this.kpiSections[j].pkpis[i].selected = true; // by default display first kpi
                        this.kpiSections[j].pkpis[i].displayed = false;
                    }
                }
                this.kpiSections[j].pkpis[0].displayed = true;
            }
        }
    };
    FitnessComponent.prototype.onNumberChanged = function (ev) {
        this.start = ev.date.unix();
        if (ev.range && ev.range.length === 4) {
            this.defaultRange = {
                day: ev.range[0],
                week: ev.range[1],
                month: ev.range[2],
                year: ev.range[3]
            };
        }
    };
    FitnessComponent.prototype.updatePeriod = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            var preferences = data.preferences;
            preferences.healthStatusRangeSelector = _this.defaultRange;
            return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(function () {
            if (!environment.production) { }
            _this.msgs = [];
            _this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'The range has been saved in your preferences. The charts are being updated.'
            });
            _this._init();
        }, function (error) {
            console.error(error);
            _this.alertService.error(error[0]);
        });
    };
    /**
     * Display selected chart
     */
    FitnessComponent.prototype.displayChart = function (i, j) {
        for (var k = 0; k < this.kpiSections[i].pkpis.length; k++) {
            this.kpiSections[i].pkpis[k].displayed = false;
        }
        this.kpiSections[i].pkpis[j].displayed = true;
        for (var k = 0; k < this.kpiSections[i].pkpis.length; k++) {
            this.kpiSections[i].pkpis[k].selected = false;
        }
        this.kpiSections[i].pkpis[j].selected = true;
    };
    /**
     * Display dialog box for editing KPI
     */
    FitnessComponent.prototype.add = function (kpi, unit, name, pattern) {
        this.popupService.confirm({
            message: '',
            header: 'Add ' + kpi,
            icon: '',
            kpi: name,
            range: null,
            resource_uri: null,
            unit: unit,
            acceptVisible: true,
            rejectVisible: true,
            updateVisible: false,
            deleteVisible: false,
            pattern: pattern ? pattern : "[0-9]+([\.,][0-9]+)?",
            accept: function () { }
        });
    };
    /**
     * Display dialog box for editing KPI
     */
    FitnessComponent.prototype.edit = function (object) {
        if (LINK_ACTIVITY[object.label] === true) {
            var value = object.value;
            var unit = object.unit;
            if (object.unit === 's') {
                var time = object.value;
                // If time is longer than 1 second, otherwise keep it unchanged
                if (time > 1) {
                    value = HxToolsTime.second2HHMM(time);
                    unit = "hh:mm";
                }
                else {
                    // todo : refactoring, loop instead of multiples if
                    if (value * 10 >= 1) {
                        value = Number((value).toFixed(2));
                    }
                    if (value * 100 >= 1) {
                        value = Number((value).toFixed(3));
                    }
                }
            }
            this.popupService.confirm({
                message: '',
                header: 'Edit ' + object.title,
                icon: '',
                range: object.range,
                resource_uri: object.resource_uri,
                kpi: object.label,
                value: value,
                date: object.date,
                deleteVisible: true,
                updateVisible: true,
                acceptVisible: false,
                rejectVisible: true,
                unit: unit,
                pattern: PATTERNS[object.label] ? PATTERNS[object.label] : ".*",
                update: function () { },
                delete: function () { }
            });
        }
    };
    /**
     * Display selected explanation
     */
    FitnessComponent.prototype.showExplanations = function (kpiName) {
        if (this.showedExplanations[kpiName]) {
            this.showedExplanations[kpiName] = false;
        }
        else {
            this.showedExplanations[kpiName] = true;
        }
    };
    FitnessComponent.prototype.ngOnDestroy = function () {
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.FSS__HIDE }));
        this.eventAggregator.getEvent(MessageSentEvent).unsubscribe(this.onMessageReceived);
        this.subscriptionTasks.unsubscribe();
    };
    return FitnessComponent;
}(HelperComponent));
export { FitnessComponent };
