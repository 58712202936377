<div #container [ngClass]="'hx-ui-growl hx-ui-widget'" [style.zIndex]="zIndex" [ngStyle]="style" [class]="styleClass">
    <div #msgel *ngFor="let msg of value" class="hx-ui-growl-item-container ui-state-highlight ui-corner-all hx-ui-shadow" aria-live="polite"
        [ngClass]="{'hx-ui-growl-message-info':msg.severity === 'info','hx-ui-growl-message-warn':msg.severity === 'warn',
                    'hx-ui-growl-message-error':msg.severity === 'error','hx-ui-growl-message-success':msg.severity === 'success'}">
        <div class="hx-ui-growl-item">
            <div class="hx-ui-growl-icon-close fa fa-close" (click)="remove(msg,msgel)"></div>
            <span class="hx-ui-growl-image fa fa-2x" [ngClass]="{'fa-info-circle':msg.severity === 'info','fa-exclamation-circle':msg.severity === 'warn',
                                'fa-close':msg.severity === 'error','fa-check':msg.severity === 'success'}"></span>
                <div class="hx-ui-growl-message">
                    <span class="hx-ui-growl-title">{{msg.summary}}</span>
                    <p [innerHTML]="msg.detail"></p>
                </div>
                <div style="clear: both;"></div>
        </div>
</div>
</div>