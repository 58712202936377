﻿export class HelpService {
    private modals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        // Add it only if not already there (no double)
        if (this.modals.filter(x => x.id === modal.id)[0] === undefined) this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, top: string) {
        // open modal specified by id
        let _id = id + "-help";
        let modal: any = this.modals.filter(x => x.id === _id)[0];

        modal.open(top);
    }

    close(id: string) {
        // close modal specified by id
        let _id = id + "-help";
        let modal: any = this.modals.filter(x => x.id === _id)[0];
        modal.close();
    }
}
