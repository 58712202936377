import { Component } from '@angular/core';
import { ApiService } from '../../core/services';
import { GlobalVariables } from '../../core/services/global.variables';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { AddBox, DisplaySelector } from '../../core/boxes-selector/actions/boxes-selector.actions';
import { SelectorService } from '../../shared/components/selector/selector.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-data',
    template: ``
})
export class BoxViewComponent {

    /**
     * View list telling if displayed or not
     */
    public displayView: Map<string, boolean>;

    /**
     * Box views
     */
    boxes = [];

    default_boxes = [];

    constructor(public store: Store<AppState>,
        public apiService: ApiService,
        public selectorService: SelectorService,
        public globalVariables: GlobalVariables) {


    }

    initBoxes() {
        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).subscribe(data => {
            this.boxes = new Array<any>();
            if (data.preferences && data.preferences.record_view && data.preferences.record_view && true) {
                let i = 1;
                for (let _box of this.default_boxes) {
                    if (this.isBoxPreferenceValid(data.preferences.record_view[_box.name])) {
                        _box = data.preferences.record_view[_box.name];
                    }
                    this.store.dispatch(new AddBox(
                        {
                            page: 'record_view',
                            name: _box.name,
                            label: _box.label,
                            displayed: _box.displayed,
                            order: i,
                            kpis: _box.kpis ? _box.kpis : null
                        }
                    ));
                }
                i++;
                this.boxes = this.default_boxes;
                this.store.dispatch(new DisplaySelector());
            } else {
                this.boxes = this.default_boxes;
            }
            for (const view of this.boxes) {
                this.displayView[view.name] = view.displayed;
            }
            let box_selectors = {
                'page': 'record_view',
                'boxes': this.boxes
            };
            this.selectorService.postBoxes(box_selectors);
        });
    }

    /**
   * Check if the preference box has all the fields 
   * @param preferenceBox
   */
    isBoxPreferenceValid(preferenceBox) {
        if (preferenceBox !== undefined
            && preferenceBox['label'] !== undefined
            && preferenceBox['displayed'] !== undefined
            && preferenceBox['name'] !== undefined
            && _.isString(preferenceBox['label'])
            && _.isBoolean(preferenceBox['displayed'])
            && _.isString(preferenceBox['name'])) {
            return true;
        }
        return false;
    }

}
