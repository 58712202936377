import { OnInit, OnChanges, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TimelineService } from "./timeline.service";
import { ApiQuery, ApiService } from '../../../core/services';
var WAITINGEVENT = -1;
var MOUSEDOWN = 0;
var TIMELINE_SIZE = 600;
var CHANGE_TIMELAPSE = 1;
var TimelineComponent = /** @class */ (function () {
    function TimelineComponent(router, apiService, timelineService) {
        var _this = this;
        this.router = router;
        this.apiService = apiService;
        this.timelineService = timelineService;
        /**
        * Mouse over range event
        */
        this.evMouseOverRangeMetrics = new EventEmitter();
        this.evMouseOverRange2Metrics = new EventEmitter();
        /**
        * Mouse out range event
        */
        this.evRemoveRangeMetrics = new EventEmitter();
        /**
         * Mouse click event
         */
        this.evClickRange = new EventEmitter();
        /**
         * Size of one hour on the timeline (by default 600 / 24)
         */
        this.hourSize = 25;
        /**
         * Mouse state (WAITING, DOWN, UP)
         */
        this.mouseState = WAITINGEVENT;
        this.loading = true;
        this.flashing_record = "#518CAB;#8eb8ce;#518CAB";
        this.flashing_range = "#518CAB;#8eb8ce;#518CAB";
        /* this.records = new Array<any>();
         this.ranges = new Array<any>();*/
        this.rid = Math.random();
        this.timelineService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === CHANGE_TIMELAPSE && task.object.time === _this.time) {
                _this.startHourV = task.object.dstart;
                _this.endHourV = task.object.dend;
                _this.render();
            }
        });
    }
    Object.defineProperty(TimelineComponent.prototype, "records", {
        get: function () {
            return this._records;
        },
        set: function (array) {
            if (array === undefined) {
                this._records = new Array();
            }
            else {
                this._records = array;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimelineComponent.prototype, "ranges", {
        get: function () {
            return this._ranges;
        },
        set: function (array) {
            this._ranges = array;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * At initialization, we draw the ranges and records on timeline
     * and the vertical pixel to track position of the mouse when the mouse
     * is presssed down.
     */
    TimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.getQuery(ApiQuery.all_activitiytype).subscribe(function (data) {
            _this.activityTypes = data.objects.filter(function (activitiy) { return activitiy.role === 'system' || activitiy.role === 'support'; });
            _this.startHourV = _this.startHour;
            _this.endHourV = _this.endHour;
            _this.render();
            _this.lines = new Array();
            for (var i = 0; i < TIMELINE_SIZE; i++) {
                _this.lines.push(i);
            }
        });
    };
    TimelineComponent.prototype.ngOnChanges = function () {
        this.startHourV = this.startHour;
        this.endHourV = this.endHour;
        this.render();
        this.lines = new Array();
        for (var i = 0; i < TIMELINE_SIZE; i++) {
            this.lines.push(i);
        }
    };
    /**
     * When start and end hours change
     */
    /* ngOnChanges() {
       this.startHourV = this.startHour;
       this.endHourV = this.endHour;
       this.render();
       this.lines = new Array<any>();
       for (let i = 0; i < TIMELINE_SIZE; i++) {
         this.lines.push(i);
       }
     }*/
    /**
     * highlight the zoom in (mouse pressed down and horizontal move over the timeline)
     */
    TimelineComponent.prototype.highlightZoomIn = function (line) {
        if ((this.mouseState === MOUSEDOWN) && (this.xx)) {
            // move to the right
            if (line > this.xx) {
                this.ww = line - this.xx;
                // move to the left
            }
            else {
                var w = this.original_xx - line;
                this.xx = line;
                this.ww = w;
            }
        }
    };
    /* ngOnChanges(...args: any[]) {
   
   
   
       this.render();
     }
   */
    TimelineComponent.prototype.render = function () {
        // hour size on the timeline
        this.hourSize = TIMELINE_SIZE / (this.endHourV - this.startHourV);
        /* Positionning the records along the timeline */
        this.recordCoordinates = new Array();
        for (var _i = 0, _a = this.records; _i < _a.length; _i++) {
            var record = _a[_i];
            var x = this.hourSize * (record.dstart - this.startHourV);
            var width = TIMELINE_SIZE - x;
            if (record.dend >= record.dstart) {
                width = this.hourSize * (record.dend - record.dstart);
            }
            this.recordCoordinates.push({
                "x": x,
                "width": width,
                "recordId": record.recordId,
                "color": record.highlighted ? "#518CAB" : "#c9d2db",
                "highlighted": record.highlighted
            });
        }
        /* Positionning the ranges along the timeline */
        this.rangeCoordinates = new Array();
        if (this.ranges) {
            for (var _b = 0, _c = this.ranges; _b < _c.length; _b++) {
                var range = _c[_b];
                var x = this.hourSize * (range.dstart - this.startHourV);
                var width = TIMELINE_SIZE - x;
                if (range.dend >= range.dstart) {
                    width = this.hourSize * (range.dend - range.dstart);
                }
                this.rangeCoordinates.push({
                    "x": x,
                    "width": width,
                    "rangeId": range.rangeId,
                    "color": range.highlighted ? "green" : "#183F66",
                    "highlighted": range.highlighted
                });
            }
        }
        this.hours = new Array();
        this.hoursAt2h = new Array();
        var j = this.startHourV;
        for (var i = 0; i <= this.endHourV - this.startHourV; i++) {
            var h = j;
            if (j > 24) {
                h = j - 24;
            }
            var _h = "" + h;
            if (h < 10) {
                _h = "0" + h;
            }
            this.hours.push(this.hourSize * i);
            var v = {
                "x": this.hourSize * i,
                "x2": this.hourSize * i + 1,
                "value": _h
            };
            /* display hours only at every 2 hours */
            if (i % 2 === 0)
                this.hoursAt2h.push(v);
            j++;
        }
        // We hide the timelapse highlight
        this.xx = 0;
        this.ww = 0;
        this.loading = false;
    };
    /**
     * Emit event mouse over range if the mouse
     * is not pressed
     */
    TimelineComponent.prototype.displayRangeMetrics = function (recordId) {
        if (this.mouseState !== MOUSEDOWN) {
            this.evMouseOverRangeMetrics.emit(recordId);
        }
    };
    TimelineComponent.prototype.transform = function (str) {
        if (!str) {
            return 'hx-icon-other';
        }
        var icon = str.toLowerCase().replace(/\s/g, '-');
        if (icon.match(/cross-country-ski/i)) {
            icon = "cross-country-skiing";
        }
        else if (icon.match(/downhill-ski/i)) {
            icon = "downhill-skiing";
        }
        else if (icon.match(/hike/i)) {
            icon = "hiking";
        }
        else if (icon.match(/jog|run|sprint|cooper/i)) {
            icon = "running";
        }
        else if (icon.match(/meditate/i)) {
            icon = "meditating";
        }
        else if (icon.match(/mountain-bike/i)) {
            icon = "mountain-biking";
        }
        else if (icon.match(/rest-test/i)) {
            icon = "5-minute-rest-test";
        }
        else if (icon.match(/roller-skate/i)) {
            icon = "roller-skating";
        }
        else if (icon.match(/roller-skate/i)) {
            icon = "rowing";
        }
        else if (icon.match(/skate/i)) {
            icon = "skating";
        }
        else if (icon.match(/swim/i)) {
            icon = "swimming";
        }
        else if (icon.match(/row/i)) {
            icon = "rowing";
        }
        else if (icon.match(/weight-training/i)) {
            icon = "weight-lifting";
        }
        return 'hx-icon-' + icon;
    };
    TimelineComponent.prototype.displayRangeMetricsRange = function (rangeId, $event) {
        var activityType = null;
        var _element = document.getElementById("ontop_" + this.rid);
        _element.innerHTML = null;
        var activity = this.ranges.filter(function (entry) {
            return entry.rangeId === rangeId;
        });
        activityType = this.activityTypes.filter(function (entry) {
            return entry.resource_uri === activity[0].activity;
        });
        _element.style['top'] = '0';
        _element.style['left'] = $event.layerX + 10 + 'px';
        _element.style['display'] = 'block';
        if (activityType[0]) {
            _element.innerHTML = "<i class=\"hx-icon hx-icon " +
                this.transform(activityType[0].name) + "\"></i><br>" + activity[0].name;
        }
        else {
            _element.innerHTML = "No info.";
        }
        if (this.mouseState !== MOUSEDOWN) {
            this.evMouseOverRange2Metrics.emit(rangeId);
        }
    };
    /**
     * Emit event mouse out range
     */
    TimelineComponent.prototype.removeRangeMetrics = function () {
        var _element = document.getElementById("ontop_" + this.rid);
        _element.style['display'] = 'none';
        if (this.mouseState !== MOUSEDOWN) {
            this.evRemoveRangeMetrics.emit();
        }
    };
    /**
     * Redirect to record view if click on record
     */
    TimelineComponent.prototype.clickRecord = function (recordId) {
        var link = ['/record-view/' + recordId];
        this.router.navigate(link);
        this.evClickRange.emit(recordId);
    };
    /**
     * Redirect to range view if click on record
     */
    TimelineComponent.prototype.clickRange = function (rangeId) {
        var link = ['/range-view/' + rangeId];
        this.router.navigate(link);
        this.evClickRange.emit(rangeId);
    };
    return TimelineComponent;
}());
export { TimelineComponent };
