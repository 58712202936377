import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'metric-small',
  templateUrl: './metric-small.component.html',
  styleUrls: ['./metric-small.component.scss']
})
export class MetricSmallComponent implements OnInit {

  @Input() value: number;
  @Input() icon: string;
  @Input() title: string;
  @Input() unit: string;
  @Input() prefix: string = "";

  constructor() { }

  ngOnInit() {
  }

}
