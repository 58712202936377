<div class="big_title">
    <div *ngIf="user && user.first_name != '';then firstname else nofirstname"></div>
    <ng-template #firstname>{{ user.first_name }}'s Calendar</ng-template>
    <ng-template #nofirstname>Programs</ng-template>
</div>

<ng-container *ngIf="loading">
    <section>
        <div class="hx-g header">
            <div class="hx-u-24-24">
                <div class="title">

                </div>
            </div>
        </div>
        <div id="spinner">
            <div></div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!loading">

    <section #calendar id="calendar">
        <div class="hx-g header">
            <div class="hx-u-23-24">
                <div class="title">
                    {{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}
                </div>
            </div>
            <div class="hx-u-1-24">
                <img (click)="openModal('calendar')" width="30px" style="cursor: pointer" src="/assets/icons/info.svg">
            </div>
        </div>
        <div *ngIf="!loading" class="hx-calendar padding-bottom">

            <calendar-header [(view)]="view " [(viewDate)]="viewDate" [(metrics)]="metrics" [(displayRecords)]="displayRecords" [(displayRanges)]="displayRanges">
            </calendar-header>

            <ng-template #customCellTemplate let-day="day " let-locale="locale ">
                <div [ngClass]="{'cell-with-record' : day.eventGroups && day.eventGroups.record}">
                    <div class="cal-cell-top2 hx-g">
                        <div class="hx-u-3-4">
                        </div>
                        <div class="hx-u-1-4 cal-day-number">
                            {{ day.date | calendarDate:'monthViewDayNumber':locale }}
                        </div>
                    </div>

                    <div class="cal-cell-top2 hx-g">
                        <div class="hx-u-1-1">

                            <div class="cell-totals">
                                <div *ngIf="day.eventGroups && day.eventGroups.all" style="border-top:1px solid black">
                                    <div style="cursor:pointer;" *ngFor="let r of day.eventGroups.all; let i = index" class="cal-day-range" style="width:100%">

                                        <a target="_blank" href="/{{ r.meta.type }}-view/{{ r.id }}">

                                        <div [ngClass]="{ 'r-r' : i < day.eventGroups.all.length - 1 }" style="height:28px;cursor:pointer"
                                            *ngIf="(displayRecords && r.meta.type =='record') || (displayRanges && r.meta.type =='range')">
                                            <div class="hx-g">
                                                <div class="hx-u-7-24 icon">
                                                    <span>
                                                    <img *ngIf="r.meta.type =='record'" width="34px" src="/assets/images/record.png">
                                                    <i *ngIf="r.meta.type =='range'" class="hx-i-calendar hx-icon {{ r.icon | activityTypeToIconClass }}"></i>
                                                </span>
                                                </div>
                                                <div class="hx-u-9-24 time"><span>{{ r.start_time }}</span></div>
                                                <div class="hx-u-8-24 duration" style="vertical-align:bottom;"><span>{{ r.duration_hh_mm }}</span></div>
                                            </div>
                                            <div class="hx-g">
                                                <div class="hx-u-7-24"></div>
                                                <div class="hx-u-9-24 time_legend"><span>start time</span></div>
                                                <div class="hx-u-8-24 duration_legend"><span>duration</span></div>
                                            </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="displayRanges22" class="cell-totals2">
                            <div *ngIf="day.eventGroups && day.eventGroups.range" style="padding-left:2px;border-top:1px solid black">
                                <a href="/{{ r.meta.type }}-view/{{ r.id }}" onclick="return false;">
                                    <div *ngFor="let r of day.eventGroups.range" class="cal-day-range" style="width:100%; cursor:pointer">
                                        <div class="" style="height:28px">
                                            <div class="hx-g">
                                                <div class="hx-u-7-24"><i class="hx-icon {{ r.icon | activityTypeToIconClass }}"></i></div>
                                                <div class="hx-u-9-24 time"><span>{{ r.start_time }}</span></div>
                                                <div class="hx-u-8-24 duration" style="vertical-align:bottom;"><span>{{ r.duration_hh_mm }}</span></div>
                                            </div>
                                            <div class="hx-g">
                                                <div class="hx-u-7-24"></div>
                                                <div class="hx-u-9-24 time_legend"><span>start time</span></div>
                                                <div class="hx-u-8-24 duration_legend"><span>duration</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="!day.isFuture && metrics" class="cell-totals">
                    <div class="hx-g">
                        <div class="hx-u-1-2">
                            <div class="cal-metrics cal-metrics-w-bottom-border cal-metrics-w-top-border" [ngClass]="{ 'cal-metrics-nodata' : day.energy === '-'  } ">
                                <div class="hx-icon hx-icon-energy"></div>
                                {{ day.energy }} cal
                            </div>
                        </div>
                        <div class="hx-u-1-2">
                            <div class="cal-metrics cal-metrics-w-left-border cal-metrics-w-bottom-border cal-metrics-w-top-border" [ngClass]="{ 'cal-metrics-nodata' : day.energy === '-'  }">
                                <div class="hx-icon hx-icon-steps"></div>
                                {{ day.steps }}
                            </div>
                        </div>
                    </div>
                    <div class="hx-g">
                        <div class="hx-u-1-2">
                            <div class="cal-metrics" [ngClass]="{ 'cal-metrics-nodata' : day.energy === '-'  }">
                                <div class="hx-icon hx-icon-heart-rate"></div>
                                {{ day.avg_heartrate }} bpm
                            </div>
                        </div>
                        <div class="hx-u-1-2">
                            <div class="cal-metrics cal-metrics-w-left-border" [ngClass]="{ 'cal-metrics-nodata' : day.energy === '-'  }">
                                <div class="hx-icon hx-icon-clock"></div>
                                {{ day.totalTime }}
                            </div>
                        </div>
                    </div>

                </div>
        </div>
        </ng-template>

        <div [ngSwitch]="view">

            <app-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [cellTemplate]="customCellTemplate"
                (beforeViewRender)="beforeMonthViewRender($event)" [activeDayIsOpen]="true"></app-calendar-month-view>

                <app-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events"></app-calendar-week-view>

                <app-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events"></app-calendar-day-view>

        </div>

        </div>
    </section>

</ng-container>


<help-modal id="calendar-help" style="display: none;">
    <p>Monthly calendar view which displays your "Daily Metrics", your "Records" and/or your "Activities". Every Record is represented
        by <img height="24" src="/assets/icons/rec.svg">, while each Activity shows up as a symbol that graphically represents a specific activity (for example,
        <img height="24" src="/assets/icons/sleep.svg"> is for a sleep activity). To directly access the record of your choice, click on <img height="24" src="/assets/icons/rec.svg"> and for an activity, click on <img height="24" src="/assets/icons/sleep.svg"> or any other activity-specific icons. Click
        on <img height="24" src="/assets/icons/pv_next.svg"> to navigate between months. Click on "Today" to return to the current month.
    </p>
    <!--button (click)="closeModal('timeline-help');">Close</button-->
</help-modal>