/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/calendar-date.pipe";
import * as i3 from "../../providers/calendar-date-formatter.provider";
import * as i4 from "./calendar-month-view-header.component";
var styles_CalendarMonthViewHeaderComponent = [];
var RenderType_CalendarMonthViewHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarMonthViewHeaderComponent, data: {} });
export { RenderType_CalendarMonthViewHeaderComponent as RenderType_CalendarMonthViewHeaderComponent };
function View_CalendarMonthViewHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "cal-cell"]], [[2, "cal-past", null], [2, "cal-today", null], [2, "cal-future", null], [2, "cal-weekend", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵppd(3, 3)], function (_ck, _v) { var currVal_4 = "cal-cell"; var currVal_5 = _v.context.$implicit.cssClass; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isPast; var currVal_1 = _v.context.$implicit.isToday; var currVal_2 = _v.context.$implicit.isFuture; var currVal_3 = _v.context.$implicit.isWeekend; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.date, "monthViewColumnHeader", _co.locale)); _ck(_v, 2, 0, currVal_6); }); }
function View_CalendarMonthViewHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "cal-cell-row cal-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthViewHeaderComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarMonthViewHeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_CalendarMonthViewHeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.CalendarDatePipe, [i3.CalendarDateFormatter, i0.LOCALE_ID]), (_l()(), i0.ɵand(0, [["defaultTemplate", 2]], null, 0, null, View_CalendarMonthViewHeaderComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarMonthViewHeaderComponent_3)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { days: 0, locale: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.days, _co.locale); var currVal_1 = (_co.customTemplate || i0.ɵnov(_v, 1)); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarMonthViewHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-month-view-header", [], null, null, null, View_CalendarMonthViewHeaderComponent_0, RenderType_CalendarMonthViewHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.CalendarMonthViewHeaderComponent, [], null, null)], null, null); }
var CalendarMonthViewHeaderComponentNgFactory = i0.ɵccf("app-calendar-month-view-header", i4.CalendarMonthViewHeaderComponent, View_CalendarMonthViewHeaderComponent_Host_0, { days: "days", locale: "locale", customTemplate: "customTemplate" }, {}, []);
export { CalendarMonthViewHeaderComponentNgFactory as CalendarMonthViewHeaderComponentNgFactory };
