
/**
* Explanations
*/
export const EXPLANATIONS = {
    "rmssd": `<p class="c1"><span class="c0">Why should I monitor my HRV?</span></p>
              <p class="c1"><span>
                  HRV is the variation in the time interval between heartbeats. Hexoskin
              measures heart rate (HR) and its natural variances from beat to beat.
              HRV depends on external or internal stimuli such as emotions, heat,
              respiration, sleep, and physical exertion. Note: A HR of 60 bpm doesn’t
              necessarily mean there is 1sec between each beat (see image).</span></p>
<p class="c1 c5"><span></span></p>
<img width="400px" src="/assets/images/AHR.png"></img>
<p class="c1 c5"><span></span></p>
<p class="c1"><span class="c0">Why should I monitor HRV?</span></p>
              <p class="c1"><span>
<p>HRV measures the cardiac balance of the autonomic nervous system (ANS). It’s a good sign if your heart rate varies; it means your ANS is active
and adjusting to every situation. Hexoskin allows you to create your personal HRV scores linked to your daily living habits (exercising, diet, stress)
and conditions. High-level athletes and coaches also use HRV to monitor training readiness and performance1. HRV monitoring with Hexoskin can
be used to prevent overtraining or overreaching, thus optimizing training efficiency.</span></p>
<p class="c1 c5"><span></span></p>

<p class="c1"><span class="c0">How do I get my HRV?</span></p>
              <p class="c1"><span>
Each time you move, talk or have stressful thoughts, your HRV changes. Exercise is a type of stress and causes extreme variations in the heart
rate. Studies have shown that HRV is difficult to interpret during exercise. Hexoskin provides simple HRV data during rest and sleep, a measure
that has more meaning than HRV obtained during exercise. Many factors influence HRV (see page 2). It is very important to control your testing
conditions for accurate results.</span></p>
<p class="c1 c5"><span></span></p>
<p class="c1"><span class="c0">Rest monitoring</span></p>
              <p class="c1"><span>
To monitor HRV and prevent overtraining, <b>RMSSD</b> (for Root Mean Square of Successive Differences) is widely used, RMSSD measures the time, difference between adjacent heartbeats in milliseconds (ms). Unlike resting heart rate, a higher value is better.

To build your HRV history, compare your results, and plan training or recovery strategies, we suggest that you perform a baseline protocol with your
Hexoskin (3 mornings within the same week) at least once a month. To do so, you must go through Hexoskin’s 5min Rest Test when you wake up
in the morning, after a good night’s sleep. Simply lie down and relax, but don’t fall asleep! It is very important to always do this test the same way
every time in order to obtain comparable and accurate results.

Note your RMSSD score for each test to find your personal average for a relaxed morning. Once your baseline value is set, your baseline zone will
be around this value +/- 10 % (see figure on page 2). Do the test every week with your Hexoskin, or every training day morning, to build a significant
history. A drop of >10%, associated with fatigue symptoms and decrease in sport performance during a few days or weeks, suggests that resting/
recovery strategy might be needed. Your Hexoskin health and recovery history will help you work on strategies to optimize performance and avoid
overtraining or health issues.
</span></p>

              `,
    "weight": `<p class="c1"><span class="c0">How to properly weigh yourself?</span></p>
              <p class="c1"><span>
              Place your scale on a flat and hard surface (i.e. avoid carpeting). Make sure that your
              scale is calibrated. If not, perform the calibration procedure according to the
              manufacturer’s instructions.
              If you are following a weight loss program, you should weigh yourself at least once a
              week or every day (around the same time during the day). You can keep a weight loss
              journal to help you stay on track until you reach your objective. Remember to set
              yourself realistic and achievable goals!</span>
              </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c0">Why is it important to input your weight?</span></p>
              <p class="c1"><span>Hexoskin system uses your profile information, such as your height, age and gender to
              process your raw data collected from your Hexoskin shirt and device to calculate
              different metrics displayed in the Dashboard.</span>
              </p>`,
    "height": `<p class="c1"><span class="c0">How to properly measure your height by yourself?</span></p>
              <p class="c1"><span>Take off your shoes, socks and any head accessories. Find a place with a flat and hard
              surface (i.e. avoid carpeting) and stand straight with your feet together and your back
              against a wall. Take a pencil and make a mark on the wall at the top of your head.
              Then, take a measuring tape and measure the distance from the floor to the mark on
              the wall.</span>
              </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c0">Why is it important to input your height?</span></p>
              <p class="c1"><span>Hexoskin system uses your profile information, such as your height, age and gender to
              process your raw data collected from your Hexoskin shirt and device to calculate
              different metrics displayed in the Dashboard.</span>
              </p>`,
    "bmi": `<p class="c1"><span class="c0">What is the BMI?</span></p>
              <p class="c1"><span>
              The Body Mass Index (BMI) is a tool based on your weight relative to your height used
              by healthcare providers to estimate your health status. Usually, the BMI is considered
              as a measure for obesity. According to the National Heart, Lung and Blood Institute
              (NHLBI) [1] and based on your BMI, you are:</span>
              </p>
              <p class="c1 c5"><span></span></p>

              <table class="t">
              <thead>
              <tr><td></td><td class="column-name">BMI value</td></tr>
              </thead>
              <tbody>
              <tr><td class="row-name">Underweight</td><td>Below 18.5</td></tr>
              <tr><td class="row-name">Normal</td><td>Between 18.5–24.9</td></tr>
              <tr><td class="row-name">Overweight</td><td>Between 25.0–29.9</td></tr>
              <tr><td class="row-name">Obesity</td><td>30.0 and Above</td></tr>
              </tbody>
              </table>

              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c0">How is the BMI calculated?</span></p>
              <p class="c1"><span>The formula to calculate the BMI is: BMI = weight in kilogram/(height in meter) 2 .
              The unit is Kg/m 2 .</span> </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c0">Why should I monitor my BMI?</span></p>
              <p class="c1"><span>The BMI is an estimate of your body fat and a good indicator for your risks for obesity-
              associated diseases, such as heart diseases and type-2 diabetes [1]. If you are
              overweight or obese, you can lower your risks by losing only a small amount of weight.</span> </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span>Be careful, taken alone the BMI can be misleading in certain populations. Indeed, the
              BMI does not take into consideration the age, the gender, the race, the muscle mass
              and/or the bone density. Therefore, the BMI alone can overestimate the body fat in
              athlete with greater muscle mass as it can underestimate it in the elderly who lost
              muscle mass and bone density [2,3,4]. However, for the general population, the BMI is
              still a good indicator of your health status, thus it is important to keep track of it.</span> </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c2">
              1.	https://www.nhlbi.nih.gov/health/educational/lose_wt/risk.htm<br>
              2.	Romero-Corral et al. Accuracy of Body Mass Index to Diagnose Obesity In the US Adult Population. Int J Obes (Lond). 2008 June; 32(6): 959–966.<br>
              3.	Rothman. BMI-related errors in the measurement of obesity. Int J Obes (Lond). 2008 Aug;32 Suppl 3:S56-9.<br>
              4.	Shah et al. Measuring adiposity in patients: the utility of body mass index (BMI), percent body fat, and leptin. PLoS One. 2012;7(4):e33308.<br>
              </span></p>
              `,
    "hr_max": `             <p class="c1"><span class="c0">What is HRmax?</span></p>


<div class="hx-g">
<div class="hx-u-2-3">
              <p class="c1"><span>The highest heart rate a person can achieve</span><span>&nbsp;</span><span>during a maximal exercise. </span><span>The value depends mainly on age, genetic, exercise type, and on medication. This value can be estimated by formulas.</span><span>&nbsp;</span><span>However, to have a more precise and individual HRmax value, it is better to do a maximal capacity test.</span><span>&nbsp;The maximal heart rate normally decreases with age and it is unrelated to exercise training. </span>
              </p>

              <p class="c1 c5"><span></span></p>

                            <p class="c1"><span>A well known formula that was probably devised by Haskell and Fox in 1970 states that maximal heart rate should be: HRmax&nbsp;</span><span>= 220 &ndash;
                age. </span><span>&nbsp;</span><span>A more precise formula is </span><span>HRmax = </span><span>208-0.7*age</span><span>&nbsp;but currently there is no acceptable formula to correctly predict HRmax. The given value is off by approximately 10-20 bpm</span><span>, depending on the individual. </span>
              </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c2">[ref1:Tanaka H., Monahan KD. &amp; Seals DR. Age-Predicted Maximal Heart Rate Revisited. Journal of the American College of Cardiology 37 (1); 153&ndash;56, 2001.]</span>
              </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c2">[ref2: Robergs R., Landwehr R. The Surprising History of the &ldquo;HRmax = 220 - age&rdquo;
                Equation. &nbsp;Journal of Exercise Physiology&nbsp;5&nbsp;(2), 2002]</span></p>

              <p class="c1 c5"><span class="c2"></span></p>

              <p class="c1"><span class="c0">How is calculated HRmax?</span></p>

              <p class="c1"><span>The maximal heart rate can be determined in laboratory or on the field through a maximal fitness test. The most reliable way to measure HRmax is a supervised fitness test in laboratory. It is usually done on a treadmill or on a stationary bike. There are also </span><span>field tests</span><span>&nbsp;that you can do to measure your heart rate by yourself. </span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span>Hexoskin&rsquo;s technology determines individual HRmax during maximal capacity tests (Ex. 400m Maximal Sprint Test).</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">Why should I monitor my maximal heart rate?</span></p>

              <p class="c1"><span>The maximal heart rate is used to measure your heart&rsquo;s maximal capacity of pumping blood in your body. While it is not a good indicator of fitness, it is an indicator that is widely used to determine individual and sport specific</span><span>&nbsp;heart rate training zones</span><span>. </span>
              </p>
              </div>
              <div class="hx-u-1-3">
              <img width="300px" src="/assets/images/HR-max.svg"></img>
              </div>
              </div>

              <p class="c1 c8"><span class="c2">
              Vashillo E.G et Al (2006) Applied Psychophysiologyand Biofeedback , Vol 31, No 2
              </p>
<br/>
<br/>
              <p class="c1 c5"><span>
              Adults who are beginning a new exercise regimen are advised to consult a physician before performing this test due to risks associated with reaching high heart rates. 
              </span></p>

            </div>
`,
    "hr_recov": `
              <p class="c1"><span class="c0">What is Heart Rate Recovery ?</span></p>

<div class="hx-g">
<div class="hx-u-2-3">

              <p class="c1"><span>Heart rate recovery (HRR) is the reduction of heart rate after a fixed duration of recovery after a peak intensity exercise. Heart rates that do not drop by more than 12 bpm one minute after stopping exercise are associated with an increased risk of mortality. A greater HR</span><span
                  class="c3">R</span><span>&nbsp;is associated with good cardiovascular function.</span></p>
              <p class="c1 c5"><span></span></p>
              <p class="c1"><span class="c2">[ref3: Cole, Christopher R., Eugene H. Blackstone, Fredric J. Pashkow, Claire E. Snader, and Michael S. Lauer. &ldquo;Heart-Rate Recovery Immediately after Exercise as a Predictor of Mortality.&rdquo;
                New England Journal of Medicine 341, no. 18 (October 28, 1999): 1351&ndash;57]</span></p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span>To determine HRR, a maximal capacity test has to be done and the person has to reach at least 85% of his predicted HRmax value (208-0.7*age) to ensure a valid HR recovery result. At the end of a maximal capacity test (Ex. 400m Maximal Sprint Test), the person has to rest for 1 minute. For the recovery, we recommend to continue to walk slowly after the test and to take big breaths to relax.</span>
              </p>

</div>
<div class="hx-u-1-3">
<img width="300px" src="/assets/images/HR-r.svg"></img>
</div>
</div>

              <p class="c1 c5"><span class="c0"></span></p>

              <p class="c1"><span class="c0">How is calculated HRR?</span></p>

              <p class="c1"><span>Hexoskin uses the highest HR value reached during a maximal capacity test (Ex. 400m Maximal Sprint Test) and the lowest HR value after 1 minute of recovery to determine HRR. HRR is available in your cardio profile.</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">Why should I monitor my resting heart rate?</span></p>

              <p class="c1"><span>A greater reduction in heart rate after exercise during the reference period is associated with a higher level of cardiovascular fitness[ref]. Fitness condition is associated with better mental and physical health and wellbeing</span><span>[ref].</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span>You can improve your HRR with exercise. Aerobic exercise is known to improve cardiovascular function and recovery capacity.</span>
              </p>
              <p class="c1 c5"><span></span></p>
              <p class="c1 c8"><span class="c2">[ref </span><span class="c2">Gibbons &nbsp;R.J., Balady &nbsp;G.J., Beasley &nbsp;J.W.; ACC/AHA guidelines for exercise testing. a report of the American College of Cardiology/American Heart Association Task Force on Practice Guidelines (Committee on Exercise Testing). J Am Coll Cardiol. 30 1997:260-315</span><span
                  class="c2">].</span><span class="c6">&nbsp;</span>
              </p>


`, "hr_rest": `
              <p class="c1"><span class="c0">What is HRrest?</span></p>

<div class="hx-g">
<div class="hx-u-2-3">
              <p class="c1"><span>Resting heart rate is the lowest heart rate value that is measured laid down when fully relaxed, without any distractions. </span><span>At rest, the average heart rate for women is 72-80 bpm and for men 64-72 bpm. Depending on fitness level, age, heart&rsquo;s size and strength of contraction, HRrest can be lower or higher. Cardiovascular training results in lower HRrest. Endurance athletes can have a resting heart rate </span><span>as low as 30 bpm.</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">How is calculated HRrest?</span></p>

              <p class="c1"><span>To determine your individual HRrest, you have to do a rest test. To have an accurate HRrest value, refrain from exercising, from smoking and from consuming stimulants, such as caffeine, in the 4-6 hours prior to the test.</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">Why should I monitor my resting heart rate?</span></p>

              <p class="c1"><span>The resting heart rate is a good indicator of fitness level. It is used to monitor the heart&rsquo;s efficiency to pump to blood into the body. It is also used to target heart rate </span><span>training zones</span><span>.</span>
              </p>
              </div>
<div class="hx-u-1-3">
<img width="300px" src="/assets/images/HR-rest.svg"></img>
</div>
</div>

`,
    "vo2_max":
      `         
            <p class="c1"><span class="c0">What is VO2?</span></p>

<div class="hx-g">
<div class="hx-u-2-3">
            <p class="c1"><span>VO</span><span class="c3">2</span><span>&nbsp;(or oxygen consumption) is the volume of oxygen that is used by your body to convert the energy from the food into energy usable at the cellular level. VO</span><span
                class="c3">2</span><span>&nbsp;is important in the context of exercise, because it&rsquo;s a measure of the body&#39;s ability to generate energy that allows muscles to work while exercising. </span>
            </p>

            <p class="c1 c5"><span></span></p>

            <p class="c1"><span class="c0">What is VO2max</span></p>

            <p class="c1"><span>VO</span><span class="c3">2</span><span>max is a measure of cardiorespiratory fitness. It is the maximum rate of oxygen consumption that is measured when a person is doing a maximal aerobic exercise, often measured during an incremental maximal exercise. This value is a good indicator of the endurance capacity of a person. VO</span><span
                class="c3">2</span><span>max is usually expressed in L/min or normalised by the mass of the person mL/kg/min. The average VO</span><span
                class="c3">2</span><span>&nbsp;max is around 35&ndash;40 mL/kg/min for an untrained man and around 27&ndash;31 mL/kg/min for an untrained woman</span><span>. </span>
            </p>
            <p class="c1 c5"><span></span></p>
            <p class="c1"><span class="c2">[</span><span class="c2">ref: Heywood, V (1998). &quot;Advance Fitness Assessment &amp; Exercise Prescription, 3rd Ed&quot;. p.&nbsp;48.]</span>
            </p>

            <p class="c1 c5"><span></span></p>

            <p class="c1"><span>&ldquo;It is an indicator of the oxygen availability but to predict an athlete&rsquo;s performance, other factors have to be considered such as muscle efficiency and fatigue&rdquo;.</span>
            </p>

            <p class="c1"><span
                class="c2">[ref:Noakes, Tim. 2001.&nbsp;The Lore of Running.&nbsp;(3rd edition)&nbsp;</span><span
                class="c10 c2"><a class="c4"
                                  href="https://www.google.com/url?q=http://en.wikipedia.org/wiki/Oxford_University_Press&amp;sa=D&amp;usg=AFQjCNFpt43oVmznJyLc-vZqecZ7e7_Tyw">Oxford
              University Press</a></span><span class="c2">]</span></p>

            <p class="c1 c5 c8"><span class="c6"></span></p>

            <p class="c1"><span class="c0">How is calculated VO</span><span class="c3 c0">2</span><span
                class="c0">max?</span></p>

            <p class="c1"><span>Many tests are used to calculate VO</span><span class="c3">2</span><span>max. The most precise ones are done in laboratory on treadmill or a bike in which exercise intensity is progressively increased until the participant reaches exhaustion. The test is performed with a mask to collect and analyze expired air.</span>
            </p>

            <p class="c1"><span>&nbsp;</span></p>

            <p class="c1"><span>The Cooper test estimates the VO</span><span class="c3">2</span><span>max by calculating the distance performed during a 12 minutes running.</span>
            </p>

            <p class="c1 c5"><span></span></p>

            <p class="c1"><span>The Leger test measures the VO</span><span class="c3">2</span><span>max by doing a multi-stage fitness test where the participant runs continuously between 2 points 20 meters apart at an increasing pace.</span>
                          </div>
<div class="hx-u-1-3">
<img width="300px" src="/assets/images/VO2-max.svg"></img>
</div>
</div>

          `,
    "total_sleep_time":
      `
        <p class="c1"><span class="c0">What is the Total Sleep Time (TST)?</span></p>
        
<p class="c1"><span>
The Total Sleep Time is the amount of time that you are actually sleeping. There are
different sleep stages REM (Rapid Eye Movement) and NREM (non REM). It has been
shown that dreaming and memory consolidation takes place during REM sleep [1].
</span></p>
<p class="c1 c5"><span></span></p>

<p class="c1"><span class="c0">How is the Total Sleep Time calculated?</span></p>


<p class="c1"><span>
To calculate the Total Sleep Time, you need to add the amount of time spent in REM
and NREM sleep stages.
The formula is: SleepTotalTime = Time in non-REM + Time in REM.
</span></p>
<p class="c1 c5"><span></span></p>
<p class="c1"><span class="c0">Why should I monitor my Total Sleep Time?</span></p>

<p class="c1"><span>
It is important to monitor your amount of sleep because sleep loss can cause fatigue
and impair your concentration, your memory as well as other cognitive processes [2].
The hours of sleep recommended vary according to the age group. According to the
National Sleep Foundation, you should sleep:
</span></p>
<p class="c1 c5"><span></span></p>

              <table class="t">
              <thead>
              <tr><td>Age Group</td><td>Hours of sleep needed</td></tr>
              </thead>
              <tbody>
              <tr><td class="row-name">Newborns (0-3 months)</td><td>14-17 hours</td></tr>
              <tr><td class="row-name">Infants (4-11 months)</td><td>12-15 hours</td></tr>
              <tr><td class="row-name">Toddlers (1-2 years)</td><td>11-14 hours</td></tr>
              <tr><td class="row-name">Preschoolers (3-5)</td><td>10-13 hours</td></tr>
              <tr><td class="row-name">School age children (6-13)</td><td>9-11 hours</td></tr>
              <tr><td class="row-name">Teenagers (14-17)</td><td>8-10 hours</td></tr>
               <tr><td class="row-name">Younger adults (18-25)</td><td>7-9 hours</td></tr>
                <tr><td class="row-name">Adults (26-64)</td><td>7-9 hours</td></tr>
                <tr><td class="row-name">Older adults (65+)</td><td>7-8 hours</td></tr>
              </tbody>
              </table>

<p class="c1 c5"><span></span></p>
            <p class="c1"><span
                class="c2">1. Rasch et al. About sleep&#39;s role in memory. Physiol Rev. 2013 Apr;93(2):681-766.</span></p>
<p class="c1"><span
                class="c2">2. Alhola et al. Sleep deprivation: Impact on cognitive performance. Neuropsychiatr Dis Treat. 2007 Oct; 3(5): 553 -567.</span></p>
        `,
    "sleep_latency":
      `
              <p class="c1"><span class="c0">What is Sleep Onset or Time to fall asleep?</span></p>

              <p class="c1"><span>The time to fall asleep, also known as sleep latency, is the time between the sleep activity onset to the first NREM or REM sleep epochs. Generally, it should be under 30&nbsp;minutes. </span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">How is calculated Sleep Onset?</span></p>

              <p class="c1"><span>The sleep evaluation is done over a night recording and serves to quantify and qualify sleep by giving sleep measures and time function graphic to represent the sleeper position and the sleep stages progression over the night. Many specialised sleep metrics are calculated: sleep efficiency, total sleep time, time to fall asleep, position changes and HF normalized.</span><span>&nbsp;</span><span>This test should be repeated at least three times to follow your sleep metrics over time.</span>`,
    "sleep_efficiency":
      `              <p class="c1"><span class="c0">What is Sleep </span><span class="c0">Efficiency</span><span
                  class="c0">?</span></p>

              <p class="c1"><span>The sleep efficiency is a sleep quality index, in percent, that measures the proportion of time asleep over the time spent in bed. It&rsquo;s an indicator of sleep stability. Sleep efficiency isn&rsquo;t affected by the sleep stage distribution. The Hexoskin computation consider the total sleep time over the total sleep period. For normal sleepers without sleep disorders, the sleep efficiency is usually over 95%. A value under 85% is generally associated with a bad night.</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span class="c0">How is calculated Sleep%?</span></p>

              <p class="c1"><span>According to the AASM standard, the sleep efficiency is defined as the total sleep time (TST) over the time when the light was off in the laboratory. Since this formula is not applicable at home, Hexoskin define the sleep efficiency as the TST over the total sleep period</span><span>, which is defined as the time between the sleep onset and the last awakening.</span>
              </p>

              <p class="c1 c5"><span></span></p>

              <p class="c1"><span>The sleep evaluation is done over a night recording and serves to quantify and qualify sleep by giving sleep measures and time function graphic to represent the sleeper position and the sleep stages progression over the night. Many specialised sleep metrics are calculated: sleep efficiency, total sleep time, time to fall asleep, position changes and HF normalized.</span><span>&nbsp;</span><span>This test should be repeated at least three times to follow your sleep metrics over time.</span>
              </p>`
};

