import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export class MetricOneStatusTask {
  constructor(public name?: string, public unit?: string, public title?: string, public pattern?: string) {}
}

@Injectable()
export class MetricOneStatusService {

    // Observable string sources
    private tasks = new Subject<any>();

    // Observable string streams
    tasksConfirmed$ = this.tasks.asObservable();

    /**
     * Post task
     * Allows to send actions to execute to others component
     * @params : task name
     */
    postTask(task: MetricOneStatusTask) {
        this.tasks.next(task);
    }

}