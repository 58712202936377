/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-day-view.component";
import * as i2 from "../../providers/calendar-utils.provider";
var styles_CalendarDayViewComponent = [];
var RenderType_CalendarDayViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarDayViewComponent, data: {} });
export { RenderType_CalendarDayViewComponent as RenderType_CalendarDayViewComponent };
export function View_CalendarDayViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["calendar-day-view.component.html"]))], null, null); }
export function View_CalendarDayViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-day-view", [], null, null, null, View_CalendarDayViewComponent_0, RenderType_CalendarDayViewComponent)), i0.ɵdid(1, 770048, null, 0, i1.CalendarDayViewComponent, [i0.ChangeDetectorRef, i2.CalendarUtils, i0.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarDayViewComponentNgFactory = i0.ɵccf("app-calendar-day-view", i1.CalendarDayViewComponent, View_CalendarDayViewComponent_Host_0, { viewDate: "viewDate", events: "events", hourSegments: "hourSegments", dayStartHour: "dayStartHour", dayStartMinute: "dayStartMinute", dayEndHour: "dayEndHour", dayEndMinute: "dayEndMinute", eventWidth: "eventWidth", refresh: "refresh", locale: "locale", eventSnapSize: "eventSnapSize", tooltipPlacement: "tooltipPlacement", tooltipTemplate: "tooltipTemplate", tooltipAppendToBody: "tooltipAppendToBody", hourSegmentTemplate: "hourSegmentTemplate", allDayEventTemplate: "allDayEventTemplate", eventTemplate: "eventTemplate" }, { eventClicked: "eventClicked", hourSegmentClicked: "hourSegmentClicked", eventTimesChanged: "eventTimesChanged", beforeViewRender: "beforeViewRender" }, []);
export { CalendarDayViewComponentNgFactory as CalendarDayViewComponentNgFactory };
