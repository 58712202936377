import { Component } from '@angular/core';
import { Action, State, ChartTask, ChartService } from "../../charts/chart";
import { Annotation, Band } from "../../models";
import { Message } from '../../shared/components/growl/message';
import { ApiService } from '../../core/services';
import { GlobalVariables } from '../../core/services/global.variables';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SelectorService } from '../../shared/components/selector/selector.service';
import { DEFAULT_COLORS, SLEEP_POSITION_LABELS, SLEEP_PHASE_LABELS } from '../../config';

/* A field common stuff of range and record view component */
@Component({
  selector: 'app-data',
  template: ``
})
export class DataViewComponent {

  /**
   * View list telling if displayed or not
   */
  public displayView: Map<string, boolean>;

  /**
   * If the dropdown menu to download data is currently displayed
   */
  public downloadClicked = false;

  /**
   * If file download is in progress
   */
  public downloadInProgress = false;

  /**
   * processed data curves queue
   */
  public curvesQueue: Array<number>;

  /**
   * raw data curves queue
   */
  public curvesRawQueue: Array<number>;

  /**
   * processed data curve array
   */
  public curves: Array<any>;

  /**
   * raw data curve array
   */
  public curvesRaw: Array<any>;

  /**
   * Array telling which histograms are displayed
   */
  public displayedHistograms: Array<boolean>;

  /**
   * Processed data charts array
   */
  public charts: Array<any> = [];

  /**
   * Raw data charts array
   */
  public rawCharts: Array<any> = [];

  /**
   * Salt variable to force changes
   */
  public salt: number;

  /**
   * If the navigator is displayed on the processed data chart
   * By default, it is not displayed
   */
  public displayNavigatorProcessedData = false;

  /**
   * State tells whether the user is editing or add range/annotation to range
   */
  public state = null;

  /**
   * Array telling which forms are displayed
   */
  displayedForms: Array<boolean>;

  /**
   * Range currently being edited
   */
  public editableRange: Band;

  /**
   * Annotation currently being edited
   */
  public editableAnnotation: Annotation;

  /**
   * Messages for growl
   */
  public msgs: Message[] = [];

  /**
   * Waiting a few seconds before allowing a new requests
   */
  public waiting = false;

  /**
   * Colors for sleep
   */
  protected columnColors: Map<string, Array<any>> = new Map<string, Array<any>>();

  /**
   * Box views
   */
  boxes = [];


  optionsAxis = {
    "yAxis": [
      {
        "labels": {
          "format": "{value}"
        },
        "title": {
          "text": "min",
          "align": 'high',
          "offset": 0,
          "rotation": 0,
          "y": -10
        },
        "opposite": true
      }
    ]
  };

  default_boxes = [
    {
      "label": "Timeline",
      "displayed": true,
      "name": "timeline"
    },
    {
      "label": "Metrics",
      "displayed": true,
      "name": "metrics"
    },
    {
      "label": "Charts",
      "displayed": true,
      "name": "charts"
    },
    {
      "label": "Activities",
      "displayed": true,
      "name": "activities"
    },
    {
      "label": "Annotations",
      "displayed": true,
      "name": "annotations"
    },
    {
      "label": "Map",
      "displayed": true,
      "name": "map"
    },
    {
      "label": "Altitude",
      "displayed": true,
      "name": "altitude"
    }
  ];

  constructor(protected store: Store<AppState>,
    protected chartService: ChartService,
    protected apiService: ApiService,
    protected selectorService: SelectorService,
    protected globalVariables: GlobalVariables) {

    this.editableRange = new Band();
    this.editableAnnotation = new Annotation();

    this.displayedForms = new Array<boolean>();
    this.displayedForms['EDIT_RANGE'] = false;
    this.displayedForms['ADD_RANGE'] = false;
    this.displayedForms['ANNOTATION'] = false;

    let colors = new Array<any>();
    for (const sleepPosition of SLEEP_POSITION_LABELS) {
      colors.push(sleepPosition.color);
    }
    this.columnColors.set('308', colors);

    colors = new Array<any>();
    for (const sleepPhase of SLEEP_PHASE_LABELS) {
      colors.push(sleepPhase.color);
    }
    this.columnColors.set('309', colors);

    colors = new Array<any>();
    for (const color of DEFAULT_COLORS) {
      colors.push(color.color);
    }
    this.columnColors.set('else', colors);
  }

  /**
   * Add a curve to the queue
   */
  _addCurve(charts: Object, new_chart: Object) {
    for (let key in new_chart) {
      if (new_chart.hasOwnProperty(key)) {
        let value = new_chart[key];
        if (key === "navigator") {
          charts[key] = value;
        } else {
          if (charts[key] === undefined || charts[key].length === 0) charts[key] = new Array<any>();
          charts[key].push(value);
        }
      }
    }
  }

  /**
  /* Add and remove curve on the chart
  /* depending on the pressed buttons
  */
  toggleCurve(curveId: number, remove: boolean) {
    this.waiting = true;
    let new_chart = new Object();
    if (!remove) {
      this.curvesQueue.push(curveId);
      /* If we want 2 metrics max on the graph, uncomment the following line
      if (this.curvesQueue.length > 2) this.curvesQueue.shift(); */
    } else if (this.curvesQueue.length > 1) {
      // At least one curve on the chart. So we do not remove the curve if there is only one left.
      const index = this.curvesQueue.indexOf(curveId);
      this.curvesQueue.splice(index, 1);
    }
    if (this.displayedHistograms) {
      for (let i = 0; i < this.displayedHistograms.length; i++) { this.displayedHistograms[i] = false; }
    }
    for (let id of this.curvesQueue) {
      this._addCurve(new_chart, this.charts[id]);
      if (this.displayedHistograms) {
        this.displayedHistograms[id] = true;
      }
    }
    this.curves[0] = new_chart;
    //this.curvesQueue.sort(compare);

    this.salt = Math.random();
    setTimeout(() => this.waiting = false, 3000);

  }

  /**
   * Reset chart
   */
  public resetChart() {
    this.chartService.postTask(new ChartTask(Action.REINIT_CHART));
  }

  /**
  /* Add and remove curve on the chart
  /* depending on the pressed buttons
  */
  toggleCurveRaw(curveId: number, remove: boolean) {
    this.waiting = true;
    let new_chart = new Object();
    if (!remove) {
      this.curvesRawQueue.push(curveId);
      /* If we want 2 metrics max on the graph, uncomment the following line
      if (this.curvesQueue.length > 2) this.curvesQueue.shift(); */
    } else if (this.curvesRawQueue.length > 1) {
      // At least one curve on the chart. So we do not remove the curve if there is only one left.
      const index = this.curvesRawQueue.indexOf(curveId);
      this.curvesRawQueue.splice(index, 1);
    }
    for (let id of this.curvesRawQueue) {
      this._addCurve(new_chart, this.rawCharts[id]);
    }
    this.curvesRaw[0] = new_chart;
    this.salt = Math.random();
    setTimeout(() => this.waiting = false, 3000);
  }

  /**
  * Highligh the range  on chart
  * @param range
  */
  public highlightRange(range) {
    if (range != null && range !== undefined && this.displayedForms['EDIT_RANGE'] === false
      && this.displayedForms['ADD_RANGE'] === false) {
      this.chartService.postTask(new ChartTask(Action.HIGHLIGHT_PLOTRANGE, null, null, range));
    }
  }

  /**
  * Dis-highligh range on chart
  */
  public dishighlightRange() {
    if (this.displayedForms['EDIT_RANGE'] === false && this.displayedForms['ADD_RANGE'] === false) {
      this.chartService.postTask(new ChartTask(Action.CANCEL_PLOTRANGE));
    }
  }

  /**
  * Highligh the annotation  on chart
  * @param range
  */
  public highlightAnnotation(annotation) {
    if (annotation != null && annotation !== undefined && this.displayedForms['ANNOTATION'] === false) {
      this.chartService.postTask(new ChartTask(Action.HIGHLIGHT_PLOTANNOTATION, null, null, annotation));
    }
  }

  /**
  * Dis-highligh annotation
  */
  public dishighlightAnnotation() {
    if (this.displayedForms['ANNOTATION'] === false) {
      this.chartService.postTask(new ChartTask(Action.CANCEL_PLOTANNOTATION));
    }
  }

  /**
  /* Display the navigator on the chart
  */
  displayNavigator() {
    this.hideForms(null);
    this.displayedForms["ANNOTATION"] = false;
    this.displayedForms["EDIT_RANGE"] = false;
    this.displayedForms["ADD_RANGE"] = false;
    this.displayNavigatorProcessedData = true;
    this.state = State.ZOOM;
  }

  /**
  /* Hide the navigator on the chart
  */
  hideNavigator() {
    this.hideForms(null);
    this.displayNavigatorProcessedData = false;
    this.state = null;
  }

  /**
   * Hide all the annotation and range forms.
   */
  hideForms(formName = null) {
    for (let key in this.displayedForms) {
      if (key !== formName) this.displayedForms[key] = false;
    }
  }

  /**
   * Displays form corresponding the button clicked (todo : rename toggle)
   */
  displayForm(formName: string) {
    this.hideForms(formName);                                             // hide all the forms, before displaying the selected one
    this.displayedForms[formName] = true;
    this.displayNavigatorProcessedData = false;                           // disable navigator
    if (formName === "ANNOTATION") this.state = State.ANNOTATION_ACTIVE;
    else if (formName === "EDIT_RANGE") this.state = State.RANGE_ACTIVE;
    else if (formName === "ADD_RANGE") this.state = State.RANGE_ACTIVE;
  }

  /**
   * Hides the form corresponding the button clicked (todo : rename toggle)
   */
  hideForm(formName: string) {
    this.editableRange = new Band();                                      // reinitialize editable range for the form
    this.editableAnnotation = new Annotation();                           // reinitialize editable annotation for the form
    this.chartService.postTask(new ChartTask(Action.CANCEL_PLOTRANGE));
    this.chartService.postTask(new ChartTask(Action.CANCEL_PLOTANNOTATION));
    this.hideForms(formName);
    this.displayNavigatorProcessedData = false;                           // disable navigator
    this.displayedForms[formName] = false;
    this.state = null;
  }

  /**
   * Displays the form corresponding the button clicked
   */
  displayForceForm(formName: string) {
    this.editableRange = new Band();
    this.editableAnnotation = new Annotation();
    this.hideForms(formName);
    this.displayNavigatorProcessedData = false;                           // disable navigator
    this.displayedForms[formName] = true;
    if (formName === "ANNOTATION") this.state = State.ANNOTATION_ACTIVE;
    else if (formName === "RANGE") this.state = State.RANGE_ACTIVE;
  }

  /**
   * When the user clicks a range to select it a event is emitted
   * the range object is communicated to the range form component
   */
  onChartRange(range: Band) {

    this.editableRange = range;
    if (range.context && range.context.activitytype) {
      this.editableRange.activitytype =
        range.context.activitytype.replace("/api/activitytype/", "").replace("/", "");
    }
  }

  /**
   * When the user clicks to add or edit an annoration, an event is emitted
   * The annotation position is communicated to the annotation form component
   */
  onChartAnnotation(annotation: Annotation) {
    this.editableAnnotation = annotation;
  }

  /** 
   * Perform the actions corresponding to the events emitted
   * by others components
   */
  performAction(action: Action) {
    if ((action === Action.HIDE_ANNOTATION_FORM) ||
      (action === Action.HIDE_RANGE_FORM)) {
      this.hideForms(null);
      this.state = State.NO_ENTRY;
    } else if (action === Action.CHANGE_ANNOTATION_TO_DEFAULT_COLOR) {
      this.chartService.postTask(new ChartTask(Action.REFRESH_CHART));
    } else if (action === Action.EMPTY_FORM_FIELDS) {
      this.editableAnnotation.annotation = "";
      this.editableRange = new Band();
    } else if (action === Action.DISPLAY_GROWL_RANGE_SAVED) {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range saved' });
    } else if (action === Action.DISPLAY_GROWL_RANGE_DELETED) {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range deleted' });
    } else if (action === Action.DISPLAY_GROWL_ANNOTATION_SAVED) {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Annotation saved' });
    }
  }


}
