/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./calendar-tooltip.directive";
var styles_CalendarTooltipWindowComponent = [];
var RenderType_CalendarTooltipWindowComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarTooltipWindowComponent, data: {} });
export { RenderType_CalendarTooltipWindowComponent as RenderType_CalendarTooltipWindowComponent };
function View_CalendarTooltipWindowComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "cal-tooltip"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "cal-tooltip-arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "cal-tooltip-inner"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cal-tooltip"; var currVal_1 = ("cal-tooltip-" + _co.placement); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.contents; _ck(_v, 3, 0, currVal_2); }); }
function View_CalendarTooltipWindowComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_CalendarTooltipWindowComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, [["defaultTemplate", 2]], null, 0, null, View_CalendarTooltipWindowComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarTooltipWindowComponent_2)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { contents: 0, placement: 1, event: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.contents, _co.placement, _co.event); var currVal_1 = (_co.customTemplate || i0.ɵnov(_v, 0)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarTooltipWindowComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CalendarTooltipWindowComponent_0, RenderType_CalendarTooltipWindowComponent)), i0.ɵdid(1, 49152, null, 0, i2.CalendarTooltipWindowComponent, [], null, null)], null, null); }
var CalendarTooltipWindowComponentNgFactory = i0.ɵccf("ng-component", i2.CalendarTooltipWindowComponent, View_CalendarTooltipWindowComponent_Host_0, { contents: "contents", placement: "placement", event: "event", customTemplate: "customTemplate" }, {}, []);
export { CalendarTooltipWindowComponentNgFactory as CalendarTooltipWindowComponentNgFactory };
