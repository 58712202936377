import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpModule } from "@angular/http";
import { FormsModule } from '@angular/forms';
import { HxDaterangepicker } from './shared/components/daterangepicker/daterangepicker.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppComponent } from './app.component';
import { routing } from "./app.routes";
import { TopMenuComponent } from './shared/components/top-menu/top-menu.component';
import { TopMenuService } from "./shared/components/top-menu/top-menu.service";
import { DataViewComponent } from './modules/data-view/data-view.component';
import { LoginComponent } from './modules/login/login.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { RecordsTableComponent } from './modules/records/records-table/records-table.component';
import { RangesTableComponent } from './modules/ranges/ranges-table/ranges-table.component';
import { ConfirmationComponent } from './shared/components/confirmation/confirmation.component';
import { HelpComponent } from './shared/components/help/help.component';
import { PopupComponent } from './shared/components/popup/popup.component';
import { GrowlComponent } from './shared/components/growl/growl.component';
import { HomeComponent } from './modules/home/home.component';
import { MapComponent } from './shared/components/map/map.component';
import { MapService } from './shared/components/map/map.service';
import { TimelineService } from './shared/components/timeline/timeline.service';
import { CalendarsComponent } from './modules/calendars/calendars.component';
import { FitnessComponent } from './modules/fitness/fitness.component';
import { TimelineComponent } from './shared/components/timeline/timeline.component';
import { RrboxComponent } from './shared/components/rrbox/rrbox.component';
import { MetricValueComponent } from './shared/components/metric/metric-value/metric-value.component';
import { MetricSmallComponent } from './shared/components/metric/metric-small/metric-small.component';
import { MetricOneStatusComponent } from './shared/components/metric/metric-one-status/metric-one-status.component';
import { UserPreferencesComponent } from './modules/profile/user-preferences.component';
import { FastSubjectSwitchingComponent } from './shared/components/fast-subject-switching/fast-subject-switching.component';
import { SelectorComponent } from './shared/components/selector/selector.component';
import { SelectorService } from './shared/components/selector/selector.service';
import { MetricBoxMmaComponent } from './shared/components/metric-boxes/metric-box-mma/metric-box-mma.component';
import { MetricBoxOneComponent } from './shared/components/metric-boxes/metric-box-one/metric-box-one.component';
import { MetricBoxTwoComponent } from './shared/components/metric-boxes/metric-box-two/metric-box-two.component';
import { MonthlyReportComponent } from './shared/components/monthly-report/monthly-report.component';
import { MetricsPanelComponent } from './shared/components/metrics-panel/metrics-panel.component';
import { CalendarModule } from './shared/components/calendar';
import { RecordAnnotationComponent } from './modules/records/record-annotation/record-annotation.component';
import { RecordRangeComponent } from './modules/records/record-range/record-range.component';
import { SelectBoxesComponent } from './shared/components/select-boxes/select-boxes.component';
// services
import { AuthenticationService } from './core/services/authentication.service';
import { AlertService } from './shared/components/alert/alert.service';
import { AuthGuardService } from './core/guard/auth-guard.service';
import { ConfirmationService } from './shared/components/confirmation/confirmation.service';
import { HelpService } from './shared/components/help/help.service';
import { PopupService } from './shared/components/popup/popup.service';
import { ErrorAlertService } from './shared/components/error-alert/error-alert.service';
import { GlobalVariables } from './core/services/global.variables';

import {
  HttpService,
  ApiService,
  CoachService,
  RecordService,
  DataService,
  RangeService,
  UserService
} from './core/services';
import { DomHandler } from './core/dom/domhandler';

// Pipes
import {
  DurationMin2HrMinFormPipe,
  TransformUnitPipe,
  TimeIntervalPipe,
  DurationMinimizedPipe,
  Second2HMSPipe,
  Millisecond2HMSPipe,
  Millisecond2HMSIntPipe,
  HMSFromRelativeTimePipe,
  DbRecordToMetricPipe,
  DbTimeToDatePipe,
  DbTimeToShortDatePipe,
  CapitalizeFirstPipe,
  DurationHumanizePipe,
  DateHumanizePipe,
  RoundPipe,
  RoundPipeWithUnit,
  CapitalizePipe,
  ActivityNamePipe,
  ToIconPipe,
  KeysPipe,
  RemoveUnderscorePipe,
  SearchPipe,
  FilterPipe,
  DbTimeToDateTimePipe,
  AgePipe,
  SortPipe,
  UnitizePipe,
  ActivityTypeToIconClassPipe,
  Round2decPipe,
  UnityPipe,
  UnityRepeatPipe
} from './shared/pipes';

export declare let require: any;

// Chart
import { ChartComponent } from './charts/chart';
import { ChartService } from './charts/chart/chart.service';
import { SelectModule } from './shared/components/hx-select/hx-select.module';
import { BasicChartModule } from './charts/basic-chart/basic-chart.module';
import { BaseChartComponent } from './charts/chart/base-chart.component';
import { AddAthleteComponent } from './modules/relationship/athlete/add-athlete.component';
import { RelationshipTableComponent } from './modules/relationship/relationship-table.component';
import { AccountComponent } from './modules/relationship/account.component';
import { AddCoachComponent } from './modules/relationship/coach/add-coach.component';
import { AddUserComponent } from './modules/relationship/user/add-user.component';
import { AddCoachService } from './modules/relationship/coach/add-coach.service';
import { AddUserService } from './modules/relationship/user/add-user.service';
import { AddFriendComponent } from './modules/relationship/friend/add-friend.component';
import { AddFriendService } from './modules/relationship/friend/add-friend.service';
import { AddAthleteService } from './modules/relationship/athlete/add-athlete.service';
import { PreferenceService } from './core/services/preferences.service';
import { PrivacyComponent } from './static/privacy.component';
import { TermsComponent } from './static/terms.component';
import { LegalComponent } from './static/legal.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { UserAccountComponent } from './modules/profile/user-account.component';
import { UserFitnessComponent } from './modules/profile/user-fitness.component';
import { UserInfoComponent } from './modules/profile/user-info.component';
import { UserPasswordComponent } from './modules/profile/user-password.component';
import { RecordViewComponent } from './modules/records/record-view/record-view.component';
import { RecordViewService } from './modules/records/record-view';
import { Section, HelperComponent } from './shared/components/helper/helper.component';
import { RangeViewComponent } from './modules/ranges/range-view/range-view.component';
import { RangeViewService } from './modules/ranges/range-view/range-view.service';
import { ConfigService } from "./shared/components/config/config.service";
import { DurationPickerComponent } from './shared/components/duration-picker/duration-picker.component';
import { DurationPickerUnitComponent } from './shared/components/duration-picker/duration-picker-unit.component';
import { ReactiveFormsModule } from '@angular/forms'; // 2-way binding
import { MetricOneStatusService } from './shared/components/metric/metric-one-status/metric-one-status.service';
import { UserForm } from './modules/profile/user-form';
import { EventAggregatorModule } from './core/event-aggregator/event-aggregator.module';
import { OnEvent } from './core/event-aggregator/on-event/on-event.component';
import { DndModule } from 'ng2-dnd';
import { ErrorAlertComponent } from './shared/components/error-alert/error-alert.component';
import { EntryComponent } from './entry/entry.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { BoxViewComponent } from './modules/data-view/box-view.component';
import { HttpClientModule } from '@angular/common/http';
import { NotifierModule } from 'angular-notifier';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './core/boxes-selector/reducers/boxes-selector.reducer';
import { SelectorBoxEffects } from './core/boxes-selector/reducers/effects';
import { HammerConfig } from './core/hammer/hammer';
import { RedirectionComponent } from './shared/components/redirection/redirection.component';
import { ExportComponent } from './shared/components/export/export.component';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

//import { environment } from '../environments/environment';

export function provideErrorHandler() {
  return new ErrorHandler();
}

@NgModule({
  declarations: [
    SelectBoxesComponent,
    OnEvent,
    RelationshipTableComponent,
    EntryComponent,
    AccountComponent,
    UserPreferencesComponent,
    AddCoachComponent,
    AddUserComponent,
    HelperComponent,
    UserForm,
    FastSubjectSwitchingComponent,
    SelectorComponent,
    BaseChartComponent,
    Section,
    HelpComponent,
    AlertComponent,
    AppComponent,
    PrivacyComponent,
    TermsComponent,
    LegalComponent,
    CalendarsComponent,
    ChartComponent,
    DataViewComponent,
    DurationPickerUnitComponent,
    DurationPickerComponent,
    ConfirmationComponent,
    HelpComponent,
    PopupComponent,
    GrowlComponent,
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    UserAccountComponent,
    UserFitnessComponent,
    UserInfoComponent,
    UserPasswordComponent,
    RedirectionComponent,
    MapComponent,
    ExportComponent,
    MetricBoxMmaComponent,
    MetricBoxOneComponent,
    MetricBoxTwoComponent,
    MetricsPanelComponent,
    AddFriendComponent,
    MetricSmallComponent,
    MetricValueComponent,
    MetricOneStatusComponent,
    RangeViewComponent,
    RangesTableComponent,
    RecordAnnotationComponent,
    RecordRangeComponent,
    RecordViewComponent,
    RecordsTableComponent,
    RrboxComponent,
    TimelineComponent,
    TopMenuComponent,
    FitnessComponent,
    MonthlyReportComponent,
    AddAthleteComponent,
    BoxViewComponent,
    // PIPE
    AgePipe,
    Second2HMSPipe,
    ActivityNamePipe,
    Millisecond2HMSPipe,
    HMSFromRelativeTimePipe,
    DurationMin2HrMinFormPipe,
    Millisecond2HMSIntPipe,
    ActivityTypeToIconClassPipe,
    DbTimeToDateTimePipe,
    CapitalizeFirstPipe,
    DateHumanizePipe,
    TimeIntervalPipe,
    DbRecordToMetricPipe,
    CapitalizePipe,
    DbTimeToDatePipe,
    DbTimeToShortDatePipe,
    DurationHumanizePipe,
    DurationMinimizedPipe,
    FilterPipe,
    RemoveUnderscorePipe,
    Round2decPipe,
    RoundPipe,
    RoundPipeWithUnit,
    SearchPipe,
    SortPipe,
    KeysPipe,
    ToIconPipe,
    TransformUnitPipe,
    UnitizePipe,
    UnityPipe,
    UnityRepeatPipe,
    ErrorAlertComponent
  ],
  entryComponents: [RecordRangeComponent, RecordAnnotationComponent],
  imports: [
    NotifierModule,
    InfiniteScrollModule,
    HxDaterangepicker,
    HttpClientModule,
    DndModule.forRoot(),
    BasicChartModule.forRoot(),
    EventAggregatorModule.forRoot(),
    HttpModule,
    BrowserModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule.forRoot(),
    SelectModule,
    /*StoreModule.forRoot({
      selector: reducer
    })*/
    StoreModule.forRoot({ selector: reducer }),
    EffectsModule.forRoot([SelectorBoxEffects])
  ],
  providers: [
    { provide: ErrorHandler, useFactory: provideErrorHandler },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    GlobalVariables,
    ErrorAlertService,
    AddCoachService,
    AddUserService,
    PreferenceService,
    AddFriendService,
    AlertService,
    SelectorService,
    AuthGuardService,
    AuthenticationService,
    AddAthleteService,
    HttpService,
    ApiService,
    ChartService,
    ConfigService,
    ConfirmationService,
    HelpService,
    PopupService,
    DataService,
    DomHandler,
    MapService,
    TimelineService,
    RangeService,
    RangeViewService,
    RecordService,
    RecordViewService,
    TopMenuService,
    UserService,
    CoachService,
    MetricOneStatusService],
  bootstrap: [AppComponent]
})
export class AppModule { }

