import { OAuth2Process } from './oauth2-process';
var OAuth2Flow = /** @class */ (function () {
    function OAuth2Flow(conf) {
        this.client_id = conf.client_id;
        this.secret = conf.secret;
        this.redirect_uri = conf.redirect_uri;
        this.flow = conf.flow || 'implicit';
        this.scope = conf.flow || 'readwrite';
        this.storage = conf.storage || window.localStorage;
    }
    OAuth2Flow.prototype.authenticate = function (cb) {
        try {
            //   if (this.state) return this.fetch_access_token({ auth_response_url: document.location, state: this.state }, cb);
            if (!this.access_token) {
                return this.send_to_auth_page();
            }
            /*  if (this.access_token) return cb(this);*/
        }
        catch (err) {
            console.error('OAuth2 Error encountered:' + err);
            this.clearSession();
            return cb(this, err);
        }
    };
    OAuth2Flow.prototype.send_to_auth_page = function () {
        var oauth2url = this.oauth2.get_auth_url();
        document.location.replace(oauth2url);
    };
    OAuth2Flow.prototype.logout = function () {
        this.clearSession();
        window.location.href = this.oauth2().get_logout_url();
    };
    Object.defineProperty(OAuth2Flow.prototype, "oauth2", {
        get: function () {
            if (!this._oauth2)
                this._oauth2 = new OAuth2Process(this.client_id, this.secret, this.redirect_uri, this.flow);
            return this._oauth2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OAuth2Flow.prototype, "access_token", {
        get: function () {
            return localStorage.getItem('access_token');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OAuth2Flow.prototype, "state", {
        get: function () {
            return this._retrieve('state');
        },
        enumerable: true,
        configurable: true
    });
    OAuth2Flow.prototype._store = function (key, val) {
        return localStorage.setItem(key, val);
        //this.storage.setItem(this._key(key), val);
    };
    OAuth2Flow.prototype._retrieve = function (key) {
        return localStorage.getItem(key);
        //return this.storage.getItem(this._key(key));
    };
    OAuth2Flow.prototype._clear = function (key) {
        localStorage.removeItem(key);
        //this.storage.removeItem(this._key(key))
    };
    OAuth2Flow.prototype._key = function (key) {
        return "OAuth2Flow:" + key;
    };
    OAuth2Flow.prototype.clearSession = function () {
        localStorage.clear();
        /*        for (let k of Object.keys(this.storage)) {
                    //   if (k.indexOf(this._key('')) == 0) this.storage.removeItem(k);
                }*/
    };
    return OAuth2Flow;
}());
export { OAuth2Flow };
