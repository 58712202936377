import { BoxesSelector } from '../models/boxes-selector';
import { Actions, UPDATE_PREFERENCES, ADD_BOX, DELETE_BOX, DISPLAY_SELECTOR, HIDE_SELECTOR, EMPTY_SELECTOR, EMPTY_HIDE_SELECTOR, TOGGLE_BOX, CHANGE_TIME_RANGE } from '../actions/boxes-selector.actions';
import * as _ from 'lodash';

const initialState = {
    'box_views': new BoxesSelector(new Map(), false),
    'time_range': null
};

export function reducer(state = initialState, action: Actions) {
    let _boxes;
    
    switch (action.type) {
        case UPDATE_PREFERENCES:
            
            return state;
        case ADD_BOX:
            _boxes = new Map(state.box_views.boxes);
            return {
                'box_views': new BoxesSelector(_boxes.set(action.payload.name, action.payload), state.box_views.shown),
                'time_range': state.time_range
            };

        case DELETE_BOX:
            _boxes = new Map(state.box_views.boxes);
            _boxes.delete(action.name);
            //return new BoxesSelector(_boxes.filter(({ name }) => name !== action.name), state.shown);
            return {
                'box_views': new BoxesSelector(_boxes, state.box_views.shown),
                'time_range': state.time_range
            };

        case TOGGLE_BOX:
            console.log("=======> 3");
            _boxes = new Map(state.box_views.boxes);
            console.log(_boxes);
            if (_boxes.get(action.payload.name) !== undefined) {
                let current_state = _boxes.get(action.payload.name).displayed;
                if (!current_state) {
                    _boxes.get(action.payload.name).displayed = true;
                } else {
                    _boxes.get(action.payload.name).displayed = false;
                }
            }
            console.log("=======> 4 (new state)");
            console.log({
                'box_views': new BoxesSelector(_boxes, true),
                'time_range': state.time_range
            });
            return {
                'box_views': new BoxesSelector(_boxes, true),
                'time_range': state.time_range
            };

        case DISPLAY_SELECTOR:
            _boxes = new Map(state.box_views.boxes);
            return {
                'box_views': new BoxesSelector(_boxes, true),
                'time_range': state.time_range
            };

        case HIDE_SELECTOR:
            _boxes = new Map(state.box_views.boxes);
            return {
                'box_views': new BoxesSelector(_boxes, false),
                'time_range': state.time_range
            };

        case EMPTY_SELECTOR:
            _boxes = new Map();
            return {
                'box_views': new BoxesSelector(_boxes, state.box_views.shown),
                'time_range': state.time_range
            };

        case EMPTY_HIDE_SELECTOR:
            return {
                'box_views': new BoxesSelector(new Map(), false),
                'time_range': state.time_range
            };
    
        case CHANGE_TIME_RANGE:
            let new_state = _.clone(state);
            new_state.time_range = action.range;
            return new_state;

        default:
            return state;
    }
}
