import { Component, OnInit, Input } from '@angular/core';
import { HxToolsTime } from '../../../hx-tools/hx-tools-time';

@Component({
    selector: 'metric-box-one',
    templateUrl: './metric-box-one.component.html',
    styleUrls: ['./metric-box-one.component.scss']
})
export class MetricBoxOneComponent implements OnInit {

    @Input()
    metricIds: Array<number>;

    @Input()
    results: Array<any>;

    @Input()
    offset: number;

    @Input()
    length: number;

    //////////
    @Input()
    title: string;

    @Input()
    precision = 1;

    @Input()
    unity: string;

    @Input()
    icon: string;

    @Input()
    type: string;

    loading = false;
    val = "-";
    max = "-";
    min = "-";

    constructor() {

    }

    ngOnChanges() {
        this.init();
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.loading = true;
        this.precision = 1;

        //    this.title = "test";
        //   this.icon = "heart-rate";
        //   this.unity = "(bpm)";
        /* for (let result of this.results) {
             if (result.)
         }*/
        let results = this.results.slice(this.offset, this.offset + this.length);
        if (results[0]) {
            this.unity = results[0].unit.si_short;
            if (results[0].value < 10) {
                this.precision = 100;
            }
        }
        this.val = results[0] ? results[0].value ? (Math.round(this.precision * results[0].value) / this.precision).toString() : "-" : "-";
        //  this.max = results[1].value ? (Math.round(this.precision * results[1].value) / this.precision).toString() : "-";
        //   this.min = results[2].value ? (Math.round(this.precision * results[2].value) / this.precision).toString() : "-";


        //  this.title = results[0] ? results[0].title ? results[0].title.replace("Avg.", "").replace("Avg", "") : "-" : "-";
        this.icon = "";


        if (this.unity === "bpm") {
            this.icon = "heart-rate";
        } else if (this.unity === "L/min") {
            this.icon = "breathing-volume";
        } else if (this.unity === "rpm") {
            this.icon = "breathing-rate";
        } else if (this.unity === "spm") {
            this.icon = "cadence";
        } else if (this.unity === "Step") {
            this.icon = "steps";
        } else if (this.unity === "s") {
            this.icon = "clock";
            if (!isNaN(+this.val)) { this.val = HxToolsTime.second2HMSInt(+this.val); }
            this.unity = null;
        } else if (this.unity === "m") {
            this.icon = "distance";
            this.val = results[0].value ? (Math.round(10 * results[0].value / 1000) / 10).toString() : "-";
            this.unity = "km";
        } else if (this.unity === "Cal") {
            this.icon = "energy";
        }

    }

}