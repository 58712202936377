<label
    *ngIf="label !== ''">
    {{label}}
</label>
<div
    #selection
    [attr.tabindex]="disabled ? null : 0"
    [ngClass]="{'open': isOpen, 'focus': hasFocus, 'below': isBelow, 'disabled': disabled}"
    (click)="onSelectContainerClick()"
    (focus)="onSelectContainerFocus()"
    (keydown)="onSelectContainerKeydown($event)">

    <div class="single"
        *ngIf="!multiple">
        <div class="value"
            *ngIf="optionList.hasSelected">
            <ng-container *ngTemplateOutlet="optionTemplate; context:{option: optionList.selection[0].wrappedOption, onDeselectOptionClick: onDeselectOptionClick}"></ng-container>
            <span *ngIf="!optionTemplate">
                <i class="hx-icon {{optionList.selection[0].icon}}"></i>
                {{optionList.selection[0].label}}
            </span>
        </div>
        <div class="placeholder"
            *ngIf="!optionList.hasSelected">
            {{placeholderView}}
        </div>
        <div class="clear"
            *ngIf="allowClear && optionList.hasSelected"
            (click)="onClearSelectionClick()">
            <span class="hx-icon hx-icon-tiny hx-icon-times"></span>
        </div>
        <div class="toggle"
            *ngIf="isOpen">
            <i class="hx-icon hx-icon-caret-down"></i>
        </div>
        <div class="toggle"
            *ngIf="!isOpen">
            <i class="hx-icon hx-icon-caret-down"></i>
        </div>
    </div>

    <div class="multiple"
        *ngIf="multiple">
        <div class="option"
            *ngFor="let option of optionList.selection">
            <span class="deselect-option"
                (click)=onDeselectOptionClick(option)>
                &#x2715;
            </span>
            {{option.label}}
        </div>
        <div class="placeholder"
            *ngIf="!filterEnabled && !optionList.hasSelected">
            {{placeholderView}}
        </div>
        <input
            *ngIf="filterEnabled"
            #filterInput
            autocomplete="off"
            tabindex="-1"
            [placeholder]="placeholderView"
            [ngStyle]="{'width.px': filterInputWidth}"
            (input)="onFilterInput($event.target.value)"
            (keydown)="onMultipleFilterKeydown($event)"
            (focus)="onMultipleFilterFocus()"/>
    </div>

</div>
<select-dropdown
    *ngIf="isOpen"
    #dropdown
    [multiple]="multiple"
    [optionList]="optionList"
    [notFoundMsg]="notFoundMsg"
    [highlightColor]="highlightColor"
    [highlightTextColor]="highlightTextColor"
    [filterEnabled]="filterEnabled"
    [placeholder]="filterPlaceholder"
    [width]="width"
    [top]="top"
    [left]="left"
    [optionTemplate]="optionTemplate"
    (optionClicked)="onDropdownOptionClicked($event)"
    (optionsListClick)="onOptionsListClick()"
    (singleFilterClick)="onSingleFilterClick()"
    (singleFilterFocus)="onSingleFilterFocus()"
    (singleFilterInput)="onFilterInput($event)"
    (singleFilterKeydown)="onSingleFilterKeydown($event)">
</select-dropdown>
