import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { RangeService } from '../../../core/services/range.service';
import { RecordService } from '../../../core/services/record.service';

/*
* Component for the data export in different
* formats
*/
@Component({
    selector: 'app-data',
    template: ``
})
export class ExportComponent {

    rangeId: number;

    recordId: number;

    downloadInProgress = false;

    downloadClicked = false;

    constructor(public rangeService: RangeService,
        public recordService: RecordService) { }

    /**
    * Download the data associated to the range in the format provided in parameter
    * @param extension
    */
    public exportData(extension, type) {
        this.downloadInProgress = true;

        let contenttypes = {
            'edf': {
                type: 'application/x-edf',
                datatype: null //'33,49,19,53'
            },
            'zip': {
                type: 'application/octet-stream',
                datatype: '33,36,49,19,53,270'
            },
            'csv': {
                type: 'text/csv',
                datatype: '33,36,49,19,53,270,38'
            }
        };

        const datatype_query = contenttypes[extension].datatype;
        this.downloadClicked = false;
        if (type === 'range') {
            this.rangeService.downloadResource(this.rangeId, datatype_query, contenttypes[extension].type).subscribe(data => {
                const filename = "range-" + this.rangeId + "." + extension;
                if (navigator.appVersion.toString().indexOf('.NET') > 0)
                    window.navigator.msSaveBlob(data, filename);
                else {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    document.body.appendChild(link); // for firefox
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.downloadInProgress = false;
                }
            });
        } else {
            this.recordService.downloadResource(this.recordId, datatype_query, contenttypes[extension].type).subscribe(data => {
                const filename = "record-" + this.recordId + "." + extension;
                if (navigator.appVersion.toString().indexOf('.NET') > 0)
                    window.navigator.msSaveBlob(data, filename);
                else {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    document.body.appendChild(link); // for firefox
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.downloadInProgress = false;
                }
            });
        }
    }

}
