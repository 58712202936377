export enum HxEvent {
    // Map event
    MAP__MOUSEHOVER_ROUTEPATH = 1001,
    MAP__MOUSEOUT_ROUTEPATH = 1002,
    // Chart event
    CHART__MOUSECLICK_MARKER = 2001,
    CHART__SYNC_ZOOM = 2002,
    CHART__DATA_LOADING = 2003,
    CHART__DATA_LOADED = 2004,
    CHART__EDIT_KPI = 2005,
    // Chart set event
    CHART__MOUSECLICK_DISPLAY_RANGE_FORM = 3001,
    CHART__MOUSECLICK_DISPLAY_ANNOTATION_FORM = 3002,
    // export
    EXPORT__BINARY_DATA = 4001,
    EXPORT__CSV_DATA = 4002,
    // Fast subject switching
    FSS__MOUSECLICK_SUBJECT = 5001,
    FSS__UPDATE = 5002,
    FSS__HIDE = 5003,
    //
    MAIN__FULL_SCREEN = 6001,
    MAIN__PARTIAL_SCREEN = 6002,
    //
    SELECTOR__DISPLAY = 7001,
    SELECTOR__HIDE = 7002,
    // Login/logout
    LOGOUT = 9001,
    // Error
    ERROR = 10001
}
