/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../../../../assets/stylesheets/core/icon.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/calendar-event-title.pipe";
import * as i3 from "../../providers/calendar-event-title-formatter.provider";
import * as i4 from "./calendar-event-title.component";
var styles_CalendarEventTitleComponent = [i0.styles];
var RenderType_CalendarEventTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarEventTitleComponent, data: {} });
export { RenderType_CalendarEventTitleComponent as RenderType_CalendarEventTitleComponent };
export function View_CalendarEventTitleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CalendarEventTitlePipe, [i3.CalendarEventTitleFormatter]), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "cal-event-title"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkToRecordView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(3, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "hx-icon ", _co.event.icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.event.title, _co.view, _co.event)); _ck(_v, 2, 0, currVal_1); }); }
export function View_CalendarEventTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-event-title", [], null, null, null, View_CalendarEventTitleComponent_0, RenderType_CalendarEventTitleComponent)), i1.ɵdid(1, 49152, null, 0, i4.CalendarEventTitleComponent, [], null, null)], null, null); }
var CalendarEventTitleComponentNgFactory = i1.ɵccf("app-calendar-event-title", i4.CalendarEventTitleComponent, View_CalendarEventTitleComponent_Host_0, { event: "event", view: "view" }, {}, []);
export { CalendarEventTitleComponentNgFactory as CalendarEventTitleComponentNgFactory };
