import { OAuth2Process } from './oauth2-process';

export class OAuth2Flow {

    client_id: string;
    secret: string;
    redirect_uri: string;
    flow: string;
    scope: string;
    storage: string;
    _oauth2: any;
    popup: any;

    constructor(conf) {
        this.client_id = conf.client_id;
        this.secret = conf.secret;
        this.redirect_uri = conf.redirect_uri;
        this.flow = conf.flow || 'implicit';
        this.scope = conf.flow || 'readwrite';
        this.storage = conf.storage || window.localStorage;
    }

    authenticate(cb) {
        try {
            //   if (this.state) return this.fetch_access_token({ auth_response_url: document.location, state: this.state }, cb);
            if (!this.access_token) {
                return this.send_to_auth_page();
            }
            /*  if (this.access_token) return cb(this);*/
        } catch (err) {
            console.error('OAuth2 Error encountered:' + err);
            this.clearSession();
            return cb(this, err);
        }
    }

    send_to_auth_page() {
        let oauth2url = this.oauth2.get_auth_url();
        document.location.replace(oauth2url);
    }

    logout() {
        this.clearSession();
        window.location.href = this.oauth2().get_logout_url();
    }

    get oauth2() {
        if (!this._oauth2) this._oauth2 = new OAuth2Process(this.client_id, this.secret, this.redirect_uri, this.flow);
        return this._oauth2;
    }

    get access_token() {
        return localStorage.getItem('access_token');
    }

    get state() {
        return this._retrieve('state');
    }

    _store(key, val) {
        return localStorage.setItem(key, val);
        //this.storage.setItem(this._key(key), val);
    }

    _retrieve(key) {
        return localStorage.getItem(key);
        //return this.storage.getItem(this._key(key));
    }

    _clear(key) {
        localStorage.removeItem(key);
        //this.storage.removeItem(this._key(key))
    }

    _key(key) {
        return `OAuth2Flow:${key}`;
    }

    clearSession() {
        localStorage.clear();
        /*        for (let k of Object.keys(this.storage)) {
                    //   if (k.indexOf(this._key('')) == 0) this.storage.removeItem(k);
                }*/
    }
}

