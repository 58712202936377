import * as moment from 'moment/moment';
import { HxToolsDate } from './hx-tools-date';

export class HxToolsTime {


    static UX2DTime(start, end, reference_date, is_over_night) {

        let dstart;
        let dend;
        let startPeriod;
        let endPeriod;
        if (is_over_night) {
            startPeriod = moment(reference_date).startOf('day').add(18, 'hours').valueOf();
            endPeriod = moment(reference_date).endOf('day').add(12, 'hours').valueOf();
            if (start < startPeriod && end > startPeriod && end < endPeriod) {
                dstart = 0;
                dend = (end - startPeriod) / (60 * 60 * 1000);
            } else if (start > startPeriod && end < endPeriod) {

                dstart = (start - startPeriod) / (60 * 60 * 1000);
                dend = (end - startPeriod) / (60 * 60 * 1000);
            } else if (start < endPeriod && end > endPeriod) {
                dstart = (start - startPeriod) / (60 * 60 * 1000);
                dend = 36;
            }
            dstart += 18;
            dend += 18;
        } else {
            startPeriod = moment(reference_date).startOf('day').valueOf();
            endPeriod = moment(reference_date).endOf('day').valueOf();
            if (start < startPeriod && end > startPeriod && end < endPeriod) {
                dstart = 0;
                dend = (end - startPeriod) / (60 * 60 * 1000);
            } else if (start > startPeriod && end < endPeriod) {
                dstart = (start - startPeriod) / (60 * 60 * 1000);
                dend = (end - startPeriod) / (60 * 60 * 1000);
            } else if (start < endPeriod && end > endPeriod) {
                dstart = (start - startPeriod) / (60 * 60 * 1000);
                dend = 24;
            }
        }
        return [dstart, dend];
    }

    static second2HMS(duration: number): string {

        let durationStr = "";
        if (duration === 0) {
            return "0h";
        }
        const _duration = moment.duration(duration, 'seconds');
        if (_duration.hours() !== 0) {
            durationStr += _duration.hours() + "h ";
        }
        if (_duration.hours() !== 0 || _duration.minutes() !== 0) {
            durationStr += _duration.minutes() + "m ";
        }
        if (_duration.seconds() !== 0) {
            durationStr += _duration.seconds() + "s";
        }
        return durationStr;

    }

    static second2HHMM(duration: number): string {
        return this.millisecond2HMSInt(duration * 1000, true);
    }

    static millisecond2HMS(duration: number): string {

        let durationStr = "";
        if (duration === 0) {
            return "0h";
        }
        const _duration = moment.duration(duration, 'milliseconds');
        if (_duration.hours() !== 0) {
            durationStr += _duration.hours() + "h ";
        }
        if (_duration.hours() !== 0 || _duration.minutes() !== 0) {
            durationStr += _duration.minutes() + "m ";
        }
        if (_duration.seconds() !== 0) {
            durationStr += _duration.seconds() + "s";
        }
        return durationStr;

    }

    static millisecond2HMSInt(duration: number, hideSeconds = false): string {

        let durationStr = "";
        if (duration === 0) {
            return "00:00";
        }
        const _duration = moment.duration(duration, 'milliseconds');
        let _hours = 0;
        if (_duration.days() !== 0) {
            _hours = 24 * _duration.days();
        }
        if (_duration.hours() !== 0 || _hours) {
            if (_duration.hours() + _hours < 10) {
                durationStr += "0";
            }
            durationStr += _duration.hours() + _hours;
        } else {
            durationStr += "00";
        }
        if (_duration.hours() !== 0 || _duration.minutes() !== 0) {
            durationStr += ":";
            if (_duration.minutes() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.minutes();
        } else {
            durationStr += ":00";
        }
        if (!hideSeconds) {
            if (_duration.seconds() !== 0) {
                durationStr += ":";
                if (_duration.seconds() < 10) {
                    durationStr += "0";
                }
                durationStr += _duration.seconds();
            } else {
                durationStr += ":00";
            }
        }
        return durationStr;

    }

    static second2HMSInt(duration: number): string {

        let durationStr = "";
        if (duration === 0) {
            return "00:00";
        }
        const _duration = moment.duration(duration, 'seconds');
        if (_duration.hours() !== 0) {
            if (_duration.hours() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.hours();
        } else {
            durationStr += "00";
        }
        if (_duration.hours() !== 0 || _duration.minutes() !== 0) {
            durationStr += ":";
            if (_duration.minutes() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.minutes();
        } else {
            durationStr += ":00";
        }
        if (_duration.seconds() !== 0) {
            durationStr += ":";
            if (_duration.seconds() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.seconds();
        } else {
            durationStr += ":00";
        }
        return durationStr;

    }

    static second2HMInt(duration: number): string {

        let durationStr = "";
        if (duration === 0) {
            return "00:00";
        }
        const _duration = moment.duration(duration, 'seconds');
        if (_duration.hours() !== 0) {
            if (_duration.hours() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.hours();
        } else {
            durationStr += "00";
        }
        if (_duration.hours() !== 0 || _duration.minutes() !== 0) {
            durationStr += ":";
            if (_duration.minutes() < 10) {
                durationStr += "0";
            }
            durationStr += _duration.minutes();
        } else {
            durationStr += ":00";
        }
        return durationStr;

    }

    static fromHXtoU(values: Array<any>) {
        let _values = Array<any>();
        for (const v of values) {
            _values.push([Math.floor(1000 * v[0] / 256), v[1]]);
        }
        return _values;
    }

    static HX2U(time) {
        return Math.floor(1000 * time / 256);
    }

    /**
     * @params: offset in seconds
     */
    public static isOverNight(start: number, end: number) {
        if (moment(start).hour() <= moment(end).hour()) {
            return false;
        } else {
            return true;
        }
    }

    static points2Polygons(values: Array<any>, series: Array<any>, yBottom: number, yTop: number) {

        if (values.length > 0) {

            let phases = new Array<any>();
            let indices = new Array<any>();
            let k = 0;
            for (const serie of series) {
                indices[serie.id] = k;
                phases[k] = new Array<any>();
                k++;
            }
            let ind = 1;
            let last_phase = -1;
            let last_value;
            for (const value of values) {
                if (last_phase !== -1) {
                    phases[last_phase].push([value[0], yTop]);
                    phases[last_phase].push([value[0], yBottom]);
                }
                for (const serie of series) {
                    if (value[1] === serie.id) {
                        phases[indices[serie.id]].push([value[0], yBottom]);
                        phases[indices[serie.id]].push([value[0], yTop]);
                        last_phase = indices[serie.id];
                    }
                }
                if ((ind === values.length) && (last_phase > 0)) {
                    phases[last_phase].push([value[0], yTop]);
                    phases[last_phase].push([value[0], yBottom]);
                }
                last_value = value[0];
                ind++;
            }
            if (last_phase > 0 && phases[last_phase]) {
                phases[last_phase].push([last_value, yBottom]); // to make sure the polygon is closed
            }
            return phases;
        }
    }


    static calculateIntervalRangeForTimeline(range: any, startOfTheDay: number) {

        const timestamp = Math.round(range.start / 256);
        const start = moment.unix(timestamp); // Unix time start

        const endTimestamp = Math.round(range.end / 256);
        const end = moment.unix(endTimestamp); // Unix time start

        const _startOfTheDayOfRange = HxToolsDate.getStartOfDay(timestamp);

        let _end, _start;

        if (HxToolsTime.isOverNight(1000 * timestamp, 1000 * endTimestamp)) {
            const duration = (end.hour() + Number(end.minutes()) / 60) + 24 - (start.hour() + Number(start.minutes()) / 60);
            _end = start.hour() + Number(start.minutes()) / 60 + duration;
            _start = start.hour() + Number(start.minutes()) / 60;
        } else {
            // distinguish if the first or second day
            if (_startOfTheDayOfRange > startOfTheDay) {
                _end = end.hour() + Number(end.minutes()) / 60 + 24;
                _start = start.hour() + Number(start.minutes()) / 60 + 24;
            } else {
                _end = end.hour() + Number(end.minutes()) / 60;
                _start = start.hour() + Number(start.minutes()) / 60;
            }
        }
        return [_start, _end];
    }

}


