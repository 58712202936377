    <ng-template #defaultTemplate>
      <div
        class="cal-event"
        [class.cal-starts-within-week]="!weekEvent.startsBeforeWeek"
        [class.cal-ends-within-week]="!weekEvent.endsAfterWeek"
        [style.backgroundColor]="weekEvent.event.color.secondary"
        [ngClass]="weekEvent.event?.cssClass"
        calCalendarTooltip="TO DO" 
       
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="weekEvent.event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody">
        <app-calendar-event-actions [event]="weekEvent.event"></app-calendar-event-actions>
        <app-calendar-event-title
          [event]="weekEvent.event"
          view="week"
          (calClick)="eventClicked.emit()">
        </app-calendar-event-title>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        weekEvent: weekEvent,
        tooltipPlacement: tooltipPlacement,
        eventClicked: eventClicked,
        tooltipTemplate: tooltipTemplate,
        tooltipAppendToBody: tooltipAppendToBody
      }">
    </ng-template>

    <!-- weekEvent.event.title | calendarEventTitle:'weekTooltip':weekEvent.event" -->