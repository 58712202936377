<div class="hx-ui-dialog" [style.display]="visible ? 'block' : 'none'" [style.width.px]="width" [style.height.px]="height"
  (mousedown)="moveOnTop()">

  <div class="hx-ui-dialog-titlebar hx-ui-widget-header hx-ui-helper-clearfix ui-corner-top">
    <span class="hx-ui-dialog-title" *ngIf="header">{{header}}</span>
    <a *ngIf="closable" [ngClass]="{'hx-ui-dialog-titlebar-icon hx-ui-dialog-titlebar-close ui-corner-all':true}" href="#" role="button"
      (click)="hide($event)">
      <span class="fa fa-fw fa-close"></span>
      </a>
  </div>

  <div class="hx-ui-dialog-content hx-ui-widget-content">
    <i [class]="icon"></i>
    <span class="hx-ui-confirmdialog-message" [innerHTML]="message"></span>
  </div>

  <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
    <ng-content select="p-footer"></ng-content>
  </div>

  <div class="hx-ui-dialog-buttonpane hx-ui-widget-content hx-ui-helper-clearfix">
    <button type="button" class="button" (click)="reject()" *ngIf="rejectVisible">
            <i class="hx-icon hx-icon-times"></i> No
        </button>
    <button type="button" class="button" (click)="accept()" *ngIf="acceptVisible">
            <i class="hx-icon hx-icon-check"></i> Yes
        </button>
  </div>

</div>