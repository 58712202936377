var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs/Subject';
import { EventBase } from './event.base';
var PubSubEvent = /** @class */ (function (_super) {
    __extends(PubSubEvent, _super);
    function PubSubEvent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.source = new Subject();
        // Observable string streams
        _this.observable = _this.source.asObservable();
        // Cache array of tuples
        _this.subscriptions = [];
        return _this;
    }
    PubSubEvent.prototype.subscribe = function (observer) {
        if (this.subscriptions.find(function (item) { return item[0] === observer; }) !== undefined)
            return;
        var subscription = this.observable.subscribe(observer);
        this.subscriptions.push([observer, subscription]);
    };
    PubSubEvent.prototype.publish = function (payload) {
        this.source.next(payload);
    };
    PubSubEvent.prototype.unsubscribe = function (observer) {
        var foundIndex = this.subscriptions.findIndex(function (item) { return item[0] === observer; });
        if (foundIndex > -1) {
            var subscription = this.subscriptions[foundIndex][1];
            if (subscription.closed === false) {
                subscription.unsubscribe();
            }
            this.subscriptions.splice(foundIndex, 1);
        }
    };
    return PubSubEvent;
}(EventBase));
export { PubSubEvent };
