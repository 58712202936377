/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/calendar-tooltip.directive";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../directives/click.directive";
import * as i5 from "../../pipes/calendar-date.pipe";
import * as i6 from "../../providers/calendar-date-formatter.provider";
import * as i7 from "../../pipes/calendar-event-title.pipe";
import * as i8 from "../../providers/calendar-event-title-formatter.provider";
import * as i9 from "./calendar-month-cell.component";
var styles_CalendarMonthCellComponent = [];
var RenderType_CalendarMonthCellComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarMonthCellComponent, data: {} });
export { RenderType_CalendarMonthCellComponent as RenderType_CalendarMonthCellComponent };
function View_CalendarMonthCellComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "cal-day-badge"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.day.badgeTotal; _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarMonthCellComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 4, "div", [["class", "cal-event"]], [[4, "backgroundColor", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "calClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onMouseOut() !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (_co.highlightDay.emit({ event: _v.context.$implicit }) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (_co.unhighlightDay.emit({ event: _v.context.$implicit }) !== false);
        ad = (pd_3 && ad);
    } if (("calClick" === en)) {
        var pd_4 = (_co.onEventClick($event, _v.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(2, 147456, null, 0, i2.CalendarTooltipDirective, [i0.ElementRef, i0.Injector, i0.Renderer2, i0.ComponentFactoryResolver, i0.ViewContainerRef, i3.DOCUMENT], { contents: [0, "contents"], placement: [1, "placement"], customTemplate: [2, "customTemplate"], event: [3, "event"], appendToBody: [4, "appendToBody"] }, null), i0.ɵppd(3, 3), i0.ɵdid(4, 212992, null, 0, i4.ClickDirective, [i0.Renderer2, i0.ElementRef], null, { click: "calClick" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "cal-event"; var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.cssClass); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit.title, "monthTooltip", _v.context.$implicit)); var currVal_4 = _co.tooltipPlacement; var currVal_5 = _co.tooltipTemplate; var currVal_6 = _v.context.$implicit; var currVal_7 = _co.tooltipAppendToBody; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.color.primary; _ck(_v, 0, 0, currVal_0); }); }
function View_CalendarMonthCellComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "cal-events"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthCellComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.day.events; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarMonthCellComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "cal-cell-top"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthCellComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "cal-day-number"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 3), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthCellComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.day.badgeTotal > 0); _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.day.events.length > 0); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.day.date, "monthViewDayNumber", _co.locale)); _ck(_v, 4, 0, currVal_1); }); }
function View_CalendarMonthCellComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_CalendarMonthCellComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.CalendarDatePipe, [i6.CalendarDateFormatter, i0.LOCALE_ID]), i0.ɵpid(0, i7.CalendarEventTitlePipe, [i8.CalendarEventTitleFormatter]), (_l()(), i0.ɵand(0, [["defaultTemplate", 2]], null, 0, null, View_CalendarMonthCellComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarMonthCellComponent_5)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(5, { day: 0, openDay: 1, locale: 2, tooltipPlacement: 3, highlightDay: 4, unhighlightDay: 5, eventClicked: 6, tooltipTemplate: 7, tooltipAppendToBody: 8 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.day, _co.openDay, _co.locale, _co.tooltipPlacement, _co.highlightDay, _co.unhighlightDay, _co.eventClicked, _co.tooltipTemplate, _co.tooltipAppendToBody); var currVal_1 = (_co.customTemplate || i0.ɵnov(_v, 2)); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_CalendarMonthCellComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-month-cell", [["class", "cal-cell cal-day-cell"]], [[2, "cal-past", null], [2, "cal-today", null], [2, "cal-future", null], [2, "cal-weekend", null], [2, "cal-in-month", null], [2, "cal-out-month", null], [2, "cal-has-events", null], [2, "cal-open", null], [4, "backgroundColor", null]], null, null, View_CalendarMonthCellComponent_0, RenderType_CalendarMonthCellComponent)), i0.ɵdid(1, 49152, null, 0, i9.CalendarMonthCellComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).day.isPast; var currVal_1 = i0.ɵnov(_v, 1).day.isToday; var currVal_2 = i0.ɵnov(_v, 1).day.isFuture; var currVal_3 = i0.ɵnov(_v, 1).day.isWeekend; var currVal_4 = i0.ɵnov(_v, 1).day.inMonth; var currVal_5 = !i0.ɵnov(_v, 1).day.inMonth; var currVal_6 = (i0.ɵnov(_v, 1).day.events.length > 0); var currVal_7 = (i0.ɵnov(_v, 1).day === i0.ɵnov(_v, 1).openDay); var currVal_8 = i0.ɵnov(_v, 1).day.backgroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
var CalendarMonthCellComponentNgFactory = i0.ɵccf("app-calendar-month-cell", i9.CalendarMonthCellComponent, View_CalendarMonthCellComponent_Host_0, { day: "day", openDay: "openDay", locale: "locale", tooltipPlacement: "tooltipPlacement", tooltipAppendToBody: "tooltipAppendToBody", customTemplate: "customTemplate", tooltipTemplate: "tooltipTemplate" }, { highlightDay: "highlightDay", unhighlightDay: "unhighlightDay", eventClicked: "eventClicked" }, []);
export { CalendarMonthCellComponentNgFactory as CalendarMonthCellComponentNgFactory };
