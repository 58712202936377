import { Component, Output, ViewChild, EventEmitter, OnDestroy, OnInit, OnChanges } from "@angular/core";
import { RangeSelectEvent } from "./range-event";
import { Annotation, Range, Band } from "../../models";
import * as CONST from './constants';
import * as Highcharts from 'highcharts/highstock';
declare let require: any;
require('highcharts/highcharts-more')(Highcharts);

/**
* Historical Chart component
*/
@Component({
    template: `
        <div class="chart" #container>
        </div>
    `
})
export class BaseChartComponent implements OnInit, OnDestroy, OnChanges {

    /**
    * HighStock Chart Object
    */
    public _chart: Highcharts.ChartObject;

    /**
    * The container to display the chart
    */
    @ViewChild("container") protected container;

    /**
     * Event Emitter when plot line added on a chart
     */
    @Output()
    public annotationEvent: EventEmitter<Annotation> = new EventEmitter<Annotation>();

    /**
     * Event Emitter when plot range added on a chart
     */
    @Output()
    public rangeEvent: EventEmitter<Band> = new EventEmitter<Band>();

    /**
    * Event Emitter when zoom on a chart
    */
    @Output()
    evRangeSelected: EventEmitter<RangeSelectEvent> = new EventEmitter<RangeSelectEvent>();

    /**
     *  All the annotations displayed on the chart
     */
    public annotations: Array<Annotation> = new Array<Annotation>();

    /**
     *  All the annotations displayed on the chart
     */
    public ranges: Array<Range> = new Array<Range>();

    /** 
     * Add an annotation on the chart
    /** 
     * @param {number} position  A timestamp. An integer indicating the number of milliseconds that have passed since Jan 1, 1970 UTC.
     * @param {string} color  Annotation color on the chart (Hexadecimal format)
     * @param {straing} id  Annotation id
     * @param {string} label  Annotation label (optional)
     */
    public addAnnotation(position: number, id: string, label?: string, color?: string, width?: number) {
        let _label = label;
        if (!label) { label = ""; _label = "no note"; }
        if (!color) { color = "#7690A4"; }
        let _width = 1;
        if (width) { _width = width; }
        const annotation = new Annotation();
        annotation.start = 256 * position / 1000; // to HXTimestamps
        this.annotationEvent.emit(annotation);

        if (this._chart !== undefined && this._chart.xAxis !== undefined && this._chart.xAxis.length > 0) {
            this._chart.xAxis[0].addPlotLine({
                id: id,
                color: color,
                value: position,
                dashStyle: 'ShortDash',
                width: _width,
                label: {
                    rotation: 0,
                    useHTML: true,
                    text: '<div class="tip"><i class="hx-icon hx-icon hx-icon-flag"></i><span>' + _label + '</span></div>',
                    y: -12,
                    x: -4
                }
            });
        }
    }

    /** 
     * Add a plotline on the chart
     * 
     * @param {any} plotline  JSON object
     * @param {number} position  Timestamp position
     */
    public addPlotLine(plotline: any) {
        let that = this;
        if (this._chart !== undefined && this._chart.xAxis !== undefined && this._chart.xAxis.length > 0)
            that._chart.xAxis[0].addPlotLine(plotline);
    }


    /** 
     * Add a range on the chart
     * 
     * @param {number} start  Range start. An integer indicating the number of 256ths of a second that have passed since Jan 1, 1970 UTC, ie. a traditional timestamp, multiplied by 256.
     * @param {number} end  Range end. An integer indicating the number of 256ths of a second that have passed since Jan 1, 1970 UTC, ie. a traditional timestamp, multiplied by 256.
     * @param {number} id  Range id 
     * @param {string} name  Range rame (optional)
     * @param {string} color Range color on the chart (optional)
     */
    public addRange(start: number, end: number, id: string, name?: string, color?: string, icon?: string, borderWidth?: number) {
        if (!color) color = CONST.RANGE_COLOR;
        if (!borderWidth) borderWidth = 0;
        if (!name) name = "";
        if (icon !== null) {
            name = '<i class="hx-icon ' + icon + '"></i> ' + name;
        }

        let range = new Band();
        range.start = 256 * start / 1000;
        range.end = 256 * end / 1000;
        //        this.rangeEvent.emit(range); //Emit an event so that the range is actually added on the server side .  ?????
        if (this._chart !== undefined && this._chart.xAxis && this._chart.xAxis[0]) {
            this._chart.xAxis[0].addPlotBand({
                id: id,
                label: {
                    useHTML: true,
                    text: name,
                    style: {
                        //fontWeight: 'bold'
                    }
                },
                borderColor: '#122F4C',
                borderWidth: borderWidth,
                color: color,
                from: start,
                to: end,
                zIndex: 20
            });
        }
    }

    /** 
     * Remove a plotline from the chart
     * 
     * @param {string} name  Plotline rame
     */
    public removePlotLine(name: string) {
        if (this._chart !== undefined && this._chart.xAxis !== undefined && this._chart.xAxis.length > 0) this._chart.xAxis[0].removePlotLine(name);
    }

    /** 
    * Remove the current annotation from the chart if it has not been saved yet
    */
    public removeEditablePlotLine() {
        this.removePlotLine("currentPlotLine");
    }

    /** 
    * Remove the highlighted annotation
    */
    public removeHighlightPlotLine() {
        this.removePlotLine("highlighted");
    }

    /** 
     * Remove a plotband from the chart
     * 
     * @param {string} name  Plotband rame
     */
    public removePlotBand(name: string) {
        if (this._chart !== undefined && this._chart.xAxis !== undefined && this._chart.xAxis.length > 0)
            this._chart.xAxis[0].removePlotBand(name);
    }

    /**
     * Remove the current range on the chart if it has not been saved
     */
    public removeEditablePlotBand() {
        this.removePlotBand("editablePlotBand");
    }


    /**
     * Retrieve the closest annotation at least delta from the time
     *
     * @param {number} time  Click timestamp
     * @param {number} delta  Timestamp delta
     */
    public retrieveClosestAnnotation(time: number, delta?: number) {
        let closest_annotation = null;
        const smallest_distance = 100 * 24 * 60 * 60 * 1000;
        if (!delta) {
            delta = 5000; // 5 seconds
        }
        const _time = time * 256 / 1000;
        for (const annotation of this.annotations) {
            const current_distance = Math.abs(annotation.start - _time);
            if ((current_distance < delta) && (current_distance < smallest_distance)) {
                closest_annotation = annotation;
            }
        }
        return closest_annotation;
    }

    /**
     * Retrieve the range which the user clicks on
     *
     * @param {number} time  Click timestamp
     */
    public retrieveClosestRange(time: number) {
        let clicked_range = null;
        let size = 0;
        const _time = time * 256 / 1000;
        for (const range of this.ranges) {

            if ((range.start < _time) && (_time < range.end)) {
                if ((size === 0) || (range.end - range.start < size)) {
                    size = range.end - range.start;
                    clicked_range = range;
                }
            }
        }
        return clicked_range;
    }

    /**
     * Change color of the selected plotline
     * 
     * @param {number} position  Plotline xAxis position
     * @param {string} color  Plotline color
     */
    public changeColorPlotLine(position: number, color: string) {
        this._chart.xAxis[0].addPlotLine({
            id: "highlighted",
            color: color,
            dashStyle: 'ShortDash',
            value: position,
            width: 3
        });
    }

    public loadData(reset) {

    }

    public ngOnInit() {
        this.loadData(true);
    }

    public ngOnChanges() {
        if (this.container) {
            this.loadData(false); // not change the zoom 
        }
    }

    public ngOnDestroy() {
        if (this._chart) {
            this._chart.destroy();
        }
    }

}
