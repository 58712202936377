var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
// Import from other modules
import { OnInit, OnDestroy, ElementRef, QueryList, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// Import from current module
import { ConfigService } from '../../../shared/components/config/config.service';
import { ApiQuery, HttpService, ApiService, RangeService, RecordService, DataService } from '../../../core/services';
import { Range } from "../../../models";
import { Action, ChartService, ChartTask } from "../../../charts/chart/";
import { HxToolsGeo, HxToolsRange, HxToolsHistogram, HxToolsTimeline } from '../../../shared/hx-tools';
import { SelectorService } from '../../../shared/components/selector/selector.service';
import { Router } from '@angular/router';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { DataViewComponent } from '../../data-view/data-view.component';
import { BoxViewComponent } from '../../data-view/box-view.component';
import { GlobalVariables } from '../../../core/services/global.variables';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import { HxToolsTime } from '../../../shared/hx-tools/hx-tools-time';
import { RedirectionComponent } from '../../../shared/components/redirection/redirection.component';
import { Store } from '@ngrx/store';
import { EmptyHideSelector } from '../../../core/boxes-selector/actions/boxes-selector.actions';
import { ExportComponent } from '../../../shared/components/export/export.component';
import { Mixin } from '../../../core/mixin/mixin-decorator';
import { ZONE_LABELS, SLEEP_POSITION_LABELS, SLEEP_PHASE_LABELS } from '../../../config';
import { METRICS, SLEEP_STATUS } from '../../../config';
var RangeViewComponent = /** @class */ (function (_super) {
    __extends(RangeViewComponent, _super);
    /**
     * CONSTRUCTOR
     */
    function RangeViewComponent(store, route, rangeService, recordService, httpService, helpService, globalVariables, apiService, configService, dataService, eventAggregator, router, selectorService, chartService) {
        var _this = _super.call(this, store, chartService, apiService, selectorService, globalVariables) || this;
        _this.store = store;
        _this.route = route;
        _this.rangeService = rangeService;
        _this.recordService = recordService;
        _this.httpService = httpService;
        _this.helpService = helpService;
        _this.globalVariables = globalVariables;
        _this.apiService = apiService;
        _this.configService = configService;
        _this.dataService = dataService;
        _this.eventAggregator = eventAggregator;
        _this.router = router;
        _this.selectorService = selectorService;
        _this.chartService = chartService;
        _this.boxesClicked = new EventEmitter();
        _this.ZONE_LABELS = ZONE_LABELS;
        _this.SLEEP_POSITION_LABELS = SLEEP_POSITION_LABELS;
        _this.SLEEP_PHASE_LABELS = SLEEP_PHASE_LABELS;
        /**
         * Equals to true if the data is being retrieved from the server
         */
        _this.loading = true;
        _this.displayNavigatorRawData = true;
        _this.displayNavigatorProcessedData = false;
        /**
         * Charts displaued (Processed or Raw)
         */
        _this.displayProcessedData = true;
        _this.displayRawData = false;
        _this.processedCharts = [];
        _this.metricValues = [];
        /**
         * Matrix telling which charts are actually displayed
         */
        _this.display = [];
        _this.annotations = [];
        _this.previousRangeId = null;
        _this.nextRangeId = null;
        _this.nextRank = null;
        /* Variables for timeline */
        _this.time = "diurnal";
        _this.startHour = 0;
        _this.endHour = 24;
        _this.isOverNight = false;
        _this.mode = "add";
        /* Type of activity */
        _this.activity = "";
        _this.sleep_status = null;
        _this.helps = [];
        /**
         * If we take the preferences saved on the server
         */
        _this.preference_db = true;
        /**
       * Treatment of messages received from event aggregator
       */
        _this.onMessageReceived = function (payload) {
            if (payload.object.msg === HxEvent.FSS__MOUSECLICK_SUBJECT) { // if subject changes, go back to home page
                _this.router.navigate(['/home']);
            }
        };
        _this.metrics = METRICS;
        store.select('selector').subscribe(function (_selector) {
            _this.selector = _selector.box_views;
        });
        _this.altitudes = new Array();
        _this.gpsPositions = new Array();
        _this.displayedHistograms = new Array();
        _this.displayView = new Map();
        _this.eventAggregator.getEvent(MessageSentEvent).subscribe(_this.onMessageReceived);
        //-------------------------------- Chart related actions  ---------------------------------//
        _this.chartService.tasksConfirmed$.subscribe(function (task) {
            if (task.action === Action.REFRESH_CHART) {
                _this.rangeService.fetchPeriod(_this.range.end, _this.range.start, null, null, _this.userId, "start").subscribe(function (data) {
                    _this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, _this.range, _this.startOfTheDay, _this.endOfTheDay);
                });
                _this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
                    user: _this.userId,
                    start: _this.range.start, end: _this.range.end
                }).subscribe(function (_annotations) {
                    _this.annotations = _annotations.objects;
                });
            }
            if (task.action === Action.DISPLAY_RANGE_EDIT_FORM) {
                _this.displayForm('EDIT_RANGE');
            }
        });
        return _this;
    }
    RangeViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({ msg: HxEvent.SELECTOR__DISPLAY }));
        // We retrieve the record Id from the URL
        this.sub = this.route.params.subscribe(function (params) {
            _this.rangeId = +params['id'];
            _this._init();
        });
    };
    RangeViewComponent.prototype.afterViewInit = function () {
        var _this = this;
        this.sections.forEach(function (_section) {
            _this.helps[_section.nativeElement.id] = false;
        });
    };
    RangeViewComponent.prototype._init = function () {
        var _this = this;
        var histogramIds = new Array(); // array of metric ids for histogram
        this.metricValues = new Array(); // To reinitalize!!! IMPORTANT...
        this.curves = new Array();
        this.curvesRaw = new Array();
        this.curvesQueue = new Array();
        this.curvesQueue.push(0, 1);
        this.curvesRawQueue = new Array();
        this.curvesRawQueue.push(0);
        this.loading = true;
        var user = this.globalVariables.get('currentSubject');
        this.userId = user.id;
        this.initBoxes();
        /**
         * Retrieve the activity types from server
         */
        this._retrieveActivityTypes();
        this.configService.read('assets/config/config-raw.json') // We retrieve the default config for raw data
            .flatMap(function (_rawCharts) {
            _this.rawCharts = _rawCharts;
            return _this.configService.read('assets/config/config.json'); // We retrieve the default config for processed data
        }).flatMap(function (_processedCharts) {
            _this.processedCharts = _processedCharts;
            var chartsets = _this.processedCharts;
            for (var i = 0; i < chartsets.length; i++) { // retrieve metric ids for Histograms
                if (chartsets[i].histogram) {
                    histogramIds.push(chartsets[i].histogram);
                }
            }
            return _this.rangeService.get(_this.rangeId, histogramIds); // retrieve range with metrics for hitograms
        }).flatMap(function (_range) {
            var range = new Range(JSON.stringify(_range));
            _this.range = range;
            var parameters = HxToolsTimeline.getParameters(_this.range /*, this.record*/);
            _this.time = parameters.time;
            _this.startHour = parameters.startHour;
            _this.endHour = parameters.endHour;
            _this.startOfTheDay = parameters.startOfTheDay;
            _this.endOfTheDay = parameters.endOfTheDay;
            _this.nextRank = range.rank + 1;
            return _this.apiService.getFromUri(range.user);
        }).flatMap(function (_user) {
            _this.user = _user.first_name + " " + _user.last_name;
            _this.userId = _user.id;
            _this.globalVariables.set('currentSubject', _user);
            _this.globalVariables.set('currentSubjectId', _user.id);
            _this._retrieveGpsPositions(_this.range);
            _this._retrievePreviousRangeId(_this.range);
            _this._retrieveNextRangeId(_this.range);
            /*************** Raw Charts **************/
            var rawChartSet = new Object();
            _this._addCurve(rawChartSet, _this.rawCharts[0]); // By default, first metric displayed on chart
            _this.curvesRaw[0] = rawChartSet;
            /*************** Processed Charts **************/
            _this.charts = new Array();
            for (var _i = 0, _a = _this.processedCharts; _i < _a.length; _i++) {
                var chart = _a[_i];
                if (_this._isRelevantDatatypeForRangeActivity(_this.range, chart)) {
                    _this.charts.push(chart);
                }
            }
            var processedChartSet = new Object();
            for (var _b = 0, _c = _this.curvesQueue; _b < _c.length; _b++) {
                var id = _c[_b];
                _this._addCurve(processedChartSet, _this.charts[id]);
                _this.displayedHistograms[id] = true;
            }
            _this.curves[0] = processedChartSet;
            /*************** Histograms **************/
            var histogramSet = HxToolsHistogram.createHistograms(_this.range, histogramIds, _this.columnColors);
            _this.range.histogramsReordered = histogramSet.histogramsReordered;
            /*************** Metrics **************/
            _this._retrieveRelevantMetricForRangeActivity(_this.range, histogramIds);
            _this.range.calculate();
            return _this.httpService.getFromUrl(_this.range.context.activitytype);
        }).flatMap(function (_activitytype) {
            _this.activityTypeName = _activitytype.name;
            _this.loading = false; // we can display the components at this point.
            return _this.apiService.getQuery(ApiQuery.all_annotations_for_user_id__start__end, {
                user: _this.userId,
                start: _this.range.start, end: _this.range.end
            });
        }).flatMap(function (_annotations) {
            _this.annotations = _annotations.objects;
            return _this.recordService.fetchPeriod(_this.startOfTheDay * 256, _this.endOfTheDay * 256, null, null, _this.userId);
        }).flatMap(function (_records) {
            _this.isOverNight = HxToolsTime.isOverNight(1000 * _this.range.start / 256, 1000 * _this.range.end / 256);
            _this.records = new Array();
            for (var _i = 0, _a = _records.objects; _i < _a.length; _i++) {
                var record = _a[_i];
                var intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(record.start), HxToolsTime.HX2U(record.end), _this.startOfTheDay * 1000, _this.isOverNight);
                _this.records.push({
                    "start": record.start,
                    "end": record.end,
                    "dstart": intervals[0],
                    "dend": intervals[1],
                    "recordId": record.id,
                    "highlighted": false
                });
            }
            return _this.rangeService.fetchPeriod(_this.endOfTheDay * 256, _this.startOfTheDay * 256, null, null, _this.userId);
        }).flatMap(function (_ranges) {
            _this.rangesOfDay = new Array();
            for (var _i = 0, _a = _ranges.objects; _i < _a.length; _i++) {
                var range = _a[_i];
                var intervals = HxToolsTime.UX2DTime(HxToolsTime.HX2U(range.start), HxToolsTime.HX2U(range.end), _this.startOfTheDay * 1000, _this.isOverNight);
                _this.rangesOfDay.push({
                    "start": range.start,
                    "end": range.end,
                    "dstart": intervals[0],
                    "dend": intervals[1],
                    "rangeId": range.id,
                    "highlighted": _this.range.id === range.id ? true : false
                });
            }
            return _this.rangeService.fetchPeriod(_this.range.end, _this.range.start, null, null, _this.userId, "start");
        }).subscribe(function (data) {
            _this.subRanges = HxToolsRange.getRangesOfTheDayForTimeline(data.objects, _this.range, _this.startOfTheDay, _this.endOfTheDay);
        });
    };
    /**
     * Display the edit form for range
     */
    RangeViewComponent.prototype.editRange = function (range) {
        this.hideForms('EDIT_RANGE');
        this.displayNavigatorProcessedData = false; // disable navigator
        this.displayedForms['EDIT_RANGE'] = true;
        this.onChartRange(range);
        this.chartService.postTask(new ChartTask(Action.HIGHLIGHT_PLOTRANGE, null, null, range));
        this.mode = "edit";
    };
    /**
    * Retrieve the all the activity types
    * @param range
    */
    RangeViewComponent.prototype._retrieveActivityTypes = function () {
        var _this = this;
        this.apiService.getQuery(ApiQuery.all_activitiytype).subscribe(function (activityTypes) {
            _this.activityTypes = activityTypes.objects.filter(function (activitiy) { return activitiy.role === 'system'; });
            for (var i = 0; i < _this.activityTypes.length; i++) {
                _this.activityTypes[i].label = _this.activityTypes[i].name;
                _this.activityTypes[i].icon = HxToolsActivity.activityTypeToIconClass(_this.activityTypes[i].name);
                _this.activityTypes[i].value = "" + _this.activityTypes[i].id;
            }
        });
    };
    /**
    * Retrieve the gps positions
    * @param range
    */
    RangeViewComponent.prototype._retrieveGpsPositions = function (range) {
        var _this = this;
        this.apiService.getQuery(ApiQuery.retrieve_gps_positions, { user_id: +this.userId, start: range.start, end: range.end }).subscribe(function (gpsData) {
            _this.gpsPositions = gpsData['objects'];
            _this.altitudes = HxToolsGeo.retrieveAltitudeFromGPS(_this.gpsPositions);
        });
    };
    /**
    * Check if the datatype is relevant for range activity
    * @param range
    * @param chart
    * @returns returns true if the datatype is relevant for range activity, false otherwise
    */
    RangeViewComponent.prototype._isRelevantDatatypeForRangeActivity = function (range, chart) {
        var relevant = true; // defaut, it is
        if (chart.activitytypes && chart.activitytypes.length > 0) {
            relevant = false;
            for (var _i = 0, _a = chart.activitytypes; _i < _a.length; _i++) {
                var activitytype = _a[_i];
                if (range.context.activitytype === activitytype) {
                    relevant = true;
                }
            }
        }
        return relevant;
    };
    RangeViewComponent.prototype._retrieveRelevantMetricForRangeActivity = function (range, histogramIds) {
        var _this = this;
        if (range.context.activitytype == null) { // If not activitytype associated to range (todo : check default metrics)
            this.rangeService.get(this.rangeId, histogramIds, [44, 46, 47, 71, 149, 255, 257]).subscribe(function (_data) {
                _this.metricValues = _data['metrics'];
            });
        }
        else {
            this.httpService.getFromUrl(range.context.activitytype).flatMap(function (_data) {
                var metricIds = [];
                for (var _i = 0, _a = _data.default_metrics; _i < _a.length; _i++) {
                    var metric = _a[_i];
                    var metric_id = metric.replace('/api/metric/', '').replace('/', '');
                    metricIds.push(metric_id);
                }
                // If sleep activity
                _this.activity = null;
                if (range.context.activitytype === '/api/activitytype/12/') {
                    _this.activity = "sleep";
                    metricIds.push(1071);
                }
                return _this.rangeService.get(_this.rangeId, histogramIds, metricIds);
            }).subscribe(function (_data) {
                _this.sleep_status = null;
                _this.metricValues = _data['metrics'];
                if (_this.activity === "sleep") {
                    var _sleep_status = _this.metricValues.filter(function (item) { return item.id === 1071; });
                    if (_sleep_status.length > 0 && _sleep_status[0].value !== 1) {
                        _this.sleep_status = SLEEP_STATUS[_sleep_status[0].value];
                    }
                }
            });
        }
    };
    /**
     * Perform the actions corresponding to the events emitted
     * by others components
     */
    RangeViewComponent.prototype.performAction = function (action) {
        _super.prototype.performAction.call(this, action);
    };
    /**
     * Destroyes what should be destroyed....
     */
    RangeViewComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new EmptyHideSelector());
        this.metricValues = new Array();
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload({
            msg: HxEvent.SELECTOR__HIDE
        }));
    };
    RangeViewComponent.prototype.openModalChartsBtn = function () {
        var _top = 0 + this.chartsbtn.nativeElement.getBoundingClientRect().top + window.scrollY;
        this.helpService.open("chartsbtn", _top + 'px');
    };
    RangeViewComponent = __decorate([
        Mixin([BoxViewComponent, RedirectionComponent, ExportComponent, HelperComponent]),
        __metadata("design:paramtypes", [Store,
            ActivatedRoute,
            RangeService,
            RecordService,
            HttpService,
            HelpService,
            GlobalVariables,
            ApiService,
            ConfigService,
            DataService,
            EventAggregator,
            Router,
            SelectorService,
            ChartService])
    ], RangeViewComponent);
    return RangeViewComponent;
}(DataViewComponent));
export { RangeViewComponent };
