var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Import from other modules
import { OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// Import from current module
import { ConfirmationService } from '../../../shared/components/confirmation/confirmation.service';
import { ApiQuery, ApiService, RangeService } from '../../../core/services';
import { GlobalVariables } from '../../../core/services/global.variables';
import { ArrayOfJSONObjects } from "../../../shared/hx-tools/array-of-json-objects";
import { HxToolsActivity } from '../../../shared/hx-tools/hx-tools-activity';
import { DataService } from '../../../core/services';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Subject } from 'rxjs/Subject';
import { HelpService } from '../../../shared/components/help/help.service';
import { HelperComponent } from '../../../shared/components/helper/helper.component';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
var TableData = [];
var RangesTableComponent = /** @class */ (function (_super) {
    __extends(RangesTableComponent, _super);
    //private options: Object;
    /**
     * CONSTRUCTOR
     */
    function RangesTableComponent(rangeService, router, helpService, globalVariables, dataService, apiService, confirmationService, viewContainerRef) {
        var _this = _super.call(this, helpService) || this;
        _this.rangeService = rangeService;
        _this.router = router;
        _this.helpService = helpService;
        _this.globalVariables = globalVariables;
        _this.dataService = dataService;
        _this.apiService = apiService;
        _this.confirmationService = confirmationService;
        _this.viewContainerRef = viewContainerRef;
        /**
         * Array of ranges
         */
        _this.data = TableData;
        _this.displayedData = TableData;
        _this.myDateRangePickerOptions = {};
        //@ViewChild("chart") private chart;
        /**
         * Number of ranges before update
         * If previousDataLength !== number of ranges then new range(s) has(ve) been added or deleted
         */
        _this.previousDataLength = 0;
        /**
         * Equals to true if the data is being retrieved from the server
         */
        _this.loading = false;
        /**
         * Minimum duration of ranges
         */
        _this.filterByDuration = 0;
        _this.durationQueryChanged = new Subject();
        /**
         * If we display the last record button
         */
        _this.displayLastRecordBtn = false;
        /**
     * If we display the last record button
     */
        _this.displayResetBtn = true;
        /**
         * The number of times the server has been called the page has been loaded
         */
        _this.time = 0;
        /**
         * Messages for growl
         */
        _this.msgs = [];
        /**
         * Sorting
         */
        _this.column = "start";
        _this.order = "desc";
        /**
         * Filters
         */
        _this.filter_user = null;
        /**
         * Table parameters
         */
        _this.maxItemsPerPage = 10;
        _this.currentPage = 1;
        _this.offset = 0;
        _this.activities = Array();
        _this.users = Array();
        _this.downloadInProgress = false;
        _this.downloadClicked = false;
        _this.dateRanges = {
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 100 days': [moment().subtract(100, 'days'), moment()],
            'This year': [moment().startOf('year'), moment()],
            'From the beginning': [moment().subtract(10, 'year'), moment()],
        };
        _this.pages = new Array();
        _this.dropdownHitMouseOver = new Array();
        _this.durationQueryChanged
            .debounceTime(1000) // wait 1 sec after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(function (model) {
            _this.durationQuery = model;
            var resourceUri = _this.globalVariables.get("profileUri");
            _this.apiService.getFromUri(resourceUri).flatMap(function (data) {
                _this.currentPage = 1;
                var preferences = data.preferences;
                preferences.filterByDuration = _this.filterByDuration;
                return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
            }).subscribe(function () {
                _this.msgs = [];
                _this.msgs.push({
                    severity: 'success',
                    summary: 'Confirmed',
                    detail: 'Your preference has been saved.'
                });
                _this.onClickGo(null);
            }, function (error) {
                console.error(error);
            });
        });
        return _this;
    }
    /**
     * On init
     */
    RangesTableComponent.prototype.ngOnInit = function () {
        this.router.events.subscribe(function (evt) {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.selectedPeriod = {
            startDate: moment().subtract(10, 'year'),
            endDate: moment()
        };
        this.getAll();
    };
    /**
     * On destroy
     */
    RangesTableComponent.prototype.ngOnDestroy = function () {
    };
    /**
     * Display the previous page
     */
    RangesTableComponent.prototype.nextPage = function () {
        this.currentPage++;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    };
    /**
     * Display the next page
     */
    RangesTableComponent.prototype.previousPage = function () {
        this.currentPage--;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    };
    /**
     * Display the page
     */
    RangesTableComponent.prototype.changeToPage = function (page) {
        this.currentPage = page;
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    };
    /**
     * Retrieve all the ranges the logged user is allowed to see
     */
    RangesTableComponent.prototype.getAll = function (user, activityType, start, end) {
        var _this = this;
        this.loading = true;
        var offset = (this.currentPage - 1) * this.maxItemsPerPage;
        // let offset = (this.currentPage - 1) * this.maxItemsPerPage;
        var _start = start;
        var _end = end;
        if (start === undefined || end === undefined) {
            _start = 256 * moment().subtract(10, 'year').unix();
            _end = 256 * moment().unix();
        }
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            _this.maxItemsPerPage = 10;
            if (data.preferences && data.preferences.maxItemsPerPage) {
                // todo : check if tree is valid
                _this.maxItemsPerPage = data.preferences.maxItemsPerPage;
            }
            if (data.preferences && data.preferences.filterByDuration) {
                // todo : check if tree is valid
                _this.filterByDuration = data.preferences.filterByDuration;
            }
            return _this.apiService.getQuery(ApiQuery.all_users_for_user_id);
        }).flatMap(function (data) {
            _this.users = new Array();
            for (var i = 0; i < data.objects.length; i++) {
                var subject = {
                    "value": "" + data.objects[i].id,
                    "label": data.objects[i].first_name + " " + data.objects[i].last_name
                };
                _this.users.push(subject);
            }
            // cache (todo)
            _this.cachedUsers = new Array();
            for (var _i = 0, _a = data.objects; _i < _a.length; _i++) {
                var _user = _a[_i];
                _this.cachedUsers[_user.resource_uri] = _user.first_name + " " + _user.last_name;
            }
            var rangeRank = 0; // we only display ranges of rank = 1 here.
            return _this.rangeService.fetchAll(_this.maxItemsPerPage, offset, _this.column, _this.order === "desc" ? "-" : null, activityType, _start, _end, rangeRank, user, _this.filterByDuration / 1000, [12, 44, 149]);
        }).flatMap(function (data) {
            _this.totalRangesCount = data.meta.total_count;
            _this.totalPages = Math.ceil(_this.totalRangesCount / _this.maxItemsPerPage);
            for (var i = 0; i < _this.totalRangesCount; i++) {
                _this.dropdownHitMouseOver[i] = false;
            }
            // Beginning - pagination
            _this.pages = [];
            if (_this.currentPage === 1) {
                _this.pages.push(1);
                if (_this.maxItemsPerPage < _this.totalRangesCount) {
                    _this.pages.push(2);
                }
                if (_this.maxItemsPerPage * 2 < _this.totalRangesCount) {
                    _this.pages.push(3);
                }
            }
            if (_this.currentPage >= 2) {
                if (_this.currentPage - 2 > 0)
                    _this.pages.push(_this.currentPage - 2);
                _this.pages.push(_this.currentPage - 1);
                _this.pages.push(_this.currentPage);
                if (_this.maxItemsPerPage * _this.currentPage < _this.totalRangesCount) {
                    _this.pages.push(_this.currentPage + 1);
                }
            }
            // End - pagination
            _this.data = data;
            _this.displayedData = data.objects;
            if ((_this.time > 0) && (_this.previousDataLength !== _this.totalRangesCount)) {
                //this.hasNewRecord = true;
            }
            _this.previousDataLength = _this.totalRangesCount;
            _this.time++;
            _this.loading = false;
            return _this.apiService.getQuery(ApiQuery.all_activitiytype);
        }).subscribe(function (data) {
            var activityTypes = data.objects.filter(function (activitiy) { return activitiy.role === 'system' || activitiy.role === 'support'; });
            var activities = [];
            for (var i = 0; i < activityTypes.length; i++) {
                var icon = HxToolsActivity.activityTypeToIconClass(activityTypes[i].name);
                var activity = {
                    "label": activityTypes[i].name,
                    "icon": icon ? icon : 'other',
                    "value": "" + activityTypes[i].id
                };
                activities.push(activity);
                _this.activities = _.sortBy(activities, 'label');
            }
            for (var i = 0; i < _this.displayedData.length; i++) {
                // We retrieve the training routine category
                if (_this.displayedData[i].context && _this.displayedData[i].context.activitytype) {
                    var parts = _this.displayedData[i].context.activitytype.split('/');
                    _this.displayedData[i].activityType = ArrayOfJSONObjects.findById(activityTypes, parts[3]);
                }
            }
            _this.loading = false;
        });
    };
    RangesTableComponent.prototype.onChangeActivity = function (ev) {
        this.selectedActivity = ev.value;
        this.onClickGo(ev);
    };
    RangesTableComponent.prototype.onChangeUser = function (ev) {
        this.selectedUser = ev.value;
        this.onClickGo(ev);
    };
    RangesTableComponent.prototype.onClearActivity = function (ev) {
        this.selectedActivity = null;
        this.onClickGo(ev);
    };
    RangesTableComponent.prototype.onClearUser = function (ev) {
        this.selectedUser = null;
        this.onClickGo(ev);
    };
    RangesTableComponent.prototype.onClickGo = function ($event) {
        if ($event !== undefined) {
            var range = $event;
            // Only if the time range has changed
            if (range.startDate !== undefined && range.endDate !== undefined) {
                this.currentPage = 1;
                this.start = 256 * range.startDate.startOf('day').unix();
                this.end = 256 * range.endDate.endOf('day').unix();
            }
        }
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
    };
    RangesTableComponent.prototype.reset = function () {
        this.start = this.end = null;
        this.getAll(this.selectedUser, this.selectedActivity);
    };
    RangesTableComponent.prototype.updateDuration = function (ev) {
        this.durationQueryChanged.next(ev);
    };
    RangesTableComponent.prototype.decreaseDuration = function () {
        if (this.filterByDuration >= 10 * 60 * 1000) {
            this.filterByDuration = this.filterByDuration - 10 * 60 * 1000;
        }
        this.durationQueryChanged.next(this.filterByDuration);
    };
    RangesTableComponent.prototype.increaseDuration = function () {
        this.filterByDuration = this.filterByDuration + 10 * 60 * 1000;
        this.durationQueryChanged.next(this.filterByDuration);
    };
    RangesTableComponent.prototype.onClickCancel = function () {
        this.currentPage = 1;
        this.selectedUser = null;
        this.selectedPeriod = null;
        this.getAll();
    };
    RangesTableComponent.prototype.populate = function (ranges) {
        return ranges;
    };
    /**
     * Delete a range
     * @param {number} id
     */
    RangesTableComponent.prototype.delete = function (range) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this activity?',
            header: 'Delete Confirmation',
            icon: 'fa fa-trash',
            rejectVisible: true,
            acceptVisible: true,
            accept: function () {
                _this.msgs = [];
                _this.loading = true;
                _this.rangeService.delete(range.id, "range")
                    .subscribe(function () {
                    _this.time = 0;
                    _this.getAll();
                    _this.msgs.push({ severity: 'success', summary: 'Confirmed', detail: 'Range deleted' });
                    _this.loading = false;
                }, function (error) {
                    console.error(error);
                    _this.loading = false;
                });
            }
        });
    };
    /**
     * Redirect to range view page
     */
    RangesTableComponent.prototype.view = function (range) {
        var link = ['/range-view/' + range.id];
        this.router.navigate(link);
    };
    RangesTableComponent.prototype.resetByPage = function () {
        var _this = this;
        var resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).flatMap(function (data) {
            var preferences = data.preferences;
            preferences.maxItemsPerPage = _this.maxItemsPerPage;
            return _this.dataService.updatePreferences(resourceUri, { 'preferences': preferences });
        }).subscribe(function () {
            _this.msgs = [];
            _this.msgs.push({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Your preference has been saved.'
            });
            _this.getAll(_this.selectedUser, _this.selectedActivity, _this.start, _this.end);
        }, function (error) {
            console.error(error);
        });
    };
    /**
     * Sort data table
     */
    RangesTableComponent.prototype.sort = function (property) {
        // let sortedData: any[];
        this.column = property;
        if (this.order === 'asc')
            this.order = 'desc';
        else
            this.order = 'asc';
        this.getAll(this.selectedUser, this.selectedActivity, this.start, this.end);
        //this.displayedData = this.populate(sortedData);
    };
    /**
     * Export of CSV file
     */
    RangesTableComponent.prototype.exportCSV = function (range) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, 'text/csv').subscribe(function (data) {
            var filename = "range-" + range.id + ".csv";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    /**
     * Export of binary file
     */
    RangesTableComponent.prototype.exportBinary = function (range) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,36,49,19,53,270";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, 'application/octet-stream').subscribe(function (data) {
            var filename = "range-" + range.id + ".zip";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    /**
     * Export of EDF file
     */
    RangesTableComponent.prototype.exportEDF = function (range) {
        var _this = this;
        this.downloadInProgress = true;
        var datatype_query = "33,49,19,53";
        this.downloadClicked = false;
        this.rangeService.downloadResource(range.id, datatype_query, "application/x-edf").subscribe(function (data) {
            var filename = "range-" + range.id + ".edf";
            var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge) {
                window.navigator.msSaveBlob(data, filename);
                _this.downloadInProgress = false;
            }
            else {
                var url = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link); // for firefox
                link.click();
                window.URL.revokeObjectURL(url);
                _this.downloadInProgress = false;
            }
        });
    };
    return RangesTableComponent;
}(HelperComponent));
export { RangesTableComponent };
