import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { map, catchError } from 'rxjs/operators';
import { User } from '../../models';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../core/event-aggregator/events/event';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalVariables } from '../../core/services/global.variables';
import { environment } from '../../../environments/environment';
import { NotifierService } from 'angular-notifier';

interface CacheContent {
    expiry: number;
    value: any;
}

@Injectable()
export class HttpService {

    /**
     * Logged user object
     */
    protected user: User;

    readonly DEFAULT_MAX_AGE: number = 300000;

    readonly API_URL = environment.apiURL;

    protected cache: Map<string, CacheContent> = new Map<string, CacheContent>();

    protected inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();

    protected readonly notifier: NotifierService;

    constructor(protected http: HttpClient,
        public eventAggregator: EventAggregator,
        public globalVariables: GlobalVariables,
        public notifierService: NotifierService) {
        this.notifier = notifierService;

        if (this.API_URL.slice(-1) !== "/")  {
            this.API_URL += "/";
        }
    }

    /**
     * Checks if the key exists and has not expired.
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    protected hasValidCachedValue(key: string): boolean {
        if (this.cache.has(key)) {
            if (this.cache.get(key).expiry < Date.now()) {
                this.cache.delete(key);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     *
     * @param {string} key
     *  Key
     * @param {string} value
     *  Value associated to the key
     * @param {string} maxAge
     *  Max duration of key in cache in seconds
     */
    protected set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
        this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
        this.notifyInFlightObservers(key, value);
    }

    /**
     * Checks if the a key exists in cache
     *
     * @param {string} key
     *
     * @return
     *  True if the key exists, else false
     */
    protected has(key: string): boolean {
        return this.cache.has(key);
    }


    /**
     * Clear all the cache
     *
     */
    public clearAll() {
        this.cache.forEach((value: any, key: string) => {
            this.cache.delete(key);
        });
    }


    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     *
     * @param {string} key
     * @param {any} value
     *
     */
    private notifyInFlightObservers(key: string, value: any): void {
        if (this.inFlightObservables.has(key)) {
            const inFlight = this.inFlightObservables.get(key);
            const observersCount = inFlight.observers.length;
            if (observersCount) {

                inFlight.next(value);
            }
            inFlight.complete();
            this.inFlightObservables.delete(key);
        }
    }

    protected isAuthentificated() {
        const options = this.generateHeader();

        return this.http.get(this.API_URL + 'api/account/', { 'headers': options }).pipe(
            map(res => {

                return res;
            }),
            catchError((err: HttpErrorResponse) => {
                window.location.href = this.API_URL + '/api/connect/logout/';
                throw new Error(err.error.message);
            })
        );
    }

    protected _sanetize_url(url: string) {
        return url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
    }

    /**
     * Perform a GET
     *
     * @param {number} query
     *  API call url
     * @param {boolean} cache
     *  If cache must be used
     * @param {boolean} noErrorPopup
     *  If popup is display to inform about the error
     *
     * @return
     *  An observable
     */
    protected _get(query: string, cache = true, noErrorPopup?: boolean) {

        query = this._sanetize_url(query);
        const options = this.generateHeader();
        // Response already in cache
        if (this.hasValidCachedValue(query) && cache) {
            return Observable.of(this.cache.get(query).value);
        }
        // Already waiting for the reponse
        if (this.inFlightObservables.has(query) && cache) {
            return this.inFlightObservables.get(query);
        } else {
            // Display request headers only if in dev mode
            if (!environment.production) {
                console.log(options);
            }
            this.inFlightObservables.set(query, new Subject());
            return this.http.get(query, { 'headers': options })
                .pipe(
                    map(res => {
                        this.set(query, res, Date.now() + this.DEFAULT_MAX_AGE);
                        return res;
                    }),
                    catchError((err: HttpErrorResponse) => {
                        if (err.error instanceof Error) {
                            // A client-side or network error occurred. Handle it accordingly.
                            console.error('An error occurred:', err.error.message);
                        } else {
                            console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                            if (err.status === 401 /*|| err.status === 400*/ ) {
                                localStorage.clear();
                                this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                                    {
                                        msg: HxEvent.LOGOUT
                                    }));
                            } else if (err.status === 403) {
                                this.notifier.notify('error', 'You are not allowed to perform this action.');
                            } else {
                                console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                            }
                        }
                        throw new Error((err.error && err.error.message) ? err.error.message : "An error occured");
                    })
                );
        }
    }

    /**
     * Perform a PATCH
     *
     * @param {string} query
     *  API call url
     * @param {object} object
     *  the object to modify (only properties to be changed)
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  An observable
     */
    protected _patch(query: string, object: Object, keyToClear?: string) {

        query = this._sanetize_url(query);
        const options = this.generateHeader();
        let _object = object;
        if (this._is_not_json_string(object)) _object = JSON.stringify(object);

        return this.http.patch(query, _object, { 'headers': options })
            .pipe(
                map(res => {
                    if (keyToClear) {
                        let keys = keyToClear.split(',');
                        for (let _key of keys) {
                            this.cache.forEach((value: any, key: string) => {
                                if (key.indexOf(_key) !== -1) {
                                    this.cache.delete(key);
                                }
                            });
                        }
                    }
                    this.set(query, res, Date.now() + this.DEFAULT_MAX_AGE);
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
        /*
        .catch(err => this.handleError(err));
        */

    }

    /**
     * Perform a DELETE
     *
     * @param {string} query
     *  API call url
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    protected _delete(query: string, keyToClear?: string) {
        const options = this.generateHeader();
        return this.http.delete(query, { 'headers': options })
            .pipe(
                map((response: Response) => {
                    if (keyToClear) {
                        let keys = keyToClear.split(',');
                        for (let _key of keys) {
                            this.cache.forEach((value: any, key: string) => {
                                if (key.indexOf(_key) !== -1) {
                                    this.cache.delete(key);
                                }
                            });
                        }
                    }
                    return response;
                }),
                catchError(err => this.handleError(err))
            );
    }

    _is_not_json_string(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return true;
        }
        return false;
    }


    /**
     * Perform a POST
     *
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    protected _post(query: string, object: Object, keyToClear?: string) {

        const options = this.generateHeader();
        query = this._sanetize_url(query);
        let _object = object;
        if (this._is_not_json_string(object)) _object = JSON.stringify(object);

        return this.http.post(query, _object, { 'headers': options })
            .pipe(
                map((response: Response) => {
                    if (keyToClear) {
                        let keys = keyToClear.split(',');
                        for (let _key of keys) {
                            this.cache.forEach((value: any, key: string) => {
                                if (key.indexOf(_key) !== -1) {
                                    this.cache.delete(key);
                                }
                            });
                        }
                    }
                    const body = response;
                    return body;
                }),
                catchError(err => this.handleError(err))
            );
    }


    /**
     * Perform a PUT
     *
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    protected _put(query: string, object: Object, keyToClear?: string) {
        query = this._sanetize_url(query);
        const options = this.generateHeader();
        return this.http.put(query, JSON.stringify(object), { 'headers': options })
            .pipe(
                map((response: Response) => {
                    if (keyToClear) {
                        this.cache.forEach((value: any, key: string) => {
                            if (key.indexOf(keyToClear) !== -1) {
                                this.cache.delete(key);
                            }
                        });
                    }
                    const body = response;
                    return body;
                }),
                catchError(err => this.handleError(err))
            );
    }


    public getAuthToken() {
        return this.globalVariables.get("access_token");
    }

    /**
     * Generates headers for requesting API
     * TODO
     * @param {string} query
     *  API call url
     * @param {Object} object
     *  Object to be posted
     * @param {boolean} keyToClear
     *
     *
     * @return
     *  Server response
     */
    protected generateHeader(contentType?: string): HttpHeaders {

        const accessToken = this.globalVariables.get("access_token");
        let _headers = { 'Authorization': 'Bearer ' + accessToken };


        if (accessToken !== undefined) {

            _headers = { 'Authorization': 'Bearer ' + accessToken };

            if (contentType) {
                _headers = Object.assign({}, _headers, { 'Accept': contentType });
                // options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
            } else {
                // By default, application/json content type
                _headers = Object.assign({}, _headers, { 'Content-Type': 'application/json' });
                // _headers = Object.assign({}, _headers, { 'Content-Type': 'application/json; charset=utf-8' });
                // options = new RequestOptions({  headers: headers });
            }
            const headers = new HttpHeaders(_headers);
            return headers;

        } else {
            return null;
        }

    }


    /**
     * Handles error during server communication
     */
    protected handleError(error: Response | any, nopopup?: boolean) {
        // We could use a remote logging infrastructure
        let errMsg: Array<string>;

        if (error instanceof HttpErrorResponse) {
            let msg = '';
            if (error.status === 403 || error.status === 404) {
                msg = 'You are not allowed to perform this action.';
            }
            if (error.error && error.error.errors && error.error.errors['__all__']) {
                msg = error.error.errors['__all__'];
            }
            if (msg !== '') {
                this.notifier.notify('error', msg);
            }
        }
        if (error instanceof Response) {
            if (error.status === 401) {
                this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                    {
                        msg: error.status,
                        text: errMsg
                    }));
            }
            const body = error.json() || '';
            errMsg = body.errors || JSON.stringify(body);
        } else {

            // errMsg.push(error.message ? error.message : error.toString());
        }
        if (nopopup !== true) {
            this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                {
                    msg: HxEvent.ERROR,
                    text: errMsg
                }));
        }

        return Observable.throw(error);
    }


    getFromUrl(url: string): Observable<any> {
        if ((url === null) || (url === "")) {
            return new Observable((observer) => {
                observer.next("");
                observer.complete();
            });
        } else {
            const options = this.generateHeader();
            return this.http.get(this.API_URL + url, { 'headers': options })
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError(err => this.handleError(err))
                );
        }
    }

}
