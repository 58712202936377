<div *ngIf="!loading" id="container">
  <div id="chairs">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 70">
      <g>
        <rect fill="#FFFFFF" height="70" id="svg_6" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null"
          stroke-width="0" y="20" [attr.width]="ww" [attr.x]="xx" fill="gray"></rect>
          <line *ngFor="let line of lines"
            id="svg_8" y1="20" y2="70" [attr.x2]="line" [attr.x1]="line" stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null"
            stroke="#FFFFFF" fill="none" />
          <!-- records -->
<ng-container *ngFor="let coordinate of recordCoordinates; let i = index">
          <rect *ngIf="coordinate.width && coordinate.x !== null" (mouseover)="displayRangeMetrics(coordinate.recordId)"
            (mouseout)="removeRangeMetrics()" (click)="clickRecord(coordinate.recordId)" id="svg_f_{{ i }}" [attr.stroke]="coordinate.color"
            height="30" [attr.width]="coordinate.width" y="30" [attr.x]="coordinate.x" stroke-linecap="null" stroke-linejoin="null"
            stroke-dasharray="null" stroke-width="0" [attr.fill]="coordinate.color">
            <animate *ngIf="coordinate.highlighted" attributeType="XML" attributeName="fill" [attr.values]="flashing_record" dur="3s"
              repeatCount="indefinite" />
            </rect>
</ng-container>
            <!-- ranges -->
<ng-container *ngFor="let coordinate of rangeCoordinates; let i = index">
            <rect *ngIf="coordinate.width && coordinate.x !== null" (mouseover)="displayRangeMetricsRange(coordinate.rangeId, $event)"
              (mouseout)="removeRangeMetrics()" (click)="clickRange(coordinate.rangeId)" id="svg_g_{{ i }}" [attr.stroke]="coordinate.color"
              height="15" [attr.width]="coordinate.width" y="30" [attr.x]="coordinate.x" stroke-linecap="null" stroke-linejoin="null"
              stroke-dasharray="null" stroke-width="0" [attr.fill]="coordinate.color">
              <animate *ngIf="coordinate.highlighted" attributeType="XML" attributeName="fill" [attr.values]="flashing_range" dur="3s"
                repeatCount="indefinite" />
              </rect>
</ng-container>
              <!-- bottom line -->
              <line id="svg_8" y2="20" x2="600" y1="20" x1="0" stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke="#85A1BB"
                fill="none" />
              <!-- top line -->
              <line id="svg_8" y2="70" x2="600" y1="70" x1="0" stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke="#85A1BB"
                fill="none" />
              <!-- hours at one hour interval on bottom line -->
              <line *ngFor="let hour of hours" id="svg_10" y2="70" [attr.x2]="hour" y1="65" [attr.x1]="hour" stroke-linecap="null" stroke-linejoin="null"
                stroke-dasharray="null" stroke="#183F66" fill="none" stroke-width="1" />
              <!-- hours at every 2 hours interval on top line -->
              <line *ngFor="let hour of hoursAt2h" id="svg_10" y2="20" [attr.x2]="hour.x" y1="25" [attr.x1]="hour.x" stroke-linecap="null"
                stroke-linejoin="null" stroke-dasharray="null" stroke="#183F66" fill="none" stroke-width="1" />
              <!-- hour labels -->
              <text *ngFor="let hour of hoursAt2h" xml:space="preserve" text-anchor="bottom" id="svg_1" y="15" [attr.x]="hour.x2" stroke-linecap="null"
                stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" stroke="#000000" fill="#000000">{{ hour.value }}:00</text>
      </g>
    </svg>
  </div>
  <div id="ontop_{{ rid }}" class="ontop">
    DIV
  </div>
</div>