import { User } from "./user";

export class Band {
    start: number;
    end: number;
    user: User;
    name: string;
    datatype: number;
    context: any;
    activitytype: any;
}
