import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ApiService, ApiQuery } from '../../../core/services';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import { EventAggregator } from '../../../core/event-aggregator/event.aggregator';
import { MessageSentEvent } from '../../../core/event-aggregator/events/message.sent.event';
import { MessageSentEventPayload } from '../../../core/event-aggregator/events/message.sent.event.payload';
import { HxEvent } from '../../../core/event-aggregator/events/event';
import { GlobalVariables } from '../../../core/services/global.variables';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

@Component({
    selector: 'monthly-report',
    templateUrl: './monthly-report.component.html',
    styleUrls: ['./monthly-report.component.scss',
        '../../../../assets/stylesheets/app.scss',
        '../../../../assets/stylesheets/core/section.scss',
        '../../../../assets/stylesheets/core/slider.scss',
        '../../../../assets/stylesheets/core/metric.scss',
        '../../../../assets/stylesheets/core/icon.scss']
})
export class MonthlyReportComponent implements OnInit, OnChanges {

    @Input() metricIds: Array<any>;

    monthyMetrics: Array<any>;

    loading = true;

    values: Array<any>;
    units = [];

    optionsAxis: any;

    indexOfActiveChart = 0;

    xAxisSuffix: string = "";

    @Input() offsetMonth = 0;
    offsetDay = 0;
    offsetYear = 0;

    thisMonth: number;
    thisYear: number;

    currentMonth: number;
    currentYear: number;
    currentDay: number;

    chartType = "column";

    @Input() selectedPeriod: any;

    @Input() start: any;

    @Input() end: any;

    enableNavigation = true;

    colors: Array<string>;


    constructor(
        private apiService: ApiService,
        private globalVariables: GlobalVariables,
        private eventAggregator: EventAggregator) {
        this.monthyMetrics = new Array<any>();

        this.metricIds = [44, 12, 149, 71, 1032];
        this.units = ['bpm', 'rpm', 'Cal', 'Steps', ''];

        this.colors = new Array<string>();
        this.colors.push('rgb(42, 127, 193)');
        this.colors.push('rgb(30,162,77)');
        this.colors.push('rgb(249,170,65)');
        this.colors.push('rgb(236,82,48)');
        this.colors.push('#763EA8');

        this.optionsAxis = {
            "cropThreshold": 1000,
            "yAxis": [
                {
                    "labels": false
                }
            ],
            /*"yAxis": [
                {
                    "labels": {
                        "format": "{value}"
                    },
                    "opposite": false
                }
            ],*/
            "xAxis": {
                type: 'datetime',
                tickInterval: 24 * 3600 * 1000,
                tickPixelInterval: 150,
                scrollbar: {
                    enabled: false
                }
                //min: 0,
                //max: 150 * 24 * 3600 * 1000
            }
        };
    }


    ngOnInit() {
        this.offsetMonth = 0;

        this.retrieveData(this.start, this.end);
    }

    displayChart(indexOfChart: number) {
        this.indexOfActiveChart = indexOfChart;
    }

    ngOnChanges() {
        this.reloadFromPeriod();
    }

    reloadFromPeriod() {
        this.currentMonth = this.selectedPeriod.endDate.month(); //  this.selectedPeriod.endDate.month;
        this.currentYear = this.selectedPeriod.endDate.year(); // this.selectedPeriod.endDate.year;

        this.enableNavigation = false;

        let firstDay = this.selectedPeriod.startDate.startOf('day');

        /*moment([this.selectedPeriod.beginDate.year,
        this.selectedPeriod.beginDate.month - 1,
        this.selectedPeriod.beginDate.day]);*/

        let lastDay = this.selectedPeriod.endDate.startOf('day');

        /*moment([this.selectedPeriod.endDate.year,
        this.selectedPeriod.endDate.month - 1,
        this.selectedPeriod.endDate.day]);*/

        this.xAxisSuffix = "/" + this.currentMonth;

        let start = 256 * firstDay.unix();
        let end = 256 * lastDay.unix();
        this.retrieveData(start, end);
    }



    _retrieveForInterval(start, end, user_id, metricIds) {
        return this.apiService.getQuery(ApiQuery.get_report_for_user_id__metrics__start__end__o_groupby, {
            user_id: user_id,
            metric_ids: _.toString(metricIds),
            start: start,
            end: end,
            option: '&group_by=time&time=days'
        });
    }


    retrieveData(start: number, end: number) {
        this.loading = true;
        this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
            {
                msg: HxEvent.CHART__DATA_LOADING
            }));

        const user = this.globalVariables.get('currentSubject');

        let limit = 256 * 3 * 30 * 24 * 60 * 60; // 90 days

        let nb_c_intervals = 1;                         // By default 1 intervals, diff < 90 days
        let _intervals = new Array<any>();              // Intervals of 90 days
        let _end = this.end;                            // By default, the end is the end provided in arguments
        if (end - start > limit) {                      // If diff > 90 days, we split it
            nb_c_intervals = Math.floor((end - start) / limit);
            let rest = (end - start) % limit;
            if (rest > 0) nb_c_intervals++;
            this.chartType = "scatter";
            //this.optionsAxis.xAxis.scrollbar.enabled = true;
            _end = this.start + limit;
        }
        _intervals.push({
            'start': start,
            'end': _end,
            'diff': _end - start
        });
        for (let i = 1; i < nb_c_intervals; i++) {
            let _start = start + i * limit;
            if (i === nb_c_intervals - 1) {
                _end = end;
            } else {
                _end = _start + limit;
            }
            _intervals.push({
                'start': _start,
                'end': _end,
                'diff': _end - _start
            });
        }

        let _monthyMetrics = new Array<any>();

        // We retrieve the metrics provided by the report
        // This first request because we must handle the case there is still no ranges for the user
        this.apiService.getQuery(ApiQuery.get_report_for_user_id__metrics__start__end__o_groupby, {
            user_id: user.id,
            metric_ids: _.toString(this.metricIds),
            start: _intervals[0].start,
            end: _intervals[0].end,
            option: null
        }).flatMap(data => {
            _monthyMetrics = new Array<any>();
            for (let metrics of data.metrics) _monthyMetrics.push(metrics);
            return this.apiService.getQuery(ApiQuery.get_report_for_user_id__metrics__start__end__o_groupby, {
                user_id: user.id,
                metric_ids: _.toString(this.metricIds),
                start: _intervals[0].start,
                end: _intervals[0].end,
                option: '&group_by=time&time=days'
            });
            //return this.reportService.get(user.id, this.metricIds, start, end, "days");
        }).subscribe(data => {
            let k = 0;
            this.monthyMetrics = _monthyMetrics;
            let observables = _intervals.map(_day => this._retrieveForInterval(_day.start, _day.end, user.id, this.metricIds));
            let source = Observable.forkJoin(observables);
            source.subscribe(sets => {
                this.loading = true;
                this.values = new Array<any>();
                for (data of sets) {
                    k = 0;
                    for (const id of this.metricIds) {
                        if (this.values[k] === undefined) this.values[k] = new Array<any>();
                        for (let metrics of data.objects) {
                            let date = moment(metrics.timeranges[0].start / 256, 'X');
                            this.values[k].push([1000 * date.unix(), metrics.metrics[k].value ? metrics.metrics[k].value : null]);
                        }
                        k++;
                    }
                }
                this.eventAggregator.getEvent(MessageSentEvent).publish(new MessageSentEventPayload(
                    {
                        msg: HxEvent.CHART__DATA_LOADED
                    }));
                this.loading = false;
            });



        });

    }

}
