import { Profile } from './profile';

export class User {

    id: number;

    first_name: string;

    last_name: string;

    username: string;

    email: string;

    password: string;

    system: string;

    profile: Profile;

    is_staff: boolean;
    hr_rest: number;
    hr_max: number;
    /**
     * Mandatory fields to create a new user
     */
    gender: string;
    date_of_birth: string;
    height: string;
    weight: number;

    constructor(jsonStr?: string) {
        if (jsonStr) {
            let jsonObj: any = JSON.parse(jsonStr);
            for (let prop in jsonObj) {
                if (jsonObj.hasOwnProperty(prop)) {
                    this[prop] = jsonObj[prop];
                }
            }
        }
    }

}
