<div class="cal-month-view">
    <app-calendar-month-view-header
      [days]="columnHeaders"
      [locale]="locale"
      [customTemplate]="headerTemplate">
    </app-calendar-month-view-header>
    <div class="cal-days">
      <div *ngFor="let rowIndex of view.rowOffsets">
        <div class="cal-cell-row">
          <app-calendar-month-cell
            *ngFor="let day of view.days | slice : rowIndex : rowIndex + (view.totalDaysVisibleInWeek)"
            [class.cal-drag-over]="day.dragOver"
            [ngClass]="day?.cssClass"
            [day]="day"
            [openDay]="openDay"
            [locale]="locale"
            [tooltipPlacement]="tooltipPlacement"
            [tooltipAppendToBody]="tooltipAppendToBody"
            [tooltipTemplate]="tooltipTemplate"
            [customTemplate]="cellTemplate"
            (click)="dayClicked.emit({day: day})"
            (highlightDay)="toggleDayHighlight($event.event, true)"
            (unhighlightDay)="toggleDayHighlight($event.event, false)"
            (eventClicked)="eventClicked.emit({event: $event.event})">
          </app-calendar-month-cell>
        </div>
        <!--app-calendar-open-day-events
          [isOpen]="openRowIndex === rowIndex"
          [events]="openDay?.events"
          [customTemplate]="openDayEventsTemplate"
          (eventClicked)="eventClicked.emit({event: $event.event})">
        </app-calendar-open-day-events-->
      </div>
    </div>
  </div>