/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rrbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../metric/metric-small/metric-small.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../metric/metric-small/metric-small.component";
import * as i5 from "../timeline/timeline.component.ngfactory";
import * as i6 from "../timeline/timeline.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../core/services/api.service";
import * as i9 from "../timeline/timeline.service";
import * as i10 from "../../pipes/round.pipe";
import * as i11 from "./rrbox.component";
var styles_RrboxComponent = [i0.styles];
var RenderType_RrboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RrboxComponent, data: {} });
export { RenderType_RrboxComponent as RenderType_RrboxComponent };
function View_RrboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty_box"], ["style", "background-color: white; padding:10px; height:92"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "spinner"], ["src", "assets/images/spinner.gif"]], null, null, null, null, null))], null, null); }
function View_RrboxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "hx-u-6-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "metric-small", [], null, null, null, i2.View_MetricSmallComponent_0, i2.RenderType_MetricSmallComponent)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hx-unavailable-metric": 0 }), i1.ɵdid(4, 114688, null, 0, i4.MetricSmallComponent, [], { value: [0, "value"], icon: [1, "icon"], title: [2, "title"], unit: [3, "unit"] }, null), i1.ɵppd(5, 2), i1.ɵppd(6, 1), i1.ɵppd(7, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.value === 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.value, _v.context.$implicit.unit)); var currVal_2 = i1.ɵunv(_v, 4, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 2), ((_v.context.$implicit.unit == null) ? null : _v.context.$implicit.unit.si_short))); var currVal_3 = _v.context.$implicit.title; var currVal_4 = i1.ɵunv(_v, 4, 3, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.value, _v.context.$implicit.unit)); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_RrboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "hx-u-8-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "hx-g"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RrboxComponent_3)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "hx-u-16-24"], ["style", "background-color: white;opacity:0.8; padding: 0px 0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["style", "vertical-align: bottom;padding:4px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-timeline", [], null, [[null, "evMouseOverRangeMetrics"], [null, "evMouseOverRange2Metrics"], [null, "evRemoveRangeMetrics"], [null, "evClickRange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("evMouseOverRangeMetrics" === en)) {
        var pd_0 = (_co.evMouseOverRangeMetrics($event) !== false);
        ad = (pd_0 && ad);
    } if (("evMouseOverRange2Metrics" === en)) {
        var pd_1 = (_co.evMouseOverRange2Metrics($event) !== false);
        ad = (pd_1 && ad);
    } if (("evRemoveRangeMetrics" === en)) {
        var pd_2 = (_co.evRemoveRangeMetrics() !== false);
        ad = (pd_2 && ad);
    } if (("evClickRange" === en)) {
        var pd_3 = (_co.evClickRange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_TimelineComponent_0, i5.RenderType_TimelineComponent)), i1.ɵdid(8, 638976, null, 0, i6.TimelineComponent, [i7.Router, i8.ApiService, i9.TimelineService], { records: [0, "records"], ranges: [1, "ranges"], startHour: [2, "startHour"], endHour: [3, "endHour"], time: [4, "time"] }, { evMouseOverRangeMetrics: "evMouseOverRangeMetrics", evMouseOverRange2Metrics: "evMouseOverRange2Metrics", evRemoveRangeMetrics: "evRemoveRangeMetrics", evClickRange: "evClickRange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metrics; var currVal_1 = _co.metricName; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.records; var currVal_3 = _co.ranges; var currVal_4 = _co.start; var currVal_5 = _co.end; var currVal_6 = _co.time; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_RrboxComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.TransformUnitPipe, []), i1.ɵpid(0, i10.RoundPipeWithUnit, []), i1.ɵpid(0, i10.ToIconPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "hx-date rrbox"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RrboxComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RrboxComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date.date; _ck(_v, 4, 0, currVal_0); }); }
export function View_RrboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rrbox", [], null, null, null, View_RrboxComponent_0, RenderType_RrboxComponent)), i1.ɵdid(1, 114688, null, 0, i11.RrboxComponent, [i8.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RrboxComponentNgFactory = i1.ɵccf("rrbox", i11.RrboxComponent, View_RrboxComponent_Host_0, { period: "period", records: "records", ranges: "ranges", metricList: "metricList", date: "date", time: "time", userId: "userId", start: "start", end: "end" }, {}, []);
export { RrboxComponentNgFactory as RrboxComponentNgFactory };
