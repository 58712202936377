<div class="hx-g hx-padding-bottom-15">
  <div class="hx-u-2-24">
    <div class="button button-blue tip" calCalendarToday [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
      Today
      <span>Current month</span>
    </div>
  </div>

  <div class="hx-u-2-24" style="padding: 0.5em 0;color: #183F66;text-align: center;">
    <ng-container *ngIf="false">
      {{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}
    </ng-container>
  </div>
  <div class="hx-u-3-24">
    <div *ngIf="false" class="button-group">
      <div class="button button-blue" (click)="viewChange.emit('month')" [class.active]="view === 'month'">
        Month
      </div>
      <div class="button button-blue" (click)="viewChange.emit('week')" [class.active]="view === 'week'">
        Week
      </div>
      <div class="button button-blue" (click)="viewChange.emit('day')" [class.active]="view === 'day'">
        Day
      </div>
    </div>
  </div>
  <div class="hx-u-7-24">
    <div class="button-group">
      <div class="button button-blue" calCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
        <div class="tip">
          <i class="hx-icon hx-icon-caret-left"></i>
          <span>Previous month</span>
        </div>
      </div>
      <div class="button button-blue" calCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
        <div class="tip">
          <i class="hx-icon hx-icon-caret-right"></i>
          <span>Next month</span>
        </div>
      </div>
    </div>
  </div>
  <div class="hx-u-10-24 hx-pull-right">
    <div class="button-group">
      <div [ngClass]="{ 'button-selector-active' : metrics == true }" class="button-selector" (click)="metricsChange.emit(!metrics)">
        Daily metrics
      </div>

      <div [ngClass]="{ 'button-selector-active' : displayRecords == true }" class="button-selector" (click)="displayRecordsChange.emit(!displayRecords)">
        Records
      </div>

      <div [ngClass]="{ 'button-selector-active' : displayRanges == true }" class="button-selector" (click)="displayRangesChange.emit(!displayRanges)">
        Activities
      </div>

    </div>
  </div>

</div>