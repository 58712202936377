import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import { NotifierService } from 'angular-notifier';
import 'rxjs/add/operator/mergeMap';
import * as _ from 'lodash';

@Injectable()
export class PreferenceService extends HttpService {

    public res;

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }

    saveSelector(page, name, displayed): Observable<any> {
        let _preferences = {
            'preferences': page
        };
        const profile_uri = localStorage.getItem('profileUri');
        const query = this.API_URL + profile_uri;
        console.log(query);
        let current_preferences;
        let new_preferences;

        //, JSON.stringify(_preferences)
        return this._get(query)
            .map((res: Response) => {
                if (res['preferences']) {
                    current_preferences = {
                        'preferences': res['preferences']
                    };
                } else {
                    current_preferences = {
                        'preferences': ''
                    };
                }
                
                //const second_field = _preferences.preferences[page].filter()
                
                //let index = _.findIndex(current_preferences.preferences[page], { name: name });

                new_preferences = _.cloneDeep(current_preferences);

//console.log(index);
                console.log(current_preferences.preferences);
                //console.log(second_field);
                //if (_.hasIn(new_preferences, ['preferences', first_field, second_field])) {
                // update
            /*    _.set(new_preferences,
                    ['preferences', page, second_field],
                    _.get(_preferences, ['preferences', page, second_field]));
*/
                    console.log(new_preferences);

                /*} else {
                    // add
                    _.set(new_preferences,
                        ['preferences', first_field],
                        _.get(_preferences, ['preferences', first_field]));
                }*/
            })
            .mergeMap(update => this._patch(query, JSON.stringify(new_preferences)))
            .map((res: Response) => { 

                console.log("XXXXXXX");
             })
            .map(res => this.res = res);

    }


    /**
     * Save preferences
     */
    save(preferences: Object): Observable<any> {
        let _preferences = {
            'preferences': preferences
        };
        const profile_uri = localStorage.getItem('profileUri');
        const query = this.API_URL + profile_uri;
        console.log(query);
        let current_preferences;
        let new_preferences;

        //, JSON.stringify(_preferences)
        return this._get(query)
            .map((res: Response) => {
                if (res['preferences']) {
                    current_preferences = {
                        'preferences': res['preferences']
                    };
                } else {
                    current_preferences = {
                        'preferences': ''
                    };
                }
                
                const first_field = Object.keys(_preferences.preferences)[0];
                const second_field = Object.keys(_preferences.preferences[first_field])[0];
                
                new_preferences = _.cloneDeep(current_preferences);

                console.log(new_preferences);
                console.log(_preferences);
                console.log(first_field);
                console.log(second_field);
                console.log(Object.keys(preferences[first_field]));
                //if (_.hasIn(new_preferences, ['preferences', first_field, second_field])) {
                // update
                _.set(new_preferences,
                    ['preferences', first_field, second_field],
                    _.get(_preferences, ['preferences', first_field, second_field]));

                    console.log(new_preferences);

                /*} else {
                    // add
                    _.set(new_preferences,
                        ['preferences', first_field],
                        _.get(_preferences, ['preferences', first_field]));
                }*/
            })
            .mergeMap(update => this._patch(query, JSON.stringify(new_preferences)))
            .map((res: Response) => { 

                console.log("XXXXXXX");
             })
            .map(res => this.res = res);

    }

}
