import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalVariables } from '../../core/services/global.variables';
import { ApiService } from '../../core/services';
import { User } from '../../models';
import 'rxjs/add/operator/mergeMap';
import { UserForm } from './user-form';
import { lessThan, greaterThan, isNumber } from '../../shared/validators/number.validator';
import { dateBefore } from '../../shared/validators/date.validator';
import { Message } from '../../shared/components/growl/message';


@Component({
    selector: 'preferences',
    styleUrls: ['./user-preferences.component.scss',
        '../../../assets/stylesheets/core/section.scss',
        '../../../assets/stylesheets/core/table.scss'],
    templateUrl: './user-preferences.component.html'
})
export class UserPreferencesComponent extends UserForm implements OnInit {


    /**
     * Messages for growl
     */
    public msgs: Message[] = [];

    user: User;
    profile: any;
    active = true;
    success = false;
    preferences: any;
    
    @Input() profile_id = null;

    /**
     * CONSTRUCTOR
     */
    constructor(fb: FormBuilder,
        private apiService: ApiService,
        private globalVariables: GlobalVariables) {
        super(fb);
        this.user = new User();

    }

    
    /**
     * Building the form on init
     */
    ngOnInit(): void {
        const resourceUri = this.globalVariables.get("profileUri");
        this.apiService.getFromUri(resourceUri).subscribe(data => {
            this.preferences = data.preferences;
        });
    }

    /**    
     * Actions performed when submit is clicked
     */
    onSubmit() {
    }

    /**
     * Building form function 
     * Fill the fields with the user properties
     * Associate validators to fields
     */
    buildForm(): void {
        this.form = this.fb.group({
            'weight': [this.user.weight, [
                Validators.required,
                isNumber,
                lessThan(150),
                greaterThan(5)
            ]
            ],
            'height': [this.user.height, [
                Validators.required,
                isNumber,
                lessThan(2.30),
                greaterThan(0.5)
            ]
            ],
            'gender': [this.user.gender, []],
            'date_of_birth': [this.user.date_of_birth, [
                dateBefore(new Date(Date.now() + -(5 * 365 + 3) * 24 * 3600 * 1000)) // 5 years ago + 3 days
            ]],
        });

        this.form.valueChanges
            .subscribe(data => {
                this.onValueChanged();
            });

        this.onValueChanged();
    }



}

