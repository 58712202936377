import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { EventAggregator } from '../../core/event-aggregator/event.aggregator';
import { GlobalVariables } from '../../core/services/global.variables';
import 'rxjs/add/operator/catch';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class UserService extends HttpService {

    constructor(http: HttpClient, eventAggregator: EventAggregator, globalVariables: GlobalVariables, notifierService: NotifierService) {
        super(http, eventAggregator, globalVariables, notifierService);
    }

    /**
    * Retrieve all the users associated with the logged user
    */
    fetch(): Observable<any> {
        const query = this.API_URL + "api/user/?limit=" + Math.floor((Math.random() * 100) + 100); //todo
        return this._get(query, false);
    }

    /**
     * Get the user from user_id
     */
    get(id?: number): Observable<any> {
        let query = this.API_URL + "api/user/";
        if (id) {
            query = query + id + "/";
        } else {
            query = query + this.globalVariables.get('currentUserId') + "/";
        }
        return this._get(query);
    }

    /**
     * Add a new user 
     */
    add(account): Observable<any> {
        const options = this.generateHeader();
        let query: string = "createuser/";
        return this.http.post(this.API_URL + "api/" + query, JSON.stringify(account), { headers : options} )
            .map((response: Response) => {
            })
            .catch(error => { console.error(error); 
                return Observable.throw(error.json());
            });

    }

    /**
     * Update user info
     */
    save(account, id?: number): Observable<any> {
        const options = this.generateHeader();
        let query: string = "user/";
        if (id) {
            query = query + id + "/";
        } else {
            query = query + this.globalVariables.get('currentUserId') + "/";
        }
        return this._put(this.API_URL + "api/" + query, account, 'user');
    }

}
