
                        <a (click)="decreaseValue()" class="hx-field">
                           <i class="hx-clickable hx-small-icon hx-icon-chevron-down chevron-caret"></i>
                        </a>
                        <a (click)="increaseValue()" class="hx-field">
                           <i class="hx-clickable hx-small-icon hx-icon-chevron-up chevron-caret"></i>
                        </a>
						<input [(ngModel)]="value" [disabled]="inputDisabled" class="form-control text-center" style="height:24px;width:42px;font-size:14px"
						(blur)="checkValue($event)" type="number" min="{{min}}" max="{{max}}"/>
						<span class="input-group-addon" [ngClass]="{ 'inactive' : value === 0, 'active' : value !== 0 }"style="padding: 5px;">{{ label }}</span>
  