import { Component, EventEmitter, Input, OnInit, Output, Host, Inject, forwardRef } from '@angular/core';
import { DurationPickerComponent } from './duration-picker.component';

@Component({
    selector: 'app-duration-picker-unit',
    template: `
                        <a (click)="decreaseValue()" class="hx-field">
                           <i class="hx-clickable hx-small-icon hx-icon-chevron-down chevron-caret"></i>
                        </a>
                        <a (click)="increaseValue()" class="hx-field">
                           <i class="hx-clickable hx-small-icon hx-icon-chevron-up chevron-caret"></i>
                        </a>
						<input [(ngModel)]="value" [disabled]="inputDisabled" class="form-control text-center" style="height:24px;width:42px;font-size:14px"
						(blur)="checkValue($event)" type="number" min="{{min}}" max="{{max}}"/>
						<span class="input-group-addon" [ngClass]="{ 'inactive' : value === 0, 'active' : value !== 0 }"style="padding: 5px;">{{ label }}</span>
  `,
    styles: [`
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      text-align: center;
    }
    .hx-light-button {
        color:"white";
    }
    .hx-light-button:hover {
        color:"gray";
    }
    .hx-clickable {
        cursor: pointer;
        margin-right: 4px;
    }
    .inactive {
        opacity: 0.5;
    }
    .active {
        font-weight: bold;
    }
  `]
})
export class DurationPickerUnitComponent implements OnInit {

    @Input() name: string;
    @Input() label: string;
    @Input() max: number;
    @Input() min: number;
    @Input() step: number;
    @Input() inputDisabled: boolean;

    @Output() onChange: EventEmitter<number> = new EventEmitter();

    @Input() value;

    private _label;

    constructor( @Host() @Inject(forwardRef(() => DurationPickerComponent)) public durationPicker: DurationPickerComponent) {
    }

    ngOnInit() {

        this.name = this.name.toLocaleLowerCase();
        const supportedUnitsNames = Object.keys(this.durationPicker.supportedUnits);
        if (supportedUnitsNames.indexOf(this.name) === -1) {
            throw new Error('Unsupported unit: ' + this.name);
        }
        if (this.label == null) {
            this.label = this.name.charAt(0).toUpperCase() + this.name.slice(1);
        }
        this._label = this.label;


        if (this.step == null) {
            this.step = 1;
        }
        if (this.durationPicker.inputDisabled != null) {
            this.inputDisabled = this.durationPicker.inputDisabled;
        }
        if (this.inputDisabled == null) {
            this.inputDisabled = true;
        }
        if (this.min == null) {
            this.min = this.durationPicker.supportedUnits[this.name].min;
        }
        if (this.max == null) {
            this.max = this.durationPicker.supportedUnits[this.name].max;
        }
    }

    public increaseValue(): void {
        let currentValue = this.value;
        if (currentValue < this.max) {
            currentValue = currentValue + 1; // this.step;
        } else {
            currentValue = this.min;
        }
        this.value = currentValue;
        if (this.value > 1) { this.label = this._label + "s"; } else { this.label = this._label; }
        this.updateValue();
    }

    public decreaseValue(): void {
        let currentValue = this.value;
        if (currentValue > this.min) {
            currentValue = currentValue - 1; //this.step;
        } else {
            currentValue = this.max;
        }
        this.value = currentValue;
        if (this.value > 1) { this.label = this._label + "s"; } else { this.label = this._label; }
        this.updateValue();
    }

    public checkValue(event: any) {
        let currentValue = event.target.value;
        if (currentValue < this.min) {
            currentValue = this.min;
        } else if (currentValue > this.max) {
            currentValue = this.max;
        }
        if (currentValue !== parseInt(currentValue, 10)) {
            currentValue = Math.round(currentValue);
        }
        event.target.value = currentValue;
        this.updateValue();
    }

    public updateValue() {
        this.onChange.emit(this.getValue());
        this.durationPicker.updateValue();
    }

    public getValue(): number {
        return this.value;
    }
}
